import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { breakpoints } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import { required } from '@services/FormValidators';

import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormDatePicker from '@components/ReduxFormDatePicker';
import moment from 'moment';

const ExperienceItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.s}) {
    > div:not(:last-of-type) {
      flex-direction: row;
      margin-right: 15px;
      width: 70%;
    }
  }

  @media (min-width: ${breakpoints.m}) {
    flex-direction: column;
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
  }
`;

ExperienceItemContainer.displayName = 'ExperienceItemContainer';

const RemoveExperienceItemContainer = styled.div`
  button {
    margin: 15px 0 30px;
  }

  @media (min-width: ${breakpoints.xl}) {
    button {
      margin: 45px 0 30px 15px;
    }
  }
`;

RemoveExperienceItemContainer.displayName = 'RemoveExperienceItemContainer';

const ExperienceItem = ({
  experience,
  index,
  onRemove,
}) => (
  <ExperienceItemContainer>
    <FormFieldWrapper wide>
      <Field
        component={ReduxFormInput}
        id={'employer{index}'}
        label="Company Name*"
        name={`${experience}.employer`}
        validate={required}
      />
    </FormFieldWrapper>
    <FormFieldWrapper wide>
      <Field
        component={ReduxFormDatePicker}
        id={`experienceStartDate${index}`}
        label="Start Date*"
        maxDate={moment().toDate()}
        name={`${experience}.startDate`}
        scrollableYearDropdown
        showYearDropdown
        validate={required}
        yearDropdownItemNumber={50}
      />
      <Field
        component={ReduxFormDatePicker}
        id={`experienceEndDate${index}`}
        label="End Date"
        maxDate={moment().toDate()}
        name={`${experience}.endDate`}
        scrollableYearDropdown
        showYearDropdown
        yearDropdownItemNumber={50}
      />
    </FormFieldWrapper>
    <RemoveExperienceItemContainer>
      <Button
        onClick={onRemove}
        type="button"
        variant="ghost"
      >
          REMOVE
      </Button>
    </RemoveExperienceItemContainer>
  </ExperienceItemContainer>
);

ExperienceItem.propTypes = {
  experience: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};

ExperienceItem.displayName = 'ExperienceItem';

export default ExperienceItem;
