import React from 'react';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

import Body from './components/Body';
import CloseButton from './components/CloseButton';
import Header from './components/Header';
import ModalHead from './components/ModalHead';
import ModalBody from './components/ModalBody';
import ModalOverlay from './components/ModalOverlay';

class PopUpModal extends React.Component {
  componentDidMount() {
    document.body.style.overflow = 'hidden';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  render() {
    const {
      closeAction,
      body,
      dismissable,
      head,
    } = this.props;

    document.addEventListener('keydown', ({ keyCode, key }) => {
      if (keyCode === 27 || key === 'Escape') {
        closeAction();
      }
    }, true);

    return (
      <FocusTrap>
        <ModalOverlay>
          <ModalBody>
            <ModalHead>
              <center style={{
                justifyContent: 'center',
                lineHeight: '14.5px',
                display: 'block',
              }}
              >
                {head}
              </center>
            </ModalHead>
            {dismissable && (
              <Header>
                <CloseButton
                  onClick={closeAction}
                />
              </Header>
            )}
            <Body>
              {body}
            </Body>
          </ModalBody>
        </ModalOverlay>
      </FocusTrap>
    );
  }
}

PopUpModal.propTypes = {
  closeAction: PropTypes.func.isRequired,
  body: PropTypes.element.isRequired,
  dismissable: PropTypes.bool,
  head: PropTypes.string,
};

PopUpModal.defaultProps = {
  dismissable: true,
  head: '',
};

PopUpModal.displayName = 'Modal';

export default PopUpModal;
