import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, colors, typography } from '@style-guide/config';
import ScreenReaderText from '@components/ScreenReaderText';

const Remove = styled.button`
  color: ${colors.red};
  font-size: ${rem('20px', typography.baseFontSize)};
  padding: 0;

  &::before {
    content: '\\f057';
    font-family: 'Font Awesome 5 Free', sans-serif;
    font-weight: 900;
    vertical-align: middle;
  }

  @media (min-width: ${breakpoints.l}) {
    font-size: ${rem('16px', typography.baseFontSize)};
  }
`;

Remove.displayName = 'Remove';

const Label = styled.span`
  color: ${colors.black};
  font-size: ${rem('14px', typography.baseFontSize)};
  margin-left: 10px;
`;

Label.displayName = 'Label';

const RemoveButton = ({
  label = '',
  screenReaderLabel = '',
  action,
  ...restProps
}) => {
  const handleKeyPress = (charCode) => {
    if (charCode === 13) {
      action();
    }
  };

  return (
    <Remove
      onClick={action}
      onKeyPress={handleKeyPress}
      aria-label={screenReaderLabel}
      {...restProps}
    >
      {label && (
        <Label>
          {label}
        </Label>
      )}
      <ScreenReaderText>
        {screenReaderLabel}
      </ScreenReaderText>
    </Remove>
  );
};

RemoveButton.propTypes = {
  label: PropTypes.string,
  screenReaderLabel: PropTypes.string,
  action: PropTypes.func.isRequired,
};

RemoveButton.displayName = 'RemoveButton';

export default RemoveButton;
