const serializePostData = (contentGuidString, type) => ({
  contentGuid: contentGuidString,
  eBarContentType: type,
});

const AddEBarBookmark = async (contentGuidString, type) => {
  const AddEBarBookMarkCall = await fetch('/EBarBookmark/AddEBarBookmark', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(serializePostData(contentGuidString, type)),
  });

  // if the call returns any other code other than 200 (OK)
  // just return false
  return AddEBarBookMarkCall.status === 200;
};

const DeleteEBarBookmark = async (contentGuidString, type) => {
  const DeleteEBarBookMarkCall = await fetch('/EBarBookmark/DeleteEBarBookmark', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(serializePostData(contentGuidString, type)),
  });

  // if the call returns any other code other than 200 (OK)
  // just return false
  return DeleteEBarBookMarkCall.status === 200;
};

export {
  AddEBarBookmark,
  DeleteEBarBookmark,
};
