import styled from 'styled-components';

const TwitterLink = styled.a`
  svg {
    color: #3B5998;
  }
`;

TwitterLink.displayName = 'TwitterLink';

export default TwitterLink;
