import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import { Provider } from 'react-redux';
import store from '@redux';
import Wrapper from './components/Wrapper';
import WrapperInner from './components/WrapperInner';
import TabMenu from './components/TabMenu';
import CourseDetailsTab from './components/CourseDetailsTab';
import ContentArea from './components/ContentArea';
import CourseMaterialsTab from './components/CourseMaterialsTab';

class EventClassroom extends React.Component {
  constructor() {
    super();
    this.state = {
      activeTab: 'courseDetails',
    };

    this.updateTabState = this.updateTabState.bind(this);
    this.renderTab = this.renderTab.bind(this);
  }

  updateTabState(state) {
    this.setState({
      activeTab: state,
    });
  }

  renderTab() {
    const tabToRender = this.state.activeTab;
    const {
      instructors,
      courseMaterials,
      eventInstance,
      credits,
      parentMeeting,
      specializationCredits,
      inxpoClassroomLink,
      podcastUrl,
      richCourseDescription,
      addOns,
    } = this.props;

    if (tabToRender === 'courseDetails') {
      return (
        <CourseDetailsTab
          instructors={instructors}
          eventInstance={eventInstance}
          parentMeeting={parentMeeting}
          credits={credits}
          specializationCredits={specializationCredits}
          inxpoClassroomLink={inxpoClassroomLink}
          podcastUrl={podcastUrl}
          richCourseDescription={richCourseDescription}
          addOns={addOns}
        />
      );
    }

    if (tabToRender === 'courseMaterials') {
      if (is.not.empty(courseMaterials) ||
          ((eventInstance.ctaLabel1 != null && eventInstance.ctaLabel1 !== '') || (eventInstance.ctaUrl1 != null && eventInstance.ctaUrl1 !== ''))
          || ((eventInstance.ctaLabel2 != null && eventInstance.ctaLabel2 !== '') || (eventInstance.ctaUrl2 != null && eventInstance.ctaUrl2 !== ''))
          || ((eventInstance.ctaLabel3 != null && eventInstance.ctaLabel3 !== '') || (eventInstance.ctaUrl3 != null && eventInstance.ctaUrl3 !== ''))) {
        return (
          <ContentArea>
            <CourseMaterialsTab
              courseMaterials={courseMaterials}
              eventInstance={eventInstance}
            />
          </ContentArea>
        );
      }

      return undefined;
    }

    return undefined;
  }


  render() {
    const storeInstance = store.get();
    const {
      courseMaterials,
      eventInstance,
    } = this.props;
    const courseMaterialsExist = is.not.empty(courseMaterials) ||
        ((eventInstance.ctaLabel1 != null && eventInstance.ctaLabel1 !== '') || (eventInstance.ctaUrl1 != null && eventInstance.ctaUrl1 !== ''))
        || ((eventInstance.ctaLabel2 != null && eventInstance.ctaLabel2 !== '') || (eventInstance.ctaUrl2 != null && eventInstance.ctaUrl2 !== ''))
        || ((eventInstance.ctaLabel3 != null && eventInstance.ctaLabel3 !== '') || (eventInstance.ctaUrl3 != null && eventInstance.ctaUrl3 !== ''));

    return (
      <Provider
        store={storeInstance}
      >
        <React.Fragment>
          <Wrapper>
            <WrapperInner>
              <TabMenu
                activeTab={this.state.activeTab}
                updateTabState={this.updateTabState}
                courseMaterialsExist={courseMaterialsExist}
              />
              {this.renderTab()}
            </WrapperInner>
          </Wrapper>
        </React.Fragment>
      </Provider>
    );
  }
}

EventClassroom.propTypes = {
  instructors: PropTypes.arrayOf(PropTypes.object),
  courseMaterials: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line
  eventInstance: PropTypes.object.isRequired,
  // eslint-disable-next-line
  parentMeeting: PropTypes.object.isRequired,
  credits: PropTypes.arrayOf(PropTypes.object).isRequired,
  specializationCredits: PropTypes.arrayOf(PropTypes.object),
  inxpoClassroomLink: PropTypes.string,
  podcastUrl: PropTypes.string,
  richCourseDescription: PropTypes.string,
  addOns: PropTypes.arrayOf(PropTypes.object),
};

EventClassroom.defaultProps = {
  instructors: [],
  courseMaterials: [],
  specializationCredits: [],
  inxpoClassroomLink: '',
  podcastUrl: '',
  richCourseDescription: '',
  addOns: [],
};

EventClassroom.displayName = 'EventClassroom';

export default EventClassroom;
