import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import affiliationsInfoReducer from './modules/affiliationsInfo';
import calqSearchReducer from './modules/calqSearch';
import cartReducer from './modules/cart';
import cleSearchReducer from './modules/cleSearch';
import committeesReducer from './modules/committees';
import companyInfoReducer from './modules/companyInfo';
import contactInfoReducer from './modules/contactInfo';
import demographicInfoReducer from './modules/demographicInfo';
import educationInfoReducer from './modules/educationInfo';
import experienceInfoReducer from './modules/experienceInfo';
import eventSearchReducer from './modules/eventSearch';
import findALawyerSearchReducer from './modules/findALawyerSearch';
import honorsInfoReducer from './modules/honorsInfo';
import linksInfoReducer from './modules/linksInfo';
import lawSchoolInfoReducer from './modules/lawSchoolInfo';
import marketingPreferencesInfoReducer from './modules/marketingPreferencesInfo';
import mediaInfoReducer from './modules/mediaInfo';
import overviewInfoReducer from './modules/overviewInfo';
import personalInfoReducer from './modules/personalInfo';
import practiceAreaReducer from './modules/practiceAreas';
import topicOfInterestsReducer from './modules/CommunicationPreferences/TopicOfInterests';
import memberBenefitsReducer from './modules/CommunicationPreferences/MemberBenefitsInfo';
import eNewsLettersReducer from './modules/CommunicationPreferences/ENewsLettersInfo';
import practiceLibrarySearchReducer from './modules/practiceLibrarySearch';
import sectionsReducer from './modules/sections';
import siteSearchReducer from './modules/siteSearch';
import socialMediaInfoReducer from './modules/socialMediaInfo';
import supremeCourtNumberReducer from './modules/supremeCourtNumber';
import eBarHomePageReducer from './modules/ebarHomePageInfo';

const reducer = combineReducers({
  affiliationsInfo: affiliationsInfoReducer,
  calqSearch: calqSearchReducer,
  cart: cartReducer,
  cleSearch: cleSearchReducer,
  committees: committeesReducer,
  companyInfo: companyInfoReducer,
  contactInfo: contactInfoReducer,
  demographicInfo: demographicInfoReducer,
  educationInfo: educationInfoReducer,
  experienceInfo: experienceInfoReducer,
  eventSearch: eventSearchReducer,
  findALawyerSearch: findALawyerSearchReducer,
  form: formReducer,
  honorsInfo: honorsInfoReducer,
  lawSchoolInfo: lawSchoolInfoReducer,
  linksInfo: linksInfoReducer,
  marketingPreferencesInfo: marketingPreferencesInfoReducer,
  mediaInfo: mediaInfoReducer,
  overviewInfo: overviewInfoReducer,
  personalInfo: personalInfoReducer,
  practiceAreaInfo: practiceAreaReducer,
  topicOfInterestInfo: topicOfInterestsReducer,
  memberBenefitsInfo: memberBenefitsReducer,
  eNewsLettersInfo: eNewsLettersReducer,
  practiceLibrarySearch: practiceLibrarySearchReducer,
  sections: sectionsReducer,
  siteSearch: siteSearchReducer,
  socialMediaInfo: socialMediaInfoReducer,
  supremeCourtNumber: supremeCourtNumberReducer,
  ebarHomePageInfo: eBarHomePageReducer,
});

export default reducer;
