import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import FocusTrap from 'focus-trap-react';
import classNames from 'classnames';

import {
  breakpoints,
  colors,
  typography,
} from '@style-guide/config';
import Angle from '@style-guide/mixins/Angle';
import Link from '@style-guide/mixins/Link';

import LinkGroup from '@components/LinkGroup';

import SubMenu from './SubMenu';

const NavItemContainer = styled.li`
  font-size: ${rem('13px', typography.baseFontSize)};
  padding: .6rem 1rem .6rem 0;
  position: relative;

  > a {
    ${Link({ type: 'white' })}

    &:hover {
      box-shadow: 0 2px 0px 0px ${colors.yellow};
    }
  }

  section {
    right: -300px;

    @media (min-width: ${breakpoints.xl}) {
      right: -225px;
    }
  }
`;

NavItemContainer.displayName = 'NavItemContainer';

const SubMenuToggle = styled.button`
  ${Angle()}

  color: ${colors.white};
  margin-top: -2px;
  padding: 0;

  .open &,
  &:hover {
    box-shadow: 0 2px 0px 0px ${colors.yellow};
  }
`;

SubMenuToggle.displayName = 'SubMenuToggle';

const SubMenuContent = styled.div`
  padding: 20px;
`;

SubMenuContent.displayName = 'SubMenuContent';

const SubmenuLinks = styled.div`
  display: flex;

  ul:first-child {
    padding-left: 0;
  }
`;

SubmenuLinks.displayName = 'SubmenuLinks';

class NavItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.handleEscape = this.handleEscape.bind(this);
    this.navLinkGroups = [this.props.links];

    while (this.navLinkGroups[this.navLinkGroups.length - 1].length > 5) {
      this.navLinkGroups.push(this.navLinkGroups[this.navLinkGroups.length - 1].splice(5));
    }

    // Create a ref
    this.subMenuToggleRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.state.isOpen) {
      document.addEventListener('keydown', this.handleEscape, true);
    }
  }

  handleClickOutside(e) {
    // Access the DOM node using ref
    if (
      this.subMenuToggleRef != null &&
      this.subMenuToggleRef.current != null &&
      !this.subMenuToggleRef.current.contains(e.target)
    ) {
      this.setState({
        isOpen: false,
      });
    }
  }

  toggleOpenState() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleEscape({ keyCode, key }) {
    if (keyCode === 27 || key === 'Escape') {
      this.setState({
        isOpen: false,
      });
    }
  }

  render() {
    const { itemIndex } = this.props;

    const navItemClassNames = classNames({
      open: this.state.isOpen,
    });

    const renderSubMenuNavItem = () => {
      const navLinksGroupsWithIDs = this.navLinkGroups.map((navLinkGroup, index) => ({
        id: index,
        links: navLinkGroup,
      }));

      const utilityLinkGroups = navLinksGroupsWithIDs.map(navLinkGroup => (
        <LinkGroup
          key={navLinkGroup.id}
          links={navLinkGroup.links}
        />
      ));

      const content = (
        <SubMenuContent>
          <p>
            {this.props.description}
          </p>
          <SubmenuLinks>
            {utilityLinkGroups}
          </SubmenuLinks>
        </SubMenuContent>
      );

      return (
        <NavItemContainer
          className={navItemClassNames}
        >
          <FocusTrap
            active={this.state.isOpen}
          >
            <SubMenuToggle
              ref={this.subMenuToggleRef} // Pass the ref to the element
              onClick={this.toggleOpenState}
              aria-expanded={this.state.isOpen}
              aria-haspopup="true"
              title={this.props.title}
              id={`desktopHeaderUtilityNavItemToggle${itemIndex}`}
            >
              {this.props.text}
            </SubMenuToggle>
            {this.state.isOpen && (
              <SubMenu
                heading={this.props.text}
                content={content}
                id={`desktopHeaderUtilityNavItemContent${itemIndex}`}
              />
            )}
          </FocusTrap>
        </NavItemContainer>
      );
    };

    const renderSimpleNavItem = () => (
      <NavItemContainer>
        <a
          href={this.props.href}
          title={this.props.title}
          id={`desktopHeaderUtilityNavItem${itemIndex}`}
        >
          {this.props.text}
        </a>
      </NavItemContainer>
    );

    return (this.props.links.length) ? renderSubMenuNavItem() : renderSimpleNavItem();
  }
}

NavItem.propTypes = {
  description: PropTypes.string,
  href: PropTypes.string,
  links: PropTypes.arrayOf(Object),
  title: PropTypes.string,
  text: PropTypes.string,
  itemIndex: PropTypes.number.isRequired,
};

NavItem.defaultProps = {
  description: '',
  href: '/',
  links: [],
  title: '',
  text: '',
};

NavItem.displayName = 'NavItem';

export default NavItem;
