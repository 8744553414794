import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { saveEnewsLettersInfo } from '@redux/modules/CommunicationPreferences/ENewsLettersInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import ENewsLettersInfoForm from './components/ENewsLettersInfoForm';

const HeadingDiv = styled.div`
  background: #263746;
  color: #FFF;
  font-family: 'proxima-nova', 'Helvetica Nueue', sans-serif;
  font-weight: 500;
  padding: 12px;
`;

HeadingDiv.displayName = 'HeadingDiv';

class ENewsLettersInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPracticeAreas: false,
      showCourts: false,
    };

    this.submit = this.submit.bind(this);
    this.handlePracticeAreas = this.handlePracticeAreas.bind(this);
    this.handleCourts = this.handleCourts.bind(this);
    this.handleEnewsLettersSubscription = this.handleEnewsLettersSubscription.bind(this);
  }

  submit(formValues) {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    // since the Redux checkbox group requires just an array of strings, that's all we get back
    // to determine which ones are checked.  So we need to filter through all the practice areas
    // and look to see which items are in the array of strings (meaning the user has checked them)
    const selectedAreasOfLaw = this.props.eBarAreasOfLawList.filter(item =>
      formValues.PracticeAreasSelected.indexOf(item.name) > -1);
    const selectedCourts = this.props.eBarCourtsList.filter(item =>
      formValues.CourtsSelected.indexOf(item.name) > -1);
    const finalFormValues = {
      ...formValues,
      PracticeAreasSelected: selectedAreasOfLaw,
      CourtsSelected: selectedCourts,
    };
    this.setState({
      showPracticeAreas: false,
      showCourts: false,
    });
    this.props.dispatch(saveEnewsLettersInfo(finalFormValues));
  }

  handlePracticeAreas(value) {
    this.setState({
      showPracticeAreas: value || !this.state.showPracticeAreas,
    });
  }

  handleCourts(value) {
    this.setState({
      showCourts: value || !this.state.showCourts,
    });
  }

  handleEnewsLettersSubscription() {
    this.setState({
      showPracticeAreas: false,
      showCourts: false,
    });
  }

  render() {
    const {
      loading,
      eNewsLettersInfo,
      eBarAreasOfLawList,
      eBarCourtsList,
      title,
      isMember,
      labelContent,
      dispatch,
    } = this.props;

    const {
      showPracticeAreas,
      showCourts,
    } = this.state;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormContainer>
        {loading && <LoadingOverlay />}
        <HeadingDiv id="Enewsletters">{title}</HeadingDiv>
        <ENewsLettersInfoForm
          initialValues={eNewsLettersInfo}
          onSubmit={this.submit}
          handleEnewsLettersSubscription={this.handleEnewsLettersSubscription}
          handlePracticeAreas={this.handlePracticeAreas}
          handleCourts={this.handleCourts}
          loading={loading}
          hasSaveError={!!error}
          isMember={isMember}
          eBarAreasOfLawList={eBarAreasOfLawList}
          eBarCourtsList={eBarCourtsList}
          labelContent={labelContent}
          showPracticeAreas={showPracticeAreas}
          showCourts={showCourts}
          dispatch={dispatch}
        />
        {error && (
          <ErrorMessage>
            <span>{error}</span>
          </ErrorMessage>
        )}
      </FormContainer>
    );
  }
}

ENewsLettersInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  eNewsLettersInfo: PropTypes.shape({
    eNewsLettersGreenBook: PropTypes.bool,
    frequencyGreenBook: PropTypes.number,
    allPracticeAreasSelected: PropTypes.string,
    allCourtsSelected: PropTypes.string,
    PracticeAreasSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
    CourtsSelected: PropTypes.arrayOf(PropTypes.string).isRequired,
    eNewsLettersBriefs: PropTypes.bool,
  }).isRequired,
  eBarAreasOfLawList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  eBarCourtsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  title: PropTypes.string.isRequired,
  isMember: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  labelContent: PropTypes.string,
};

ENewsLettersInfoContent.defaultProps = {
  error: '',
  loading: false,
  labelContent: '',
};

const mapStateToProps = ({ eNewsLettersInfo }) => ({
  error: eNewsLettersInfo.error,
  loading: eNewsLettersInfo.loading,
  eNewsLettersInfo,
});

ENewsLettersInfoContent.displayName = 'ENewsLettersInfoContent';

export default connect(mapStateToProps)(ENewsLettersInfoContent);
