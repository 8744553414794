import React from 'react';
import Loadable from 'react-loadable';
import ShellLoading from '@components/ShellLoading';

const InlinePdfLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "InlinePdf" */ './InlinePdf'),
  loading() {
    return <ShellLoading />;
  },
});

const InlinePdfAsync = props => <InlinePdfLoadable {...props} />;

export default InlinePdfAsync;
