import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const OptionsInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  z-index: 2;

  @media(min-width: ${breakpoints.xl}) {
    max-height: 170px;
    max-width: 700px;
  }
`;

OptionsInner.displayName = 'OptionsInner';

export default OptionsInner;
