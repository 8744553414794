import styled from 'styled-components';
import { breakpoints, colors } from '@style-guide/config';

const ShoppingCartItemsContainer = styled.div`
  background: ${colors.white};
  position: relative;

  > * {
    padding: 20px;
  }

  ul {
    border-bottom: 1px solid ${colors.gray};
  }

  @media (min-width: ${breakpoints.l}) {
    background: transparent;
    padding: 10px 30px;
    width: 70vw;

    > * {
      padding: 20px 0;
    }
  }
`;

ShoppingCartItemsContainer.displayName = 'ShoppingCartItemsContainer';

export default ShoppingCartItemsContainer;
