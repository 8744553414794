import React from 'react';

import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { colors, typography } from '@style-guide/config';

import Arrow from '@style-guide/mixins/Arrow';

const arrowSettings = {
  arrow: 'up',
  position: 'after',
  color: colors.red,
};

const LinkContainer = styled.div`
  margin: 8px 0px;
  display: flex;
  justify-content: flex-end;

  a {
    ${Arrow(arrowSettings)};

    align-items: center;
    color: ${colors.black};
    display: flex;
    font-size: ${rem('16px', typography.baseFontSize)};
    font-weight: 700;
    line-height: 1.5em;
    margin: 15px 0;
    transition: transform 200ms;

    &:hover,
    &:active {
      color: ${colors.darkRed};
      transform: scale(1.0125);
    }

    &::after {
      margin: 0 30px 0 15px;
    }
  }
`;

LinkContainer.displayName = 'LinkContainer';

const BackToTopLink = () => (
  <LinkContainer>
    <a href="#top">Back to Top</a>
  </LinkContainer>
);

export default BackToTopLink;
