import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import is from 'is_js';
import TextArea from '@style-guide/components/TextArea';
import LoadingOverlay from '@components/LoadingOverlay';
import Divider from '@components/Divider';
import ErrorMessage from '@components/ErrorMessage';
import styled from 'styled-components';
import BlockWrapper from './components/BlockWrapper';
import Wrapper from './components/Wrapper';
import TotalCasesPerPracticeArea from './components/TotalCasesPerPracticeArea/TotalCasesPerPracticeArea';
import EBarTopics from './components/EBarTopics/EBarTopics';
import CasesWithPracticeArea from './components/CasesWithPracticeArea/CasesWithPracticeArea';
import EBarEnactments from './components/EBarEnactments/EBarEnactments';
import EBarSCOAnnouncements from './components/EBarSCOAnnouncements/EBarSCOAnnouncements';
import EBarBoardOfProfessionals from './components/EBarBoardOfProfessionals/EBarBoardOfProfessionals';
import EBarAttorneys from './components/EBarAttorneys/EBarAttorneys';
import EBarEthics from './components/EBarEthics/EBarEthics';
import EBarOtherAnnouncements from './components/EBarOtherAnnouncements/EBarOtherAnnouncements';
import EBarJobs from './components/EBarJobs/EBarJobs';
import EBarJobsResultDiv from './components/EBarJobs/components/EBarJobsResultDiv';
import ResultDiv from './components/shared/components/ResultDiv';
import YellowLine from './components/shared/components/YellowLine';
import LinkAnchor from './components/shared/components/LinkAnchor';
import AngleSpan from './components/shared/components/AngleSpan';

const BlockDiv = styled.div`
  position: relative;
  margin-bottom: 20px;
  width: 100%;
`;

BlockDiv.displayName = 'BlockDiv';

const BlockDivWithoutMargin = styled.div`
  position: relative;
  width: 100%;
`;

BlockDivWithoutMargin.displayName = 'BlockDivWithoutMargin';

const BlockTitleDiv = styled.div`
  background-image: url('http://cdn.mcauto-images-production.sendgrid.net/8c8886a44801d85b/a2c219f8-3033-4773-afb5-4571fb6ed6d9/1200x300.jpg');
  background-repeat: round;
  width: 100%;
  height: 45px;
  border: 0px;
  font-size: 15px;
  line-height: 18px;
  color: #555555;
  margin: auto;
`;

BlockTitleDiv.displayName = 'BlockTitleDiv';

const BlockTitleIcon = styled.div`
  float: left;
  display: inline-block;
`;

BlockTitleIcon.displayName = 'BlockTitleIcon';

const BlockTitle = styled.div`
  padding-left: 72px;
  font-size: 18px;
  font-weight: 900;
  position: relative;
  color: rgb(255, 255, 255);
  height: 45px;
`;

BlockTitle.displayName = 'BlockTitle';

const BlockClear = styled.div`
  clear: both;
`;

BlockClear.displayName = 'BlockClear';

const BlockHeading = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

BlockHeading.displayName = 'BlockHeading';

const EBarHeading = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${props => (props.color ? props.color : '#C3002F')};
`;

EBarHeading.displayName = 'EBarHeading';

const EBarOSBAHeading = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #000;
`;

EBarOSBAHeading.displayName = 'EBarOSBAHeading';

const OSBAUpdateLeftBlock = styled.div`
  float: left;
  width: 40%;
  position: absolute;
  top: calc(40% - 20px);
  padding-left: 20px;
`;

OSBAUpdateLeftBlock.displayName = 'OSBAUpdateLeftBlock';

const OSBAUpdateRightBlock = styled.div`
  float: left;
  width: 60%;
  transform: translateX(67%);
`;

OSBAUpdateRightBlock.displayName = 'OSBAUpdateRightBlock';

const yellowLine = styled.img`
  width: 30px;
  margin-bottom: 5px;
`;

yellowLine.displayName = 'yellowLine';

const AreasTopicsBlockDiv = styled.div`
  background-color: #EFEFEF;
  padding: 20px 0px;
`;

AreasTopicsBlockDiv.displayName = 'AreasTopicsBlockDiv';

const AreasTopicsBlockContentDiv = styled.div`
  width: 80%;
  margin: 0px auto;
`;

AreasTopicsBlockContentDiv.displayName = 'AreasTopicsBlockContentDiv';

const TotalCasesDiv = styled.div`
  display: table;
  padding: 4px 10px;

  @media (min-width: 1025px) {
  }

  @media (max-width: 1024px) {
    display: flow-root;
    width: 100%;
  }
`;

TotalCasesDiv.displayName = 'TotalCasesDiv';

const OuterDivAreasOfLaw = styled.div`
  padding-top: 14px;

  @media (max-width: 1024px) {
    text-align: center;
  }
`;

OuterDivAreasOfLaw.displayName = 'OuterDivAreasOfLaw';

const AreasTopicsHr = styled.hr`
  width: 100%;
  background-color: #C3002F;
  border: 0px;
  margin-top: ${props => (props.$marginTop ? props.$marginTop : '0px')};
  margin-bottom: 20px;
`;

AreasTopicsHr.displayName = 'AreasTopicsHr';

/* eslint-disable */
const convertReplaceString = (summaryString) => {
  if (!summaryString || !summaryString.summary) {
    return summaryString;
  }
  summaryString.summary = summaryString.summary.replaceAll('&lt;', '<');
  summaryString.summary = summaryString.summary.replaceAll('&gt;', '>');

  return summaryString;
};

const convertReplaceCapsString = (summaryString) => {
  if (!summaryString || !summaryString.Summary) {
    return summaryString;
  }
  summaryString.Summary = summaryString.Summary.replaceAll('&lt;', '<');
  summaryString.Summary = summaryString.Summary.replaceAll('&gt;', '>');
  return summaryString;
};

const returnOpinionsCount = (casesState) => {
  let total = 0;
  casesState.map(cases => total += cases.casesCount);
  return total;
};
/* eslint-enable */

const EBarHomeContent = ({
  casesState = [],
  eBarEnactmentsListsState = [],
  eBarSCOCaseAnnouncementsListsState = [],
  eBarSCOAdministrativeListsState = [],
  eBarSCOOhioRulesListsState = [],
  eBarBoardOfProfessionalsListsState = [],
  eBarAttorneysListsState = [],
  eBarEthicsListsState = [],
  eBarOtherAnnouncementsListsState = [],
  eBarJobsListsState = [],
  eBarShowSCOAnnouncementState,
  eBarTotalSCOAnnouncementState,
  loading,
  error = '',
  featureRibbonHeadline = '',
  featureRibbonContent = '',
  featureCTALabel = '',
  randNum,
  baseUrl,
}) => (
  <BlockWrapper>
    {loading && <LoadingOverlay />}
    <Wrapper>
      {((casesState !== undefined && is.not.null(casesState) && casesState.length > 0) ||
        (eBarEnactmentsListsState !== undefined && is.not.null(eBarEnactmentsListsState) &&
          eBarEnactmentsListsState.length > 0) ||
          (eBarTotalSCOAnnouncementState !== undefined &&
          is.not.null(eBarTotalSCOAnnouncementState) &&
          eBarTotalSCOAnnouncementState > 0) ||
                (eBarBoardOfProfessionalsListsState !== undefined &&
                    is.not.null(eBarBoardOfProfessionalsListsState) &&
                    eBarBoardOfProfessionalsListsState.length > 0) ||
                (eBarAttorneysListsState !== undefined &&
                    is.not.null(eBarAttorneysListsState) &&
                    eBarAttorneysListsState.length > 0) ||
                (eBarEthicsListsState !== undefined && is.not.null(eBarEthicsListsState) &&
                    eBarEthicsListsState.length > 0) ||
                (eBarOtherAnnouncementsListsState !== undefined &&
                    is.not.null(eBarOtherAnnouncementsListsState) &&
                    eBarOtherAnnouncementsListsState.length > 0) ||
                (eBarJobsListsState !== undefined &&
                    is.not.null(eBarJobsListsState) &&
                eBarJobsListsState.length > 0)) && (
                <BlockDivWithoutMargin>
                  <AreasTopicsBlockDiv>
                    <AreasTopicsBlockContentDiv>
                      {casesState !== undefined && is.not.null(casesState) &&
                        casesState.length > 0 && (
                        <div>
                          <center>
                            <YellowLine />
                            <EBarHeading color="#000">
                              AREAS OF LAW
                            </EBarHeading>

                            <OuterDivAreasOfLaw>
                              <center>
                                {casesState.map(c => (
                                  <TotalCasesDiv>
                                    <TotalCasesPerPracticeArea
                                      key={c.practiceAreaId}
                                      caseWithPracticeArea={c}
                                    />
                                  </TotalCasesDiv>
                                ))}
                              </center>
                            </OuterDivAreasOfLaw>
                            <Divider
                              margin="20px 0px"
                              hasDecorator={false}
                            />
                          </center>
                        </div>
                      )}
                      <center>
                        <YellowLine />
                        <EBarHeading color="#000">
                          TOPICS
                        </EBarHeading>
                        <EBarTopics
                          totalOpinions={casesState !== undefined &&
                              is.not.null(casesState) ? returnOpinionsCount(casesState) : 0}
                          totalLegislativeEnactments={eBarEnactmentsListsState !== undefined &&
                              is.not.null(eBarEnactmentsListsState) ?
                            eBarEnactmentsListsState.length : 0}
                          totalSCOAnnounements={eBarTotalSCOAnnouncementState !== undefined &&
                              is.not.null(eBarTotalSCOAnnouncementState) ?
                            eBarTotalSCOAnnouncementState : 0}
                          totalBoardOfProfessionals={eBarBoardOfProfessionalsListsState !==
                              undefined &&
                              is.not.null(eBarBoardOfProfessionalsListsState) ?
                            eBarBoardOfProfessionalsListsState.length : 0}
                          totalAttorneyGenerals={eBarAttorneysListsState !== undefined &&
                              is.not.null(eBarAttorneysListsState) ?
                            eBarAttorneysListsState.length : 0}
                          totalEthics={eBarEthicsListsState !== undefined &&
                              is.not.null(eBarEthicsListsState) ?
                            eBarEthicsListsState.length : 0}
                          totalOtherAnnouncements={eBarOtherAnnouncementsListsState !==
                              undefined && is.not.null(eBarOtherAnnouncementsListsState) ?
                            eBarOtherAnnouncementsListsState.length : 0}
                          totalJobs={eBarJobsListsState !== undefined &&
                              is.not.null(eBarJobsListsState) ? eBarJobsListsState.length : 0}
                        />
                      </center>
                    </AreasTopicsBlockContentDiv>
                  </AreasTopicsBlockDiv>
                  <AreasTopicsHr size="7" />
                </BlockDivWithoutMargin>
      )}
      {((featureRibbonHeadline !== undefined && is.not.null(featureRibbonHeadline)) ||
                (featureRibbonContent !== undefined && is.not.null(featureRibbonContent))) && (
                <BlockDivWithoutMargin>
                  <OSBAUpdateLeftBlock>
                    <YellowLine />
                    <EBarHeading>
                      MEMBER UPDATES
                    </EBarHeading>
                  </OSBAUpdateLeftBlock>
                  <OSBAUpdateRightBlock>
                    {featureRibbonHeadline !== undefined &&
                            is.not.null(featureRibbonHeadline) && (
                            <div>
                              <YellowLine />
                              <EBarOSBAHeading>{featureRibbonHeadline}</EBarOSBAHeading>
                              <br />
                            </div>
                    )}
                    {featureRibbonContent !== undefined &&
                            is.not.null(featureRibbonContent) && (
                            <div>
                              <TextArea
                                htmlString={featureRibbonContent}
                              />
                            </div>
                    )}
                    {featureCTALabel && (
                      <div>
                        <AngleSpan>
                          &gt;
                        </AngleSpan>
                        <LinkAnchor
                          href="#MembershipCenter"
                        >
                          {featureCTALabel}
                        </LinkAnchor>
                      </div>
                    )}
                  </OSBAUpdateRightBlock>
                  <BlockClear />
                  <AreasTopicsHr size="7" $marginTop="20px" />
                </BlockDivWithoutMargin>
      )}
      <BlockDiv>
        {/* eslint-disable */}
        <a href={baseUrl + '/content/ebar_default_atf_middletop.htm'}>
          <img src={'https://pubads.g.doubleclick.net/gampad/ad?iu=/81541499/Greenbook_Website_TopBanner_728x90&sz=728x90&c=' + randNum} width="100%" alt="" border="0" />
        </a>
        {/* eslint-enable */}
      </BlockDiv>
      {casesState !== undefined && is.not.null(casesState) && casesState.length > 0 && (
      <BlockDiv>
        <BlockTitleDiv id="opinions">
          <BlockTitleIcon>
            <img src="http://cdn.mcauto-images-production.sendgrid.net/8c8886a44801d85b/c38ea779-09ef-4b3d-9f93-7560064049c6/232x151.png" alt="" width="68" />
          </BlockTitleIcon>
          <BlockTitle>
            <BlockHeading>
              OPINIONS
            </BlockHeading>
          </BlockTitle>
          <BlockClear />
        </BlockTitleDiv>
        {casesState.map((c, index) => (
          <CasesWithPracticeArea
            key={c.practiceAreaId}
            caseWithPracticeArea={c}
            showDivider={index !== casesState.length - 1}
          />
        ))}
      </BlockDiv>
      )}
      {eBarEnactmentsListsState !== undefined &&
         is.not.null(eBarEnactmentsListsState) && eBarEnactmentsListsState.length > 0 && (
         <BlockDiv>
           <BlockTitleDiv id="enactments">
             <BlockTitleIcon>
               <img src="http://cdn.mcauto-images-production.sendgrid.net/8c8886a44801d85b/6fc145fd-c889-4550-ba87-1c9f5a81c8fb/232x151.png" alt="" width="68" />
             </BlockTitleIcon>
             <BlockTitle>
               <BlockHeading>
                 LEGISLATIVE ENACTMENTS
               </BlockHeading>
             </BlockTitle>
             <BlockClear />
           </BlockTitleDiv>
           {eBarEnactmentsListsState.map(c => (
             <EBarEnactments
               key={c.contentGuidString}
               eBarEnactmentsLists={convertReplaceString(c)}
             />
           ))}
           <EBarJobsResultDiv>
             To track pending legislation, visit the&nbsp;
             <LinkAnchor
               href="https://www.ohiobar.org/advocacy/practice-area-updates/"
               target="_blank"
               rel="noopener noreferrer"
             >
               OSBA’s legislative updates
             </LinkAnchor>&nbsp;by practice area.
           </EBarJobsResultDiv>
         </BlockDiv>
      )}
      {eBarShowSCOAnnouncementState && ((is.not.null(eBarSCOCaseAnnouncementsListsState) &&
        eBarSCOCaseAnnouncementsListsState.length > 0) ||
        (is.not.null(eBarSCOAdministrativeListsState) &&
        eBarSCOAdministrativeListsState.length > 0) ||
        (is.not.null(eBarSCOOhioRulesListsState) &&
        eBarSCOOhioRulesListsState.length > 0)) && (
        <BlockDiv>
          <BlockTitleDiv id="scoannouncements">
            <BlockTitleIcon>
              <img src="http://cdn.mcauto-images-production.sendgrid.net/8c8886a44801d85b/8151aa0f-95f8-4fac-9ed0-5cb4d321ac7a/232x151.png" alt="" width="68" />
            </BlockTitleIcon>
            <BlockTitle>
              <BlockHeading>
                SUPREME COURT OF OHIO ANNOUNCEMENTS
              </BlockHeading>
            </BlockTitle>
            <BlockClear />
          </BlockTitleDiv>
          {eBarShowSCOAnnouncementState && (
            <ResultDiv>
              {is.not.null(eBarSCOCaseAnnouncementsListsState) &&
                        eBarSCOCaseAnnouncementsListsState.length > 0 && (
                        <EBarHeading>CASE ANNOUNCEMENTS</EBarHeading>
              )}
              {is.not.null(eBarSCOCaseAnnouncementsListsState) &&
                        eBarSCOCaseAnnouncementsListsState.length > 0 &&
                        eBarSCOCaseAnnouncementsListsState.map(c => (
                          <EBarSCOAnnouncements
                            key={c.contentGuidString}
                            eBarSCOAnnouncementsLists={convertReplaceString(c)}
                          />
                        ))}
              {is.not.null(eBarSCOCaseAnnouncementsListsState) &&
                        eBarSCOCaseAnnouncementsListsState.length > 0 &&
                        is.not.null(eBarSCOAdministrativeListsState) &&
                        eBarSCOAdministrativeListsState.length > 0 && (
                        <Divider
                          margin="14px 0px 28px"
                          hasDecorator={false}
                        />
              )}
              {is.not.null(eBarSCOAdministrativeListsState) &&
                        eBarSCOAdministrativeListsState.length > 0 && (
                        <EBarHeading>ADMINISTRATIVE ACTIONS</EBarHeading>
              )}
              {is.not.null(eBarSCOAdministrativeListsState) &&
                        eBarSCOAdministrativeListsState.length > 0 &&
                        eBarSCOAdministrativeListsState.map(c => (
                          <EBarSCOAnnouncements
                            key={c.contentGuidString}
                            eBarSCOAnnouncementsLists={convertReplaceString(c)}
                          />
                        ))}
              {((is.not.null(eBarSCOAdministrativeListsState) &&
                        eBarSCOAdministrativeListsState.length > 0 &&
                        is.not.null(eBarSCOOhioRulesListsState) &&
                        eBarSCOOhioRulesListsState.length > 0) ||
                        (is.not.null(eBarSCOCaseAnnouncementsListsState) &&
                            eBarSCOCaseAnnouncementsListsState.length > 0 &&
                            is.not.null(eBarSCOOhioRulesListsState) &&
                            eBarSCOOhioRulesListsState.length > 0)) && (
                            <Divider
                              margin="14px 0px 28px"
                              hasDecorator={false}
                            />
              )}
              {is.not.null(eBarSCOOhioRulesListsState) &&
                        eBarSCOOhioRulesListsState.length > 0 && (
                        <EBarHeading>RULES OF COURT</EBarHeading>
              )}
              {is.not.null(eBarSCOOhioRulesListsState) &&
                        eBarSCOOhioRulesListsState.length > 0 &&
                        eBarSCOOhioRulesListsState.map(c => (
                          <EBarSCOAnnouncements
                            key={c.contentGuidString}
                            eBarSCOAnnouncementsLists={convertReplaceString(c)}
                          />
                        ))}
            </ResultDiv>
          )}
        </BlockDiv>
      )}
      {eBarBoardOfProfessionalsListsState !== undefined &&
        is.not.null(eBarBoardOfProfessionalsListsState) &&
        eBarBoardOfProfessionalsListsState.length > 0 && (
        <BlockDiv>
          <BlockTitleDiv id="professconadopi">
            <BlockTitleIcon>
              <img src="http://cdn.mcauto-images-production.sendgrid.net/8c8886a44801d85b/f7b1c95d-c328-483b-9fd6-071b0c329a89/232x151.png" alt="" width="68" />
            </BlockTitleIcon>
            <BlockTitle>
              <BlockHeading>
                BOARD OF PROFESSIONAL CONDUCT ADVISORY OPINIONS
              </BlockHeading>
            </BlockTitle>
            <BlockClear />
          </BlockTitleDiv>
          {eBarBoardOfProfessionalsListsState.map(c => (
            <EBarBoardOfProfessionals
              key={c.contentGuidString}
              eBarBoardOfProfessionalsLists={convertReplaceString(c)}
            />
          ))}
        </BlockDiv>
      )}
      {eBarAttorneysListsState !== undefined &&
        is.not.null(eBarAttorneysListsState) &&
        eBarAttorneysListsState.length > 0 && (
        <BlockDiv>
          <BlockTitleDiv id="attgenopi">
            <BlockTitleIcon>
              <img src="http://cdn.mcauto-images-production.sendgrid.net/8c8886a44801d85b/22f85026-7f21-4d9a-946d-2741a23d8451/232x151.png" alt="" width="68" />
            </BlockTitleIcon>
            <BlockTitle>
              <BlockHeading>
                ATTORNEY GENERAL OPINIONS
              </BlockHeading>
            </BlockTitle>
            <BlockClear />
          </BlockTitleDiv>
          {eBarAttorneysListsState.map(c => (
            <EBarAttorneys
              key={c.contentGuidString}
              eBarAttorneysLists={convertReplaceString(c)}
            />
          ))}
        </BlockDiv>
      )}
      {eBarEthicsListsState !== undefined &&
        is.not.null(eBarEthicsListsState) &&
        eBarEthicsListsState.length > 0 && (
        <BlockDiv>
          <BlockTitleDiv id="ethicscommopi">
            <BlockTitleIcon>
              <img src="http://cdn.mcauto-images-production.sendgrid.net/8c8886a44801d85b/4a2de118-6ff4-4757-9160-a0f87790348d/232x151.png" alt="" width="68" />
            </BlockTitleIcon>
            <BlockTitle>
              <BlockHeading>
                OHIO ETHICS COMMISSION ADVISORY OPINIONS
              </BlockHeading>
            </BlockTitle>
            <BlockClear />
          </BlockTitleDiv>
          {eBarEthicsListsState.map(c => (
            <EBarEthics
              key={c.contentGuidString}
              eBarEthicsLists={convertReplaceString(c)}
            />
          ))}
        </BlockDiv>
      )}
      {eBarOtherAnnouncementsListsState !== undefined &&
        is.not.null(eBarOtherAnnouncementsListsState) &&
        eBarOtherAnnouncementsListsState.length > 0 && (
        <BlockDiv>
          <BlockTitleDiv id="otherannounce">
            <BlockTitleIcon>
              <img src="http://cdn.mcauto-images-production.sendgrid.net/8c8886a44801d85b/e3ee3773-8b06-47b9-8c9a-42cb83455e8d/232x151.png" alt="" width="68" />
            </BlockTitleIcon>
            <BlockTitle>
              <BlockHeading>
                OTHER ANNOUNCEMENTS
              </BlockHeading>
            </BlockTitle>
            <BlockClear />
          </BlockTitleDiv>
          {eBarOtherAnnouncementsListsState.map(c => (
            <EBarOtherAnnouncements
              key={c.contentGuidString}
              eBarOtherAnnouncementsLists={convertReplaceString(c)}
            />
          ))}
        </BlockDiv>
      )}
      {eBarJobsListsState !== undefined &&
        is.not.null(eBarJobsListsState) &&
        eBarJobsListsState.length > 0 && (
        <BlockDiv>
          <BlockTitleDiv id="jobs">
            <BlockTitleIcon>
              <img src="http://cdn.mcauto-images-production.sendgrid.net/8c8886a44801d85b/375fe769-b6af-4d9b-b852-9b5c7d911b36/232x151.png" alt="" width="68" />
            </BlockTitleIcon>
            <BlockTitle>
              <BlockHeading>
                CAREER OPPORTUNITIES
              </BlockHeading>
            </BlockTitle>
            <BlockClear />
          </BlockTitleDiv>
          {eBarJobsListsState.map(c => (
            <EBarJobs
              key={c.contentGuidString}
              eBarJobsLists={convertReplaceString(c)}
            />
          ))}
          <EBarJobsResultDiv>
            To see more current job openings or to find your next employee, visit the&nbsp;
            <LinkAnchor
              href="https://careercenter.ohiobar.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              OSBA Career Center
            </LinkAnchor>.
          </EBarJobsResultDiv>
        </BlockDiv>
      )}
    </Wrapper>
    {error && (
      <ErrorMessage>
        <span>{error}</span>
      </ErrorMessage>
    )}
  </BlockWrapper>
);

EBarHomeContent.propTypes = {
  casesState: PropTypes.arrayOf(PropTypes.shape({
    nameOfPractice: PropTypes.string,
    practiceAreaId: PropTypes.number,
    casesCount: PropTypes.number,
    viewCases: PropTypes.bool,
    cases: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      summary: PropTypes.string,
      caseName: PropTypes.string,
      webCitation: PropTypes.string,
      casemakerLink: PropTypes.string,
      pDFLink: PropTypes.string,
      practiceId: PropTypes.number,
      courtId: PropTypes.number,
      contentGuidString: PropTypes.string,
      isBookmarked: PropTypes.bool,
    })).isRequired,
  })),
  eBarEnactmentsListsState: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    billNumber: PropTypes.string,
    effectiveDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarSCOCaseAnnouncementsListsState: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeaderString: PropTypes.string,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateTypeString: PropTypes.string,
    summary: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarSCOAdministrativeListsState: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeaderString: PropTypes.string,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateTypeString: PropTypes.string,
    summary: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarSCOOhioRulesListsState: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeaderString: PropTypes.string,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateTypeString: PropTypes.string,
    summary: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarBoardOfProfessionalsListsState: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarAttorneysListsState: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarEthicsListsState: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarOtherAnnouncementsListsState: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    partnerEntity: PropTypes.string,
    postedDateString: PropTypes.string,
    summary: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarJobsListsState: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    company: PropTypes.string,
    location: PropTypes.string,
    activeDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
  })),
  eBarShowSCOAnnouncementState: PropTypes.bool.isRequired,
  eBarTotalSCOAnnouncementState: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  featureRibbonHeadline: PropTypes.string,
  featureRibbonContent: PropTypes.string,
  featureCTALabel: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  randNum: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

const mapStateToProps = ({ ebarHomePageInfo }) => ({
  loading: ebarHomePageInfo.loading,
  error: ebarHomePageInfo.error,
  casesState: ebarHomePageInfo.casesState,
  eBarEnactmentsListsState: ebarHomePageInfo.eBarEnactmentsListsState,
  eBarShowSCOAnnouncementState: ebarHomePageInfo.eBarShowSCOAnnouncementState,
  eBarTotalSCOAnnouncementState: ebarHomePageInfo.eBarTotalSCOAnnouncementState,
  eBarSCOCaseAnnouncementsListsState: ebarHomePageInfo.eBarSCOCaseAnnouncementsListsState,
  eBarSCOAdministrativeListsState: ebarHomePageInfo.eBarSCOAdministrativeListsState,
  eBarSCOOhioRulesListsState: ebarHomePageInfo.eBarSCOOhioRulesListsState,
  eBarBoardOfProfessionalsListsState: ebarHomePageInfo.eBarBoardOfProfessionalsListsState,
  eBarAttorneysListsState: ebarHomePageInfo.eBarAttorneysListsState,
  eBarEthicsListsState: ebarHomePageInfo.eBarEthicsListsState,
  eBarOtherAnnouncementsListsState: ebarHomePageInfo.eBarOtherAnnouncementsListsState,
  eBarJobsListsState: ebarHomePageInfo.eBarJobsListsState,
});

EBarHomeContent.displayName = 'EBarHomeContent';

export default connect(mapStateToProps)(EBarHomeContent);
