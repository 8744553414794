import React from 'react';
import { Provider } from 'react-redux';
import { getAffiliationsInfo } from '@redux/modules/affiliationsInfo';
import Store from '@redux';

import AffiliationsContent from './components/AffiliationsContent';

class AffiliationsInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getAffiliationsInfo());
  }

  render() {
    return (
      <Provider store={this.store}>
        <AffiliationsContent
          {...this.props}
        />
      </Provider>
    );
  }
}

AffiliationsInfo.displayName = 'AffiliationsInfo';

export default AffiliationsInfo;
