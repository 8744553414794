import styled from 'styled-components';

const ResultDiv = styled.div`
  display: block;
  text-align: left;
  padding: 14px 28px;
  font-size: 14px;
  font-weight: normal;
  color: #000;
`;

ResultDiv.displayName = 'ResultDiv';

export default ResultDiv;
