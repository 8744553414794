import styled from 'styled-components';

const DisclaimerText = styled.p`
  font-style: italic;
  margin-bottom: -20px;
  text-align: center;
`;

DisclaimerText.displayName = 'DisclaimerText';

export default DisclaimerText;
