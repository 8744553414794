import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { hydrateEBarHomePageInfo } from '@redux/modules/ebarHomePageInfo';
import Store from '@redux';
import EBarHomeContent from './EBarHomeContent/EBarHomeContent';

class EBarHome extends React.Component {
  constructor(props) {
    super(props);
    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(hydrateEBarHomePageInfo(this.props));
  }

  render() {
    return (
      <Provider store={this.store}>
        <EBarHomeContent
          types={this.props.types}
          featureRibbonHeadline={this.props.featureRibbonHeadline}
          featureRibbonContent={this.props.featureRibbonContent}
          featureCTALabel={this.props.featureCTALabel}
          featureCTAUrl={this.props.featureCTAUrl}
          randNum={this.props.randNum}
          baseUrl={this.props.baseUrl}
        />
      </Provider>
    );
  }
}

EBarHome.propTypes = {
  types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  featureRibbonHeadline: PropTypes.string,
  featureRibbonContent: PropTypes.string,
  featureCTALabel: PropTypes.string,
  featureCTAUrl: PropTypes.string,
  randNum: PropTypes.string.isRequired,
  baseUrl: PropTypes.string.isRequired,
};

EBarHome.defaultProps = {
  featureRibbonHeadline: '',
  featureRibbonContent: '',
  featureCTALabel: '',
  featureCTAUrl: '',
};

EBarHome.displayName = 'EBarHome';

export default EBarHome;
