import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  breakpoints,
  colors,
  typography,
} from '@style-guide/config';

const JobTitle = styled.h4`
  color: ${colors.charcoal};
  display: inline-block;
  flex: 1;
  font-size: ${rem('18px', typography.baseFontSize)};
  font-weight: bold;
  margin-bottom: 10px;
  min-width: 159px;

  @media (min-width: ${breakpoints.m}) {
    margin-bottom: 0;
  }
`;

JobTitle.displayName = 'JobTitle';

export default JobTitle;
