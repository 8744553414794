import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  breakpoints,
  typography,
} from '@style-guide/config';

const Subtitle = styled.h3`
  font-size: ${rem('20px', typography.baseFontSize)};
  line-height: 1em;
  max-width: 450px;

  @media (min-width: ${breakpoints.m}) {
    font-size: ${rem('24px', typography.baseFontSize)};
  }
`;

Subtitle.displayName = 'Subtitle';

export default Subtitle;
