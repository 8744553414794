import styled from 'styled-components';
import { rem } from 'polished';

import { typography } from '@style-guide/config';

const Publication = styled.span`
  display: block;
  font-size: ${rem('14px', typography.baseFontSize)};
  font-style: italic;
`;

Publication.displayName = 'Publication';

export default Publication;
