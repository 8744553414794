import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import styled from 'styled-components';
import ResultDiv from '../shared/components/ResultDiv';
import AngleSpan from '../shared/components/AngleSpan';
import LinkAnchor from '../shared/components/LinkAnchor';

const TitleDiv = styled.div`
  font-size : 16px;
  font-weight : bold;
  line-height : 24px;
`;

TitleDiv.displayName = 'TitleDiv';

const CompanyDiv = styled.div`
`;

CompanyDiv.displayName = 'CompanyDiv';

const ColDiv = styled.div`
  width : 100%;
`;

ColDiv.displayName = 'ColDiv';

const RowDiv = styled.div`
  width : 100%;
`;

RowDiv.displayName = 'RowDiv';

const CaseSpan = styled.span`
  color : #024B3A;
  font-size : 14px;
  line-height : 24px;
  font-weight : normal;
`;

CaseSpan.displayName = 'CaseSpan';

const EBarJobs = ({
  eBarJobsLists,
}) => (
  <ResultDiv>
    <RowDiv>
      <ColDiv>
        <TitleDiv>
          {eBarJobsLists.title !== undefined && is.not.null(eBarJobsLists.title) &&
              eBarJobsLists.title.trim().length > 0 && eBarJobsLists.title
          }
          {eBarJobsLists.title !== undefined && is.not.null(eBarJobsLists.title) &&
               eBarJobsLists.title.trim().length > 0 && eBarJobsLists.location !== undefined
               && is.not.null(eBarJobsLists.location) &&
               eBarJobsLists.location.trim().length > 0 && (
               <CaseSpan>&nbsp;|&nbsp;</CaseSpan>
          )}
          {eBarJobsLists.location !== undefined && is.not.null(eBarJobsLists.location) &&
              eBarJobsLists.location.trim().length > 0 && (
              <CaseSpan>{eBarJobsLists.location}</CaseSpan>
          )}
        </TitleDiv>
      </ColDiv>
      <ColDiv>
        <CompanyDiv>
          {eBarJobsLists.company !== undefined && is.not.null(eBarJobsLists.company) &&
           eBarJobsLists.company.trim().length > 0 && eBarJobsLists.company
          }
          {((eBarJobsLists.ctaUrl !== undefined && is.not.null(eBarJobsLists.ctaUrl) &&
          eBarJobsLists.ctaUrl.trim().length > 0) ||
          (eBarJobsLists.ctaLabel !== undefined && is.not.null(eBarJobsLists.ctaLabel) &&
          eBarJobsLists.ctaLabel.trim().length > 0)) && (
            <span>
              &nbsp;&nbsp;
              <AngleSpan>
                &gt;
              </AngleSpan>
              <LinkAnchor
                href={(eBarJobsLists.ctaUrl !== undefined && is.not.null(eBarJobsLists.ctaUrl) &&
                    eBarJobsLists.ctaUrl.trim().length > 0) ?
                  eBarJobsLists.ctaUrl : eBarJobsLists.ctaLabel}
                target="_blank"
                rel="noopener noreferrer"
              >
                {(eBarJobsLists.ctaLabel !== undefined && is.not.null(eBarJobsLists.ctaLabel) &&
                  eBarJobsLists.ctaLabel.trim().length > 0) ?
                  eBarJobsLists.ctaLabel : eBarJobsLists.ctaUrl}
              </LinkAnchor>
            </span>
          )}
        </CompanyDiv>
      </ColDiv>
    </RowDiv>
  </ResultDiv>
);

EBarJobs.propTypes = {
  eBarJobsLists: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    company: PropTypes.string,
    location: PropTypes.string,
    activeDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
  }).isRequired,
};

EBarJobs.defaultProps = {
};

EBarJobs.displayName = 'EBarJobs';

export default EBarJobs;
