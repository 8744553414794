const GetNavAlerts = async (navSection) => {
  let alertURL = '';

  switch (navSection) {
    case 'myAccountOverview':
      alertURL = '/DashboardHeroBlock/ValidateProfile';
      break;
    default:
      break;
  }

  if (alertURL !== '') {
    const GetNavAlertsCall = await fetch(`${alertURL}`, {
      method: 'GET',
      credentials: 'same-origin',
    });

    // if the call returns any other code other than 200 (OK)
    // just return false
    return GetNavAlertsCall.json();
  }
  return false;
};


export default GetNavAlerts;
