import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';

const DetailList = styled.ul`
  li {
    padding: 3px 0;

    > span:first-child {
      font-weight: 600;
    }
  }

  span {
    font-size: ${rem('14px', typography.baseFontSize)};
  }
`;

DetailList.displayName = 'DetailList';

export default DetailList;
