import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { colors, typography } from '@style-guide/config';
import { Caret } from '@style-guide/mixins';

const caretSettings = {
  position: 'before',
  color: colors.white,
};

const FormAccordionToggle = styled.button`
  ${Caret(caretSettings)}

  align-items: center;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  display: flex;
  font-size: ${rem('20px', typography.baseFontSize)};
  justify-content: flex-start;
  padding: 15px;
  width: 100%;

  span {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  svg {
    color: ${colors.yellow};
    margin-left: 10px;
  }

  &::before {
    margin-right: 15px;
    transition: 100ms transform 100ms, 100ms margin 100ms;
  }

  &.open {
    background-color: ${colors.red};

    &::before {
      margin-bottom: 5px;
      transition: 100ms transform, 100ms margin;
    }
  }
`;

FormAccordionToggle.displayName = 'FormAccordionToggle';

const FormAccordionContent = styled.div`
  max-height: ${props => (props.$isOpen ? 'auto' : '0')};
  overflow: hidden;
  transition: 500ms max-height;
`;

FormAccordionContent.displayName = 'FormAccordionContent';

const FormAccordionWrapper = styled.div`
  margin: 15px;
  position: relative;
`;

FormAccordionWrapper.displayName = 'FormAccordionWrapper';

class FormAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.isAccordionOpen,
    };

    this.toggleOpenState = this.toggleOpenState.bind(this);
  }

  toggleOpenState() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const {
      title,
      content,
      hasWarning,
    } = this.props;

    const { isOpen } = this.state;

    return (
      <FormAccordionWrapper>
        <FormAccordionToggle
          className={isOpen ? 'open' : ''}
          aria-expanded={isOpen}
          onClick={this.toggleOpenState}
        >
          <span>
            {title}
            {hasWarning && <FontAwesomeIcon icon={faExclamationTriangle} />}
          </span>
        </FormAccordionToggle>
        <FormAccordionContent
          $isOpen={isOpen}
        >
          {content}
        </FormAccordionContent>
      </FormAccordionWrapper>
    );
  }
}

FormAccordion.propTypes = {
  content: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  hasWarning: PropTypes.bool,
  isAccordionOpen: PropTypes.bool,
};

FormAccordion.defaultProps = {
  hasWarning: false,
  isAccordionOpen: false,
};

FormAccordion.displayName = 'FormAccordion';

export default FormAccordion;
