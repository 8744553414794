import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const Wrapper = styled.div`
  background-color: ${colors.white};
  display: none;
  margin-bottom: 30px;
  padding: 20px 30px;

  @media (min-width: ${breakpoints.l}) {
    display: block;
    min-height: 128px;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
