const renderCredit = credits => credits.map((credit, index, collection) => {
  if (index === 0) {
    return `${credit.hours.toFixed(2)} ${credit.typeName}`;
  }

  if (index === collection.length - 1 && collection.length > 2) {
    return `, & ${credit.hours.toFixed(2)} ${credit.typeName}`;
  }

  if (index === collection.length - 1 && collection.length === 2) {
    return ` & ${credit.hours.toFixed(2)} ${credit.typeName}`;
  }

  return `, ${credit.hours.toFixed(2)} ${credit.typeName}`;
});

export default renderCredit;
