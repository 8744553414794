import React from 'react';
import gtag from 'ga-gtag';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CartActions from './components/CartActions';
import CartItem from './components/CartItem';
import DisclaimerText from './components/DisclaimerText';
import EmptyCartMessage from './components/EmptyCartMessage';
import ShoppingCartItemsContainer from './components/ShoppingCartItemsContainer';

const ShoppingCartItems = ({
  items = [],
  total = '$0.00',
  checkoutLink,
}) => {
  const cartItems = items.map(item => (
    <CartItem
      key={item.ItemId}
      displayName={item.DisplayName}
      price={item.Price}
      note={item.Note}
      contentGuid={item.ItemId}
      city={item.City}
      date={item.Date}
      detailUrl={item.DetailUrl}
      productType={item.ProductType}
      attendanceType={item.AttendanceType}
      applyAutomatically={item.ApplyAutomatically}
      soldOut={item.SoldOut}
    />
  ));
  const anySoldOut = items.filter(item => item.SoldOut);

  const gtagItems = items.map((item, index) => {
    const input = {
      item_id: item.ProductId,
      item_name: item.DisplayName,
      affiliation: item.LocationDisplayName,
      index: index + 1,
      item_category: item.CourseFormat,
      price: parseFloat(item.Price.substring(1, item.Price.length - 1)),
    };
    return input;
  });

/* eslint-disable */
    if (cartItems.length > 0) {
      gtag('event', 'view_cart', {
        currency: 'USD',
        value: parseFloat(total.substring(1, total.length - 1)),
        items: gtagItems
      });
    }
  /* eslint-enable */

  return (
    <ShoppingCartItemsContainer>
      <ul>
        {cartItems.length ? cartItems : (
          <EmptyCartMessage>You have no items in your shopping cart.</EmptyCartMessage>
        )}
      </ul>
      <ul>
        <CartItem
          variant="summary-item"
          displayName="Total"
          price={total}
        />
      </ul>
      <DisclaimerText>
        Promotional codes or passes will be applied during the checkout process.
      </DisclaimerText>
      <CartActions
        checkoutDisabled={!items.length || anySoldOut.length > 0}
        checkoutLink={checkoutLink}
      />
    </ShoppingCartItemsContainer>
  );
};

ShoppingCartItems.propTypes = {
  checkoutLink: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(Object),
  total: PropTypes.string,
};

const mapStateToProps = ({ cart }) => ({
  items: cart.lineItems,
  total: cart.cartTotal,
});

ShoppingCartItems.displayName = 'ShoppingCartItems';

export default connect(mapStateToProps)(ShoppingCartItems);
