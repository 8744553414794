import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveOverviewInfo } from '@redux/modules/overviewInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import OverviewInfoForm from './components/OverviewInfoForm';

class OverviewInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formValues) {
    this.props.dispatch(saveOverviewInfo(formValues));
  }

  render() {
    const {
      loading,
      overviewInfo,
      title,
      states,
      counties,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          title={title}
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <OverviewInfoForm
                initialValues={overviewInfo}
                states={states}
                counties={counties}
                onSubmit={this.submit}
                loading={loading}
                hasSaveError={!!error}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
        />
      </FormAccordionContainer>
    );
  }
}

OverviewInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  overviewInfo: PropTypes.shape({
    Awards: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  title: PropTypes.string,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
  counties: PropTypes.arrayOf(PropTypes.string),
  dispatch: PropTypes.func.isRequired,
};

OverviewInfoContent.defaultProps = {
  error: '',
  counties: [],
  loading: false,
  title: 'Overview Information',
};

const mapStateToProps = ({ overviewInfo }) => ({
  error: overviewInfo.error,
  loading: overviewInfo.loading,
  overviewInfo,
});

OverviewInfoContent.displayName = 'OverviewInfoContent';

export default connect(mapStateToProps)(OverviewInfoContent);
