import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Arrow } from '@style-guide/mixins';
import { breakpoints, colors, typography } from '@style-guide/config';

const arrowSettings = {
  arrow: 'right',
  position: 'after',
  color: colors.darkRed,
};

const LeftNavRoot = styled.a`
  font-size: ${rem('26px', typography.baseFontSize)};

  span {
    ${Arrow(arrowSettings)};

    align-items: center;
    display: flex;

    &::after {
      margin-left: 10px;
    }
  }

  @media (min-width: ${breakpoints.m}) {
    font-size: ${rem('20px', typography.baseFontSize)};

    span::after {
      content: '';
    }
  }
`;

LeftNavRoot.displayName = 'LeftNavRoot';

export default LeftNavRoot;
