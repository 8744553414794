import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

import MobileButton from './MobileButton';

const MobileButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${/* sc-selector */MobileButton} {
    margin-bottom: 15px;
  }

  @media(min-width: ${breakpoints.m}) {
    flex-direction: row;

    ${MobileButton} {
      margin-right: 15px;
      width: calc(50% - 7.5px);
    }
  }

  @media(min-width: ${breakpoints.l}) {
    display: none;
  }
`;

MobileButtons.displayName = 'MobileButtons';

export default MobileButtons;
