import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const PageContainerRightRail = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 1130px;
  width: 100%;

  @media (min-width: ${breakpoints.l}) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto 30px;
    padding: 0 35px;
  }
`;

PageContainerRightRail.displayName = 'PageContainerRightRail';

export default PageContainerRightRail;
