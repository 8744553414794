import styled from 'styled-components';
import { breakpoints, colors } from '@style-guide/config';

const ResourceContentContainer = styled.div`
  background-color: ${colors.white};
  padding: 10px;
  position: relative;
  width: 100%;

  @media (min-width: ${breakpoints.s}) {
    padding: 15px;
  }

  @media (min-width: ${breakpoints.m}) {
    margin: -140px 0 0;
    max-width: 670px;
  }

  ${props => (props.isRestricted ? `
    max-height: 1000px;
    overflow: hidden;
  ` : '')}
`;

ResourceContentContainer.displayName = 'ResourceContentContainer';

export default ResourceContentContainer;
