import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, colors, typography } from '@style-guide/config';
import Arrow from '@style-guide/mixins/Arrow';

const arrowSettings = {
  color: colors.yellow,
  size: '20px',
};

const AlertMessage = styled.span`
  background-color: ${colors.yellow};
  color: ${colors.darkBlue};
  font-size: ${rem('16px', typography.baseFontSize)};
  margin: 15px -15px -10px;
  padding: 15px;
  text-align: center;

  a {
    color: ${colors.darkBlue};
  }

  @media (min-width: ${breakpoints.l}) {
    ${Arrow(arrowSettings)};

    bottom: 0;
    left: 0;
    margin: 0 0 60px 0;
    padding: 15px 20px;
    position: absolute;
    width: 100%;

    &::before {
      bottom: -12px;
      position: absolute;
      right: 40%;
    }
  }
`;

AlertMessage.displayName = 'AlertMessage';

export default AlertMessage;
