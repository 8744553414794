import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints, colors } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import removeNonSavedField from '@views/EditDirectory/services/removeNonSavedField';

import LinkItem from './components/LinkItem';

const LinksListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  &:not(:first-child) {
    border-top: 1px solid ${colors.gray};
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
  }
`;

LinksListItem.displayName = 'LinksListItem';

const AddLinkContainer = styled.li`
  button {
    margin: 0;
  }
`;

AddLinkContainer.displayName = 'AddLinkContainer';

const RemoveLinkContainer = styled.div`
  button {
    margin: 15px 0 30px;
  }

  @media (min-width: ${breakpoints.xl}) {
    button {
      margin: 45px 0px 30px 30px;
    }
  }
`;

RemoveLinkContainer.displayName = 'RemoveLinkContainer';

const createNewLink = () => ({
  Deleted: false,
  Id: 0,
  LinkType: '',
  Name: '',
  Position: '',
  IsSaved: false,
});

const LinksList = ({
  fields = [],
}) => {
  const renderAffiliations = (link, index) => {
    if (!fields.get(index).Deleted) {
      return (
        <LinksListItem
          key={link}
        >
          <LinkItem
            link={link}
            index={index}
          />
          <RemoveLinkContainer>
            <Button
              type="button"
              variant="ghost"
              onClick={() => removeNonSavedField(fields, index)}
            >
              REMOVE
            </Button>
          </RemoveLinkContainer>
        </LinksListItem>
      );
    }

    return undefined;
  };

  return (
    <ul>
      <AddLinkContainer>
        <Button
          type="button"
          onClick={() => fields.push(createNewLink())}
        >
          ADD LINK
        </Button>
        <br />
      </AddLinkContainer>
      {fields.map(renderAffiliations)}
    </ul>
  );
};

LinksList.propTypes = {
  fields: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

LinksList.displayName = 'LinksList';

export default LinksList;
