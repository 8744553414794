import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import is from 'is_js';
import { ebarContentTypesValues } from '@config';
import ResultDiv from '../shared/components/ResultDiv';
import LinkMainDiv from '../shared/components/LinkMainDiv';
import AngleSpan from '../shared/components/AngleSpan';
import LinkAnchor from '../shared/components/LinkAnchor';
import CaseSpan from '../shared/components/CaseSpan';
import TitleDiv from '../shared/components/TitleDiv';
import ClearDiv from '../shared/components/ClearDiv';
import ResourceBookmark from '../shared/ResourceBookmark/ResourceBookmark';
import ListParagraph from '../shared/components/ListParagraph';

const EBarAttorneys = ({
  eBarAttorneysLists,
}) => (
  <ResultDiv>
    {eBarAttorneysLists.title !== undefined &&
       is.not.null(eBarAttorneysLists.title) && eBarAttorneysLists.title.trim().length > 0 && (
       <TitleDiv>{eBarAttorneysLists.title}</TitleDiv>
    )}
    <CaseSpan>
      {eBarAttorneysLists.cite !== undefined &&
       is.not.null(eBarAttorneysLists.cite) && eBarAttorneysLists.cite.trim().length > 0 && (
       <span>{eBarAttorneysLists.cite}</span>
      )}
      {(eBarAttorneysLists.cite !== undefined &&
       is.not.null(eBarAttorneysLists.cite) && eBarAttorneysLists.cite.trim().length > 0 &&
       eBarAttorneysLists.issueDate !== undefined && is.not.null(eBarAttorneysLists.issueDate) &&
       eBarAttorneysLists.issueDate.trim().length > 0) && (
       <span> | </span>
      )}
      {(eBarAttorneysLists.issueDate !== undefined && is.not.null(eBarAttorneysLists.issueDate) &&
       eBarAttorneysLists.issueDate.trim().length > 0) ?
        `Issued: ${moment(eBarAttorneysLists.issueDate).format('MMM D, YYYY')}` : ''}
    </CaseSpan>
    {/* eslint-disable */}
    {eBarAttorneysLists.summary !== undefined &&
     is.not.null(eBarAttorneysLists.summary) && eBarAttorneysLists.summary.trim().length > 0 && (
      <ListParagraph dangerouslySetInnerHTML={{ __html: eBarAttorneysLists.summary }} />
    )}
    {/* eslint-enable */}
    <LinkMainDiv>
      {((eBarAttorneysLists.ctaUrl !== undefined &&
      is.not.null(eBarAttorneysLists.ctaUrl) && eBarAttorneysLists.ctaUrl.trim().length > 0) ||
      (eBarAttorneysLists.ctaLabel !== undefined && is.not.null(eBarAttorneysLists.ctaLabel) &&
      eBarAttorneysLists.ctaLabel.trim().length > 0)) && (
        <div>
          <AngleSpan>
            &gt;
          </AngleSpan>
          <LinkAnchor
            href={(eBarAttorneysLists.ctaUrl !== undefined &&
               is.not.null(eBarAttorneysLists.ctaUrl) &&
               eBarAttorneysLists.ctaUrl.trim().length > 0) ?
              eBarAttorneysLists.ctaUrl : eBarAttorneysLists.ctaLabel}
            target="_blank"
            rel="noopener noreferrer"
          >
            {(eBarAttorneysLists.ctaLabel !== undefined &&
             is.not.null(eBarAttorneysLists.ctaLabel) &&
             eBarAttorneysLists.ctaLabel.trim().length > 0) ?
              eBarAttorneysLists.ctaLabel : eBarAttorneysLists.ctaUrl}
          </LinkAnchor>
        </div>
      )}
      <ResourceBookmark
        contentGuidString={eBarAttorneysLists.contentGuidString}
        type={ebarContentTypesValues.eBarAttorneyGeneralOpinion}
        isBookmarked={eBarAttorneysLists.isBookmarked}
      />
    </LinkMainDiv>
    <ClearDiv />
  </ResultDiv>
);

EBarAttorneys.propTypes = {
  eBarAttorneysLists: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    isBookmarked: PropTypes.bool,
  }).isRequired,
};

EBarAttorneys.defaultProps = {
};

EBarAttorneys.displayName = 'EBarAttorneys';

export default EBarAttorneys;
