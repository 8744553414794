import React from 'react';
import styled from 'styled-components';
import YellowLineSVG from '@style-guide/svg/YellowLineSVG';


const YellowLineStyle = styled.span`
  margin: 0px 0px 5px; 
  display: block;
`;

YellowLineStyle.displayName = 'YellowLineStyle';

const YellowLine = () =>
  (
    <YellowLineStyle>
      <YellowLineSVG />
    </YellowLineStyle>
  );

YellowLine.displayName = 'YellowLine';

export default YellowLine;
