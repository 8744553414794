import styled from 'styled-components';

import { colors } from '@style-guide/config';

import CourseMaterialsItemWrapper from './CourseMaterialsItemWrapper';

const CourseMaterialsList = styled.div`
  border-top: 1px solid ${colors.gray};
  border-bottom: 1px solid ${colors.gray};
  padding: 15px 0;
  width: 100%;

  ${/* sc-selector */CourseMaterialsItemWrapper}:not(:last-of-type) {
    margin-bottom: 15px;
  }
`;

CourseMaterialsList.displayName = 'CourseMaterialsList';

export default CourseMaterialsList;
