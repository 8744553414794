import styled from 'styled-components';

const FormContainer = styled.div`
  padding: 15px;

  button {
    margin: 30px 0;
  }
`;

export default FormContainer;
