import styled from 'styled-components';

import { colors } from '@style-guide/config';

const CardWrapperTop = styled.div`
  background-color: ${colors.white};
`;

CardWrapperTop.displayName = 'CardWrapperTop';

export default CardWrapperTop;
