import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretRight,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';

import AlternativeLocations from './components/AlternativeLocations';
import LocationsContainer from './components/LocationsContainer';
import PrimaryLocation from './components/PrimaryLocation';

const Locations = ({
  location,
  additionalLocations,
}) => {
  const additionalLocationList = additionalLocations.map(additionalLocation => (
    <li
      key={additionalLocation.city}
    >
      <a href={additionalLocation.url}>
        {additionalLocation.city}
      </a>
      <FontAwesomeIcon icon={faCaretRight} aria-hidden="true" />
    </li>
  ));

  return (
    <LocationsContainer>
      <PrimaryLocation>
        <div>
          <FontAwesomeIcon icon={faMapMarkerAlt} aria-hidden="true" />
          Location:
        </div>
        {location && (
          <div>
            <div>{location.displayName}</div>
            {location.line1 && (
              <div>{location.line1}</div>
            )}
            {location.line2 && (
              <div>{location.line2}</div>
            )}
            {location.line3 && (
              <div>{location.line3}</div>
            )}
            {location.line4 && (
              <div>{location.line4}</div>
            )}
            <div>{`${location.city}, ${location.state} ${location.postalCode}`}</div>
          </div>
        )}
        {!location && (
          <div><i>To Be Determined</i></div>
        )}
      </PrimaryLocation>
      {!!additionalLocationList.length && (
        <AlternativeLocations>
          <div>Other Locations:</div>
          <ul>
            {additionalLocationList}
          </ul>
        </AlternativeLocations>
      )}
    </LocationsContainer>
  );
};

Locations.displayName = 'Locations';

Locations.propTypes = {
  location: PropTypes.shape({
    displayName: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
    line4: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  additionalLocations: PropTypes.arrayOf(PropTypes.object),
};

Locations.defaultProps = {
  location: {
    displayName: '',
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    city: '',
    state: '',
    postalCode: '',
  },
  additionalLocations: [],
};

export default Locations;
