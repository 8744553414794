import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import trimStart from 'lodash/trimStart';
import { breakpoints, colors, typography } from '@style-guide/config';

const borderThickness = '1px';
const height = rem('35px', typography.baseFontSize);
const paddingLeft = '20px';

const LinkGroupContainer = styled.ul`
  display: inline-block;
  margin-bottom: -${borderThickness};
  padding-left: ${paddingLeft};
  min-width: 235px;
  width: 100%;

  a {
    box-shadow: none;
    color: ${colors.black};
    display: block;
    font-size: ${rem('14px', typography.baseFontSize)};
    height: ${height};
    line-height: ${height};

    &:hover {
      color: ${colors.darkRed};
    }
  }

  li {
    border-top: ${borderThickness} solid ${colors.gray};
    font-weight: 700;

    &:last-child {
      border-bottom: ${borderThickness} solid ${colors.gray};
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    width: calc(50% - ${paddingLeft});

    &:only-of-type {
      width: 60%;
    }
  }
`;

LinkGroupContainer.displayName = 'LinkGroupContainer';

const LinkGroup = ({ links = [] }) => {
  const linksWithIDs = links.map((link, index) => ({
    id: index,
    ...link,
  }));

  const navLinks = linksWithIDs.map(link => (
    <li key={link.id}>
      <a
        href={trimStart(link.href, '~')}
        title={link.title}
        target={link.target}
      >
        {link.text}
      </a>
    </li>
  ));

  return (
    <LinkGroupContainer>
      {navLinks}
    </LinkGroupContainer>
  );
};

LinkGroup.propTypes = {
  links: PropTypes.arrayOf(Object),
};

LinkGroup.displayName = 'LinkGroup';

export default LinkGroup;
