import { change } from 'redux-form';

const validate = ({
  OhioSupremeCourtNumber,
  OhioSupremeCourtNumberOptOut,
}, props) => {
  const errors = {};

  if (!OhioSupremeCourtNumber && !OhioSupremeCourtNumberOptOut) {
    errors.OhioSupremeCourtNumber = props.supremeCourtNumber
      ?
      'Ohio supreme court number must be entered'
      :
      'Ohio supreme court number must be entered or you must opt out';
  } else if (OhioSupremeCourtNumberOptOut && OhioSupremeCourtNumber) {
    props.dispatch(change('personalInfoForm', 'OhioSupremeCourtNumber', ''));
  }

  return errors;
};

export default validate;
