import styled from 'styled-components';

const OuterPlayer = styled.div`
  width: 100%;
  position: relative;
`;

OuterPlayer.displayName = 'OuterPlayer';

export default OuterPlayer;
