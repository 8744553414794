import styled from 'styled-components';

import {
  colors,
  breakpoints,
} from '@style-guide/config';

const Wrapper = styled.div`
  background-color: ${colors.lightGray};
  padding: 20px 15px 30px 15px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media(min-width: ${breakpoints.m}) {
    padding: 20px;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
