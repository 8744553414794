import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const DescriptionPicture = styled.img`
  width: 100%;

  @media (min-width: ${breakpoints.l}) {
    width: 330px;
    float: left;
    margin-right: 10px;
  }
`;

DescriptionPicture.displayName = 'DescriptionPicture';

export default DescriptionPicture;
