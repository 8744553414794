import styled from 'styled-components';

import { colors } from '@style-guide/config';

const ClearButton = styled.a`
  color: ${colors.white};
  text-decoration: underline;
  cursor: pointer;
`;

ClearButton.displayName = 'ClearButton';

export default ClearButton;
