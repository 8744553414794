import React from 'react';
import is from 'is_js';
import PropTypes from 'prop-types';

import Divider from '@components/Divider';

import CardBody from './components/CardBody';
import CardWrapper from './components/CardWrapper';
import Header from './components/Header';
import HeaderItem from './components/HeaderItem';
import ItemField from './components/ItemField';
import LinkWrapper from './components/LinkWrapper';
import NoLinkWrapper from './components/NoLinkWrapper';
import NoOrdersTitle from './components/NoOrdersTitle';
import Title from './components/Title';
import Wrapper from './components/Wrapper';

const productTypeMap = {
  MeetingInstance: 'CLE',
  CLEAddOn: 'CLE Add-on',
  EventInstance: 'Event',
  MembershipType: 'Membership',
  Section: 'Section',
};

const renderProductType = (productType) => {
  const typeMapKeys = Object.keys(productTypeMap);
  const typeIsWhitelisted = typeMapKeys.includes(productType);

  if (typeIsWhitelisted) {
    return (
      <ItemField>
        <strong>Product Type: </strong> {productTypeMap[productType]}
      </ItemField>
    );
  }

  return undefined;
};

const renderLineItems = (items) => {
  if (is.empty(items)) {
    return undefined;
  }

  const itemsWithId = items.map((item, index) => ({
    ...item,
    id: index,
  }));

  const itemsToRender = itemsWithId.map((item, index, collection) => {
    const ItemWrapper = item.detailUrl ? LinkWrapper : NoLinkWrapper;

    return (
      <ItemWrapper
        key={item.id}
        href={item.detailUrl ? item.detailUrl : undefined}
      >
        <Title>{item.displayName}</Title>
        {renderProductType(item.productType)}
        {item.date && (
          <ItemField>
            <strong>Date: </strong> {item.date}
          </ItemField>
        )}
        {item.city && (
          <ItemField>
            <strong>City: </strong> {item.city}
          </ItemField>
        )}
        {index !== collection.length - 1 && (
          <Divider
            margin="15px 0px"
            hasDecorator={false}
          />
        )}
      </ItemWrapper>
    );
  });

  return itemsToRender;
};

const PurchaseHistory = ({ orderHistory = [] }) => {
  if (is.empty(orderHistory)) {
    return (
      <CardWrapper
        $hasMarginTop={false}
      >
        <CardBody>
          <NoOrdersTitle>You do not have any purchases in your history.</NoOrdersTitle>
        </CardBody>
      </CardWrapper>
    );
  }

  const orderHistoryWithIds = orderHistory.map((order, index) => ({
    ...order,
    id: index,
  }));

  const ordersToRender = orderHistoryWithIds.map((order, index) => (
    <CardWrapper
      key={order.id}
      $hasMarginTop={index > 0}
    >
      <Header>
        <HeaderItem>
          <strong>Order Date: </strong>{order.orderDate}
        </HeaderItem>
        <HeaderItem>
          <strong>Total Billed: </strong>${order.billedAmount.toFixed(2)}
        </HeaderItem>
        <HeaderItem>
          <strong>Order # </strong>{order.orderNumber}
        </HeaderItem>
      </Header>
      <CardBody>
        {is.not.empty(order.lineItems) && renderLineItems(order.lineItems)}
      </CardBody>
    </CardWrapper>
  ));

  return (
    <Wrapper>
      {ordersToRender}
    </Wrapper>
  );
};

PurchaseHistory.propTypes = {
  orderHistory: PropTypes.arrayOf(Object),
};

PurchaseHistory.displayName = 'PurchaseHistory';

export default PurchaseHistory;
