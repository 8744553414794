import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const Status = styled.span`
  background-color: ${colors.lightGray};
  padding: 10px 25px;

  strong {
    font-weight: bold;
  }

  span {
    color: ${props => (props.status === 'Attended' ? colors.blue : colors.red)};
  }

  @media(min-width: ${breakpoints.xl}) {
    background-color: ${colors.white};
  }
`;

Status.displayName = 'Status';

export default Status;
