import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';
import { typography, colors } from '@style-guide/config';

import { DeleteBookmark } from '@services/Bookmark';
import LoadingOverlay from '@components/LoadingOverlay';
import PracticeLibraryBookmarkListItem from './components/PracticeLibraryBookmarkListItem';


const BookMarkListContainer = styled.ul`
  position: relative;
  padding: 30px;
`;

const BookMarkListTitle = styled.p`
  font-size: ${rem('16px', typography.baseFontSize)};
  color: #a41f35;
  font-weight: bold;
  border-bottom: 1px solid ${colors.lightGray};
  padding-bottom: 14px;
  margin-bottom: 25px;
`;

class PracticeLibraryBookmarkList extends React.Component {
  constructor(props) {
    super(props);

    const newItemList = props.bookmarkList.map(item => ({
      ...item,
      itemState: 'active',
    }));

    this.state = {
      bookmarkList: newItemList,
      loadingState: '',
    };

    this.handleDeleteBookmark = this.handleDeleteBookmark.bind(this);
    this.resetToActiveHandler = this.resetToActiveHandler.bind(this);
    this.removeFromPageHandler = this.removeFromPageHandler.bind(this);
  }

  handleDeleteBookmark(pageId) {
    // throw up loader until the call is made
    this.setState({ loadingState: 'loading' });

    // make the delete call
    DeleteBookmark(pageId)
      .then((res) => {
        if (res === false) {
          // if there wasn error removing this item, set its error state
          // we pass the state to change and id which is how we choose items from state
          this.setState({ bookmarkList: this.alterItemState('error', pageId), loadingState: '' });
        } else {
          // no error, set its state to be removed
          this.setState({ bookmarkList: this.alterItemState('remove', pageId), loadingState: '' });
        }
      })
      .catch(() => {
        // any other error, just set it to an error state
        this.setState({ bookmarkList: this.alterItemState('error', pageId), loadingState: '' });
      });
  }

  resetToActiveHandler(whichId) {
    // after making any necessary state changes, set the state to change the UI
    this.setState({ bookmarkList: this.alterItemState('active', whichId) });
  }

  removeFromPageHandler(whichId) {
    // filter out the id that you're removing and re-render the list
    const newState = this.state.bookmarkList.filter(item => item.pageId !== whichId);
    this.setState({ bookmarkList: newState });
  }


  alterItemState(whichState, whichId) {
    const currentListItems = this.state.bookmarkList;

    const newState = currentListItems.map((item) => {
      if (item.pageId === whichId) {
        return ({
          ...item,
          itemState: whichState,
        });
      }

      return item;
    });

    return newState;
  }


/* eslint-disable */
  render() {
    return (
      <BookMarkListContainer>
        <BookMarkListTitle>Bookmarked Resources</BookMarkListTitle>
        {this.state.loadingState === 'loading' &&
          <LoadingOverlay />
        }
        {
          this.state.bookmarkList.map(item =>
            (
              <PracticeLibraryBookmarkListItem
                removeFromPageHandler={this.removeFromPageHandler}
                resetToActiveHandler={this.resetToActiveHandler}
                itemState={item.itemState}
                pageId={item.pageId}
                key={item.pageId}
                href={item.href}
                title={item.title}
                deleteBookmarkHandler={this.handleDeleteBookmark}
            />),
          this)
        }
      </BookMarkListContainer>
    );
  }
/* eslint-enable */
}


PracticeLibraryBookmarkList.propTypes = {
  bookmarkList: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
  })),
};

PracticeLibraryBookmarkList.defaultProps = {
  bookmarkList: [],
};

BookMarkListContainer.displayName = 'BookMarkListContainer';
PracticeLibraryBookmarkList.displayName = 'PracticeLibraryBookmarkList';

export default PracticeLibraryBookmarkList;
