import React from 'react';
import PropTypes from 'prop-types';

import Button from '@style-guide/components/Button';

import ExperienceContainer from './components/ExperienceContainer';
import ExperienceItem from './components/ExperienceItem';
import ExperienceListItem from './components/ExperienceListItem';

const createNewExperienceItem = () => ({
  isDeleted: false,
  employmentId: -1,
  employer: '',
});

const ExperienceList = ({ fields = [] }) => {
  const renderExperienceItem = (experience, index) => {
    if (!fields.get(index).deleted) {
      return (
        <ExperienceListItem
          key={experience}
        >
          <ExperienceItem
            experience={experience}
            index={index}
            onRemove={() => {
              // redux-form field array objects have immutable accessors
              // so we have to splice to modify
              // https://redux-form.com/7.4.2/docs/api/fieldarray.md/#fields-props

              const deletedExperience = JSON.parse(JSON.stringify(fields.get(index)));
              deletedExperience.deleted = true;
              deletedExperience.isDeleted = true;
              fields.splice(index, 1, deletedExperience);
            }}
          />
        </ExperienceListItem>
      );
    }

    return undefined;
  };

  return (
    <ul>
      {fields.map(renderExperienceItem)}
      <ExperienceContainer>
        <Button
          type="button"
          onClick={() => fields.push(createNewExperienceItem())}
        >
          ADD EMPLOYMENT
        </Button>
      </ExperienceContainer>
    </ul>
  );
};

ExperienceList.propTypes = {
  fields: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

ExperienceList.displayName = 'ExperienceList';

export default ExperienceList;
