import styled from 'styled-components';

import { colors } from '@style-guide/config';

const ResultsWrapper = styled.div`
  background-color: ${colors.white};
  padding: 20px 30px;
`;

ResultsWrapper.displayName = 'ResultsWrapper';

export default ResultsWrapper;
