import styled from 'styled-components';

import { colors } from '@style-guide/config';

const Wrapper = styled.div`
  background-color: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 60px;
  position: relative;
  width: 100%;
  align-items: center;
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
