import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';
import { colors } from '@style-guide/config';

const SubMenuContainer = styled.section`
  background-color: ${transparentize(0.1, colors.white)};
  padding: 3px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 4;
`;

SubMenuContainer.displayName = 'SubMenuContainer';

const SubMenuHeading = styled.h2`
  align-items: center;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  display: flex;
  font-size: 22px;
  height: 50px;
  padding: 0 20px;
`;

SubMenuHeading.displayName = 'SubMenuHeading';

const SubMenu = ({
  heading,
  content,
  id,
}) => (
  <SubMenuContainer
    id={id}
  >
    <SubMenuHeading>{heading}</SubMenuHeading>
    {content}
  </SubMenuContainer>
);

SubMenu.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
};

SubMenu.displayName = 'SubMenu';

export default SubMenu;
