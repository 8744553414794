import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TextArea from '@style-guide/components/TextArea';
import YellowLine from './components/YellowLine';

import Wrapper from './components/Wrapper';
import HeadingDiv from './components/HeadingDiv';

const ImageHeadingDiv = styled.div`
  display: flex;
  align-items: center;
`;

ImageHeadingDiv.displayName = 'ImageHeadingDiv';

const ImageTag = styled.img`
  vertical-align: middle;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  flex-basis: 40%;
`;

ImageTag.displayName = 'ImageTag';

const AngleSpan = styled.span`
  font-weight: bold;
  color: #FDBA2B;
  padding-right: 5px;
  font-size: 14px;
`;

AngleSpan.displayName = 'AngleSpan';

const LinkTag = styled.a`
  color: #C3002F;
  text-decoration: underline;
  font-size: 14px;
`;

LinkTag.displayName = 'LinkTag';

const HeadingSpan = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

HeadingSpan.displayName = 'HeadingSpan';

const EBarSnippetBlock = ({
  hasBottomMargin = true,
  hasTopMargin = true,
  headline = 'Snippet Block',
  image = '',
  summary = '',
  cTALabel = '',
  cTAUrl = '',
}) => (
  <Wrapper
    $hasTopMargin={hasTopMargin}
    $hasBottomMargin={hasBottomMargin}
  >
    <ImageHeadingDiv>
      {image && (
        <div>
          <ImageTag src={image} alt="" />
        </div>
      )}
      <HeadingDiv
        image={image}
      >
        <YellowLine />
        <HeadingSpan>{headline}</HeadingSpan>
      </HeadingDiv>
    </ImageHeadingDiv>
    <br />
    <TextArea
      htmlString={summary}
    />
    {cTALabel && cTAUrl && (
      <div>
        <AngleSpan>
          &gt;
        </AngleSpan>
        <LinkTag
          href={cTAUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cTALabel}
        </LinkTag>
      </div>
    )}
  </Wrapper>
);

EBarSnippetBlock.propTypes = {
  /** Whether or not a 30px bottom margin is applied. */
  hasBottomMargin: PropTypes.bool,
  /** Whether or not a 30px top margin is applied. */
  hasTopMargin: PropTypes.bool,
  headline: PropTypes.string,
  image: PropTypes.string,
  summary: PropTypes.string,
  cTALabel: PropTypes.string,
  cTAUrl: PropTypes.string,
};

EBarSnippetBlock.displayName = 'EBarSnippetBlock';

export default EBarSnippetBlock;
