import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@style-guide/config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import LeftNavAccordionNode from './components/LeftNavAccordionNode';

const AlertIcon = styled(FontAwesomeIcon)`
  color: ${colors.yellow};
`;

AlertIcon.displayName = 'AlertIcon';

function hasActiveChild(nodes) {
  for (let i = 0; i < nodes.length; i += 1) {
    if (nodes[i].IsActive || (nodes[i].Children && hasActiveChild(nodes[i].Children))) {
      return true;
    }
  }

  return false;
}

function renderAccordionNode(node, childNodes, isOpen) {
  return (
    <LeftNavAccordionNode
      key={node.Id}
      Name={node.Name}
      Link={node.Link}
      IsActive={node.IsActive}
      ChildNodes={childNodes}
      ShowAlert={node.ShowAlert}
      IsOpen={isOpen}
    />
  );
}

function renderNode(node) {
  return (
    <li key={node.Id}>
      {!node.Valid &&
        <span className="invalid">{node.Name}</span>
      }

      {node.Valid && (
        <a href={node.Link} className={node.IsActive ? 'active' : ''}>
          <span>{node.Name}</span>
          {node.ShowAlert &&
          <AlertIcon icon={faExclamationTriangle} />
            }
        </a>
      )}
    </li>
  );
}

function renderContent(node) {
  if (node.Children && node.Children.length) {
    const childNodes = node.Children.map(childNode => renderContent(childNode));

    return renderAccordionNode(node, childNodes, hasActiveChild(node.Children));
  }

  return renderNode(node);
}

const LeftNavNode = ({
  Id = 0,
  Name = '',
  Link = '',
  IsActive = false,
  Valid,
  Children = [],
  ShowAlert = false,
}) => renderContent({
  Id, Name, Link, IsActive, Valid, Children, ShowAlert,
});


LeftNavNode.propTypes = {
  Id: PropTypes.number,
  Name: PropTypes.string,
  Link: PropTypes.string,
  IsActive: PropTypes.bool,
  Valid: PropTypes.bool.isRequired,
  Children: PropTypes.arrayOf(Object),
  ShowAlert: PropTypes.bool,
};

LeftNavNode.displayName = 'LeftNavNode';

export default LeftNavNode;
