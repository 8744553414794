import styled from 'styled-components';

const Title = styled.h3`
  font-size: 24px;
  line-height: 28px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 10px;
`;

Title.displayName = 'Title';

export default Title;
