import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import { colors } from '@style-guide/config';
import Store from '@redux';
import DashboardContainer from './components/DashboardContainer';
import DashboardHeroImage from './components/DashboardHeroImage';
import DashboardTools from './components/DashboardTools';

const GlobalStyle = createGlobalStyle`
  body {
    background: linear-gradient(#747e82, ${colors.lightGray} 350px, ${colors.white} 100%) no-repeat;
  }
`;

const EBarDashboardHero = ({
  imgSrc,
  eBarGreenbookSearchUrl,
  title = '',
  subtitle = '',
  customizeLabel = '',
  customizeLink = '',
  faqLabel = '',
  faqLink = '',
  quickLinks = [],
  isMember = false,
  types = [],
}) => {
  const store = Store.get();

  return (
    <React.Fragment>
      <GlobalStyle />
      <DashboardContainer>
        <DashboardHeroImage
          imgSrc={imgSrc}
          title={title}
          subtitle={subtitle}
          buttonLabel={customizeLabel}
          buttonLink={customizeLink}
        />
        <Provider store={store}>
          <DashboardTools
            types={types}
            isMember={isMember}
            quickLinks={quickLinks}
            customizeLabel={customizeLabel}
            customizeLink={customizeLink}
            faqLabel={faqLabel}
            faqLink={faqLink}
            eBarGreenbookSearchUrl={eBarGreenbookSearchUrl}
          />
        </Provider>
      </DashboardContainer>
    </React.Fragment>
  );
};

EBarDashboardHero.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  eBarGreenbookSearchUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  customizeLabel: PropTypes.string,
  customizeLink: PropTypes.string,
  faqLabel: PropTypes.string,
  faqLink: PropTypes.string,
  quickLinks: PropTypes.arrayOf(PropTypes.object),
  isMember: PropTypes.bool,
  types: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
};

EBarDashboardHero.displayName = 'EBarDashboardHero';

export default EBarDashboardHero;
