import ApiGenerator from '@services/ApiGenerator';

const HYDRATE = 'osba/accountMedia/HYDRATE';
const SAVE = 'osba/accountMedia/SAVE';
const SAVE_ERROR = 'osba/accountMedia/SAVE_ERROR';
const SAVE_RECEIVED = 'osba/accountMedia/SAVE_RECEIVED';
const SUBMISSION_ERROR = 'osba/accountMedia/SUBMISSION_ERROR';

const initialState = {
  values: {
    companyLogo: {
      url: '',
      deleted: false,
      file: null,
      isValid: false,
    },
    coverImage: {
      url: '',
    },
    profilePhoto: {
      url: '',
      deleted: false,
      file: null,
      isValid: false,
    },
  },
  loading: false,
  message: '',
  error: '',
};

const mapResponse = data => ({
  companyLogo: {
    url: data.CompanyLogoUrl,
    deleted: false,
    file: null,
    isValid: true,
  },
  coverImage: {
    url: data.CoverImageUrl,
  },
  profilePhoto: {
    url: data.ProfileImageUrl,
    deleted: false,
    file: null,
    isValid: true,
  },
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        values: {
          ...mapResponse(action.data),
        },
        error: '',
      };
    case SAVE:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SAVE_RECEIVED:
      return {
        ...state,
        values: {
          ...mapResponse(action.data),
        },
        loading: false,
      };
    case SUBMISSION_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const hydrateMediaInfo = data => ({
  type: HYDRATE,
  data: {
    CompanyLogoUrl: data.CompanyLogoUrl,
    CoverImageUrl: data.CoverImageUrl,
    ProfileImageUrl: data.ProfileImageUrl,
  },
});

export const saveMediaInfo = data => ({
  type: SAVE,
  data,
});

export const updateMediaSubmissionError = error => ({
  type: SUBMISSION_ERROR,
  error,
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case SAVE:
      api(
        'post',
        '/Media/Upload',
        SAVE,
        action.data,
        true,
      );
      break;
    default:
      break;
  }
};

export default reducer;
