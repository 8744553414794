import React from 'react';
import Loadable from 'react-loadable';
import ShellLoading from '@components/ShellLoading';

const MembershipPackagesLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "MembershipPackages" */ './MembershipPackages'),
  loading() {
    return <ShellLoading height="50vh" />;
  },
});

const MembershipPackagesAsync = props => <MembershipPackagesLoadable {...props} />;

export default MembershipPackagesAsync;
