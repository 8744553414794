import is from 'is_js';

const renderSpecializationCredits = (specializationCredits) => {
  if (is.not.empty(specializationCredits)) {
    return specializationCredits.map((credit, index, collection) => {
      if (index === 0) {
        return `${credit.typeName}`;
      }

      if (index === collection.length - 1) {
        return `, & ${credit.typeName}`;
      }

      return `, ${credit.typeName}`;
    }).join('');
  }

  return undefined;
};

export default renderSpecializationCredits;
