import styled from 'styled-components';

const SpecializationCreditBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px auto;

  span {
    line-height: 28px;
  }

  span:nth-of-type(2) {
    margin-left: 4px;
  }
`;

SpecializationCreditBlock.displayName = 'SpecializationCreditBlock';

export default SpecializationCreditBlock;
