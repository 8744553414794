import React from 'react';
import PropTypes from 'prop-types';

import ResourceUtilitiesContainer from './components/ResourceUtilitiesContainer';
import ResourceBookmark from './components/ResourceBookmark';
import SocialShare from './components/SocialShare';

const ResourceUtilities = ({
  isMember,
  pageId,
  isBookmarked,
}) => (
  <ResourceUtilitiesContainer>
    {isMember &&
      <ResourceBookmark pageId={pageId} isBookmarked={isBookmarked} />
    }
    <SocialShare />
  </ResourceUtilitiesContainer>
);

ResourceUtilities.displayName = 'ResourceUtilities';

ResourceUtilities.propTypes = {
  pageId: PropTypes.number.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  isMember: PropTypes.bool.isRequired,
};

export default ResourceUtilities;
