import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const DesktopButtons = styled.div`
  display: none;

  @media(min-width: ${breakpoints.l}) {
    display: flex;
    flex-direction: row;
  }
`;

DesktopButtons.displayName = 'DesktopButtons';

export default DesktopButtons;
