import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { hydrateMediaInfo } from '@redux/modules/mediaInfo';
import Store from '@redux';

import MediaInfoContent from './components/MediaInfoContent';

class MediaInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(hydrateMediaInfo({
      CompanyLogoUrl: this.props.companyLogoUrl,
      CoverImageUrl: this.props.coverImageUrl,
      ProfileImageUrl: this.props.profileImageUrl,
    }));
  }

  render() {
    return (
      <Provider store={this.store}>
        <MediaInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

MediaInfo.propTypes = {
  companyLogoUrl: PropTypes.string,
  coverImageUrl: PropTypes.string,
  profileImageUrl: PropTypes.string,
};

MediaInfo.defaultProps = {
  companyLogoUrl: '',
  coverImageUrl: '',
  profileImageUrl: '',
};

MediaInfo.displayName = 'MediaInfo';

export default MediaInfo;
