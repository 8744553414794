import styled from 'styled-components';

const RestrictedOverlay = styled.div`
  align-items: flex-end;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 2;
`;

RestrictedOverlay.displayName = 'RestrictedOverlay';

export default RestrictedOverlay;
