import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const LogoWrapper = styled.div`
  position: absolute;
  right: -20px;
  bottom: -25px;
  z-index: 1;

  @media(min-width: ${breakpoints.xl}) {
    bottom: -50px;
  }
`;

LogoWrapper.displayName = 'LogoWrapper';

export default LogoWrapper;
