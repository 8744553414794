import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import is from 'is_js';

import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import LinkButton from '@style-guide/components/LinkButton';
import Button from '@style-guide/components/Button';

import { courseFormat as courseFormatPropTypes } from '@services/PropTypes';
import CLEBadge from '@components/CLEBadge';
import SpecializationCreditSVG from '@components/SpecializationCreditSVG';

import renderSpecializationCredits from './services/renderSpecializationCredits';
import renderCredits from './services/renderCredits';
import renderGoogleMapLinkQuery from './services/renderGoogleMapLinkQuery';
import renderPracticeAreas from './services/renderPracticeAreas';

import BrochureDownloadLink from './components/BrochureDownloadLink';
import ButtonWrapper from './components/ButtonWrapper';
import DescriptionInner from './components/DescriptionInner';
import DescriptionWrapper from './components/DescriptionWrapper';
import DetailsItem from './components/DetailsItem';
import DetailsWrapper from './components/DetailsWrapper';
import LocationBlock from './components/LocationBlock';
import SpecializationCreditBlock from './components/SpecializationCreditBlock';
import Title from './components/Title';
import Thumbnail from './components/Thumbnail';
import ThumbnailWrapper from './components/ThumbnailWrapper';
import Wrapper from './components/Wrapper';

/* eslint-disable */
const CourseDetails = ({
  meetingInstance,
  parentMeeting,
  credits,
  practiceAreas,
  courseFormat,
  specializationCredits = [],
  inxpoClassroomLink = '',
  podcastUrl = '',
  richCourseDescription = '',
  showCLEOnDemand,
  videoUrl = '',
}) => (
  <Wrapper>
    <ThumbnailWrapper>
      <CLEBadge
        courseFormat={courseFormat}
      />
      <Thumbnail
        src={meetingInstance.pictureLink || '/Static/img/osba-default-cle.png'}
        alt={`${meetingInstance.displayName} course thumbnail`}
        style={{
          width: '100%',
        }}
      />
    </ThumbnailWrapper>
    <DetailsWrapper>
      <h2>{meetingInstance.displayName}</h2>
      {meetingInstance.courseId && (
        <DetailsItem>
          <strong>Course ID: </strong><span>{meetingInstance.courseID}</span>
        </DetailsItem>
      )}
      <DetailsItem>
        <strong>Date:</strong><span>{meetingInstance.meetingDateString}</span>
      </DetailsItem>
      {meetingInstance.startTimeValue && meetingInstance.endTimeValue && (
        <DetailsItem>
          <strong>Time:</strong>
          <span>{meetingInstance.startTimeValue} - {meetingInstance.endTimeValue}</span>
        </DetailsItem>
      )}
      {is.not.empty(credits) && (
        <DetailsItem>
          <strong>Credits:</strong><span>{renderCredits(credits)}</span>
        </DetailsItem>
      )}
      {is.not.empty(practiceAreas) && (
        <DetailsItem>
          <strong>Practice Areas:</strong><span>{renderPracticeAreas(practiceAreas)}</span>
        </DetailsItem>
      )}
      {is.not.empty(specializationCredits) && (
        <SpecializationCreditBlock>
          <SpecializationCreditSVG
            height="28px"
            width="28px"
          />
          <span>
            <strong>Specialization Credit:</strong>
          </span>
          <span>
            {renderSpecializationCredits(specializationCredits)}
          </span>
        </SpecializationCreditBlock>
      )}
      {inxpoClassroomLink && (
        <ButtonWrapper>
          <LinkButton
            href={`/${inxpoClassroomLink}`}
            target="_blank"
          >
            Start Course
          </LinkButton>
        </ButtonWrapper>
      )}
      {videoUrl !== '' && videoUrl !== null && (
        <ButtonWrapper>
          <Button
            onClick={showCLEOnDemand}
          >
            Start Course
          </Button>
        </ButtonWrapper>
      )}
      {podcastUrl && (
        <ButtonWrapper>
          <LinkButton
            href={podcastUrl}
            target="_blank"
          >
            Download Podcast
          </LinkButton>
        </ButtonWrapper>
      )}
      {meetingInstance.location && (
        <React.Fragment>
          <LocationBlock>
            <div>Location:</div>
            <div>
              <p>{meetingInstance.location.name}</p>
              {/* eslint-disable max-len */}
              <p>{meetingInstance.location.line1} {meetingInstance.location.line2} {meetingInstance.location.line3} {meetingInstance.location.line4}</p>
              <p>{meetingInstance.location.city}, {meetingInstance.location.state} {meetingInstance.location.postalCode}</p>
              {/* eslint-enable max-len */}
            </div>
          </LocationBlock>
          <ButtonWrapper>
            <LinkButton
              href={`https://www.google.com/maps/dir/?api=1&destination=${renderGoogleMapLinkQuery(meetingInstance.location)}`}
              target="_blank"
            >
              Get Directions
            </LinkButton>
          </ButtonWrapper>
        </React.Fragment>
      )}
      {!meetingInstance.location && meetingInstance.zoomMeeting !== '' && meetingInstance.zoomMeeting !== null && (
        <LocationBlock>
          <div>Location:</div>
          <div>
            <p>Virtual</p>
          </div>
        </LocationBlock>
      )}
      {meetingInstance.zoomMeeting !== '' && meetingInstance.zoomMeeting !== null && (
        <ButtonWrapper>
          <LinkButton
            href={meetingInstance.zoomMeeting}
            target="_blank"
          >
            Start Course
          </LinkButton>
        </ButtonWrapper>
      )}
      {meetingInstance.brochure && (
        <ButtonWrapper>
          <BrochureDownloadLink
            href={meetingInstance.brochure}
          >
            <FontAwesomeIcon
              icon={faDownload}
            />
            <span>Download Course Brochure</span>
          </BrochureDownloadLink>
        </ButtonWrapper>
      )}
    </DetailsWrapper>
    <DescriptionWrapper>
      <Title>Course Description</Title>
      <YellowLineSVG />
      <DescriptionInner>
        {(richCourseDescription.length) /* eslint-disable-next-line react/no-danger */
          ? <span dangerouslySetInnerHTML={{ __html: richCourseDescription }} />
          : <p>{parentMeeting.description}</p>}
      </DescriptionInner>
    </DescriptionWrapper>
  </Wrapper>
);
/* eslint-enable */

CourseDetails.propTypes = {
  meetingInstance: PropTypes.shape({
    pictureLink: PropTypes.string,
    zoomMeeting: PropTypes.string,
    isVirtual: PropTypes.bool,
  }).isRequired,
  credits: PropTypes.arrayOf(Object).isRequired,
  // eslint-disable-next-line
  parentMeeting: PropTypes.object.isRequired,
  practiceAreas: PropTypes.arrayOf(Object).isRequired,
  courseFormat: courseFormatPropTypes.isRequired,
  specializationCredits: PropTypes.arrayOf(Object),
  inxpoClassroomLink: PropTypes.string,
  podcastUrl: PropTypes.string,
  richCourseDescription: PropTypes.string,
  showCLEOnDemand: PropTypes.func.isRequired,
  videoUrl: PropTypes.string,
};

CourseDetails.displayName = 'CourseDetails';

export default CourseDetails;
