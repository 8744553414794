import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const RightRail = styled.div`
  @media(min-width: ${breakpoints.l}) {
    margin-left: 50px;
    width: 350px;
  }
`;

RightRail.displayName = 'RightRail';

export default RightRail;
