import React from 'react';
import PropTypes from 'prop-types';

import ProgressTrackerContainer from './components/ProgressTrackerContainer';
import ProgressTrackerTitle from './components/ProgressTrackerTitle';
import ProgressTrackerStep from './components/ProgressTrackerStep';

const ProgressTracker = ({
  steps,
  convertedChecks,
}) => {
  const trackerSteps = [];
  for (let i = 0; i < steps; i += 1) {
    trackerSteps.push(
      <React.Fragment>
        <ProgressTrackerStep
          key={i}
          className={convertedChecks.includes((i + 1).toString()) ? 'active' : ''}
        />
      </React.Fragment>,
    );
  }

  return (
    <React.Fragment>
      <ProgressTrackerTitle>
        Checkpoints
      </ProgressTrackerTitle>
      <ProgressTrackerContainer>
        {trackerSteps}
      </ProgressTrackerContainer>
    </React.Fragment>
  );
};

ProgressTracker.propTypes = {
  // currentStep: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
  convertedChecks: PropTypes.string.isRequired,
};

ProgressTracker.displayName = 'ProgressTracker';

export default ProgressTracker;
