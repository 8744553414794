import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const ProgressTrackerTitle = styled.h3`
  counter-reset: step;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 0px 10px;

  @media (min-width: ${breakpoints.m}) {
    margin: 0px 50px;
  }
`;

ProgressTrackerTitle.displayName = 'ProgressTrackerTitle';

export default ProgressTrackerTitle;
