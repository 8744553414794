import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    margin-bottom: 25px;
    line-height: 1.5rem;
    font-size: ${rem('18px', typography.baseFontSize)};
  }
`;

NoResultsWrapper.displayName = 'NoResultsWrapper';

export default NoResultsWrapper;
