import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { colors, typography } from '@style-guide/config';

import Link from '@style-guide/mixins/Link';

const BrochureDownloadContainer = styled.div`
  color: ${colors.red};
  font-size: ${rem('14px', typography.baseFontSize)};
  margin: 0 auto 15px;
  text-align: center;

  a {
    ${Link({ type: 'red' })}

    font-weight: 600;
    margin-right: 10px;
  }
`;

BrochureDownloadContainer.displayName = 'BrochureDownloadContainer';

export default BrochureDownloadContainer;
