import styled from 'styled-components';
import { Arrow } from '@style-guide/mixins';
import { breakpoints, colors } from '@style-guide/config';

const arrowSettings = {
  arrow: 'right',
  position: 'after',
  color: colors.darkRed,
};

const LeftNavContent = styled.div`
  background-color: ${colors.white};
  max-height: 0;
  overflow: hidden;
  padding: 0;
  transition: 600ms max-height;
  width: 100%;

  &.open {
    max-height: 500vh;
  }

  a, span.invalid,
  button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    line-height: 1.4em;
    min-height: 30px;

    &:hover,
    &:active,
    &:focus {
      background-color: ${colors.lightGray};
      box-shadow: 0 0 0 1px ${colors.lightGray};
      transform: translateX(-1px);
      transition: 200ms all;

      &.active {
        background-color: ${colors.red};
        box-shadow: 0 0 0 1px ${colors.red};
      }
    }

    &.invalid:hover,
    &.invalid:active,
    &.invalid:focus {
      cursor: auto;
      background-color: ${colors.white};
      box-shadow: none;
      transform: none;
      transition: none;

      &.invalid.active {
        background-color: ${colors.white};
        box-shadow: none;
      }
    }


  }

  a, span.invalid {
    border-bottom: 1px solid ${colors.lightGray};
    color: ${colors.black};
    font-weight: 600;

    &.invalid {
      color: ${colors.darkGray}
    }

    span {
      ${Arrow(arrowSettings)};

      align-items: center;
      display: flex;

      &::after {
        margin-left: 10px;
      }
    }

    &:first-child {
      display: flex;
      font-weight: 700;
      padding: 10px 20px;
    }

    &.active {
      background-color: ${colors.red};
      border-color: ${colors.red};
      border-top: 1px solid ${colors.red};
      color: ${colors.white};
      margin-top: -1px;

      span::after {
        color: ${colors.white};
      }
    }
  }

  @media (min-width: ${breakpoints.m}) {
    padding: 20px 0 20px 10px;
    max-height: fit-content;
    width: 230px;

    a, span.invalid {
      span::after {
        content: '';
      }

      &:first-child {
        padding: 5px 15px 5px 10px;
      }
    }
  }
`;

LeftNavContent.displayName = 'LeftNavContent';

export default LeftNavContent;
