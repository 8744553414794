import styled from 'styled-components';

const TotalCasesPerPracticeAreaDiv = styled.div`
  display: block;

  @media (min-width: 1025px) {
    text-align: left;
  }  
`;

TotalCasesPerPracticeAreaDiv.displayName = 'TotalCasesPerPracticeAreaDiv';

export default TotalCasesPerPracticeAreaDiv;
