import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const EventDetailPageContainer = styled.div`
  margin: 0 auto;
  max-width: 700px;
  width: 100%;

  @media (min-width: ${breakpoints.s}) {
    padding: 0 10px;
  }

  @media (min-width: ${breakpoints.m}) {
    padding: 0 30px;
  }

  @media (min-width: ${breakpoints.l}) {
    margin: 0 auto 30px;
    max-width: 1130px;
  }
`;

EventDetailPageContainer.displayName = 'EventDetailPageContainer';

export default EventDetailPageContainer;
