import styled from 'styled-components';

const LinkAnchor = styled.a`
  color: #C3002F;
  text-decoration: underline;
  font-size: 14px;
`;

LinkAnchor.displayName = 'LinkAnchor';

export default LinkAnchor;
