import styled from 'styled-components';

const ModalOverlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
`;

ModalOverlay.displayName = 'ModalOverlay';

export default ModalOverlay;
