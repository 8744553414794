import React from 'react';
import Loadable from 'react-loadable';
import ShellLoading from '@components/ShellLoading';

const EventSearchResultsLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "EventSearchResultsStateContainer" */ './EventSearchResultsStateContainer'),
  loading() {
    return <ShellLoading height="50vh" />;
  },
});

const EventSearchResultsAsync = props => <EventSearchResultsLoadable {...props} />;

export default EventSearchResultsAsync;
