import styled from 'styled-components';

import {
  colors,
  breakpoints,
} from '@style-guide/config';

const ExperienceListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  &:not(:first-child) {
    border-top: 1px solid ${colors.gray};
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
    width: 100%;
  }
`;

ExperienceListItem.displayName = 'ExperienceListItem';

export default ExperienceListItem;
