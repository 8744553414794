import styled from 'styled-components';

const HeaderDiv = styled.div`
  display: flex;
  margin: 5px;
  align-items: center;

  @media (min-width: 320px) and (max-width: 1024px) {
    align-self: center;
  }
`;

HeaderDiv.displayName = 'HeaderDiv';

export default HeaderDiv;
