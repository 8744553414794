import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveContactInfo } from '@redux/modules/contactInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import ContactInfoForm from './components/ContactInfoForm';

class ContactInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasWarning: false,
    };

    this.setWarning = this.setWarning.bind(this);
    this.submit = this.submit.bind(this);
  }

  setWarning(hasWarning) {
    this.setState({
      hasWarning,
    });
  }

  submit(formValues) {
    this.props.dispatch(saveContactInfo(formValues));
  }

  render() {
    const {
      contactInfo,
      loading,
      states,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <ContactInfoForm
                initialValues={contactInfo}
                onSubmit={this.submit}
                states={states}
                loading={loading}
                hasSaveError={!!error}
                setWarning={this.setWarning}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
          hasWarning={this.state.hasWarning}
          title="Contact Information"
        />
      </FormAccordionContainer>
    );
  }
}

ContactInfoContent.propTypes = {
  contactInfo: PropTypes.shape({
    HomePhoneNumberAreaCode: PropTypes.string,
    HomePhoneNumber: PropTypes.string,
    CellPhoneNumberAreaCode: PropTypes.string,
    CellPhoneNumber: PropTypes.string,
    WorkPhoneNumberAreaCode: PropTypes.string,
    FaxNumberAreaCode: PropTypes.string,
    PreferredPhone: PropTypes.string,
    PersonalEmailAddress: PropTypes.string,
    IsPersonalEmailPreferred: PropTypes.bool,
    WorkOrSchoolEmailAddress: PropTypes.string,
    IsWorkOrSchoolEmailPreferred: PropTypes.bool,
    AddressLine1: PropTypes.string,
    AddressLine2: PropTypes.string,
    City: PropTypes.string,
    State: PropTypes.string,
    ZipCode: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ContactInfoContent.defaultProps = {
  error: '',
  loading: false,
};

const mapStateToProps = ({ contactInfo }) => ({
  contactInfo,
  error: contactInfo.error,
  loading: contactInfo.loading,
});

ContactInfoContent.displayName = 'ContactInfoContent';

export default connect(mapStateToProps)(ContactInfoContent);
