import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import transparentize from 'polished/lib/color/transparentize';
import CookieUtil from '@services/Cookie';
import { Link } from '@style-guide/mixins';
import { colors, typography } from '@style-guide/config';

const FlyoutHelpContainer = styled.div`
  padding: 10px 0;

  a {
    ${Link({ type: 'white' })}

    font-size: ${rem('12px', typography.baseFontSize)};
    font-style: italic;
    font-weight: 500;
    margin-left: 10px;
  }

  p {
    line-height: 1.5em;
    margin: 0 10px;
  }
`;

FlyoutHelpContainer.displayName = 'FlyoutHelpContainer';

const Divider = styled.div`
  border-bottom: 1px solid ${transparentize(0.8, colors.white)};
  margin-bottom: 20px;
  padding-top: 20px;
`;

Divider.displayName = 'Divider';

const removeSpecialization = () => {
  CookieUtil.remove('cookie.visitor', {
    path: '/',
  });
};

const FlyoutHelp = ({
  description,
  undoSelection,
}) => (
  <FlyoutHelpContainer>
    <a
      href="/?nr"
      onClick={removeSpecialization}
      id="flyoutClearPreferences"
    >
      {undoSelection}
    </a>
    <Divider />
    <p>
      {description}
    </p>
  </FlyoutHelpContainer>
);

FlyoutHelp.propTypes = {
  description: PropTypes.string.isRequired,
  undoSelection: PropTypes.string.isRequired,
};

FlyoutHelp.displayName = 'FlyoutHelp';

export default FlyoutHelp;
