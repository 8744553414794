import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveSelectedTopicOfInterests } from '@redux/modules/CommunicationPreferences/TopicOfInterests';

import styled from 'styled-components';

import ErrorMessage from '@components/ErrorMessage';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import TopicOfInterestsForm from './components/TopicOfInterestsForm';

const HeadingDiv = styled.div`
  background: #263746;
  color: #FFF;
  font-family: 'proxima-nova', 'Helvetica Nueue', sans-serif;
  font-weight: 500;
  padding: 12px;
`;

HeadingDiv.displayName = 'HeadingDiv';

const ContentDiv = styled.div`
  background: #FFF;
  padding: 12px;
`;

ContentDiv.displayName = 'ContentDiv';


class TopicOfInterestsBlockContent extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(formValues) {
    const {
      topicOfInterests,
      dispatch,
    } = this.props;


    // since the Redux checkbox group requires just an array of strings, that's all we get back
    // to determine which ones are checked.  So we need to filter through all the practice areas
    // and look to see which items are in the array of strings (meaning the user has checked them)
    const selected = topicOfInterests.filter(item =>
      formValues.SelectedTopicOfInterests.indexOf(item.name) > -1);

    dispatch(saveSelectedTopicOfInterests({ SelectedTopicOfInterests: selected }));
  }

  render() {
    const {
      loading,
      topicOfInterests,
      description,
      SelectedTopicOfInterests,
      title,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <React.Fragment>

        <FormContainer>
          <HeadingDiv>{!title ? 'Topics of Interest' : title}</HeadingDiv>
          {loading && <LoadingOverlay />}
          <ContentDiv>
            <p>
              {!description ? "While you're here... Please take a moment to let the OSBA know what topics of interest you would like to see more of:" : description}
            </p>
            {error && (
              <ErrorMessage>
                <span>{error}</span>
              </ErrorMessage>
            )}

            <TopicOfInterestsForm
              topicOfInterests={topicOfInterests}
              initialValues={{ SelectedTopicOfInterests }}
              onSubmit={this.handleSubmit}
              loading={loading}
              hasSaveError={!!error}
            />
          </ContentDiv>
        </FormContainer>

      </React.Fragment>
    );
  }
}

TopicOfInterestsBlockContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  SelectedTopicOfInterests: PropTypes.arrayOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  topicOfInterests: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string,
  })),
  description: PropTypes.string.isRequired,
};

TopicOfInterestsBlockContent.defaultProps = {
  error: '',
  loading: false,
  title: 'Topic Of Interests',
  topicOfInterests: [],
};

const mapStateToProps = ({ topicOfInterestInfo }) => ({
  error: topicOfInterestInfo.error,
  loading: topicOfInterestInfo.loading,
  SelectedTopicOfInterests: topicOfInterestInfo.SelectedTopicOfInterests,
});


TopicOfInterestsBlockContent.displayName = 'TopicOfInterestsBlockContent';

export default connect(mapStateToProps)(TopicOfInterestsBlockContent);
