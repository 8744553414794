import styled from 'styled-components';

const EBarJobsResultDiv = styled.div`
  display: block;
  text-align: left;
  padding: 14px 28px 0px;
  font-size: 14px;
`;

EBarJobsResultDiv.displayName = 'EBarJobsResultDiv';

export default EBarJobsResultDiv;
