import styled from 'styled-components';

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;

DetailsWrapper.displayName = 'DetailsWrapper';

export default DetailsWrapper;
