import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import concat from 'lodash/concat';
import { breakpoints, colors } from '@style-guide/config';
import FooterAccordion from './components/FooterAccordion';

const GlobalStyle = createGlobalStyle`
  [data-react-component="FooterPrimaryLinks"] {
    flex-grow: 1;
    width: 80%;
  }
`;

const PrimaryLinksContainer = styled.div`
  background-color: ${colors.lightGray};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  padding: 0;
  width: 100%;
  font-size: 14px;

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    padding: 80px 55px;
  }
`;

PrimaryLinksContainer.displayName = 'PrimaryLinksContainer';

const FooterPrimaryLinks = ({
  linkGroups = [],
  linkGroupsMember = [],
  linkGroupsNonMember = [],
  linkGroupsLoggedOut = [],
  isAuthenticated = false,
  isMember = false,
}) => {
  let visibleLinkGroups = linkGroups;
  let userRole;

  if (isAuthenticated) {
    if (isMember) {
      visibleLinkGroups = concat(linkGroupsMember, visibleLinkGroups);
      userRole = 'authedMember';
    } else {
      visibleLinkGroups = concat(linkGroupsNonMember, visibleLinkGroups);
      userRole = 'authedNonMember';
    }
  } else {
    visibleLinkGroups = concat(linkGroupsLoggedOut, visibleLinkGroups);
    userRole = 'unauthed';
  }

  const linkGroupsWithIDs = visibleLinkGroups.map((linkGroup, index) => ({
    id: index,
    ...linkGroup,
  }));

  const linkGroupAccordions = linkGroupsWithIDs.map((linkGroup, index) => {
    if (index === 0) {
      return (
        <FooterAccordion
          key={linkGroup.id}
          linkGroup={linkGroup}
          groupName={`footerLinks${userRole}`}
        />
      );
    }

    return (
      <FooterAccordion
        key={linkGroup.id}
        linkGroup={linkGroup}
        groupName={`footerPrimaryLinks${index}`}
      />
    );
  });

  return (
    <React.Fragment>
      <GlobalStyle />
      <PrimaryLinksContainer
        id="footerPrimaryLinksContainer"
      >
        {linkGroupAccordions}
      </PrimaryLinksContainer>
    </React.Fragment>
  );
};

FooterPrimaryLinks.propTypes = {
  linkGroups: PropTypes.arrayOf(PropTypes.shape({
    titleLinkData: PropTypes.instanceOf(Object),
    children: PropTypes.instanceOf(Array),
  })),
  linkGroupsMember: PropTypes.arrayOf(PropTypes.shape({
    titleLinkData: PropTypes.instanceOf(Object),
    children: PropTypes.instanceOf(Array),
  })),
  linkGroupsNonMember: PropTypes.arrayOf(PropTypes.shape({
    titleLinkData: PropTypes.instanceOf(Object),
    children: PropTypes.instanceOf(Array),
  })),
  linkGroupsLoggedOut: PropTypes.arrayOf(PropTypes.shape({
    titleLinkData: PropTypes.instanceOf(Object),
    children: PropTypes.instanceOf(Array),
  })),
  isAuthenticated: PropTypes.bool,
  isMember: PropTypes.bool,
};

FooterPrimaryLinks.displayName = 'FooterPrimaryLinks';

export default FooterPrimaryLinks;
