import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const FormAccordionContainer = styled.div`
  @media (min-width: ${breakpoints.l}) {
    margin: 0 30px;
  }
`;

export default FormAccordionContainer;
