import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors } from '@style-guide/config';

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${colors.charcoal};
  margin-right: 5px;
`;

StyledIcon.displayName = 'StyledIcon';

export default StyledIcon;
