import styled from 'styled-components';
import { colors } from '@style-guide/config';

const DashboardToolsSecondary = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 3px solid ${colors.darkBlue};

  @media (min-width: 1025px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

DashboardToolsSecondary.displayName = 'DashboardToolsSecondary';

export default DashboardToolsSecondary;
