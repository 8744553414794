import {
  applyMiddleware,
  createStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from './reducer';

import { middleware as affiliationsInfoMiddleware } from './modules/affiliationsInfo';
import { middleware as calqSearchMiddleware } from './modules/calqSearch';
import { middleware as cartMiddleware } from './modules/cart';
import { middleware as cleSearchMiddleware } from './modules/cleSearch';
import { middleware as committeesMiddleware } from './modules/committees';
import { middleware as companyInfoMiddleware } from './modules/companyInfo';
import { middleware as contactInfoMiddleware } from './modules/contactInfo';
import { middleware as demographicInfoMiddleware } from './modules/demographicInfo';
import { middleware as educationInfoMiddleware } from './modules/educationInfo';
import { middleware as experienceInfoMiddleware } from './modules/experienceInfo';
import { middleware as eventSearchMiddleware } from './modules/eventSearch';
import { middleware as findALawyerSearchMiddleware } from './modules/findALawyerSearch';
import { middleware as honorsInfoMiddleware } from './modules/honorsInfo';
import { middleware as linksInfoMiddleware } from './modules/linksInfo';
import { middleware as lawSchoolInfoMiddleware } from './modules/lawSchoolInfo';
import { middleware as marketingPreferencesInfoMiddleware } from './modules/marketingPreferencesInfo';
import { middleware as mediaInfoMiddleware } from './modules/mediaInfo';
import { middleware as overviewInfoMiddleware } from './modules/overviewInfo';
import { middleware as personalInfoMiddleware } from './modules/personalInfo';
import { middleware as practiceAreaMiddleware } from './modules/practiceAreas';
import { middleware as topicOfInterestsMiddleware } from './modules/CommunicationPreferences/TopicOfInterests';
import { middleware as memberBenefitsMiddleware } from './modules/CommunicationPreferences/MemberBenefitsInfo';
import { middleware as eNewsLettersMiddleware } from './modules/CommunicationPreferences/ENewsLettersInfo';
import { middleware as practiceLibraryMiddleware } from './modules/practiceLibrarySearch';
import { middleware as sectionsMiddleware } from './modules/sections';
import { middleware as siteSearchMiddleware } from './modules/siteSearch';
import { middleware as socialMediaMiddleware } from './modules/socialMediaInfo';
import { middleware as supremeCourtNumberMiddleware } from './modules/supremeCourtNumber';
import { middleware as eBarHomePageInfoMiddleware } from './modules/ebarHomePageInfo';

class Store {
  initialize() {
    this.store = createStore(
      reducer,
      {},
      composeWithDevTools(applyMiddleware(
        affiliationsInfoMiddleware,
        calqSearchMiddleware,
        cartMiddleware,
        cleSearchMiddleware,
        committeesMiddleware,
        companyInfoMiddleware,
        contactInfoMiddleware,
        demographicInfoMiddleware,
        educationInfoMiddleware,
        experienceInfoMiddleware,
        eventSearchMiddleware,
        findALawyerSearchMiddleware,
        honorsInfoMiddleware,
        lawSchoolInfoMiddleware,
        linksInfoMiddleware,
        marketingPreferencesInfoMiddleware,
        mediaInfoMiddleware,
        overviewInfoMiddleware,
        personalInfoMiddleware,
        practiceAreaMiddleware,
        topicOfInterestsMiddleware,
        memberBenefitsMiddleware,
        eNewsLettersMiddleware,
        practiceLibraryMiddleware,
        sectionsMiddleware,
        siteSearchMiddleware,
        socialMediaMiddleware,
        supremeCourtNumberMiddleware,
        eBarHomePageInfoMiddleware,
      )),
    );
  }

  get() {
    return this.store;
  }
}

export default new Store();
