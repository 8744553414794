import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';

import { colors, typography } from '@style-guide/config';
import Button from '@style-guide/components/Button';

import ErrorMessage from '@components/ErrorMessage';

const BookMarkListItem = styled.li`
  font-size: ${rem('16px', typography.baseFontSize)};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  transition: opacity .3s linear;
  transition: margin-bottom .3s linear;
  position: relative;

  &.fadeOut {
    opacity: 0;
    height: 0;
    margin-bottom: 0px;
  }
`;

const BookMarkListItemTitle = styled.a`
  text-decoration: underline;
  color: ${colors.black};
  display: inline-block;
  padding-right: 20px;

  &:hover {
    color: ${colors.red};
  }
`;

const BookmarkErrorMessage = styled(ErrorMessage)`
  position: absolute;
  width: 100%;
  background: rgba(255,255,255,.8);
  height: 100%;
  margin: 0px;
  opacity: 0;
  transition: opacity .3s linear;

  &::after {
    display: inline-block;
    margin: 0px 10px;
    position: relative;
  }

  &.fadeIn {
    opacity: 1;
  }

  &.fadeOut {
    opacity: 0;
  }
`;

const CopyWrapper = styled.p`
  font-size: 1rem;
`;

CopyWrapper.displayName = 'CopyWrapper';

const Wrapper = styled.p`
  font-size: 1rem;
  display: flex;
`;

Wrapper.displayName = 'Wrapper';

const RemoveButton = styled.span`
  position: absolute;
  right: 0px;
`;

RemoveButton.displayName = 'RemoveButton';

const CaseTitle = styled.span`
  font-weight: bold;
`;

CaseTitle.displayName = 'CaseTitle';

const PracticeArea = styled.span`
  display: block;
  color: ${colors.darkRed};
  font-size: ${rem('14px', typography.baseFontSize)};
  font-style: italic;
  font-weight: 600;
`;

PracticeArea.displayName = 'PracticeArea';

const PDFLink = styled.span`
  text-decoration: underline;
`;

PDFLink.displayName = 'PDFLink';

class GreenbookBookmarkListItem extends React.Component {
  constructor(props) {
    super(props);

    this.errorMessageRef = React.createRef();
    this.listItemRef = React.createRef();
  }

  componentDidUpdate() {
    switch (this.props.itemState) {
      case 'error': {
        const errorMessageNode = this.errorMessageRef.current;
        // after the loading state goes away, pop up the error message after 300ms
        errorMessageNode.className += ' fadeIn';
        setTimeout(() => {
          // after the error message comes up, make it disappaer after 1800 ms
          errorMessageNode.className += ' fadeOut';
          setTimeout(() => {
            // reset it back to active state
            this.props.resetToActiveHandler(this.props.guid, this.props.contentType);
          }, 500);
        }, 1800);
        break;
      }
      case 'remove': {
        // after the loading state goes away, pop up the error message after 300ms
        const listItemNode = this.listItemRef.current;
        listItemNode.className += ' fadeOut';
        setTimeout(() => {
          this.props.removeFromPageHandler(this.props.guid, this.props.contentType);
        }, 300);
        break;
      }
      default:
        break;
    }
  }

  render() {
    return (
      <BookMarkListItem ref={this.listItemRef} key={this.props.guid}>
        {this.props.itemState === 'error' && (
          <BookmarkErrorMessage
            ref={this.errorMessageRef}
          >
            Error removing bookmark
          </BookmarkErrorMessage>
        )}
        <CopyWrapper>
          <PracticeArea>{this.props.practiceName}</PracticeArea>
        </CopyWrapper>
        <CopyWrapper>
          <CaseTitle>{this.props.title}</CaseTitle>
        </CopyWrapper>
        <CopyWrapper>
          {(this.props.caseName != null) && (
          <span>{this.props.caseName} | </span>
          )}
          <span>{this.props.cite}</span>
          {(this.props.cite != null && this.props.date != null) && (
          <span>&nbsp;|&nbsp;</span>
          )}
          {(this.props.courtName != null) && (
          <span>{this.props.courtName} | </span>
          )}
          {this.props.dateType != null && this.props.date != null && (
          <span>{this.props.dateType}: </span>
          )}
          <span>{this.props.date != null ? moment(this.props.date).format('LL') : ''}</span>
          <br />
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: this.props.summary }} />
        </CopyWrapper>
        <Wrapper>
          {this.props.ctaLabel != null && (
          <PDFLink>
            <a
              href={this.props.ctaUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              &gt; {this.props.ctaLabel}
            </a>
          </PDFLink>
          )}
          <RemoveButton>
            <Button onClick={() => this.props.deleteBookmarkHandler(this.props.guid,
              this.props.contentType)}
            >
              REMOVE&nbsp;<FontAwesomeIcon icon={faBookmark} />
            </Button>
          </RemoveButton>
        </Wrapper>
        {(this.props.ctaLabel == null) && (
          <br />
        )}
      </BookMarkListItem>
    );
  }
}

GreenbookBookmarkListItem.propTypes = {
  itemState: PropTypes.string,
  resetToActiveHandler: PropTypes.func.isRequired,
  removeFromPageHandler: PropTypes.func.isRequired,
  deleteBookmarkHandler: PropTypes.func.isRequired,
  guid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  caseName: PropTypes.string.isRequired,
  cite: PropTypes.string.isRequired,
  dateType: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  practiceName: PropTypes.string.isRequired,
  courtName: PropTypes.string.isRequired,
};


GreenbookBookmarkListItem.defaultProps = {
  itemState: 'active',
};

BookMarkListItem.displayName = 'BookMarkListItem';
BookMarkListItemTitle.displayName = 'BookMarkListItemTitle';
BookmarkErrorMessage.displayName = 'BookmarkErrorMessage';

export default GreenbookBookmarkListItem;
