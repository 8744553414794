import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';

import { colors, typography } from '@style-guide/config';
import Button from '@style-guide/components/Button';

import ErrorMessage from '@components/ErrorMessage';

const BookMarkListItem = styled.li`
  font-size: ${rem('16px', typography.baseFontSize)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  transition: opacity .3s linear;
  transition: margin-bottom .3s linear;
  position: relative;

  &.fadeOut {
    opacity: 0;
    height: 0;
    margin-bottom: 0px;
  }
`;

const BookMarkListItemTitle = styled.a`
  text-decoration: underline;
  color: ${colors.black};
  display: inline-block;
  padding-right: 20px;

  &:hover {
    color: ${colors.red};
  }
`;

const BookmarkErrorMessage = styled(ErrorMessage)`
  position: absolute;
  width: 100%;
  background: rgba(255,255,255,.8);
  height: 100%;
  margin: 0px;
  opacity: 0;
  transition: opacity .3s linear;

  &::after {
    display: inline-block;
    margin: 0px 10px;
    position: relative;
  }

  &.fadeIn {
    opacity: 1;
  }

  &.fadeOut {
    opacity: 0;
  }
`;

class PracticeLibraryBookmarkListItem extends React.Component {
  constructor(props) {
    super(props);

    this.errorMessageRef = React.createRef();
    this.listItemRef = React.createRef();
  }

  componentDidUpdate() {
    switch (this.props.itemState) {
      case 'error': {
        const errorMessageNode = this.errorMessageRef.current;
        // after the loading state goes away, pop up the error message after 300ms
        errorMessageNode.className += ' fadeIn';
        setTimeout(() => {
          // after the error message comes up, make it disappaer after 1800 ms
          errorMessageNode.className += ' fadeOut';
          setTimeout(() => {
            // reset it back to active state
            this.props.resetToActiveHandler(this.props.pageId);
          }, 500);
        }, 1800);
        break;
      }
      case 'remove': {
        // after the loading state goes away, pop up the error message after 300ms
        const listItemNode = this.listItemRef.current;
        listItemNode.className += ' fadeOut';
        setTimeout(() => {
          this.props.removeFromPageHandler(this.props.pageId);
        }, 300);
        break;
      }
      default:
        break;
    }
  }

  render() {
    return (
      <BookMarkListItem innerRef={this.listItemRef} key={this.props.pageId}>
        {this.props.itemState === 'error' && (
          <BookmarkErrorMessage
            innerRef={this.errorMessageRef}
          >
              Error removing bookmark
          </BookmarkErrorMessage>
        )}
        <BookMarkListItemTitle href={this.props.href}>{this.props.title}</BookMarkListItemTitle>
        <Button onClick={() => this.props.deleteBookmarkHandler(this.props.pageId)}>
          REMOVE&nbsp;<FontAwesomeIcon icon={faBookmark} />
        </Button>
      </BookMarkListItem>
    );
  }
}

PracticeLibraryBookmarkListItem.propTypes = {
  itemState: PropTypes.string,
  resetToActiveHandler: PropTypes.func.isRequired,
  removeFromPageHandler: PropTypes.func.isRequired,
  deleteBookmarkHandler: PropTypes.func.isRequired,
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  pageId: PropTypes.number.isRequired,
};


PracticeLibraryBookmarkListItem.defaultProps = {
  itemState: 'active',
};

BookMarkListItem.displayName = 'BookMarkListItem';
BookMarkListItemTitle.displayName = 'BookMarkListItemTitle';
BookmarkErrorMessage.displayName = 'BookmarkErrorMessage';

export default PracticeLibraryBookmarkListItem;
