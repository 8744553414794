// the items in this list don't have ids and are view only
// (never sorted or re-ordered) so we can safely use
// array indexes as keys
/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import chunk from 'lodash/chunk';

import { colors, typography } from '@style-guide/config';
import Arrow from '@style-guide/mixins/Arrow';

import ContentBlock from './shared/ContentBlock';

const arrowSettings = {
  arrow: 'right',
  position: 'after',
  color: colors.red,
};

const LinkSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

LinkSection.displayName = 'LinkSection';

const LinkWrapper = styled.div`
  flex: 0 0 33.3333%;

  a {
    ${Arrow(arrowSettings)};

    align-items: center;
    color: ${colors.black};
    display: flex;
    font-size: ${rem('16px', typography.baseFontSize)};
    font-weight: 700;
    justify-content: space-between;
    line-height: 1.5em;
    margin: 15px 0;
    transition: transform 200ms;

    &:hover,
    &:active {
      color: ${colors.darkRed};
      transform: scale(1.0125);
    }

    &::after {
      margin: 0 30px 0 15px;
    }
  }
`;

LinkWrapper.displayName = 'LinkWrapper';

const LinksContentBlock = (props) => {
  const chunkedLinks = chunk(props.contentToRender.links, 9);

  return (
    <ContentBlock>
      {chunkedLinks.map((linkGroup, index) => (
        <LinkSection key={index}>
          {linkGroup.map(link => (
            <LinkWrapper key={link.id}>
              <p><a href={link.url}>{link.name}</a></p>
            </LinkWrapper>
          ))}
        </LinkSection>
      ))}
    </ContentBlock>
  );
};

LinksContentBlock.propTypes = {
  contentToRender: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
      deleted: PropTypes.bool,
    })),
  }).isRequired,
};

LinksContentBlock.displayName = 'LinksContentBlock';

export default LinksContentBlock;
