import ApiGenerator from '@services/ApiGenerator';

const GET = 'osba/account/PERSONAL_INFO_GET';
const GET_ERROR = 'osba/account/PERSONAL_INFO_GET_ERROR';
const GET_RECEIVED = 'osba/account/PERSONAL_INFO_GET_RECEIVED';
const SAVE = 'osba/account/PERSONAL_INFO_SAVE';
const SAVE_ERROR = 'osba/account/PERSONAL_INFO_SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/PERSONAL_INFO_SAVE_RECEIVED';

const initialState = {
  Salutation: '',
  Suffix: '',
  FirstName: '',
  MiddleName: '',
  LastName: '',
  PreferredName: '',
  OhioSupremeCourtNumber: '',
  PriorLastName: '',
  OhioSupremeCourtNumberOptOut: false,
  loading: false,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SAVE:
      return {
        ...state,
        ...action.data,
        loading: true,
        error: '',
      };
    case GET_RECEIVED:
    case SAVE_RECEIVED:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case GET_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getPersonalInfo = () => ({
  type: GET,
});

export const savePersonalInfo = data => ({
  type: SAVE,
  data: {
    FirstName: data.FirstName,
    LastName: data.LastName,
    MiddleName: data.MiddleName,
    OhioSupremeCourtNumber: data.OhioSupremeCourtNumber,
    PreferredName: data.PreferredName,
    Salutation: data.Salutation,
    Suffix: data.Suffix,
    BirthDate: data.BirthDate,
    PriorLastName: data.PriorLastName,
  },
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api('get', '/PersonalInformationForm/GetPersonalInformation', GET);
      break;
    case SAVE:
      api('post', '/PersonalInformationForm/SavePersonalInformation', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
