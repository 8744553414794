import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const EmailLink = styled.a`
  span {
    display: none;
  }

  @media (max-width: ${breakpoints.m}) {
    span {
      display: inline;
    }
  }
`;

EmailLink.displayName = 'EmailLink';

export default EmailLink;
