import React from 'react';
import { Provider } from 'react-redux';
import { getPersonalInfo } from '@redux/modules/personalInfo';
import Store from '@redux';

import PersonalInfoContent from './components/PersonalInfoContent';

class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getPersonalInfo());
  }

  render() {
    return (
      <Provider store={this.store}>
        <PersonalInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

PersonalInfo.displayName = 'PersonalInfo';

export default PersonalInfo;
