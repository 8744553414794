import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveSocialMediaInfo } from '@redux/modules/socialMediaInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import SocialMediaInfoForm from './components/SocialMediaInfoForm';

class SocialMediaInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formValues) {
    this.props.dispatch(saveSocialMediaInfo(formValues));
  }

  render() {
    const {
      loading,
      socialMediaInfo,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          title="Social Media Information"
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <SocialMediaInfoForm
                initialValues={socialMediaInfo}
                onSubmit={this.submit}
                loading={loading}
                hasSaveError={!!error}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
        />
      </FormAccordionContainer>
    );
  }
}

SocialMediaInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  socialMediaInfo: PropTypes.shape({
    TwitterUrl: PropTypes.string,
    LinkedInUrl: PropTypes.string,
    InstagramUrl: PropTypes.string,
    FacebookUrl: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

SocialMediaInfoContent.defaultProps = {
  error: '',
  loading: false,
};

const mapStateToProps = ({ socialMediaInfo }) => ({
  error: socialMediaInfo.error,
  loading: socialMediaInfo.loading,
  socialMediaInfo,
});

SocialMediaInfoContent.displayName = 'SocialMediaInfoContent';

export default connect(mapStateToProps)(SocialMediaInfoContent);
