import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, colors, typography } from '@style-guide/config';

const DashboardToolsPrimary = styled.div`
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 10px 15px;

  > div:first-child {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  h1 {
    display: flex;
    flex-direction: column;
    font-size: ${rem('28px', typography.baseFontSize)};
    justify-content: flex-end;
    padding: 5px 0 15px;

    svg {
      margin-bottom: 15px;
    }
  }

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    min-height: 70px;
    padding: 10px 0;

    h1 {
      padding: 5px 25px;

      svg {
        display: none;
      }
    }
  }
`;

DashboardToolsPrimary.displayName = 'DashboardToolsPrimary';

export default DashboardToolsPrimary;
