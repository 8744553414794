import styled from 'styled-components';

import {
  colors,
  breakpoints,
} from '@style-guide/config';

const CopyWrapper = styled.div`
  background-color: ${colors.lightGray};
  color: ${colors.black};
  padding: 30px 20px;

  svg {
    display: block;
  }

  button {
    margin-top: 20px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-top: 45px;
  }

  @media (min-width: ${breakpoints.xs}) {
    background-color: transparent;
    color: ${colors.white};
    padding: 0;
  }
`;

CopyWrapper.displayName = 'CopyWrapper';

export default CopyWrapper;
