import styled from 'styled-components';

const CaseTable = styled.table`
  width: 100%;
  table-layout: fixed;
  text-align: left !important;
  border-collapse: collapse;
  line-height: 2;
  vertical-align: middle;

  tbody tr {
    background-color: #EDEDED;
    box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 10%);
    margin-bottom: 10px;
    border-radius: 3px;
    justify-content: space-between;
  }

  tr {
    text-align: left !important;
  }

  tr:hover {
    background-color: #FFFFFF;
  }

  tr td:first-child {
    padding-left: 10px;
    padding-right: 10px;
  }

  tr td:nth-child(2) {
    padding-right: 10px;
  }

  tr td:last-child {
    vertical-align: middle;
    padding-top: 10px;
  }
`;

CaseTable.displayName = 'CaseTable';

const ColumnHeaders = styled.tr`
  color: rgb(195, 0, 47);
  margin-bottom: 10px;

  th:first-child {
    padding-left: 10px;
    width: 25%;
  }

  th:nth-child(2) {
    width: 35%;
  }

  th:nth-child(3) {
    width: 12%;
  }

  th:nth-child(4) {
    width: 12%;
  }

  th:last-child {
    text-align: center;
  }
`;

ColumnHeaders.displayName = 'ColumnHeaders';

export {
  CaseTable,
  ColumnHeaders,
};
