import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const RightRailContainer = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.l}) {
    margin-left: 40px;
    width: auto;
  }
`;

RightRailContainer.displayName = 'RightRailContainer';

export default RightRailContainer;
