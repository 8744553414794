import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';
import { colors } from '@style-guide/config';
import NavAccordionContent from './components/NavAccordionContent';
import NavAccordionToggle from './components/NavAccordionToggle';

const NavAccordionContainer = styled.div`
  background-color: ${props => colors[props.type] || colors.gray};

  &:not(:first-child) {
    border-top: 2px solid ${transparentize(0.8, colors.white)};
  }
`;

NavAccordionContainer.displayName = 'NavAccordionContainer';

class NavAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.onClick = this.onClick.bind(this);
    this.toggleOpenState = this.toggleOpenState.bind(this);
  }

  onClick() {
    window.open(this.props.href, '_self');
  }

  toggleOpenState() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
      <NavAccordionContainer
        type={this.props.type}
      >
        <NavAccordionToggle
          title={this.props.title}
          text={this.props.text}
          isOpen={this.state.isOpen}
          toggleOpenState={React.Children.count(this.props.content) > 0 ?
            this.toggleOpenState : this.onClick}
          id={`mobileHeaderNavAccordionToggle${this.props.itemIndex}`}
        />
        <NavAccordionContent
          isOpen={this.state.isOpen}
          content={this.props.content}
          id={`mobileHeaderNavAccordionContent${this.props.itemIndex}`}
        />
      </NavAccordionContainer>
    );
  }
}

NavAccordion.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  itemIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  type: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
};

NavAccordion.defaultProps = {
  type: 'gray',
  text: '',
  href: '',
};

NavAccordion.displayName = 'NavAccordion';

export default NavAccordion;
