import rem from 'polished/lib/helpers/rem';
import styled from 'styled-components';

import {
  breakpoints,
  typography,
} from '@style-guide/config';

import { WysiwygStyles } from '@style-guide/mixins';

const IntroText = styled.div`
  ${WysiwygStyles()}

  p {
    font-size: ${rem('14px', typography.baseFontSize)};
    line-height: ${rem('20px', typography.baseFontSize)};
    margin: auto;
    text-align: center;

    @media(min-width: ${breakpoints.m}) {
      font-size: ${rem('16px', typography.baseFontSize)};
      line-height: ${rem('24px', typography.baseFontSize)};
      width: 75%;
    }
  }
`;

IntroText.displayName = 'IntroText';

export default IntroText;
