import React from 'react';
import PropTypes from 'prop-types';

import CLEContentCard from '@components/CLEContentCard';
import AdditionalInstance from './components/AdditionalInstance';

const AdditionalInstances = ({ additionalInstances }) => {
  const instances = additionalInstances.map(({
    city,
    endTimeValue,
    startTimeValue,
    meetingDate,
    url,
  }, index) => (
    <AdditionalInstance
      key={url}
      city={city}
      date={meetingDate}
      time={`${startTimeValue} - ${endTimeValue}`}
      url={url}
      id={`cleDetailsAddtionalInstanceLearnMore${index}`}
    />
  ));

  return (
    <CLEContentCard>
      <h2>Additional Dates, Times and Locations</h2>
      {instances}
    </CLEContentCard>
  );
};

AdditionalInstances.displayName = 'AdditionalInstances';

AdditionalInstances.propTypes = {
  additionalInstances: PropTypes.arrayOf(PropTypes.object),
};

AdditionalInstances.defaultProps = {
  additionalInstances: [],
};

export default AdditionalInstances;
