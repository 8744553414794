import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const PracticeAreasWrapper = styled.div`
  display: none;

  @media(min-width: ${breakpoints.m}) {
    display: block;
    width: 100%;
    margin-top: 15px;
  }
`;

PracticeAreasWrapper.displayName = 'PracticeAreasWrapper';

export default PracticeAreasWrapper;
