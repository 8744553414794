import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

import ButtonWrapper from './ButtonWrapper';

const MobileButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media(min-width: ${breakpoints.l}) {
    display: none;
  }

  ${/* sc-selector */ButtonWrapper}:not(:last-of-type) {
    margin-bottom: 15px;
  }
`;

MobileButtons.displayName = 'MobileButtons';

export default MobileButtons;
