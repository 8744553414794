import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';
import styled from 'styled-components';

const Title = styled.h3`
  font-size: ${rem('24px', typography.baseFontSize)};
  line-height: ${rem('28px', typography.baseFontSize)};
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 10px;
`;

Title.displayName = 'Title';

export default Title;
