import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const DateText = ({
  startDateDaily,
  startDateWeekly,
  isDaily,
}) => (
  <div>
    {isDaily && (
      <div>
        {startDateDaily.format('ll')}
      </div>
    )}
    {!isDaily && (
      <div>
        {startDateWeekly.format('ll')} - {moment(startDateWeekly).add(4, 'days').format('ll')}
      </div>
    )}
  </div>
);

DateText.displayName = 'DateText';

DateText.propTypes = {
  startDateDaily: PropTypes.instanceOf(moment).isRequired,
  startDateWeekly: PropTypes.instanceOf(moment).isRequired,
  isDaily: PropTypes.bool.isRequired,
};

export default DateText;
