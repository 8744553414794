import React from 'react';
import PropTypes from 'prop-types';

import { courseFormat as courseFormatPropTypes } from '@services/PropTypes';
import CLEBadge from '@components/CLEBadge';
import CLEContentCard from '@components/CLEContentCard';

import DateStamp from './components/DateStamp';
import DescriptionContainer from './components/DescriptionContainer';
import DescriptionHeading from './components/DescriptionHeading';
import DescriptionPicture from './components/DescriptionPicture';

const Description = ({
  courseFormat,
  date = '',
  description = '',
  richDescription = '',
  displayPicture = '',
}) => (
  <CLEContentCard>
    <DateStamp>
      <span>Date:&nbsp;</span>
      <span>{date}</span>
    </DateStamp>
    <DescriptionHeading>Course Description</DescriptionHeading>
    <DescriptionContainer>
      <div>
        <CLEBadge
          courseFormat={courseFormat}
        />
        <DescriptionPicture
          src={displayPicture || '/Static/img/osba-default-cle.png'}
          alt={courseFormat}
          id="cleDetailCoursePicture"
        />
      </div>
      {(richDescription.length) /* eslint-disable-next-line react/no-danger */
        ? <span dangerouslySetInnerHTML={{ __html: richDescription }} />
        : <p>{description}</p>}
    </DescriptionContainer>
  </CLEContentCard>
);

Description.displayName = 'Description';

Description.propTypes = {
  courseFormat: courseFormatPropTypes.isRequired,
  date: PropTypes.string,
  description: PropTypes.string,
  richDescription: PropTypes.string,
  displayPicture: PropTypes.string,
};

export default Description;
