import React from 'react';
import PropTypes from 'prop-types';
import YellowLineSVG from '@style-guide/svg/YellowLineSVG';

import AccountEdit from './components/AccountEdit';
import DashboardToolsPrimary from './components/DashboardToolsPrimary';
import DashboardToolsSecondary from './components/DashboardToolsSecondary';
import MembershipStatus from './components/MembershipStatus';
import QuickLinks from './components/QuickLinks';

const DashboardTools = ({
  isMember = false,
  alertMessage = '',
  memberType = '',
  isMonthlyMember = false,
  membershipExpiration = '',
  quickLinks = [],
  myAccountOverviewLink,
  becomeAMemberLink,
  upgradeLink,
  autoRenew,
}) => (
  <div>
    <DashboardToolsPrimary>
      <div>
        <h1>
          <YellowLineSVG />
          <span>My Dashboard</span>
        </h1>
        <AccountEdit
          myAccountOverviewLink={myAccountOverviewLink}
        />
      </div>
      <MembershipStatus
        isMember={isMember}
        alertMessage={alertMessage}
        memberType={memberType}
        isMonthlyMember={isMonthlyMember}
        membershipExpiration={membershipExpiration}
        becomeAMemberLink={becomeAMemberLink}
        upgradeLink={upgradeLink}
        autoRenew={autoRenew}
      />
    </DashboardToolsPrimary>
    <DashboardToolsSecondary>
      <QuickLinks
        isMember={isMember}
        quickLinks={quickLinks}
      />
    </DashboardToolsSecondary>
  </div>
);

DashboardTools.propTypes = {
  isMember: PropTypes.bool,
  isMonthlyMember: PropTypes.bool,
  alertMessage: PropTypes.string,
  memberType: PropTypes.string,
  membershipExpiration: PropTypes.string,
  quickLinks: PropTypes.arrayOf(Object),
  myAccountOverviewLink: PropTypes.string.isRequired,
  becomeAMemberLink: PropTypes.string.isRequired,
  upgradeLink: PropTypes.string.isRequired,
  autoRenew: PropTypes.bool.isRequired,
};

DashboardTools.displayName = 'DashboardTools';

export default DashboardTools;
