import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const FiltersWrapper = styled.div`
  margin: 0 0 25px;

  @media(min-width: ${breakpoints.xl}) {
    flex-shrink: 0;
    margin: 0 30px 0 0;
    width: 350px;
  }
`;

FiltersWrapper.displayName = 'FiltersWrapper';

export default FiltersWrapper;
