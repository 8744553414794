import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  colors,
  typography,
} from '@style-guide/config';

const SocialLinks = styled.div`
  border-left: 1px solid ${colors.darkGray};
  display: flex;
  flex-direction: row;

  a {
    border: none;
    font-size: ${rem('24px', typography.baseFontSize)};
    margin: 0px 9px;
    padding: 0px;
  }

  a:first-child {
    border: none;
    padding: 0px;
  }

  a:last-child {
    margin-right: 0px;
  }

  @media (max-width: 430px) {
    border: none;
    justify-content: center;
    margin-top: 10px;
  }
`;

SocialLinks.displayName = 'SocialLinks';

export default SocialLinks;
