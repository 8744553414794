import styled from 'styled-components';

const FacebookLink = styled.a`
  svg {
    color: #0084b4;
  }
`;

FacebookLink.displayName = 'FacebookLink';

export default FacebookLink;
