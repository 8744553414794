import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import transparentize from 'polished/lib/color/transparentize';
import CookieUtil from '@services/Cookie';
import { colors, typography } from '@style-guide/config';
import Link from '@style-guide/mixins/Link';
import SpecializationActions from '@components/SpecializationActions';

const SpecializationSelectorContainer = styled.div`
  color: ${colors.white};

  svg {
    fill: ${colors.white};
    margin-right: 20px;
    width: 40px;
  }

  p {
    line-height: 1.5em;
  }

  ul {
    margin: 0 -25px 0 -45px;
  }

  li {
    border-bottom: 2px solid ${transparentize(0.8, colors.white)};
    padding: 0 25px 0 45px;

    &.active {
      background-color: ${colors.darkRed};
    }
  }
`;

SpecializationSelectorContainer.displayName = 'SpecializationSelectorContainer';

const SpecializationHelp = styled.div`
  margin-bottom: 30px;

  a {
    ${Link({ type: 'white' })}

    display: inline-block;
    font-size: ${rem('14px', typography.baseFontSize)};
    font-style: italic;
    font-weight: 500;
    margin: 30px 0 20px;
  }
`;

SpecializationHelp.displayName = 'SpecializationHelp';

const removeSpecialization = () => {
  CookieUtil.remove('cookie.visitor', {
    path: '/',
  });
};

const SpecializationSelector = ({
  actions,
  description,
  undoSelection,
}) => (
  <SpecializationSelectorContainer>
    <SpecializationActions
      actions={actions}
      location="mobileHeader"
    />
    <SpecializationHelp>
      <a
        href="/?nr"
        onClick={removeSpecialization}
        id="mobileHeaderSpecializationClear"
      >
        {undoSelection}
      </a>
      <p>
        {description}
      </p>
    </SpecializationHelp>
  </SpecializationSelectorContainer>
);

SpecializationSelector.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  description: PropTypes.string.isRequired,
  undoSelection: PropTypes.string.isRequired,
};

SpecializationSelector.displayName = 'SpecializationSelector';

export default SpecializationSelector;
