import styled from 'styled-components';

const AngleSpan = styled.span`
  font-weight: bold;
  color: #FDBA2B;
  padding-right: 5px;
  font-size: 14px;
`;

AngleSpan.displayName = 'AngleSpan';

export default AngleSpan;
