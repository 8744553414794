import React from 'react';
import { Provider } from 'react-redux';
import { getContactInfo } from '@redux/modules/contactInfo';
import Store from '@redux';

import ContactInfoContent from './components/ContactInfoContent';

class ContactInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getContactInfo());
  }

  render() {
    return (
      <Provider store={this.store}>
        <ContactInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

ContactInfo.displayName = 'ContactInfo';

export default ContactInfo;
