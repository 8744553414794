import React from 'react';
import PropTypes from 'prop-types';

/**
# SpecializationCreditSVG
An SVG specialization credit icon
 */
const SpecializationCreditSVG = ({
  height,
  width,
  color,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76.5 73.9"
    width={width}
    height={height}
  >
    <g>
      <path
        fill={color}
        d="M70.7,43l-5-4.2c0-0.5,0-1,0-1.6s0-1,0-1.6l5-4.2l-6.1-2.2c-0.3-1-0.7-2-1.1-2.9l3.2-5.6h-6.5
        c-0.6-0.8-1.3-1.6-2-2.4l1.1-6.4l-6.1,2.2c-0.9-0.6-1.9-1.1-2.8-1.6l-1.1-6.4l-5,4.2c-1-0.2-2-0.4-3.1-0.5L38,4.2l-3.2,5.6
        c-1,0.1-2.1,0.3-3.1,0.5l-5-4.2l-1.1,6.4c-0.9,0.5-1.8,1-2.7,1.6l-6.1-2.2l1.1,6.4c-0.7,0.8-1.4,1.5-2,2.4H9.4l3.2,5.6
        c-0.4,0.9-0.8,1.9-1.1,2.9l-6.1,2.2l5,4.2c0,0.5,0,1,0,1.6c0,0.5,0,1,0,1.6l-5,4.2l6.1,2.2c0.3,1,0.7,2,1.1,2.9l-3.2,5.6h6.5
        c0.6,0.8,1.3,1.6,2,2.4l-1.1,6.4l6.1-2.2c0.9,0.6,1.8,1.1,2.7,1.6l1.1,6.4l5-4.2c1,0.2,2,0.4,3.1,0.5l3.2,5.6l3.2-5.6
        c1-0.1,2.1-0.3,3.1-0.5l5,4.2l1.1-6.4c0.9-0.5,1.8-1,2.7-1.6l6.1,2.2l-1.1-6.4c0.7-0.8,1.4-1.5,2-2.4h6.5l-3.2-5.6
        c0.4-0.9,0.8-1.9,1.1-2.9L70.7,43z M38.2,62.6c-13.9,0-25.3-11.3-25.3-25.3S24.2,12,38.2,12s25.3,11.3,25.3,25.3
        S52.1,62.6,38.2,62.6z"
      />
      <path
        fill={color}
        d="M38.2,14.7c-12.5,0-22.6,10.1-22.6,22.6s10.1,22.6,22.6,22.6s22.6-10.1,22.6-22.6S50.7,14.7,38.2,14.7z
        M45.6,49.3c-1.8,1.3-4.3,2-7.3,2c-2.2,0-4.1-0.3-5.8-1c-1.7-0.7-3.2-1.6-4.3-2.8v-5.7h4.2l0.6,3.9c0.5,0.4,1.3,0.8,2.2,1.1
        s2,0.4,3.1,0.4c1.5,0,2.6-0.3,3.4-0.9c0.8-0.6,1.2-1.4,1.2-2.4s-0.4-1.9-1.1-2.5s-2-1.2-3.9-1.8c-3.2-0.9-5.7-2-7.3-3.3
        c-1.7-1.3-2.5-3-2.5-5.3c0-2.2,0.9-4,2.8-5.4c1.9-1.4,4.2-2.1,7-2.2c2.1,0,4,0.3,5.7,1s3.2,1.5,4.3,2.5v5.6h-4.1l-0.7-3.7
        c-0.5-0.3-1.2-0.6-2.1-0.8c-0.9-0.2-1.9-0.3-2.9-0.3c-1.5,0-2.7,0.3-3.5,0.9S33.4,30,33.4,31c0,0.9,0.4,1.7,1.3,2.3
        s2.3,1.2,4.4,1.9c3,0.8,5.3,1.9,6.8,3.3c1.6,1.4,2.3,3.2,2.3,5.4C48.3,46.1,47.4,47.9,45.6,49.3z"
      />
    </g>
  </svg>
);

SpecializationCreditSVG.propTypes = {
  /** height for the SVG. Can be px, rem, percentage, etc. */
  height: PropTypes.string,
  /** width for the SVG. Can be px, rem, percentage, etc. */
  width: PropTypes.string,
  /** a color for the icon */
  color: PropTypes.string,
};

SpecializationCreditSVG.defaultProps = {
  height: '82px',
  width: '66px',
  color: '#231F20',
};

SpecializationCreditSVG.displayName = 'SpecializationCreditSVG';

export default SpecializationCreditSVG;
