import styled from 'styled-components';

import { colors } from '@style-guide/config';

const NoLinkWrapper = styled.div`
  color: ${colors.black};
`;

NoLinkWrapper.displayName = 'NoLinkWrapper';

export default NoLinkWrapper;
