import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import FormFieldGroup from '@components/FormFieldGroup';
import FormFieldWrapper from '@components/FormFieldWrapper';
import FormFieldSubGroup from '@components/FormFieldSubGroup';
import FormHeader from '@components/FormHeader';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormTextAreaInput from '@components/ReduxFormTextAreaInput';
import ReduxFormDatePicker from '@components/ReduxFormDatePicker';
import Button from '@style-guide/components/Button';
import moment from 'moment';
import FormButtonWrapper from './components/FormButtonWrapper';

const CaseForm = ({
  handleSubmit,
  pristine,
  onClickCloseCancel,
  edit,
  onSubmit,
  invalid,
}) => (
  <form
    id="caseForm"
    onSubmit={handleSubmit(onSubmit)}
  >
    <FormHeader>{edit ? 'Update Legislative Enactment' : 'Create Legislative Enactment'}</FormHeader>
    <FormFieldGroup>
      <FormFieldWrapper $wide>
        <Field
          name="title"
          id="title"
          type="text"
          label="Title"
          component={ReduxFormInput}
          autoFocus
        />
      </FormFieldWrapper>
      <FormFieldWrapper $wide>
        <Field
          name="summary"
          id="summary"
          label="Summary"
          props={{
            rows: '5',
            maxLength: '2000',
          }}
          component={ReduxFormTextAreaInput}
        />
      </FormFieldWrapper>
      <FormFieldWrapper $wide>
        <Field
          name="billNumber"
          id="billNumber"
          type="text"
          label="Bill Number"
          component={ReduxFormInput}
        />
      </FormFieldWrapper>
      <FormFieldWrapper $wide>
        <Field
          name="ctaLabel"
          id="ctaLabel"
          type="text"
          label="CTA Label"
          component={ReduxFormInput}
        />
      </FormFieldWrapper>
      <FormFieldWrapper $wide>
        <Field
          name="ctaUrl"
          id="ctaUrl"
          type="text"
          label="CTA URL"
          component={ReduxFormInput}
        />
      </FormFieldWrapper>
      <FormFieldWrapper $wide>
        <FormFieldSubGroup>
          <Field
            component={ReduxFormDatePicker}
            id="effectiveDate"
            label="Effective Date"
            name="effectiveDate"
            scrollableYearDropdown
            showYearDropdown
            yearDropdownItemNumber={50}
          />
          <Field
            component={ReduxFormDatePicker}
            id="addedDate"
            label="Active Date"
            maxDate={moment().add(31, 'days').toDate()}
            name="addedDate"
            scrollableYearDropdown
            showYearDropdown
            yearDropdownItemNumber={50}
          />
        </FormFieldSubGroup>
      </FormFieldWrapper>
    </FormFieldGroup>
    <FormButtonWrapper>
      <Button
        type="submit"
        disabled={pristine || invalid}
      >
        {edit ? 'Update' : 'Create'}
      </Button>
      <Button
        onClick={() => onClickCloseCancel()}
      >
        Cancel
      </Button>
    </FormButtonWrapper>
  </form>
);

CaseForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    billNumber: PropTypes.string,
    effectiveDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
  }),
  onClickCloseCancel: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'caseForm',
  enableReinitialize: true,
})(CaseForm);
