import styled from 'styled-components';

import { colors } from '@style-guide/config';

const Wrapper = styled.div`
  padding: 45px 30px 30px 30px;
  background-color: ${colors.white};
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
