import React from 'react';
import PropTypes from 'prop-types';

import buildNavigationItems from './services/buildNavigationItems';
import buildVCardUrl from './services/buildVcardUrl';

import BackToTopLink from './components/BackToTopLink';
import DirectoryProfileBodyContainer from './components/DirectoryProfileBodyContainer';
import DirectoryProfileContentBlock from './components/DirectoryProfileContentBlock';
import DirectoryProfileHeader from './components/DirectoryProfileHeader';
import DirectoryProfileHeaderContainer from './components/DirectoryProfileHeaderContainer';
import DirectoryProfileNavigation from './components/DirectoryProfileNavigation';
import DirectoryProfileSecondaryContactInfo from './components/DirectoryProfileSecondaryContactInfo';
import ProfileContainer from './components/ProfileContainer';

const DirectoryProfile = (props) => {
  const {
    affiliationsContent,
    educationContent,
    employmentContent,
    headerContent,
    honorsContent,
    linksContent,
    mediaContent,
    overviewContent,
    participationContent,
  } = props;

  const navigationItems = buildNavigationItems(props);

  return (
    <ProfileContainer id="top">
      <DirectoryProfileHeaderContainer />
      <DirectoryProfileBodyContainer>
        <DirectoryProfileHeader
          blogURL={headerContent.blogUrl}
          coverImageURL={mediaContent.coverImageUrl}
          email={headerContent.workEmail}
          facebookURL={headerContent.facebook}
          instagramURL={headerContent.instagram}
          jobTitle={headerContent.jobTitle}
          linkedInURL={headerContent.linkedIn}
          name={headerContent.fullName}
          profileImageURL={mediaContent.profileImageUrl}
          twitterURL={headerContent.twitter}
          vCardUrl={buildVCardUrl()}
        />
        <DirectoryProfileSecondaryContactInfo
          companyAddress={headerContent.companyAddress}
          companyName={headerContent.companyName}
          logoURL={mediaContent.companyLogoUrl}
          officeFax={headerContent.fax}
          officePhone={headerContent.workPhone}
        />
        <DirectoryProfileNavigation
          navigationItems={navigationItems}
        />
        {navigationItems.indexOf('Overview') !== -1 && (
          <React.Fragment>
            <DirectoryProfileContentBlock
              contentBlock={overviewContent}
              id="Overview"
              title="Overview"
            />
            <BackToTopLink />
          </React.Fragment>
        )}
        {navigationItems.indexOf('Affiliations') !== -1 && (
          <React.Fragment>
            <DirectoryProfileContentBlock
              contentBlock={affiliationsContent}
              id="Affiliations"
              title="Affiliations"
            />
            <BackToTopLink />
          </React.Fragment>
        )}
        {navigationItems.indexOf('Education') !== -1 && (
          <React.Fragment>
            <DirectoryProfileContentBlock
              contentBlock={educationContent}
              id="Education"
              title="Education"
            />
            <BackToTopLink />
          </React.Fragment>
        )}
        {navigationItems.indexOf('Experience') !== -1 && (
          <React.Fragment>
            <DirectoryProfileContentBlock
              contentBlock={employmentContent}
              id="Experience"
              title="Experience"
            />
            <BackToTopLink />
          </React.Fragment>
        )}
        {navigationItems.indexOf('Honors') !== -1 && (
          <React.Fragment>
            <DirectoryProfileContentBlock
              contentBlock={honorsContent}
              id="Honors"
              title="Honors"
            />
            <BackToTopLink />
          </React.Fragment>
        )}
        {navigationItems.indexOf('Participation') !== -1 && (
          <React.Fragment>
            <DirectoryProfileContentBlock
              contentBlock={participationContent}
              id="Participation"
              title="Participation"
            />
            <BackToTopLink />
          </React.Fragment>
        )}
        {navigationItems.indexOf('Links') !== -1 && (
          <React.Fragment>
            <DirectoryProfileContentBlock
              contentBlock={linksContent}
              id="Links"
              title="Links"
            />
            <BackToTopLink />
          </React.Fragment>
        )}
      </DirectoryProfileBodyContainer>
    </ProfileContainer>
  );
};

DirectoryProfile.propTypes = {
  headerContent: PropTypes.shape({
    fullName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    jobTitle: PropTypes.string,
    workEmail: PropTypes.string,
    blogUrl: PropTypes.string,
    facebook: PropTypes.string,
    linkedIn: PropTypes.string,
    twitter: PropTypes.string,
    instagram: PropTypes.string,
    companyName: PropTypes.string,
    companyAddress: PropTypes.string,
    workPhone: PropTypes.string,
    fax: PropTypes.string,
    vCardHandler: PropTypes.func,
  }).isRequired,
  // the shape of this object could change depending on what's passed in
  /* eslint-disable react/forbid-prop-types */
  overviewContent: PropTypes.object.isRequired,
  honorsContent: PropTypes.shape({
    awards: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      dateReceived: PropTypes.string,
      deleted: PropTypes.bool,
    })).isRequired,
  }).isRequired,
  mediaContent: PropTypes.shape({
    profileImageUrl: PropTypes.string,
    coverImageUrl: PropTypes.string,
    companyLogoUrl: PropTypes.string,
  }).isRequired,
  linksContent: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
      deleted: PropTypes.string,
    })),
  }).isRequired,
  affiliationsContent: PropTypes.shape({
    certifications: PropTypes.arrayOf(PropTypes.string),
    involvement: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      position: PropTypes.string,
      involvementType: PropTypes.string,
      deleted: PropTypes.bool,
    })),
  }).isRequired,
  educationContent: PropTypes.shape({
    lineItems: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      isDeleted: PropTypes.bool,
    })).isRequired,
  }).isRequired,
  participationContent: PropTypes.shape({
    clesSpokenAt: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      date: PropTypes.string,
    })).isRequired,
  }).isRequired,
  employmentContent: PropTypes.shape({
    employmentHistory: PropTypes.arrayOf(PropTypes.shape({
      employer: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    })),
  }).isRequired,
};

export default DirectoryProfile;
