import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const FilterButton = styled.a`
  background-color: ${colors.darkRed};
  bottom: 0;
  color: ${colors.white};
  font-size: 18px;
  font-weight: bold;
  left: 0;
  letter-spacing: 1px;
  padding: 15px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 1;


  @media(min-width: ${breakpoints.m}) {
    left: calc(50% - 125px);
    width: 250px;
  }

  @media(min-width: ${breakpoints.l}) {
    display: none;
  }
`;

FilterButton.displayName = 'FilterButton';

export default FilterButton;
