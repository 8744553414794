import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const CLEDetailBodyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.l}) {
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
`;

CLEDetailBodyContainer.displayName = 'CLEDetailBodyContainer';

export default CLEDetailBodyContainer;
