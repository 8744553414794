import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const ItemName = styled.span`
  font-weight: 500;

  @media (min-width: ${breakpoints.l}) {
    max-width: 240px;
  }
`;

ItemName.displayName = 'ItemName';

export default ItemName;
