import ApiGenerator from '@services/ApiGenerator';

const GET = 'osba/supremeCourtNumber/GET';
const SAVE = 'osba/supremeCourtNumber/SAVE';
const GET_RECEIVED = 'osba/supremeCourtNumber/GET_RECEIVED';
const SAVE_RECEIVED = 'osba/supremeCourtNumber/SAVE_RECEIVED';
const GET_ERROR = 'osba/supremeCourtNumber/GET_ERROR';
const SAVE_ERROR = 'osba/supremeCourtNumber/SAVE_ERROR';

const initialState = {
  OhioSupremeCourtNumber: '',
  loading: false,
  error: null,
  AttorneyMembershipPackageType: false,
  IsMember: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET:
    case SAVE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_RECEIVED:
    case SAVE_RECEIVED:
      return {
        ...state,
        ...action.data,
        loading: false,
        error: null,
      };
    case GET_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getSupremeCourtNumber = () => ({
  type: GET,
});

export const saveSupremeCourtNumber = supremeCourtNumber => ({
  type: SAVE,
  data: supremeCourtNumber,
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api(
        'get',
        '/OrderProcessing/GetSupremeCourtNumber',
        GET,
      );
      break;
    case SAVE:
      api(
        'post',
        '/OrderProcessing/SaveSupremeCourtNumber',
        SAVE,
        {
          OhioSupremeCourtNumber: action.data,
        },
      );
      break;
    default:
      break;
  }
};

export default reducer;
