import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  /**
   * These max-widths are 95% of the breakpoint width- this is a convention I saw in Bootstrap.
   * We need to talk about creating a standard page "container",
   * but this is just what I've been using the mean time.
   */
  @media(min-width: ${breakpoints.s}) {
    max-width: 456px;
  }
  @media(min-width: ${breakpoints.m}) {
    max-width: 684px;
  }

  @media(min-width: ${breakpoints.l}) {
    flex-direction: row;
    max-width: 855px;
  }

  @media(min-width: ${breakpoints.xl}) {
    max-width: 1140px;
  }
`;

WrapperInner.displayName = 'WrapperInner';

export default WrapperInner;
