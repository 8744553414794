import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, colors, typography } from '@style-guide/config';

const ErrorMessage = styled.p`
  align-items: center;
  color: ${props => (props.$warn ? colors.black : colors.red)};
  display: flex;
  font-size: ${rem('16px', typography.baseFontSize)};
  font-weight: 500;
  justify-content: center;
  margin: 15px 0;
  max-width: ${breakpoints.s};
  padding: 0 35px;
  position: relative;
  text-align: center;

  a {
    text-decoration: underline;
  }

  &::after {
    color: ${colors.yellow};
    content: '\\f071';
    font-family: 'Font Awesome 5 Free', sans-serif;
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    position: absolute;
    right: 0;
  }
`;

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
