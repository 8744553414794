import React from 'react';
import PropTypes from 'prop-types';

import TextAreaInput from '@style-guide/components/TextAreaInput';

import {
  reduxInputPropType,
  reduxMetaPropType,
} from '@services/PropTypes';

import FieldValidationError from '@components/FieldValidationError';

const ReduxFormTextAreaInput = ({
  input,
  label = '',
  meta: {
    touched,
    error,
  },
  ...restProps
}) => (
  <div>
    <TextAreaInput
      {...input}
      label={label}
      invalid={!!(touched && error)}
      {...restProps}
    />
    {touched && error && (
      <FieldValidationError>{error}</FieldValidationError>
    )}
  </div>
);

ReduxFormTextAreaInput.propTypes = {
  input: reduxInputPropType.isRequired,
  label: PropTypes.string,
  meta: reduxMetaPropType.isRequired,
};

ReduxFormTextAreaInput.displayName = 'ReduxFormTextAreaInput';

export default ReduxFormTextAreaInput;
