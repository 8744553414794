import React from 'react';
import { Provider } from 'react-redux';
import { getSelectedTopicOfInterests } from '@redux/modules/CommunicationPreferences/TopicOfInterests';

import Store from '@redux';


import TopicOfInterestsBlockContent from './TopicOfInterestsBlockContent';


class TopicOfInterestBlock extends React.Component {
  constructor(props) {
    super(props);
    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getSelectedTopicOfInterests());
  }

  render() {
    return (
      <Provider store={this.store}>
        <TopicOfInterestsBlockContent
          {...this.props}
        />
      </Provider>
    );
  }
}

TopicOfInterestBlock.displayName = 'TopicOfInterestBlock';

export default TopicOfInterestBlock;
