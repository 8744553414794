import styled from 'styled-components';

import { colors } from '@style-guide/config';

const ModalBody = styled.div`
  background-color: ${colors.red};
  color: ${colors.white};
  padding: 25px;
  text-align: center;

  -ms-overflow-style: none;  /* IE 10+ */
  overflow: -moz-scrollbars-none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }
`;

ModalBody.displayName = 'ModalBody';

export default ModalBody;
