import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '@style-guide/components/Button';

import FormFieldSet from '@components/FormFieldSet';
import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormCheckboxGroup from '@components/ReduxFormCheckboxGroup';

class PracticeAreasForm extends React.Component {
  // the ReduxFormCheckboxGroup just requires a array of strings to make checkboxes from
  // we receive an array of objects with ID so we need to flatten it to just an array of names
  static flattenPracticeAreaArray(whichArray) {
    return (whichArray.map(item => item.name));
  }

  render() {
    return (
      <form
        id="practiceAreasForm"
        onSubmit={values => this.props.handleSubmit(values)}
      >
        <FormFieldWrapper>
          <FormFieldSet>
            <Field
              name="SelectedPracticeAreas"
              props={{
                options: PracticeAreasForm.flattenPracticeAreaArray(this.props.practiceAreas),
                columns: 4,
              }}
              onChange={this.props.checkLimitHandler}
              component={ReduxFormCheckboxGroup}
            />
          </FormFieldSet>
        </FormFieldWrapper>
        <Button
          type="submit"
          disabled={(this.props.pristine || this.props.loading) || this.props.hasSaveError}
        >
          Save
        </Button>
      </form>
    );
  }
}


PracticeAreasForm.propTypes = {
  practiceAreas: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hasSaveError: PropTypes.bool,
  pristine: PropTypes.bool,
  checkLimitHandler: PropTypes.func.isRequired,
};


PracticeAreasForm.defaultProps = {
  hasSaveError: false,
  loading: true,
  practiceAreas: [],
  pristine: true,
};

PracticeAreasForm.displayName = 'PracticeAreasForm';

export default reduxForm({
  form: 'practiceAreasForm',
  enableReinitialize: true,
})(PracticeAreasForm);
