import styled from 'styled-components';

import { colors } from '@style-guide/config';

const RemoveButton = styled.div`
  color: ${colors.red};
  display: flex;
  flex-direction: row;
  margin: 15px 0 0 0;
  cursor: pointer;
  align-items: center;
  line-height: 28px;

  svg {
    margin-right: 4px;
  }
`;

RemoveButton.displayName = 'RemoveButton';

export default RemoveButton;
