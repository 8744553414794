import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '@style-guide/components/Button';

import FormFieldSet from '@components/FormFieldSet';
import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormCheckboxGroup from '@components/ReduxFormCheckboxGroup';

const CommitteesForm = ({
  handleSubmit,
  committees = [],
  loading,
  invalid,
  pristine,
  hasSaveError = false,
}) => (
  <form
    id="CommitteesForm"
    onSubmit={handleSubmit}
  >
    <FormFieldWrapper>
      <FormFieldSet
        label="Committees"
      >
        <Field
          name="Committees"
          props={{
            options: committees.Committees,
            columns: 3,
          }}
          component={ReduxFormCheckboxGroup}
        />
      </FormFieldSet>
    </FormFieldWrapper>
    <Button
      type="submit"
      disabled={((pristine || loading) && !hasSaveError) || invalid}
    >
      Save
    </Button>
  </form>
);

CommitteesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  committees: PropTypes.shape({
    Committees: PropTypes.arrayOf(Object),
    error: PropTypes.string,
    loading: PropTypes.bool,
    message: PropTypes.string,
  }),
  invalid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  hasSaveError: PropTypes.bool,
};

CommitteesForm.displayName = 'CommitteesForm';

export default reduxForm({
  form: 'committeesForm',
  enableReinitialize: true,
})(CommitteesForm);
