import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints, colors } from '@style-guide/config';

import ContentBlock from './shared/ContentBlock';

const AffiliationContentBlock = styled(ContentBlock)`
  display: block;

  h3 {
    margin-bottom: 10px;
  }

  @media (min-width: ${breakpoints.m}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

AffiliationContentBlock.displayName = 'AffiliationContentBlock';

const AffiliationColumn = styled.div`
  flex: 1;
  margin-right: 30px;

  div:last-child {
    border-bottom: none;
  }
`;

AffiliationColumn.displayName = 'AffiliationColumn';

const AffiliationSection = styled(ContentBlock)`
  padding: 10px 20px 10px 0;
  margin: 0px 0 10px 0px;

  @media (min-width: ${breakpoints.m}) {
    border-bottom: 1px solid ${colors.darkGray};
  }
`;

AffiliationSection.displayName = 'AffiliationSection';

const AffiliationsContentBlock = ({ contentToRender }) => {
  const {
    certifications,
    involvement,
  } = contentToRender;
  const professionalOrganizationArray = involvement.filter(item => item.involvementType === 'Professional Organization');
  const sectionArray = involvement.filter(item => item.involvementType === 'Section');
  const committeeArray = involvement.filter(item => item.involvementType === 'Committee');
  const barAssociationArray = involvement.filter(item => item.involvementType === 'Bar Association');
  const otherDesignationArray = involvement.filter(item => item.involvementType === 'Other Designation');

  return (
    <AffiliationContentBlock>
      {(certifications.length > 0
        ||
        committeeArray.length > 0
        ||
        otherDesignationArray.length > 0
      ) && (
        <AffiliationColumn>
          {certifications.length > 0 && (
            <AffiliationSection>
              <h3>CERTIFICATIONS/SPECIALIZATIONS</h3>
              {contentToRender.certifications.map(certification => (
                <p key={certification}>{certification}</p>
              ))}
            </AffiliationSection>
          )}
          {committeeArray.length > 0 && (
            <AffiliationSection>
              <h3>COMMITTEES AND LEADERSHIP POSTS THEREIN</h3>
              {committeeArray.map(committee => (
                <p key={committee.name}>
                  <span>Committee: </span>{committee.name} {committee.position !== '' ? <React.Fragment><span>Position: </span>{committee.position}</React.Fragment> : ''}
                </p>
              ))}
            </AffiliationSection>
          )}
          {otherDesignationArray.length > 0 && (
            <AffiliationSection>
              <h3>OTHER DESIGNATIONS</h3>
              {otherDesignationArray.map(otherDesignation => (
                <p key={otherDesignation.name}>
                  <span>Designation: </span>{otherDesignation.name} {otherDesignation.position !== '' ? <React.Fragment><span>Position: </span>{otherDesignation.position}</React.Fragment> : ''}
                </p>
              ))}
            </AffiliationSection>
          )}
        </AffiliationColumn>
      )}
      {(sectionArray.length > 0
        ||
        barAssociationArray.length > 0
        ||
        professionalOrganizationArray.length > 0
      ) && (
        <AffiliationColumn>
          {sectionArray.length > 0 && (
            <AffiliationSection>
              <h3>SECTIONS AND LEADERSHIP POSTS THEREIN</h3>
              {sectionArray.map(section => (
                <p key={section.name}>
                  <span>Section: </span>{section.name} {section.position !== '' ? <React.Fragment><span>Position: </span>{section.position}</React.Fragment> : ''}
                </p>
              ))}
            </AffiliationSection>
          )}
          {barAssociationArray.length > 0 && (
            <AffiliationSection>
              <h3>BAR ASSOCIATIONS AND LEADERSHIP POSTS THEREIN</h3>
              {barAssociationArray.map(barAssociation => (
                <p key={barAssociation.name}>
                  <span>Association: </span>{barAssociation.name} {barAssociation.position !== '' ? <React.Fragment><span>Position: </span>{barAssociation.position}</React.Fragment> : ''}
                </p>
              ))}
            </AffiliationSection>
          )}
          {professionalOrganizationArray.length > 0 && (
            <AffiliationSection>
              <h3>PROFESSIONAL ORGANIZATIONS AND LEADERSHIP POSTS</h3>
              {professionalOrganizationArray.map(professionalOrganization => (
                <p key={professionalOrganization.name}>
                  <span>Organization: </span>{professionalOrganization.name} {professionalOrganization.position !== '' ? <React.Fragment><span>Position: </span>{professionalOrganization.position}</React.Fragment> : ''}
                </p>
              ))}
            </AffiliationSection>
          )}
        </AffiliationColumn>
      )}
    </AffiliationContentBlock>
  );
};

AffiliationsContentBlock.propTypes = {
  contentToRender: PropTypes.shape({
    certifications: PropTypes.arrayOf(PropTypes.string),
    involvement: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      position: PropTypes.string,
      involvementType: PropTypes.string,
      deleted: PropTypes.bool,
    })),
  }).isRequired,
};

AffiliationsContentBlock.displayName = 'AffiliationsContentBlock';

export default AffiliationsContentBlock;
