import React from 'react';
import PropTypes from 'prop-types';

import TextArea from '@style-guide/components/TextArea';

import TabTitle from '../TabTitle';

const SystemRequirements = ({
  systemRequirementsHtmlString,
  meetingInstance,
}) => (
  <div>
    <TabTitle>{meetingInstance.displayName}</TabTitle>
    <TextArea
      htmlString={systemRequirementsHtmlString}
    />
  </div>
);

SystemRequirements.propTypes = {
  systemRequirementsHtmlString: PropTypes.string.isRequired,
  meetingInstance: PropTypes.shape({
    displayName: PropTypes.string,
  }).isRequired,
};

SystemRequirements.displayName = 'SystemRequirements';

export default SystemRequirements;
