import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const DirectoryProfileBodyContainer = styled.div`
  max-width: ${breakpoints.xl};
  margin: -190px auto 0 auto;
  padding: 20px;

  @media (min-width: ${breakpoints.m}) {
    padding: 20px 35px 0px;
  }
`;

DirectoryProfileBodyContainer.displayName = 'DirectoryProfileBodyContainer';

export default DirectoryProfileBodyContainer;
