import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { breakpoints } from '@style-guide/config';
import Button from '@style-guide/components/Button';

const GlobalStyle = createGlobalStyle`
  @media print {
    div[data-react-component="Hero"],
    div[data-react-component="Header"],
    #epi-quickNavigator {
      display: none !important;
    }
  }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 15px;
  width: 100%;

  button {
    margin-bottom: 30px;
  }

  @media(min-width: ${breakpoints.l}) {
    padding: 50px 25px;
  }

  @media(min-width: ${breakpoints.xl}) {
    padding: 50px 0px;
  }

  @media print {
    max-height: 100vw;
    padding: 0px;

    button {
      display: none;
    }
  }
`;

Wrapper.displayName = 'Wrapper';

const WrapperInner = styled.div`
  position: relative;
  max-width: 1140px;

  @media print {
    width: 100%;
  }
`;

WrapperInner.displayName = 'WrapperInner';

const CertificateBackground = styled.img`
  width: 100%;

  @media print {
    position: absolute;
    top: 0;
  }
`;

CertificateBackground.displayName = 'CertificateBackground';

const CertificateBody = styled.div`
  font-family: Georgia, serif;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
`;

CertificateBody.displayName = 'CertificateBody';

const TopBlockWrapper = styled.div`
  margin-top: calc((100vw - 30px) * 0.25);

  @media(min-width: ${breakpoints.l}) {
    margin-top: calc((100vw - 50px) * 0.25);
  }

  @media(min-width: ${breakpoints.xl}) {
    margin-top: calc(1140px * 0.25);
    width: 100%;
  }

  @media print {
    margin-top: calc(100vw * 0.25);
  }
`;

TopBlockWrapper.displayName = 'TopBlockWrapper';
const Heading = styled.h3`
  font-size: calc((100vw - 30px) * 0.025);
  font-weight: 800;
  margin-bottom: calc((100vw - 100px) * 0.005);
  text-align: center;
  width: 100%;

  @media(min-width: ${breakpoints.l}) {
    font-size: calc((100vw - 50px) * 0.025);
    margin-bottom: calc((100vw - 50px) * 0.005);
  }

  @media(min-width: ${breakpoints.xl}) {
    font-size: calc(1140px * 0.025);
    margin-bottom: calc(1140px * 0.005);
  }

  @media print {
    font-size: calc(100vw * 0.025);
    margin-bottom: calc(100vw * 0.005);
  }
`;

Heading.displayName = 'Heading';

const Subtitle = styled.h4`
  font-size: calc((100vw - 100px) * 0.025);
  font-weight: 400;
  margin-bottom: calc((100vw - 100px) * 0.005);
  text-align: center;
  width: 100%;

  @media(min-width: ${breakpoints.l}) {
    font-size: calc((100vw - 50px) * 0.025);
    font-weight: 400;
    margin-bottom: calc((100vw - 50px) * 0.005);
  }

  @media(min-width: ${breakpoints.xl}) {
    font-size: calc(1140px * 0.025);
    margin-bottom: calc(1140px * 0.005);
  }

  @media print {
    font-size: calc(100vw * 0.025);
    margin-bottom: calc(100vw * 0.005);
  }
`;
const TotalSubtitle = styled.h4`
  font-size: calc((100vw - 100px) * 0.025);
  font-weight: 400;
  margin-bottom: calc((100vw - 100px) * 0.005);
  width: 100%;

  @media(min-width: ${breakpoints.l}) {
    font-size: calc((100vw - 50px) * 0.025);
    font-weight: 400;
    margin-bottom: calc((100vw - 50px) * 0.005);
  }

  @media(min-width: ${breakpoints.xl}) {
    font-size: calc(1140px * 0.025);
    margin-bottom: calc(1140px * 0.005);
  }

  @media print {
    font-size: calc(100vw * 0.025);
    margin-bottom: calc(100vw * 0.005);
  }
`;
const BottomWrapper = styled.div`
  margin-top: calc((100vw - 30px) * 0.020);
  display: inline-block;
 
  @media(min-width: ${breakpoints.l}) {
    margin-top: calc((100vw - 50px) * 0.03);
  }

  @media(min-width: ${breakpoints.xl}) {
    margin-top: calc(1140px * 0.03);
  }

  @media print {
    margin-top: calc(100vw * 0.03);
  }
`;

BottomWrapper.displayName = 'BottomWrapper';

class CLECertificatePage extends React.Component {
  render() {
    const {
      courseFormat,
      date,
      displayName,
      meetingName,
      earnedCredits,
      meetingNumber,
      supreemCourtNumber,
    } = this.props;
    const earnedCredit = [];
    let fixedCredits = 0;
    if (earnedCredits != null) {
      const result = earnedCredits.reduce(// eslint-disable-line react/prop-types
        (creditsSoFar, { hours, typeName }) => {
          if (!creditsSoFar[typeName]) {
            creditsSoFar[typeName] = 0;// eslint-disable-line no-param-reassign
          }
          creditsSoFar[typeName] += parseFloat(hours);// eslint-disable-line no-param-reassign
          return creditsSoFar;
        }, {},
      );
      Object.keys(result).forEach((prop) => {
        if (result.hasOwnProperty.call(result, prop)) {
          earnedCredit.push(<TotalSubtitle>{result[prop].toFixed(2)} {prop} Hrs</TotalSubtitle>);
        }
      });
      // credits is an array of credit objects. since there could be multiple credits for a course,
      // but we only want to show one number for the credits since this is the case,
      // we need to reduce
      // down all values in the array of objects to one number
      const reducedCredits = earnedCredits.reduce(// eslint-disable-line react/prop-types
        (accumulator, currentValue) => accumulator + parseFloat(currentValue.hoursString),
        0,
      );
      fixedCredits = reducedCredits.toFixed(2);
    }
    return (
      <React.Fragment>
        <GlobalStyle />
        <Wrapper
          id="certificateWrapper"
        >
          <Button
            variant="ghost"
            onClick={window.print}
          >
            Print
          </Button>
          <WrapperInner>
            <CertificateBackground
              src="/Static/gfx/certificate-background.jpg"
            />
            <CertificateBody>
              <TopBlockWrapper>
                <Heading>This Certifies</Heading>
                <Subtitle>{displayName}</Subtitle>
                <Subtitle>{supreemCourtNumber}</Subtitle>
              </TopBlockWrapper>
              <BottomWrapper>
                <Heading>Attended/Completed</Heading>
                <Subtitle>{meetingName}</Subtitle>
                <Subtitle>{meetingNumber}</Subtitle>
                <Subtitle>{date}</Subtitle>
                <Subtitle>{courseFormat.name}</Subtitle>
                <TotalSubtitle>{fixedCredits} Total Hrs</TotalSubtitle>
                {earnedCredit}
              </BottomWrapper>
            </CertificateBody>
          </WrapperInner>
        </Wrapper>
      </React.Fragment>
    );
  }
}
CLECertificatePage.defaultProps = {
  earnedCredits: [],
  meetingNumber: '',
  supreemCourtNumber: '',
};
CLECertificatePage.propTypes = {
  credits: PropTypes.shape({
    hours: PropTypes.number,
    hoursString: PropTypes.string,
    status: PropTypes.string,
    typeName: PropTypes.string,
  }).isRequired,
  earnedCredits: PropTypes.shape({
    hours: PropTypes.number,
    hoursString: PropTypes.string,
    status: PropTypes.string,
    typeName: PropTypes.string,
  }),
  courseFormat: PropTypes.shape({
    courseFormatType: PropTypes.string,
    name: PropTypes.string,
    sourceId: PropTypes.string,
    type: PropTypes.number,
  }).isRequired,
  date: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  meetingName: PropTypes.string.isRequired,
  meetingNumber: PropTypes.string,
  supreemCourtNumber: PropTypes.string,
};

CLECertificatePage.displayName = 'CLECertificatePage';

export default CLECertificatePage;
