import React from 'react';
import Loadable from 'react-loadable';
import ShellLoading from '@components/ShellLoading';

const CLEResultsLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "CleResultsStateContainer" */ './CleResultsStateContainer'),
  loading() {
    return <ShellLoading height="50vh" />;
  },
});

const CLEResultsAsync = props => <CLEResultsLoadable {...props} />;

export default CLEResultsAsync;
