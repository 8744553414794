import styled from 'styled-components';

const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #C3002F;
  display: block;
  text-transform: uppercase;
`;

Title.displayName = 'Title';

export default Title;
