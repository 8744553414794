import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { typography } from '@style-guide/config';

const LocationBlock = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  
  > div:first-child {
    font-weight: 700;
    margin-right: 4px;
  }

  div {
    display: block;
  }

  p {
    margin-bottom: 2px;
    font-size: ${rem('16px', typography.baseFontSize)};
    line-height: ${typography.baseFontSize};
  }
`;

LocationBlock.displayName = 'LocationBlock';

export default LocationBlock;
