import styled from 'styled-components';

const HeaderDiv = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

HeaderDiv.displayName = 'HeaderDiv';

export default HeaderDiv;
