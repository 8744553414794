import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const MobileLogo = styled.div`
  @media(min-width: ${breakpoints.xl}) {
    display: none;
  }
`;

MobileLogo.displayName = 'MobileLogo';

export default MobileLogo;
