const postQuizData = async (personMeetingInstance, meetingInstance, personId) => {
  const quizPost = await fetch('/cleclassroompage/postquizdetails', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      PersonId: personId,
      ExamId: meetingInstance.ExamId,
      Passed: true,
      Score: 100,
      PercentScore: 100.00,
      Answers: personMeetingInstance.ExamQuestions.map((question, index) => ({
        Sequence: index + 1,
        QuestionCode: question.Code,
        StudentAnswer: question.Answer,
        IsCorrect: true,
        PointsEarned: 1.0,
      })),
    }),
  });
  const quizPostJson = quizPost.json();

  return quizPostJson;
};

export default postQuizData;
