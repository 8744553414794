import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => (props.$hasBottomMargin ? '30px' : '0')};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${props => (props.$hasTopMargin ? '30px' : '0')};
  max-width: 350px;
  min-width: 325px;
  padding: 0 25px 25px;  
  width: 100%;  

  &::after {
    content: "";
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 0;    
    border-bottom: 1px solid #707070;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
