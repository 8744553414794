import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import {
  faBook,
  faFile,
  faNewspaper,
  faPlayCircle,
} from '@fortawesome/free-solid-svg-icons';

import Divider from '@components/Divider';

import Category from './components/Category';
import DetailsWrapper from './components/DetailsWrapper';
import ListItem from './components/ListItem';
import Publication from './components/Publication';
import ResourceType from './components/ResourceType';
import StyledIcon from './components/StyledIcon';
import Title from './components/Title';
import Thumbnail from './components/Thumbnail';
import Wrapper from './components/Wrapper';

const ResourcePreviewList = ({
  hasBottomMargin = true,
  hasTopMargin = true,
  resources = [],
  showImages = true,
  hasDividerDecorator = false,
}) => {
  if (is.not.empty(resources)) {
    const itemsToRender = resources.map((resource, index) => {
      const icon = ((resourceType) => {
        switch (resourceType) {
          case 'Documents':
            return faNewspaper;
          case 'Videos':
            return faPlayCircle;
          case 'OnlineBooks':
            return faBook;
          case 'Articles':
          default:
            return faFile;
        }
      })(resource.resourceType);

      return (
        <li key={resource.link}>
          {!!index && (
            <Divider
              hasDecorator={hasDividerDecorator}
              margin={hasDividerDecorator ? '15px 0' : '20px 0'}
            />
          )}
          <ListItem
            href={resource.link}
          >
            {showImages && resource.imageLink && (
              <Thumbnail
                src={resource.imageLink}
              />
            )}
            <DetailsWrapper>
              {(resource.category || resource.practiceArea) && (
                <Category>{resource.category || resource.practiceArea}</Category>
              )}
              <Title>{resource.title}</Title>
              {resource.resourceType && (
                <ResourceType>
                  <StyledIcon
                    icon={icon}
                  />
                  {resource.resourceType.match(/[A-Z][a-z]+/g).join(' ')}
                </ResourceType>
              )}
              {resource.publication && <Publication>{resource.publication}</Publication>}
            </DetailsWrapper>
          </ListItem>
        </li>
      );
    });

    return (
      <Wrapper
        $hasBottomMargin={hasBottomMargin}
        $hasTopMargin={hasTopMargin}
      >
        {itemsToRender}
      </Wrapper>
    );
  }

  return null;
};

ResourcePreviewList.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    imageLink: PropTypes.string,
    category: PropTypes.string,
    title: PropTypes.string,
    resourceType: PropTypes.string,
  })),
  /** Whether or not a 30px bottom margin is applied. */
  hasBottomMargin: PropTypes.bool,
  /** Whether or not a 30px top margin is applied. */
  hasTopMargin: PropTypes.bool,
  /** Whether or not the images are shown. */
  showImages: PropTypes.bool,
  /** Whether or not the dividers have yellow decorators. */
  hasDividerDecorator: PropTypes.bool,
};


ResourcePreviewList.displayName = 'ResourcePreviewList';

export default ResourcePreviewList;
