import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { typography } from '@style-guide/config';

const Title = styled.h3`
  font-size: ${rem('32px', typography.baseFontSize)};
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 20px;
  margin-top: 15px;
`;

Title.displayName = 'Title';

export default Title;
