import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { savePersonalInfo } from '@redux/modules/personalInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import PersonalInfoForm from './components/PersonalInfoForm';

class PersonalInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasWarning: false,
    };

    this.setWarning = this.setWarning.bind(this);
    this.submit = this.submit.bind(this);
  }

  setWarning(hasWarning) {
    this.setState({
      hasWarning,
    });
  }

  submit(formValues) {
    this.props.dispatch(savePersonalInfo(formValues));
  }

  render() {
    const {
      loading,
      personalInfo,
      salutations,
      suffixes,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          title="Personal Information"
          hasWarning={this.state.hasWarning}
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <PersonalInfoForm
                initialValues={personalInfo}
                onSubmit={this.submit}
                salutations={salutations}
                suffixes={suffixes}
                loading={loading}
                hasSaveError={!!error}
                setWarning={this.setWarning}
                supremeCourtNumber={personalInfo.OhioSupremeCourtNumber}
                isMember={personalInfo.IsMember}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
        />
      </FormAccordionContainer>
    );
  }
}

PersonalInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  personalInfo: PropTypes.shape({
    Salutation: PropTypes.string,
    Suffix: PropTypes.string,
    FirstName: PropTypes.string,
    MiddleName: PropTypes.string,
    LastName: PropTypes.string,
    PreferredName: PropTypes.string,
    OhioSupremeCourtNumber: PropTypes.string,
    IsMember: PropTypes.bool,
    PriorLastName: PropTypes.string,
  }).isRequired,
  salutations: PropTypes.arrayOf(PropTypes.string),
  suffixes: PropTypes.arrayOf(PropTypes.string),
  dispatch: PropTypes.func.isRequired,
};

PersonalInfoContent.defaultProps = {
  error: '',
  loading: false,
  salutations: [],
  suffixes: [],
};

const mapStateToProps = ({ personalInfo }) => ({
  error: personalInfo.error,
  loading: personalInfo.loading,
  personalInfo: {
    ...personalInfo,
    OhioSupremeCourtNumberOptOut: !personalInfo.OhioSupremeCourtNumber,
  },
});

PersonalInfoContent.displayName = 'PersonalInfoContent';

export default connect(mapStateToProps)(PersonalInfoContent);
