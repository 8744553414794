import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  colors,
  typography,
} from '@style-guide/config';

import LinkButton from '@style-guide/components/LinkButton';

const Wrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
  margin: 30px 0;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.5);
`;

Wrapper.displayName = 'Wrapper';

const Copy = styled.h2`
  margin-bottom: 15px;
  max-width: 500px;
  line-height: ${rem('28px', typography.baseFontSize)};
  text-align: center;
`;

Copy.displayName = 'Copy';

const EditDirectoryNonMemberCTA = ({ becomeAMemberUrl = '' }) => (
  <Wrapper>
    <Copy>
      Directory pages are for Members Only - Become a Member today to connect with the community
    </Copy>
    <LinkButton
      href={becomeAMemberUrl}
    >
      JOIN TODAY
    </LinkButton>
  </Wrapper>
);

EditDirectoryNonMemberCTA.propTypes = {
  becomeAMemberUrl: PropTypes.string,
};

EditDirectoryNonMemberCTA.displayName = 'EditDirectoryNonMemberCTA';

export default EditDirectoryNonMemberCTA;
