import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, colors, typography } from '@style-guide/config';

const CLEDetailContentCard = styled.div`
  background: ${colors.white};
  margin: 10px 0;
  padding: 15px;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h2 {
    border-bottom: 1px solid ${colors.lightGray};
    color: ${colors.red};
    font-size: ${rem('14px', typography.baseFontSize)};
    font-weight: 600;
    letter-spacing: 1.2px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    text-transform: uppercase;
  }

  @media (min-width: ${breakpoints.l}) {
    margin: 15px 0;
    min-width: 350px;
    padding: 20px 30px;
  }
`;

CLEDetailContentCard.displayName = 'CLEDetailContentCard';

export default CLEDetailContentCard;
