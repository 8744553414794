import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

import DetailsItem from './DetailsItem';
import ButtonWrapper from './ButtonWrapper';

const DetailsWrapper = styled.div`
  width: 100%;

  @media(min-width: ${breakpoints.m}) {
    width: 50%;
  }

  ${DetailsItem} {
    margin-top: 5px;
  }

  ${ButtonWrapper} {
    margin-top: 15px;
  }
`;

DetailsWrapper.displayName = 'DetailsWrapper';

export default DetailsWrapper;
