import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import { colors } from '@style-guide/config';

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  margin: 30px 0;
  width: 100%;
`;

Wrapper.displayName = 'Wrapper';

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${colors.red};
  font-size: 2rem;
  margin-right: 0.5rem;
`;

StyledIcon.displayName = 'StyledIcon';

const Message = styled.span`
  align-self: center;
  font-size: 1.25rem;
`;

Message.displayName = 'Message';

const ErrorMessage = ({ message }) => (
  <Wrapper>
    <StyledIcon
      icon={faBan}
    />
    <Message>{message}</Message>
  </Wrapper>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
