import React from 'react';
import { Provider } from 'react-redux';
import { getEducationInfo } from '@redux/modules/educationInfo';
import Store from '@redux';

import EducationInfoContent from './components/EducationInfoContent';

class EduationInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getEducationInfo());
  }

  render() {
    return (
      <Provider store={this.store}>
        <EducationInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

EduationInfo.displayName = 'EduationInfo';

export default EduationInfo;
