import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const Thumbnail = styled.img`
  width: 100%;

  @media(min-width: ${breakpoints.m}) {
    padding-right: 30px;
  }
`;

Thumbnail.displayName = 'Thumbnail';

export default Thumbnail;
