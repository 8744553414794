import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Caret } from '@style-guide/mixins';
import { breakpoints, colors } from '@style-guide/config';

const LeftNavAccordion = styled.li`
  div {
    display: flex;
    justify-content: space-between;

    > *:first-child {
      flex-grow: 2;
    }
  }

  section {
    display: block;
    max-height: 0;
    overflow: hidden;
    transition: 400ms max-height;

    > ul {
      margin-left: 10px;
    }

    &.open {
      max-height: fit-content;
    }

    &:not(.open) button::after {
      border-width: 0;
    }
  }
`;

LeftNavAccordion.displayName = 'LeftNavAccordion';

const LeftNavAccordionToggle = styled.button`
  ${Caret({
    position: 'after',
    size: '6px',
  })}

  border-bottom: 1px solid ${colors.lightGray};
  border-left: 1px solid ${colors.lightGray};
  padding: 5px 25px;
  position: relative;

  &:hover {
    background: ${colors.lightGray};
  }

  &::after {
    position: absolute;
    right: 21px;
    transition: 100ms border;
  }

  &.open::after {
    right: 19px;
    margin-top: -2px;
    margin-left: -2px;
  }

  @media (min-width: ${breakpoints.m}) {
    padding: 5px 15px;

    &::after {
      padding: 4px;
      right: 12px;
    }

    &.open::after {
      right: 10px;
    }
  }
`;

LeftNavAccordionToggle.displayName = 'LeftNavAccordionToggle';

class LeftNavAccordionNode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Name: props.Name,
      Link: props.Link,
      IsActive: props.IsActive,
      ChildNodes: props.ChildNodes,
      IsOpen: props.IsOpen,
    };
  }

  toggleOpenState() {
    this.setState({
      IsOpen: !this.state.IsOpen,
    });
  }

  render() {
    const {
      IsActive,
      IsOpen,
      Link,
      Name,
      ChildNodes,
    } = this.state;

    return (
      <LeftNavAccordion>
        <div>
          <a
            className={IsActive ? 'active' : ''}
            href={Link}
          >
            <span>{Name}</span>
          </a>
          <LeftNavAccordionToggle
            className={IsOpen ? 'open' : ''}
            onClick={() => this.toggleOpenState()}
            aria-label="expand accordion"
            aria-expanded={IsOpen}
          />
        </div>
        <section className={IsOpen ? 'open' : ''}>
          <ul>
            {ChildNodes}
          </ul>
        </section>
      </LeftNavAccordion>
    );
  }
}

LeftNavAccordionNode.propTypes = {
  Name: PropTypes.string,
  Link: PropTypes.string,
  IsActive: PropTypes.bool,
  ChildNodes: PropTypes.arrayOf(PropTypes.object),
  IsOpen: PropTypes.bool,
};

LeftNavAccordionNode.defaultProps = {
  Name: '',
  Link: '',
  IsActive: false,
  ChildNodes: [],
  IsOpen: false,
};

LeftNavAccordionNode.displayName = 'LeftNavAccordionNode';

export default LeftNavAccordionNode;
