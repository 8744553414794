import styled from 'styled-components';

const HelpText = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  > *:last-child {
    margin: 0 10px;
  }
`;

HelpText.displayName = 'HelpText';

export default HelpText;
