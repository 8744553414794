const findALawyerSearchParamBuilder = (params) => {
  const defaultOpts = {
    firstName: '',
    lastName: '',
    lawFirm: '',
    keyword: '',
    locations: [],
    practiceAreas: [],
    areasOfLaw: [],
    languages: [],
    certifications: [],
  };
  const newOpts = Object.assign(defaultOpts, params);
  const {
    firstName,
    lastName,
    lawFirm,
    keyword,
    locations,
    practiceAreas,
    areasOfLaw,
    languages,
    certifications,
  } = newOpts;

  // we have to stringify the object, then decode it, because we are encoding it again below
  const locationsString = decodeURIComponent(JSON.stringify(locations));
  const practiceAreasString = decodeURIComponent(JSON.stringify(practiceAreas));
  const areasOfLawString = decodeURIComponent(JSON.stringify(areasOfLaw));
  const languagesString = decodeURIComponent(JSON.stringify(languages));
  const certificationsString = decodeURIComponent(JSON.stringify(certifications));

  return `?sort=random&search=${encodeURIComponent(`{"keyword":"${keyword}","firstName":"${firstName}","lastName":"${lastName}","lawFirm":"${lawFirm}","locations":${locationsString},"practiceAreas":${practiceAreasString},"areasOfLaw":${areasOfLawString},"languages":${languagesString},"certifications":${certificationsString}}`)}`;
};

export default findALawyerSearchParamBuilder;
