import styled from 'styled-components';

const PassedMessage = styled.p`
  font-weight: bold;
  font-style: italic;
  margin-top: 7.5px;
`;

PassedMessage.displayName = 'PassedMessage';

export default PassedMessage;
