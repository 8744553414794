// the items in this list don't have ids and are view only
// (never sorted or re-ordered) so we can safely use
// array indexes as keys
/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';

import ContentBlock from './shared/ContentBlock';
import {
  StyledTable,
  ColumnHeaders,
  Column,
  HideOnMobileColumn,
  HideOnMobileColumnHeader,
  ShowOnMobile,
} from './shared/StyledTable';

const HonorsContentBlock = props => (
  <ContentBlock>
    <StyledTable>
      <thead>
        <ColumnHeaders>
          <th><h3>AWARD NAME</h3></th>
          <HideOnMobileColumnHeader><h3>DATE</h3></HideOnMobileColumnHeader>
        </ColumnHeaders>
      </thead>
      <tbody>
        {props.contentToRender.awards.map((item, index) => (
          <tr key={index}>
            <Column>
              <p>{item.name}</p>
              <ShowOnMobile><span>Date: </span>{item.dateReceived}</ShowOnMobile>
            </Column>
            <HideOnMobileColumn>
              <p>
                {item.dateReceived}
              </p>
            </HideOnMobileColumn>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  </ContentBlock>
);

HonorsContentBlock.propTypes = {
  contentToRender: PropTypes.shape({
    awards: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      dateReceived: PropTypes.string,
      deleted: PropTypes.bool,
    })),
  }).isRequired,
};

HonorsContentBlock.displayName = 'HonorsContentBlock';

export default HonorsContentBlock;
