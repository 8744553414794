import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const Wrapper = styled.div`
  margin: 0px 30px;

  @media(min-width: ${breakpoints.m}) {
    margin: 0px 20px 0px 0px;
  }

  @media(min-width: ${breakpoints.l}) {
    margin: 0px 60px 0px 5px;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
