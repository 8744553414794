import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const WrapperInner = styled.div`
  position: relative;
  width: 100%;

  @media(min-width: ${breakpoints.s}) {
    width: 456px;
  }
  @media(min-width: ${breakpoints.m}) {
    width: 684px;
  }

  @media(min-width: ${breakpoints.l}) {
    width: 855px;
  }

  @media(min-width: ${breakpoints.xl}) {
    width: 1140px;
  }
`;

WrapperInner.displayName = 'WrapperInner';

export default WrapperInner;
