import React from 'react';
import Loadable from 'react-loadable';
import ShellLoading from '@components/ShellLoading';

const CalqResultsLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "CalqResultsStateContainer" */ './CalqResultsStateContainer'),
  loading() {
    return <ShellLoading height="50vh" />;
  },
});

const CalqResultsAsync = props => <CalqResultsLoadable {...props} />;

export default CalqResultsAsync;
