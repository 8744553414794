import styled from 'styled-components';

const RadioButtonGroup = styled.ul`
  li {
    margin-bottom: 5px;
  }
`;

RadioButtonGroup.displayName = 'RadioButtonGroup';

export default RadioButtonGroup;
