import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import { typography, breakpoints, colors } from '@style-guide/config';
import OhioSVG from '@style-guide/svg/OhioSVG';

const badgeSizeDesktop = '120px';
const badgeSizeMobile = '90px';
const badgeTextSizeDesktop = '80px';
const badgeTextSizeMobile = '50px';

const Badge = styled.div`
  position: relative;
`;

Badge.displayName = 'Badge';

const BadgeText = styled.div`
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  font-size: ${rem('9px', typography.baseFontSize)};
  font-weight: 700;
  height: ${badgeTextSizeMobile};
  justify-content: center;
  left: calc(50% - (${badgeTextSizeMobile}/2));
  line-height: 1.8em;
  padding-top: 1em;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: calc(-${badgeTextSizeMobile}/2);
  width: ${badgeTextSizeMobile};

  svg {
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.l}) {
    font-size: ${rem('12px', typography.baseFontSize)};
    height: ${badgeTextSizeDesktop};
    left: calc(50% - (${badgeTextSizeDesktop}/2));
    top: calc(-${badgeTextSizeDesktop}/2);
    width: ${badgeTextSizeDesktop};
  }
`;

BadgeText.displayName = 'BadgeText';

const BadgeImage = styled.div`
  height: ${badgeSizeMobile};
  left: calc(50% - (${badgeSizeMobile}/2));
  position: absolute;
  top: calc(-${badgeSizeMobile}/2);
  width: ${badgeSizeMobile};

  > svg {
    fill: ${colors.darkRed};
    filter: drop-shadow(0 3px 7px rgba(0,0,0,0.35));
  }

  @media (min-width: ${breakpoints.l}) {
    height: ${badgeSizeDesktop};
    left: calc(50% - (${badgeSizeDesktop}/2));
    top: calc(-${badgeSizeDesktop}/2);
    width: ${badgeSizeDesktop};
  }
`;

BadgeImage.displayName = 'BadgeImage';

const BadgeLabel = styled.h2`
  font-size: ${rem('9px', typography.baseFontSize)};

  @media (min-width: ${breakpoints.l}) {
    font-size: ${rem('12px', typography.baseFontSize)};
  }
`;

BadgeLabel.displayName = 'BadgeLabel';

const ActionBarBadge = ({ badgeText = '' }) => (
  <Badge>
    <BadgeImage>
      <OhioSVG />
    </BadgeImage>
    <BadgeText>
      <BadgeLabel>{badgeText}</BadgeLabel>
      <FontAwesomeIcon
        aria-hidden="true"
        icon={faAngleDown}
      />
    </BadgeText>
  </Badge>
);

ActionBarBadge.propTypes = {
  badgeText: PropTypes.string,
};

ActionBarBadge.displayName = 'ActionBarBadge';

export default ActionBarBadge;
