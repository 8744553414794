import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints, colors, typography } from '@style-guide/config';

const markerSize = '35px';

const ProgressTrackerStep = styled.li`
  align-items: center;
  color: ${colors.white};
  display: flex;
  letter-spacing: 1px;
  text-transform: uppercase;

  &:not(.active) span {
    display: none;
    padding-right: 0;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }

  span {
    font-size: ${rem('10px', typography.baseFontSize)};
    font-weight: 600;
    padding: 5px 10px 5px 20px;
    margin-left: -10px;
  }

  &.active {
    color: ${colors.white};

    span {
      background-color: ${colors.green};
    }

    &::before {
      background-color: ${colors.green};
    }
  }

  &::before {
    align-items: center;
    background-color: ${colors.gray};
    border-radius: ${markerSize};
    content: counters(step, ".") " ";
    counter-increment: step;
    display: flex;
    flex-shrink: 0;
    font-size: 20px;
    font-weight: 700;
    height: ${markerSize};
    justify-content: center;
    width: ${markerSize};
    z-index: 1;
  }

  @media (min-width: ${breakpoints.m}) {
    &:not(.active) span {
      display: block;
    }

    &:not(:last-child) {
      margin-right: 15px;
    }
  }
`;

ProgressTrackerStep.displayName = 'ProgressTrackerStep';

export default ProgressTrackerStep;
