import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';

import {
  ContentArea,
  TabMenu,
  Wrapper,
  WrapperInner,
} from '@components/TabPage';
import PracticeLibraryBookmarkList from './PracticeLibraryBookmarkList';
import GreenbookBookmarkList from './GreenbookBookmarkList';

/**
# MyBookmarks
A view containing Practice Library and Greenbook bookmarks
 */
class MyBookmarks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.defaultTab === '' ? 'Practice Library' : 'Greenbook',
      practiceLibraryBookmarkList: this.props.practiceLibraryBookmarkList,
      eBarCase: this.props.eBarCase,
      eBarLegislativeEnactment: this.props.eBarLegislativeEnactment,
      eBarSCOAnnouncement: this.props.eBarSCOAnnouncement,
      eBarBoardOfProfessionalsConduct: this.props.eBarBoardOfProfessionalsConduct,
      eBarEthicsCommissionAdvisoryOpinion: this.props.eBarEthicsCommissionAdvisoryOpinion,
      eBarAttorneyGeneralOpinion: this.props.eBarAttorneyGeneralOpinion,
      eBarOtherAnnouncement: this.props.eBarOtherAnnouncement,
    };

    this.updateTabState = this.updateTabState.bind(this);
    this.renderTab = this.renderTab.bind(this);
  }

  updateTabState(newTab) {
    this.setState({
      activeTab: newTab,
    });
  }

  renderTab(name) {
    let uiToRender = null;

    if (this.state.activeTab === name && name === 'Practice Library') {
      if (is.not.empty(this.state.practiceLibraryBookmarkList)) {
        uiToRender = (
          <PracticeLibraryBookmarkList
            bookmarkList={this.state.practiceLibraryBookmarkList}
          />
        );
      }
    }
    if (this.state.activeTab === name && name === 'Greenbook') {
      uiToRender = (
        <GreenbookBookmarkList
          eBarCase={this.state.eBarCase}
          eBarLegislativeEnactment={this.state.eBarLegislativeEnactment}
          eBarSCOAnnouncement={this.state.eBarSCOAnnouncement}
          eBarBoardOfProfessionalsConduct={this.state.eBarBoardOfProfessionalsConduct}
          eBarEthicsCommissionAdvisoryOpinion={this.state.eBarEthicsCommissionAdvisoryOpinion}
          eBarAttorneyGeneralOpinion={this.state.eBarAttorneyGeneralOpinion}
          eBarOtherAnnouncement={this.state.eBarOtherAnnouncement}
        />
      );
    }

    return (
      <div>
        {uiToRender}
      </div>
    );
  }


  render() {
    const {
      activeTab,
      practiceLibraryBookmarkList,
      eBarCase,
    } = this.state;

    return (
      <Wrapper>
        <WrapperInner>
          <ContentArea>
            <TabMenu
              tabLabels={['Practice Library', 'Greenbook']}
              updateTabState={this.updateTabState}
              activeTab={activeTab}
            />
            {this.renderTab('Practice Library', practiceLibraryBookmarkList)}
            {this.renderTab('Greenbook', eBarCase)}
          </ContentArea>
        </WrapperInner>
      </Wrapper>
    );
  }
}

MyBookmarks.propTypes = {
  practiceLibraryBookmarkList: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
  })),
  eBarCase: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
    caseName: PropTypes.string,
    webCitation: PropTypes.string,
    activeDate: PropTypes.string,
    casemakerLink: PropTypes.string,
    pDFLink: PropTypes.string,
    practiceId: PropTypes.number,
    courtId: PropTypes.number,
    practiceName: PropTypes.string,
    courtName: PropTypes.string,
    contentGuidString: PropTypes.string,
    dateDecidedString: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarLegislativeEnactment: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    billNumber: PropTypes.string,
    effectiveDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  eBarSCOAnnouncement: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeader: PropTypes.string,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateTypeString: PropTypes.string,
    summary: PropTypes.string,
    createdDate: PropTypes.string,
    activeDateString: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  eBarBoardOfProfessionalsConduct: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  eBarEthicsCommissionAdvisoryOpinion: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  eBarAttorneyGeneralOpinion: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  eBarOtherAnnouncement: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    partnerEntity: PropTypes.string,
    postedDate: PropTypes.string,
    summary: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    activeDateString: PropTypes.string,
    createdDate: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  defaultTab: PropTypes.string,
};

MyBookmarks.defaultProps = {
  practiceLibraryBookmarkList: [],
  eBarCase: [],
  eBarLegislativeEnactment: [],
  eBarSCOAnnouncement: [],
  eBarBoardOfProfessionalsConduct: [],
  eBarEthicsCommissionAdvisoryOpinion: [],
  eBarAttorneyGeneralOpinion: [],
  eBarOtherAnnouncement: [],
  defaultTab: [],
};

MyBookmarks.displayName = 'MyBookmarks';

export default MyBookmarks;
