import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  colors,
  typography,
} from '@style-guide/config';

const CLEPassStudentWrapper = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: calc(31%);

  h3 {
    font-size: ${rem('24px', typography.baseFontSize)};
    font-weight: 700;
    line-height: ${rem('28px', typography.baseFontSize)};
  }

  h4 {
    color: ${colors.red};
    font-size: ${rem('32px', typography.baseFontSize)};
    font-weight: 700;
    line-height: ${rem('36px', typography.baseFontSize)};

    span {
      color: ${colors.black};
      display: block;
      font-size: ${rem('21px', typography.baseFontSize)};
      font-weight: 300;
      line-height: ${rem('24px', typography.baseFontSize)};
    }
  }
`;

CLEPassStudentWrapper.displayName = 'CLEPassStudentWrapper';

export default CLEPassStudentWrapper;
