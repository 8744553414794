import styled from 'styled-components';

const HeaderItem = styled.span`
  margin-right: 16px;
  display: inline-block;

  strong {
    font-weight: 700;
  }
`;

HeaderItem.displayName = 'HeaderItem';

export default HeaderItem;
