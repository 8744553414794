import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

const ActionButton = styled.button`
  border: none;
  cursor: pointer;
`;

ActionButton.displayName = 'ActionButton';

const ViewColumn = styled.td`
  background-color: #FFFFFF;
`;

ViewColumn.displayName = 'ViewColumn';

const EBarManageEmailsJobResult = ({
  initialValues,
}) => (
  <React.Fragment>
    <tr style={{ color: initialValues.Timestamp == null ? 'red' : '', backgroundColor: initialValues.Timestamp == null ? 'aliceblue' : '' }}>
      <td>{initialValues.Timestamp != null ? moment(initialValues.Timestamp).format('MM/DD/YYYY hh:mm A') : ''}</td>
      <td>{initialValues.ReportType}</td>
      <td>{initialValues.Status} - {initialValues.StatusDetail}</td>
    </tr>
  </React.Fragment>
);

EBarManageEmailsJobResult.displayName = 'EBarManageEmailsJobResult';

EBarManageEmailsJobResult.propTypes = {
  initialValues: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    Timestamp: PropTypes.string,
    ReportType: PropTypes.string,
    Status: PropTypes.string,
    StatusDetail: PropTypes.string,
  }).isRequired,
};

export default EBarManageEmailsJobResult;
