import styled from 'styled-components';
import { breakpoints, colors } from '@style-guide/config';

const DashboardToolsSecondary = styled.div`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (min-width: ${breakpoints.l}) {
    border-bottom: 3px solid ${colors.darkBlue};
    flex-direction: row;
    justify-content: flex-end;
  }
`;

DashboardToolsSecondary.displayName = 'DashboardToolsSecondary';

export default DashboardToolsSecondary;
