import { submitFreeOrder } from '@services/Payment';
import React from 'react';
import gtag from 'ga-gtag';
import is from 'is_js';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from '@style-guide/components/Button';
import Tooltip from '@style-guide/components/Tooltip';
import {
  addEventToCart,
  addEventToCartWithAddOns,
} from '@redux/modules/cart';
import Select from '@style-guide/components/Select';
import { colors } from '@style-guide/config';
import ErrorMessage from '@components/ErrorMessage';
import LoadingOverlay from '@components/LoadingOverlay';
import ShoppingCartIcon from '@components/ShoppingCartIcon';
import Checkbox from '@style-guide/components/Checkbox';
import RegisterSVG from '@style-guide/svg/RegisterSVG';
import Divider from '../Divider';
import HelpText from './components/HelpText';
import RegisterContainer from './components/RegisterContainer';


const AttendanceType = styled.div`
  label {
    margin-bottom: 15px;
  }
`;

AttendanceType.displayName = 'AttendanceType';

function buildAttendanceOptions(filterOptions) {
  const selectOptions = [{ label: 'Select...', value: '' }];

  Object.keys(filterOptions).forEach((key) => {
    selectOptions.push({
      label: filterOptions[key],
      value: key,
    });
  });

  return selectOptions;
}

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      note: '',
      attendanceType: '',
      registering: false,
      validated: !(is.not.empty(props.attendanceTypeOptions)),
      adaSelected: false,
      otherSelected: false,
      kosherSelected: false,
      glutenFreeSelected: false,
      veganSelected: false,
      vegSelected: false,
      visionSelected: false,
      mobilitySelected: false,
      hearingSelected: false,
      adaList: [],
      id: '',
      validationError: '',
      freeRegistering: false,
      captchaValid: false,
      captchaValue: '',
      meetingAvailableUntilValidationError: '',
      meetingEndedError: '',
    };

    this.updateNoteValue = this.updateNoteValue.bind(this);
    this.updateAttendanceOptions = this.updateAttendanceOptions.bind(this);
    this.register = this.register.bind(this);
    this.registerFree = this.registerFree.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onOtherChange = this.onOtherChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onRecaptchaChange = this.onRecaptchaChange.bind(this);
  }

  componentDidMount() {
    if (this.props.isMeetingAvailableUntilDate) {
      this.setState({
        meetingAvailableUntilValidationError: 'This event is closed for Registration. Please contact OSBA member services at <u>1-800-232-7124</u> for additional options and information.',
      });
    } else {
      this.setState({
        meetingAvailableUntilValidationError: '',
      });
    }
    if (this.props.isMeetingEnded && !this.props.isMeetingAvailableUntilDate) {
      this.setState({
        meetingEndedError: 'This event has ended. Check out our meeting, event and CLE program listings for future opportunities to connect with the Ohio Bar.',
      });
    }
  }

  componentDidUpdate(prevProps) {
    const cartItem = this.props.lineItems[this.props.lineItems.length - 1];
    if (this.props.lineItems.length === (prevProps.lineItems.length + 1) &&
        cartItem.ItemId === this.props.contentGuid) {
      gtag('event', 'add_to_cart', {
        currency: 'USD',
        value: parseFloat(cartItem.Price.substring(1, cartItem.Price.length - 1)),
        items: [
          {
            item_id: cartItem.ProductId,
            item_name: cartItem.DisplayName,
            index: this.props.lineItems.length,
            item_category: 'Event',
            price: parseFloat(cartItem.Price.substring(1, cartItem.Price.length - 1)),
          },
        ],
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const isSuccessState = (
      !nextProps.loading &&
      !nextProps.error &&
      !nextProps.message &&
      prevState.registering
    );

    if (nextProps.message && nextProps.message !== '') {
      nextProps.updateShowCart(false);
    }

    return {
      note: isSuccessState ? '' : prevState.note,
      registering: !!(prevState.registering && nextProps.loading),
    };
  }

  onOptionChange() {
    if (this.state.adaSelected === true) {
      this.setState({
        adaList: this.state.adaList.filter((item => item === 0)),
        validationError: '',
        hearingSelected: false,
        mobilitySelected: false,
        visionSelected: false,
        vegSelected: false,
        veganSelected: false,
        kosherSelected: false,
        glutenFreeSelected: false,
        otherSelected: false,
        note: '',
      });
    }
    this.setState({
      adaSelected: !this.state.adaSelected,
    });
  }

  onOtherChange() {
    this.setState({
      otherSelected: !this.state.otherSelected,
    });
  }

  onRecaptchaChange(value) {
    if (value !== null) {
      this.setCaptchaState(value, true);
    } else {
      this.setCaptchaState('', false);
    }
  }

  setCaptchaState(value, isSelected) {
    this.setState({
      captchaValid: isSelected,
      captchaValue: value,
    });
  }

  handleChange(e) {
    if (e.target.id === '10') {
      if (this.state.otherSelected) {
        this.setState({
          otherSelected: !this.state.otherSelected,
          validationError: '',
        });
      } else {
        this.setState({
          otherSelected: !this.state.otherSelected,
        });
      }
    }
    if (e.target.id === '9') {
      this.setState({
        kosherSelected: !this.state.kosherSelected,
      });
    }
    if (e.target.id === '8') {
      this.setState({
        glutenFreeSelected: !this.state.glutenFreeSelected,
      });
    }
    if (e.target.id === '7') {
      this.setState({
        veganSelected: !this.state.veganSelected,
      });
    }
    if (e.target.id === '6') {
      this.setState({
        vegSelected: !this.state.vegSelected,
      });
    }
    if (e.target.id === '4') {
      this.setState({
        visionSelected: !this.state.visionSelected,
      });
    }
    if (e.target.id === '3') {
      this.setState({
        mobilitySelected: !this.state.mobilitySelected,
      });
    }
    if (e.target.id === '2') {
      this.setState({
        hearingSelected: !this.state.hearingSelected,
      });
    }

    this.setState({
      id: e.target.id,
    }, () => {
      this.changeArray();
    });
  }

  changeArray() {
    let add = true;
    this.state.adaList.map((item) => {
      if (item === this.state.id) {
        add = false;
      } return this.state.id;
    });
    if (add) {
      this.setState({
        adaList: this.state.adaList.concat(this.state.id),
        validationError: '',
      });
    } else {
      this.setState({
        adaList: this.state.adaList.filter((item =>
          item !== this.state.id)),
      });
    }
  }

  updateNoteValue(event) {
    if (this.state.validationError !== '' && event.target.value !== '') {
      this.setState({
        validationError: '',
      });
    }
    this.setState({
      note: event.target.value,
    });
  }

  updateAttendanceOptions(event) {
    this.setState({
      attendanceType: event.target.value,
      validated: (event.target.value !== ''),
    });
  }

  register() {
    if (this.state.adaSelected && this.state.adaList.length === 0) {
      this.setState({
        validationError: 'Could not add item to cart - You must select at least one ADA and/or dietary accommodation selection or uncheck the click here box if an ADA and/or dietary accommodation is no longer required.',
      });
    } else if (this.state.adaSelected && this.state.otherSelected && this.state.note.length === 0) {
      this.setState({
        validationError: 'Could not add item to cart - You must enter something in the text box or uncheck "Other Food Allergy"',
      });
    } else {
      const { addOnsActive } = this.props;
      this.props.updateShowCart(true);
      if (addOnsActive && !!addOnsActive.length) {
        this.props.dispatch(addEventToCartWithAddOns(
          this.props.contentGuid,
          encodeURIComponent(this.state.note),
          encodeURIComponent(this.state.attendanceType),
          addOnsActive,
          this.state.adaList,
        ));
      } else {
        this.props.dispatch(addEventToCart(
          this.props.contentGuid,
          encodeURIComponent(this.state.note),
          encodeURIComponent(this.state.attendanceType),
          this.state.adaList,
        ));
      }
    }

    this.setState({
      registering: true,
    });
  }

  handleResponse(data) {
    if (data && data.result && data.result.IsSuccess) {
      this.setState({
        validationError: 'Successfuly Registered for Event',
      });
      this.props.onFreePurchaseComplete();
    } else {
      this.setState({
        validationError: (data && data.result) ? data.result.Message : '' || 'An unknown error has occurred. Please try again later.',
      });
    }
    this.changeFreeRegisterState(false);
  }

  registerFree() {
    let addOns = [];
    if (this.state.adaSelected && this.state.adaList.length === 0) {
      this.setState({
        validationError: 'Could not add item to cart - You must select at least one ADA and/or dietary accommodation selection or uncheck the click here box if an ADA and/or dietary accommodation is no longer required.',
      });
    } else if (this.state.adaSelected && this.state.otherSelected && this.state.note.length === 0) {
      this.setState({
        validationError: 'Could not add item to cart - You must enter something in the text box or uncheck "Other Food Allergy"',
      });
    } else {
      const { addOnsActive } = this.props;
      if (addOnsActive.length > 0) {
        addOns = addOnsActive;
      }
      submitFreeOrder(this.props.contentGuid, this.state.note,
        this.state.attendanceType, this.state.adaList, addOns, this.state.captchaValue)
        .then(this.handleResponse)
        .catch(this.handleResponse);
    }
    this.changeFreeRegisterState(true);
  }

  changeFreeRegisterState(isFreeRegister) {
    this.setState({
      freeRegistering: isFreeRegister,
    });
  }

  render() {
    const {
      loading,
      error,
      message,
      attendanceTypeOptions,
      lineItems,
      contentGuid,
      disableAddToCart,
      isRegister,
      isRegisterType,
      isAddonComplete,
      isMeetingAvailableUntilDate,
      isMeetingEnded,
    } = this.props;

    const {
      registering,
      note,
      adaSelected,
      otherSelected,
      kosherSelected,
      glutenFreeSelected,
      veganSelected,
      vegSelected,
      visionSelected,
      mobilitySelected,
      hearingSelected,
      validationError,
      freeRegistering,
      captchaValid,
      meetingAvailableUntilValidationError,
      meetingEndedError,
    } = this.state;

    const isEventInCart = !!lineItems.filter(item =>
      item.ItemId === contentGuid).length;
    const verticalAlignCheckbox = {
      verticalAlign: 'bottom',
      fontWeight: 'normal',
    };
    return (
      <RegisterContainer>
        {(is.not.empty(attendanceTypeOptions)) && (
          <AttendanceType>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label id="attendanceTypeLabel" htmlFor="attendanceType">
              <span>I will attend:</span>
              <Select
                placeholder="Select location"
                options={buildAttendanceOptions(attendanceTypeOptions)}
                onChange={this.updateAttendanceOptions}
                key="attendanceType"
                id="attendanceType"
              />
            </label>
          </AttendanceType>
        )}
        {(registering || freeRegistering) && (<LoadingOverlay />)}
        <label htmlFor="cleDetailCourseRestrictionsAccommodations">
          <HelpText>
            <Checkbox
              id="ADA_Checkbox"
              isChecked={adaSelected || false}
              name="ADA_Checkbox"
              onChange={this.onOptionChange}
              value={adaSelected || false}
            />
            <span>
              Click here if you require any ADA accomodations and/or have any dietary restrictions:
            </span>
            <Tooltip
              content="i.e. Gluten Free, Vegetarian, Vegan, Dairy Free, etc. /
                Visually, Hearing, or Mobile Impaired, etc.
                Requests should be made at least 14 days prior to the event.
                If you need further assistance, please contact Member Services at 800-232-7124."
            />
          </HelpText>
          <br />
          {this.state.adaSelected && (
          <div>
            <span> ADA accommodation requirements:</span> <br />
            <Checkbox
              id="2"
              isChecked={hearingSelected || false}
              name="adaHearingImpaired"
              value={false}
              onChange={this.handleChange}
              style={verticalAlignCheckbox}
            /> <span style={verticalAlignCheckbox}>Hearing Impaired</span> <br />
            <Checkbox
              id="3"
              isChecked={mobilitySelected || false}
              name="adaMobilityImpaired"
              value={false}
              onChange={this.handleChange}
              style={verticalAlignCheckbox}
            /> <span style={verticalAlignCheckbox}>Mobility Impaired</span>  <br />
            <Checkbox
              id="4"
              isChecked={visionSelected || false}
              name="adaVisionImpaired"
              value={false}
              onChange={this.handleChange}
              style={verticalAlignCheckbox}
            /><span style={verticalAlignCheckbox}> Vision Impaired</span>  <br /><br />
            <span> Dietary restrictions:</span> <br />
            <Checkbox
              id="6"
              isChecked={vegSelected || false}
              name="adaVegetarianDiet"
              value={false}
              onChange={this.handleChange}
              style={verticalAlignCheckbox}
            /><span style={verticalAlignCheckbox}>Vegetarian Diet</span> <br />
            <Checkbox
              id="7"
              isChecked={veganSelected || false}
              name="adaVeganDiet"
              value={false}
              onChange={this.handleChange}
              style={verticalAlignCheckbox}
            /> <span style={verticalAlignCheckbox}>Vegan Diet</span> <br />
            <Checkbox
              id="8"
              isChecked={glutenFreeSelected || false}
              name="adaGlutenFreeDiet"
              value={false}
              onChange={this.handleChange}
              style={verticalAlignCheckbox}
            /><span style={verticalAlignCheckbox}> Gluten Free Diet</span> <br />
            <Checkbox
              id="9"
              isChecked={kosherSelected || false}
              name="adaKosherDiet"
              value={false}
              onChange={this.handleChange}
              style={verticalAlignCheckbox}
            /><span style={verticalAlignCheckbox}> Kosher Diet</span> <br />
            <Checkbox
              id="10"
              isChecked={otherSelected || false}
              name="adaOtherFoodAllergy"
              value={false}
              onChange={this.handleChange}
              style={verticalAlignCheckbox}
            /> <span style={verticalAlignCheckbox}>Other Food Allergy</span> <br />
            {otherSelected && (
              <textarea
                id="cleDetailCourseRestrictionsAccommodations"
                rows="2"
                onChange={this.updateNoteValue}
                value={note}
                maxLength="500"
              />
            )}
          </div>
          )}
        </label>
        <Divider />
        {(error || message || validationError || isMeetingAvailableUntilDate || meetingEndedError)
          && (
          <ErrorMessage>
            {/* eslint-disable */
              <span
                dangerouslySetInnerHTML={{
                  __html: error || message || validationError || meetingAvailableUntilValidationError || meetingEndedError,
                }}
              />
             /* eslint-enable */ }
          </ErrorMessage>
          )}
        {(isRegister && isRegisterType && !isMeetingAvailableUntilDate &&
          !isMeetingEnded) && (
          <ReCAPTCHA
            sitekey="6LeSVtwUAAAAADr4YotVlpu-6s41pa0PcpVuQZQ-"
            onChange={this.onRecaptchaChange}
          />
        )}
        {(isRegister === false || isRegisterType === false) && (
          <Button
            onClick={this.register}
            disabled={(loading || !this.state.validated || disableAddToCart || !isAddonComplete
              || isMeetingAvailableUntilDate || isMeetingEnded)}
            isSuccess={isEventInCart}
            id="eventDetailsRegisterSubmit"
          >
            {!isEventInCart ? <RegisterSVG color={colors.white} height="15px" /> : <ShoppingCartIcon /> }
            &nbsp;
            {isEventInCart ? ' Added To Cart' : ' Register'}
          </Button>
        )}
        {(isRegister === true && isRegisterType === true) && (
          <Button
            onClick={this.registerFree}
            disabled={(loading || !this.state.validated || disableAddToCart || !isAddonComplete
            || !captchaValid || isMeetingAvailableUntilDate || isMeetingEnded)}
            id="eventDetailsRegisterSubmit1"
          >
            <RegisterSVG color={colors.white} height="15px" /> &nbsp;
            Register
          </Button>
        )}
      </RegisterContainer>
    );
  }
}

Register.displayName = 'Register';

Register.propTypes = {
  addOnsActive: PropTypes.arrayOf(PropTypes.string),
  dispatch: PropTypes.func.isRequired,
  contentGuid: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  message: PropTypes.string,
  attendanceTypeOptions: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  lineItems: PropTypes.arrayOf(Object),
  disableAddToCart: PropTypes.bool,
  isRegister: PropTypes.bool,
  isRegisterType: PropTypes.bool,
  isAddonComplete: PropTypes.bool,
  onFreePurchaseComplete: PropTypes.func.isRequired,
  updateShowCart: PropTypes.func,
  isMeetingAvailableUntilDate: PropTypes.bool.isRequired,
  isMeetingEnded: PropTypes.bool.isRequired,
};

Register.defaultProps = {
  addOnsActive: [],
  contentGuid: '',
  loading: true,
  error: '',
  message: '',
  attendanceTypeOptions: {},
  lineItems: [],
  disableAddToCart: false,
  isRegister: false,
  isRegisterType: true,
  isAddonComplete: true,
  updateShowCart: () => {},
};

const mapStateToProps = ({ cart }) => ({
  loading: cart.loading,
  error: cart.error,
  message: cart.message,
  lineItems: cart.lineItems,
});

export default connect(mapStateToProps)(Register);
