import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';

const LocationsContainer = styled.div`
  font-size: ${rem('14px', typography.baseFontSize)};
  margin: 10px 0;
`;

LocationsContainer.displayName = 'LocationsContainer';

export default LocationsContainer;
