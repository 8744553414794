import styled from 'styled-components';

import { colors } from '@style-guide/config';

const QuestionText = styled.h3`
  width: 100%;
  background-color: ${colors.lightGray};
  padding: 10px;
`;

QuestionText.displayName = 'QuestionText';

export default QuestionText;
