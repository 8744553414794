import {
  change,
  touch,
} from 'redux-form';

const validate = ({
  CellPhoneNumber,
  HomePhoneNumber,
  PersonalEmailAddress,
  PreferredEmail,
  WorkOrSchoolEmailAddress,
  WorkPhoneNumber,
}, props) => {
  const errors = {};

  if (!CellPhoneNumber && !HomePhoneNumber && !WorkPhoneNumber) {
    errors.CellPhoneNumber = 'At least one phone number is required.';
    errors.HomePhoneNumber = 'At least one phone number is required.';
    errors.WorkPhoneNumber = 'At least one phone number is required.';

    props.dispatch(touch('contactInfoForm', 'HomePhoneNumber', 'CellPhoneNumber', 'WorkPhoneNumber'));
  }

  if (!PersonalEmailAddress && !WorkOrSchoolEmailAddress) {
    errors.PersonalEmailAddress = 'At least one email is required.';
    errors.WorkOrSchoolEmailAddress = 'At least one email is required.';

    // we need to set both fields as touched so the error messages will display on both fields
    props.dispatch(touch('contactInfoForm', 'PersonalEmailAddress', 'WorkOrSchoolEmailAddress'));
  }

  // the personal email cannot be the same as the work/school email, but both emails cannot empty,
  // because that's not really a "match"
  if (
    PersonalEmailAddress === WorkOrSchoolEmailAddress
    &&
    (PersonalEmailAddress || WorkOrSchoolEmailAddress)
  ) {
    errors.PersonalEmailAddress = 'Personal email must not be the same as the work/school email.';
    errors.WorkOrSchoolEmailAddress = 'Work/school email must not be the same as the personal email.';

    // we need to set both fields as touched so the error messages will display on both fields
    props.dispatch(touch('contactInfoForm', 'PersonalEmailAddress', 'WorkOrSchoolEmailAddress'));
  }

  // if there is at least one email, we want to show that there also must be a preferred email
  if (!PreferredEmail) {
    errors.PreferredEmail = 'One email address must be preferred.';
  }

  if (
    (!WorkOrSchoolEmailAddress && PreferredEmail === 'workOrSchool')
    ||
    (!PersonalEmailAddress && PreferredEmail === 'personal')
  ) {
    props.dispatch(change('contactInfoForm', 'PreferredEmail', ''));
  }

  return errors;
};

export default validate;
