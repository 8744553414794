import styled from 'styled-components';

const ItemPrice = styled.span`
  flex-shrink: 0;
  padding-left: 15px;

  > * {
    margin-left: 15px;
  }
`;

ItemPrice.displayName = 'ItemPrice';

export default ItemPrice;
