import React from 'react';
import Loadable from 'react-loadable';
import ShellLoading from '@components/ShellLoading';

const SiteSearchResultsLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "SiteSearchStateContainer" */ './SiteSearchStateContainer'),
  loading() {
    return <ShellLoading height="50vh" />;
  },
});

const SiteSearchResultsAsync = props => <SiteSearchResultsLoadable {...props} />;

export default SiteSearchResultsAsync;
