import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import toLower from 'lodash/toLower';
import replace from 'lodash/replace';
import styled from 'styled-components';
import LinkButton from '@style-guide/components/LinkButton';
import SocialShare from '@components/SocialShare';

import { courseFormat as courseFormatPropTypes } from '@services/PropTypes';
import CLEBadge from '@components/CLEBadge';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons/faCalendarPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@style-guide/config';
import renderCredits from './utilities/renderCredits';
import renderSpeakers from './utilities/renderSpeakers';

import SpecializationCreditWrapper from './components/SpecializationCreditWrapper';
import SpecializationCreditIcon from './components/SpecializationCreditIcon';
import Title from './components/Title';
import Wrapper from './components/Wrapper';
import DataItem from './components/DataItem';
import FlexContainer from './components/FlexContainer';
import Status from './components/Status';

const BtnOuterDiv = styled.div`
  margin-bottom: 8px;
  margin-top: 8px;
`;

BtnOuterDiv.displayName = 'BtnOuterDiv';

const BtnContainer = styled.span`
  a {
    font-size: 14px;
    padding: 8px 14px;
    margin: 4px 8px 4px 0px;
  }
`;

BtnContainer.displayName = 'BtnContainer';
const Card = ({
  title,
  courseId,
  isPastEvent,
  date = '',
  courseFormat,
  possibleCredits,
  speakers,
  specializationCredit = '',
  classroomLink = '',
  certificateLink = '',
  status,
  startTime = '',
  endTime = '',
  location = '',
  earnedCredits,
  practiceAreas = '',
  startDate = '',
  endDate = '',
  detailLink = '',
  surveyLink = '',
}) => (
  <Wrapper>
    <CLEBadge
      courseFormat={courseFormat}
    />
    <Title>{title}</Title>
    <DataItem><strong>Course ID#:</strong><span> {courseId}</span></DataItem>
    {date && (
      <DataItem>
        <strong>Date:</strong><span> {date}</span>
      </DataItem>
    )}
    {startTime && endTime && (
      <DataItem>
        <strong>Time:</strong><span> {replace(toLower(startTime), ' ', '')} - {replace(toLower(endTime), ' ', '')}</span>
      </DataItem>
    )}
    {/* eslint-disable no-restricted-globals */}
    {location && (
      <DataItem>
        <strong>Location:</strong><span> {replace(location, ' - ', ', ')}</span>
      </DataItem>
    )}
    {/* eslint-enable no-restricted-globals */}
    {courseFormat &&
      <DataItem><strong>Format:</strong><span> {courseFormat.name}</span></DataItem>
    }
    {(is.not.empty(possibleCredits) && !isPastEvent) && (
      <DataItem>
        <strong>Credits: </strong><span>{renderCredits(possibleCredits)}</span>
      </DataItem>
    )}
    {practiceAreas && (
      <DataItem>
        <strong>Practice Areas: </strong><span>{practiceAreas}</span>
      </DataItem>
    )}
    {(is.not.empty(possibleCredits) && isPastEvent) && (
      <DataItem>
        <strong>Possible Credit: </strong><span>{renderCredits(possibleCredits)}</span>
      </DataItem>
    )}
    {(is.not.empty(earnedCredits) && isPastEvent) && (
      <DataItem>
        <strong>Credit Earned: </strong><span>{renderCredits(earnedCredits)}</span>
      </DataItem>
    )}
    {is.not.empty(speakers) && (
      <DataItem>
        <strong>{speakers.length > 0 ? 'Speakers:' : 'Speaker'}</strong><span>{renderSpeakers(speakers)}</span>
      </DataItem>
    )}
    {specializationCredit && (
      <SpecializationCreditWrapper>
        <SpecializationCreditIcon />
        <strong>Specialization Credit:</strong><span> {specializationCredit}</span>
      </SpecializationCreditWrapper>
    )}
    {isPastEvent && (
      <FlexContainer>
        <Status
          status={status}
        >
          <strong>Attendance Status:</strong><span> {status}</span>
        </Status>
      </FlexContainer>
    )}
    <BtnOuterDiv>
      {!isPastEvent && courseFormat && courseFormat.name !== 'CLE Online Seminars' && (
        <BtnContainer>
          <LinkButton
            href={`/MyCLEPage/AddToCalendar?startDate=${startDate}&startTime=${startTime}&endDate=${endDate}&endTime=${endTime}&title=${title}&location=${replace(location, ' - ', ', ')}`}
            target="_parent"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              icon={faCalendarPlus}
              color={colors.white}
              style={{ height: '10px', width: '10px' }}
            />&nbsp;
            ADD TO CALENDAR
          </LinkButton>
        </BtnContainer>
      )}
      {classroomLink && (
        <BtnContainer>
          <LinkButton
            href={classroomLink}
          >
            Classroom Page
          </LinkButton>
        </BtnContainer>
      )}
      {certificateLink && isPastEvent && status === 'Attended' && (
        <BtnContainer>
          <LinkButton
            href={certificateLink}
            target="_blank"
          >
            Certificate
          </LinkButton>
        </BtnContainer>
      )}
      {surveyLink && isPastEvent && status === 'Attended' && (
        <BtnContainer>
          <LinkButton
            href={surveyLink}
            target="_blank"
          >
            Survey
          </LinkButton>
        </BtnContainer>
      )}
    </BtnOuterDiv>
    <SocialShare
      shareUrl={detailLink}
      articleTitle={title}
    />
  </Wrapper>
);

Card.propTypes = {
  title: PropTypes.string.isRequired,
  courseId: PropTypes.string.isRequired,
  isPastEvent: PropTypes.bool.isRequired,
  courseFormat: courseFormatPropTypes.isRequired,
  earnedCredits: PropTypes.arrayOf(Object).isRequired,
  possibleCredits: PropTypes.arrayOf(Object).isRequired,
  speakers: PropTypes.arrayOf(Object).isRequired,
  classroomLink: PropTypes.string,
  certificateLink: PropTypes.string,
  status: PropTypes.string.isRequired,
  specializationCredit: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  location: PropTypes.string,
  practiceAreas: PropTypes.string,
  date: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  detailLink: PropTypes.string,
  surveyLink: PropTypes.string,
};

Card.displayName = 'Card';

export default Card;
