import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Divider from '@components/Divider';
import rem from 'polished/lib/helpers/rem';
import { typography, colors } from '@style-guide/config';
import {
  LoadMoreButton,
  LoadMoreWrapper,
  ResultsContainer,
  ResultsHeader,
  ResultsTitle,
  ResultsWrapper,
  FlexWrapper,
  SortFilterLabel,
  SortFilterWrapper,
  StyledSelect,
  WarningMessage,
} from '@components/SearchResults';
import {
  sCOAnnouncementsSubHeaderOptions,
  sCOAnnouncementsDateTypeOptions,
  ebarContentTypesValues,
} from '@config';
import GreenbookListItem from './GreenbookListItem';
import FilterCloud from './Shared/FilterCloud/FilterCloud';

const ListContainer = styled.ul`
  position: relative;
  margin-bottom: 20px;
`;

const ListTitle = styled.p`
  font-size: ${rem('16px', typography.baseFontSize)};
  font-weight: bold;
  border-bottom: 1px solid ${colors.lightGray};
  margin-bottom: 25px;
  background-color: #263746;
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  text-transform: uppercase;
`;

class Results extends React.Component {
  /* eslint-disable */
  convertReplaceString(summary) {
    if(!summary) {
      return summary;
    }

    const summary1 = summary.replaceAll('&lt;', '<');
    const result = summary1.replaceAll('&gt;', '>');
    return result;
  }
  /* eslint-enable */

  sortUpdate(criteria) {
    if (criteria === 'Keyword Relevancy') {
      this.props.newEBarCase = this.props.newEBarCase.sort((a, b) => {
        if (a.filterName < b.filterName) return -1;
        return a.filterName > b.filterName ? 1 : 0;
      });
    } else if (criteria === 'Recently Published') {
      this.props.newEBarCase = this.props.newEBarCase.sort((a, b) => {
        if (a.filterName < b.filterName) return -1;
        return a.filterName > b.filterName ? 1 : 0;
      });
    } else if (criteria === 'A-Z') {
      this.props.newEBarCase = this.props.newEBarCase.sort((a, b) => {
        if (a.filterName < b.filterName) return -1;
        return a.filterName > b.filterName ? 1 : 0;
      });
    } else if (criteria === 'Z-A') {
      this.props.newEBarCase = this.props.newEBarCase.sort((a, b) => {
        if (a.filterName < b.filterName) return -1;
        return a.filterName > b.filterName ? 1 : 0;
      });
    }
  }

  render() {
    const {
      eBarCase,
      eBarLegislativeEnactment,
      eBarSCOAnnouncement,
      eBarBoardOfProfessionalsConduct,
      eBarEthicsCommissionAdvisoryOpinion,
      eBarAttorneyGeneralOpinion,
      eBarOtherAnnouncement,
      /* eslint-disable */
      newEBarCase,
      newEBarLegislativeEnactment,
      newEBarSCOAnnouncement,
      newEBarBoardOfProfessionalsConduct,
      newEBarEthicsCommissionAdvisoryOpinion,
      newEBarAttorneyGeneralOpinion,
      newEBarOtherAnnouncement,
      /* eslint-enable */
      showAllResults,
      showResults,
      totalCount,
    } = this.props;

    const resultCount = showAllResults ? (totalCount.case + totalCount.enactment
        + totalCount.sco + totalCount.conduct + totalCount.ethics + totalCount.attorney
        + totalCount.other) :
      ((showResults.Case ? totalCount.case : 0) +
        (showResults.Enactment ? totalCount.enactment : 0) +
        (showResults.SCO ? totalCount.sco : 0) +
        (showResults.Conduct ? totalCount.conduct : 0) +
        (showResults.Ethics ? totalCount.ethics : 0) +
        (showResults.Attorney ? totalCount.attorney : 0) +
        (showResults.Other ? totalCount.other : 0));

    return (
      <FlexWrapper>
        <FilterCloud
          searchFilterValues={this.props.filterValues}
          updateSearchFilter={this.props.updateSearchFilter}
          clearSearchFilters={this.props.clearSearchFilters}
        />
        <ResultsWrapper>
          <ResultsHeader>
            <ResultsTitle>
              Results
              <span
                id="resultsCountHeader"
              >
                {(resultCount > 0) && ` (${resultCount})`}
              </span>
            </ResultsTitle>
            {(resultCount > 0) && (
            <SortFilterWrapper>
              <SortFilterLabel>Sort By:</SortFilterLabel>
              <StyledSelect
                onChange={e => this.props.sortUpdate(e.target.value)}
                value={this.props.sortBy}
                id="sortByOptionsSelect"
              >
                <option
                  value="relevancy"
                  id="sortByOptionsKeywordRelevancy"
                >
                  Keyword Relevancy
                </option>
                <option
                  value="mostRecent"
                  id="sortByOptionsMostRecent"
                >
                  Recently Published
                </option>
                <option
                  value="nameAZ"
                  id="sortByOptionsNameAZ"
                >
                  Name - A-Z
                </option>
                <option
                  value="nameZA"
                  id="sortByOptionsNameZA"
                >
                  Name - Z-A
                </option>
              </StyledSelect>
            </SortFilterWrapper>
            )}
          </ResultsHeader>
          {(resultCount < 1) && (
          <WarningMessage
            message="No results have matched your search criteria. Please widen your search."
          />
          )}
          <ResultsContainer
            id="resultsContainer"
            style={{
              paddingTop: 0,
            }}
          >
            {eBarCase.length > 0 && showResults.Case && (
            <ListContainer>
              <ListTitle>Opinions ({totalCount.case})</ListTitle>
              {
              eBarCase.map((item, index) => (
                <div key={item.contentGuidString}>
                  {!!index && <Divider />}
                  <GreenbookListItem
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.webCitation}
                    caseName={item.caseName}
                    date={item.dateDecidedString}
                    dateType="Decided"
                    summary={item.summary}
                    ctaLabel="PDF"
                    ctaUrl={item.pDFLink}
                    isBookmarked={item.isBookmarked}
                    contentType={ebarContentTypesValues.eBarCase}
                    practiceName={item.practiceName}
                    courtName={item.courtName}
                  />
                </div>
              ))}
              {totalCount.case > eBarCase.length && (
              <LoadMoreWrapper>
                <LoadMoreButton
                  id="resultsLoadMore"
                  onClick={() => {
                    this.props.pageCountUpdate('case');
                  }}
                >
                  Load more +
                </LoadMoreButton>
              </LoadMoreWrapper>
              )}
            </ListContainer>
            )}
            {eBarLegislativeEnactment.length > 0 && showResults.Enactment && (
            <ListContainer>
              <ListTitle>Legislative Enactments ({totalCount.enactment})</ListTitle>
              {
              eBarLegislativeEnactment.map((item, index) => (
                <span key={item.contentGuidString}>
                  {!!index && <Divider />}
                  <GreenbookListItem
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.billNumber}
                    date={item.effectiveDate}
                    dateType="Effective"
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.ctaLabel}
                    ctaUrl={item.ctaUrl}
                    isBookmarked={item.isBookmarked}
                    contentType={ebarContentTypesValues.eBarLegislativeEnactment}
                  />
                </span>
              ))}
              {totalCount.enactment > eBarLegislativeEnactment.length && (
              <LoadMoreWrapper>
                <LoadMoreButton
                  id="resultsLoadMore"
                  onClick={() => {
                    this.props.pageCountUpdate('enactment');
                  }}
                >
                  Load more +
                </LoadMoreButton>
              </LoadMoreWrapper>
              )}
            </ListContainer>
            )}
            {eBarSCOAnnouncement.length > 0 && showResults.SCO && (
            <ListContainer>
              <ListTitle>
                Supreme Court of Ohio Announcements ({totalCount.sco})
              </ListTitle>
              {
              eBarSCOAnnouncement.map((item, index) => (
                <div key={item.contentGuidString}>
                  {!!index && <Divider />}
                  <GreenbookListItem
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.cite}
                    practiceName={!item.subHeader ? '' : sCOAnnouncementsSubHeaderOptions.filter(c =>
                      c.value === item.subHeader.toString())[0].label}
                    dateType={!item.dateType ? '' : sCOAnnouncementsDateTypeOptions.filter(c =>
                      c.value === item.dateType.toString())[0].label}
                    date={item.effectivePostedDateString}
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.cTALabel}
                    ctaUrl={item.cTAUrl}
                    isBookmarked={item.isBookmarked}
                    contentType={ebarContentTypesValues.eBarSCOAnnouncement}
                  />
                </div>
              ))}
              {totalCount.sco > eBarSCOAnnouncement.length && (
              <LoadMoreWrapper>
                <LoadMoreButton
                  id="resultsLoadMore"
                  onClick={() => {
                    this.props.pageCountUpdate('sco');
                  }}
                >
                  Load more +
                </LoadMoreButton>
              </LoadMoreWrapper>
              )}
            </ListContainer>
            )}
            {eBarBoardOfProfessionalsConduct.length > 0 && showResults.Conduct && (
            <ListContainer>
              <ListTitle>
                Board of Professional Conduct
                Advisory Opinions ({totalCount.conduct})
              </ListTitle>
              {
              eBarBoardOfProfessionalsConduct.map((item, index) => (
                <div key={item.contentGuidString}>
                  {!!index && <Divider />}
                  <GreenbookListItem
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.cite}
                    date={item.issueDate}
                    dateType="Issued"
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.ctaLabel}
                    ctaUrl={item.ctaUrl}
                    isBookmarked={item.isBookmarked}
                    contentType={ebarContentTypesValues.eBarBoardOfProfessionalsConduct}
                  />
                </div>
              ))}
              {totalCount.conduct > eBarBoardOfProfessionalsConduct.length && (
                <LoadMoreWrapper>
                  <LoadMoreButton
                    id="resultsLoadMore"
                    onClick={() => {
                      this.props.pageCountUpdate('conduct');
                    }}
                  >
                    Load more +
                  </LoadMoreButton>
                </LoadMoreWrapper>
              )}
            </ListContainer>
            )}
            {eBarEthicsCommissionAdvisoryOpinion.length > 0 && showResults.Ethics && (
            <ListContainer>
              <ListTitle>
                Ethics Commission Advisory Opinions ({totalCount.ethics})
              </ListTitle>
              {
              eBarEthicsCommissionAdvisoryOpinion.map((item, index) => (
                <div key={item.contentGuidString}>
                  {!!index && <Divider />}
                  <GreenbookListItem
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.cite}
                    date={item.issueDate}
                    dateType="Issued"
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.ctaLabel}
                    ctaUrl={item.ctaUrl}
                    isBookmarked={item.isBookmarked}
                    contentType={ebarContentTypesValues.eBarEthicsCommissionAdvisoryOpinion}
                  />
                </div>
              ))}
              {totalCount.ethics > eBarEthicsCommissionAdvisoryOpinion.length && (
                <LoadMoreWrapper>
                  <LoadMoreButton
                    id="resultsLoadMore"
                    onClick={() => {
                      this.props.pageCountUpdate('ethics');
                    }}
                  >
                    Load more +
                  </LoadMoreButton>
                </LoadMoreWrapper>
              )}
            </ListContainer>
            )}
            {eBarAttorneyGeneralOpinion.length > 0 && showResults.Attorney && (
            <ListContainer>
              <ListTitle>Attorney General Opinions ({totalCount.attorney})</ListTitle>
              {
              eBarAttorneyGeneralOpinion.map((item, index) => (
                <div key={item.contentGuidString}>
                  {!!index && <Divider />}
                  <GreenbookListItem
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.cite}
                    date={item.issueDate}
                    dateType="Issued"
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.ctaLabel}
                    ctaUrl={item.ctaUrl}
                    isBookmarked={item.isBookmarked}
                    contentType={ebarContentTypesValues.eBarAttorneyGeneralOpinion}
                  />
                </div>
              ))}
              {totalCount.attorney > eBarAttorneyGeneralOpinion.length && (
              <LoadMoreWrapper>
                <LoadMoreButton
                  id="resultsLoadMore"
                  onClick={() => {
                    this.props.pageCountUpdate('attorney');
                  }}
                >
                  Load more +
                </LoadMoreButton>
              </LoadMoreWrapper>
              )}
            </ListContainer>
            )}
            {eBarOtherAnnouncement.length > 0 && showResults.Other && (
            <ListContainer>
              <ListTitle>Other Announcements ({totalCount.other})</ListTitle>
              {
              eBarOtherAnnouncement.map((item, index) => (
                <div key={item.contentGuidString}>
                  {!!index && <Divider />}
                  <GreenbookListItem
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.partnerEntity}
                    date={item.postedDate}
                    dateType="Posted"
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.cTALabel}
                    ctaUrl={item.cTAUrl}
                    isBookmarked={item.isBookmarked}
                    contentType={ebarContentTypesValues.eBarOtherAnnouncement}
                  />
                </div>
              ))}
              {totalCount.other > eBarOtherAnnouncement.length && (
              <LoadMoreWrapper>
                <LoadMoreButton
                  id="resultsLoadMore"
                  onClick={() => {
                    this.props.pageCountUpdate('other');
                  }}
                >
                  Load more +
                </LoadMoreButton>
              </LoadMoreWrapper>
              )}
            </ListContainer>
            )}
          </ResultsContainer>
        </ResultsWrapper>
      </FlexWrapper>
    );
  }
}

Results.propTypes = {
  eBarCase: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
    caseName: PropTypes.string,
    webCitation: PropTypes.string,
    activeDate: PropTypes.string,
    casemakerLink: PropTypes.string,
    pDFLink: PropTypes.string,
    practiceId: PropTypes.number,
    courtId: PropTypes.number,
    practiceName: PropTypes.string,
    courtName: PropTypes.string,
    contentGuidString: PropTypes.string,
    dateDecidedString: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarLegislativeEnactment: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    billNumber: PropTypes.string,
    effectiveDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarSCOAnnouncement: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeader: PropTypes.number,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDate: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateType: PropTypes.number,
    summary: PropTypes.string,
    createdDate: PropTypes.string,
    activeDate: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarBoardOfProfessionalsConduct: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarEthicsCommissionAdvisoryOpinion: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarAttorneyGeneralOpinion: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarOtherAnnouncement: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    pitle: PropTypes.string,
    partnerEntity: PropTypes.string,
    postedDate: PropTypes.string,
    summary: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    activeDate: PropTypes.string,
    createdDate: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  newEBarCase: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
    caseName: PropTypes.string,
    webCitation: PropTypes.string,
    activeDate: PropTypes.string,
    casemakerLink: PropTypes.string,
    pDFLink: PropTypes.string,
    practiceId: PropTypes.number,
    courtId: PropTypes.number,
    practiceName: PropTypes.string,
    courtName: PropTypes.string,
    contentGuidString: PropTypes.string,
    dateDecidedString: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  newEBarLegislativeEnactment: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    billNumber: PropTypes.string,
    effectiveDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  newEBarSCOAnnouncement: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeader: PropTypes.number,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDate: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateType: PropTypes.number,
    summary: PropTypes.string,
    createdDate: PropTypes.string,
    activeDate: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  newEBarBoardOfProfessionalsConduct: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  newEBarEthicsCommissionAdvisoryOpinion: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  newEBarAttorneyGeneralOpinion: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  newEBarOtherAnnouncement: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    pitle: PropTypes.string,
    partnerEntity: PropTypes.string,
    postedDate: PropTypes.string,
    summary: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    activeDate: PropTypes.string,
    createdDate: PropTypes.string,
    modifiedBy: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  showAllResults: PropTypes.bool.isRequired,
  showResults: PropTypes.shape({
    Case: PropTypes.bool,
    Enactment: PropTypes.bool,
    SCO: PropTypes.bool,
    Conduct: PropTypes.bool,
    Ethics: PropTypes.bool,
    Attorney: PropTypes.bool,
    Other: PropTypes.bool,
  }),
  pageCountUpdate: PropTypes.func.isRequired,
  sortUpdate: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  filterValues: PropTypes.arrayOf(PropTypes.shape({
    FilterId: PropTypes.string,
    FilterName: PropTypes.string,
    FilterValue: PropTypes.bool,
  })),
  updateSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
  totalCount: PropTypes.shape({
    case: PropTypes.number,
    enactment: PropTypes.number,
    sco: PropTypes.number,
    conduct: PropTypes.number,
    ethics: PropTypes.number,
    attorney: PropTypes.number,
    other: PropTypes.number,
  }),
};

Results.defaultProps = {
  eBarCase: [],
  eBarLegislativeEnactment: [],
  eBarSCOAnnouncement: [],
  eBarBoardOfProfessionalsConduct: [],
  eBarEthicsCommissionAdvisoryOpinion: [],
  eBarAttorneyGeneralOpinion: [],
  eBarOtherAnnouncement: [],
  newEBarCase: [],
  newEBarLegislativeEnactment: [],
  newEBarSCOAnnouncement: [],
  newEBarBoardOfProfessionalsConduct: [],
  newEBarEthicsCommissionAdvisoryOpinion: [],
  newEBarAttorneyGeneralOpinion: [],
  newEBarOtherAnnouncement: [],
  showResults: {
    Case: true,
    Enactment: true,
    SCO: true,
    Conduct: true,
    Ethics: true,
    Attorney: true,
    Other: true,
  },
  filterValues: [],
  totalCount: {
    case: 0,
    enactment: 0,
    sco: 0,
    conduct: 0,
    ethics: 0,
    attorney: 0,
    other: 0,
  },
};

Results.displayName = 'Results';

export default Results;
