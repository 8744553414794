import styled from 'styled-components';

import { colors } from '@style-guide/config';

const SupremeCourtLinkMessage = styled.div`
  margin-bottom: 30px;

  a {
    color: ${colors.red};

    &:hover,
    &:focus, {
      color: ${colors.darkRed};
    }
  }
`;

SupremeCourtLinkMessage.displayName = 'SupremeCourtLinkMessage';

export default SupremeCourtLinkMessage;
