import styled from 'styled-components';
import { colors } from '@style-guide/config';

const CaseResultDiv = styled.div`
  font-size: 14px;
  font-weight: normal;  
  background-color: ${colors.white};
  position: relative;
  width: 100%;
`;

CaseResultDiv.displayName = 'CaseResultDiv';

export default CaseResultDiv;
