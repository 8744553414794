import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';


const Title = styled.h3`
  margin-bottom: 15px;
  font-size: ${rem('21px', typography.baseFontSize)};
  text-align: center;
`;

Title.displayName = 'Title';

export default Title;
