import styled from 'styled-components';

const Thumbnail = styled.img`
  max-width: 150px;
  align-self: flex-start;
  margin-right: 10px;
`;

Thumbnail.displayName = 'Thumbnail';

export default Thumbnail;
