import styled from 'styled-components';

import { colors } from '@style-guide/config';

const Title = styled.h3`
  color: ${colors.white};
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.25rem;
`;

Title.displayName = 'Title';

export default Title;
