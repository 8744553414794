import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';

import { colors } from '@style-guide/config';
import Checkbox from '@style-guide/components/Checkbox';
import LinkButton from '@style-guide/components/LinkButton';

import CLEContentCard from '@components/CLEContentCard';

const AddOnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${props => (props.hasBottomBorder
    ?
    `1px solid ${colors.lightGray}`
    :
    '0'
  )};
  margin-bottom: 15px;
  padding-bottom: ${props => (props.hasBottomBorder
    ?
    '15px'
    :
    '0'
  )}
`;

const AddOnDetails = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 700;
  }

  ul {
    margin: 5px 35px;
  }

  li {
    line-height: 1.25em;

    span:first-child {
      font-weight: 600;
    }
  }

  a {
    margin-top: 15px;
  }
`;

AddOnDetails.displayName = 'AddOnDetails';

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AddOns = ({
  addOns,
  updateAddOnsActiveState,
  addOnsActive,
}) => {
  const renderCreditsList = credits => credits.map((credit, index) => (
    <React.Fragment
      key={credit.typeName}
    >
      {credit.hours.toFixed(2)} {credit.typeName.trim()}{index + 1 < credits.length && ', '}
    </React.Fragment>
  ));

  return (
    <CLEContentCard>
      <h2>Add-On(s)</h2>
      {addOns.map((addOn, index, collection) => (
        <AddOnWrapper
          hasBottomBorder={(index + 1) < collection.length}
          key={addOn.id}
        >
          <AddOnDetails>
            <CheckboxWrapper>
              <Checkbox
                type="checkbox"
                value={addOn.id}
                isChecked={includes(addOnsActive, addOn.id)}
                name="addons"
                label={addOn.title}
                id={addOn.id}
                onChange={(event, name, val) => updateAddOnsActiveState(val)}
              />
            </CheckboxWrapper>
            <ul>
              {addOn.date && (
                <li>
                  <span>Date:&nbsp;</span>
                  <span>{addOn.date}</span>
                </li>
              )}
              {(addOn.startTime && addOn.endTime) && (
                <li>
                  <span>Time:&nbsp;</span>
                  <span>{`${addOn.startTime} - ${addOn.endTime}`}</span>
                </li>
              )}
              {addOn.credits && !!addOn.credits.length && (
                <li>
                  <span>Credits:&nbsp;</span>
                  <span>{renderCreditsList(addOn.credits)}</span>
                </li>
              )}
              {addOn.link && (
                <li>
                  <LinkButton
                    href={addOn.link}
                    target="_blank"
                  >
                    View Details
                  </LinkButton>
                </li>
              )}
            </ul>
          </AddOnDetails>
          {addOn.prices[0].amount}
        </AddOnWrapper>
      ))}
    </CLEContentCard>
  );
};

AddOns.displayName = 'AddOns';

AddOns.propTypes = {
  addOns: PropTypes.arrayOf(PropTypes.object),
  updateAddOnsActiveState: PropTypes.func.isRequired,
  addOnsActive: PropTypes.arrayOf(PropTypes.string),
};

AddOns.defaultProps = {
  addOns: [],
  addOnsActive: [],
};

export default AddOns;
