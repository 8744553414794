import styled from 'styled-components';

const FlexWrapper = styled.div`
  flex-grow: 1;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex-basis: 100%;
  }
`;

FlexWrapper.displayName = 'FlexWrapper';

export default FlexWrapper;
