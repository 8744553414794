import React from 'react';
import Loadable from 'react-loadable';
import ShellLoading from '@components/ShellLoading';

const MemberDashboardAsync = Loadable({
  loader: () => import(/* webpackChunkName: "MemberDashboard" */ './components/MemberDashboardAsync'),
  loading() {
    return <ShellLoading height="50vh" />;
  },
});

const MemberDashboard = props => <MemberDashboardAsync {...props} />;

MemberDashboard.displayName = 'MemberDashboard';

export default MemberDashboard;
