import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import transparentize from 'polished/lib/color/transparentize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { colors, stickyHeader, typography } from '@style-guide/config';
import ScreenReaderText from '@components/ScreenReaderText';

const LoginNavItem = styled.li`
  border-left: 1px solid ${transparentize(0.8, colors.white)};
  float: left;

  a {
    color: ${colors.white};
    display: block;
    line-height: ${stickyHeader.stickyHeaderHeight};
    text-align: center;
    width: ${rem('50px', typography.baseFontSize)};
  }

  svg {
    font-size: ${rem('20px', typography.baseFontSize)};
  }
`;

LoginNavItem.displayName = 'LoginNavItem';

const Login = ({
  isAuthenticated,
  loginLink,
  logoutLink,
}) => (
  <LoginNavItem>
    <a
      aria-label="Login"
      href={isAuthenticated ? logoutLink : loginLink}
      id={isAuthenticated ? 'mobileHeaderLogout' : 'mobileHeaderLogin'}
    >
      <FontAwesomeIcon icon={faUser} aria-hidden="true" />
      <ScreenReaderText>Login</ScreenReaderText>
    </a>
  </LoginNavItem>
);

Login.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  loginLink: PropTypes.string.isRequired,
  logoutLink: PropTypes.string.isRequired,
};

Login.displayName = 'Login';

export default Login;
