import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const Image = styled.div`
  background-image: url(${props => props.$imgSrc});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  width: 100%;

  @media (min-width: ${breakpoints.m}) {
    display: none;
  }
`;

Image.displayName = 'Image';

export default Image;
