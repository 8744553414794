import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  breakpoints,
  colors,
} from '@style-guide/config';

import Button from '@style-guide/components/Button';

import CLEContentCard from '@components/CLEContentCard';
import renderCredits from './services/renderCredits';

const Event = styled.li`
  margin: 0 0 15px;
  padding: 0 0 10px;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.lightGray};
  }

  h4 {
    margin: 0 0 5px;
  }
`;

Event.displayName = 'Event';

const EventDescription = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media(min-width: ${breakpoints.s}) {
    flex-direction: row;
  }

  p {
    margin: 0;
  }

  > *:first-child {
    margin-right: 15px;
  }
`;

EventDescription.displayName = 'EventDescription';

const StyledButton = styled(Button)`
  margin-top: 15px;

  @media(min-width: ${breakpoints.s}) {
    margin-top: 0;
  }
`;

StyledButton.displayName = 'StyledButton';

// destructure similar courses in the args
const AddOnList = ({ addOns }) => {
  const AddOnsListItems = addOns.map(event => (
    <Event
      key={event.id}
    >
      <h4>{event.title}</h4>
      <EventDescription>
        <div>
          {event.date &&
            <p><strong>Date: </strong>{event.date}</p>
          }
          {(event.startTime || event.endTime) &&
            <p><strong>Time: </strong>{event.startTime}{(event.startTime && event.endTime) && ' - '}{event.endTime && `${event.endTime}`}</p>
          }
          {(event.addOnCredits) &&
            <p><strong>Credits: </strong>{renderCredits(event.addOnCredits)}</p>
          }
          {(event.location || event.city) &&
            <p><strong>Location: </strong>{event.location}{(event.location && event.city) && ' - '}{event.city && `${event.city}`}</p>
          }
        </div>
        <div>
          <StyledButton
            onClick={() => window.location.assign(event.classroomLink)}
            id={event.id}
          >
            View Detail
          </StyledButton>
        </div>
      </EventDescription>
    </Event>
  ));

  return (
    <CLEContentCard>
      <h2>Registered Add-On(s)</h2>
      <ul>
        {AddOnsListItems}
      </ul>
    </CLEContentCard>
  );
};

AddOnList.displayName = 'AddOnList';

AddOnList.propTypes = {
  addOns: PropTypes.arrayOf(PropTypes.object),
};

AddOnList.defaultProps = {
  addOns: [],
};

export default AddOnList;
