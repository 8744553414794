import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import AccountEditContainer from './components/AccountEditContainer';

const AccountEdit = ({
  myAccountOverviewLink,
}) => (
  <AccountEditContainer>
    <h3>
      <FontAwesomeIcon icon={faUser} aria-hidden="true" />
      <a
        href={myAccountOverviewLink}
        id="profileEditor"
      >
        <span>My Account</span>
      </a>
    </h3>
  </AccountEditContainer>
);

AccountEdit.propTypes = {
  myAccountOverviewLink: PropTypes.string.isRequired,
};

export default AccountEdit;
