import styled from 'styled-components';

const SearchWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  > div {
    flex-grow: 1;
    margin-right: 20px;
    margin-top: 15px;
  }
`;

SearchWrapper.displayName = 'SearchWrapper';

export default SearchWrapper;
