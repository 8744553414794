import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints, colors } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import removeNonSavedField from '@views/EditDirectory/services/removeNonSavedField';

import Award from './components/Award';

const AwardsListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  &:not(:first-child) {
    border-top: 1px solid ${colors.gray};
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
  }
`;

AwardsListItem.displayName = 'AwardsListItem';

const AddAwardContainer = styled.li`
  button {
    margin: 0;
  }
`;

AddAwardContainer.displayName = 'AddAwardContainer';

const createNewAward = () => ({
  DateReceived: '',
  Deleted: false,
  Id: 0,
  Name: '',
  TopicCodeLinks: null,
  IsSaved: false,
});

const AwardsList = ({ fields = [] }) => {
  const renderAward = (award, index) => {
    if (!fields.get(index).Deleted) {
      return (
        <AwardsListItem
          key={award}
        >
          <Award
            award={award}
            index={index}
            onRemove={() => removeNonSavedField(fields, index)}
          />
        </AwardsListItem>
      );
    }

    return undefined;
  };

  return (
    <ul>
      {fields.map(renderAward)}
      <AddAwardContainer>
        <Button
          type="button"
          onClick={() => fields.push(createNewAward())}
        >
          ADD AWARD
        </Button>
      </AddAwardContainer>
    </ul>
  );
};

AwardsList.propTypes = {
  fields: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

AwardsList.displayName = 'AwardsList';

export default AwardsList;
