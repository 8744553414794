import ApiGenerator from '@services/ApiGenerator';
import camelCaseKeys from 'camelcase-keys';

const HYDRATE = 'osba/account/EXPERIENCE_INFO_HYDRATE';
const GET = 'osba/account/EXPERIENCE_INFO_GET';
const GET_ERROR = 'osba/account/EXPERIENCE_INFO_GET_ERROR';
const GET_RECEIVED = 'osba/account/EXPERIENCE_INFO_GET_RECEIVED';
const SAVE = 'osba/account/EXPERIENCE_INFO_SAVE';
const SAVE_ERROR = 'osba/account/EXPERIENCE_INFO_SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/EXPERIENCE_INFO_SAVE_RECEIVED';

const initialState = {
  EmploymentHistory: [],
  loading: false,
  error: '',
};

const mapResponse = data => ({
  EmploymentHistory: data.EmploymentHistory,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...mapResponse(action.data),
        error: '',
      };
    case SAVE:
      return {
        ...state,
        ...action.data,
        loading: true,
        error: '',
      };
    case GET_RECEIVED:
    case SAVE_RECEIVED:
      return {
        ...state,
        ...mapResponse({ EmploymentHistory: camelCaseKeys(action.data.Model.EmploymentHistory) }),
        loading: false,
      };
    case GET_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const hydrateExperienceInfo = data => ({
  type: HYDRATE,
  data: {
    EmploymentHistory: data,
  },
});

export const saveExperienceInfo = formData => ({
  type: SAVE,
  data: {
    EmploymentHistory: formData.EmploymentHistory,
  },
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api('get', '/Employment/Get', GET);
      break;
    case SAVE:
      api('post', '/Employment/Save', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
