import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  typography,
  colors,
} from '@style-guide/config';

const YearLabel = styled.h3`
  margin-bottom: 50px;
  font-size: ${rem('20px', typography.baseFontSize)};
  width: 100%;
  border-bottom: 1px solid ${colors.gray};
  padding-bottom: 10px;
`;

YearLabel.displayName = 'YearLabel';

export default YearLabel;
