import styled from 'styled-components';

const HeadingWrapper = styled.div`
  padding: 0px 20px;
  width: 100%;
`;

HeadingWrapper.displayName = 'HeadingWrapper';

export default HeadingWrapper;
