import styled from 'styled-components';

const SearchWrapper = styled.div`
  margin-right: 24px;
  display: flex;
`;

SearchWrapper.displayName = 'SearchWrapper';

export default SearchWrapper;
