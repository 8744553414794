import styled from 'styled-components';

import { colors, breakpoints } from '@style-guide/config';
import transparentize from 'polished/lib/color/transparentize';

const ContactInfoContainer = styled.div`
  align-items: flex-start;
  background-color: ${transparentize(0.2, colors.lightGray)};
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 15px;
  position: absolute;
  width: 100%;

  @media (min-width: ${breakpoints.m}) {
    align-items: center;
    flex-direction: row;
  }
`;

ContactInfoContainer.displayName = 'ContactInfoContainer';

export default ContactInfoContainer;
