import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { typography } from '@style-guide/config';

const Subtitle = styled.h3`
  font-size: ${rem('18px', typography.baseFontSize)};
  margin-top: 32px;
  padding-bottom: 8px;
  width: 100%;
`;

Subtitle.displayName = 'Subtitle';

export default Subtitle;
