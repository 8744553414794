import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import is from 'is_js';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import DecisisSVG from '@style-guide/svg/DecisisSVG';
import { ebarContentTypesValues } from '@config';
import ResourceBookmark from '../../../shared/ResourceBookmark/ResourceBookmark';
import TitleDiv from '../../../shared/components/TitleDiv';
import ClearDiv from '../../../shared/components/ClearDiv';
import LinkMainDiv from '../../../shared/components/LinkMainDiv';
import CaseResultDiv from './components/CaseResultDiv';
import ListParagraph from '../../../shared/components/ListParagraph';

const CaseSpan = styled.span`  
  color: #024B3A;
  display: block;
  line-height: 24px;
`;

CaseSpan.displayName = 'CaseSpan';

const ButtonTextSpan = styled.span`
  margin-left: 5px;
  color: #C3002F;
  text-decoration: underline;
  font-size: 14px;
`;

ButtonTextSpan.displayName = 'ButtonTextSpan';

const StyledIcon = styled(FontAwesomeIcon)`
  color: #FFBB2B;
`;

StyledIcon.displayName = 'StyledIcon';

const Case = ({
  caseData,
  noMargin,
}) => (
  <CaseResultDiv>
    {caseData.title !== undefined &&
      is.not.null(caseData.title) &&
      caseData.title.trim().length > 0 && (
        <TitleDiv>
          {caseData.title}
        </TitleDiv>
    )}

    <CaseSpan>
      {caseData.caseName !== undefined &&
       is.not.null(caseData.caseName) &&
       caseData.caseName.trim().length > 0 && (
       <span>{caseData.caseName}</span>
      )}
      {(caseData.caseName !== undefined &&
       is.not.null(caseData.caseName) &&
       caseData.caseName.trim().length > 0 &&
       caseData.webCitation !== undefined &&
       is.not.null(caseData.webCitation) &&
       caseData.webCitation.trim().length > 0) && (
       <span> | </span>
      )}
      {caseData.webCitation !== undefined &&
       is.not.null(caseData.webCitation) &&
       caseData.webCitation.trim().length > 0 && (
       <span>{caseData.webCitation}</span>
      )}
      {((caseData.webCitation !== undefined &&
       is.not.null(caseData.webCitation) &&
       caseData.webCitation.trim().length > 0 &&
       caseData.courtName !== undefined &&
       is.not.null(caseData.courtName) &&
       caseData.courtName.trim().length > 0) ||
       (caseData.caseName !== undefined &&
       is.not.null(caseData.caseName) &&
       caseData.caseName.trim().length > 0 &&
       caseData.courtName !== undefined &&
       is.not.null(caseData.courtName) &&
       caseData.courtName.trim().length > 0)) && (
       <span> | </span>
      )}
      {caseData.courtName !== undefined &&
       is.not.null(caseData.courtName) &&
       caseData.courtName.trim().length > 0 && (
       <span>{caseData.courtName}</span>
      )}
      {((caseData.courtName !== undefined &&
       is.not.null(caseData.courtName) &&
       caseData.courtName.trim().length > 0 &&
       caseData.dateDecidedString !== undefined &&
       is.not.null(caseData.dateDecidedString) &&
       caseData.dateDecidedString.trim().length > 0) ||
       (caseData.webCitation !== undefined &&
       is.not.null(caseData.webCitation) &&
       caseData.webCitation.trim().length > 0 &&
       caseData.dateDecidedString !== undefined &&
       is.not.null(caseData.dateDecidedString) &&
       caseData.dateDecidedString.trim().length > 0) ||
       (caseData.caseName !== undefined &&
       is.not.null(caseData.caseName) &&
       caseData.caseName.trim().length > 0 &&
       caseData.dateDecidedString !== undefined &&
       is.not.null(caseData.dateDecidedString) &&
       caseData.dateDecidedString.trim().length > 0)) && (
       <span> | </span>
      )}
      {(caseData.dateDecidedString !== undefined &&
       is.not.null(caseData.dateDecidedString) &&
       caseData.dateDecidedString.trim().length > 0) ?
        `Decided: ${moment(caseData.dateDecidedString).format('MMM D, YYYY')}` : ''}
    </CaseSpan>
    {/* eslint-disable */}
    {caseData.summary !== undefined &&
      is.not.null(caseData.summary) &&
      caseData.summary.trim().length > 0 && (
      <ListParagraph dangerouslySetInnerHTML={{ __html: caseData.summary }} />
    )}
    {/* eslint-enable */}
    <br />
    <LinkMainDiv
      noMargin={noMargin}
    >
      {(caseData.pDFLink !== undefined &&
       is.not.null(caseData.pDFLink) &&
       caseData.pDFLink.trim().length > 0) && (
       <a
         href={caseData.pDFLink}
         target="_blank"
         rel="noopener noreferrer"
       >
         <StyledIcon icon={faFilePdf} /><ButtonTextSpan>PDF</ButtonTextSpan>
         &emsp;&emsp;
       </a>
      )}
      {(caseData.casemakerLink !== undefined &&
       is.not.null(caseData.casemakerLink) &&
       caseData.casemakerLink.trim().length > 0) && (
       <a
         href={caseData.casemakerLink}
         target="_blank"
         rel="noopener noreferrer"
       >
         <DecisisSVG height="14px" color="#FFBB2B" /> <ButtonTextSpan>Decisis</ButtonTextSpan>
       </a>
      )}
      <ResourceBookmark
        contentGuidString={caseData.contentGuidString}
        type={ebarContentTypesValues.eBarCase}
        isBookmarked={caseData.isBookmarked}
      />
    </LinkMainDiv>
    <ClearDiv />
  </CaseResultDiv>
);

Case.displayName = 'Case';

Case.propTypes = {
  caseData: PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
    caseName: PropTypes.string,
    webCitation: PropTypes.string,
    casemakerLink: PropTypes.string,
    pDFLink: PropTypes.string,
    practiceId: PropTypes.number,
    courtId: PropTypes.number,
    courtName: PropTypes.string,
    contentGuidString: PropTypes.string,
    isBookmarked: PropTypes.bool,
    dateDecidedString: PropTypes.string,
  }).isRequired,
  noMargin: PropTypes.bool.isRequired,
};

Case.defaultProps = {
};

export default Case;
