import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveCompanyInfo } from '@redux/modules/companyInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import CompanyInfoForm from './components/CompanyInfoForm';

class CompanyInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasWarning: false,
    };

    this.setWarning = this.setWarning.bind(this);
    this.submit = this.submit.bind(this);
  }

  setWarning(hasWarning) {
    this.setState({
      hasWarning,
    });
  }

  submit(formValues) {
    this.props.dispatch(saveCompanyInfo(formValues));
  }

  render() {
    const {
      counties,
      companyInfo,
      companyTypeComparisonValue,
      companyTypes,
      employmentStatuses,
      firmSizes,
      jobTitles,
      loading,
      states,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <CompanyInfoForm
                companyTypeComparisonValue={companyTypeComparisonValue}
                companyTypes={companyTypes}
                counties={counties}
                employmentStatuses={employmentStatuses}
                firmSizes={firmSizes}
                hasSaveError={!!error}
                initialValues={companyInfo}
                jobTitles={jobTitles}
                loading={loading}
                onSubmit={this.submit}
                setWarning={this.setWarning}
                states={states}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
          hasWarning={this.state.hasWarning}
          title="Company Information"
        />
      </FormAccordionContainer>
    );
  }
}

CompanyInfoContent.propTypes = {
  companyInfo: PropTypes.shape({
    OSBAEmploymentStatusTypeID: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    Title: PropTypes.string,
    SpecialJobTitle: PropTypes.string,
    CompanyName: PropTypes.string,
    CompanyAddressLine1: PropTypes.string,
    CompanyAddressLine2: PropTypes.string,
    CompanyCity: PropTypes.string,
    CompanyState: PropTypes.string,
    CompanyCounty: PropTypes.string,
    CompanyZipCode: PropTypes.string,
    CompanyTypeId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    NumberOfLawyers: PropTypes.number,
  }).isRequired,
  companyTypeComparisonValue: PropTypes.number.isRequired,
  companyTypes: PropTypes.arrayOf(PropTypes.object),
  counties: PropTypes.arrayOf(PropTypes.string),
  dispatch: PropTypes.func.isRequired,
  employmentStatuses: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  firmSizes: PropTypes.arrayOf(PropTypes.object),
  jobTitles: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  states: PropTypes.arrayOf(PropTypes.object),
};

CompanyInfoContent.defaultProps = {
  companyTypes: [],
  counties: [],
  employmentStatuses: [],
  error: '',
  firmSizes: [],
  jobTitles: [],
  loading: false,
  states: [],
};

const mapStateToProps = ({ companyInfo }) => ({
  companyInfo,
  error: companyInfo.error,
  loading: companyInfo.loading,
});

CompanyInfoContent.displayName = 'CompanyInfoContent';

export default connect(mapStateToProps)(CompanyInfoContent);
