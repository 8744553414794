import styled from 'styled-components';

import RadioButton from '@style-guide/components/RadioButton';

const OptionsRadioButton = styled(RadioButton)`
  margin: 15px 20px;
  font-style: italic;

  label {
    font-weight: 700 !important;
  }
`;

OptionsRadioButton.displayName = 'OptionsRadioButton';

export default OptionsRadioButton;
