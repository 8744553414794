import rem from 'polished/lib/helpers/rem';
import styled from 'styled-components';
import { breakpoints, typography } from '@style-guide/config';

import CartItemContainer from './CartItemContainer';
import ItemName from './ItemName';
import ItemPrice from './ItemPrice';

const CartSummaryItemContainer = styled(CartItemContainer)`
  ${/* sc-selector */ItemName},
  ${/* sc-selector */ItemPrice} {
    font-weight: 700;
  }

  @media (min-width: ${breakpoints.l}) {
    ${/* sc-selector */ItemName},
    ${/* sc-selector */ItemPrice} {
      font-size: ${rem('16px', typography.baseFontSize)};
    }
  }
`;

CartSummaryItemContainer.displayName = 'CartSummaryItemContainer';

export default CartSummaryItemContainer;
