import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography, breakpoints } from '@style-guide/config';

const LeftNavContainer = styled.div`
  font-size: ${rem('14px', typography.baseFontSize)};
  margin: 25px 0;

  @media (min-width: ${breakpoints.m}) {
    margin: 0 20px 55px;
  }

  @media (min-width: ${breakpoints.l}) {
    margin: 0 55px 55px;
  }
`;

LeftNavContainer.displayName = 'LeftNavContainer';

export default LeftNavContainer;
