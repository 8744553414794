import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  breakpoints,
  typography,
} from '@style-guide/config';

const Title = styled.h2`
  font-size: ${rem('24px', typography.baseFontSize)};
  font-weight: 700;
  line-height: 1.8rem;
  padding: .5rem 0 0;

  @media (min-width: ${breakpoints.m}) {
    font-size: ${rem('38px', typography.baseFontSize)};
    line-height: 3rem;
  }
`;

Title.displayName = 'Title';

export default Title;
