import React from 'react';
import styled from 'styled-components';

import SpecializationCreditSVG from '@components/SpecializationCreditSVG';

const StyledSVG = styled(SpecializationCreditSVG)`
  margin-right: 15px;
`;

const SpecializationCreditIcon = () => (
  <StyledSVG
    height="25px"
    width="25px"
  />
);

SpecializationCreditIcon.displayName = 'SpecializationCreditIcon';

export default SpecializationCreditIcon;
