import React from 'react';
import PropTypes from 'prop-types';

import LinkButton from '@style-guide/components/LinkButton';

import Wrapper from './components/Wrapper';
import Header from './components/Header';
import Title from './components/Title';

const LegislativeReportCTA = ({
  title,
  buttonLabel,
  latestReport,
}) => (
  <Wrapper>
    <Header
      title={title}
    />
    <Title>
      {latestReport.Title}
    </Title>
    <LinkButton
      href={latestReport.Link}
    >
      {buttonLabel}
    </LinkButton>
  </Wrapper>
);

LegislativeReportCTA.propTypes = {
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  latestReport: PropTypes.shape({
    AreaOfLaw: PropTypes.string,
    Category: PropTypes.string,
    Description: PropTypes.string,
    ImageLink: PropTypes.string,
    ItemType: PropTypes.number,
    Link: PropTypes.string,
    OtherResourceCategory: PropTypes.string,
    PracticeArea: PropTypes.string,
    Publication: PropTypes.string,
    ResourceType: PropTypes.string,
    Title: PropTypes.string,
  }).isRequired,
};

LegislativeReportCTA.defaultProps = {
  buttonLabel: 'View the report',
};

LegislativeReportCTA.displayName = 'LegislativeReportCTA';

export default LegislativeReportCTA;
