import FooterLegalLinks from './components/FooterLegalLinks';
import FooterLogo from './components/FooterLogo';
import FooterPrimaryLinks from './components/FooterPrimaryLinks';
import FooterSecondaryLinks from './components/FooterSecondaryLinks';

export {
  FooterLegalLinks,
  FooterLogo,
  FooterPrimaryLinks,
  FooterSecondaryLinks,
};
