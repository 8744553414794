import React from 'react';
import PropTypes from 'prop-types';

import Body from './components/Body';
import ErrorMessage from './components/ErrorMessage';
import QuestionText from './components/QuestionText';
import Radio from './components/Radio';
import Wrapper from './components/Wrapper';
import WrapperInner from './components/WrapperInner';

/* eslint-disable */
const QuestionBlock = ({
  questionData,
  input,
  meta,
  index,
}) => (
  <Wrapper>
    <QuestionText>{`#${index + 1} ${questionData.Question}`}</QuestionText>
    <Body>
      {questionData.Answer === 'Survey' && (meta.error && <ErrorMessage>{'* '}{meta.error}</ErrorMessage>)}
      {questionData.PossibleAnswers.map(answer => (
        <WrapperInner
          key={answer.Id}
        >
          <Radio
            {...input}
            id={answer.Id}
            type="radio"
            checked={input.value === answer.Code}
            name={questionData.Id}
            value={answer.Code}
          />
          <span>{answer.Description}</span>
        </WrapperInner>
      ))}
    </Body>
  </Wrapper>
);
/* eslint-enable */

QuestionBlock.propTypes = {
  questionData: PropTypes.shape({
    Question: PropTypes.string,
    PossibleAnswers: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  meta: PropTypes.shape({
    submitFailed: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

QuestionBlock.displayName = 'QuestionBlock';

export default QuestionBlock;
