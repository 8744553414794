import styled from 'styled-components';

const Name = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.5rem;
`;

Name.displayName = 'Name';

export default Name;
