import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const SortFilterLabel = styled.label`
  margin: 0px 0px 0.65rem 0px;
  font-weight: bold;

  @media (min-width: ${breakpoints.m}) {
    margin: 0px 0.65rem 0px 0px;
  }
`;

SortFilterLabel.displayName = 'SortFilterLabel';

export default SortFilterLabel;
