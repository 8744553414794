import React from 'react';
import PropTypes from 'prop-types';
import { UnmountClosed } from 'react-collapse';
import { presets } from 'react-motion';
import sortBy from 'lodash/sortBy';

import Checkbox from '@style-guide/components/Checkbox';

import Arrow from './shared/Arrow';
import Body from './shared/Body';
import ClearButton from './shared/ClearButton';
import ClearWrapper from './shared/ClearWrapper';
import Header from './shared/Header';
import Menu from './shared/Menu';
import MenuButton from './shared/MenuButton';
import Title from './shared/Title';
import Wrapper from './shared/Wrapper';

class PracticeAreaFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: true,
      selectedGroup: 'AD',
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.toggleSelectedGroup = this.toggleSelectedGroup.bind(this);
  }

  /* eslint-disable */
  filterGroup(opts, startCharCode, endCharCode) {
    let filteredOpts = [];
    if (opts != null) {
      filteredOpts = opts.filter((practiceArea) => {
        const optCharCode = practiceArea.name.charCodeAt(0);

        return optCharCode >= startCharCode && optCharCode <= endCharCode;
      });
    }

    const orderedOpts = sortBy(filteredOpts, ['name']);

    return orderedOpts;
  };
  /* eslint-enable */

  toggleCollapse() {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened,
    }));
  }

  toggleSelectedGroup(group) {
    this.setState({
      selectedGroup: group,
    });
  }

  handleKeyPress(group) {
    this.toggleCollapse(group);
  }

  render() {
    const {
      filterValue,
      filterClearAll,
      noCollapse,
      filterAppendOrRemove,
      ...restProps
    } = this.props;

    const {
      isOpened,
    } = this.state;

    const groups = [
      {
        id: 'AD',
        label: 'A-D',
      },
      {
        id: 'EK',
        label: 'E-K',
      },
      {
        id: 'LR',
        label: 'L-R',
      },
      {
        id: 'SZ',
        label: 'S-Z',
      },
    ];

    const renderGroup = groupType => this.props.groups[groupType].map((item) => {
      const isChecked = item.itemState;
      return (
        <Checkbox
          key={item.id}
          label={item.name}
          value={item.name}
          onChange={() => this.props.filterAppendOrRemove(item.id, !item.itemState)}
          id={`filterPracticeAreasOption${item.name}`}
          isChecked={isChecked}
          name="practiceAreas"
        />
      );
    });

    return (
      <Wrapper
        {...restProps}
      >
        {!noCollapse && (
          <Header
            onClick={this.toggleCollapse}
            $isOpened={isOpened}
            id="practiceAreaFilterCollapseToggle"
          >
            <Title>Areas Of Law</Title>
            <Arrow isOpened={isOpened} />
          </Header>
        )}
        <div />
        <UnmountClosed
          presets={presets.gentle}
          isOpened={isOpened}
        >
          <ClearWrapper
            $noCollapse={noCollapse}
          >
            &#40;
            <ClearButton
              onClick={() => filterClearAll()}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  filterClearAll();
                }
              }}
              tabIndex={0}
              id="practiceAreasClearAll"
            >
              Clear All
            </ClearButton>
            &#41;
          </ClearWrapper>
          <Menu
            id="filterPracticeAreasMenu"
          >
            {groups.map(group => (
              <MenuButton
                key={group.id}
                $active={this.state.selectedGroup === group.id}
                onClick={() => this.toggleSelectedGroup(group.id)}
                onKeyPress={() => this.handleKeyPress(group.id)}
                id={`filterPracticeAreasMenuOption${group.id}`}
              >
                {group.label}
              </MenuButton>
            ))}
          </Menu>
          <Body
            id="filterPracticeAreasWrapper"
          >
            <React.Fragment>
              {renderGroup(this.state.selectedGroup)}
            </React.Fragment>
          </Body>
        </UnmountClosed>
      </Wrapper>
    );
  }
}

PracticeAreaFilter.propTypes = {
  filterAppendOrRemove: PropTypes.func.isRequired,
  filterValue: PropTypes.arrayOf(PropTypes.object),
  $filterOptions: PropTypes.arrayOf(PropTypes.object),
  filterClearAll: PropTypes.func.isRequired,
  noCollapse: PropTypes.bool,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

PracticeAreaFilter.defaultProps = {
  filterValue: [],
  $filterOptions: [],
  noCollapse: false,
};

PracticeAreaFilter.displayName = 'PracticeAreaFilter';

export default PracticeAreaFilter;
