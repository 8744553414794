import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LoadingOverlay from '@components/LoadingOverlay';

import Header from './components/Header';
import LoadingSpacer from './components/LoadingSpacer';
import PercentRequired from './components/PercentRequired';
import PercentRequiredNumber from './components/PercentRequiredNumber';
import StyledContentArea from './components/StyledContentArea';
import TabTitle from './components/TabTitle';
import QuizBody from './components/QuizBody';

const CourseQuizTab = ({
  quizData,
  meetingInstance,
  updateModalState,
  updateSubmittingState,
  isSubmitting,
  personId,
  updateQuizData,
  myClesUrl,
}) => {
  if (!quizData) {
    return (
      <StyledContentArea>
        <TabTitle>{meetingInstance.displayName}</TabTitle>
        <LoadingSpacer />
        <LoadingOverlay
          message="Loading..."
        />
      </StyledContentArea>
    );
  }

  return (
    <StyledContentArea>
      <Header>
        <TabTitle>{meetingInstance.displayName}</TabTitle>
        <PercentRequired>
          <PercentRequiredNumber>100%</PercentRequiredNumber>
          <span>Required to pass</span>
        </PercentRequired>
      </Header>
      {quizData.personExamDetails && (
        <QuizBody
          personExamDetails={quizData.personExamDetails}
          meetingInstance={meetingInstance}
          updateModalState={updateModalState}
          updateSubmittingState={updateSubmittingState}
          personId={personId}
          updateQuizData={updateQuizData}
          myClesUrl={myClesUrl}
        />
      )}
      {isSubmitting && (
        <React.Fragment>
          <LoadingSpacer />
          <LoadingOverlay
            message="Submitting..."
          />
        </React.Fragment>
      )}
    </StyledContentArea>
  );
};

CourseQuizTab.propTypes = {
  quizData: PropTypes.shape({
    success: PropTypes.bool.isRequired,
    personExamDetails: PropTypes.shape({
      ExamPassed: PropTypes.bool.isRequired,
      ExamQuestions: PropTypes.arrayOf(PropTypes.object),
      ExamTaken: PropTypes.bool.isRequired,
    }).isRequired,
  }),
  meetingInstance: PropTypes.shape({
    displayName: PropTypes.string,
  }).isRequired,
  updateModalState: PropTypes.func.isRequired,
  updateSubmittingState: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  updateQuizData: PropTypes.bool.isRequired,
  personId: PropTypes.string.isRequired,
  myClesUrl: PropTypes.string.isRequired,
};

CourseQuizTab.defaultProps = {
  quizData: null,
};

CourseQuizTab.displayName = 'CourseQuizTab';

export default connect(state => state)(CourseQuizTab);
