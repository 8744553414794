import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';

const Title = styled.h3`
  font-weight: bold;
  font-size: ${rem('21px', typography.baseFontSize)};
  margin-bottom: 25px;
`;

Title.displayName = 'Title';

export default Title;
