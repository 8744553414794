import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@style-guide/components/Checkbox';

import { reduxInputPropType } from '@services/PropTypes';

const ReduxFormCheckbox = ({
  input,
  label = '',
  ...restProps
}) => (
  <Checkbox
    {...input}
    label={label}
    isChecked={`${input.value}` === `${restProps.value}`}
    {...restProps}
  />
);

ReduxFormCheckbox.propTypes = {
  input: reduxInputPropType.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

ReduxFormCheckbox.displayName = 'ReduxFormCheckbox';

export default ReduxFormCheckbox;
