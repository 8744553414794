import styled from 'styled-components';
import { breakpoints, colors } from '@style-guide/config';

const ResourceUtilitiesContainer = styled.div`
  background-color: ${colors.white};
  margin-bottom: 20px;

  @media (min-width: ${breakpoints.l}) {
    margin-bottom: 0;
    padding: 20px;
    position: absolute;
    right: -390px;
    top: 0;
    width: 350px;
  }
`;

ResourceUtilitiesContainer.displayName = 'ResourceUtilitiesContainer';

export default ResourceUtilitiesContainer;
