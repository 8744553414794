import is from 'is_js';
import keys from 'lodash/keys';
import includes from 'lodash/includes';
import { SubmissionError } from 'redux-form';

import postQuizData from './postQuizData';

const handleSubmit = (values, dispatch, props) => {
  const {
    personExamDetails,
    updateSubmittingState,
    personId,
    updateQuizData,
    myClesUrl,
  } = props;

  const errors = {};

  updateSubmittingState(true);

  // checking that all questions have an answer
  personExamDetails.ExamQuestions.map((question) => {
    if (!values[`vk_${question.Id}`]) {
      errors[`vk_${question.Id}`] = 'Required field';
    }

    return undefined;
  });

  if (is.not.empty(errors)) {
    const errIds = keys(errors);
    // we need to add an order property so that we can refer to it later in the modal
    const questionsWithOrder = personExamDetails.ExamQuestions.map((question, index) => ({
      ...question,
      order: index + 1,
    }));
    // check if the errors contains the question ID
    const activeErrs = questionsWithOrder.filter(question => includes(errIds, `vk_${question.Id}`));
    // adding an error message
    const activeErrsWithErrMessage = activeErrs.map(err => ({
      ...err,
      message: errors[`vk_${err.Id}`],
    }));

    props.updateModalState({
      isOpen: true,
      message: 'Invalid submission, please try again.',
      errors: activeErrsWithErrMessage,
      buttonAction: () => props.updateModalState({
        isOpen: false,
      }),
      buttonLabel: 'Retry',
    });

    updateSubmittingState(false);

    throw new SubmissionError(errors);
  }

  // checking if the answers are right
  personExamDetails.ExamQuestions.map((question) => {
    if (question.Answer !== 'Survey') {
      const answerIsInvalid = values[`vk_${question.Id}`] !== question.Answer;
      if (answerIsInvalid) {
        errors[`vk_${question.Id}`] = 'Wrong answer';
      }
    }

    return undefined;
  });

  if (is.not.empty(errors)) {
    props.updateModalState({
      isOpen: true,
      message: 'You failed, please try again.',
      errors: [],
      buttonAction: () => props.updateModalState({
        isOpen: false,
      }),
      buttonLabel: 'Retry',
    });

    updateSubmittingState(false);

    throw new SubmissionError(errors);
  }

  const updatedPersonExamDetails = {
    ...personExamDetails,
    ExamPassed: true,
    ExamTaken: true,
  };

  postQuizData(updatedPersonExamDetails, personExamDetails, personId)
    .then((res) => {
      if (res.success) {
        updateQuizData({
          success: true,
          personExamDetails: {
            ...updatedPersonExamDetails,
          },
        });

        props.updateModalState({
          isOpen: true,
          message: 'You passed!',
          buttonAction: () => window.location.assign(myClesUrl),
          buttonLabel: 'Go to My CLEs',
        });

        updateSubmittingState(false);
      } else {
        props.updateModalState({
          isOpen: true,
          message: 'You failed, please try again.',
          buttonAction: () => {
            props.updateModalState({
              isOpen: false,
            });
          },
          buttonLabel: 'Retry',
        });

        updateSubmittingState(false);
      }
    });

  return {};
};

export default handleSubmit;
