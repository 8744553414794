import React from 'react';
import { Provider } from 'react-redux';
import { getLinksInfo } from '@redux/modules/linksInfo';
import Store from '@redux';

import LinksContent from './components/LinksContent';

class LinksInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getLinksInfo());
  }

  render() {
    return (
      <Provider store={this.store}>
        <LinksContent
          {...this.props}
        />
      </Provider>
    );
  }
}

LinksInfo.displayName = 'LinksInfo';

export default LinksInfo;
