import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { breakpoints, colors, typography } from '@style-guide/config';

const ContentBlock = styled.div`
  padding: 20px;
  margin: 20px 0 20px 0px;
  background-color: ${colors.lightGray};

  h3 {
    color: ${colors.red};
    font-size: ${rem('15px', typography.baseFontSize)};
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    margin: 0px;
  }

  span {
    font-weight: bold;
  }

  @media (min-width: ${breakpoints.m}) {
    p {
      margin: 0 0 4px 0;
    }
  }
`;

ContentBlock.displayName = 'ContentBlock';

export default ContentBlock;
