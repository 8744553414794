import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import is from 'is_js';
import styled from 'styled-components';
import { ebarContentTypesValues } from '@config';
import LinkMainDiv from '../shared/components/LinkMainDiv';
import AngleSpan from '../shared/components/AngleSpan';
import LinkAnchor from '../shared/components/LinkAnchor';
import CaseSpan from '../shared/components/CaseSpan';
import TitleDiv from '../shared/components/TitleDiv';
import ClearDiv from '../shared/components/ClearDiv';
import ResourceBookmark from '../shared/ResourceBookmark/ResourceBookmark';
import ListParagraph from '../shared/components/ListParagraph';

const DataDiv = styled.div`
  padding: 14px 0;  
`;

DataDiv.displayName = 'DataDiv';

const EBarSCOAnnouncements = ({
  eBarSCOAnnouncementsLists,
}) => (
  <DataDiv>
    {eBarSCOAnnouncementsLists.title !== undefined &&
        is.not.null(eBarSCOAnnouncementsLists.title) &&
        eBarSCOAnnouncementsLists.title.trim().length > 0 && (
        <TitleDiv>{eBarSCOAnnouncementsLists.title}</TitleDiv>
    )}
    <CaseSpan>
      {eBarSCOAnnouncementsLists.cite !== undefined &&
       is.not.null(eBarSCOAnnouncementsLists.cite) &&
       eBarSCOAnnouncementsLists.cite.trim().length > 0 && (
       <span>{ eBarSCOAnnouncementsLists.cite }</span>
      )}
      {(eBarSCOAnnouncementsLists.cite !== undefined &&
       is.not.null(eBarSCOAnnouncementsLists.cite) &&
       eBarSCOAnnouncementsLists.cite.trim().length > 0 &&
       eBarSCOAnnouncementsLists.effectivePostedDateString !== undefined &&
       is.not.null(eBarSCOAnnouncementsLists.effectivePostedDateString) &&
       eBarSCOAnnouncementsLists.effectivePostedDateString.trim().length > 0) && (
       <span> | </span>
      )}
      {(eBarSCOAnnouncementsLists.effectivePostedDateString !== undefined &&
       is.not.null(eBarSCOAnnouncementsLists.effectivePostedDateString) &&
       eBarSCOAnnouncementsLists.effectivePostedDateString.trim().length > 0) ?
        eBarSCOAnnouncementsLists.dateTypeString === '1' ?
          `Posted: ${moment(eBarSCOAnnouncementsLists.effectivePostedDateString).format('MMM D, YYYY')}` :
          eBarSCOAnnouncementsLists.dateTypeString === '3' ?
            `Deadline: ${moment(eBarSCOAnnouncementsLists.effectivePostedDateString).format('MMM D, YYYY')}` :
            `Effective: ${moment(eBarSCOAnnouncementsLists.effectivePostedDateString).format('MMM D, YYYY')}` : ''}
    </CaseSpan>
    {/* eslint-disable */}
    {eBarSCOAnnouncementsLists.summary !== undefined &&
      is.not.null(eBarSCOAnnouncementsLists.summary) &&
      eBarSCOAnnouncementsLists.summary.trim().length > 0 &&
     (<ListParagraph dangerouslySetInnerHTML={{ __html: eBarSCOAnnouncementsLists.summary }} />)}
    {/* eslint-enable */}
    <LinkMainDiv>
      {((eBarSCOAnnouncementsLists.cTAUrl !== undefined &&
        is.not.null(eBarSCOAnnouncementsLists.cTAUrl) &&
        eBarSCOAnnouncementsLists.cTAUrl.trim().length > 0) ||
        (eBarSCOAnnouncementsLists.cTALabel !== undefined &&
         is.not.null(eBarSCOAnnouncementsLists.cTALabel) &&
         eBarSCOAnnouncementsLists.cTALabel.trim().length > 0)) && (
         <div>
           <AngleSpan>
             &gt;
           </AngleSpan>
           <LinkAnchor
             href={(eBarSCOAnnouncementsLists.cTAUrl !== undefined &&
                   is.not.null(eBarSCOAnnouncementsLists.cTAUrl) &&
                   eBarSCOAnnouncementsLists.cTAUrl.trim().length > 0) ?
               eBarSCOAnnouncementsLists.cTAUrl : eBarSCOAnnouncementsLists.cTALabel}
             target="_blank"
             rel="noopener noreferrer"
           >
             {(eBarSCOAnnouncementsLists.cTALabel !== undefined &&
              is.not.null(eBarSCOAnnouncementsLists.cTALabel) &&
              eBarSCOAnnouncementsLists.cTALabel.trim().length > 0) ?
               eBarSCOAnnouncementsLists.cTALabel : eBarSCOAnnouncementsLists.cTAUrl}
           </LinkAnchor>
         </div>
      )}
      <ResourceBookmark
        contentGuidString={eBarSCOAnnouncementsLists.contentGuidString}
        type={ebarContentTypesValues.eBarSCOAnnouncement}
        isBookmarked={eBarSCOAnnouncementsLists.isBookmarked}
      />
    </LinkMainDiv>
    <ClearDiv />
  </DataDiv>
);

EBarSCOAnnouncements.propTypes = {
  eBarSCOAnnouncementsLists: PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeaderString: PropTypes.string,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateTypeString: PropTypes.string,
    summary: PropTypes.string,
    isBookmarked: PropTypes.bool,
  }).isRequired,
};

EBarSCOAnnouncements.defaultProps = {
};

EBarSCOAnnouncements.displayName = 'EBarSCOAnnouncements';

export default EBarSCOAnnouncements;
