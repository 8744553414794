import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import trimStart from 'lodash/trimStart';
import rem from 'polished/lib/helpers/rem';
import { typography, breakpoints, colors } from '@style-guide/config';

const GlobalStyle = createGlobalStyle`
  [data-react-component="FooterLegalLinks"] {
    width: 100%;
  }
`;

const LegalLinksContainer = styled.ul`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  text-align: center;

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    padding: 0 55px;
  }
`;

LegalLinksContainer.displayName = 'LegalLinksContainer';

const LegalItem = styled.li`
  display: flex;
  margin: 0 auto;

  @media (min-width: ${breakpoints.l}) {
    height: ${rem('60px', typography.baseFontSize)};
    margin: 0;
  }
`;

LegalItem.displayName = 'LegalItem';

const LegalCopyright = styled(LegalItem)`
  align-items: baseline;
  height: ${rem('40px', typography.baseFontSize)};

  @media (min-width: ${breakpoints.l}) {
    align-items: center;
  }
`;

LegalCopyright.displayName = 'LegalCopyright';

const LegalNav = styled(LegalItem)`
  align-items: center;
  height: ${rem('70px', typography.baseFontSize)};
`;

LegalNav.displayName = 'LegalNav';

const LegalLink = styled.a`
  color: ${colors.black};

  &:not(:last-child) {
    margin-right: 2rem;
  }
`;

LegalLink.displayName = 'LegalLink';

const FooterLegalLinks = ({ legalLinks }) => {
  const currentYear = (new Date()).getFullYear();
  const linksWithIDs = legalLinks.map((link, index) => ({
    id: index,
    ...link,
  }));

  const legalNavItems = linksWithIDs.map(link => (
    <LegalLink
      key={link.id}
      target={link.target}
      href={trimStart(link.href, '~')}
      title={link.title}
      id={`footerLegalLink${link.id}`}
    >
      {link.text}
    </LegalLink>
  ));

  return (
    <React.Fragment>
      <GlobalStyle />
      <LegalLinksContainer>
        <LegalCopyright>
          <span>&copy; {currentYear} Ohio State Bar Association, All Rights Reserved</span>
        </LegalCopyright>
        <LegalNav>
          {legalNavItems}
        </LegalNav>
      </LegalLinksContainer>
    </React.Fragment>
  );
};

FooterLegalLinks.propTypes = {
  legalLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
};

FooterLegalLinks.displayName = 'FooterLegalLinks';

export default FooterLegalLinks;
