import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveSelectedPracticeAreas, setLimitError, removeLimitError } from '@redux/modules/practiceAreas';

import ErrorMessage from '@components/ErrorMessage';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import PracticeAreasForm from './components/PracticeAreasForm';

class PracticeAreasContent extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.checkForCheckedLimit = this.checkForCheckedLimit.bind(this);
  }

  handleSubmit(formValues) {
    const {
      practiceAreas,
      dispatch,
    } = this.props;

    // since the Redux checkbox group requires just an array of strings, that's all we get back
    // to determine which ones are checked.  So we need to filter through all the practice areas
    // and look to see which items are in the array of strings (meaning the user has checked them)
    const selected = practiceAreas.filter(item =>
      formValues.SelectedPracticeAreas.indexOf(item.name) > -1);

    dispatch(saveSelectedPracticeAreas({ SelectedPracticeAreas: selected }));
  }

  checkForCheckedLimit(newSelectedValues) {
    // newSelectedValues comes back as an object with keys for each selected value
    // plus a preventDefault key so need to check for > 6 keys
    if (Object.keys(newSelectedValues).length > 6) {
      this.props.dispatch(setLimitError());
    } else {
      this.props.dispatch(removeLimitError());
    }
  }

  render() {
    const {
      loading,
      practiceAreas,
      SelectedPracticeAreas,
      title,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <React.Fragment>

        <h2>{title}</h2>
        <FormContainer>
          {loading && <LoadingOverlay />}

          {error && (
          <ErrorMessage>
            <span>{error}</span>
          </ErrorMessage>
          )}

          <PracticeAreasForm
            practiceAreas={practiceAreas}
            initialValues={{ SelectedPracticeAreas }}
            onSubmit={this.handleSubmit}
            checkLimitHandler={this.checkForCheckedLimit}
            loading={loading}
            hasSaveError={!!error}
          />
        </FormContainer>
      </React.Fragment>
    );
  }
}

PracticeAreasContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  SelectedPracticeAreas: PropTypes.arrayOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  practiceAreas: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string,
  })),
};

PracticeAreasContent.defaultProps = {
  error: '',
  loading: false,
  title: 'Practice Areas',
  practiceAreas: [],
};

const mapStateToProps = ({ practiceAreaInfo }) => ({
  error: practiceAreaInfo.error,
  loading: practiceAreaInfo.loading,
  SelectedPracticeAreas: practiceAreaInfo.SelectedPracticeAreas,
});

PracticeAreasContent.displayName = 'PracticeAreasContent';

export default connect(mapStateToProps)(PracticeAreasContent);
