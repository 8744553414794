import styled from 'styled-components';

import { colors } from '@style-guide/config';

const CardWrapper = styled.div`
  background-color: ${colors.white};
  margin-top: 30px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.5);
`;

CardWrapper.displayName = 'CardWrapper';

export default CardWrapper;
