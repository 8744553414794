import styled from 'styled-components';

const CartItemContainer = styled.li`
  line-height: 1.25em;

  > div {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0 0 0 15px;

    span {
      font-weight: 600;
    }
  }

  + li {
    padding-top: 10px;
  }
`;

CartItemContainer.displayName = 'CartItemContainer';

export default CartItemContainer;
