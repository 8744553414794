const required = value => (value ? undefined : 'Required');
const maxLength = max => value => (
  value && value.length > max ? `Must be ${max} characters or less` : undefined
);
const minLength = min => value => (
  value && value.length < min ? `Must be ${min} characters or more` : undefined
);

const ccNumber = (value, allValues) => {
  if (!allValues.cardType) {
    return undefined;
  }

  if (allValues.cardType !== '8' && value.length !== 16) {
    return 'Must be 16 characters';
  }

  if (allValues.cardType === '8' && value.length !== 15) {
    return 'Must be 15 characters';
  }

  return undefined;
};

const phoneNumber = value => (
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined
);

const allNumbers = value => (
  value && /^[0-9]+$/g.test(value)
    ? undefined
    : 'Can only contain numbers'
);

const email = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(value)
    ? 'Invalid email address'
    : undefined
);

const facebookUrl = value => (
  value && !/(http(s)?:\/\/.www\.)?(facebook\.com)\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g.test(value)
    ? 'Please enter a valid URL- ex. facebook.com/OhioBar'
    : undefined
);

const instagramUrl = value => (
  value && !/(http(s)?:\/\/.www\.)?(instagram\.com)\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g.test(value)
    ? 'Please enter a valid URL- ex. instagram.com/ohiobar'
    : undefined
);

const twitterUrl = value => (
  value && !/(http(s)?:\/\/.www\.)?(twitter\.com)\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g.test(value)
    ? 'Please enter a valid URL- ex. twitter.com/OSBA'
    : undefined
);

const linkedinUrl = value => (
  value && !/(http(s)?:\/\/.www\.)?(linkedin\.com)\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g.test(value)
    ? 'Please enter a valid URL- ex. linkedin.com/company/ohio-state-bar-association'
    : undefined
);

export {
  required,
  maxLength,
  minLength,
  ccNumber,
  phoneNumber,
  email,
  allNumbers,
  facebookUrl,
  twitterUrl,
  linkedinUrl,
  instagramUrl,
};
