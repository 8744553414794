import styled from 'styled-components';

import Title from './Title';

const NoOrdersTitle = styled(Title)`
  width: 100%;
  text-align: center;
`;

NoOrdersTitle.displayName = 'NoOrdersTitle';

export default NoOrdersTitle;
