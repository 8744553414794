import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';

const PriceList = styled.ul`
  margin-bottom: 15px;

  li {
    display: flex;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 5px;
    }

    &:first-child > span {
      font-weight: 600;
    }
  }

  span {
    font-size: ${rem('14px', typography.baseFontSize)};
  }
`;

PriceList.displayName = 'PriceList';

export default PriceList;
