import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { colors, typography, breakpoints } from '@style-guide/config';

const SecondaryInfoWrapper = styled.div`
  width: 100%;
  padding: 0px;
  margin: 0 0 20px 0;

  .divider {
    margin: 15px 0px;

    span {
      width: 100%;
      height: 1px;
      margin: 0px;
    }
  }

  @media (min-width: ${breakpoints.m}) {
    display: flex;
    padding: 0 15px 0 30px;

    .divider {
      margin: 0px 15px;

      span {
        width: 1px;
        height: 100%;
      }
    }
  }
`;

SecondaryInfoWrapper.displayName = 'SecondaryInfoWrapper';

const ContentColumn = styled.div`
  font-size: ${rem('15px', typography.baseFontSize)};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0px;
  margin: 0px;
  flex: 1;

  a {
    text-decoration: underline;
    color: ${colors.red}
  }

  p {
    margin: 0 0 8px 0;
    padding: 0px;
  }

  &:first-child {
    padding: 0 15px 0 0px;
    margin-top: 20px;
  }

  &:last-child {
    border: none;
  }

  p:last-child {
    margin: 0px;
  }

  span {
    font-weight: bold;
  }

  @media (min-width: ${breakpoints.m}) {
    border-right: 1px solid ${colors.darkGray};
    border-bottom: none;
    padding: 0px 15px;
    min-height: 85px;

    &:first-child {
      margin: 0;
    }
  }
`;

ContentColumn.displayName = 'ContentColumn';

const LogoColumn = styled.div`
  text-align: center;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 15px;
  flex: 1;
  max-width: 100%;
  overflow: hidden;

  img {
    max-height: 85px;
    max-width: 100%;
  }

  @media (min-width: ${breakpoints.m}) {
    padding-left: 15px;
    padding-top: 0px;
    justify-content: center;
  }
`;

LogoColumn.displayName = 'LogoColumn';

const DirectorySecondaryContactInfo = props => (
  <SecondaryInfoWrapper>
    {(props.companyName || props.companyAddress) && (
      <ContentColumn>
        {props.companyName &&
          <p><span>Company/Firm: </span> {props.companyName}</p>
        }
        {props.companyAddress &&
          <p><span>Address: </span> {props.companyAddress}</p>
        }
      </ContentColumn>
    )}
    {(props.officePhone || props.officeFax) && (
      <ContentColumn>
        {props.officePhone && (
          <p><span>Primary Phone Number: </span> {props.officePhone}</p>
        )}
        {props.officeFax && (
          <p><span>Fax: </span> {props.officeFax}</p>
        )}
      </ContentColumn>
    )}
    {props.logoURL && (
      <LogoColumn>
        <img src={props.logoURL} alt={props.companyName} />
      </LogoColumn>
    )}
  </SecondaryInfoWrapper>
);

DirectorySecondaryContactInfo.propTypes = {
  companyName: PropTypes.string,
  companyAddress: PropTypes.string,
  officePhone: PropTypes.string,
  officeFax: PropTypes.string,
  logoURL: PropTypes.string,
};

DirectorySecondaryContactInfo.defaultProps = {
  companyName: '',
  companyAddress: '',
  officePhone: '',
  officeFax: '',
  logoURL: '',
};

DirectorySecondaryContactInfo.displayName = 'DirectorySecondaryContactInfo';

export default DirectorySecondaryContactInfo;
