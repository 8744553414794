import styled from 'styled-components';

const DatePickerWrapper = styled.div`
  margin-right: 24px;
  display: flex;
`;

DatePickerWrapper.displayName = 'DatePickerWrapper';

export default DatePickerWrapper;
