import styled from 'styled-components';

import DetailsInner from './DetailsInner';

const DetailsWrapper = styled.div`
  margin: 30px 0px;
  display: flex;
  flex-direction: column;

  ${DetailsInner} {
    align-self: flex-start;

    button {
      align-self: flex-start;
    }
  }
`;

DetailsWrapper.displayName = 'DetailsWrapper';

export default DetailsWrapper;
