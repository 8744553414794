import ApiGenerator from '@services/ApiGenerator';

const HYDRATE = 'osba/account/MEMBER_BENEFITS_INFO_HYDRATE';
const SAVE = 'osba/account/MEMBER_BENEFITS_INFO_SAVE';
const SAVE_ERROR = 'osba/account/MEMBER_BENEFITS_INFO_SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/MEMBER_BENEFITS_INFO_SAVE_RECEIVED';

const initialState = {
  cleUpdates: true,
  cleFrequency: 1,
  clePromotions: true,
  memberPracticeMgmtTools: false,
  memberJobListings: false,
  memberEvents: false,
  loading: false,
  error: '',
  message: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.data,
        message: '',
        error: '',
      };
    case SAVE:
      return {
        ...state,
        ...action.data,
        loading: true,
        message: '',
        error: '',
      };
    case SAVE_RECEIVED:
      return {
        ...state,
        ...action.data.Model,
        message: action.data.message,
        loading: false,
      };
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const hydrateMemberBenefitsInfo = propsData => ({
  type: HYDRATE,
  data: {
    ...propsData,
  },
});

export const saveMemberBenefitsInfo = formData => ({
  type: SAVE,
  data: {
    cleUpdates: formData.cleUpdates,
    cleFrequency: formData.cleFrequency,
    clePromotions: formData.clePromotions,
    memberPracticeMgmtTools: formData.memberPracticeMgmtTools,
    memberJobListings: formData.memberJobListings,
    memberEvents: formData.memberEvents,
  },
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case SAVE:
      api('post', '/MemberBenefitInformationBlock/Save', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
