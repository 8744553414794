import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const Wrapper = styled.div`
  margin: 30px 0;
  padding: 0px 15px;

  @media(min-width: ${breakpoints.m}) {
    padding: 0px;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
