import styled from 'styled-components';

import ContentArea from '@views/CLEClassroom/components/ContentArea';

const StyledContentArea = styled(ContentArea)`
  background-color: transparent;
  padding: 0px;
  flex-direction: column;
`;

StyledContentArea.displayName = 'StyledContentArea';

export default StyledContentArea;
