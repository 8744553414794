import styled from 'styled-components';

const LinkedInLink = styled.a`
  svg {
    color: #0077B5;
  }
`;

LinkedInLink.displayName = 'LinkedInLink';

export default LinkedInLink;
