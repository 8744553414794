// we have to pass in the deletedPropertyName because the property on the models that indicates
// deletion isn't consistently named across different fields (education, links, etc)
const removeNonSavedField = (fields, index) => {
  // redux-form field array objects have immutable accessors
  // so we have to splice to modify
  // https://redux-form.com/7.4.2/docs/api/fieldarray.md/#fields-props

  const deletedAward = JSON.parse(JSON.stringify(fields.get(index)));

  if (deletedAward.IsSaved !== undefined && deletedAward.IsSaved === false) {
    fields.remove(index);
  } else {
    deletedAward.Deleted = true;
    fields.splice(index, 1, deletedAward);
  }
};

export default removeNonSavedField;
