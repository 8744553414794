import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;

  @media(min-width: ${breakpoints.m}) {
    min-width: 620px;
  }

  @media(min-width: ${breakpoints.l}) {
    max-width: 800px;
  }

  @media(min-width: ${breakpoints.s}) {
    max-width: 456px;
  }
  @media(min-width: ${breakpoints.m}) {
    max-width: 684px;
  }

  @media(min-width: ${breakpoints.l}) {
    max-width: 855px;
  }

  @media(min-width: ${breakpoints.xl}) {
    max-width: 1140px;
    flex-direction: row;
  }
`;

ContentArea.displayName = 'ContentArea';

export default ContentArea;
