import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const SpeakerGridItem = styled.div`
  border-bottom: 1px solid ${colors.lightGray};
  padding: 15px;

  @media(min-width: ${breakpoints.l}) {
    border-bottom: 0;
    padding-right: ${props => (props.noBorderOnL ? '0px' : '15px')};
    margin-right: ${props => (props.noBorderOnL ? '0px' : '15px')};
    border-right: ${props => (props.noBorderOnL ? '0' : `1px solid ${colors.lightGray}`)};
    width: ${props => (props.noBorderOnL ? '50%' : 'calc(50% - 30px)')};
  }

  @media(min-width: ${breakpoints.xl}) {
    width: ${props => (props.noBorderOnXl ? '33%' : 'calc(33% - 30px)')};
    padding-right: ${props => (props.noBorderOnXl ? '0px' : '15px')};
    margin-right: ${props => (props.noBorderOnXl ? '0px' : '15px')};
    border-right: ${props => (props.noBorderOnXl ? '0' : `1px solid ${colors.lightGray}`)};
  }
`;

export default SpeakerGridItem;
