import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, colors, typography } from '@style-guide/config';

const FormHeader = styled.h2`
  border-bottom: 1px solid ${colors.darkGray};
  font-size: ${rem('18px', typography.baseFontSize)};
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 100%;

  @media (min-width: ${breakpoints.m}) {
    font-size: ${rem('22px', typography.baseFontSize)};
  }
`;

FormHeader.displayName = 'FormHeader';

export default FormHeader;
