import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import Button from '@style-guide/components/Button';
import ComponentDiv from './components/ComponentDiv';
import Name from './components/Name';

const ActionButton = styled.button`
  border: none;
  cursor: pointer;
`;

ActionButton.displayName = 'ActionButton';

const ViewColumn = styled.td`
  background-color: #FFFFFF;
`;

ViewColumn.displayName = 'ViewColumn';

const ImageStyle = {
  height: '24px',
  width: '24px',
};

const EBarNewsResult = ({
  initialValues = {
    summary: '',
    url: '',
    createdDate: '',
    title: '',
  },
  viewId = '',
  onClickViewButton,
  onClickEditButton,
  onClickCloseCancel,
  onClickDelete,
}) => (
  <React.Fragment>
    <tr>
      <td>{initialValues.title}</td>
      {/* eslint-disable-next-line react/no-danger */}
      <td dangerouslySetInnerHTML={{ __html: initialValues.summary }} />
      <td>Link/URL</td>
      <td>{moment(initialValues.createdDate).format('MM/DD/YYYY')}</td>
      <td>
        <ActionButton
          title="Edit"
          onClick={() => onClickEditButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Edit.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="View"
          onClick={() => onClickViewButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Detail.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="Delete"
          onClick={() => onClickDelete(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Delete.png" alt="" style={ImageStyle} />
        </ActionButton>
      </td>
    </tr>
    {viewId && viewId === initialValues.contentGuidString && (
    <tr>
      <ViewColumn colSpan="5">
        <ComponentDiv>
          <Name>{initialValues.title}</Name>
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: initialValues.summary }} />
          <p><span>Url: </span><a href={initialValues.url} target="_blank" rel="noopener noreferrer">{initialValues.url}</a></p>
          <p><span>Active Date:</span> {moment(initialValues.createdDate).format('MM/DD/YYYY')}</p>
          <Button
            onClick={() => onClickCloseCancel()}
          >
            Close
          </Button>
        </ComponentDiv>
      </ViewColumn>
    </tr>
    )}
  </React.Fragment>
);


EBarNewsResult.displayName = 'EBarNewsResult';

EBarNewsResult.propTypes = {
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    url: PropTypes.string,
    createdDate: PropTypes.string,
  }),
  viewId: PropTypes.string,
  onClickViewButton: PropTypes.func.isRequired,
  onClickEditButton: PropTypes.func.isRequired,
  onClickCloseCancel: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default EBarNewsResult;
