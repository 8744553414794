import React from 'react';
import PropTypes from 'prop-types';

import YellowLineSVG from '@style-guide/svg/YellowLineSVG';

import ContactInfoContainer from './components/ContactInfoContainer';
import CoverImageWrapper from './components/CoverImageWrapper';
import JobTitle from './components/JobTitle';
import HeaderContactInfo from './components/HeaderContactInfo/HeaderContactInfo';
import ProfileImage from './components/ProfileImage';
import Title from './components/Title';
import Wrapper from './components/Wrapper';

const DirectoryProfileHeader = ({
  blogURL,
  coverImageURL,
  email,
  facebookURL,
  instagramURL,
  jobTitle,
  linkedInURL,
  name,
  profileImageURL,
  twitterURL,
  vCardUrl,
}) => (
  <React.Fragment>
    <YellowLineSVG />
    <Title>{name}</Title>
    <Wrapper>
      <CoverImageWrapper
        backgroundImage={coverImageURL}
      >
        <ProfileImage
          alt={name}
          src={!profileImageURL ? undefined : profileImageURL}
        />
        <ContactInfoContainer>
          {jobTitle && (
            <JobTitle>{jobTitle}</JobTitle>
          )}
          <HeaderContactInfo
            blogURL={blogURL}
            email={email}
            facebookURL={facebookURL}
            instagramURL={instagramURL}
            linkedInURL={linkedInURL}
            twitterURL={twitterURL}
            vCardUrl={vCardUrl}
          />
        </ContactInfoContainer>
      </CoverImageWrapper>
    </Wrapper>
  </React.Fragment>
);

DirectoryProfileHeader.propTypes = {
  blogURL: PropTypes.string,
  coverImageURL: PropTypes.string,
  email: PropTypes.string,
  facebookURL: PropTypes.string,
  instagramURL: PropTypes.string,
  jobTitle: PropTypes.string,
  linkedInURL: PropTypes.string,
  name: PropTypes.string,
  profileImageURL: PropTypes.string,
  twitterURL: PropTypes.string,
  vCardUrl: PropTypes.string.isRequired,
};

DirectoryProfileHeader.defaultProps = {
  blogURL: '',
  coverImageURL: '',
  email: '',
  facebookURL: '',
  instagramURL: '',
  jobTitle: '',
  linkedInURL: '',
  name: '',
  profileImageURL: '',
  twitterURL: '',
};

DirectoryProfileHeader.displayName = 'DirectoryProfileHeader';

export default DirectoryProfileHeader;
