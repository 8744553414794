import styled from 'styled-components';
import { colors } from '@style-guide/config';

const Divider = styled.div`
  border-bottom: 1px solid ${colors.lightGray};
`;

Divider.displayName = 'Divider';

export default Divider;
