import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

import MembershipTypeContainer from './MembershipTypeContainer';

const FlexWrapper = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  padding: 20px;

  ${MembershipTypeContainer} {
    margin: 0px 0px 15px 0px;
    padding: 0px 0px 15px 0px;
    border-bottom: 1px solid ${colors.lightGray};
  }

  @media(min-width: ${breakpoints.l}) {
    flex-direction: row;

    ${MembershipTypeContainer} {
      border-bottom: 0;
      border-right: 1px solid ${colors.lightGray};
      margin: 0px 15px 0px 0px;
      padding: 0px 15px 0px 0px;
    }
  }

  @media(min-width: ${breakpoints.xl}) {
    flex-direction: row;

    ${MembershipTypeContainer} {
      margin: 0px 15px 0px 0px;
      padding: 0px 15px 0px 0px;
    }
  }
`;

FlexWrapper.displayName = 'FlexWrapper';

export default FlexWrapper;
