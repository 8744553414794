import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  height: 50%;
`;

ButtonWrapper.displayName = 'ButtonWrapper';

export default ButtonWrapper;
