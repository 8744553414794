import styled from 'styled-components';

const Answer = styled.strong`
  font-weight: bold;
  margin-bottom: 5px;
`;

Answer.displayName = 'Answer';

export default Answer;
