import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import {
  colors,
  typography,
  breakpoints,
} from '@style-guide/config';
import LinkButton from '@style-guide/components/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media(min-width: ${breakpoints.s}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${colors.red};
  margin-right: 16px;
  font-size: ${rem('24px', typography.baseFontSize)};
`;

const StyledLinkButton = styled(LinkButton)`
  margin-top: 15px;

  @media(min-width: ${breakpoints.s}) {
    margin-top: 0;
  }
`;

class CourseMaterialsLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const linkProps = {
      target: '_blank',
      rel: 'noopener noreferrer',
    };
    return (
      <Wrapper>
        <FlexWrapper>
          <StyledIcon icon={faArrowUpRightFromSquare} />
          <span>{this.props.ctaLabel}</span>
        </FlexWrapper>
        <StyledLinkButton
          href={this.props.ctaUrl}
          {...linkProps}
        >
          View
        </StyledLinkButton>
      </Wrapper>
    );
  }
}


CourseMaterialsLink.propTypes = {
  ctaLabel: PropTypes.string,
  ctaUrl: PropTypes.string,
};

CourseMaterialsLink.defaultProps = {
  ctaLabel: '',
  ctaUrl: '',
};

CourseMaterialsLink.displayName = 'CourseMaterialsLink';

export default CourseMaterialsLink;
