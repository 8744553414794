import concat from 'lodash/concat';
import isMatch from 'lodash/isMatch';
import reject from 'lodash/reject';

import ApiGenerator from '@services/ApiGenerator';
import encodeSearchFilters from '@services/EncodeSearchFilters';

const SEARCH = 'osba/findALawyer/SEARCH';
const SEARCH_LOAD_MORE = 'osba/findALawyer/SEARCH_LOAD_MORE';
const SEARCH_ERROR = 'osba/findALawyer/SEARCH_ERROR';
const SEARCH_RECEIVED = 'osba/findALawyer/SEARCH_RECEIVED';
const SEARCH_LOAD_MORE_ERROR = 'osba/findALawyer/SEARCH_LOAD_MORE_ERROR';
const SEARCH_LOAD_MORE_RECEIVED = 'osba/findALawyer/SEARCH_LOAD_MORE_RECEIVED';
const SORT_UPDATE = 'osba/findALawyer/SORT_UPDATE';
const FILTERS_UPDATE = 'osba/findALawyer/FILTERS_UPDATE';
const FILTERS_UPDATE_MULTIPLE = 'osba/findALawyer/FILTERS_UPDATE_MULTIPLE';
const FILTERS_RESTORE = 'osba/findALawyer/FILTERS_RESTORE';
const FILTERS_CLEAR = 'osba/findALawyer/FILTERS_CLEAR';
const FILTERS_APPEND_OR_REMOVE = 'osba/findALawyer/FILTERS_APPEND_OR_REMOVE';

const initialState = {
  count: 0,
  error: null,
  filters: {
    keyword: '',
    firstName: '',
    lastName: '',
    lawFirm: '',
    locations: [],
    practiceAreas: [],
    areasOfLaw: [],
    languages: [],
    certifications: [],
  },
  loading: false,
  results: {
    Lawyers: [],
    Positions: [],
    Count: 0,
    Total: 0,
  },
  sort: {
    criteria: 'random',
    pageNumber: 1,
    pageLength: 10,
    Positions: [],
  },
  totalCount: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
    case SEARCH_LOAD_MORE:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_RECEIVED:
      return {
        ...state,
        ...action.data,
        sort: {
          ...state.sort,
          Positions: action.data.results.Positions,
        },
        loading: false,
        error: null,
      };
    case SEARCH_LOAD_MORE_RECEIVED:
      return {
        ...state,
        ...action.data,
        loading: false,
        results: {
          ...action.data.results,
          Lawyers: concat(state.results.Lawyers, action.data.results.Lawyers),
        },
        error: null,
      };
    case SEARCH_ERROR:
    case SEARCH_LOAD_MORE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SORT_UPDATE:
      return {
        ...state,
        sort: {
          ...state.sort,
          [action.key]: action.value,
        },
      };
    case FILTERS_UPDATE:
    case FILTERS_APPEND_OR_REMOVE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.field]: action.value,
        },
        sort: {
          ...initialState.sort,
          criteria: state.sort.criteria,
        },
      };
    case FILTERS_UPDATE_MULTIPLE:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filterUpdates,
        },
        sort: {
          ...initialState.sort,
          criteria: state.sort.criteria,
        },
      };
    case FILTERS_RESTORE:
      return {
        ...state,
        filters: {
          ...initialState.filters,
          ...action.filters,
        },
      };
    case FILTERS_CLEAR:
      return {
        ...state,
        filters: {
          keyword: '',
          locations: [],
          practiceAreas: [],
          areasOfLaw: [],
          languages: [],
          certifications: [],
          firstName: '',
          lastName: '',
          lawFirm: '',
        },
        sort: {
          ...initialState.sort,
          criteria: state.sort.criteria,
        },
      };
    default:
      return state;
  }
};

export const search = (sortFilters, searchFilters) => ({
  type: SEARCH,
  sortFilters,
  searchFilters,
});

export const searchLoadMore = (sortFilters, searchFilters) => ({
  type: SEARCH_LOAD_MORE,
  sortFilters,
  searchFilters,
});

export const sortUpdate = (key, value) => ({
  type: SORT_UPDATE,
  key,
  value,
});

export const filtersUpdate = (field, value) => ({
  type: FILTERS_UPDATE,
  field,
  value,
});

export const filtersUpdateMultiple = filterUpdates => ({
  type: FILTERS_UPDATE_MULTIPLE,
  filterUpdates,
});

export const filtersRestore = filters => ({
  type: FILTERS_RESTORE,
  filters,
});

export const filtersClear = () => ({
  type: FILTERS_CLEAR,
});

export const filtersAppendOrRemove = (field, value, currentVals) => {
  const newVal = currentVals.filter(currentVal => isMatch(value, currentVal)).length ?
    reject(currentVals, currentVal => isMatch(value, currentVal))
    :
    [...currentVals, value];

  return ({
    type: FILTERS_APPEND_OR_REMOVE,
    field,
    value: newVal,
  });
};

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);
  const encodedFilters = action.searchFilters ? encodeSearchFilters(action.searchFilters) : null;

  next(action);

  switch (action.type) {
    case SEARCH:
      api(
        'get',
        `/FindALawyerSearch?sortData=${JSON.stringify(action.sortFilters)}&searchData=${JSON.stringify(encodedFilters)}`,
        SEARCH,
      );
      break;
    case SEARCH_LOAD_MORE:
      api(
        'get',
        `/FindALawyerSearch?sortData=${JSON.stringify(action.sortFilters)}&searchData=${JSON.stringify(encodedFilters)}`,
        SEARCH_LOAD_MORE,
      );
      break;
    default:
      break;
  }
};

export default reducer;
