import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 15px;

  @media(min-width: ${breakpoints.m}) {
    width: 50%;
    margin-bottom: 0px;
  }
`;

ThumbnailWrapper.displayName = 'ThumbnailWrapper';

export default ThumbnailWrapper;
