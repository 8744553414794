import styled from 'styled-components';
import { colors } from '@style-guide/config';

const ShoppingCartToggle = styled.button`
  align-items: center;
  color: ${props => (props.$isOpen ? colors.black : colors.white)};
  display: flex;
  height: 100%;
  padding: 0 .5rem;

  svg {
    margin-right: 5px;
  }
`;

ShoppingCartToggle.displayName = 'ShoppingCartToggle';

export default ShoppingCartToggle;
