import ApiGenerator from '@services/ApiGenerator';
import camelCaseKeys from 'camelcase-keys';

const HYDRATE = 'osba/account/MARKETING_PREFERENCES_INFO_HYDRATE';
const GET = 'osba/account/MARKETING_PREFERENCES_INFO_GET';
const GET_ERROR = 'osba/account/MARKETING_PREFERENCES_INFO_GET_ERROR';
const GET_RECEIVED = 'osba/account/MARKETING_PREFERENCES_INFO_GET_RECEIVED';
const SAVE = 'osba/account/MARKETING_PREFERENCES_INFO_SAVE';
const SAVE_ERROR = 'osba/account/MARKETING_PREFERENCES_INFO_SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/MARKETING_PREFERENCES_INFO_SAVE_RECEIVED';

const initialState = {
  cleMarketing: false,
  practiceManagementDiscountPrograms: false,
  travelDiscountPrograms: false,
  loading: false,
  error: '',
  message: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.data,
        message: '',
        error: '',
      };
    case SAVE:
      return {
        ...state,
        ...action.data,
        loading: true,
        message: '',
        error: '',
      };
    case GET_RECEIVED:
    case SAVE_RECEIVED:
      return {
        ...state,
        ...camelCaseKeys(action.data.Model),
        message: action.data.message,
        loading: false,
      };
    case GET_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const hydrateExperienceInfo = data => ({
  type: HYDRATE,
  data: {
    ...data,
  },
});

export const saveMarketingPreferencesInfo = data => ({
  type: SAVE,
  data: {
    cleMarketing: data.cleMarketing,
    practiceManagementDiscountPrograms: data.practiceManagementDiscountPrograms,
    travelDiscountPrograms: data.travelDiscountPrograms,
  },
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api('get', '/MarketingPreferencesForm/Get', GET);
      break;
    case SAVE:
      api('post', '/MarketingPreferencesForm/Save', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
