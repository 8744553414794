import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const SpeakerCardContainer = styled.div`
  display: flex;
  width: 100%;

  img {
    margin: 0 15px 0 0;
    width: 130px;
  }

  @media (min-width: ${breakpoints.l}) {
    img {
      width: 100px;
    }
  }
`;

SpeakerCardContainer.displayName = 'SpeakerCardContainer';

export default SpeakerCardContainer;
