import ApiGenerator from '@services/ApiGenerator';

const GET = 'osba/account/PracticeAreas_INFO_GET';
const GET_ERROR = 'osba/account/PracticeAreas_INFO_GET_ERROR';
const GET_RECEIVED = 'osba/account/PracticeAreas_INFO_GET_RECEIVED';
const SAVE = 'osba/account/PracticeAreas_INFO_SAVE';
const SAVE_ERROR = 'osba/account/PracticeAreas_INFO_SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/PracticeAreas_INFO_SAVE_RECEIVED';
const SET_LIMIT_ERROR = 'osba/account/PracticeAreas_SET_LIMIT_ERROR';
const REMOVE_LIMIT_ERROR = 'osba/account/PracticeAreas_REMOVE_LIMIT_ERROR';

const initialState = {
  SelectedPracticeAreas: [],
  loading: false,
  message: '',
  error: '',
};

const mapResponse = (data) => {
  // the ReduxCheckboxFormGroup component requires an array of just strings
  // we get an array of objects so we need to flatten it.
  const flattenedPracticeAreas = data.SelectedPracticeAreas.map(item => item.Name);

  return ({
    message: data.Message,
    SelectedPracticeAreas: flattenedPracticeAreas,
    error: data.SelectedPracticeAreas.length > 5 ? 'You may only select 5 Practice Areas' : '',
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SAVE:
      return {
        ...state,
        ...action.data,
        loading: true,
        error: '',
      };
    case GET_RECEIVED:
    case SAVE_RECEIVED:
      return {
        ...state,
        ...mapResponse(action.data),
        loading: false,
      };
    case SET_LIMIT_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case REMOVE_LIMIT_ERROR:
      return {
        ...state,
        error: '',
      };
    case GET_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getSelectedPracticeAreas = () => ({
  type: GET,
});

export const setLimitError = () => ({
  type: SET_LIMIT_ERROR,
  error: 'You can only have 5 Practice Areas checked',
});

export const removeLimitError = () => ({
  type: REMOVE_LIMIT_ERROR,
  error: '',
});


export const saveSelectedPracticeAreas = data => ({
  type: SAVE,
  data: {
    SelectedPracticeAreas: data.SelectedPracticeAreas,
  },
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api('get', '/PracticeSubscriptions/GetPracticeSubscriptions', GET);
      break;
    case SAVE:
      api('post', '/PracticeSubscriptions/SavePracticeSubscriptions', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
