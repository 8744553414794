import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const DescriptionHeading = styled.h2`
  display: none;

  @media (min-width: ${breakpoints.l}) {
    display: block;
  }
`;

DescriptionHeading.displayName = 'DescriptionHeading';

export default DescriptionHeading;
