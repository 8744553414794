import isMatch from 'lodash/isMatch';
import reject from 'lodash/reject';

import ApiGenerator from '@services/ApiGenerator';
import encodeSearchFilters from '@services/EncodeSearchFilters';

const SEARCH = 'osba/siteSearch/SEARCH';
const SEARCH_ERROR = 'osba/siteSearch/SEARCH_ERROR';
const SEARCH_RECEIVED = 'osba/siteSearch/SEARCH_RECEIVED';
const FILTERS_UPDATE = 'osba/siteSearch/FILTERS_UPDATE';
const FILTERS_RESTORE = 'osba/siteSearch/FILTERS_RESTORE';
const FILTERS_CLEAR = 'osba/fsiteSearch/FILTERS_CLEAR';
const FILTERS_APPEND_OR_REMOVE = 'osba/siteSearch/FILTERS_APPEND_OR_REMOVE';

const initialState = {
  count: 0,
  error: null,
  filters: {
    keyword: '',
    section: [],
  },
  loading: false,
  results: {
    Cles: [],
    Events: [],
    Count: 0,
    Lawyers: [],
    Pages: [],
    Resources: [],
    EBarGreenbookResults: [],
    SearchLinks: {},
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_RECEIVED: {
      return {
        ...state,
        ...action.data,
        error: null,
        loading: false,
      };
    }
    case SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FILTERS_UPDATE:
    case FILTERS_APPEND_OR_REMOVE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.field]: action.value,
        },
      };
    case FILTERS_RESTORE:
      return {
        ...state,
        filters: {
          ...initialState.filters,
          ...action.filters,
        },
      };
    case FILTERS_CLEAR:
      return {
        ...state,
        filters: {
          keyword: '',
          section: [],
        },
      };
    default:
      return state;
  }
};

export const search = searchFilters => ({
  type: SEARCH,
  searchFilters,
});

export const filtersUpdate = (field, value) => ({
  type: FILTERS_UPDATE,
  field,
  value,
});

export const filtersRestore = filters => ({
  type: FILTERS_RESTORE,
  filters,
});

export const filtersClear = () => ({
  type: FILTERS_CLEAR,
});

export const filtersAppendOrRemove = (field, value, currentVals) => {
  const newVal = currentVals.filter(currentVal => isMatch(value, currentVal)).length ?
    reject(currentVals, currentVal => isMatch(value, currentVal))
    :
    [...currentVals, value];

  return ({
    type: FILTERS_APPEND_OR_REMOVE,
    field,
    value: newVal,
  });
};

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);
  const encodedFilters = action.searchFilters ? encodeSearchFilters(action.searchFilters) : null;

  next(action);

  switch (action.type) {
    case SEARCH:
      api(
        'get',
        `/globalsearch?searchData=${JSON.stringify(encodedFilters)}`,
        SEARCH,
      );
      break;
    default:
      break;
  }
};

export default reducer;
