import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  height: 100%;
  margin-right: 10px;
`;

ButtonWrapper.displayName = 'ButtonWrapper';

export default ButtonWrapper;
