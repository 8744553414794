import ApiGenerator from '@services/ApiGenerator';

const GET = 'osba/account/COMPANY_INFO_GET';
const GET_ERROR = 'osba/account/COMPANY_INFO_GET_ERROR';
const GET_RECEIVED = 'osba/account/COMPANY_INFO_GET_RECEIVED';
const SAVE = 'osba/account/COMPANY_INFO_SAVE';
const SAVE_ERROR = 'osba/account/COMPANY_INFO_SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/COMPANY_INFO_SAVE_RECEIVED';

const initialState = {
  OSBAEmploymentStatusTypeID: 0,
  Title: '',
  SpecialJobTitle: '',
  CompanyName: '',
  CompanyAddressLine1: '',
  CompanyAddressLine2: '',
  CompanyCity: '',
  CompanyState: '',
  CompanyCounty: '',
  CompanyZipCode: '',
  CompanyTypeId: 0,
  NumberOfLawyers: 0,
  loading: false,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SAVE:
      return {
        ...state,
        ...action.data,
        loading: true,
        error: '',
      };
    case GET_RECEIVED:
    case SAVE_RECEIVED:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case GET_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getCompanyInfo = () => ({
  type: GET,
});

export const saveCompanyInfo = data => ({
  type: SAVE,
  data: {
    OSBAEmploymentStatusTypeID: data.OSBAEmploymentStatusTypeID,
    Title: data.Title,
    SpecialJobTitle: data.Title === 'Other' ? data.SpecialJobTitle : null,
    CompanyName: data.CompanyName,
    CompanyAddressLine1: data.CompanyAddressLine1,
    CompanyAddressLine2: data.CompanyAddressLine2,
    CompanyCity: data.CompanyCity,
    CompanyState: data.CompanyState,
    CompanyCounty: data.CompanyCounty,
    CompanyZipCode: data.CompanyZipCode,
    CompanyTypeId: data.CompanyTypeId,
    NumberOfLawyers: data.NumberOfLawyers,
  },
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api('get', '/CompanyInformationForm/GetCompanyInformation', GET);
      break;
    case SAVE:
      api('post', '/CompanyInformationForm/SaveCompanyInformation', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
