import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 30px 30px 0px;

  @media(min-width: ${breakpoints.l}) {
    background-color: transparent;
    border-bottom: 0;
    left: 0;
    margin-bottom: 0px;
    position: absolute;
    top: -65px;
    padding: 0;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
