import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';

import { typography, colors, breakpoints } from '@style-guide/config';
import { ClearFix } from '@style-guide/mixins';
import LinkButton from '@style-guide/components/LinkButton';

const Wrapper = styled.div`
  ${props => (props.hasTopMargin || props.hasBottomMargin ? ClearFix() : '')}

  margin-bottom: ${props => (props.hasBottomMargin ? (props.largeMargins ? '75px' : '30px') : '0')};
  margin-top: ${props => (props.hasTopMargin ? (props.largeMargins ? '75px' : '30px') : '0')};
  width: 100%;
`;

Wrapper.displayName = 'Wrapper';

const WrapperInner = styled.div`
  align-items: 'flex-start';
  background: ${colors.darkBlue};
  color: white;
  display: flex;
  margin: ${props => (props.centered ? 'auto' : '0px')};

  ${props => (props.hasMaxWidth ? `
    @media (min-width: ${breakpoints.l}) {
      max-width: 80%;
    }

    @media (min-width: ${breakpoints.xl}) {
      max-width: 50%;
    }
  ` : '')}
`;

WrapperInner.displayName = 'WrapperInner';

const IconContainer = styled.div`
  background: ${colors.yellow};
  padding: 25px;
  font-size: 60px;
  color: white;
  display: none;

  @media (min-width: ${breakpoints.m}) {
    display: block;
  }
`;

IconContainer.displayName = 'IconContainer';

const ContentContainer = styled.div`
  padding: 20px;
  flex: 1;
`;

ContentContainer.displayName = 'ContentContainer';

const Title = styled.p`
  color: ${colors.blue};
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 8px;
`;

Title.displayName = 'Title';

const DescriptionBlock = styled.div`
  font-size: ${rem('14px', typography.baseFontSize)};
  line-height: 1rem;
  border-bottom: 1px solid ${colors.lightGray};
  padding-bottom: 10px;
`;

DescriptionBlock.displayName = 'DescriptionBlock';

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 25px;

  a {
    margin-right: 15px;
  }
`;

ButtonContainer.displayName = 'ButtonContainer';

const BecomeAMemberBlock = ({
  title,
  centered,
  description,
  hasTopMargin,
  hasBottomMargin,
  hasMaxWidth,
  largeMargins,
  becomeAMemberLink,
}) => (
  <Wrapper
    hasTopMargin={hasTopMargin}
    hasBottomMargin={hasBottomMargin}
    largeMargins={largeMargins}
  >
    <WrapperInner
      centered={centered}
      hasMaxWidth={hasMaxWidth}
    >
      <IconContainer>
        <FontAwesomeIcon icon={faKey} />
      </IconContainer>
      <ContentContainer>
        <Title>{title.toUpperCase()}</Title>
        <DescriptionBlock>{description}</DescriptionBlock>
        <ButtonContainer>
          <LinkButton
            href={becomeAMemberLink}
          >
            BECOME A MEMBER
          </LinkButton>
        </ButtonContainer>
      </ContentContainer>
    </WrapperInner>
  </Wrapper>
);

BecomeAMemberBlock.propTypes = {
  centered: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hasTopMargin: PropTypes.bool,
  hasBottomMargin: PropTypes.bool,
  hasMaxWidth: PropTypes.bool,
  largeMargins: PropTypes.bool,
  becomeAMemberLink: PropTypes.string.isRequired,
};

BecomeAMemberBlock.defaultProps = {
  hasTopMargin: false,
  hasBottomMargin: false,
  hasMaxWidth: true,
  centered: false,
  largeMargins: false,
};

BecomeAMemberBlock.displayName = 'BecomeAMemberBlock';

export default BecomeAMemberBlock;
