import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Button from '@style-guide/components/Button';

import FormFieldSet from '@components/FormFieldSet';
import ReduxFormCheckboxGroup from '@components/ReduxFormCheckboxGroup';
import CheckBoxGroupWrapper from './components/CheckBoxGroupWrapper';

class TopicOfInterestsForm extends React.Component {
  // the ReduxFormCheckboxGroup just requires a array of strings to make checkboxes from
  // we receive an array of objects with ID so we need to flatten it to just an array of names
  static flattenTopicOfInterestArray(whichArray) {
    return (whichArray.map(item => item.name));
  }

  render() {
    return (
      <form
        id="topicOfInterestsForm"
        onSubmit={values => this.props.handleSubmit(values)}
      >
        <CheckBoxGroupWrapper>
          <FormFieldSet>
            <Field
              name="SelectedTopicOfInterests"
              props={{
                options:
                    TopicOfInterestsForm.flattenTopicOfInterestArray(this.props.topicOfInterests),
                columns: 3,
              }}
              component={ReduxFormCheckboxGroup}
            />
          </FormFieldSet>
        </CheckBoxGroupWrapper>
        <Button
          type="submit"
          disabled={(this.props.pristine || this.props.loading) || this.props.hasSaveError}
        >
          Save
        </Button>
      </form>
    );
  }
}

TopicOfInterestsForm.propTypes = {
  topicOfInterests: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hasSaveError: PropTypes.bool,
  pristine: PropTypes.bool,
};


TopicOfInterestsForm.defaultProps = {
  hasSaveError: false,
  loading: true,
  topicOfInterests: [],
  pristine: true,
};

TopicOfInterestsForm.displayName = 'TopicOfInterestsForm';

export default reduxForm({
  form: 'TopicOfInterestsForm',
  enableReinitialize: true,
})(TopicOfInterestsForm);
