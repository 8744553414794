import styled from 'styled-components';

const UploadLabel = styled.span`
  display: block;
  font-weight: 700;
  margin: 15px 0px 7.5px 0px;
`;

UploadLabel.displayName = 'UploadLabel';

export default UploadLabel;
