import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/*
 * Attributes
 * 1) message - which message is to display in pop-up
 * 2) heading - any title for that message in pop-up
 * 3) bodyContent - if you want to set ur custom
*/

const ModalDiv = styled.div`
  &.modalDialog {
    position: fixed;
    font-family: Arial,Helvetica,Sans-Serif;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.8);
    z-index: 99999;
    /*-webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;*/
    transition: opacity 400ms ease-in;
    pointer-events: auto;
  }

  &.modalDialog p {
    padding: 0px 20px;
  }

  &.modalDialog > div {
    width: 480px;
    position: relative;
    margin: 15% auto;
    padding: 5px 0px 13px 0px;
    border-radius: 10px;
    background: #fff;
  }

  &.modalDialog label {
    font-family: Arial,Helvetica,Sans-Serif;
    font-size: 12px;
    line-height: 20px;
    font-weight: 200;
    padding-right: 15px;
    width: 450px;
  }

`;
ModalDiv.displayName = 'ModalDiv';

const HeadingDiv = styled.div`
  &.heading {
    margin: 15px; 
  }

  &.non-heading {
    margin: 40px;
  }
`;
HeadingDiv.displayName = 'HeadingDiv';

const SubmitDiv = styled.div`
  &.container-right {
    float: right;
    padding: 10px;
  }
`;
SubmitDiv.displayName = 'SubmitDiv';

const ClearDiv = styled.div`
  &.clear {
    clear: both;
  }
`;
ClearDiv.displayName = 'ClearDiv';

const CloseButton = styled.button`
  &.close-icon {
    cursor: pointer;
    display: block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    margin: -15px -10px 0px 0px;
    border-width: 3px;
    border-style: solid;
    border-color: #c3002f;
    border-radius: 100%;
    background: linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%, white 56%,transparent 56%, transparent 100%), linear-gradient(45deg, transparent 0%, transparent 46%, white 46%, white 56%,transparent 56%, transparent 100%);
    background-color: #c3002f;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.5);
    transition: all 0.3s ease;
  }

  &.close-icon:hover {
    background-color: #a41f35;
    border-color: #a41f35;
  }

  &.clear {
    clear: both;
  }

  &.container-right {
    float: right;
    padding: 10px;
  }
`;
CloseButton.displayName = 'CloseButton';

const FooterButton = styled.button`
  &.msgOk {
    cursor: pointer;
    font-weight: 400;
    color: #fff;
    border: 1px solid #CA9E3D;
    padding: .3rem 1.5rem;
    text-align: center;
    background: #c3002f;
    text-transform: uppercase;
    text-decoration: none;
  }
  
  &.msgOk:hover {
    color: #fff;
    background: #a41f35;
  }
`;
FooterButton.displayName = 'FooterButton';

const PopupMessage = (props) => {
  const {
    onHandleClick,
    onSaveClick,
    heading,
    submitButton,
    bodyContent,
  } = props;

  return (
    <ModalDiv className="modalDialog">
      <div>
        <CloseButton type="button" onClick={onHandleClick} className="close-icon container-right clear" />
        <HeadingDiv className={heading !== '' ? 'heading' : 'non-heading'}>
          {heading !== '' && (<h5>{heading}</h5>)}
        </HeadingDiv>
        <hr />
        <span>
          <p>
            {bodyContent}
          </p>
          <hr />
          <SubmitDiv className="container-right">
            {submitButton !== '' &&
                submitButton
            }
            {submitButton === '' &&
            <FooterButton type="button" className="msgOk" onClick={onSaveClick}>Ok</FooterButton>
            }
          </SubmitDiv>
        </span>
        <ClearDiv className="clear" />
      </div>
    </ModalDiv>
  );
};

PopupMessage.propTypes = {
  onHandleClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  heading: PropTypes.string,
  submitButton: PropTypes.element,
  bodyContent: PropTypes.element,
};

PopupMessage.defaultProps = {
  heading: '',
  submitButton: '',
  bodyContent: '',
};

export default PopupMessage;
