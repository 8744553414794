import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const ProgressTrackerContainer = styled.ol`
  counter-reset: step;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  margin: 20px 10px;

  @media (min-width: ${breakpoints.m}) {
    margin: 15px 50px;
  }
`;

ProgressTrackerContainer.displayName = 'ProgressTrackerContainer';

export default ProgressTrackerContainer;
