import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import is from 'is_js';
import { ebarContentTypesValues } from '@config';
import ResultDiv from '../shared/components/ResultDiv';
import LinkMainDiv from '../shared/components/LinkMainDiv';
import AngleSpan from '../shared/components/AngleSpan';
import LinkAnchor from '../shared/components/LinkAnchor';
import CaseSpan from '../shared/components/CaseSpan';
import TitleDiv from '../shared/components/TitleDiv';
import ClearDiv from '../shared/components/ClearDiv';
import ResourceBookmark from '../shared/ResourceBookmark/ResourceBookmark';
import ListParagraph from '../shared/components/ListParagraph';

const EBarEnactments = ({
  eBarEnactmentsLists,
}) => (
  <ResultDiv>
    {eBarEnactmentsLists.title !== undefined && is.not.null(eBarEnactmentsLists.title) &&
        eBarEnactmentsLists.title.trim().length > 0 && (
          <TitleDiv>{eBarEnactmentsLists.title}</TitleDiv>
    )}
    <CaseSpan>
      {eBarEnactmentsLists.billNumber !== undefined &&
        is.not.null(eBarEnactmentsLists.billNumber) &&
        eBarEnactmentsLists.billNumber.trim().length > 0 && (
          <span>{eBarEnactmentsLists.billNumber}</span>
      )}
      {(eBarEnactmentsLists.billNumber !== undefined &&
          is.not.null(eBarEnactmentsLists.billNumber) &&
        eBarEnactmentsLists.billNumber.trim().length > 0 &&
        eBarEnactmentsLists.effectiveDate !== undefined &&
          is.not.null(eBarEnactmentsLists.effectiveDate) &&
        eBarEnactmentsLists.effectiveDate.trim().length > 0) && (
          <span> | </span>
      )}
      {(eBarEnactmentsLists.effectiveDate !== undefined &&
        is.not.null(eBarEnactmentsLists.effectiveDate) &&
        eBarEnactmentsLists.effectiveDate.trim().length > 0) ?
        `Effective: ${moment(eBarEnactmentsLists.effectiveDate).format('MMM D, YYYY')}` : ''}
    </CaseSpan>
    {/* eslint-disable */}
    {eBarEnactmentsLists.summary !== undefined &&
      is.not.null(eBarEnactmentsLists.summary) &&
      eBarEnactmentsLists.summary.trim().length > 0 && (
        <ListParagraph dangerouslySetInnerHTML={{ __html: eBarEnactmentsLists.summary }} />
    )}
    {/* eslint-enable */}
    <LinkMainDiv>
      {((eBarEnactmentsLists.ctaUrl !== undefined && is.not.null(eBarEnactmentsLists.ctaUrl) &&
        eBarEnactmentsLists.ctaUrl.trim().length > 0) ||
        (eBarEnactmentsLists.ctaLabel !== undefined && is.not.null(eBarEnactmentsLists.ctaLabel) &&
        eBarEnactmentsLists.ctaLabel.trim().length > 0)) && (
          <div>
            <AngleSpan>
              &gt;
            </AngleSpan>
            <LinkAnchor
              href={(eBarEnactmentsLists.ctaUrl !== undefined &&
                is.not.null(eBarEnactmentsLists.ctaUrl) &&
                eBarEnactmentsLists.ctaUrl.trim().length > 0) ?
                eBarEnactmentsLists.ctaUrl : eBarEnactmentsLists.ctaLabel}
              target="_blank"
              rel="noopener noreferrer"
            >
              {(eBarEnactmentsLists.ctaLabel !== undefined &&
                is.not.null(eBarEnactmentsLists.ctaLabel) &&
                eBarEnactmentsLists.ctaLabel.trim().length > 0) ?
                eBarEnactmentsLists.ctaLabel : eBarEnactmentsLists.ctaUrl}
            </LinkAnchor>
          </div>
      )}
      <ResourceBookmark
        contentGuidString={eBarEnactmentsLists.contentGuidString}
        type={ebarContentTypesValues.eBarLegislativeEnactment}
        isBookmarked={eBarEnactmentsLists.isBookmarked}
      />
    </LinkMainDiv>
    <ClearDiv />
  </ResultDiv>
);

EBarEnactments.propTypes = {
  eBarEnactmentsLists: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    billNumber: PropTypes.string,
    effectiveDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    isBookmarked: PropTypes.bool,
  }).isRequired,
};

EBarEnactments.defaultProps = {
};

EBarEnactments.displayName = 'EBarEnactments';

export default EBarEnactments;
