import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  button {
    margin: 0;
  }

  > div {
    width: 100%;
  }

  @media(min-width: ${breakpoints.l}) {
    flex-direction: row;
  }

  > div:first-of-type {
    align-self: flex-start;
    margin-right: 30px;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
