import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '@style-guide/config';

const Wrapper = styled.div`
  margin-bottom: 15px;
`;

Wrapper.displayName = 'Wrapper';

const ArticleLink = styled.a`
  color: ${colors.black};
  font-weight: bolder;
  font-size: larger;
  transition: color ease 0.5s;

  &:hover {    
    color: ${colors.red};
  }
`;

ArticleLink.displayName = 'ArticleLink';

const Link = ({
  label,
  url,
}) => (
  <Wrapper>
    <ArticleLink
      href={url}
    >
      {label}
    </ArticleLink>
  </Wrapper>
);

Link.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

Link.displayName = 'Link';

export default Link;
