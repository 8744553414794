import styled from 'styled-components';
import { rem } from 'polished';

import { typography } from '@style-guide/config';

const ResourceType = styled.span`
  align-items: center;
  display: flex;
  font-size: ${rem('14px', typography.baseFontSize)};
  flex-direction: row;
`;

ResourceType.displayName = 'ResourceType';

export default ResourceType;
