import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';

const SpeakerName = styled.span`
  display: block;
  font-size: ${rem('16px', typography.baseFontSize)};
  font-weight: 600;
`;

SpeakerName.displayName = 'SpeakerName';

export default SpeakerName;
