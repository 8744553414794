const AddBookmark = async (id) => {
  const AddBookMarkCall = await fetch('/PracticeLibraryBookmark/AddBookmark', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: `pageId=${id}`,
  });

  // if the call returns any other code other than 200 (OK)
  // just return false
  return AddBookMarkCall.status === 200;
};

const DeleteBookmark = async (id) => {
  const DeleteBookMarkCall = await fetch('/PracticeLibraryBookmark/DeleteBookmark', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    body: `pageId=${id}`,
  });
  return DeleteBookMarkCall.status === 200;
};

export {
  AddBookmark,
  DeleteBookmark,
};
