import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Link from '@style-guide/mixins/Link';
import { colors, typography } from '@style-guide/config';

const AlternativeLocations = styled.div`
  margin: 15px 0;

  div {
    font-weight: 600;
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 20px;
  }

  li {
    margin: 0 0 10px 10px;
  }

  a {
    ${Link({ type: 'red' })}
  }

  svg {
    color: ${colors.red};
    font-size: ${rem('12px', typography.baseFontSize)};
    margin-left: 5px;
  }
`;

AlternativeLocations.displayName = 'AlternativeLocations';

export default AlternativeLocations;
