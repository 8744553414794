import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography, colors } from '@style-guide/config';
import FlyoutActions from './components/FlyoutActions';
import FlyoutHelp from './components/FlyoutHelp';

const FlyoutClose = styled.button`
  color: ${colors.white};
  font-size: ${rem('20px', typography.baseFontSize)};
  height: 18px;
  position: relative;
  width: 18px;

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &::before,
  &::after {
    background: ${colors.white};
    border-radius: 5px;
    content: "";
    height: 2px;
    left: 0px;
    margin-top: -1px;
    position: absolute;
    top: 50%;
    width: 100%;
  }
`;

FlyoutClose.displayName = 'FlyoutClose';

const FlyoutHeading = styled.h4`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-right: 50px;
  width: 100%;
`;

FlyoutHeading.displayName = 'FlyoutHeading';

const FlyoutOpenContent = ({
  heading,
  actions,
  description,
  undoSelection,
  toggleOpenState,
  onKeyPress,
}) => (
  <div
    id="flyoutContainerOpen"
  >
    <FlyoutHeading>
      <span>{heading}</span>
      <FlyoutClose
        aria-label="Close"
        tabIndex="0"
        onClick={toggleOpenState}
        onKeyPress={onKeyPress}
        id="flyoutContainerDismiss"
      />
    </FlyoutHeading>
    <div>
      <FlyoutActions
        actions={actions}
      />
      <FlyoutHelp
        description={description}
        undoSelection={undoSelection}
      />
    </div>
  </div>
);

FlyoutOpenContent.propTypes = {
  heading: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  description: PropTypes.string.isRequired,
  undoSelection: PropTypes.string.isRequired,
  toggleOpenState: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
};

FlyoutOpenContent.displayName = 'FlyoutOpenContent';

export default FlyoutOpenContent;
