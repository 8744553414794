import styled from 'styled-components';
import WysiwygStyles from '@style-guide/mixins/WysiwygStyles';

const Subtitle = styled.p`
  ${WysiwygStyles()}

  margin-bottom: 15px;
  margin-top: 15px;
`;

Subtitle.displayName = 'Subtitle';

export default Subtitle;
