const encodeSearchFilters = filters => filters.map((filter) => {
  if (filter.Value) {
    return {
      Name: filter.Name,
      Value: encodeURIComponent(filter.Value),
    };
  }

  const mappedMembers = filter.Values.map(member => encodeURIComponent(member));

  return {
    Name: filter.Name,
    Values: mappedMembers,
  };
});

export default encodeSearchFilters;
