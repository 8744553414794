const warn = ({
  CellPhoneNumber,
  HomePhoneNumber,
  PersonalEmailAddress,
  PreferredEmail,
  WorkOrSchoolEmailAddress,
  WorkPhoneNumber,
}, props) => {
  const warnings = {};
  const phoneMissing = (!HomePhoneNumber && !CellPhoneNumber && !WorkPhoneNumber);
  const emailMissing = (!PersonalEmailAddress && !WorkOrSchoolEmailAddress);
  const emailsMatch = PersonalEmailAddress === WorkOrSchoolEmailAddress;
  const noPreferredEmail = !PreferredEmail;

  if (!props.loading && (phoneMissing || emailMissing)) {
    /* this is part of redux-form API */
    /* eslint-disable no-underscore-dangle */
    warnings._warning = 'Missing required fields';
    props.setWarning(true);
  } else if (emailsMatch) {
    warnings._warning = 'Personal & work/school emails can\'t be the same';
    props.setWarning(true);
    /* eslint-enable no-underscore-dangle */
  } else if (noPreferredEmail) {
    /* this is part of redux-form API */
    /* eslint-disable no-underscore-dangle */
    warnings._warning = 'Must specify a preferred email';
    props.setWarning(true);
  } else {
    props.setWarning(false);
  }

  return warnings;
};

export default warn;
