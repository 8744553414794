import styled from 'styled-components';

import ResultWrapper from './ResultWrapper';
import YearResultsWrapper from './YearResultsWrapper';

const ResultsWrapper = styled.div`
  ${/* sc-selector */ResultWrapper}:not(:last-of-type) {
    margin-bottom: 50px;
  }

  ${/* sc-selector */YearResultsWrapper}:not(:last-of-type) {
    margin-bottom: 50px;
  }
`;

ResultsWrapper.displayName = 'ResultsWrapper';

export default ResultsWrapper;
