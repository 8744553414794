import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CreditCardDetail = styled.div`
  display: inline-block;
  list-style: none;
  line-height: 1.5em;
`;

CreditCardDetail.displayName = 'CreditCardDetail';

const CreditCardDetails = (props) => {
  const {
    cardType,
    cardNumber,
    expirationMonth,
    expirationYear,
  } = props;
  return (
    <CreditCardDetail>
      <table>
        <tbody>
          <tr>
            <td>{cardType}: &emsp;</td>
            <td>{cardNumber}</td>
          </tr>
          <tr>
            <td>Expiration: &emsp;</td>
            <td>{`${expirationMonth}/${expirationYear}`}</td>
          </tr>
        </tbody>
      </table>
    </CreditCardDetail>
  );
};

CreditCardDetails.propTypes = {
  cardType: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  expirationMonth: PropTypes.string.isRequired,
  expirationYear: PropTypes.number.isRequired,
};

export default CreditCardDetails;
