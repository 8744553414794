import styled from 'styled-components';
import { colors } from '@style-guide/config';

const FieldValidationError = styled.div`
  color: ${colors.red};
  display: block;
  font-weight: 500;
  margin: 5px 0 0 5px;
`;

FieldValidationError.displayName = 'FieldValidationError';

export default FieldValidationError;
