import styled from 'styled-components';
import { colors } from '@style-guide/config';

const PrimaryLocation = styled.div`
  display: flex;
  line-height: 1.4em;
  margin: 10px 0;

  svg {
    color: ${colors.red};
    margin-right: 5px;
  }

  > div:first-child {
    flex-shrink: 0;
    font-weight: 600;
    margin-right: 10px;
  }
`;

PrimaryLocation.displayName = 'PrimaryLocation';

export default PrimaryLocation;
