import styled from 'styled-components';

const MainColumnContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

MainColumnContainer.displayName = 'MainColumnContainer';

export default MainColumnContainer;
