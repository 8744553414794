import styled from 'styled-components';

const EBarOtherAnnouncementResultTr = styled.tr`
  color: ${props => (props.isActiveDateNull ? 'red' : '')}; 
  background-color: ${props => (props.isActiveDateNull ? 'aliceblue' : '#EDEDED')};
`;

EBarOtherAnnouncementResultTr.displayName = 'EBarOtherAnnouncementResultTr';

export default EBarOtherAnnouncementResultTr;
