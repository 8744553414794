import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { colors, typography } from '@style-guide/config';

import Divider from '@components/Divider';

const TagLink = styled.a`
  color: ${colors.red};
  text-decoration: underline;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
`;

TagLink.displayName = 'TagLink';

const TagsContainer = styled.div`
  font-size: ${rem('10px', typography.baseFontSize)};
  margin-bottom: 30px;

  h3 {
    font-size: ${rem('18px', typography.baseFontSize)};
  }

  li {
    display: inline;
    margin-right: 20px;
  }
`;

TagsContainer.displayName = 'TagsContainer';

const Tags = ({ tags }) => {
  const tagsWithIDs = tags.map((tag, index) => ({
    id: index,
    ...tag,
  }));

  const tagListItems = tagsWithIDs.map(tag => (
    <li
      key={tag.id}
    >
      <TagLink href="/">{tag.tagName}</TagLink>
    </li>
  ));

  return (
    <TagsContainer>
      <h3>Tags</h3>
      <Divider />
      <ul>
        {tagListItems}
      </ul>
    </TagsContainer>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Tags.displayName = 'Tags';

export default Tags;
