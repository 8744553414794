import styled from 'styled-components';

const BoldPara = styled.p`
  font-weight: bold;
  font-size: 1.5rem;
`;

BoldPara.displayName = 'BoldPara';

const StylishPara = styled.p`
  font-weight: bold;
  font-size: 1rem;
  color: #696969;
  font-family: Georgia, Serif;
`;

StylishPara.displayName = 'StylishPara';

const ButtonTextSpan = styled.span`
  margin-left: 5px;
`;

ButtonTextSpan.displayName = 'ButtonTextSpan';

export {
  BoldPara,
  StylishPara,
  ButtonTextSpan,
};
