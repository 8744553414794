import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { colors, stickyHeader, typography } from '@style-guide/config';
import Close from '@style-guide/mixins/Close';

const ShoppingCartToggle = styled.button`
  display: block;
  position: relative;

  svg {
    font-size: ${rem('18px', typography.baseFontSize)};
    line-height: ${stickyHeader.stickyHeaderHeight};
    margin-right: 5px;
  }

  &.open {
    ${Close({ color: colors.yellow })}

    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > span,
    > svg {
      display: none;
    }
  }
`;

ShoppingCartToggle.displayName = 'ShoppingCartToggle';

export default ShoppingCartToggle;
