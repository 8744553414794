import { urlEncode } from '@services/Url';

const serializeFormData = data => ({
  contentGuidString: data.contentGuidString,
  title: data.title,
  summary: data.summary,
  ctaLabel: data.ctaLabel,
  ctaUrl: data.ctaUrl,
  activeDate: data.activeDate,
  createdDate: data.createdDate,
  createdBy: data.createdBy,
});

const eBarMemberCenterCreate = async (data) => {
  const eBarCreate = await fetch('/EBarMemberCenterCallToActionBlock/CreateEpiMemberCenter', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarCreateJson = eBarCreate.json();

  return eBarCreateJson;
};

const eBarMemberCenterUpdate = async (data) => {
  const eBarUpdate = await fetch('/EBarMemberCenterCallToActionBlock/UpdateEpiMemberCenter', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarUpdateJson = eBarUpdate.json();

  return eBarUpdateJson;
};

const eBarMemberCenterDelete = async (data) => {
  const eBarDelete = await fetch('/EBarMemberCenterCallToActionBlock/DeleteEpiMemberCenter', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      stringToDelete: data,
    }),
  });
  const eBarDeleteJson = eBarDelete.json();

  return eBarDeleteJson;
};


export {
  eBarMemberCenterCreate,
  eBarMemberCenterUpdate,
  eBarMemberCenterDelete,
};
