import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveHonorsInfo } from '@redux/modules/honorsInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import HonorsInfoForm from './components/HonorsInfoForm';

class HonorsInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formValues) {
    this.props.dispatch(saveHonorsInfo(formValues));
  }

  render() {
    const {
      loading,
      honorsInfo,
      title,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          title={title}
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <HonorsInfoForm
                initialValues={honorsInfo}
                onSubmit={this.submit}
                loading={loading}
                hasSaveError={!!error}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
        />
      </FormAccordionContainer>
    );
  }
}

HonorsInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  honorsInfo: PropTypes.shape({
    Awards: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
};

HonorsInfoContent.defaultProps = {
  error: '',
  loading: false,
  title: 'Honors Information',
};

const mapStateToProps = ({ honorsInfo }) => ({
  error: honorsInfo.error,
  loading: honorsInfo.loading,
  honorsInfo,
});

HonorsInfoContent.displayName = 'HonorsInfoContent';

export default connect(mapStateToProps)(HonorsInfoContent);
