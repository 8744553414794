import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import removeNonSavedField from '@views/EditDirectory/services/removeNonSavedField';

import Admission from './components/Admission';

const AdmissionsItem = styled.li`
  padding: 15px 0;

  &:not(:first-child) {
    border-top: 1px solid ${colors.gray};
  }
`;

AdmissionsItem.displayName = 'AdmissionsItem';

const AddAdmissionContainer = styled.li`
  button {
    margin: 0;
  }
`;

AddAdmissionContainer.displayName = 'AddAdmissionContainer';

const createNewAdmission = () => ({
  DateOfAdmission: '',
  Id: 0,
  IsDeleted: false,
  State: '',
  SupremeCourtNumber: '',
  IsSaved: false,
});

const Admissions = ({
  fields = [],
  states,
}) => {
  const renderAward = (admission, index) => {
    if (!fields.get(index).Deleted) {
      return (
        <AdmissionsItem
          key={admission}
        >
          <Admission
            admission={admission}
            states={states}
            index={index}
            onRemove={() => removeNonSavedField(fields, index)}
          />
        </AdmissionsItem>
      );
    }

    return undefined;
  };

  return (
    <ul>
      {fields.map(renderAward)}
      <AddAdmissionContainer>
        <Button
          type="button"
          onClick={() => fields.push(createNewAdmission())}
        >
          ADD ADMISSION
        </Button>
      </AddAdmissionContainer>
    </ul>
  );
};

Admissions.propTypes = {
  fields: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  states: PropTypes.arrayOf(Object).isRequired,
};

Admissions.displayName = 'Admissions';

export default Admissions;
