import styled from 'styled-components';

import { colors } from '@style-guide/config';

const CardWrapper = styled.div`
  background-color: ${colors.white};
  margin-top: 15px;
`;

CardWrapper.displayName = 'CardWrapper';

export default CardWrapper;
