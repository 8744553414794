import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import rem from 'polished/lib/helpers/rem';
import {
  colors,
  typography,
} from '@style-guide/config';

const AlertContainer = styled.div`
  align-items: center;
  background-color: ${colors.red};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  line-height: 1.2em;
  padding: 15px 20px;
  position: relative;
  text-align: center;
  width: 100%;

  span:first-child {
    font-size: ${rem('18px', typography.baseFontSize)};
    font-weight: 700;
  }

  span:last-child {
    font-size: ${rem('16px', typography.baseFontSize)};
    font-weight: 400;
  }
`;

AlertContainer.displayName = 'AlertContainer';

const Alert = ({
  title,
  message,
}) => (
  <AlertContainer>
    <div>
      <span>{title && (`${title} - `)}</span>
      <span>{message}</span>
    </div>
  </AlertContainer>
);

Alert.displayName = 'Alert';

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

Alert.defaultProps = {
  title: '',
  message: '',
};

export default Alert;
