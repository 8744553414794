import styled from 'styled-components';

const ComponentDiv = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px;
  text-align: left;  
`;

ComponentDiv.displayName = 'ComponentDiv';

export default ComponentDiv;
