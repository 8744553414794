import styled from 'styled-components';

const UploadSubtitle = styled.span`
  display: block;
  font-style: italic;
  margin-bottom: 15px;
`;

UploadSubtitle.displayName = 'UploadSubtitle';

export default UploadSubtitle;
