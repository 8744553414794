import styled from 'styled-components';

const EBarSCOAnnouncementResultTr = styled.tr`
  color: ${props => (props.isActiveDateNull ? 'red' : '')}; 
  background-color: ${props => (props.isActiveDateNull ? 'aliceblue' : '#EDEDED')};
`;

EBarSCOAnnouncementResultTr.displayName = 'EBarSCOAnnouncementResultTr';

export default EBarSCOAnnouncementResultTr;
