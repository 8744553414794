import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';

const RegisterContainer = styled.div`
  margin: 10px 0;
  position: relative;

  label {
    display: block;
    font-size: ${rem('14px', typography.baseFontSize)};
    font-weight: 600;
    line-height: 1.4em;
    margin-top: 15px;
  }

  textarea {
    margin: 15px 0;
    width: 100%;
  }

  button {
    margin: 15px auto 0;
  }
`;

RegisterContainer.displayName = 'RegisterContainer';

export default RegisterContainer;
