import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const ContentArea = styled.div`
  width: 100%;
  background-color: ${colors.white};
  padding: 30px 30px 60px 30px;
  position: relative;
  margin-bottom: 60px;

  @media (min-width: ${breakpoints.m}) {
    padding: 30px;
  }

  @media (min-width: ${breakpoints.l}) {
    width: calc(855px - 350px);
  }

  @media (min-width: ${breakpoints.xl}) {
    width: calc(1140px - 350px);
  }
`;

ContentArea.displayName = 'ContentArea';

export default ContentArea;
