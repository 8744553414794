import styled from 'styled-components';

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

SearchWrapper.displayName = 'SearchWrapper';

export default SearchWrapper;
