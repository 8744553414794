import styled from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';

import { colors } from '@style-guide/config';

const Menu = styled.div`
  border-bottom: 1px solid ${transparentize(0.85, colors.white)};
  border-top: 1px solid ${transparentize(0.85, colors.white)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  padding: 15px 0px;
  width: 85%;
`;

Menu.displayName = 'Menu';

export default Menu;
