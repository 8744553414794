import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  colors,
  breakpoints,
  typography,
} from '@style-guide/config';

const ContactLinks = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: ${rem('15px', typography.baseFontSize)};
  justify-content: flex-end;

  a {
    border-left: 1px solid ${colors.darkGray};
    color: ${colors.red};
    cursor: pointer;
    flex: 0 1 auto;
    height: 25px;
    padding: 5px 15px;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: underline;
  }

  a:first-child {
    border: none;

    @media (max-width: ${breakpoints.m}) {
      padding-left: 0px;
    }
  }

  @media (max-width: 430px) {
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    .divider {
      display: none;
    }

    a {
      border: none;
      margin: 0px;
      padding: 0px;
    }
  }
`;

ContactLinks.displayName = 'ContactLinks';

export default ContactLinks;
