import ApiGenerator from '@services/ApiGenerator';

const GET = 'osba/cart/GET';
const GET_RECEIVED = 'osba/cart/GET_RECEIVED';
const GET_ERROR = 'osba/cart/GET_ERROR';
const ADD = 'osba/cart/ADD';
const ADD_EVENT = 'osba/cart/ADD_EVENT';
const ADD_RECEIVED = 'osba/cart/ADD_RECEIVED';
const ADD_ERROR = 'osba/cart/ADD_ERROR';
const REMOVE = 'osba/cart/REMOVE';
const REMOVE_RECEIVED = 'osba/cart/REMOVE_RECEIVED';
const REMOVE_ERROR = 'osba/cart/REMOVE_ERROR';
const VALIDATE = 'osba/cart/VALIDATE';
const VALIDATE_RECEIVED = 'osba/cart/VALIDATE_RECEIVED';
const VALIDATE_ERROR = 'osba/cart/VALIDATE_ERROR';
const ADD_WITH_ADD_ONS = 'osba/cart/ADD_WITH_ADD_ONS';
const ADD_WITH_ADD_ONS_ERROR = 'osba/cart/ADD_WITH_ADD_ONS_ERROR';
const ADD_WITH_ADD_ONS_RECEIVED = 'osba/cart/ADD_WITH_ADD_ONS_RECEIVED';
const ADD_EVENT_WITH_ADD_ONS = 'osba/cart/ADD_EVENT_WITH_ADD_ONS';
const ADD_EVENT_WITH_ADD_ONS_ERROR = 'osba/cart/ADD_EVENT_WITH_ADD_ONS_ERROR';
const ADD_EVENT_WITH_ADD_ONS_RECEIVED = 'osba/cart/ADD_EVENT_WITH_ADD_ONS_RECEIVED';
const ADD_COUPON = 'osba/cart/ADD_COUPON';
const ADD_COUPON_ERROR = 'osba/cart/ADD_COUPON_ERROR';
const ADD_COUPON_RECEIVED = 'osba/cart/ADD_COUPON_RECEIVED';
const REMOVE_COUPON = 'osba/cart/REMOVE_COUPON';
const REMOVE_COUPON_ERROR = 'osba/cart/REMOVE_COUPON_ERROR';
const REMOVE_COUPON_RECEIVED = 'osba/cart/REMOVE_COUPON_RECEIVED';
const APPLY_ARM_DISCOUNT = 'osba/cart/APPLY_ARM_DISCOUNT';
const APPLY_ARM_DISCOUNT_ERROR = 'osba/cart/APPLY_ARM_DISCOUNT_ERROR';
const APPLY_ARM_DISCOUNT_RECEIVED = 'osba/cart/APPLY_ARM_DISCOUNT_RECEIVED';
const REMOVE_ARM_DISCOUNT = 'osba/cart/REMOVE_ARM_DISCOUNT';
const REMOVE_ARM_DISCOUNT_ERROR = 'osba/cart/REMOVE_ARM_DISCOUNT_ERROR';
const REMOVE_ARM_DISCOUNT_RECEIVED = 'osba/cart/REMOVE_ARM_DISCOUNT_RECEIVED';

const initialState = {
  cartTotal: '$0.00',
  cartTotalValue: 0,
  count: 0,
  loading: false,
  lineItems: [],
  error: null,
  showCartPopUp: false,
  EpiOrderId: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET:
    case ADD_COUPON:
    case REMOVE_COUPON:
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
        currentGuid: null,
        EpiOrderId: action.EpiOrderId,
      };
    case REMOVE:
    case ADD:
      return {
        ...state,
        showCartPopUp: true,
      };
    case ADD_EVENT:
    case ADD_WITH_ADD_ONS:
    case ADD_EVENT_WITH_ADD_ONS:
      return {
        ...state,
        loading: true,
        error: null,
        message: null,
        currentGuid: action.contentGuid,
      };
    case ADD_RECEIVED:
    case GET_RECEIVED:
    case REMOVE_RECEIVED:
    case VALIDATE_RECEIVED:
    case ADD_WITH_ADD_ONS_RECEIVED:
    case ADD_EVENT_WITH_ADD_ONS_RECEIVED:
    case ADD_COUPON_RECEIVED:
    case REMOVE_COUPON_RECEIVED:
      return {
        ...state,
        ...action.data,
        loading: false,
        error: null,
      };
    case GET_ERROR:
    case ADD_ERROR:
    case REMOVE_ERROR:
    case VALIDATE_ERROR:
    case ADD_WITH_ADD_ONS_ERROR:
    case ADD_EVENT_WITH_ADD_ONS_ERROR:
    case ADD_COUPON_ERROR:
    case REMOVE_COUPON_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case APPLY_ARM_DISCOUNT:
      return {
        ...state,
        loading: true,
      };
    case APPLY_ARM_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case APPLY_ARM_DISCOUNT_RECEIVED:
      return {
        ...state,
        ...action.data,
        loading: false,
        error: null,
      };
    case REMOVE_ARM_DISCOUNT:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_ARM_DISCOUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case REMOVE_ARM_DISCOUNT_RECEIVED:
      return {
        ...state,
        ...action.data,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export const getCart = EpiOrderId => ({
  type: GET,
  EpiOrderId,
});

export const addToCart = (contentGuid, note, attendanceType, adaList) => ({
  type: ADD,
  contentGuid,
  note,
  attendanceType,
  adaList,
});

export const addEventToCart = (contentGuid, note, attendanceType, adaList) => ({
  type: ADD_EVENT,
  contentGuid,
  note,
  attendanceType,
  adaList,
});

export const addEventToCartWithAddOns = (contentGuid, note, attendanceType, addOnIds, adaList) => ({
  type: ADD_EVENT_WITH_ADD_ONS,
  contentGuid,
  note,
  attendanceType,
  addOnIds,
  adaList,
});

export const addToCartWithAddOns = (contentGuid, note, attendanceType, addOnIds, adaList) => ({
  type: ADD_WITH_ADD_ONS,
  contentGuid,
  note,
  attendanceType,
  addOnIds,
  adaList,
});

export const removeFromCart = contentGuid => ({
  type: REMOVE,
  contentGuid,
});

export const validateCart = () => ({
  type: VALIDATE,
});

export const addCoupon = couponCode => ({
  type: ADD_COUPON,
  couponCode,
});

export const removeCoupon = couponCode => ({
  type: REMOVE_COUPON,
  couponCode,
});

export const applyARMDiscount = autoRenew => ({
  type: APPLY_ARM_DISCOUNT,
  autoRenew,
});

export const removeARMDiscount = autoRenew => ({
  type: REMOVE_ARM_DISCOUNT,
  autoRenew,
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api('get', '/shoppingcart/get', GET);
      break;
    case ADD: {
      const noteStr = action.note ? `&note=${action.note}` : '';
      const attendanceStr = action.attendanceType ? `&attendanceType=${action.attendanceType}` : '';
      api(
        'put',
        `/shoppingcart/addtocart/?contentId=${action.contentGuid}${noteStr}${attendanceStr}&adaList=${action.adaList}`,
        ADD,
      );
      break;
    }
    case ADD_WITH_ADD_ONS: {
      const addOnsStr = action.addOnIds.reduce((accumulator, currentVal) => `${accumulator}&addOnIds=${currentVal}`, '');
      const attendanceStr = action.attendanceType ? `&attendanceType=${action.attendanceType}` : '';
      const note = action.note ? `&note=${action.note}` : '';
      api(
        'put',
        `/shoppingcart/addtocartwithaddons/?contentId=${action.contentGuid}${note}${addOnsStr}${attendanceStr}&adaList=${action.adaList}`,
        ADD_WITH_ADD_ONS,
      );
      break;
    }
    case ADD_EVENT: {
      const noteStr = action.note ? `&note=${action.note}` : '';
      const attendanceStr = action.attendanceType ? `&attendanceType=${action.attendanceType}` : '';

      api(
        'put',
        `/shoppingcart/addtocart/?contentId=${action.contentGuid}${noteStr}${attendanceStr}&adaList=${action.adaList}`,
        ADD,
      );
      break;
    }
    case ADD_EVENT_WITH_ADD_ONS: {
      const noteStr = action.note ? `&note=${action.note}` : '';
      const attendanceStr = action.attendanceType ? `&attendanceType=${action.attendanceType}` : '';
      const addOnsStr = action.addOnIds.reduce((accumulator, currentVal) => `${accumulator}&addOnIds=${currentVal}`, '');


      api(
        'put',
        `/shoppingcart/addtocartwithaddons/?contentId=${action.contentGuid}${noteStr}${attendanceStr}${addOnsStr}&adaList=${action.adaList}`,
        ADD,
      );
      break;
    }
    case REMOVE:
      api('delete', `/shoppingcart/removefromcart/${action.contentGuid}`, REMOVE);
      break;
    case VALIDATE:
      api('get', '/shoppingcart/validate', VALIDATE);
      break;
    case ADD_COUPON:
      api('put', `/shoppingcart/addcouponcode/?couponCode=${action.couponCode}`, ADD_COUPON);
      break;
    case REMOVE_COUPON:
      api('delete', `/shoppingcart/removecouponcode/?couponCode=${action.couponCode}`, REMOVE_COUPON);
      break;
    case APPLY_ARM_DISCOUNT:
      api('get', `/shoppingcart/ApplyRemoveARMDiscount/?autoRenew=${action.autoRenew}`, APPLY_ARM_DISCOUNT);
      break;
    case REMOVE_ARM_DISCOUNT:
      api('get', `/shoppingcart/ApplyRemoveARMDiscount/?autoRenew=${action.autoRenew}`, REMOVE_ARM_DISCOUNT);
      break;
    default:
      break;
  }
};

export default reducer;
