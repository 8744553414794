import styled from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';
import { colors, stickyHeader } from '@style-guide/config';

const ShoppingCartContent = styled.section`
  background-color: ${transparentize(0.2, colors.darkBlue)};
  border-top: 2px solid ${colors.white};
  display: none;
  height: calc(100vh - ${stickyHeader.stickyHeaderHeight});
  overflow: scroll;
  width: 100%;

  &.open {
    display: inline-block;
    position: absolute;
    left: 0;
  }
`;

ShoppingCartContent.displayName = 'ShoppingCartContent';

export default ShoppingCartContent;
