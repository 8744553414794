import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { hydrateExperienceInfo } from '@redux/modules/experienceInfo';
import Store from '@redux';

import ExperienceInfoContent from './components/ExperienceInfoContent';

class ExperienceInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(hydrateExperienceInfo(this.props.employmentHistory));
  }

  render() {
    return (
      <Provider store={this.store}>
        <ExperienceInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

ExperienceInfo.propTypes = {
  employmentHistory: PropTypes.arrayOf(PropTypes.shape({
    employer: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    isDeleted: PropTypes.bool,
  })),
};

ExperienceInfo.defaultProps = {
  employmentHistory: [],
};

ExperienceInfo.displayName = 'ExperienceInfo';

export default ExperienceInfo;
