import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const ResultsHeader = styled.div`
  border-bottom: ${colors.gray} solid 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;

  @media (min-width: ${breakpoints.m}) {
    flex-direction: row;
  }
`;

ResultsHeader.displayName = 'ResultsHeader';

export default ResultsHeader;
