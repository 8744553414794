import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from '@style-guide/components/LinkButton';

import CartActionsContainer from './components/CartActionsContainer';
import ViewCartButton from './components/ViewCartButton';
import ViewCartLink from './components/ViewCartLink';

const CartActions = ({
  checkoutDisabled = false,
  checkoutLink,
  shoppingCartLink,
}) => (
  <CartActionsContainer>
    <ViewCartLink
      href={shoppingCartLink}
    >
      View Cart
    </ViewCartLink>
    <ViewCartButton
      href={shoppingCartLink}
      id="cartViewButton"
    >
      View Cart
    </ViewCartButton>
    {!checkoutDisabled && (
    <LinkButton
      disabled={checkoutDisabled}
      href={checkoutLink}
      id="cartCheckoutButton"
    >
      Proceed to Checkout
    </LinkButton>
    )}
    {checkoutDisabled && (
    <LinkButton
      disabled={checkoutDisabled}
      id="cartCheckoutButton"
    >
      Proceed to Checkout
    </LinkButton>
    )}
  </CartActionsContainer>
);

CartActions.propTypes = {
  checkoutDisabled: PropTypes.bool,
  checkoutLink: PropTypes.string.isRequired,
  shoppingCartLink: PropTypes.string.isRequired,
};

CartActions.displayName = 'CartActions';

export default CartActions;
