import styled from 'styled-components';

const EBarTopicsDiv = styled.div`
  display: block;
  text-align: left;
  padding: 14px 28px;
`;

EBarTopicsDiv.displayName = 'EBarTopicsDiv';

export default EBarTopicsDiv;
