const phoneFormatter = (number) => {
  if (!number) {
    return '';
  }
  // NNN-NNN-NNNN
  const splitter = /.{1,3}/g;
  return number.substring(0, 7).match(splitter).join('-') + number.substring(7);
};

/* will add more formatters down the line, prefer not to change import & export syntax */
/* eslint-disable-next-line import/prefer-default-export */
export { phoneFormatter };
