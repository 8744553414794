import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import trimStart from 'lodash/trimStart';
import rem from 'polished/lib/helpers/rem';
import { Caret } from '@style-guide/mixins';
import { typography, breakpoints, colors } from '@style-guide/config';

const AccordionTitle = styled.button`
  ${Caret()}

  align-items: center;
  border-bottom: 2px solid transparent;
  border-top: 2px solid ${colors.gray};
  display: flex;
  font-size: ${rem('16px', typography.baseFontSize)};
  font-weight: 700;
  height: ${rem('50px', typography.baseFontSize)};
  justify-content: space-between;
  position: relative;
  padding: 0 30px 0 20px;
  width: 100%;

  &:not(.open) {
    transition: 100ms border 400ms;
  }

  &.open {
    border-bottom: 2px solid ${colors.gray};
  }

  @media (min-width: ${breakpoints.l}) {
    display: none;
  }
`;

AccordionTitle.displayName = 'AccordionTitle';

const FooterAccordionContainer = styled.div`
  text-align: center;

  a {
    color: ${colors.black};

    &:hover {
      color: ${colors.darkRed};
    }
  }

  li {
    margin: 10px 0;
  }

  &:first-child ${/* sc-selector */AccordionTitle} {
    border-top: none;
  }

  @media (min-width: ${breakpoints.l}) {
    text-align: left;
    padding: 0 5px;
  }
`;

FooterAccordionContainer.displayName = 'FooterAccordionContainer';

const TitleLink = styled.a`
  display: none;
  font-weight: 700;
  position: relative;

  @media (min-width: ${breakpoints.l}) {
    display: block;
  }
`;

TitleLink.displayName = 'TitleLink';

const AccordionContent = styled.section`
  @media (max-width: ${breakpoints.l}) {
    max-height: 0;
    overflow: hidden;
    padding: 0 25px 0 45px;
    transition: 500ms max-height;

    &.open {
      max-height: 50vh;
    }

    li {
      margin: 0;

      &:not(:last-child) {
        border-bottom: 2px solid ${colors.gray};
      }

      > a {
        align-items: center;
        display: flex;
        height: 50px;
      }
    }
  }
`;

AccordionContent.displayName = 'AccordionContent';

const OverViewLink = styled.li`
  @media (min-width: ${breakpoints.l}) {
    display: none;
  }
`;

OverViewLink.displayName = 'OverViewLink';

class FooterAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleOpenState = this.toggleOpenState.bind(this);
  }

  toggleOpenState() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const {
      text,
      title,
      href,
      target,
    } = this.props.linkGroup;
    const accordionLinks = this.props.linkGroup.children;
    const linksWithIDs = accordionLinks.map((link, index) => ({
      id: index,
      ...link,
    }));

    const accordionItems = linksWithIDs.map(link => (
      <li key={link.id}>
        <a
          href={trimStart(link.href, '~')}
          title={link.title}
          target={link.target}
        >
          {link.text}
        </a>
      </li>
    ));

    return (
      <FooterAccordionContainer>
        <TitleLink
          href={href}
          title={title}
          target={target}
          id={`${this.props.groupName}TitleLink`}
        >
          {text}
        </TitleLink>
        <AccordionTitle
          className={this.state.isOpen ? 'open' : ''}
          aria-expanded={this.state.isOpen}
          onClick={this.toggleOpenState}
          id={`${this.props.groupName}Toggle`}
        >
          {text}
        </AccordionTitle>
        <AccordionContent
          className={this.state.isOpen ? 'open' : ''}
          id={`${this.props.groupName}Content`}
        >
          <ul>
            <OverViewLink>
              <a
                href={href}
                title={title}
                target={target}
              >
                {text}
              </a>
            </OverViewLink>
            {accordionItems}
          </ul>
        </AccordionContent>
      </FooterAccordionContainer>
    );
  }
}

FooterAccordion.propTypes = {
  linkGroup: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    children: PropTypes.array,
  }).isRequired,
  groupName: PropTypes.string.isRequired,
};

FooterAccordion.displayName = 'FooterAccordion';

export default FooterAccordion;
