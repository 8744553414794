import styled from 'styled-components';

const NavigationDiv = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  padding: 8px;

  @media (min-width: 768px) {
    width: 100%;
  }
`;

NavigationDiv.displayName = 'NavigationDiv';

export default NavigationDiv;
