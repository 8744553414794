import concat from 'lodash/concat';
import isMatch from 'lodash/isMatch';
import reject from 'lodash/reject';

import ApiGenerator from '@services/ApiGenerator';
import encodeSearchFilters from '@services/EncodeSearchFilters';

const SEARCH = 'osba/cleSearch/SEARCH';
const SEARCH_LOAD_MORE = 'osba/cleSearch/SEARCH_LOAD_MORE';
const SEARCH_ERROR = 'osba/cleSearch/SEARCH_ERROR';
const SEARCH_RECEIVED = 'osba/cleSearch/SEARCH_RECEIVED';
const SEARCH_LOAD_MORE_ERROR = 'osba/cleSearch/SEARCH_LOAD_MORE_ERROR';
const SEARCH_LOAD_MORE_RECEIVED = 'osba/cleSearch/SEARCH_LOAD_MORE_RECEIVED';
const FILTERS_UPDATE = 'osba/cleSearch/FILTERS_UPDATE';
const FILTERS_RESTORE = 'osba/cleSearch/FILTERS_RESTORE';
const FILTERS_CLEAR = 'osba/cleSearch/FILTERS_CLEAR';
const FILTERS_APPEND_OR_REMOVE = 'osba/cleSearch/FILTERS_APPEND_OR_REMOVE';
const SORT_UPDATE = 'osba/cleSearch/SORT_UPDATE';

const initialState = {
  count: 0,
  error: null,
  featuredCle: [],
  loading: false,
  results: [],
  totalCount: 0,
  sort: {
    criteria: 'relevancy',
    pageNumber: 1,
    pageLength: 10,
  },
  filters: {
    keyword: '',
    cleFormat: null,
    dateRange: {
      end: null,
      start: null,
    },
    locations: [],
    practiceAreas: [],
    creditHours: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH:
    case SEARCH_LOAD_MORE:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_RECEIVED:
      return {
        ...state,
        ...action.data,
        loading: false,
        error: null,
      };
    case SEARCH_LOAD_MORE_RECEIVED:
      return {
        ...state,
        ...action.data,
        loading: false,
        results: concat(state.results, action.data.results),
        error: null,
      };
    case SEARCH_LOAD_MORE_ERROR:
    case SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FILTERS_UPDATE:
    case FILTERS_APPEND_OR_REMOVE:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.field]: action.value,
        },
        sort: {
          ...initialState.sort,
          criteria: state.sort.criteria,
        },
      };
    case FILTERS_RESTORE:
      return {
        ...state,
        filters: {
          ...initialState.filters,
          ...action.filters,
        },
      };
    case FILTERS_CLEAR:
      return {
        ...state,
        filters: {
          keyword: '',
          cleFormat: null,
          dateRange: {
            end: null,
            start: null,
          },
          locations: [],
          practiceAreas: [],
          creditHours: [],
        },
        sort: {
          ...initialState.sort,
          criteria: state.sort.criteria,
        },
      };
    case SORT_UPDATE:
      return {
        ...state,
        sort: {
          ...state.sort,
          [action.field]: action.value,
        },
      };
    default:
      return state;
  }
};

export const search = (sortFilters, searchFilters) => ({
  type: SEARCH,
  sortFilters,
  searchFilters,
});

export const searchLoadMore = (sortFilters, searchFilters) => ({
  type: SEARCH_LOAD_MORE,
  sortFilters,
  searchFilters,
});

export const filtersUpdate = (field, value) => ({
  type: FILTERS_UPDATE,
  field,
  value,
});

export const filtersRestore = filters => ({
  type: FILTERS_RESTORE,
  filters,
});

export const filtersClear = () => ({
  type: FILTERS_CLEAR,
});

export const filtersAppendOrRemove = (field, value, currentVals) => {
  const newVal = currentVals.filter(currentVal => isMatch(value, currentVal)).length ?
    reject(currentVals, currentVal => isMatch(value, currentVal))
    :
    [...currentVals, value];

  return ({
    type: FILTERS_APPEND_OR_REMOVE,
    field,
    value: newVal,
  });
};

export const sortUpdate = (field, value) => ({
  type: SORT_UPDATE,
  field,
  value,
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);
  const encodedFilters = action.searchFilters ? encodeSearchFilters(action.searchFilters) : null;

  next(action);

  switch (action.type) {
    case SEARCH:
      api(
        'get',
        `/meetinginstancedata/search?sortData=${JSON.stringify(action.sortFilters)}&searchData=${JSON.stringify(encodedFilters)}`,
        SEARCH,
      );
      break;
    case SEARCH_LOAD_MORE:
      api(
        'get',
        `/meetinginstancedata/search?sortData=${JSON.stringify(action.sortFilters)}&searchData=${JSON.stringify(encodedFilters)}`,
        SEARCH_LOAD_MORE,
      );
      break;
    default:
      break;
  }
};

export default reducer;
