import styled from 'styled-components';

const ExperienceContainer = styled.li`
  button {
    margin: 0px;
  }
`;

ExperienceContainer.displayName = 'ExperienceContainer';

export default ExperienceContainer;
