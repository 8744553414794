import styled from 'styled-components';

const ResourceInfo = styled.div`
  align-items: center;
  display: flex;
  margin-top: 5px;
`;

ResourceInfo.displayName = 'ResourceInfo';

export default ResourceInfo;
