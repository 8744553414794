import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Caret from '@style-guide/mixins/Caret';

import { colors } from '@style-guide/config';

const caretSettings = {
  color: colors.white,
};

const Icon = styled.span`
  ${Caret(caretSettings)};

  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  &::after {
    position: absolute;
    top: -7px;
  }

  &.open::after {
    top: -10px;
  }
`;

Icon.displayName = 'Icon';

const Arrow = ({ isOpened }) => (
  <Icon
    className={isOpened ? 'open' : ''}
  />
);

Arrow.propTypes = {
  isOpened: PropTypes.bool.isRequired,
};

Arrow.displayName = 'Arrow';

export default Arrow;
