import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '@style-guide/config';

const ImageWrapper = styled.div`
  img {
    border-bottom: 14px solid ${colors.white};
    border-right: 14px solid ${colors.white};
    max-width: 145px;
    max-height: 145px;
  }
`;

ImageWrapper.displayName = 'ProfileImageWrapper';

const ProfileImage = props => (
  <ImageWrapper>
    <img
      alt={props.alt}
      src={!props.src ? '/Static/img/osba-default-profile.gif' : props.src}
    />
  </ImageWrapper>
);

ProfileImage.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
};

ProfileImage.defaultProps = {
  alt: '',
  src: '/Static/img/osba-default-profile.gif',
};

ProfileImage.displayName = 'ProfileImage';

export default ProfileImage;
