import styled from 'styled-components';

import { colors } from '@style-guide/config';

const Wrapper = styled.div`
  background-color: ${colors.darkBlue};
  width: 100%;
  margin-bottom: 30px;
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
