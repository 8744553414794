import styled from 'styled-components';

const ButtonSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin: 0;
  }

  button {
    margin: 0;
    align-self: flex-start;
  }
`;

ButtonSectionWrapper.displayName = 'ButtonSectionWrapper';

export default ButtonSectionWrapper;
