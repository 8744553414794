import styled from 'styled-components';

const ErrorSpan = styled.span`
  color: red;
  margin-top: 30px;
  margin-left: 10px;
`;

ErrorSpan.displayName = 'ErrorSpan';

export default ErrorSpan;
