import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';

import ContactLinks from './components/ContactLinks';
import EmailLink from './components/EmailLink';
import FacebookLink from './components/FacebookLink';
import InstagramLink from './components/InstagramLink';
import LinkedInLink from './components/LinkedInLink';
import SocialLinks from './components/SocialLinks';
import TwitterLink from './components/TwitterLink';
import VCardButton from './components/VCardButton';

const HeaderContactInfo = ({
  blogURL,
  email,
  facebookURL,
  instagramURL,
  linkedInURL,
  twitterURL,
  vCardUrl,
}) => (
  <ContactLinks>
    {email && (
      <EmailLink
        href={`mailto:${email}`}
      >
        <span>Contact Via</span> Email
      </EmailLink>
    )}
    <VCardButton
      aria-label="This link downloads a vCard for this lawyer"
      href={vCardUrl}
    >
      <span>vCard</span>
    </VCardButton>
    {blogURL && (
      <a
        href={blogURL}
      >
        Blog
      </a>
    )}
    {(facebookURL || linkedInURL || twitterURL || instagramURL) && (
      <SocialLinks>
        {facebookURL && (
          <FacebookLink
            href={facebookURL}
          >
            <FontAwesomeIcon
              icon={faFacebookSquare}
            />
          </FacebookLink>
        )}
        {linkedInURL && (
          <LinkedInLink
            href={linkedInURL}
          >
            <FontAwesomeIcon
              aria-hidden="true"
              icon={faLinkedin}
            />
          </LinkedInLink>
        )}
        {twitterURL && (
          <TwitterLink
            href={twitterURL}
          >
            <FontAwesomeIcon
              aria-hidden="true"
              icon={faTwitterSquare}
            />
          </TwitterLink>
        )}
        {instagramURL && (
          <InstagramLink
            href={instagramURL}
          >
            <FontAwesomeIcon
              aria-hidden="true"
              icon={faInstagram}
            />
          </InstagramLink>
        )}
      </SocialLinks>
    )}
  </ContactLinks>
);

HeaderContactInfo.propTypes = {
  blogURL: PropTypes.string,
  email: PropTypes.string,
  facebookURL: PropTypes.string,
  instagramURL: PropTypes.string,
  linkedInURL: PropTypes.string,
  twitterURL: PropTypes.string,
  vCardUrl: PropTypes.string.isRequired,
};

HeaderContactInfo.defaultProps = {
  blogURL: '',
  email: '',
  facebookURL: '',
  instagramURL: '',
  linkedInURL: '',
  twitterURL: '',
};

HeaderContactInfo.displayName = 'HeaderContactInfo';

export default HeaderContactInfo;
