import is from 'is_js';

const buildNavigationItems = ({
  overviewContent,
  affiliationsContent,
  educationContent,
  employmentContent,
  honorsContent,
  participationContent,
  linksContent,
}) => {
  // we need to build an array of strings
  // to pass as a prop to the navigation component. Which strings
  // get included depends on if the user has info for their profile
  // in that section. We only show navigation items that the user has
  // info in their profile for.
  const navigationArray = [];

  if (overviewContent && is.not.empty(overviewContent)) {
    navigationArray.push('Overview');
  }

  if ((affiliationsContent.certifications && is.not.empty(affiliationsContent.certifications)) ||
    (affiliationsContent.involvement && is.not.empty(affiliationsContent.involvement))) {
    navigationArray.push('Affiliations');
  }

  if (educationContent.lineItems && is.not.empty(educationContent.lineItems)) {
    navigationArray.push('Education');
  }

  if (employmentContent.employmentHistory && is.not.empty(employmentContent.employmentHistory)) {
    navigationArray.push('Experience');
  }

  if (honorsContent.awards && is.not.empty(honorsContent.awards)) {
    navigationArray.push('Honors');
  }

  if (
    participationContent &&
    participationContent.clesSpokenAt &&
    is.not.empty(participationContent.clesSpokenAt)
  ) {
    navigationArray.push('Participation');
  }

  if (linksContent.links && is.not.empty(linksContent.links)) {
    navigationArray.push('Links');
  }

  return navigationArray;
};

export default buildNavigationItems;
