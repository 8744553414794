import ApiGenerator from '@services/ApiGenerator';

const GET = 'osba/account/Overview_INFO_GET';
const GET_ERROR = 'osba/account/Overview_INFO_GET_ERROR';
const GET_RECEIVED = 'osba/account/Overview_INFO_GET_RECEIVED';
const SAVE = 'osba/account/Overview_INFO_SAVE';
const SAVE_ERROR = 'osba/account/Overview_INFO_SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/Overview_INFO_SAVE_RECEIVED';

const initialState = {
  AcceptingNewClients: false,
  Admissions: [],
  AfterHourCalls: false,
  Biography: '',
  CountiesServed: [],
  CourtsOfAdmission: '',
  HouseCalls: false,
  IsProbono: false,
  LimitedScopeRepresentation: false,
  Mediation: false,
  PracticeAreas: [],
  PriceArrangements: '',
  Tagline: '',
  UrgentCalls: false,
  YearsOfPractice: 0,
  loading: false,
  message: '',
  error: '',
};

const mapResponse = data => ({
  message: data.Message,
  ...data.Model,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SAVE:
      return {
        ...state,
        ...action.data,
        loading: true,
        error: '',
      };
    case GET_RECEIVED:
    case SAVE_RECEIVED:
      return {
        ...state,
        ...mapResponse(action.data),
        loading: false,
      };
    case GET_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getOverviewInfo = () => ({
  type: GET,
});

export const saveOverviewInfo = data => ({
  type: SAVE,
  data,
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api('get', '/OverviewForm/Get', GET);
      break;
    case SAVE:
      api('post', '/OverviewForm/Save', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
