import React from 'react';
import PropTypes from 'prop-types';

import RadioButton from '@style-guide/components/RadioButton';

import {
  reduxInputPropType,
  reduxMetaPropType,
} from '@services/PropTypes';

import FieldValidationError from '@components/FieldValidationError';

const ReduxFormRadioButton = ({
  input,
  label = '',
  meta: {
    error,
  },
  ...restProps
}) => (
  <div>
    <RadioButton
      {...input}
      label={label}
      isChecked={`${input.value}` === `${restProps.value}`}
      {...restProps}
    />
    {error && (
      <FieldValidationError>{error}</FieldValidationError>
    )}
  </div>
);

ReduxFormRadioButton.propTypes = {
  input: reduxInputPropType.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  meta: reduxMetaPropType.isRequired,
};

ReduxFormRadioButton.displayName = 'ReduxFormRadioButton';

export default ReduxFormRadioButton;
