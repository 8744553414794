const renderPracticeAreas = practiceAreas => practiceAreas.map((
  practiceArea,
  index,
  collection,
) => {
  if (index === 0) {
    return `${practiceArea.name}`;
  }

  if (index === collection.length - 1 && collection.length > 2) {
    return `, & ${practiceArea.name}`;
  }

  if (index === collection.length - 1 && collection.length === 2) {
    return ` & ${practiceArea.name}`;
  }

  return `, ${practiceArea.name}`;
});

export default renderPracticeAreas;
