import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import Arrow from '@style-guide/mixins/Arrow';
import {
  breakpoints,
  colors,
  typography,
} from '@style-guide/config';

import Divider from '@components/Divider';

const featureArrowOptions = {
  arrow: 'left',
  position: 'before',
  color: colors.darkRed,
  size: rem('16px', typography.baseFontSize),
};

const articleArrowOptions = {
  arrow: 'right',
  position: 'after',
  color: colors.darkRed,
  size: rem('16px', typography.baseFontSize),
};

const RelatedLink = styled.li`
  line-height: 1.5em;
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.gray};
  }

  a {
    color: ${colors.black};
    font-weight: 600;

    &:hover {
      color: ${colors.darkRed};
    }
  }
`;

RelatedLink.displayName = 'RelatedLink';

const RelatedFeatureLink = styled(RelatedLink)`
  a {
    ${Arrow(featureArrowOptions)}

    &::before {
      margin-right: 10px;
    }
  }

  svg {
    color: ${colors.darkRed};
    font-size: ${rem('14px', typography.baseFontSize)};
    margin-left: 10px;
  }
`;

RelatedFeatureLink.displayName = 'RelatedFeatureLink';

const RelatedArticleLink = styled(RelatedLink)`
  a {
    ${Arrow(articleArrowOptions)}

    &::after {
      margin-left: 10px;
    }
  }
`;

RelatedArticleLink.displayName = 'RelatedArticleLink';

const RelatedArticlesContainer = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  margin: 30px auto 70px;
  padding: 0px 10px;


  @media (min-width: ${breakpoints.s}) {
    padding: 0px 15px;
  }

  @media (min-width: ${breakpoints.m}) {
    max-width: 670px;
  }

  @media (min-width: ${breakpoints.l}) {
    margin: 30px 0px 0px;
    max-width: 350px;
    padding: 0px;
  }
`;

RelatedArticlesContainer.displayName = 'RelatedArticlesContainer';

const RelatedArticles = ({
  relatedSpecialFeatures,
  relatedArticles,
}) => {
  const featureList = relatedSpecialFeatures.map(feature => (
    <RelatedFeatureLink
      key={feature.guid}
    >
      <a href={feature.link}>{feature.title}</a>
      <FontAwesomeIcon icon={faStar} />
    </RelatedFeatureLink>
  ));

  const articleList = relatedArticles.map(article => (
    <RelatedArticleLink
      key={article.guid}
    >
      <a href={article.link}>{article.title}</a>
    </RelatedArticleLink>
  ));

  return (
    <RelatedArticlesContainer
      visible={!!articleList.length || !!featureList.length}
    >
      <h2>Related Articles</h2>
      <Divider />
      <ul>
        {featureList}
        {articleList}
      </ul>
    </RelatedArticlesContainer>
  );
};

RelatedArticles.propTypes = {
  relatedSpecialFeatures: PropTypes.arrayOf(PropTypes.object),
  relatedArticles: PropTypes.arrayOf(PropTypes.object),
};

RelatedArticles.defaultProps = {
  relatedSpecialFeatures: [],
  relatedArticles: [],
};

RelatedArticles.displayName = 'RelatedArticles';

export default RelatedArticles;
