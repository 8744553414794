import styled from 'styled-components';

import Button from '@style-guide/components/Button';

const ActionButton = styled(Button)`
  align-self: center;
  margin-top: 50px;
`;

ActionButton.displayName = 'ActionButton';

export default ActionButton;
