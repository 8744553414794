import styled from 'styled-components';

import TabButton from './TabButton';

const TabButtonWrapper = styled.div`
  ${TabButton} {
    width: 100%;
  }
`;

TabButtonWrapper.displayName = 'TabButtonWrapper';

export default TabButtonWrapper;
