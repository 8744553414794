import ApiGenerator from '@services/ApiGenerator';

const GET = 'osba/account/LAW_SCHOOL_INFO_GET';
const GET_ERROR = 'osba/account/LAW_SCHOOL_INFO_GET_ERROR';
const GET_RECEIVED = 'osba/account/LAW_SCHOOL_INFO_GET_RECEIVED';
const SAVE = 'osba/account/LAW_SCHOOL_INFO_SAVE';
const SAVE_ERROR = 'osba/account/LAW_SCHOOL_INFO_SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/LAW_SCHOOL_INFO_SAVE_RECEIVED';

const initialState = {
  LawSchoolName: '',
  GraduationYear: new Date().getFullYear(),
  loading: false,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SAVE:
      return {
        ...state,
        ...action.data,
        loading: true,
        error: '',
      };
    case GET_RECEIVED:
    case SAVE_RECEIVED:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case GET_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getLawSchoolInfo = () => ({
  type: GET,
});

export const saveLawSchoolInfo = data => ({
  type: SAVE,
  data: {
    GraduationYear: data.GraduationYear,
    LawSchoolName: data.LawSchoolName,
  },
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api('get', '/LawSchoolInformation/GetLawSchoolInformation', GET);
      break;
    case SAVE:
      api('post', '/LawSchoolInformation/SaveLawSchoolInformation', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
