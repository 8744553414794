import styled from 'styled-components';

import { Link } from '@style-guide/mixins';

const ItemLink = styled.a`
  ${Link({
    type: 'red',
  })};

  margin-bottom: 4px;
  display: block;
`;

ItemLink.displayName = 'ItemLink';

export default ItemLink;
