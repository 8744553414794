import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, colors, typography } from '@style-guide/config';

const AdditionalInstanceContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${colors.lightGray};
  }

  &:first-of-type {
    padding-top: 5px;
  }

  span {
    align-items: center;
    display: flex;
    font-size: ${rem('14px', typography.baseFontSize)};
    line-height: 1.4em;
    margin-right: 15px;
  }

  @media (min-width: ${breakpoints.l}) {
    span {
      font-size: ${rem('16px', typography.baseFontSize)};
    }
  }
`;

AdditionalInstanceContainer.displayName = 'AdditionalInstanceContainer';

export default AdditionalInstanceContainer;
