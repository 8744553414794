import styled from 'styled-components';

const DetailsItem = styled.div`
  display: flex;
  flex-direction: column;

  .react-datepicker-popper {
    z-index: 3;
  }
`;

DetailsItem.displayName = 'DetailsItem';

export default DetailsItem;
