import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import Divider from '@components/Divider';
import Title from './components/Title';
import Case from './components/Case/Case';
import CaseWithPracticeArea from './components/CaseWithPracticeAreaDiv';

const getPracticeAreaHtmlId = (practicename) => {
  const htmlId1 = practicename.replaceAll(' ', '');
  const htmlId2 = htmlId1.replaceAll('/', '');
  const htmlId3 = htmlId2.replaceAll('\'', '');
  const htmlId = htmlId3.replaceAll('\\', '');
  return htmlId;
};

class CasesWithPracticeArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: this.props.caseWithPracticeArea.viewCases,
    };

    this.convertReplaceString = this.convertReplaceString.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      view: nextProps.caseWithPracticeArea.viewCases,
    });
  }

  /* eslint-disable */
  convertReplaceString(summaryString) {
    if (!summaryString || !summaryString.summary) {
      return summaryString;
    }
    summaryString.summary = summaryString.summary.replaceAll('&lt;', '<');
    summaryString.summary = summaryString.summary.replaceAll('&gt;', '>');

    return summaryString;
  };
  /* eslint-enable */

  render() {
    const {
      view,
    } = this.state;

    const {
      caseWithPracticeArea,
      showDivider,
    } = this.props;

    return (
      <CaseWithPracticeArea
        id={getPracticeAreaHtmlId(caseWithPracticeArea.nameOfPractice)}
      >
        {view && (
          <div>
            <Title>{caseWithPracticeArea.nameOfPractice}</Title>
              {is.not.null(caseWithPracticeArea.cases) && caseWithPracticeArea.cases.length > 0
                && caseWithPracticeArea.cases.map((d, index) => (
                  <Case
                    key={d.contentGuidString}
                    caseData={this.convertReplaceString(d)}
                    noMargin={!showDivider && index === caseWithPracticeArea.cases.length - 1}
                  />
                ))}
            {showDivider && (
              <Divider
                margin="0px 0px 10px"
                hasDecorator={false}
              />
            )}
          </div>
        )}
      </CaseWithPracticeArea>
    );
  }
}

CasesWithPracticeArea.propTypes = {
  caseWithPracticeArea: PropTypes.shape({
    nameOfPractice: PropTypes.string,
    practiceAreaId: PropTypes.number,
    casesCount: PropTypes.number,
    viewCases: PropTypes.bool,
    cases: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      summary: PropTypes.string,
      caseName: PropTypes.string,
      webCitation: PropTypes.string,
      casemakerLink: PropTypes.string,
      pDFLink: PropTypes.string,
      practiceId: PropTypes.number,
      courtId: PropTypes.number,
      contentGuidString: PropTypes.string,
      isBookmarked: PropTypes.bool,
    })).isRequired,
  }).isRequired,
  showDivider: PropTypes.bool.isRequired,
};

CasesWithPracticeArea.defaultProps = {
};

CasesWithPracticeArea.displayName = 'CasesWithPracticeArea';

export default CasesWithPracticeArea;
