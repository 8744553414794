import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { required } from '@services/FormValidators';

import FormFieldGroup from '@components/FormFieldGroup';
import FormFieldWrapper from '@components/FormFieldWrapper';
import FormFieldSubGroup from '@components/FormFieldSubGroup';
import FormHeader from '@components/FormHeader';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormTextAreaInput from '@components/ReduxFormTextAreaInput';
import ReduxFormDatePicker from '@components/ReduxFormDatePicker';
import Button from '@style-guide/components/Button';
import moment from 'moment';
import FormButtonWrapper from './components/FormButtonWrapper';

const CaseForm = ({
  handleSubmit,
  pristine,
  onClickCloseCancel,
  edit,
  onSubmit,
  invalid,
}) => (
  <form
    id="caseForm"
    onSubmit={handleSubmit(onSubmit)}
  >
    <FormHeader>{edit ? 'Update News' : 'Create News'}</FormHeader>
    <FormFieldWrapper>
      <span><b>*Required</b></span>
    </FormFieldWrapper>
    <FormFieldGroup>
      <FormFieldWrapper wide>
        <Field
          name="title"
          id="title"
          type="text"
          label="News Title*"
          component={ReduxFormInput}
          validate={required}
          autoFocus
        />
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <Field
          name="summary"
          id="summary"
          label="News Summary*"
          props={{
            rows: '5',
            maxLength: '2000',
          }}
          component={ReduxFormTextAreaInput}
          validate={required}
        />
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <Field
          name="url"
          id="url"
          type="text"
          label="URL"
          component={ReduxFormInput}
        />
      </FormFieldWrapper>
      <FormFieldWrapper wide>
        <FormFieldSubGroup>
          <Field
            component={ReduxFormDatePicker}
            id="createdDate"
            label="Active Date*"
            maxDate={moment()}
            name="createdDate"
            scrollableYearDropdown
            showYearDropdown
            yearDropdownItemNumber={50}
            validate={required}
          />
        </FormFieldSubGroup>
      </FormFieldWrapper>
    </FormFieldGroup>
    <FormButtonWrapper>
      <Button
        type="submit"
        disabled={pristine || invalid}
      >
        {edit ? 'Update' : 'Create'}
      </Button>
      <Button
        onClick={() => onClickCloseCancel()}
      >
          Cancel
      </Button>
    </FormButtonWrapper>
  </form>
);

CaseForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    url: PropTypes.string,
    createdDate: PropTypes.string,
  }),
  onClickCloseCancel: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
};

CaseForm.defaultProps = {
  initialValues: {
    title: '',
    summary: '',
    url: '',
    createdDate: '',
  },
};

export default reduxForm({
  form: 'caseForm',
  enableReinitialize: true,
})(CaseForm);
