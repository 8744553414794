import React from 'react';
import is from 'is_js';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TabTitle from '../TabTitle';

import Wrapper from './components/Wrapper';
import CourseMaterialsItem from './components/CourseMaterialsItem';
import CourseMaterialsList from './components/CourseMaterialsList';
import CourseMaterialsItemWrapper from './components/CourseMaterialsItemWrapper';
import CourseMaterialsLink from './components/CourseMaterialsLink';

const HrWrapper = styled.hr`
  border: 0.75px solid #d1d3d3;
`;

HrWrapper.displayName = 'HrWrapper';

const CourseMaterialsTab = ({
  courseMaterials = [],
  meetingInstance,
}) => (
  <Wrapper>
    <TabTitle>{meetingInstance.displayName}</TabTitle>
    <CourseMaterialsList>
      {is.not.empty(courseMaterials) && courseMaterials.map((item, index, row) => (
        <CourseMaterialsItemWrapper
          key={item.url}
        >
          <CourseMaterialsItem
            fileName={item.fileName}
            fileType={item.fileType}
            url={item.url}
            sizeFormattedString={item.sizeFormattedString}
          />
          {index !== row.length - 1 && (
            <HrWrapper />
          )}
        </CourseMaterialsItemWrapper>
      ))}
      {((meetingInstance.ctaLabel1 != null && meetingInstance.ctaLabel1 !== '') || (meetingInstance.ctaUrl1 != null && meetingInstance.ctaUrl1 !== '')) && (
        <CourseMaterialsItemWrapper>
          {courseMaterials.length > 0 && (
            <HrWrapper />
          )}
          <CourseMaterialsLink
            ctaLabel={meetingInstance.ctaLabel1}
            ctaUrl={meetingInstance.ctaUrl1}
          />
        </CourseMaterialsItemWrapper>
      )}
      {((meetingInstance.ctaLabel2 != null && meetingInstance.ctaLabel2 !== '') || (meetingInstance.ctaUrl2 != null && meetingInstance.ctaUrl2 !== '')) && (
        <CourseMaterialsItemWrapper>
          {(meetingInstance.ctaLabel1 != null || meetingInstance.ctaUrl1 != null) && (
            <HrWrapper />
          )}
          <CourseMaterialsLink
            ctaLabel={meetingInstance.ctaLabel2}
            ctaUrl={meetingInstance.ctaUrl2}
          />
        </CourseMaterialsItemWrapper>
      )}
      {((meetingInstance.ctaLabel3 != null && meetingInstance.ctaLabel3 !== '') || (meetingInstance.ctaUrl3 != null && meetingInstance.ctaUrl3 !== '')) && (
        <CourseMaterialsItemWrapper>
          {(meetingInstance.ctaLabel1 != null || meetingInstance.ctaUrl1 != null ||
            meetingInstance.ctaLabel2 != null || meetingInstance.ctaUrl3 != null) && (
            <HrWrapper />
          )}
          <CourseMaterialsLink
            ctaLabel={meetingInstance.ctaLabel3}
            ctaUrl={meetingInstance.ctaUrl3}
          />
        </CourseMaterialsItemWrapper>
      )}
    </CourseMaterialsList>
  </Wrapper>
);

CourseMaterialsTab.propTypes = {
  courseMaterials: PropTypes.arrayOf(Object),
  meetingInstance: PropTypes.shape({
    displayName: PropTypes.string,
    ctaLabel1: PropTypes.string,
    ctaUrl1: PropTypes.string,
    ctaLabel2: PropTypes.string,
    ctaUrl2: PropTypes.string,
    ctaLabel3: PropTypes.string,
    ctaUrl3: PropTypes.string,
  }).isRequired,
};

CourseMaterialsTab.displayName = 'CourseMaterialsTab';

export default CourseMaterialsTab;
