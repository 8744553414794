import React from 'react';
import { Provider } from 'react-redux';
import { getCommittees } from '@redux/modules/committees';
import Store from '@redux';

import CommitteesContent from './components/CommitteesContent';

class Committees extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getCommittees());
  }

  render() {
    return (
      <Provider store={this.store}>
        <CommitteesContent
          {...this.props}
        />
      </Provider>
    );
  }
}


Committees.displayName = 'Committees';

export default Committees;
