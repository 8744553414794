import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { colors, typography } from '@style-guide/config';

const SecondaryLinksContainer = styled.ul`
  background-color: ${colors.white};
  padding: 20px 0;

  a {
    color: ${colors.black};
    display: block;
    font-size: ${rem('16px', typography.baseFontSize)};
    font-weight: 700;
    padding: 10px;
    text-align: center;
    width: 100%;
  }
`;

SecondaryLinksContainer.displayName = 'SecondaryLinksContainer';

const SecondaryLinks = ({
  links = [],
  isAuthenticated,
  userName,
}) => {
  links.unshift({
    href: isAuthenticated ? '/Util/logout.aspx' : '/authorize',
    title: isAuthenticated ? `Welcome Back ${userName} (Logout)` : 'Log In / Create an Account',
    text: isAuthenticated ? `Welcome Back ${userName} (Logout)` : 'Log In / Create an Account',
  });

  const linksWithIDs = links.map((link, index) => ({
    id: index,
    ...link,
  }));

  const navLinks = linksWithIDs.map(link => (
    <li key={link.id}>
      <a
        href={link.href}
        target={link.target}
        title={link.title}
      >
        {link.text}
      </a>
    </li>
  ));

  return (
    <SecondaryLinksContainer
      id="mobileHeaderSecondaryLinks"
    >
      {navLinks}
    </SecondaryLinksContainer>
  );
};

SecondaryLinks.propTypes = {
  links: PropTypes.arrayOf(Object),
  isAuthenticated: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
};

SecondaryLinks.displayName = 'SecondaryLinks';

export default SecondaryLinks;
