import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import FocusTrap from 'focus-trap-react';
import { typography, breakpoints, colors } from '@style-guide/config';
import FlyoutOpenContent from './components/FlyoutOpenContent';
import FlyoutClosedContent from './components/FlyoutClosedContent';

const FlyoutContainer = styled.div`
  background-color: ${colors.red};
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.35);
  color: ${colors.white};
  display: none;
  font-size: ${rem('14px', typography.baseFontSize)};
  font-weight: 700;

  @media(min-width: ${breakpoints.l}) {
    display: inline-block;
    margin-top: 38px;
    position: fixed;
    right: 0;
    z-index: 3;
  }

  a {
    color: ${colors.white};
  }

  &.open {
    max-width: 350px;
    padding: 20px;
  }
`;

FlyoutContainer.displayName = 'FlyoutContainer';

class Flyout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleEscape = this.handleEscape.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.flyoutRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('keydown', this.handleEscape, true);
  }

  componentDidUpdate() {
    if (this.state.isOpen) {
      document.addEventListener('keydown', this.handleEscape, true);
    } else {
      document.removeEventListener('keydown', this.handleEscape, true);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleEscape, true);
  }

  handleClickOutside(event) {
    if (this.flyoutRef != null &&
        this.flyoutRef.current != null &&
        !this.flyoutRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  toggleOpenState() {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  handleKeyPress({ charCode }) {
    if (charCode === 13) {
      this.toggleOpenState();
    }
  }

  handleEscape({ keyCode, key }) {
    if (keyCode === 27 || key === 'Escape') {
      this.setState({ isOpen: false });
    }
  }

  renderContent() {
    if (!this.state.isOpen) {
      return (
        <FlyoutClosedContent
          heading={this.props.headingClosed}
          actions={this.props.actions}
          toggleOpenState={this.toggleOpenState}
          onKeyPress={this.handleKeyPress}
        />
      );
    }

    return (
      <FocusTrap>
        <FlyoutOpenContent
          heading={this.props.headingOpen}
          actions={this.props.actions}
          description={this.props.description}
          undoSelection={this.props.undoSelection}
          toggleOpenState={this.toggleOpenState}
          onKeyPress={this.handleKeyPress}
        />
      </FocusTrap>
    );
  }

  render() {
    const flyoutClassNames = classNames({
      closed: !this.state.isOpen,
      open: this.state.isOpen,
    });

    return (
      <FlyoutContainer
        ref={this.flyoutRef}
        className={flyoutClassNames}
        aria-expanded={this.state.isOpen}
        aria-haspopup="true"
      >
        {this.renderContent()}
      </FlyoutContainer>
    );
  }
}

Flyout.propTypes = {
  headingOpen: PropTypes.string.isRequired,
  headingClosed: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(Object).isRequired,
  description: PropTypes.string.isRequired,
  undoSelection: PropTypes.string.isRequired,
};

Flyout.displayName = 'Flyout';

export default Flyout;
