import styled from 'styled-components';

import { colors } from '@style-guide/config';

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${colors.gray};
  margin-bottom: 25px;
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
