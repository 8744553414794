import styled from 'styled-components';

const Radio = styled.input`
  appearance: radio;
  margin-right: 5px;
`;

Radio.displayName = 'Radio';

export default Radio;
