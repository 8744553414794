import styled from 'styled-components';

const PassedMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

PassedMessageWrapper.displayName = 'PassedMessageWrapper';

export default PassedMessageWrapper;
