import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import is from 'is_js';
import { ebarContentTypesValues } from '@config';
import ResultDiv from '../shared/components/ResultDiv';
import LinkMainDiv from '../shared/components/LinkMainDiv';
import AngleSpan from '../shared/components/AngleSpan';
import LinkAnchor from '../shared/components/LinkAnchor';
import CaseSpan from '../shared/components/CaseSpan';
import TitleDiv from '../shared/components/TitleDiv';
import ClearDiv from '../shared/components/ClearDiv';
import ResourceBookmark from '../shared/ResourceBookmark/ResourceBookmark';
import ListParagraph from '../shared/components/ListParagraph';


const EBarEthics = ({
  eBarEthicsLists,
}) => (
  <ResultDiv>
    {eBarEthicsLists.title !== undefined &&
       is.not.null(eBarEthicsLists.title) && eBarEthicsLists.title.trim().length > 0 && (
       <TitleDiv>{eBarEthicsLists.title}</TitleDiv>
    )}
    <CaseSpan>
      {eBarEthicsLists.cite !== undefined &&
      is.not.null(eBarEthicsLists.cite) && eBarEthicsLists.cite.trim().length > 0 && (
      <span>{eBarEthicsLists.cite}</span>
      )}
      {(eBarEthicsLists.cite !== undefined &&
       is.not.null(eBarEthicsLists.cite) && eBarEthicsLists.cite.trim().length > 0 &&
       eBarEthicsLists.issueDate !== undefined && is.not.null(eBarEthicsLists.issueDate) &&
       eBarEthicsLists.issueDate.trim().length > 0) && (
       <span> | </span>
      )}
      {(eBarEthicsLists.issueDate !== undefined && is.not.null(eBarEthicsLists.issueDate) &&
       eBarEthicsLists.issueDate.trim().length > 0) ?
        `Issued: ${moment(eBarEthicsLists.issueDate).format('MMM D, YYYY')}` : ''}
    </CaseSpan>
    {/* eslint-disable */}
    {eBarEthicsLists.summary !== undefined &&
     is.not.null(eBarEthicsLists.summary) && eBarEthicsLists.summary.trim().length > 0 && (
     <ListParagraph dangerouslySetInnerHTML={{ __html: eBarEthicsLists.summary }} />
    )}
    {/* eslint-enable */}
    <LinkMainDiv>
      {((eBarEthicsLists.ctaUrl !== undefined &&
       is.not.null(eBarEthicsLists.ctaUrl) && eBarEthicsLists.ctaUrl.trim().length > 0) ||
       (eBarEthicsLists.ctaLabel !== undefined && is.not.null(eBarEthicsLists.ctaLabel) &&
       eBarEthicsLists.ctaLabel.trim().length > 0)) && (
       <div>
         <AngleSpan>
           &gt;
         </AngleSpan>
         <LinkAnchor
           href={(eBarEthicsLists.ctaUrl !== undefined && is.not.null(eBarEthicsLists.ctaUrl) &&
                 eBarEthicsLists.ctaUrl.trim().length > 0) ?
             eBarEthicsLists.ctaUrl : eBarEthicsLists.ctaLabel}
           target="_blank"
           rel="noopener noreferrer"
         >
           {(eBarEthicsLists.ctaLabel !== undefined && is.not.null(eBarEthicsLists.ctaLabel) &&
            eBarEthicsLists.ctaLabel.trim().length > 0) ?
             eBarEthicsLists.ctaLabel : eBarEthicsLists.ctaUrl}
         </LinkAnchor>
       </div>
      )}
      <ResourceBookmark
        contentGuidString={eBarEthicsLists.contentGuidString}
        type={ebarContentTypesValues.eBarEthicsCommissionAdvisoryOpinion}
        isBookmarked={eBarEthicsLists.isBookmarked}
      />
    </LinkMainDiv>
    <ClearDiv />
  </ResultDiv>
);

EBarEthics.propTypes = {
  eBarEthicsLists: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    isBookmarked: PropTypes.bool,
  }).isRequired,
};

EBarEthics.defaultProps = {
};

EBarEthics.displayName = 'EBarEthics';

export default EBarEthics;
