import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { saveExperienceInfo } from '@redux/modules/experienceInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import ExperienceInfoForm from './components/ExperienceInfoForm';

class ExperienceInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formValues) {
    // the date picker keeps changing the moment formatted date strings to
    // JS date objects. We can't send a date object, so reformat the dates
    // back to date strings with moment
    const updatedFormValues = formValues;

    const convertedDates = formValues.EmploymentHistory.map((item) => {
      const newItem = item;
      newItem.startDate = moment(item.startDate).format('l');
      newItem.endDate = item.endDate ? moment(item.endDate).format('l') : null;
      return newItem;
    });

    updatedFormValues.EmploymentHistory = convertedDates;

    this.props.dispatch(saveExperienceInfo(updatedFormValues));
  }

  render() {
    const {
      loading,
      experienceInfo,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please check that any end dates are set after their start dates and try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          title="Employment Information"
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <ExperienceInfoForm
                initialValues={experienceInfo}
                onSubmit={this.submit}
                loading={loading}
                hasSaveError={!!error}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
        />
      </FormAccordionContainer>
    );
  }
}

ExperienceInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  experienceInfo: PropTypes.shape({
    EmploymentHistory: PropTypes.arrayOf(PropTypes.shape({
      employer: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      isDeleted: PropTypes.bool,
    })),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

ExperienceInfoContent.defaultProps = {
  error: '',
  loading: false,
};

const mapStateToProps = ({ experienceInfo }) => ({
  error: experienceInfo.error,
  loading: experienceInfo.loading,
  experienceInfo,
});

ExperienceInfoContent.displayName = 'ExperienceInfoContent';

export default connect(mapStateToProps)(ExperienceInfoContent);
