import React from 'react';
import { Provider } from 'react-redux';
import { getSocialMediaInfo } from '@redux/modules/socialMediaInfo';
import Store from '@redux';

import SocialMediaInfoContent from './components/SocialMediaInfoContent';

class SocialMediaInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getSocialMediaInfo());
  }

  render() {
    return (
      <Provider store={this.store}>
        <SocialMediaInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

SocialMediaInfo.displayName = 'SocialMediaInfo';

export default SocialMediaInfo;
