import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const DescriptionContainer = styled.div`
  display: block;
  position: relative;

  p {
    text-align: justify;
    margin: 15px 0 0 0;
  }

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    margin-top: 45px;

    p {
      margin: 0 0 0 0px;
    }
  }
`;

DescriptionContainer.displayName = 'DescriptionContainer';

export default DescriptionContainer;
