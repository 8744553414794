import React from 'react';
import PropTypes from 'prop-types';

import { AddEBarBookmark, DeleteEBarBookmark } from '@services/EBarBookmark';
import ResourceBookmarkUI from './components/ResourceBookmarkUI';

class ResourceBookmark extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBookmarked: this.props.isBookmarked,
    };

    this.saveBookmark = this.saveBookmark.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isBookmarked: nextProps.isBookmarked,
    });
  }

  saveBookmark() {
    // get the current state
    const currentState = this.state.isBookmarked;
    // do whatever the opposite of the current state is (since this is a toggle)
    const ServiceToCall = currentState ? DeleteEBarBookmark : AddEBarBookmark;
    // flip the state (which will update the UI)
    this.setState({ isBookmarked: !currentState });
    // call the imported method from Bookmark Service
    ServiceToCall(this.props.contentGuidString, this.props.type)
      .then((res) => {
        if (res === false) {
          // if the async call returns false, there was an error, reset bookmarked state
          this.setState({ isBookmarked: currentState });
        }
      }).catch(() => {
        // if any other error, reset bookmarked state
        this.setState({ isBookmarked: currentState });
      });
  }

  render() {
    return (
      <ResourceBookmarkUI
        contentGuidString={this.props.contentGuidString}
        isBookmarked={this.state.isBookmarked}
        saveBookmarkHandler={this.saveBookmark}
      />
    );
  }
}

ResourceBookmark.propTypes = {
  contentGuidString: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
};

ResourceBookmark.displayName = 'ResourceBookmark';

export default ResourceBookmark;
