const renderSpeakers = speakers => speakers.map((speaker, index, collection) => {
  if (index === 0) {
    return ` ${speaker.fullName}`;
  }

  if (index === collection.length - 1 && collection.length > 2) {
    return `, & ${speaker.fullName}`;
  }

  if (index === collection.length - 1 && collection.length === 2) {
    return ` & ${speaker.fullName}`;
  }

  return `, ${speaker.fullName}`;
});

export default renderSpeakers;
