import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { typography } from '@style-guide/config';

const ItemField = styled.div`
  margin-top: 4px;
  font-size: ${rem('15px', typography.baseFontSize)};
  
  strong {
    font-weight: 700;
    margin-right: 4px;
  }
`;

ItemField.displayName = 'ItemField';

export default ItemField;
