import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';

import ActionButton from './components/ActionButton';
import Answer from './components/Answer';
import ErrorContainer from './components/ErrorContainer';
import ErrorMessage from './components/ErrorMessage';
import Subtitle from './components/Subtitle';
import Title from './components/Title';

const QuizModalBody = ({
  buttonAction,
  buttonLabel,
  errors,
  message,
}) => (
  <React.Fragment>
    <Title>{message}</Title>
    {is.not.empty(errors) && (
      <ErrorContainer>
        <Subtitle>Please fix the following errors:</Subtitle>
        {errors.map(error => (
          <React.Fragment>
            <Answer>{`#${error.order} ${error.Question}`}</Answer>
            <ErrorMessage>{`*${error.message}`}</ErrorMessage>
          </React.Fragment>
        ))}
      </ErrorContainer>
    )}
    <ActionButton
      onClick={buttonAction}
    >
      {buttonLabel}
    </ActionButton>
  </React.Fragment>
);

QuizModalBody.propTypes = {
  buttonAction: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object).isRequired,
  message: PropTypes.string.isRequired,
};

QuizModalBody.displayName = 'QuizModalBody';

export default QuizModalBody;
