import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const DesktopMarketingCTA = styled.div`
  display: none;

  @media(min-width: ${breakpoints.l}) {
    display: block;
  }
`;

DesktopMarketingCTA.displayName = 'DesktopMarketingCTA';

export default DesktopMarketingCTA;
