import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LoadingOverlay from '@components/LoadingOverlay';

import HeadingWrapper from './components/HeadingWrapper';
import ShoppingCartHeading from './components/ShoppingCartHeading';
import ShoppingCartItems from './components/ShoppingCartItems';
import Wrapper from './components/Wrapper';

const ShoppingCartContent = ({
  checkoutLink,
  loading,
}) => (
  <Wrapper>
    {loading && <LoadingOverlay />}
    <HeadingWrapper>
      <ShoppingCartHeading>Order Summary</ShoppingCartHeading>
    </HeadingWrapper>
    <ShoppingCartItems
      checkoutLink={checkoutLink}
    />
  </Wrapper>
);

ShoppingCartContent.propTypes = {
  checkoutLink: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ cart }) => ({
  loading: cart.loading,
});

ShoppingCartContent.displayName = 'ShoppingCartContent';

export default connect(mapStateToProps)(ShoppingCartContent);
