import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';
import LinkButton from '@style-guide/components/LinkButton';

import ErrorMessage from '@components/ErrorMessage';
import MarketingCTASection from '@components/MarketingCTASection';
import LoadingOverlay from '@components/LoadingOverlay';
import {
  ContentArea,
  DesktopMarketingCTAs,
  MobileMarketingCTAs,
  RightRail,
  TabMenu,
  Wrapper,
  WrapperInner,
} from '@components/TabPage';

import MyEventListItem from './components/MyEventListItem';
import PopUpModal from './components/PopUpModal/PopUpModal';
import MessageModalBody from './components/MessageModalBody';

const EventContent = styled.div`
  padding: 20px;
`;

EventContent.displayName = 'EventContent';

const EventErrorMessage = styled(ErrorMessage)`
  justify-content: flex-start;

  @media (min-width: ${breakpoints.m}) {
    padding: 0px;
  }
`;

EventErrorMessage.displayName = 'EventErrorMessage';

class MyEvents extends React.Component {
  constructor(props) {
    super(props);
    this.updateTabState = this.updateTabState.bind(this);
    this.onChangeRadioButton = this.onChangeRadioButton.bind(this);
    this.onClickCloseCancel = this.onClickCloseCancel.bind(this);
    this.refreshEventsList = this.refreshEventsList.bind(this);

    const { eventsList } = this.props;
    // build empty objects to hold upcoming and past events
    const upcomingEvents = {};
    const pastEvents = {};

    // the events object has date strings for keys (so we can easily group them by month/year)
    // and an array of events under each key (month)
    // {'2018-09-01T:00:00:00': [event, event, event]}

    Object.keys(eventsList).forEach((key) => {
      const currentdateParse = new Date();
      const inputDateParse = currentdateParse.toLocaleString('en-US', {
        timeZone: 'America/New_York',
      });
      // filter the array of events for this key for all upcoming events
      const upcomingEventArray = eventsList[key].filter(event =>
        Date.parse(event.endDate) >= Date.parse(inputDateParse));

      // take the same array of objects and now filter for past events
      const pastEventArray = eventsList[key].filter(event =>
        Date.parse(event.endDate) < Date.parse(inputDateParse));

      // ONLY if there's upcoming events for this key add it to the upcoming events object
      if (upcomingEventArray.length > 0) {
        upcomingEvents[key] = upcomingEventArray;
      }

      // ONLY if there's past events for this key add it to the past events object
      if (pastEventArray.length > 0) {
        pastEvents[key] = pastEventArray;
      }
    });

    this.state = {
      activeTab: 'Upcoming Events',
      upcomingEvents,
      pastEvents,
      loading: false,
      showMessagePopUp: false,
      messagePopUpTitle: '',
    };
  }

  onChangeRadioButton(id, endDate, type) {
    this.setState({
      loading: true,
    });

    // Check if the meeting is within 48 hours or not.
    const dateParse = new Date();
    dateParse.setDate(dateParse.getDate() - 2);
    const inputDateParse = dateParse.toLocaleString('en-US', {
      timeZone: 'America/New_York',
    });

    if (Date.parse(endDate) >= Date.parse(inputDateParse)) {
      const updatedType = type === 'Virtual' ? 'In Person' : 'Virtual';

      fetch(`/MyEventsPage/UpdateEventRegistrationDetails?id=${id}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          this.setState({
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: `Successfully changed from '${type}' to '${updatedType}'.`,
          });
          this.refreshEventsList(id, updatedType);
        })
        .catch(() => {
          this.setState({
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: 'Change was unsuccessful!!',
          });
        });
    } else {
      this.setState({
        loading: false,
        showMessagePopUp: true,
        messagePopUpTitle: `To change how you plan to attend within the next 48 hours,
          please contact OSBA member services at 1-800-232-7124.`,
      });
    }
  }

  onClickCloseCancel() {
    this.setState({
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  refreshEventsList(id, updatedType) {
    const upcoming = {};

    Object.keys(this.state.upcomingEvents).forEach((key) => {
      const upcomingEventArray = this.state.upcomingEvents[key].map(
        // eslint-disable-next-line
        el => el.aptifyCourseId === id ? { ...el, attendanceType: updatedType } : el,
      );

      if (upcomingEventArray.length > 0) {
        upcoming[key] = upcomingEventArray;
      }
    });

    this.setState({
      upcomingEvents: upcoming,
    });
  }

  updateTabState(newTab) {
    this.setState({
      activeTab: newTab,
    });
  }

  render() {
    const {
      activeTab,
      pastEvents,
      upcomingEvents,
      loading,
      showMessagePopUp,
      messagePopUpTitle,
    } = this.state;

    const {
      eventsResultsPageLink,
      rightRailContent,
      rightRailContentAreaName,
    } = this.props;

    return (
      <Wrapper>
        {loading && <LoadingOverlay />}
        {showMessagePopUp && messagePopUpTitle && (
          <PopUpModal
            dismissable={false}
            body={(
              <MessageModalBody
                title={messagePopUpTitle}
                okFunction={this.onClickCloseCancel}
              />
            )}
            closeAction={this.onClickCloseCancel}
          />
        )}
        <WrapperInner>
          <ContentArea>
            <TabMenu
              tabLabels={Object.keys(pastEvents).length === 0 ? ['Upcoming Events'] : ['Upcoming Events', 'Past Events']}
              updateTabState={this.updateTabState}
              activeTab={activeTab}
            />
            {activeTab === 'Upcoming Events' && (
              <EventContent>
                {Object.keys(upcomingEvents).length === 0 && (
                  <React.Fragment>
                    <EventErrorMessage>
                      You are not registered for any upcoming events
                    </EventErrorMessage>
                    <LinkButton
                      href={eventsResultsPageLink}
                    >
                      Search for Events
                    </LinkButton>
                  </React.Fragment>
                )}
                {Object.keys(upcomingEvents).length > 0 &&
                  Object.keys(upcomingEvents).map(key => (
                    <MyEventListItem
                      key={key}
                      title={key}
                      events={upcomingEvents[key]}
                      isAddToCalendar
                      onChangeRadioButton={this.onChangeRadioButton}
                    />
                  ))}
              </EventContent>
            )}
            {activeTab === 'Past Events' && (
              <EventContent>
                {Object.keys(pastEvents).length > 0 &&
                  Object.keys(pastEvents).map(key => (
                    <MyEventListItem
                      key={key}
                      title={key}
                      events={pastEvents[key]}
                      isPastEvent
                    />
                  ))}
              </EventContent>
            )}
          </ContentArea>
          <DesktopMarketingCTAs>
            <RightRail>
              <div
                data-epi-property-name={rightRailContentAreaName}
                data-epi-property-render="none"
                data-epi-property-edittype="floating"
              >
                <MarketingCTASection
                  vertical
                  contentItems={rightRailContent}
                  orientation="vertical"
                />
              </div>
            </RightRail>
          </DesktopMarketingCTAs>
        </WrapperInner>
        <MobileMarketingCTAs>
          <RightRail>
            <div
              data-epi-property-name={rightRailContentAreaName}
              data-epi-property-render="none"
              data-epi-property-edittype="floating"
            >
              <MarketingCTASection
                vertical
                contentItems={rightRailContent}
                orientation="vertical"
              />
            </div>
          </RightRail>
        </MobileMarketingCTAs>
      </Wrapper>
    );
  }
}

MyEvents.propTypes = {
  // eslint-disable-next-line
  eventsList: PropTypes.object,
  eventsResultsPageLink: PropTypes.string,
  rightRailContent: PropTypes.string,
  rightRailContentAreaName: PropTypes.string.isRequired,
};

MyEvents.defaultProps = {
  eventsList: {},
  eventsResultsPageLink: null,
  rightRailContent: '',
};

export default MyEvents;
