import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveAffiliationsInfo } from '@redux/modules/affiliationsInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import AffiliationsInfoForm from './components/AffiliationsInfoForm';

class AffiliationsInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formValues) {
    this.props.dispatch(saveAffiliationsInfo(formValues));
  }

  render() {
    const {
      loading,
      affiliationsInfo,
      title,
      involvementTypes,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          title={title}
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <AffiliationsInfoForm
                initialValues={affiliationsInfo}
                involvementTypes={involvementTypes}
                onSubmit={this.submit}
                loading={loading}
                hasSaveError={!!error}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
        />
      </FormAccordionContainer>
    );
  }
}

AffiliationsInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  affiliationsInfo: PropTypes.shape({
    Certifications: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  involvementTypes: PropTypes.arrayOf(PropTypes.string),
};

AffiliationsInfoContent.defaultProps = {
  error: '',
  loading: false,
  title: 'Affiliations Information',
  involvementTypes: [],
};

const mapStateToProps = ({ affiliationsInfo }) => ({
  error: affiliationsInfo.error,
  loading: affiliationsInfo.loading,
  affiliationsInfo,
});

AffiliationsInfoContent.displayName = 'AffiliationsInfoContent';

export default connect(mapStateToProps)(AffiliationsInfoContent);
