import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';

import {
  saveMediaInfo,
  updateMediaSubmissionError,
} from '@redux/modules/mediaInfo';

import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import MediaInfoForm from './components/MediaInfoForm';

class MediaInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formValues) {
    const profilePhotoIsValid = formValues.profilePhoto.isValid;
    const companyLogoIsValid = formValues.companyLogo.isValid;
    const profilePhotoSize = formValues.profilePhoto.file ? formValues.profilePhoto.file.size : 0;
    const companyLogoSize = formValues.companyLogo.file ? formValues.companyLogo.file.size : 0;
    const profilePhotoIsTooLarge = profilePhotoSize > 1048576;
    const companyLogoIsTooLarge = companyLogoSize > 1048576;

    let errStr;

    if (!profilePhotoIsValid) {
      errStr = 'Profile photos must be at least 500px by 500px.';
    } else if (!companyLogoIsValid) {
      errStr = 'Company logos must be at least 120px tall.';
    } else if (profilePhotoIsTooLarge) {
      errStr = 'Profile photos must be no more more than 1mb.';
    } else if (companyLogoIsTooLarge) {
      errStr = 'Company logos must be no more more than 1mb.';
    }

    if (errStr) {
      this.props.dispatch(updateMediaSubmissionError(errStr));
      throw new SubmissionError({
        _error: errStr,
      });
    } else {
      const formData = new FormData();

      formData.append('ProfilePhoto', formValues.profilePhoto.file ? formValues.profilePhoto.file : undefined);
      formData.append('CompanyLogo', formValues.companyLogo.file ? formValues.companyLogo.file : undefined);
      formData.append('CoverImageUrl', formValues.coverImage.url);
      formData.append('DeleteProfilePhoto', formValues.profilePhoto.deleted);
      formData.append('DeleteCompanyLogo', formValues.companyLogo.deleted);

      this.props.dispatch(saveMediaInfo(formData));
    }
  }

  render() {
    const {
      coverImageOptions,
      loading,
      mediaInfo,
      title,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          title={title}
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <MediaInfoForm
                initialValues={mediaInfo.values}
                onSubmit={this.submit}
                loading={loading}
                hasSaveError={!!error}
                coverImageOptions={coverImageOptions}
                error={error}
              />
            </FormContainer>
          )}
        />
      </FormAccordionContainer>
    );
  }
}

MediaInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  mediaInfo: PropTypes.shape({
    values: PropTypes.shape({
      CompanyLogoUrl: PropTypes.string,
      CoverImageUrl: PropTypes.string,
      ProfileImageUrl: PropTypes.string,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
  coverImageOptions: PropTypes.arrayOf(PropTypes.object),
};

MediaInfoContent.defaultProps = {
  error: '',
  loading: false,
  title: 'Media Information',
  coverImageOptions: [],
};

const mapStateToProps = ({ mediaInfo }) => ({
  error: mediaInfo.error,
  loading: mediaInfo.loading,
  mediaInfo,
});

MediaInfoContent.displayName = 'MediaInfoContent';

export default connect(mapStateToProps)(MediaInfoContent);
