import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { typography } from '@style-guide/config';

const DetailsTitle = styled.div`
  font-size: ${rem('18px', typography.baseFontSize)};
  font-weight: 600;
  margin-bottom: 15px;
`;

DetailsTitle.displayName = 'DetailsTitle';

export default DetailsTitle;
