import styled from 'styled-components';

import { colors } from '@style-guide/config';

const ListItem = styled.div`
  font-weight: 500;
  margin-bottom: 12px;
  padding: 0px 8px;

  a span {
    color: ${colors.black};
    margin-right: 4px;
    text-decoration: underline;
  }
`;

ListItem.displayName = 'ListItem';

export default ListItem;
