import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.fieldset`
  display: flex;
  flex-direction: column;
  max-width: ${props => (props.$maxWidth ? props.$maxWidth : 'none')};
  width: 100%;
`;

Wrapper.displayName = 'Wrapper';

const StyledLegend = styled.legend`
  font-weight: 500;
  margin-bottom: 10px;
`;

StyledLegend.displayName = 'StyledLegend';

const FormFieldSet = ({
  label = '',
  children,
  maxWidth = '',
}) => (
  <Wrapper
    $maxWidth={maxWidth}
  >
    {label && <StyledLegend>{label}</StyledLegend>}
    {children}
  </Wrapper>
);

FormFieldSet.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  maxWidth: PropTypes.string,
};

FormFieldSet.displayName = 'FormFieldSet';

export default FormFieldSet;
