const ApiGenerator = next => (type, route, name, body, isFormPost) => {
  fetch(route, {
    method: type,
    credentials: 'same-origin',
    body: isFormPost ? body : JSON.stringify(body),
    headers: isFormPost
      ?
      undefined
      :
      {
        'content-type': 'application/json',
      },
  })
    .then((res) => {
      const contentType = res.headers.get('Content-Type') || '';

      if (!res.ok) {
        throw Error('An unknown error has occured. Please try again later.');
      }

      if (contentType.indexOf('application/json') !== -1) {
        return res.json();
      }

      return res.text();
    })
    .then(data => next({
      type: `${name}_RECEIVED`,
      data,
    }))
    .catch(error => next({
      type: `${name}_ERROR`,
      error: error.message,
    }));
};

export default ApiGenerator;
