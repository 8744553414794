import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TotalCasesPerPracticeAreaDiv from './components/TotalCasesPerPracticeAreaDiv';

const TopicAnchor = styled.a`
  color: #000;
  text-decoration: none;
`;

TopicAnchor.displayName = 'TopicAnchor';

const CountSpan = styled.span`
  color: #C3002F;
`;

CountSpan.displayName = 'CountSpan';

const getPracticeAreaHtmlId = (practicename) => {
  const htmlId1 = practicename.replaceAll(' ', '');
  const htmlId2 = htmlId1.replaceAll('/', '');
  const htmlId3 = htmlId2.replaceAll('\'', '');
  const htmlId = htmlId3.replaceAll('\\', '');
  return htmlId;
};

class TotalCasesPerPracticeArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: this.props.caseWithPracticeArea.viewCases,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      view: nextProps.caseWithPracticeArea.viewCases,
    });
  }

  render() {
    const {
      view,
    } = this.state;

    const {
      caseWithPracticeArea,
    } = this.props;

    return (
      <TotalCasesPerPracticeAreaDiv>
        {view && (
          <TopicAnchor href={`#${getPracticeAreaHtmlId(caseWithPracticeArea.nameOfPractice)}`}>
            {caseWithPracticeArea.nameOfPractice}
            <CountSpan> ({caseWithPracticeArea.cases.length})</CountSpan>
          </TopicAnchor>
        )}
      </TotalCasesPerPracticeAreaDiv>
    );
  }
}

TotalCasesPerPracticeArea.propTypes = {
  caseWithPracticeArea: PropTypes.shape({
    nameOfPractice: PropTypes.string,
    practiceAreaId: PropTypes.number,
    casesCount: PropTypes.number,
    viewCases: PropTypes.bool,
    cases: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      summary: PropTypes.string,
      caseName: PropTypes.string,
      webCitation: PropTypes.string,
      casemakerLink: PropTypes.string,
      pDFLink: PropTypes.string,
      practiceId: PropTypes.number,
      courtId: PropTypes.number,
      contentGuidString: PropTypes.string,
      isBookmarked: PropTypes.bool,
    })).isRequired,
  }).isRequired,
};

TotalCasesPerPracticeArea.defaultProps = {
};

TotalCasesPerPracticeArea.displayName = 'TotalCasesPerPracticeArea';

export default TotalCasesPerPracticeArea;
