import CompanyInfo from './components/CompanyInfo';
import ContactInfo from './components/ContactInfo';
import DemographicInfo from './components/DemographicInfo';
import ExperienceInfo from './components/ExperienceInfo';
import LawSchoolInfo from './components/LawSchoolInfo';
import PersonalInfo from './components/PersonalInfo';
import SocialMediaInfo from './components/SocialMediaInfo';

export {
  CompanyInfo,
  ContactInfo,
  DemographicInfo,
  ExperienceInfo,
  LawSchoolInfo,
  PersonalInfo,
  SocialMediaInfo,
};
