import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const SortFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.m}) {
    align-items: center;
    flex-direction: row;
  }
`;

SortFilterWrapper.displayName = 'SortFilterWrapper';

export default SortFilterWrapper;
