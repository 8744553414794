import styled from 'styled-components';

const LinkMainDiv = styled.div`
  margin-bottom: ${props => (props.noMargin ? '' : '20px')};

  div {
    float: left;
  }

  button {
    @media (min-width: 376px) {
      float: right;
    }
  }
`;

LinkMainDiv.displayName = 'LinkMainDiv';

export default LinkMainDiv;
