import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import transparentize from 'polished/lib/color/transparentize';
import { colors, stickyHeader, typography } from '@style-guide/config';

const ShoppingCartNavItem = styled.li`
  border-left: 1px solid ${transparentize(0.8, colors.white)};
  float: left;

  > button {
    color: ${colors.white};
    height: ${stickyHeader.stickyHeaderHeight};
    line-height: ${stickyHeader.stickyHeaderHeight};
    text-align: center;
    /* magic number from .psd */
    width: ${rem('54px', typography.baseFontSize)};

    .fa {
      font-size: ${rem('18px', typography.baseFontSize)};
    }
  }
`;

ShoppingCartNavItem.displayName = 'ShoppingCartNavItem';

export default ShoppingCartNavItem;
