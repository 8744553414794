import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import { colors } from '@style-guide/config';

import BecomeAMemberBlock from '@components/BecomeAMemberBlock';
import ResourcePreviewList from '@components/ResourcePreviewList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import LinkButton from '@style-guide/components/LinkButton';
import Contributors from './components/Contributors';
import RelatedCLEs from './components/RelatedCLEs';
import ResourceContentContainer from './components/ResourceContentContainer';
import ResourceUtilities from './components/ResourceUtilities';
import RestrictedOverlay from './components/RestrictedOverlay';
import Tags from './components/Tags';
import { ButtonTextSpan, BoldPara, StylishPara } from './components/CSSElementsForCase';

const GlobalStyle = createGlobalStyle`
  body {
    background: ${colors.white};
  }
`;

const ResourceContent = ({
  caseDescription,
  caseName,
  webCitation,
  practiceArea,
  court,
  pdfLink,
  contributors,
  tags,
  isMember,
  contentGuidString,
  isBookmarked,
  isSpecialFeature,
  isRestricted,
  createAccountUrl,
  relatedArticles,
  relatedCles,
}) => (
  <React.Fragment>
    <GlobalStyle />
    <ResourceContentContainer
      isRestricted={isRestricted}
    >
      {isRestricted && (
      <RestrictedOverlay>
        <BecomeAMemberBlock
          title="GET FULL ACCESS"
          description="OSBA members have full access to all content on the website as well as many other member benefits."
          createAccountLink={createAccountUrl}
          hasBottomMargin
          hasMaxWidth={false}
        />
      </RestrictedOverlay>
      )}
      <ResourceUtilities
        isMember={isMember}
        contentGuidString={contentGuidString}
        isBookmarked={isBookmarked}
      />
      {isSpecialFeature && !!relatedArticles.length && (
        <ResourcePreviewList
          resources={relatedArticles}
        />
      )}
      {(!isSpecialFeature && !!contributors.length) && (
        <Contributors
          contributors={contributors}
        />
      )}
      {!!tags.length && (
        <Tags
          tags={tags}
        />
      )}
      {!!relatedCles.length && (
        <RelatedCLEs
          CLEs={relatedCles}
        />
      )}
      <BoldPara>{practiceArea}</BoldPara>
      <p>{caseDescription}</p>
      <StylishPara>{caseName}</StylishPara>
      <StylishPara>{webCitation}</StylishPara>
      <StylishPara>{court}</StylishPara>
      <LinkButton
        href={pdfLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faFilePdf} /><ButtonTextSpan>Download</ButtonTextSpan>
      </LinkButton>
    </ResourceContentContainer>
  </React.Fragment>
);

ResourceContent.propTypes = {
  caseDescription: PropTypes.string.isRequired,
  caseName: PropTypes.string.isRequired,
  webCitation: PropTypes.string.isRequired,
  practiceArea: PropTypes.string.isRequired,
  court: PropTypes.string.isRequired,
  pdfLink: PropTypes.string.isRequired,
  contributors: PropTypes.arrayOf(PropTypes.object).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMember: PropTypes.bool.isRequired,
  contentGuidString: PropTypes.string.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  isSpecialFeature: PropTypes.bool.isRequired,
  isRestricted: PropTypes.bool.isRequired,
  createAccountUrl: PropTypes.string.isRequired,
  relatedArticles: PropTypes.arrayOf(PropTypes.object),
  relatedCles: PropTypes.arrayOf(PropTypes.object),
};

ResourceContent.defaultProps = {
  relatedArticles: [],
  relatedCles: [],
};

ResourceContent.displayName = 'ResourceContent';

export default ResourceContent;
