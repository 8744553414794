import gtag from 'ga-gtag';
import { urlEncode } from '@services/Url';

const serializeFormData = data => ({
  PaymentTypeID: data.paymentTypeID,
  CCAccountNumber: data.ccPartial,
  FirstName: data.firstName,
  LastName: data.lastName,
  CCExpireMonth: data.ccExpireMonth,
  CCExpireYear: data.ccExpireYear,
  CVV: data.securityCode,
  BillingAddress1: data.billingAddress1,
  BillingAddress2: data.billingAddress2,
  BillingAddressCity: data.billingAddressCity,
  BillingAddressState: data.billingAddressState,
  BillingAddressZip: data.billingAddressZip,
  ID: data.id,
});

const serializeFormDataSubmitOrder = data => ({
  PaymentTypeID: data.cardType,
  CCAccountNumber: data.cardNumber,
  FirstName: data.firstName,
  LastName: data.lastName,
  CCExpireMonth: data.expirationMonth,
  CCExpireYear: data.expirationYear,
  CVV: data.securityCode,
  BillingAddress1: data.addressLine1,
  BillingAddress2: data.addressLine2,
  BillingAddressCity: data.city,
  BillingAddressState: data.state,
  BillingAddressZip: data.zipCode,
});

const serializePaymentData = data => ({
  CardNumber: data.cardNumber,
  CVV: data.securityCode,
  ExpirationDate: (data.expirationMonth || data.expirationYear) && `${data.expirationMonth}${data.expirationYear}`,
  FirstName: data.firstName,
  LastName: data.lastName,
  Street: (data.addressLine1 || data.addressLine2) && `${data.addressLine1} ${data.addressLine2}`,
  City: data.city,
  State: data.state,
  Zip: data.zipCode,
  Renew: data.renew,
  PaymentTypeId: data.cardType,
});

const serializePayPalData = data => ({
  OrderID: data.orderID,
  PayerID: data.payerID,
  Token: data.paymentToken,
  Renew: data.renew,
  PaymentTypeId: '48',
});

const updateCreditCard = async (data) => {
  const orderPost = await fetch('/PaymentInformationBlock/UpdateCreditCard', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
      id: data.id,
      captchaValue: data.captchaValue,
      userId: data.userId,
      isDisclaimerChecked: data.isDisclaimerChecked,
    }),
  });
  const orderPostJson = orderPost.json();

  return orderPostJson;
};

const updatePayPal = async (data) => {
  const orderPost = await fetch('/OrderProcessing/UpdatePayPal', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify({
        PayerID: data.payerID,
        Token: data.paymentToken,
      }),
      standingOrderId: data.standingOrderId,
    }),
  });
  const orderPostJson = orderPost.json();

  return orderPostJson;
};

const removeSavedPaymentMethod = async (data) => {
  const orderPost = await fetch('/PaymentInformationBlock/RemoveCreditCard', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      userId: data.userId,
    }),
  });
  const orderPostJson = orderPost.json();

  return orderPostJson;
};

function findCardType(cardType) {
  let card = '';

  switch (cardType) {
    case '8':
      card = 'American Express';
      break;
    case '7':
      card = 'Mastercard';
      break;
    case '3':
      card = 'Visa';
      break;
    case '9':
      card = 'Discover';
      break;
    default:
      card = '';
      break;
  }
  return card;
}

const submitOrder = async (data, value, useSavedPaymentMethod) => {
  const serializedData = ((data.cardNumber ||
        useSavedPaymentMethod) ? serializePaymentData(data) : serializePayPalData(data));
  const serializedDataForCreditCard = (data.cardNumber ||
        useSavedPaymentMethod) ? JSON.stringify(serializeFormDataSubmitOrder(data)) : '';
  const orderPost = await fetch('/OrderProcessing/SubmitOrder', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializedData),
      cardDataToSave: serializedDataForCreditCard,
      captchaValue: value,
      useSavedCreditCard: useSavedPaymentMethod,
    }),
  });
  const orderPostJson = orderPost.json();

  const paymentType = findCardType(data.cardType);
  const payerInformation = `FirstName:${data.firstName}, LastName:${data.lastName}, AddressLine1:${data.addressLine1}, AddressLine2:${data.addressLine2}, City:${data.city}, State:${data.state}, ZipCode:${data.zipCode}`;

  gtag('event', 'add_payment_info', {
    currency: 'USD',
    payment_type: paymentType,
    PayerInformation: payerInformation,
  });

  return orderPostJson;
};

const submitFreeOrder = async (contentId, note,
  attendanceType, adaList, addOnIds, captchaValue) => {
  const orderPost = await fetch('/OrderProcessing/SubmitFreeOrder', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      contentId,
      captchaValue,
      note,
      attendanceType,
      adaList,
      addOnIds,
    }),
  });
  const orderPostJson = orderPost.json();
  return orderPostJson;
};

const checkOrderAmount = async (contentId, note,
  attendanceType, addOnIds) => {
  const orderPost = await fetch('/OrderProcessing/CheckOrderAmount', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      contentId,
      note,
      attendanceType,
      addOnIds,
    }),
  });
  const orderPostJson = orderPost.json();
  return orderPostJson;
};

export {
  updateCreditCard,
  updatePayPal,
  removeSavedPaymentMethod,
  submitOrder,
  submitFreeOrder,
  checkOrderAmount,
};
