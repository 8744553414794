import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import is from 'is_js';

import LinkButton from '@style-guide/components/LinkButton';
import MarketingCTASection from '@components/MarketingCTASection';
import {
  ContentArea,
  DesktopMarketingCTAs,
  MobileMarketingCTAs,
  RightRail,
  TabMenu,
  Wrapper,
  WrapperInner,
} from '@components/TabPage';

import Card from './components/Card';
import ResultsWrapper from './components/ResultsWrapper';
import NoResultsWrapper from './components/NoResultsWrapper';
import ResultWrapper from './components/ResultWrapper';
import YearResultsWrapper from './components/YearResultsWrapper';
import YearLabel from './components/YearLabel';
import SupremeCourtLinkMessage from './components/SupremeCourtLinkMessage';

/**
# MyCLEView
A view containing info on CLE courses a user has interacted with
 */
class MyCLEView extends React.Component {
  constructor(props) {
    super(props);

    const {
      pastEvents,
      upcomingEvents,
    } = this.props;

    const adaptEvents = (eventsObj) => {
      // returns an object with keys that correspond to the year the course occurs
      const groupedEvents = groupBy(eventsObj, (upcomingEvent) => {
        const eventMoment = moment(upcomingEvent.startDate);

        return eventMoment.year();
      });

      // returns an array of event years in ascending order
      const sortedGroupedEventKeys = Object.keys(groupedEvents).sort((a, b) => b - a);

      // returns an array of objects with the shape
      // {
      //    year: 2018,
      //    classes: [...], // (array of objects)
      // }
      const transformedEvents = sortedGroupedEventKeys.map((val) => {
        const orderedYearEvents = orderBy(groupedEvents[val], 'endDate', 'asc');

        return ({
          year: val,
          classes: orderedYearEvents,
        });
      });

      return transformedEvents;
    };

    this.state = {
      activeTab: this.props.currentTab !== '' ? this.props.currentTab : 'Registered For',
      upcomingEvents: adaptEvents(upcomingEvents),
      pastEvents: adaptEvents(pastEvents),
    };

    this.updateTabState = this.updateTabState.bind(this);
    this.renderSupremeCourtLink = this.renderSupremeCourtLink.bind(this);
    this.renderTab = this.renderTab.bind(this);
  }

  updateTabState(newTab) {
    this.setState({
      activeTab: newTab,
    });
  }

  renderSupremeCourtLink() {
    const { supremeCourtLink } = this.props;
    const {
      pastEvents,
      activeTab,
    } = this.state;

    if (is.not.empty(pastEvents) && activeTab === 'Past Events') {
      return (
        <SupremeCourtLinkMessage>
          <span>Though we work to process your CLE credits as fast as we can, the Ohio Bar has up
            to 30 days to submit them to the Supreme Court of Ohio. If you do not see a certificate
            for a course you have completed right away, please check back or visit the&nbsp;
          </span>
          <a
            target="_blank"
            href={supremeCourtLink}
            rel="noopener noreferrer"
          >
            Supreme Court website
          </a>
          <span> to view your total CLE credits.</span>
        </SupremeCourtLinkMessage>
      );
    }

    return undefined;
  }

  renderTab(name, data) {
    const { shopForACLELink } = this.props;
    let uiToRender = null;

    const renderEvents = eventsToRender => eventsToRender.map(eventItem => (
      <ResultWrapper key={eventItem.orderDetailID}>
        <Card
          isPastEvent={name === 'Past Events'}
          title={eventItem.courseTitle}
          courseId={eventItem.courseId}
          courseFormat={eventItem.courseFormat}
          possibleCredits={eventItem.possibleCredits}
          earnedCredits={eventItem.earnedCredits}
          speakers={eventItem.speakers}
          status={eventItem.status}
          classroomLink={eventItem.classroomLink}
          certificateLink={eventItem.certificateLink}
          startTime={eventItem.startTime}
          endTime={eventItem.endTime}
          practiceAreas={eventItem.practiceAreas}
          date={eventItem.date}
          location={eventItem.location}
          startDate={eventItem.startDate}
          endDate={eventItem.endDate}
          detailLink={eventItem.detailLink}
          surveyLink={eventItem.surveyLink}
        />
      </ResultWrapper>
    ));

    if (this.state.activeTab === name) {
      if (is.not.empty(data)) {
        uiToRender = data.map(value => (
          <YearResultsWrapper key={value.year}>
            <YearLabel>{value.year}</YearLabel>
            <ResultsWrapper>
              {value && renderEvents(value.classes)}
            </ResultsWrapper>
          </YearResultsWrapper>

        ));
      } else {
        const message = this.state.activeTab === 'Registered For'
          ?
          'You are not currently registered for any CLEs. Sign up for an Ohio State Bar Association CLE today!'
          :
          'You have no completed CLEs. Sign up for an Ohio State Bar Association CLE today!';
        uiToRender = (
          <NoResultsWrapper>
            {/* eslint-disable max-len */}
            <p>{message}</p>
            {/* eslint-enable max-len */}
            <LinkButton
              href={shopForACLELink}
            >
              Find a CLE
            </LinkButton>
          </NoResultsWrapper>
        );
      }

      return (
        <ResultsWrapper>
          {this.renderSupremeCourtLink()}
          {uiToRender}
        </ResultsWrapper>
      );
    }

    return undefined;
  }

  render() {
    const {
      activeTab,
      pastEvents,
      upcomingEvents,
    } = this.state;

    const {
      rightRailContent,
      rightRailContentAreaName,
    } = this.props;

    return (
      <Wrapper>
        <WrapperInner>
          <ContentArea>
            <TabMenu
              tabLabels={['Registered For', 'Past Events']}
              updateTabState={this.updateTabState}
              activeTab={activeTab}
            />
            {this.renderTab('Registered For', upcomingEvents)}
            {this.renderTab('Past Events', pastEvents)}
          </ContentArea>
          <DesktopMarketingCTAs>
            <RightRail>
              <div
                data-epi-property-name={rightRailContentAreaName}
                data-epi-property-render="none"
                data-epi-property-edittype="floating"
              >
                <MarketingCTASection
                  vertical
                  contentItems={rightRailContent}
                  orientation="vertical"
                />
              </div>
            </RightRail>
          </DesktopMarketingCTAs>
        </WrapperInner>
        <MobileMarketingCTAs>
          <RightRail>
            <div
              data-epi-property-name={rightRailContentAreaName}
              data-epi-property-render="none"
              data-epi-property-edittype="floating"
            >
              <MarketingCTASection
                vertical
                contentItems={rightRailContent}
                orientation="vertical"
              />
            </div>
          </RightRail>
        </MobileMarketingCTAs>
      </Wrapper>
    );
  }
}

MyCLEView.propTypes = {
  upcomingEvents: PropTypes.arrayOf(Object),
  pastEvents: PropTypes.arrayOf(Object),
  rightRailContent: PropTypes.string,
  rightRailContentAreaName: PropTypes.string.isRequired,
  shopForACLELink: PropTypes.string.isRequired,
  supremeCourtLink: PropTypes.string.isRequired,
  currentTab: PropTypes.string,
};

MyCLEView.defaultProps = {
  upcomingEvents: [],
  pastEvents: [],
  rightRailContent: '',
  currentTab: '',
};

MyCLEView.displayName = 'MyCLEView';

export default MyCLEView;
