import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { typography } from '@style-guide/config';

import Divider from '@components/Divider';

const ContributorCompany = styled.span`
  font-style: italic;
  font-weight: 600;
`;

ContributorCompany.displayName = 'ContributorCompany';

const ContributorLocation = styled.span`
  font-style: italic;
`;

ContributorLocation.displayName = 'ContributorLocation';

const ContributorsContainer = styled.div`
  font-size: ${rem('14px', typography.baseFontSize)};
  margin: 50px 0;

  h3 {
    font-size: ${rem('18px', typography.baseFontSize)};
  }

  ul {
    line-height: 1.5em;
  }
`;

ContributorsContainer.displayName = 'ContributorsContainer';

const Contributors = ({ contributors }) => {
  const contributorsWithIDs = contributors.map((contributor, index) => ({
    id: index,
    ...contributor,
  }));

  const contributorListItems = contributorsWithIDs.map(contributor => (
    <li
      key={contributor.id}
    >
      <span>{contributor.name}</span>
      {!!contributor.company &&
        <ContributorCompany>,&nbsp;{contributor.company}</ContributorCompany>
      }
      {!!contributor.city &&
        <ContributorLocation>,&nbsp;{contributor.city}</ContributorLocation>
      }
      {!!contributor.state &&
        <ContributorLocation>,&nbsp;{contributor.state}</ContributorLocation>
      }
    </li>
  ));

  return (
    <ContributorsContainer>
      <h3>Contributors</h3>
      <Divider />
      <ul>
        {contributorListItems}
      </ul>
    </ContributorsContainer>
  );
};

Contributors.propTypes = {
  contributors: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Contributors.displayName = 'Contributors';

export default Contributors;
