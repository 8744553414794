import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const PracticeAreasWrapperMobile = styled.div`
  width: 100%;
  margin-top: 15px;

  @media(min-width: ${breakpoints.m}) {
    display: none;
  }
`;

PracticeAreasWrapperMobile.displayName = 'PracticeAreasWrapperMobile';

export default PracticeAreasWrapperMobile;
