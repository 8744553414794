import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';
import moment from 'moment';

import { breakpoints } from '@style-guide/config';
import Button from '@style-guide/components/Button';

import { required } from '@services/FormValidators';
import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormDatePicker from '@components/ReduxFormDatePicker';
import ReduxFormInput from '@components/ReduxFormInput';
import ReduxFormSelect from '@components/ReduxFormSelect';

const StateSelectContainer = styled.div`
  max-width: 100px;
`;

StateSelectContainer.displayName = 'StateSelectContainer';

const AdmissionContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;

    > div:not(:first-child) {
      margin-left: 15px;
    }

    button {
      margin: 45px 0 30px 15px;
    }
  }
`;

AdmissionContainer.displayName = 'AdmissionContainer';

const Admission = ({
  admission,
  index,
  onRemove,
  states,
}) => (
  <AdmissionContainer>
    <FormFieldWrapper>
      <StateSelectContainer>
        <Field
          component={ReduxFormSelect}
          id="state"
          label="State*"
          name={`${admission}.State`}
          options={states.map(state => ({
            label: state.value,
            value: state.value,
          }))}
          validate={required}
        />
      </StateSelectContainer>
      <Field
        component={ReduxFormDatePicker}
        id={`dateOfAdmission${index}`}
        label="Date of Admission*"
        maxDate={moment().toDate()}
        name={`${admission}.DateOfAdmission`}
        scrollableYearDropdown
        showYearDropdown
        validate={required}
        yearDropdownItemNumber={50}
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        id={`supremeCourtNumber${index}`}
        label="Supreme Court Number"
        name={`${admission}.SupremeCourtNumber`}
      />
    </FormFieldWrapper>
    <div>
      <Button
        onClick={onRemove}
        type="button"
        variant="ghost"
      >
        REMOVE
      </Button>
    </div>
  </AdmissionContainer>
);

Admission.propTypes = {
  admission: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Admission.displayName = 'Admission';

export default Admission;
