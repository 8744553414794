import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { FiltersWrapper } from '@components/SearchResults';
import SearchFiltersToggle from './components/SearchFiltersToggle';

class SearchFilterLeftRail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: window.innerWidth,
    };

    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', debounce(this.updateWindowWidth, 100));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', debounce(this.updateWindowWidth, 100));
  }

  updateWindowWidth() {
    this.setState({
      windowWidth: window.innerWidth,
    });
  }

  render() {
    const { children } = this.props;
    const { windowWidth } = this.state;

    return (
      <FiltersWrapper>
        {windowWidth < 1200 && (
          <SearchFiltersToggle>
            {children}
          </SearchFiltersToggle>
        )}
        {windowWidth >= 1200 && children}
      </FiltersWrapper>
    );
  }
}

SearchFilterLeftRail.propTypes = {
  // the React childen components will come through as an array of objects
  children: PropTypes.arrayOf(Object).isRequired,
};

SearchFilterLeftRail.displayName = 'SearchFilterLeftRail';

export default SearchFilterLeftRail;
