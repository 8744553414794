import React from 'react';
import styled from 'styled-components';
import is from 'is_js';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import rem from 'polished/lib/helpers/rem';

import {
  breakpoints,
  typography,
} from '@style-guide/config';
import CLEContentCard from '@components/CLEContentCard';
import SpecializationCreditSVG from '@components/SpecializationCreditSVG';
import Store from '@redux';
import renderSpecializationCredits from './services/renderSpecializationCredits';
import BrochureDownload from './components/BrochureDownload';
import DetailList from './components/DetailList';
import Divider from './components/Divider';
import PriceList from './components/PriceList';
import Register from './components/Register';

const DetailsContainerCard = styled(CLEContentCard)`
  @media (min-width: ${breakpoints.l}) {
    width: 350px;
  }
`;

DetailsContainerCard.displayName = 'DetailsContainerCard';

const LocationBlock = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px 0;

  > div:first-child {
    font-weight: 600;
    margin-right: 4px;
  }

  div {
    display: block;
    font-size: ${rem('14px', typography.baseFontSize)};
  }

  p {
    margin-bottom: 2px;
    line-height: ${typography.baseFontSize};
  }
`;

LocationBlock.displayName = 'LocationBlock';

const PricingHeading = styled.h2`
  margin-top: 15px;
`;

PricingHeading.displayName = 'PricingHeading';

const SpecializationCreditBlock = styled.div`
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 10px;

  span:nth-of-type(2) {
    line-height: 28px;
    margin-right: 4px;
  }

  strong {
    font-weight: 700;
  }
`;

SpecializationCreditBlock.displayName = 'SpecializationCreditBlock';

const Details = ({
  addOnsActive,
  brochureLink,
  contentGuid,
  zoomMeetingUrl,
  date,
  isMeetingAvailableUntilDate,
  endTime,
  startTime,
  prices,
  location,
  attendanceTypeOptions,
  disableAddToCart,
  isRegister,
  isRegisterType,
  isAddonComplete,
  onPurchaseComplete,
  updateShowCart,
  specializationCredits,
  credits,
  isMeetingEnded,
}) => {
  const store = Store.get();

  const priceList = prices.map(price => (
    <li
      key={price.market}
    >
      <span>{price.market && `${price.market} `}Price: </span>
      <span id={`eventDetailsPrice${price.index}`}>{price.amount}</span>
    </li>
  ));

  const creditsList = credits.map((credit, index) => (
    <React.Fragment
      key={credit.typeName}
    >
      {credit.hoursString} {credit.typeName.trim()}{index + 1 < credits.length && ', '}
    </React.Fragment>
  ));

  return (
    <DetailsContainerCard>
      <h2>Details</h2>
      {brochureLink && (
        <BrochureDownload
          brochureLink={brochureLink}
        />
      )}
      <DetailList>
        {date && (
          <li>
            <span>Date:&nbsp;</span>
            <span>{date}</span>
          </li>
        )}
        {(startTime && endTime) && (
          <li>
            <span>Time:&nbsp;</span>
            <span>{`${startTime} - ${endTime}`}</span>
          </li>
        )}
        {credits && !!credits.length && (
          <li>
            <span>{credits.length > 1 ? 'Credits:' : 'Credit:'}&nbsp;</span>
            <span>{creditsList}</span>
          </li>
        )}
      </DetailList>
      {location && (
        <LocationBlock>
          <div>Location:</div>
          <div>
            <p>{location.displayName}</p>
            <p>{location.line1} {location.line2} {location.line3} {location.line4}</p>
            <p>{location.city}, {location.state} {location.postalCode}</p>
          </div>
        </LocationBlock>
      )}
      {!location && zoomMeetingUrl && (
        <LocationBlock>
          <div>Location:</div>
          <div>
            <p>{zoomMeetingUrl}</p>
          </div>
        </LocationBlock>
      )}
      {is.not.empty(specializationCredits) && (
        <SpecializationCreditBlock>
          <span>
            <SpecializationCreditSVG
              height="28px"
              width="28px"
            />
          </span>
          <span><strong>Specialization Credit:</strong></span>
          <span>
            {renderSpecializationCredits(specializationCredits)}
          </span>
        </SpecializationCreditBlock>
      )}
      {is.not.empty(prices) && (
        <div>
          <PricingHeading>Pricing</PricingHeading>
          <PriceList
            id="cleDetailsPricing"
          >
            {priceList}
          </PriceList>
        </div>
      )}
      <Divider />
      <Provider store={store}>
        <Register
          contentGuid={contentGuid}
          addOnsActive={addOnsActive}
          attendanceTypeOptions={attendanceTypeOptions}
          disableAddToCart={disableAddToCart}
          isRegister={isRegister}
          isRegisterType={isRegisterType}
          isAddonComplete={isAddonComplete}
          onFreePurchaseComplete={onPurchaseComplete}
          updateShowCart={updateShowCart}
          isMeetingAvailableUntilDate={isMeetingAvailableUntilDate}
          isMeetingEnded={isMeetingEnded}
        />
      </Provider>
    </DetailsContainerCard>
  );
};

Details.displayName = 'Details';

Details.propTypes = {
  addOnsActive: PropTypes.arrayOf(PropTypes.string),
  brochureLink: PropTypes.string,
  contentGuid: PropTypes.string,
  zoomMeetingUrl: PropTypes.string,
  date: PropTypes.string,
  isMeetingAvailableUntilDate: PropTypes.bool.isRequired,
  endTime: PropTypes.string,
  startTime: PropTypes.string,
  prices: PropTypes.arrayOf(Object),
  location: PropTypes.shape({
    displayName: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
    line4: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  // eslint-disable-next-line
  attendanceTypeOptions: PropTypes.object,
  disableAddToCart: PropTypes.bool,
  isRegister: PropTypes.bool,
  isRegisterType: PropTypes.bool,
  isAddonComplete: PropTypes.bool,
  onPurchaseComplete: PropTypes.func.isRequired,
  updateShowCart: PropTypes.func,
  specializationCredits: PropTypes.arrayOf(Object),
  credits: PropTypes.arrayOf(Object),
  isMeetingEnded: PropTypes.bool.isRequired,
};

Details.defaultProps = {
  addOnsActive: [],
  brochureLink: '',
  contentGuid: '',
  zoomMeetingUrl: '',
  date: '',
  endTime: '',
  startTime: '',
  prices: [],
  location: {
    displayName: '',
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    city: '',
    state: '',
    postalCode: '',
  },
  attendanceTypeOptions: {},
  disableAddToCart: false,
  isRegister: false,
  isRegisterType: true,
  isAddonComplete: true,
  updateShowCart: () => { },
  specializationCredits: [],
  credits: [],
};

export default Details;
