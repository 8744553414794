import styled from 'styled-components';

const BodyDiv = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
`;

BodyDiv.displayName = 'BodyDiv';

export default BodyDiv;
