import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import BrochureDownloadContainer from './components/BrochureDownloadContainer';

const BrochureDownload = ({ brochureLink }) => (
  <BrochureDownloadContainer>
    <a
      href={brochureLink}
      id="cleDetailsBrochureDownload"
      download
    >
      <span>Download Course Brochure</span>
    </a>
    <FontAwesomeIcon
      icon={faDownload}
      aria-hidden="true"
    />
  </BrochureDownloadContainer>
);

BrochureDownload.displayName = 'BrochureDownload';

BrochureDownload.propTypes = {
  brochureLink: PropTypes.string,
};

BrochureDownload.defaultProps = {
  brochureLink: '',
};

export default BrochureDownload;
