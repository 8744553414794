import styled from 'styled-components';

const RadioButtonGroup = styled.ul`
  padding: 10px;
  li {
    margin-bottom: 5px;
  }
  
  label {
    font-size: 14px !important;
    font-weight: 400 !important;
    letter-spacing: inherit !important;
  }
`;

RadioButtonGroup.displayName = 'RadioButtonGroup';

export default RadioButtonGroup;
