import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveMarketingPreferencesInfo } from '@redux/modules/marketingPreferencesInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import MarketingPreferencesInfoForm from './components/MarketingPreferencesInfoForm';

class MarketingPreferencesInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formValues) {
    this.props.dispatch(saveMarketingPreferencesInfo(formValues));
  }

  render() {
    const {
      loading,
      marketingPreferencesInfo,
      links,
      linksSectionTitle,
      copy,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormContainer>
        {loading && <LoadingOverlay />}
        <MarketingPreferencesInfoForm
          initialValues={marketingPreferencesInfo}
          onSubmit={this.submit}
          loading={loading}
          hasSaveError={!!error}
          links={links}
          linksSectionTitle={linksSectionTitle}
          copy={copy}
        />
        {error && (
          <ErrorMessage>
            <span>{error}</span>
          </ErrorMessage>
        )}
      </FormContainer>
    );
  }
}

MarketingPreferencesInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  marketingPreferencesInfo: PropTypes.shape({
    CleMarketing: PropTypes.bool,
    PracticeManagementDiscountPrograms: PropTypes.bool,
    TravelDiscountPrograms: PropTypes.bool,
  }).isRequired,
  links: PropTypes.arrayOf(PropTypes.object),
  linksSectionTitle: PropTypes.string,
  copy: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
};

MarketingPreferencesInfoContent.defaultProps = {
  error: '',
  loading: false,
  links: [],
  linksSectionTitle: '',
  copy: '',
};

const mapStateToProps = ({ marketingPreferencesInfo }) => ({
  error: marketingPreferencesInfo.error,
  loading: marketingPreferencesInfo.loading,
  marketingPreferencesInfo,
});

MarketingPreferencesInfoContent.displayName = 'MarketingPreferencesInfoContent';

export default connect(mapStateToProps)(MarketingPreferencesInfoContent);
