import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, colors, typography } from '@style-guide/config';
import ScreenReaderText from '@components/ScreenReaderText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faSquareXTwitter,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';

const SocialLinksContainer = styled.ul`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-bottom: 20px;

  li:not(:last-child) {
    margin-right: 15px;
  }

  @media (min-width: ${breakpoints.l}) {
    height: 50px;
    margin-bottom: 0;
  }
`;

SocialLinksContainer.displayName = 'SocialLinksContainer';

const SocialLink = styled.a`
  color: ${colors.white};

  &:hover {
    color: ${colors.gray};
  }

  svg {
    font-size: ${rem('48px', typography.baseFontSize)};
  }

  @media (min-width: ${breakpoints.l}) {
    svg {
      font-size: ${rem('28px', typography.baseFontSize)};
    }
  }
`;

SocialLink.displayName = 'SocialLink';

const SocialLinks = ({ links = [] }) => {
  const SOCIAL_ICON_MAP = {
    facebook: faFacebookSquare,
    linkedin: faLinkedin,
    twitter: faSquareXTwitter,
    instagram: faInstagram,
    youtube: faYoutubeSquare,
  };

  const socialNavItems = links.map(link => (
    <li
      key={link.id}
    >
      <SocialLink
        href={link.href}
        title={link.title}
        target="_blank"
        id={`footerSocialLink${link.id}`}
      >
        <FontAwesomeIcon
          icon={SOCIAL_ICON_MAP[link.type]}
        />
        <ScreenReaderText>OSBA {link.type}</ScreenReaderText>
      </SocialLink>
    </li>
  ));

  return (
    <SocialLinksContainer>
      {socialNavItems}
    </SocialLinksContainer>
  );
};

SocialLinks.propTypes = {
  links: PropTypes.arrayOf(Object),
};

SocialLinks.displayName = 'SocialLinks';

export default SocialLinks;
