import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

import TabButtonWrapper from './TabButtonWrapper';

const DesktopButtons = styled.div`
  display: none;

  @media(min-width: ${breakpoints.l}) {
    display: flex;
    flex-direction: row;
  }

  ${/* sc-selector */TabButtonWrapper}:not(:last-of-type) {
    margin-right: 15px;
  }
`;

DesktopButtons.displayName = 'DesktopButtons';

export default DesktopButtons;
