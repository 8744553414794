import React from 'react';
import { Provider } from 'react-redux';
import { getLawSchoolInfo } from '@redux/modules/lawSchoolInfo';
import Store from '@redux';

import LawSchoolInfoContent from './components/LawSchoolInfoContent';

class LawSchoolInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getLawSchoolInfo());
  }

  render() {
    return (
      <Provider store={this.store}>
        <LawSchoolInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

LawSchoolInfo.displayName = 'LawSchoolInfo';

export default LawSchoolInfo;
