import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveEducationInfo } from '@redux/modules/educationInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import EducationInfoForm from './components/EducationInfoForm';

class EducationInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formValues) {
    this.props.dispatch(saveEducationInfo(formValues));
  }

  render() {
    const {
      loading,
      educationInfo,
      title,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          title={title}
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <EducationInfoForm
                initialValues={educationInfo}
                onSubmit={this.submit}
                loading={loading}
                hasSaveError={!!error}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
        />
      </FormAccordionContainer>
    );
  }
}

EducationInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  educationInfo: PropTypes.shape({
    LineItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
};

EducationInfoContent.defaultProps = {
  error: '',
  loading: false,
  title: 'Education Information',
};

const mapStateToProps = ({ educationInfo }) => ({
  error: educationInfo.error,
  loading: educationInfo.loading,
  educationInfo,
});

EducationInfoContent.displayName = 'EducationInfoContent';

export default connect(mapStateToProps)(EducationInfoContent);
