import React from 'react';
import PropTypes from 'prop-types';
import { UnmountClosed } from 'react-collapse';
import { presets } from 'react-motion';

import Arrow from './components/Arrow';
import CollapseToggle from './components/CollapseToggle';
import FilterGroupWrapper from './components/FilterGroupWrapper';

class SearchFiltersToggle extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpened: false,
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened,
    }));
  }

  render() {
    return (
      <React.Fragment>
        <CollapseToggle
          onClick={this.toggleCollapse}
        >
          <span>Filters</span>
          <Arrow
            isOpened={this.state.isOpened}
          />
        </CollapseToggle>
        <UnmountClosed
          isOpened={this.state.isOpened}
          presets={presets.gentle}
        >
          <FilterGroupWrapper>
            {this.props.children}
          </FilterGroupWrapper>
        </UnmountClosed>
      </React.Fragment>
    );
  }
}

SearchFiltersToggle.propTypes = {
  children: PropTypes.element.isRequired,
};

SearchFiltersToggle.displayName = 'SearchFiltersToggle';

export default SearchFiltersToggle;
