import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  padding: 15px 50px;
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
