import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chunk from 'lodash/chunk';

import { breakpoints } from '@style-guide/config';
import Checkbox from '@style-guide/components/Checkbox';

import { reduxInputPropType } from '@services/PropTypes';

const CheckboxGroupContainer = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    width: 100%;
  }

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

CheckboxGroupContainer.displayName = 'CheckboxGroupContainer';

const CheckboxGroupChunk = styled.ul`
  li {
    margin-bottom: 5px;
  }
`;

CheckboxGroupChunk.displayName = 'CheckboxGroupChunk';

const ReduxFormCheckboxGroup = ({
  input,
  options = [],
  columns = 1,
  ...restProps
}) => {
  const checkBoxes = options.map((option, index) => {
    const isObject = typeof option !== 'string';

    return (
      <li
        key={isObject ? option.Name : option}
      >
        <Checkbox
          id={`${input.name}Checkbox${index}`}
          name={`${input.name}[${index}]`}
          label={isObject ? option.Name : option}
          value={isObject ? option.Id : option}
          isChecked={isObject ? input.value[index].Selected : input.value.indexOf(option) !== -1}
          onChange={(event) => {
            const newValue = [...input.value];

            if (isObject) {
              const modifiedValue = JSON.parse(JSON.stringify(newValue[index]));
              modifiedValue.Selected = event.target.checked;
              newValue.splice(index, 1, modifiedValue);
            } else if (event.target.checked) {
              newValue.push(option);
              newValue.sort();
            } else {
              newValue.splice(newValue.indexOf(option), 1);
            }

            return input.onChange(newValue);
          }}
          {...restProps}
        />
      </li>
    );
  });

  const checkboxChunks = chunk(checkBoxes, (checkBoxes.length / columns) + 1).map(checkboxChunk => (
    <CheckboxGroupChunk
      key={checkboxChunk[0].key}
    >
      {checkboxChunk}
    </CheckboxGroupChunk>
  ));

  return (
    <CheckboxGroupContainer>
      {checkboxChunks}
    </CheckboxGroupContainer>
  );
};

ReduxFormCheckboxGroup.propTypes = {
  input: reduxInputPropType.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ])),
  columns: PropTypes.number,
};

ReduxFormCheckboxGroup.displayName = 'ReduxFormCheckboxGroup';

export default ReduxFormCheckboxGroup;
