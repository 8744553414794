import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { typography } from '@style-guide/config';

const Title = styled.h4`
  font-size: ${rem('18px', typography.baseFontSize)};
`;

Title.displayName = 'Title';

export default Title;
