import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import Button from '@style-guide/components/Button';
import ComponentDiv from './components/ComponentDiv';
import CaseResultTr from './components/CaseResultTr';
import ListParagraph from '../../../EBarHome/components/EBarHome/EBarHomeContent/components/Shared/components/ListParagraph';
import Name from './components/Name';

const ActionButton = styled.button`
  border: none;
  cursor: pointer;
`;

ActionButton.displayName = 'ActionButton';

const ViewColumn = styled.td`
  background-color: #FFFFFF;
`;

ViewColumn.displayName = 'ViewColumn';

const ImageStyle = {
  height: '24px',
  width: '24px',
};

const CaseResult = ({
  initialValues = {
    summary: '',
    caseName: '',
    webCitation: '',
    casemakerLink: '',
    pDFLink: '',
    dateDecidedString: '',
    activeDateString: '',
    dateAddedString: '',
    authoredBy: '',
  },
  viewId = '',
  onClickViewButton,
  onClickEditButton,
  onClickCloseCancel,
  onClickDelete,
  getAreaOfLaw,
  getCourt,
}) => (
  <React.Fragment>
    <CaseResultTr
      $isActiveDateNull={!initialValues.activeDateString}
    >
      <td>{initialValues.title}</td>
      <td>{initialValues.caseName}</td>
      <td>{initialValues.practiceId > 0 ? getAreaOfLaw(initialValues.practiceId) : ''}
      </td>
      <td>{initialValues.authoredBy}</td>
      <td>{!initialValues.activeDateString ? '' : moment(initialValues.activeDateString).format('MM/DD/YYYY')}</td>
      <td>
        <ActionButton
          title="Edit"
          onClick={() => onClickEditButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Edit.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="View"
          onClick={() => onClickViewButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Detail.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="Delete"
          onClick={() => onClickDelete(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Delete.png" alt="" style={ImageStyle} />
        </ActionButton>
      </td>
    </CaseResultTr>
    {viewId && viewId === initialValues.contentGuidString && (
      <tr>
        <ViewColumn colSpan="6">
          <ComponentDiv>
            <Name>{initialValues.title}</Name>
            <p>{initialValues.caseName}</p>
            {/* eslint-disable-next-line react/no-danger */}
            <ListParagraph dangerouslySetInnerHTML={{ __html: initialValues.summary }} />
            <p><span>Web Citation:</span> {initialValues.webCitation}</p>
            <p><span>Decisis Link:</span> {initialValues.casemakerLink}</p>
            <p><span>PDF:</span> {initialValues.pDFLink}</p>
            <p><span>Practice Area:</span> {initialValues.practiceId > 0 ? getAreaOfLaw(initialValues.practiceId) : ''}
            </p>
            <p><span>Court:</span> {initialValues.courtId > 0 ? getCourt(initialValues.courtId) : ''}
            </p>
            <p><span>Date Decided:</span> {!initialValues.dateDecidedString ? '' : moment(initialValues.dateDecidedString).format('MM/DD/YYYY')}</p>
            <p><span>Active Date:</span> {!initialValues.activeDateString ? '' : moment(initialValues.activeDateString).format('MM/DD/YYYY')}</p>
            <p><span>Author:</span> {initialValues.authoredBy}</p>
            <p><span>Date Added:</span> {!initialValues.dateAddedString ? '' : moment(initialValues.dateAddedString).format('MM/DD/YYYY')}</p>
            <Button
              onClick={() => onClickCloseCancel()}
            >
              Close
            </Button>
          </ComponentDiv>
        </ViewColumn>
      </tr>
    )}
  </React.Fragment>
);


CaseResult.displayName = 'CaseResult';

CaseResult.propTypes = {
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    caseName: PropTypes.string,
    webCitation: PropTypes.string,
    casemakerLink: PropTypes.string,
    pDFLink: PropTypes.string,
    dateDecidedString: PropTypes.string,
    activeDateString: PropTypes.string,
    dateAddedString: PropTypes.string,
    authoredBy: PropTypes.string,
    practiceId: PropTypes.number,
    courtId: PropTypes.number,
  }),
  viewId: PropTypes.string,
  onClickViewButton: PropTypes.func.isRequired,
  onClickEditButton: PropTypes.func.isRequired,
  onClickCloseCancel: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  getAreaOfLaw: PropTypes.func.isRequired,
  getCourt: PropTypes.func.isRequired,
};

export default CaseResult;
