import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileArchive,
  faFile,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileWord,
} from '@fortawesome/free-solid-svg-icons';

import {
  colors,
  typography,
  breakpoints,
} from '@style-guide/config';

import LinkButton from '@style-guide/components/LinkButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media(min-width: ${breakpoints.s}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${colors.red};
  margin-right: 16px;
  font-size: ${rem('24px', typography.baseFontSize)};
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const FileSize = styled.span`
  font-style: italic;
`;

const StyledLinkButton = styled(LinkButton)`
  margin-top: 15px;

  @media(min-width: ${breakpoints.s}) {
    margin-top: 0;
  }
`;

const CourseMaterialsItem = ({
  fileName,
  fileType,
  url,
  sizeFormattedString,
}) => {
  const IconToRender = () => {
    if (fileType === 'application/pdf') {
      return <StyledIcon icon={faFilePdf} />;
    }

    if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      return <StyledIcon icon={faFileWord} />;
    }

    if (fileType === 'application/vnd.ms-excel') {
      return <StyledIcon icon={faFileExcel} />;
    }

    if (fileType === 'image/jpeg' || fileType === 'image/png') {
      return <StyledIcon icon={faFileImage} />;
    }

    if (fileType === 'application/zip') {
      return <StyledIcon icon={faFileArchive} />;
    }

    return <StyledIcon icon={faFile} />;
  };

  return (
    <Wrapper>
      <FlexWrapper>
        {IconToRender()}
        <span>{fileName} <FileSize>({sizeFormattedString})</FileSize></span>
      </FlexWrapper>
      <StyledLinkButton
        href={url}
        download
      >
        Download
      </StyledLinkButton>
    </Wrapper>
  );
};

CourseMaterialsItem.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  sizeFormattedString: PropTypes.string.isRequired,
};

CourseMaterialsItem.displayName = 'CourseMaterialsItem';

export default CourseMaterialsItem;
