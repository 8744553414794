import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const InfoLineItem = styled.span`
  display: block;
  margin-top: ${props => (props.hasTopMargin ? '8px' : '0')};

  @media (min-width: ${breakpoints.m}) {
    display: inline;
  }
`;

InfoLineItem.displayName = 'InfoLineItem';

export default InfoLineItem;
