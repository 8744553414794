import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PopupTooltip from '@components/PopupTooltip/PopupTooltip';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  XIcon,
  EmailIcon,
} from 'react-share';

import CopyUrlSVG from '@style-guide/svg/CopyUrlSVG';

class SocialShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
    };

    this.copy = this.copy.bind(this);
    this.changedState = this.changedState.bind(this);
  }

/* eslint-disable */
  copy(shareUrl) {
    const el = document.createElement('input');
    el.value = shareUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
  /* eslint-enable */

  changedState(showVal) {
    this.setState({
      showTooltip: showVal,
    });
  }

  render() {
    const {
      shareUrl,
      articleTitle,
    } = this.props;

    const ShareItem = styled.li`
      cursor: pointer;
    `;

    ShareItem.displayName = 'ShareItem';

    const ShareLabel = styled.span`
      font-weight: 500;
      margin-right: 10px;
    `;

    ShareLabel.displayName = 'ShareLabel';

    const SocialShareContainer = styled.ul`
      align-items: center;
      display: flex;
      justify-content: flex-start;
    
      > *:not(:last-child) {
        margin-right: 5px;
      }
    `;

    SocialShareContainer.displayName = 'SocialShareContainer';


    /* eslint-disable */
    const hostname = window.location.protocol + '//' + window.location.hostname;
    /* eslint-enable */
    return (
      <SocialShareContainer>
        <li>
          <ShareLabel>Share</ShareLabel>
        </li>
        <ShareItem>
          <LinkedinShareButton
            title={articleTitle}
            url={hostname + shareUrl}
          >
            <LinkedinIcon
              size={32}
              round
            />
          </LinkedinShareButton>
        </ShareItem>
        <ShareItem>
          <FacebookShareButton
            url={hostname + shareUrl}
          >
            <FacebookIcon
              size={32}
              round
            />
          </FacebookShareButton>
        </ShareItem>
        <ShareItem>
          <TwitterShareButton
            title={articleTitle}
            url={hostname + shareUrl}
          >
            <XIcon
              size={32}
              round
            />
          </TwitterShareButton>
        </ShareItem>
        <ShareItem>
          <EmailShareButton
            subject={articleTitle}
            url={hostname + shareUrl}
          >
            <EmailIcon
              size={32}
              round
            />
          </EmailShareButton>
        </ShareItem>
        <ShareItem>
          <button
            type="button"
            style={{ padding: '0px', position: 'relative' }}
            onClick={() => {
              this.copy(hostname + shareUrl);
              this.changedState(true);
              setTimeout(() => { this.changedState(false); }, 1000);
            }}
          >
            {this.state.showTooltip && <PopupTooltip bodyContent="Copied" />}
            <CopyUrlSVG height="40px" color="#a41f35" />
          </button>
        </ShareItem>
      </SocialShareContainer>
    );
  }
}

SocialShare.propTypes = {
  shareUrl: PropTypes.string,
  articleTitle: PropTypes.string,
};

SocialShare.defaultProps = {
  shareUrl: window.location.pathname || document.location.pathname,
  articleTitle: document.title,
};

SocialShare.displayName = 'SocialShare';

export default SocialShare;
