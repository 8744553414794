import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { typography } from '@style-guide/config';
import Link from '@style-guide/mixins/Link';

import Divider from '@components/Divider';

const RelatedCLEsContainer = styled.div`
  margin: 25px 0;

  h3 {
    font-size: ${rem('18px', typography.baseFontSize)};
  }

  ul {
    margin: 20px 0;
  }
`;

RelatedCLEsContainer.displayName = 'RelatedCLEsContainer';

const RelatedCLE = styled.li`
  margin: 10px 0;
`;

RelatedCLE.displayName = 'RelatedCLE';

const CLELink = styled.a`
  ${Link({ type: 'red' })}

  line-height: 1.5em;
`;

CLELink.displayName = 'CLELink';

const RelatedCLEs = ({ CLEs }) => {
  const instances = CLEs.map(({
    title,
    link,
    id,
  }, index) => (
    <RelatedCLE
      key={id}
      id={`relatedCLE${index}`}
    >
      <CLELink
        href={link}
      >
        {title}
      </CLELink>
    </RelatedCLE>
  ));

  return (
    <RelatedCLEsContainer>
      <h3>Related CLEs</h3>
      <Divider />
      <ul>
        {instances}
      </ul>
    </RelatedCLEsContainer>
  );
};

RelatedCLEs.displayName = 'RelatedCLEs';

RelatedCLEs.propTypes = {
  CLEs: PropTypes.arrayOf(PropTypes.object),
};

RelatedCLEs.defaultProps = {
  CLEs: [],
};

export default RelatedCLEs;
