import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import includes from 'lodash/includes';

import { colors } from '@style-guide/config';
import Checkbox from '@style-guide/components/Checkbox';

import CLEContentCard from '@components/CLEContentCard';

const AddOnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: ${props => (props.hasBottomBorder
    ?
    `1px solid ${colors.lightGray}`
    :
    '0'
  )};
  margin-bottom: 15px;
  padding-bottom: ${props => (props.hasBottomBorder
    ?
    '15px'
    :
    '0'
  )}
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AddOns = ({
  addOns,
  addOnsBundle,
  updateAddOnsActiveState,
  addOnsActive,
}) => (
  <CLEContentCard>
    <h2>Add-On(s)</h2>
    {addOnsBundle.map((addOn, index, collection) => (
      <AddOnWrapper
        hasBottomBorder={(index + 1) < collection.length}
        key={addOn.id}
      >
        <CheckboxWrapper>
          <Checkbox
            type="checkbox"
            value={addOn.id}
            isChecked
            disabled
            name="addons"
            label={addOn.name}
            id={addOn.id}
            onChange={(event, name, val) => updateAddOnsActiveState(val)}
          />
        </CheckboxWrapper>
      </AddOnWrapper>
    ))}
    {addOns.map((addOn, index, collection) => (
      <AddOnWrapper
        hasBottomBorder={(index + 1) < collection.length}
        key={addOn.id}
      >
        <CheckboxWrapper>
          <Checkbox
            type="checkbox"
            value={addOn.id}
            isChecked={includes(addOnsActive, addOn.id)}
            name="addons"
            label={addOn.name}
            id={addOn.id}
            onChange={(event, name, val) => updateAddOnsActiveState(val)}
          />
        </CheckboxWrapper>
        {addOn.price.amount}
      </AddOnWrapper>
    ))}
  </CLEContentCard>
);

AddOns.displayName = 'AddOns';

AddOns.propTypes = {
  addOns: PropTypes.arrayOf(Object),
  addOnsBundle: PropTypes.arrayOf(Object),
  updateAddOnsActiveState: PropTypes.func.isRequired,
  addOnsActive: PropTypes.arrayOf(PropTypes.string),
};

AddOns.defaultProps = {
  addOns: [],
  addOnsBundle: [],
  addOnsActive: [],
};

export default AddOns;
