import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints, colors } from '@style-guide/config';

import Link from '@style-guide/mixins/Link';

import TextArea from '@style-guide/components/TextArea';

import FormFieldSection from '@components/FormFieldSection';

const IntroWrapper = styled.div`
  background: ${colors.white};
  margin: 30px 15px;

  @media(min-width: ${breakpoints.l}) {
    margin: 30px 45px;
  }
`;

IntroWrapper.displayName = 'IntroWrapper';

const DirectoryLink = styled.div`
  margin-top: 15px;

  a {
    ${Link({ type: 'red' })}
  }
`;

DirectoryLink.displayName = 'DirectoryLink';

const EditDirectoryIntro = ({
  copy = '',
  directoryLink,
  heading = '',
}) => (
  <IntroWrapper>
    <FormFieldSection
      heading={heading}
    >
      <TextArea
        hasTopMargin={false}
        htmlString={copy}
      />
      <DirectoryLink>
        <span>To view your live directory profile click <a href={directoryLink}>here</a></span>
      </DirectoryLink>
    </FormFieldSection>
  </IntroWrapper>
);

EditDirectoryIntro.propTypes = {
  copy: PropTypes.string,
  directoryLink: PropTypes.string.isRequired,
  heading: PropTypes.string,
};

EditDirectoryIntro.displayName = 'EditDirectoryIntro';

export default EditDirectoryIntro;
