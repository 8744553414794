import React from 'react';
import is from 'is_js';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

import { colors } from '@style-guide/config';

import { Checkbox } from '@style-guide/components';

import ListItem from './ListItem';
import ListWrapper from './ListWrapper';

const Menu = styled.div`
  border-bottom: 1px solid ${colors.lightGray};
  border-top: 1px solid ${colors.lightGray};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px auto;
  padding: 15px 0px;
  width: 100%;
`;

Menu.displayName = 'Menu';

const MenuButton = styled.button`
  background-color: transparent;
  border: 0;
  color: ${props => (props.$active ? colors.red : colors.black)};
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
`;

MenuButton.displayName = 'MenuButton';

const NoResultsMessage = styled.div`
  margin-bottom: 12px;
  padding: 0px 5px;
  font-weight: 500;
`;

NoResultsMessage.displayName = 'NoResultsMessage';

const menuGroups = [
  {
    id: 'AG',
    label: 'A - G',
  },
  {
    id: 'HN',
    label: 'H - N',
  },
  {
    id: 'OU',
    label: 'O - U',
  },
  {
    id: 'VZ',
    label: 'V - Z',
  },
];

class PracticeAreas extends React.Component {
  constructor() {
    super();

    this.state = {
      keyToRender: 'AG',
    };

    this.updateKeyToRender = this.updateKeyToRender.bind(this);
  }

  updateKeyToRender(key) {
    this.setState({
      keyToRender: key,
    });
  }

  render() {
    const {
      updateDisplayUserPracticeAreas,
      displayUserPracticeAreas,
      searchPageUrl,
    } = this.props;

    const practiceAreasToRender = this.props.displayUserPracticeAreas
      ?
      this.props.userPracticeAreasFormatted[this.state.keyToRender]
      :
      this.props.practiceAreasFormatted[this.state.keyToRender];

    const renderGroup = (group) => {
      if (is.not.empty(group)) {
        return group.map(item => (
          <ListItem key={item.filterId}>
            <a
              href={`${searchPageUrl}?sort=recentlyPublished&search={"dateRange":{"end":null,"start":null},"keyword":"","practiceAreas":[{"filterName":"${item.filterName}","filterId":"${item.filterId}"}],"otherResources":[],"publications":[],"resourceTypes":[]}`}
            >
              <span>{item.filterName}</span>
              <FontAwesomeIcon
                icon={faCaretRight}
                style={{
                  color: colors.red,
                }}
              />
            </a>
          </ListItem>
        ));
      }

      return <NoResultsMessage>No results</NoResultsMessage>;
    };

    return (
      <ListWrapper>
        <Checkbox
          label="Just Show My Practice Areas"
          value="justMyPracticeAreas"
          name="justMyPracticeAreas"
          id="justMyPracticeAreas"
          isChecked={displayUserPracticeAreas}
          onChange={updateDisplayUserPracticeAreas}
          style={{
            justifyContent: 'flex-start',
          }}
        />
        <Menu>
          {menuGroups.map(group => (
            <MenuButton
              key={group.id}
              $active={this.state.keyToRender === group.id}
              onClick={() => this.updateKeyToRender(group.id)}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  this.updateKeyToRender(group.id);
                }
              }}
              id={`filterPracticeAreasMenuOption${group.id}`}
            >
              {group.label}
            </MenuButton>
          ))}
        </Menu>
        {renderGroup(practiceAreasToRender)}
      </ListWrapper>
    );
  }
}

PracticeAreas.propTypes = {
  displayUserPracticeAreas: PropTypes.bool.isRequired,
  updateDisplayUserPracticeAreas: PropTypes.func.isRequired,
  practiceAreasFormatted: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchPageUrl: PropTypes.string.isRequired,
  userPracticeAreasFormatted: PropTypes.arrayOf(PropTypes.object),
};

PracticeAreas.defaultProps = {
  userPracticeAreasFormatted: [],
};

PracticeAreas.displayName = 'PracticeAreas';

export default PracticeAreas;
