import styled from 'styled-components';

import DataItem from './DataItem';

const SpecializationCreditWrapper = styled(DataItem)`
  flex-direction: row;
  align-items: center;
`;

SpecializationCreditWrapper.displayName = 'SpecializationCreditWrapper';

export default SpecializationCreditWrapper;
