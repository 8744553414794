import PropTypes from 'prop-types';

const reduxInputPropType = PropTypes.shape({
  checked: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.object,
  ]),
});

const reduxMetaPropType = PropTypes.shape({
  active: PropTypes.bool,
  autofilled: PropTypes.bool,
  asyncValidating: PropTypes.bool,
  dirty: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.string,
  form: PropTypes.string,
  initial: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.object,
  ]),
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  submitFailed: PropTypes.bool,
  touched: PropTypes.bool,
  valid: PropTypes.bool,
  visited: PropTypes.bool,
  warning: PropTypes.string,
});

const paymentFormDataPropType = PropTypes.shape({
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  cardNumber: PropTypes.string,
  cardType: PropTypes.number,
  city: PropTypes.string,
  expirationMonth: PropTypes.string,
  expirationYear: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  securityCode: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
});

const payPalButtonDataPropType = PropTypes.shape({
  intent: PropTypes.string,
  orderID: PropTypes.string,
  payerID: PropTypes.string,
  paymentToken: PropTypes.string,
  returnUrl: PropTypes.string,
});

const courseFormat = PropTypes.shape({
  courseFormatType: PropTypes.string,
  name: PropTypes.string,
  sourceId: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  type: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
});

const cleSearchFiltersOptions = PropTypes.shape({
  courseFormatFilters: PropTypes.arrayOf(Object),
  locationFilters: PropTypes.arrayOf(Object),
  practiceAreaFilters: PropTypes.arrayOf(Object),
  creditHoursFilters: PropTypes.arrayOf(Object),
});

export {
  cleSearchFiltersOptions,
  courseFormat,
  reduxInputPropType,
  reduxMetaPropType,
  paymentFormDataPropType,
  payPalButtonDataPropType,
};
