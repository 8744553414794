import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import DatePicker from '@style-guide/components/DatePicker';
import Select from '@style-guide/components/Select';
import Input from '@style-guide/components/Input';
import moment from 'moment';

import {
  cleVideosCreate,
  cleVideosUpdate,
  cleVideosDelete,
} from '@services/CLEVideos';

import LoadingOverlay from '@components/LoadingOverlay';
import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import { Provider } from 'react-redux';
import Button from '@style-guide/components/Button';
import Store from '@redux';
import PopUpModal from './components/PopUpModal/PopUpModal';
import VideoForm from './components/VideoForm/VideoForm';

import BlockWrapper from './components/BlockWrapper';
import { VideoTable, ColumnHeaders } from './components/VideoTable';
import CLEVideosResultDiv from './components/CLEVideosResultDiv';
import CLEVideosResult from './components/CLEVideosResult/CLEVideosResult';
import ButtonWrapper from './components/ButtonWrapper';
import DatePickerWrapper from './components/DatePickerWrapper';
import SearchWrapper from './components/SearchWrapper';
import SearchDiv from './components/SearchDiv';
import HeaderDiv from './components/HeaderDiv';
import InnerDiv from './components/InnerDiv';
import Title from './components/Title';
import Wrapper from './components/Wrapper';
import ModalBody from './components/ModalBody';
import MessageModalBody from './components/MessageModalBody';


/**
# CLE Videos CTA
A CTA containing list of links to CLE Video items.
 */
class CLEVideosCTA extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cleVideosListsState: this.props.cleVideosLists,
      headingState: this.props.heading,
      create: false,
      edit: false,
      viewId: '',
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
      showValidationPopUp: false,
      validationPopUpTitle: '',
      searchDescriptionId: '',
      searchProductId: 0,
      startDateState: moment(this.props.startDate),
      endDateState: moment(this.props.endDate),
      meetingTitle: '',
      descriptionId: '',
      startDate: '',
      endDate: '',
      fileName: '',
      selectedSearchOption: '',
    };

    this.store = Store.get();

    this.calculateDuration = this.calculateDuration.bind(this);
    this.handleSearchDropdownChange = this.handleSearchDropdownChange.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onChangeDescriptionId = this.onChangeDescriptionId.bind(this);
    this.onChangeProductId = this.onChangeProductId.bind(this);
    this.onChangeStartDate = this.onChangeStartDate.bind(this);
    this.onChangeEndDate = this.onChangeEndDate.bind(this);
    this.onClickViewButton = this.onClickViewButton.bind(this);
    this.onClickEditButton = this.onClickEditButton.bind(this);
    this.onClickCreateLink = this.onClickCreateLink.bind(this);
    this.onClickCloseCancel = this.onClickCloseCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.showMessage = this.showMessage.bind(this);
    this.updateMeetingDetails = this.updateMeetingDetails.bind(this);
    this.updateFileName = this.updateFileName.bind(this);
  }

  /* eslint-disable */
  calculateDuration(time) {
    var a = time.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    return seconds;
  }
  /* eslint-enable */

  handleSearchDropdownChange(event) {
    // get the node of the selected option
    const optionNode = event.target.options[event.target.selectedIndex];

    // the filterName is the text of the option element
    // and the value is the value that comes across
    this.setState({
      selectedSearchOption: optionNode.value,
    });
  }

  onClickSearch() {
    this.setState({
      loading: true,
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });

    if (this.state.selectedSearchOption === '1') {
      fetch(`/CLEManageVideosCallToActionBlock/SearchByDesc?descriptionId=${this.state.searchDescriptionId}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then((data) => {
          this.setState({
            viewId: '',
            create: false,
            edit: false,
            cleVideosListsState: data,
            formValues: undefined,
            loading: false,
            showPopUp: false,
            deleteGuid: '',
            showMessagePopUp: false,
            messagePopUpTitle: '',
          });
        });
    } else if (this.state.selectedSearchOption === '2') {
      fetch(`/CLEManageVideosCallToActionBlock/SearchById?productId=${this.state.searchProductId}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then((data) => {
          this.setState({
            viewId: '',
            create: false,
            edit: false,
            cleVideosListsState: data,
            formValues: undefined,
            loading: false,
            showPopUp: false,
            deleteGuid: '',
            showMessagePopUp: false,
            messagePopUpTitle: '',
          });
        });
    } else {
      const formattedStartDate = moment(this.state.startDateState).format('MM/DD/YYYY');
      const formattedEndDate = moment(this.state.endDateState).format('MM/DD/YYYY');
      fetch(`/CLEManageVideosCallToActionBlock/Search?startDate=${formattedStartDate}&endDate=${formattedEndDate}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then((data) => {
          this.setState({
            viewId: '',
            create: false,
            edit: false,
            cleVideosListsState: data,
            formValues: undefined,
            loading: false,
            showPopUp: false,
            deleteGuid: '',
            showMessagePopUp: false,
            messagePopUpTitle: '',
          });
        });
    }
  }

  onChangeDescriptionId(e) {
    this.setState({
      searchDescriptionId: e.target.value,
    });
  }

  onChangeProductId(e) {
    this.setState({
      searchProductId: e.target.value,
    });
  }

  onChangeStartDate(value) {
    this.setState({
      startDateState: value,
    });
  }

  onChangeEndDate(value) {
    this.setState({
      endDateState: value,
    });
  }

  onClickCreateLink() {
    this.setState({
      viewId: '',
      edit: false,
      formValues: undefined,
      create: true,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onClickViewButton(viewContentId) {
    this.setState({
      viewId: viewContentId,
      create: false,
      edit: false,
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onClickCloseCancel() {
    this.setState({
      viewId: '',
      create: false,
      edit: false,
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onClickEditButton(viewContentId) {
    this.setState({
      viewId: '',
      create: false,
      edit: true,
      showPopUp: false,
      showMessagePopUp: false,
      deleteGuid: '',
      messagePopUpTitle: '',
      formValues: (is.not.null(this.state.cleVideosListsState)
          && is.not.undefined(this.state.cleVideosListsState)
          && this.state.cleVideosListsState.length > 0) ?
        this.state.cleVideosListsState.filter(item =>
          item.contentGuidString === viewContentId)[0] :
        undefined,
    });

    document.getElementById('title').scrollIntoView();
  }

  onSubmit(data) {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    this.setState({
      viewId: '',
      loading: true,
    });
    const finalPostData = {
      ...data,
      meetingTitle: this.state.meetingTitle,
      descriptionId: this.state.descriptionId,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      fileName: this.state.fileName,
    };

    /* eslint-disable */
    const validCheckpoint1 = finalPostData.checkpoint1 != null ? this.calculateDuration(finalPostData.checkpoint1) : 0;
    const validCheckpoint2 = finalPostData.checkpoint2 != null ? this.calculateDuration(finalPostData.checkpoint2) : 0;
    const validCheckpoint3 = finalPostData.checkpoint3 != null ? this.calculateDuration(finalPostData.checkpoint3) : 0;
    const validCheckpoint4 = finalPostData.checkpoint4 != null ? this.calculateDuration(finalPostData.checkpoint4) : 0;

    if ((validCheckpoint2 > 0 && validCheckpoint1 > validCheckpoint2)
      || (validCheckpoint3 > 0 && validCheckpoint2 > validCheckpoint3)
      || (validCheckpoint4 > 0 && validCheckpoint3 > validCheckpoint4)) {
      this.setState({
        viewId: '',
        loading: false,
        showValidationPopUp: true,
        validationPopUpTitle: 'Please enter Checkpoint time in correct order.',
      });
      return;
    }
    /* eslint-enable */

    if (this.state.edit) {
      cleVideosUpdate(finalPostData)
        .then(() => {
          this.setState({
            viewId: '',
            create: false,
            edit: false,
            formValues: undefined,
            showPopUp: false,
            deleteGuid: '',
            showMessagePopUp: true,
            messagePopUpTitle: 'CLE Video has been updated successfully. Video link will be updated shortly.',
            loading: false,
          });
        })
        .catch(() => {
          this.setState({
            viewId: '',
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: 'Update CLE Video failed',
          });
        });
    } else if (this.state.create) {
      cleVideosCreate(finalPostData)
        .then(() => {
          setTimeout(() => {
            this.setState({
              viewId: '',
              create: false,
              edit: false,
              formValues: undefined,
              startDateState: moment(this.props.startDate),
              endDateState: moment(this.props.endDate),
              showPopUp: false,
              deleteGuid: '',
              showMessagePopUp: true,
              messagePopUpTitle: 'CLE Video has been created successfully. Video link will be updated shortly.',
              loading: false,
            });
          }, 5000);
        })
        .catch(() => {
          this.setState({
            viewId: '',
            loading: false,
            showMessagePopUp: true,
            messagePopUpTitle: 'Create CLE Video failed',
          });
        });
    }
  }

  onConfirmDelete(value) {
    this.setState({
      viewId: '',
      showPopUp: false,
      loading: true,
    });

    cleVideosDelete(value)
      .then(() => {
        this.setState({
          viewId: '',
          create: false,
          edit: false,
          formValues: undefined,
          showPopUp: false,
          deleteGuid: '',
          showMessagePopUp: true,
          messagePopUpTitle: 'CLE Video has been deleted successfully',
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          viewId: '',
          loading: false,
          showPopUp: false,
          deleteGuid: '',
          showMessagePopUp: true,
          messagePopUpTitle: 'Delete CLE Video Failed',
        });
      });
  }

  onClickDelete(value) {
    this.setState({
      create: false,
      edit: false,
      viewId: '',
      formValues: undefined,
      showPopUp: true,
      deleteGuid: value,
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  onDeleteCancel() {
    this.setState({
      create: false,
      edit: false,
      viewId: '',
      formValues: undefined,
      showPopUp: false,
      deleteGuid: '',
      showMessagePopUp: false,
      messagePopUpTitle: '',
    });
  }

  setLoading(flag) {
    this.setState({
      loading: flag,
    });
  }

  showMessage(msg) {
    this.setState({
      showPopUp: false,
      showMessagePopUp: true,
      messagePopUpTitle: msg,
    });
  }

  updateMeetingDetails(name, id, startdate, enddate) {
    this.setState({
      meetingTitle: name,
      descriptionId: id,
      startDate: startdate,
      endDate: enddate,
    });
  }

  updateFileName(name) {
    this.setState({
      fileName: name,
    });
  }

  render() {
    const {
      loading,
      cleVideosListsState,
      headingState,
      formValues,
      edit,
      viewId,
      startDateState,
      endDateState,
      create,
      showPopUp,
      deleteGuid,
      showMessagePopUp,
      messagePopUpTitle,
      showValidationPopUp,
      validationPopUpTitle,
      selectedSearchOption,
    } = this.state;

    const searchOptions = [
      {
        label: '--Select--',
        value: '',
      },
      {
        label: 'Description Id',
        value: '1',
      },
      {
        label: 'Product Id',
        value: '2',
      },
      {
        label: 'Start Date/End Date',
        value: '3',
      },
    ];

    return (
      <BlockWrapper>
        {loading && <LoadingOverlay />}
        {showPopUp && deleteGuid && !showMessagePopUp && (
        <PopUpModal
          dismissable={false}
          body={(
            <ModalBody
              yesFunction={this.onConfirmDelete}
              noFunction={this.onDeleteCancel}
              deleteGuidString={deleteGuid}
            />
          )}
          closeAction={this.onDeleteCancel}
        />
        )}
        {showMessagePopUp && messagePopUpTitle && !showPopUp && (
          <PopUpModal
            dismissable={false}
            body={(
              <MessageModalBody
                title={messagePopUpTitle}
                okFunction={this.onClickSearch}
              />
            )}
            closeAction={this.onClickSearch}
          />
        )}
        {showValidationPopUp && validationPopUpTitle && !showPopUp && (
          <PopUpModal
            dismissable={false}
            body={(
              <MessageModalBody
                title={validationPopUpTitle}
                okFunction={() => { this.setState({ showValidationPopUp: false }); }}
              />
            )}
            closeAction={() => { this.setState({ showValidationPopUp: false }); }}
          />
        )}
        <Wrapper>
          <YellowLineSVG />
          <HeaderDiv>
            <Title id="title">{!headingState ? 'CLE Video' : headingState}</Title>
            <Button
              onClick={() => this.onClickCreateLink()}
              disabled={create}
            >
              Create
            </Button>
          </HeaderDiv>
          {(create || edit) && (
            <Provider store={this.store}>
              <VideoForm
                id="videoform"
                initialValues={formValues}
                onSubmit={this.onSubmit}
                onClickCloseCancel={this.onClickCloseCancel}
                edit={edit}
                updateMeetingDetails={this.updateMeetingDetails}
                updateFileName={this.updateFileName}
                setLoading={this.setLoading}
                showMessage={this.showMessage}
              />
            </Provider>
          )}
          <SearchDiv>
            <InnerDiv>
              <Select
                id="searchOptions"
                name="searchOptions"
                label="Search Type"
                placeholder="Select Search option"
                onChange={this.handleSearchDropdownChange}
                options={searchOptions}
                value={selectedSearchOption}
              />
            </InnerDiv>
            {selectedSearchOption === '1' && (
              <SearchWrapper>
                <Input
                  label="Description Id"
                  onChange={e => this.onChangeDescriptionId(e)}
                  placeholder="Enter"
                />
              </SearchWrapper>
            )}
            {selectedSearchOption === '2' && (
              <SearchWrapper>
                <Input
                  label="Product Id"
                  type="number"
                  onChange={e => this.onChangeProductId(e)}
                  placeholder="Enter"
                />
              </SearchWrapper>
            )}
            {selectedSearchOption === '3' && (
              <React.Fragment>
                <DatePickerWrapper>
                  <DatePicker
                    id="FromDate"
                    maxDate={moment().add(31, 'days').toDate()}
                    onChange={value => this.onChangeStartDate(value)}
                    scrollableYearDropdown
                    selected={startDateState ? moment(startDateState).toDate() : undefined}
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    placeholderText="mm/dd/yyyy"
                    label="From"
                  />
                </DatePickerWrapper>
                <DatePickerWrapper>
                  <DatePicker
                    id="ToDate"
                    maxDate={moment().add(31, 'days').toDate()}
                    onChange={value => this.onChangeEndDate(value)}
                    scrollableYearDropdown
                    selected={endDateState ? moment(endDateState).toDate() : undefined}
                    showYearDropdown
                    yearDropdownItemNumber={50}
                    placeholderText="mm/dd/yyyy"
                    label="To"
                  />
                </DatePickerWrapper>
              </React.Fragment>
            )}
            <ButtonWrapper>
              <Button
                onClick={() => this.onClickSearch()}
                disabled={selectedSearchOption === ''}
              >
                Search
              </Button>
            </ButtonWrapper>
          </SearchDiv>
          {is.not.null(cleVideosListsState) && cleVideosListsState.length > 0 && (
            <CLEVideosResultDiv>
              <VideoTable>
                <thead>
                  <ColumnHeaders>
                    <th><h3>Product ID</h3></th>
                    <th><h3>Description ID</h3></th>
                    <th><h3>Meeting Title</h3></th>
                    <th><h3>Video ID</h3></th>
                    <th><h3>Start Date</h3></th>
                    <th><h3>End Date</h3></th>
                    <th><h3>Author</h3></th>
                    <th><h3>ACTIONS</h3></th>
                  </ColumnHeaders>
                </thead>
                <tbody>
                  {cleVideosListsState.map(c => (
                    <CLEVideosResult
                      key={c.contentGuidString}
                      initialValues={c}
                      viewId={viewId}
                      onClickViewButton={this.onClickViewButton}
                      onClickEditButton={this.onClickEditButton}
                      onClickCloseCancel={this.onClickCloseCancel}
                      onClickDelete={this.onClickDelete}
                    />
                  ))}
                </tbody>
              </VideoTable>
            </CLEVideosResultDiv>
          )}
        </Wrapper>
      </BlockWrapper>
    );
  }
}

CLEVideosCTA.propTypes = {
  cleVideosLists: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    meetingID: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    meetingTitle: PropTypes.string,
    descriptionId: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    videoTitle: PropTypes.string,
    videoLink: PropTypes.string,
    checkpoint1: PropTypes.string,
    checkpoint2: PropTypes.string,
    checkpoint3: PropTypes.string,
    checkpoint4: PropTypes.string,
    minTime: PropTypes.string,
    createdDate: PropTypes.string,
    modifiedBy: PropTypes.string,
  })).isRequired,
  heading: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

CLEVideosCTA.defaultProps = {
  heading: 'CLE Videos',
};


CLEVideosCTA.displayName = 'CLEVideosCTA';

export default CLEVideosCTA;
