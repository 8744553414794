import styled from 'styled-components';

const DescriptionWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

DescriptionWrapper.displayName = 'DescriptionWrapper';

export default DescriptionWrapper;
