import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { colors, typography } from '@style-guide/config';

const MenuButton = styled.button`
  background-color: transparent;
  border: 0;
  border-bottom: ${props => (props.$active ? `${colors.red} 5px solid` : '0')};
  color: ${colors.white};
  cursor: pointer;
  font-size: ${rem('20px', typography.baseFontSize)};
  padding: 5px;
`;

MenuButton.displayName = 'MenuButton';

export default MenuButton;
