import { urlEncode } from '@services/Url';

const serializeFormData = data => ({
  contentGuidString: data.contentGuidString,
  meetingID: data.meetingID,
  meetingTitle: data.meetingTitle,
  descriptionId: data.descriptionId,
  startDate: data.startDate,
  endDate: data.endDate,
  videoTitle: data.videoTitle,
  videoLink: data.videoLink,
  checkpoint1: data.checkpoint1,
  checkpoint2: data.checkpoint2,
  checkpoint3: data.checkpoint3,
  checkpoint4: data.checkpoint4,
  minTime: data.minTime,
  createdDate: data.createdDate,
  createdBy: data.createdBy,
  fileName: data.fileName,
});

const cleVideosCreate = async (data) => {
  const cleCreate = await fetch('/cleManageVideosCallToActionBlock/CreateCleVideos', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const cleCreateJson = cleCreate.json();

  return cleCreateJson;
};

const cleVideosUpdate = async (data) => {
  const cleUpdate = await fetch('/cleManageVideosCallToActionBlock/UpdateCleVideos', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const cleUpdateJson = cleUpdate.json();

  return cleUpdateJson;
};

const cleVideosDelete = async (data) => {
  const cleDelete = await fetch('/cleManageVideosCallToActionBlock/DeleteCleVideo', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      stringToDelete: data,
    }),
  });
  const cleDeleteJson = cleDelete.json();

  return cleDeleteJson;
};

const getMeetingName = async (data) => {
  const cleMeetingName = await fetch(`/cleManageVideosCallToActionBlock/GetMeetingName?meetingId=${data}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const cleMeetingNameJson = cleMeetingName.json();

  return cleMeetingNameJson;
};

const checkVideoId = async (data) => {
  const cleVideo = await fetch(`/cleManageVideosCallToActionBlock/CheckVideoId?videoId=${data}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const cleVideoJson = cleVideo.json();

  return cleVideoJson;
};

export {
  cleVideosCreate,
  cleVideosUpdate,
  cleVideosDelete,
  getMeetingName,
  checkVideoId,
};
