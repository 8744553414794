import styled from 'styled-components';

import { colors } from '@style-guide/config';

const ErrorMessage = styled.p`
  color: ${colors.red};
  font-style: italic;
`;

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
