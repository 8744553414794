import styled from 'styled-components';

import { colors } from '@style-guide/config';

const ResultsCount = styled.div`
  border-top: 1px solid ${colors.gray};
  color: ${colors.darkGray};
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
  padding-top: 15px;
  width: 100%;
`;

ResultsCount.displayName = 'ResultsCount';

export default ResultsCount;
