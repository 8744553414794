import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  padding: 0px 20px;
  margin-top: 15px;

  @media(min-width: ${breakpoints.m}) {
    flex-direction: row;
  }
`;

ListWrapper.displayName = 'ListWrapper';

export default ListWrapper;
