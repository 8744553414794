import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

import { Checkbox } from '@style-guide/components';

import Divider from '@components/Divider';

import Subtitle from '../Subtitle';

import IntroText from './components/IntroText';
import FlexContainer from './components/FlexContainer';
import List from './components/List';
import MyPracticeAreasToggle from './components/MyPracticeAreasToggle';
import PracticeAreas from './components/PracticeAreas';
import PracticeAreasWrapper from './components/PracticeAreasWrapper';
import PracticeAreasWrapperMobile from './components/PracticeAreasWrapperMobile';
import SearchBar from './components/SearchBar';
import SearchHeading from './components/SearchHeading';
import Wrapper from './components/Wrapper';

class SearchSection extends React.Component {
  constructor(props) {
    super(props);

    const {
      practiceAreas,
      userPracticeAreas,
    } = this.props;

    const filterGroup = (opts, startCharCode, endCharCode) => {
      const filteredOpts = opts.filter((practiceArea) => {
        const optCharCode = practiceArea.filterName.charCodeAt(0);

        return optCharCode >= startCharCode && optCharCode <= endCharCode;
      });

      const orderedOpts = sortBy(filteredOpts, ['filterName']);

      return orderedOpts;
    };

    this.state = {
      practiceAreasFormatted: {
        AG: filterGroup(practiceAreas, 65, 71),
        HN: filterGroup(practiceAreas, 72, 78),
        OU: filterGroup(practiceAreas, 79, 85),
        VZ: filterGroup(practiceAreas, 86, 90),
      },
      userPracticeAreasFormatted: !userPracticeAreas
        ?
        null
        :
        {
          AG: filterGroup(userPracticeAreas, 65, 71),
          HN: filterGroup(userPracticeAreas, 72, 78),
          OU: filterGroup(userPracticeAreas, 79, 85),
          VZ: filterGroup(userPracticeAreas, 86, 90),
        },
      displayUserPracticeAreas: false,
    };

    this.updateDisplayUserPracticeAreas = this.updateDisplayUserPracticeAreas.bind(this);
  }

  updateDisplayUserPracticeAreas() {
    const { displayUserPracticeAreas } = this.state;

    this.setState({
      displayUserPracticeAreas: !displayUserPracticeAreas,
    });
  }

  render() {
    const {
      introCopy,
      otherResources,
      otherResourcesHeading,
      publications,
      publicationsHeading,
      practiceAreaHeading,
      searchHeading,
      searchPageUrl,
      userPracticeAreas,
    } = this.props;

    const { displayUserPracticeAreas } = this.state;

    return (
      <Wrapper>
        {introCopy && (
          <IntroText
            dangerouslySetInnerHTML={{
              __html: introCopy,
            }}
          />
        )}
        {searchHeading && (
          <SearchHeading>{searchHeading}</SearchHeading>
        )}
        <SearchBar
          searchPageUrl={searchPageUrl}
        />
        {practiceAreaHeading && (
          <React.Fragment>
            <FlexContainer>
              <h3>{practiceAreaHeading}</h3>
              {userPracticeAreas && (
                <MyPracticeAreasToggle>
                  <Checkbox
                    id="justMyPracticeAreas"
                    isChecked={this.state.displayUserPracticeAreas}
                    label="Just Show My Practice Areas"
                    name="justMyPracticeAreas"
                    onChange={this.updateDisplayUserPracticeAreas}
                    value="justMyPracticeAreas"
                  />
                </MyPracticeAreasToggle>
              )}
            </FlexContainer>
            <Divider
              width="100%"
            />
          </React.Fragment>
        )}
        <PracticeAreasWrapper>
          <List
            items={displayUserPracticeAreas
              ?
              sortBy(this.props.userPracticeAreas, ['filterName'])
              :
              sortBy(this.props.practiceAreas, ['filterName'])
            }
            searchPageUrl={searchPageUrl}
            filterField="practiceAreas"
          />
        </PracticeAreasWrapper>
        <PracticeAreasWrapperMobile>
          <PracticeAreas
            displayUserPracticeAreas={this.state.displayUserPracticeAreas}
            items={displayUserPracticeAreas
              ?
              this.state.userPracticeAreasFormatted
              :
              this.state.practiceAreasFormatted
            }
            practiceAreasFormatted={this.state.practiceAreasFormatted}
            searchPageUrl={searchPageUrl}
            userPracticeAreasFormatted={this.state.userPracticeAreasFormatted}
            updateDisplayUserPracticeAreas={this.updateDisplayUserPracticeAreas}
          />
        </PracticeAreasWrapperMobile>
        {otherResourcesHeading && (
          <React.Fragment>
            <Subtitle>{otherResourcesHeading}</Subtitle>
            <Divider
              width="100%"
            />
          </React.Fragment>
        )}
        {otherResources && (
          <List
            items={sortBy(otherResources, ['filterName'])}
            searchPageUrl={searchPageUrl}
            filterField="otherResources"
          />
        )}
        {publicationsHeading && (
          <React.Fragment>
            <Subtitle>{publicationsHeading}</Subtitle>
            <Divider
              width="100%"
            />
          </React.Fragment>
        )}
        {publications && (
          <List
            items={sortBy(publications, ['filterName'])}
            searchPageUrl={searchPageUrl}
            filterField="publications"
          />
        )}
      </Wrapper>
    );
  }
}

SearchSection.propTypes = {
  introCopy: PropTypes.string,
  otherResources: PropTypes.arrayOf(PropTypes.object),
  otherResourcesHeading: PropTypes.string,
  practiceAreaHeading: PropTypes.string,
  practiceAreas: PropTypes.arrayOf(PropTypes.object),
  publicationsHeading: PropTypes.string,
  publications: PropTypes.arrayOf(PropTypes.object),
  searchPageUrl: PropTypes.string.isRequired,
  searchHeading: PropTypes.string,
  userPracticeAreas: PropTypes.arrayOf(PropTypes.object),
};

SearchSection.defaultProps = {
  introCopy: '',
  otherResources: [],
  otherResourcesHeading: '',
  practiceAreas: [],
  practiceAreaHeading: '',
  publications: [],
  publicationsHeading: '',
  searchHeading: '',
  userPracticeAreas: [],
};

SearchSection.displayName = 'SearchSection';

export default SearchSection;
