import styled from 'styled-components';

const ResourceHeadingContainer = styled.div`
  margin: 0 auto;
  max-width: 1070px;
  width: 100%;
  margin-bottom: 15px;
`;

ResourceHeadingContainer.displayName = 'ResourceHeadingContainer';

export default ResourceHeadingContainer;
