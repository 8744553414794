import React from 'react';
import { Provider } from 'react-redux';
import { getDemographicInfo } from '@redux/modules/demographicInfo';
import Store from '@redux';

import DemographicInfoContent from './components/DemographicInfoContent';

class DemographicInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getDemographicInfo());
  }

  render() {
    return (
      <Provider store={this.store}>
        <DemographicInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

DemographicInfo.displayName = 'DemographicInfo';

export default DemographicInfo;
