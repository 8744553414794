import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Button from '@style-guide/components/Button';

import ReduxFormCheckbox from '@components/ReduxFormCheckbox';
import ReduxFormRadioButton from '@components/ReduxFormRadioButton';
import { cleFrequencyOptions } from '@config';
import FormFieldWrapper from './components/FormFieldWrapper';
import CheckBoxWrapper from './components/CheckBoxWrapper';
import RadioButtonGroup from './components/RadioButtonGroup';
import FormFieldSection from './components/FormFieldSection';

const SaveContainer = styled.div`
  button {
    margin: 0;
  }
`;

SaveContainer.displayName = 'SaveContainer';

const PaddedPara = styled.p`
  padding-left: 35px;
`;

PaddedPara.displayName = 'PaddedPara';

const PracticeAreaDiv = styled.div`
  padding-left: 35px;
`;

PracticeAreaDiv.displayName = 'PracticeAreaDiv';

const FreqPromDiv = styled.div`
  padding-left: 40px;
`;

FreqPromDiv.displayName = 'FreqPromDiv';

const CheckBoxDiv = styled.div`
  label {
    font-weight: 700;
    letter-spacing: 0;
  }
`;

CheckBoxDiv.displayName = 'CheckBoxDiv';

const HeadingDiv = styled.div`
  font-size: 1rem;
  font-weight: 700;
`;

HeadingDiv.displayName = 'HeadingDiv';

const MemberBenefitInfoForm = ({
  handleSubmit,
  loading,
  pristine,
  hasSaveError = false,
  isMember,
  handleChangeCleUpdates,
  CleUpdatesValue,
}) => {
  const cleFrequencyRadioButtons = cleFrequencyOptions.map(cleFreq => (
    <li
      key={cleFreq.value}
    >
      <Field
        id={`cleFrequencyRadioButton${cleFreq.value}`}
        label={cleFreq.label}
        name="cleFrequency"
        props={{
          value: cleFreq.value,
        }}
        type="radiobutton"
        component={ReduxFormRadioButton}
        disabled={!CleUpdatesValue}
      />
    </li>
  ));
  return (
    <form
      id="memberBenefitInfoForm"
      onSubmit={handleSubmit}
    >
      <FormFieldSection>
        <FormFieldWrapper $wide>
          <CheckBoxDiv>
            <Field
              id="cleUpdates"
              label="Continuing Legal Education"
              name="cleUpdates"
              props={{
                value: true,
              }}
              type="checkbox"
              component={ReduxFormCheckbox}
              onChange={() => handleChangeCleUpdates()}
            />
          </CheckBoxDiv>
        </FormFieldWrapper>
        <PaddedPara>
          The OSBA is the largest provider of quality CLE in the state
          and offers hundreds of Ohio-specific courses throughout the year
          delivered live (both in-person and as live interactive webinars)
          and on-demand. Stay current with your CLE requirements and
          valuable learning opportunities on the topics you find most
          interesting by updating your preferences below.
        </PaddedPara>
        <PracticeAreaDiv>
          <HeadingDiv>Practice Areas</HeadingDiv>
          <p>
            The OSBA targets our CLE communications based upon your practice area.
            <b>
              &nbsp;Be sure to review and update your practice area(s) in the Practice Areas tab
              (in the navigation to the left) so we can deliver the most relevant programs to you.
            </b>
          </p>
          <FreqPromDiv>
            <HeadingDiv>Frequency</HeadingDiv>
            <RadioButtonGroup>
              {cleFrequencyRadioButtons}
            </RadioButtonGroup>
            <HeadingDiv>Promotions</HeadingDiv>
            <CheckBoxWrapper
              $wide
              $isDisabled={!CleUpdatesValue}
            >
              <Field
                id="clePromotions"
                label="CLE discounts, promotions and complimentary programs, as available"
                name="clePromotions"
                props={{
                  value: true,
                }}
                type="checkbox"
                component={ReduxFormCheckbox}
                disabled={!CleUpdatesValue}
              />
            </CheckBoxWrapper>
          </FreqPromDiv>
        </PracticeAreaDiv>
        <FormFieldWrapper
          $wide
          $isDisabled={!isMember}
        >
          <CheckBoxDiv>
            <Field
              id="memberPracticeMgmtTools"
              label="Practice Management Tools"
              name="memberPracticeMgmtTools"
              type="checkbox"
              props={{
                value: true,
              }}
              component={ReduxFormCheckbox}
              disabled={!isMember}
            />
          </CheckBoxDiv>
        </FormFieldWrapper>
        <PaddedPara>
          Get the most out of your OSBA membership with information,
          discounts and access to our full suite of practice management
          resources and business development tools, as available.
        </PaddedPara>
        <FormFieldWrapper
          $wide
          $isDisabled={!isMember}
        >
          <CheckBoxDiv>
            <Field
              id="memberJobListings"
              label="Job Listings"
              name="memberJobListings"
              type="checkbox"
              props={{
                value: true,
              }}
              component={ReduxFormCheckbox}
              disabled={!isMember}
            />
          </CheckBoxDiv>
        </FormFieldWrapper>
        <PaddedPara>
          Receive a listing of the latest Ohio-based open positions and job
          opportunities, twice monthly.
        </PaddedPara>
        <FormFieldWrapper
          $wide
          $isDisabled={!isMember}
        >
          <CheckBoxDiv>
            <Field
              id="memberEvents"
              label="Events"
              name="memberEvents"
              type="checkbox"
              props={{
                value: true,
              }}
              component={ReduxFormCheckbox}
              disabled={!isMember}
            />
          </CheckBoxDiv>
        </FormFieldWrapper>
        <PaddedPara>
          Don&apos;t miss out on important OSBA meetings, valuable networking events
          and rewarding volunteer opportunities, as announced.
        </PaddedPara>
        <SaveContainer>
          <Button
            type="submit"
            disabled={(pristine || loading) && !hasSaveError}
          >
            Save
          </Button>
        </SaveContainer>
      </FormFieldSection>
    </form>
  );
};

MemberBenefitInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  hasSaveError: PropTypes.bool,
  isMember: PropTypes.bool.isRequired,
  handleChangeCleUpdates: PropTypes.func.isRequired,
  CleUpdatesValue: PropTypes.bool,
};

MemberBenefitInfoForm.displayName = 'MemberBenefitInfoForm';

const MemberBenefitInfoReduxForm = reduxForm({
  form: 'memberBenefitInfoForm',
  enableReinitialize: true,
})(MemberBenefitInfoForm);

/* following example at https://redux-form.com/7.4.2/examples/selectingformvalues/ */
const selector = formValueSelector('memberBenefitInfoForm');

const MemberBenefitInfoFormConnected = connect((state) => {
  const CleUpdatesValue = selector(state, 'cleUpdates') || false;

  return {
    CleUpdatesValue,
  };
})(MemberBenefitInfoReduxForm);

export default MemberBenefitInfoFormConnected;
