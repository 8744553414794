import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { hydrateSectionsInfo } from '@redux/modules/sections';

import Store from '@redux';

import SectionsContent from './components/SectionsContent';

class Sections extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(hydrateSectionsInfo(this.props.sections));
  }

  render() {
    return (
      <Provider store={this.store}>
        <SectionsContent
          {...this.props}
        />
      </Provider>
    );
  }
}

Sections.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    contentGuid: PropTypes.string,
    price: PropTypes.string,
    subscriptionType: PropTypes.string,
    selected: PropTypes.bool,
  })),
  isSectionAccordionOpen: PropTypes.bool.isRequired,
};

Sections.defaultProps = {
  sections: [],
};

Sections.displayName = 'Sections';

export default Sections;
