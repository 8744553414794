import styled from 'styled-components';

import { colors } from '@style-guide/config';

import Title from './Title';

const LinkWrapper = styled.a`
  color: ${colors.black};
  transition: scale 200ms;
  display: block;

  ${Title} {
    color: ${colors.red};
  }

  &:hover {
    transform: scale(1.0125);
  }
`;

LinkWrapper.displayName = 'LinkWrapper';

export default LinkWrapper;
