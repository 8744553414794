import styled from 'styled-components';

import { colors } from '@style-guide/config';

const OptionsWrapper = styled.div`
  background-color: ${colors.white};
  padding: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

OptionsWrapper.displayName = 'OptionsWrapper';

export default OptionsWrapper;
