import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import styled from 'styled-components';
import {
  updateCreditCard,
  updatePayPal,
  removeSavedPaymentMethod,
} from '@services/Payment';

import LoadingOverlay from '@components/LoadingOverlay';
import PopupMessage from '@components/PopupMessage/PopupMessage';
import Store from '@redux';
import PaymentDetails from './components/PaymentDetails';
import UpdatePaymentForm from './components/UpdatePaymentForm';

const PaymentInformationContainer = styled.div`
  position: relative;
`;

PaymentInformationContainer.displayName = 'PaymentInformationContainer';

const SUCCESS_MESSAGES = {
  remove: 'Credit Card payment method successfully removed. It may take some time for this change to appear on your account.',
  creditCard: 'Credit card payment method successfully updated. It may take some time for this change to appear on your account.',
  billingAddressOnly: 'Billing Address successfully updated. It may take some time for this change to appear on your account.',
  payPal: 'PayPal payment method successfully updated. It may take some time for this change to appear on your account.',
  removePaypal: 'Paypal Information successfully removed. It may take some time for this change to appear on your account.',
};

class PaymentInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      payPalData: null,
      payPalError: null,
      errorMessages: {},
      successMessages: {},
      captchaValid: false,
      captchaValue: null,
      showPopup: false,
      showPopupOnCardSave: false,
      disclaimerChecked: false,
    };

    this.store = Store.get();

    this.onSubmit = this.onSubmit.bind(this);
    this.onPayPalReturn = this.onPayPalReturn.bind(this);
    this.updatePayPalPayment = this.updatePayPalPayment.bind(this);
    this.removeSavedPayment = this.removeSavedPayment.bind(this);
    this.handleCreditCardResponse = this.handleResponseFactory('creditCard').bind(this);
    this.handlePayPalResponse = this.handleResponseFactory('payPal').bind(this);
    this.handleRemoveResponse = this.handleResponseFactory('remove').bind(this);
    this.onRecaptchaChange = this.onRecaptchaChange.bind(this);
    this.onHandleClick = this.onHandleClick.bind(this);
    this.onHandleSaveClick = this.onHandleSaveClick.bind(this);
    this.onDisclaimClick = this.onDisclaimClick.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem('setShowPopup') === 'true') {
      this.setState({
        showPopup: true,
      });
    } else {
      this.setState({
        showPopup: false,
      });
    }
  }

  onDisclaimClick() {
    this.setState({
      disclaimerChecked: !this.state.disclaimerChecked,
    });
  }

  onHandleClick() {
    this.setState({
      showPopup: !this.state.showPopup,
    });
    sessionStorage.setItem('setShowPopup', false);
  }

  onHandleSaveClick() {
    this.setState({
      showPopupOnCardSave: !this.state.showPopupOnCardSave,
    });
  }

  onSubmit(data) {
    this.setState({
      loading: true,
      showPopupOnCardSave: false,
    });

    updateCreditCard({
      ...data,
      userId: this.props.paymentMethod.userId,
      captchaValue: this.state.captchaValue,
      isDisclaimerChecked: this.state.disclaimerChecked,
    })
      .then(this.handleCreditCardResponse)
      .catch(this.handleCreditCardResponse);
  }

  onPayPalReturn(payPalData, payPalError) {
    this.setState({
      payPalData,
      payPalError,
    });
  }

  onRecaptchaChange(value) {
    if (value !== null) {
      this.setState({
        captchaValid: true,
        captchaValue: value,
      });
    } else {
      this.setState({
        captchaValid: false,
        captchaValue: '',
      });
    }
  }

  updatePayPalPayment() {
    this.setState({
      loading: true,
    });

    updatePayPal({
      ...this.state.payPalData,
      standingOrderId: this.props.standingOrderId,
    })
      .then(this.handlePayPalResponse)
      .catch(this.handlePayPalResponse);
  }

  removeSavedPayment() {
    if (this.props.autoRenew) {
      sessionStorage.setItem('setShowPopup', true);
    }
    this.setState({
      loading: true,
    });
    removeSavedPaymentMethod({
      userId: this.props.paymentMethod.userId,
    })
      .then(this.handleRemoveResponse)
      .catch(this.handleRemoveResponse);
  }

  handleResponseFactory(type) {
    return (data) => {
      if (data && data.result) {
        let successMessageString = '';
        if (type === 'creditCard' && this.props.paymentMethod.id && this.props.paymentMethod.id > 0) {
          successMessageString = SUCCESS_MESSAGES.billingAddressOnly;
        } else if (type === 'remove' && this.props.paymentMethod.paymentTypeID === 48) {
          successMessageString = SUCCESS_MESSAGES.removePaypal;
        } else {
          successMessageString = SUCCESS_MESSAGES[type];
        }
        this.setState({
          loading: false,
          errorMessages: {},
          successMessages: {
            [type]: successMessageString,
          },
        });
        if (type === 'remove') {
          window.location.reload(true);
        } else {
          setTimeout(() => { window.location.reload(true); }, 5000);
        }
      } else {
        this.setState({
          loading: false,
          errorMessages: {
            [type]: (data && data.result) ? data.result.Message : 'An unknown error has occurred. Please try again later.',
          },
          successMessages: {},
        });
      }
    };
  }

  render() {
    const {
      loading,
      payPalData,
      payPalError,
      errorMessages,
      successMessages,
      captchaValid,
      disclaimerChecked,
    } = this.state;

    return (
      <PaymentInformationContainer>
        {loading && <LoadingOverlay />}
        <PaymentDetails
          {...this.props}
          loading={loading}
        />

        {this.state.showPopup && (
        <PopupMessage
          onHandleClick={this.onHandleClick}
          onSaveClick={this.onHandleClick}
          bodyContent="Please enter a new credit card to continue your enrollment in Automatic Renewal."
        />
        )}

        <Provider store={this.store}>
          <UpdatePaymentForm
            initialValues={this.props.paymentMethod}
            hasPayPalData={!!payPalData}
            payPalError={payPalError}
            payPalSaveError={errorMessages.payPal}
            payPalEnvironment={this.props.payPalEnvironment}
            creditCardSaveError={errorMessages.creditCard}
            payPalSuccessMessage={successMessages.payPal}
            creditCardSuccessMessage={successMessages.creditCard}
            onSubmit={this.onSubmit}
            onPayPalReturn={this.onPayPalReturn}
            updatePayPalPayment={this.updatePayPalPayment}
            removeSavedPaymentMethod={this.removeSavedPayment}
            creditCardRemoveError={errorMessages.remove}
            removeSuccessMethod={successMessages.remove}
            loading={loading}
            removeDisabled={this.props.paymentMethod.id === 0}
            onRecaptchaChange={this.onRecaptchaChange}
            captchaValid={captchaValid}
            recordId={this.props.paymentMethod.id}
            onHandleClick={this.onHandleClick}
            onHandleSaveClick={this.onHandleSaveClick}
            showPopupOnCardSave={this.state.showPopupOnCardSave}
            onDisclaimClick={this.onDisclaimClick}
            autoRenew={this.props.autoRenew}
            disclaimerChecked={disclaimerChecked}
          />
        </Provider>
      </PaymentInformationContainer>
    );
  }
}

PaymentInformation.propTypes = {
  standingOrderId: PropTypes.number.isRequired,
  payPalEnvironment: PropTypes.string,
  paymentMethod: PropTypes.shape({
    paymentTypeID: PropTypes.number,
    CCExpireMonth: PropTypes.number,
    CCExpireYear: PropTypes.number,
    CCPartial: PropTypes.string,
    BillingAddress1: PropTypes.string,
    BillingAddress2: PropTypes.string,
    BillingAddressCity: PropTypes.string,
    BillingAddressState: PropTypes.string,
    BillingAddressZip: PropTypes.number,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    id: PropTypes.number,
    userId: PropTypes.number,
  }),
  autoRenew: PropTypes.bool.isRequired,
};

PaymentInformation.defaultProps = {
  payPalEnvironment: 'sandbox',
  paymentMethod: ({
    paymentTypeID: 0,
    CCExpireDate: 0,
    CCAccountNumber: '',
  }),
};

PaymentInformation.displayName = 'PaymentInformation';

export default PaymentInformation;
