import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { hydrateMemberBenefitsInfo } from '@redux/modules/CommunicationPreferences/MemberBenefitsInfo';

import Store from '@redux';

import MemberBenefitInfoContent from './components/MemberBenefitInfoContent';

class MemberBenefitInformationBlock extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(hydrateMemberBenefitsInfo(this.props.memberBenefitsData));
  }

  render() {
    return (
      <Provider store={this.store}>
        <MemberBenefitInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

MemberBenefitInformationBlock.propTypes = {
  memberBenefitsData: PropTypes.shape({
    cleUpdates: PropTypes.bool,
    cleFrequency: PropTypes.number,
    clePromotions: PropTypes.bool,
    memberPracticeMgmtTools: PropTypes.bool,
    memberJobListings: PropTypes.bool,
    memberEvents: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string.isRequired,
  isMember: PropTypes.bool.isRequired,
};

MemberBenefitInformationBlock.displayName = 'MemberBenefitInformationBlock';

export default MemberBenefitInformationBlock;
