import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const BlockWrapper = styled.div`
  padding: 30px 0;

  @media(min-width: ${breakpoints.m}) {
    padding: 65px 100px;
  }
`;

BlockWrapper.displayName = 'BlockWrapper';

export default BlockWrapper;
