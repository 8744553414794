import React from 'react';
import PropTypes from 'prop-types';

import MarketingCTASection from '@components/MarketingCTASection';
import RightRailContainer from '@components/RightRailContainer';

import MarketingSectionWrapper from './components/MarketingSectionWrapper';
import RelatedArticles from './components/RelatedArticles';

const ResourceRightRail = ({
  content,
  contentAreaName,
  relatedSpecialFeatures,
  relatedArticles,
  isSpecialFeature,
}) => (
  <RightRailContainer>
    {relatedArticles && !isSpecialFeature && (
      <RelatedArticles
        relatedSpecialFeatures={relatedSpecialFeatures}
        relatedArticles={relatedArticles}
      />
    )}
    <div
      data-epi-property-name={contentAreaName}
      data-epi-property-render="none"
      data-epi-property-edittype="floating"
    >
      <MarketingSectionWrapper>
        <MarketingCTASection
          orientation="vertical"
          contentItems={content}
        />
      </MarketingSectionWrapper>
    </div>
  </RightRailContainer>
);

ResourceRightRail.propTypes = {
  content: PropTypes.node.isRequired,
  contentAreaName: PropTypes.string.isRequired,
  relatedSpecialFeatures: PropTypes.arrayOf(PropTypes.object),
  relatedArticles: PropTypes.arrayOf(PropTypes.object),
  isSpecialFeature: PropTypes.bool.isRequired,
};

ResourceRightRail.defaultProps = {
  relatedSpecialFeatures: [],
  relatedArticles: [],
};

ResourceRightRail.displayName = 'ResourceRightRail';

export default ResourceRightRail;
