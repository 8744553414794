import styled from 'styled-components';

import { colors } from '@style-guide/config';

const BrochureDownloadLink = styled.a`
  color: ${colors.red};

  &:hover {
    color: ${colors.darkRed};
  }

  span {
    margin-left: 8px;
    text-decoration: underline;
  }
`;

BrochureDownloadLink.displayName = 'BrochureDownloadLink';

export default BrochureDownloadLink;
