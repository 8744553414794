import styled from 'styled-components';
import { colors } from '@style-guide/config';

const ShoppingCartHeading = styled.h2`
  align-items: center;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  display: flex;
  font-size: 22px;
  height: 50px;
  padding: 0 20px;
`;

ShoppingCartHeading.displayName = 'ShoppingCartHeading';

export default ShoppingCartHeading;
