import { urlEncode } from '@services/Url';

const serializeFormData = data => ({
  contentGuidString: data.contentGuidString,
  title: data.title,
  summary: data.summary,
  caseName: data.caseName,
  webCitation: data.webCitation,
  casemakerLink: data.casemakerLink,
  pDFLink: data.pDFLink,
  dateDecidedString: data.dateDecidedString,
  activeDateString: data.activeDateString,
  authoredBy: data.authoredBy,
  practiceId: data.practiceId ? parseInt(data.practiceId, 10) : 0,
  courtId: data.courtId ? parseInt(data.courtId, 10) : 0,
});

const eBarCaseCreate = async (data) => {
  const eBarCreate = await fetch('/EBarAdminCasePage/CreateEpiPage', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarCreateJson = eBarCreate.json();

  return eBarCreateJson;
};

const eBarCaseUpdate = async (data) => {
  const eBarUpdate = await fetch('/EBarAdminCasePage/UpdateEpiPage', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarUpdateJson = eBarUpdate.json();

  return eBarUpdateJson;
};

const eBarCaseDelete = async (data) => {
  const eBarDelete = await fetch('/EBarAdminCasePage/DeleteEpiPage', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      stringToDelete: data,
    }),
  });
  const eBarDeleteJson = eBarDelete.json();

  return eBarDeleteJson;
};


export {
  eBarCaseCreate,
  eBarCaseUpdate,
  eBarCaseDelete,
};
