import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 8px;
  width: 100%;

  @media(min-width: ${breakpoints.m}) {
    margin-top: 48px;
  }
`;

FlexContainer.displayName = 'FlexContainer';

export default FlexContainer;
