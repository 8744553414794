import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const MobileMarketingCTAs = styled.div`
  width: 100%;

  @media(min-width: ${breakpoints.l}) {
    display: none;
  }
`;

MobileMarketingCTAs.displayName = 'MobileMarketingCTAs';

export default MobileMarketingCTAs;
