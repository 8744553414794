import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import Button from '@style-guide/components/Button';
import ComponentDiv from './components/ComponentDiv';
import EBarOtherAnnouncementResultTr from './components/EBarOtherAnnouncementResultTr';
import Name from './components/Name';
import ListParagraph from '../../../EBarHome/components/EBarHome/EBarHomeContent/components/Shared/components/ListParagraph';

const ActionButton = styled.button`
  border: none;
  cursor: pointer;
`;

ActionButton.displayName = 'ActionButton';

const ViewColumn = styled.td`
  background-color: #FFFFFF;
`;

ViewColumn.displayName = 'ViewColumn';

const ImageStyle = {
  height: '24px',
  width: '24px',
};

const EBarOtherAnnouncementsResult = ({
  initialValues = {
    contentGuidString: '',
    title: '',
    partnerEntity: '',
    postedDateString: '',
    summary: '',
    cTALabel: '',
    cTAUrl: '',
    createdDate: '',
    modifiedBy: '',
    activeDateString: '',
  },
  viewId = '',
  onClickViewButton,
  onClickEditButton,
  onClickCloseCancel,
  onClickDelete,
}) => (
  <React.Fragment>
    <EBarOtherAnnouncementResultTr
      isActiveDateNull={!initialValues.activeDateString}
    >
      <td>{initialValues.title}</td>
      <td>{initialValues.partnerEntity}</td>
      <td>{!initialValues.postedDateString ? '' : moment(initialValues.postedDateString).format('MM/DD/YYYY')}</td>
      <td>{initialValues.modifiedBy}</td>
      <td>{!initialValues.activeDateString ? '' : moment(initialValues.activeDateString).format('MM/DD/YYYY')}</td>
      <td>
        <ActionButton
          title="Edit"
          onClick={() => onClickEditButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Edit.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="View"
          onClick={() => onClickViewButton(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Detail.png" alt="" style={ImageStyle} />
        </ActionButton>
        <ActionButton
          title="Delete"
          onClick={() => onClickDelete(initialValues.contentGuidString)}
        >
          <img src="/Static/img/Delete.png" alt="" style={ImageStyle} />
        </ActionButton>
      </td>
    </EBarOtherAnnouncementResultTr>
    {viewId && viewId === initialValues.contentGuidString && (
    <tr>
      <ViewColumn colSpan="6">
        <ComponentDiv>
          <Name>{initialValues.title}</Name>
          <p><span>Partner Entity: </span>{initialValues.partnerEntity}</p>
          {/* eslint-disable-next-line react/no-danger */}
          <ListParagraph dangerouslySetInnerHTML={{ __html: initialValues.summary }} />
          <p><span>CTA Label:</span> {initialValues.cTALabel}</p>
          <p><span>CTA Url:</span> {initialValues.cTAUrl}</p>
          <p><span>Posted Date:</span> {!initialValues.postedDateString ? '' : moment(initialValues.postedDateString).format('MM/DD/YYYY')}</p>
          <p><span>Active Date:</span> {!initialValues.activeDateString ? '' : moment(initialValues.activeDateString).format('MM/DD/YYYY')}</p>
          <p><span>Author:</span> {initialValues.modifiedBy}</p>
          <p><span>Date Added:</span> {moment(initialValues.createdDate).format('MM/DD/YYYY')}</p>
          <Button
            onClick={() => onClickCloseCancel()}
          >
            Close
          </Button>
        </ComponentDiv>
      </ViewColumn>
    </tr>
    )}
  </React.Fragment>
);


EBarOtherAnnouncementsResult.displayName = 'EBarOtherAnnouncementsResult';

EBarOtherAnnouncementsResult.propTypes = {
  initialValues: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    partnerEntity: PropTypes.string,
    postedDateString: PropTypes.string,
    summary: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    createdDate: PropTypes.string,
    modifiedBy: PropTypes.string,
    activeDateString: PropTypes.string,
  }),
  viewId: PropTypes.string,
  onClickViewButton: PropTypes.func.isRequired,
  onClickEditButton: PropTypes.func.isRequired,
  onClickCloseCancel: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default EBarOtherAnnouncementsResult;
