import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { typography } from '@style-guide/config';

const OptionsTitle = styled.p`
  margin-bottom: 15px;
  font-size: ${rem('18px', typography.baseFontSize)};
  font-weight: 700;
  font-style: italic;
`;

OptionsTitle.displayName = 'OptionsTitle';

export default OptionsTitle;
