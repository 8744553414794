import styled from 'styled-components';
import { colors } from '@style-guide/config';

const ShoppingCartItemsContainer = styled.div`
  padding: 10px 30px;
  position: relative;
  width: 100%;

  > * {
    padding: 20px;
  }

  ul {
    border-bottom: 1px solid ${colors.gray};
  }
`;

ShoppingCartItemsContainer.displayName = 'ShoppingCartItemsContainer';

export default ShoppingCartItemsContainer;
