import styled from 'styled-components';

import { colors } from '@style-guide/config';

const Subtitle = styled.h4`
  margin-bottom: 22.5px;
  padding-bottom: 7.5px;
  border-bottom: 1px solid ${colors.lightGray};
`;

Subtitle.displayName = 'Subtitle';

export default Subtitle;
