import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { typography } from '@style-guide/config';

const DataItem = styled.div`
  line-height: 1.2rem;
  margin-bottom: 2px;
  font-size: ${rem('14px', typography.baseFontSize)};

  strong {
    font-weight: bold;
  }
`;

DataItem.displayName = 'DataItem';

export default DataItem;
