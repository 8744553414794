import React from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography, breakpoints, colors } from '@style-guide/config';
import NavigationLinks from './components/NavigationLinks';
import SocialLinks from './components/SocialLinks';

const GlobalStyle = createGlobalStyle`
  [data-react-component="FooterSecondaryLinks"] {
    width: 100%;
  }
`;

const SecondaryLinksContainer = styled.div`
  align-items: center;
  background-color: ${colors.darkRed};
  display: flex;
  flex-direction: column;
  font-size: ${rem('14px', typography.baseFontSize)};
  font-weight: 700;
  justify-content: space-between;
  padding: 2rem 0 1.5rem;

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    padding: 0 2rem;
  }
`;

SecondaryLinksContainer.displayName = 'SecondaryLinksContainer';

const FooterSecondaryLinks = ({
  links = {
    functionalLinks: [],
    socialLinks: [],
  },
  isAuthenticated = false,
  loginLink,
  logoutLink,
}) => {
  const { functionalLinks, socialLinks } = links;

  const functionalLinksWithIDs = functionalLinks.map((link, index) => ({
    id: index,
    ...link,
  }));

  const socialLinksWithIDs = socialLinks.map((link, index) => ({
    id: index,
    ...link,
  }));

  return (
    <React.Fragment>
      <GlobalStyle />
      <SecondaryLinksContainer>
        <SocialLinks
          links={socialLinksWithIDs}
        />
        <NavigationLinks
          links={functionalLinksWithIDs}
          isAuthenticated={isAuthenticated}
          loginLink={loginLink}
          logoutLink={logoutLink}
        />
      </SecondaryLinksContainer>
    </React.Fragment>
  );
};

FooterSecondaryLinks.propTypes = {
  links: PropTypes.shape({
    functionalLinks: PropTypes.instanceOf(Array),
    socialLinks: PropTypes.instanceOf(Array),
  }),
  isAuthenticated: PropTypes.bool,
  loginLink: PropTypes.string.isRequired,
  logoutLink: PropTypes.string.isRequired,
};

FooterSecondaryLinks.displayName = 'FooterSecondaryLinks';

export default FooterSecondaryLinks;
