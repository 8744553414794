import React from 'react';
import PropTypes from 'prop-types';
import CookieUtil from '@services/Cookie';
import BriefcaseSVG from '@style-guide/svg/BriefcaseSVG';
import ScalesSVG from '@style-guide/svg/ScalesSVG';
import GradCapSVG from '@style-guide/svg/GradCapSVG';
import trimStart from 'lodash/trimStart';

class SpecializationActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      specialization: CookieUtil.get('cookie.visitor') || '',
    };
  }

  render() {
    const LINK_ICONS = {
      0: <BriefcaseSVG />,
      1: <ScalesSVG />,
      2: <GradCapSVG />,
    };

    const SPECIALIZATIONS = [
      'help',
      'professional',
      'student',
    ];

    const setSpecialization = (specialization) => {
      const expiration = new Date();
      expiration.setDate(expiration.getDate() + 90);

      CookieUtil.set('cookie.visitor', specialization, {
        expires: expiration,
        path: '/',
      });
    };

    const actionsList = this.props.actions.map((action, index) => (
      <li
        key={SPECIALIZATIONS[index]}
        className={SPECIALIZATIONS[index] === this.state.specialization ? 'active' : ''}
      >
        <a
          href={trimStart(action.href, '~')}
          onClick={() => setSpecialization(SPECIALIZATIONS[index])}
          title={action.title}
          target={action.target}
          id={`${this.props.location}SpecializationLink${index}`}
        >
          {LINK_ICONS[index]}
          <span>{action.text}</span>
        </a>
      </li>
    ));

    return (
      <ul className={this.props.className}>
        {actionsList}
      </ul>
    );
  }
}

SpecializationActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  location: PropTypes.string.isRequired,
};

SpecializationActions.defaultProps = {
  actions: [],
  className: '',
};

SpecializationActions.displayName = 'SpecializationActions';

export default SpecializationActions;
