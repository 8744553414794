import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, colors, typography } from '@style-guide/config';

const MembershipStatusContainer = styled.div`
  border-top: 1px solid ${colors.charcoal};
  display: flex;
  flex-direction: column;
  font-size: ${rem('12px', typography.baseFontSize)};
  justify-content: center;
  padding: 20px 0 0;
  position: relative;

  div + div {
    margin-top: 5px;
  }

  @media (min-width: ${breakpoints.l}) {
    border-left: 1px solid ${colors.charcoal};
    border-top: none;
    padding: 0 20px;
  }
`;

MembershipStatusContainer.displayName = 'MembershipStatusContainer';

export default MembershipStatusContainer;
