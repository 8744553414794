import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints, colors } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import removeNonSavedField from '@views/EditDirectory/services/removeNonSavedField';

import Involvement from './components/Involvement';

const InvolvementsListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  &:not(:first-child) {
    border-top: 1px solid ${colors.gray};
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
  }
`;

InvolvementsListItem.displayName = 'InvolvementsListItem';

const AddInvolvementContainer = styled.li`
  button {
    margin: 0;
  }
`;

AddInvolvementContainer.displayName = 'AddInvolvementContainer';

const RemoveInvolvementContainer = styled.div`
  button {
    margin: 15px 0 30px;
  }

  @media (min-width: ${breakpoints.xl}) {
    button {
      margin: 45px 0px 30px 30px;
    }
  }
`;

RemoveInvolvementContainer.displayName = 'RemoveInvolvementContainer';

const createNewInvolvement = () => ({
  Deleted: false,
  Id: 0,
  InvolvementType: '',
  Name: '',
  Position: '',
  IsSaved: false,
});

const InvolvementsList = ({
  fields = [],
  involvementTypes = [],
}) => {
  const renderAffiliations = (involvement, index) => {
    if (!fields.get(index).Deleted) {
      return (
        <InvolvementsListItem
          key={involvement}
        >
          <Involvement
            involvement={involvement}
            involvementTypes={involvementTypes}
            index={index}
          />
          <RemoveInvolvementContainer>
            <Button
              type="button"
              variant="ghost"
              onClick={() => removeNonSavedField(fields, index)}
            >
              REMOVE
            </Button>
          </RemoveInvolvementContainer>
        </InvolvementsListItem>
      );
    }

    return undefined;
  };

  return (
    <ul>
      {fields.map(renderAffiliations)}
      <AddInvolvementContainer>
        <Button
          type="button"
          onClick={() => fields.push(createNewInvolvement())}
        >
          ADD INVOLVEMENT
        </Button>
      </AddInvolvementContainer>
    </ul>
  );
};

InvolvementsList.propTypes = {
  fields: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  involvementTypes: PropTypes.arrayOf(PropTypes.string),
};

InvolvementsList.displayName = 'InvolvementsList';

export default InvolvementsList;
