import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { colors } from '@style-guide/config';

const CheckMarkIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  color: ${colors.green};
`;

CheckMarkIcon.displayName = 'CheckMarkIcon';

export default CheckMarkIcon;
