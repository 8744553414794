import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import is from 'is_js';
import { ebarContentTypesValues } from '@config';
import ResultDiv from '../shared/components/ResultDiv';
import LinkMainDiv from '../shared/components/LinkMainDiv';
import AngleSpan from '../shared/components/AngleSpan';
import LinkAnchor from '../shared/components/LinkAnchor';
import CaseSpan from '../shared/components/CaseSpan';
import ListParagraph from '../shared/components/ListParagraph';
import TitleDiv from '../shared/components/TitleDiv';
import ClearDiv from '../shared/components/ClearDiv';
import ResourceBookmark from '../shared/ResourceBookmark/ResourceBookmark';


const EBarBoardOfProfessionals = ({
  eBarBoardOfProfessionalsLists,
}) => (
  <ResultDiv>
    {eBarBoardOfProfessionalsLists.title !== undefined &&
       is.not.null(eBarBoardOfProfessionalsLists.title) &&
       eBarBoardOfProfessionalsLists.title.trim().length > 0 && (
       <TitleDiv>{eBarBoardOfProfessionalsLists.title}</TitleDiv>
    )}
    <CaseSpan>
      {eBarBoardOfProfessionalsLists.cite !== undefined &&
       is.not.null(eBarBoardOfProfessionalsLists.cite) &&
       eBarBoardOfProfessionalsLists.cite.trim().length > 0 && (
       <span>{eBarBoardOfProfessionalsLists.cite}</span>
      )}
      {(eBarBoardOfProfessionalsLists.cite !== undefined &&
       is.not.null(eBarBoardOfProfessionalsLists.cite) &&
       eBarBoardOfProfessionalsLists.cite.trim().length > 0 &&
       eBarBoardOfProfessionalsLists.issueDate !== undefined &&
       is.not.null(eBarBoardOfProfessionalsLists.issueDate) &&
       eBarBoardOfProfessionalsLists.issueDate.trim().length > 0) && (
       <span> | </span>
      )}
      {(eBarBoardOfProfessionalsLists.issueDate !== undefined &&
        is.not.null(eBarBoardOfProfessionalsLists.issueDate) &&
        eBarBoardOfProfessionalsLists.issueDate.trim().length > 0) ?
        `Issued: ${moment(eBarBoardOfProfessionalsLists.issueDate).format('MMM D, YYYY')}` : ''}
    </CaseSpan>
    {/* eslint-disable */}
    {eBarBoardOfProfessionalsLists.summary !== undefined &&
     is.not.null(eBarBoardOfProfessionalsLists.summary) &&
     eBarBoardOfProfessionalsLists.summary.trim().length > 0 && (
    <ListParagraph dangerouslySetInnerHTML={{ __html: eBarBoardOfProfessionalsLists.summary }} />
    )}
    {/* eslint-enable */}
    <LinkMainDiv>
      {((eBarBoardOfProfessionalsLists.ctaUrl !== undefined &&
       is.not.null(eBarBoardOfProfessionalsLists.ctaUrl) &&
       eBarBoardOfProfessionalsLists.ctaUrl.trim().length > 0) ||
       (eBarBoardOfProfessionalsLists.ctaLabel !== undefined &&
       is.not.null(eBarBoardOfProfessionalsLists.ctaLabel) &&
       eBarBoardOfProfessionalsLists.ctaLabel.trim().length > 0)) && (
       <div>
         <AngleSpan>
           &gt;
         </AngleSpan>
         <LinkAnchor
           href={(eBarBoardOfProfessionalsLists.ctaUrl !== undefined &&
                  is.not.null(eBarBoardOfProfessionalsLists.ctaUrl) &&
                  eBarBoardOfProfessionalsLists.ctaUrl.trim().length > 0) ?
             eBarBoardOfProfessionalsLists.ctaUrl : eBarBoardOfProfessionalsLists.ctaLabel}
           target="_blank"
           rel="noopener noreferrer"
         >
           {(eBarBoardOfProfessionalsLists.ctaLabel !== undefined &&
            is.not.null(eBarBoardOfProfessionalsLists.ctaLabel) &&
            eBarBoardOfProfessionalsLists.ctaLabel.trim().length > 0) ?
             eBarBoardOfProfessionalsLists.ctaLabel : eBarBoardOfProfessionalsLists.ctaUrl}
         </LinkAnchor>
       </div>
      )}
      <ResourceBookmark
        contentGuidString={eBarBoardOfProfessionalsLists.contentGuidString}
        type={ebarContentTypesValues.eBarBoardOfProfessionalsConduct}
        isBookmarked={eBarBoardOfProfessionalsLists.isBookmarked}
      />
    </LinkMainDiv>
    <ClearDiv />
  </ResultDiv>
);

EBarBoardOfProfessionals.propTypes = {
  eBarBoardOfProfessionalsLists: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    isBookmarked: PropTypes.bool,
  }).isRequired,
};

EBarBoardOfProfessionals.defaultProps = {
};

EBarBoardOfProfessionals.displayName = 'EBarBoardOfProfessionals';

export default EBarBoardOfProfessionals;
