import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';
import LinkButton from '@style-guide/components/LinkButton';

const ViewCartButton = styled(LinkButton)`
  @media (min-width: ${breakpoints.l}) {
    display: none;
  }
`;

ViewCartButton.displayName = 'ViewCartButton';

export default ViewCartButton;
