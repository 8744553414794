import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const smallPhoneWidth = '430px';

const CoverImageWrapper = styled.div`
  background-color: ${colors.white};
  background-image: url("${props => props.backgroundImage}");
  background-position: center;
  background-size: cover;
  height: 250px;
  max-width: ${breakpoints.xl};
  overflow: hidden;
  position: relative;
  width: 100%;

  @media (min-width: ${breakpoints.m}) {
    height: 220px;
  }

  @media (max-width: ${smallPhoneWidth}) {
    height: 300px;
  }
`;

CoverImageWrapper.displayName = 'CoverImageWrapper';

export default CoverImageWrapper;
