import styled from 'styled-components';

const AuthorPicture = styled.img`
  border-radius: 50%;
  height: 45px;
  margin-right: 10px;
  width: 45px;
`;

AuthorPicture.displayName = 'AuthorPicture';

export default AuthorPicture;
