import styled from 'styled-components';

const CaseResultTr = styled.tr`
  color: ${props => (props.isActiveDateNull ? 'red' : '')}; 
  background-color: ${props => (props.isActiveDateNull ? 'aliceblue' : '#EDEDED')};
`;

CaseResultTr.displayName = 'CaseResultTr';

export default CaseResultTr;
