import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { colors } from '@style-guide/config';

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.white};
  font-size: 2rem;
`;

const CloseButton = ({
  onClick,
}) => (
  <button
    type="button"
    onClick={() => onClick()}
    style={{ float: 'right' }}
  >
    <Icon
      icon={faTimesCircle}
    />
  </button>
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

CloseButton.displayName = 'CloseButton';

export default CloseButton;
