import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { breakpoints } from '@style-guide/config';

import { required } from '@services/FormValidators';

import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormInput from '@components/ReduxFormInput';

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: ${breakpoints.s}) {
    flex-direction: row;

    > div:not(:last-of-type) {
      margin-right: 15px;
    }
  }

  @media(min-width: ${breakpoints.l}) {
    flex-wrap: nowrap;
  }
`;

LinkContainer.displayName = 'LinkContainer';

const LinkItem = ({
  link,
  index,
}) => (
  <LinkContainer>
    <FormFieldWrapper>
      <Field
        id={`LinkName${index}`}
        label="Name*"
        name={`${link}.Name`}
        component={ReduxFormInput}
        validate={required}
      />
    </FormFieldWrapper>
    <FormFieldWrapper>
      <Field
        id={`LinkUrl${index}`}
        label="Link Url*"
        name={`${link}.Url`}
        component={ReduxFormInput}
        validate={required}
      />
    </FormFieldWrapper>
  </LinkContainer>
);

LinkItem.propTypes = {
  link: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

LinkItem.displayName = 'LinkItem';

export default LinkItem;
