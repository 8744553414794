import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { addToCart } from '@redux/modules/cart';

import { breakpoints, colors } from '@style-guide/config';
import Button from '@style-guide/components/Button';

import ErrorMessage from '@components/ErrorMessage';
import ShoppingCartIcon from '@components/ShoppingCartIcon';

const SectionItemContainer = styled.li`
  margin-bottom: 40px;
  border-bottom: 1px solid ${colors.darkGray};
  padding-bottom: 40px;

  &:last-child {
    border-bottom: none;
  }

  p {
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 300;
  }

  @media (min-width: ${breakpoints.m}) {
    display: flex;
    flex-wrap: wrap;

    p:first-child {
      flex: 50%;
    }

    p:nth-child(2) {
      flex: 20%;
    }
  }

  @media (min-width: ${breakpoints.l}) {
    margin-bottom: 30px;
    border-bottom: 1px solid ${colors.darkGray};
    padding-bottom: 30px;
  }
`;

SectionItemContainer.displayName = 'SectionItemContainer';

const SectionErrorMessage = styled(ErrorMessage)`
  padding: 0px;
  text-align: center;
  padding-right: 30px;
  min-width: 100%;

  @media (min-width: ${breakpoints.m}) {
    flex: 100%;
  }
`;

SectionErrorMessage.displayName = 'SectionErrorMessage';

const Labels = styled.span`
  display: block;
  font-weight: bold;
`;

Labels.displayName = 'Labels';

const ButtonContainer = styled.div`
  button {
    margin: 10px 0;
  }

  @media (min-width: ${breakpoints.m}) {
    flex: 100%;
  }

  @media (min-width: ${breakpoints.l}) {
    flex: 20%;

    button {
      margin: 0px;
    }
  }
`;

ButtonContainer.displayName = 'ButtonContainer';

class SectionListItem extends React.Component {
  constructor(props) {
    super(props);

    this.addToCart = this.addToCart.bind(this);
  }

  addToCart() {
    this.props.dispatch(addToCart(this.props.section.contentGuid));
    this.addingToCart = true;
  }

  render() {
    const {
      section,
      error,
      message,
      currentGuid,
      loading,
      lineItems,
    } = this.props;

    const isSuccessState = (
      !loading &&
      !error &&
      !message &&
      this.addingToCart
    );

    const isErrorState = (
      !loading &&
      (error || message) &&
      this.addingToCart
    );

    const isSectionInTheCart = !!lineItems.filter(item =>
      item.ItemId === section.contentGuid).length;

    this.addingToCart = this.addingToCart && loading && currentGuid === section.contentGuid;

    return (
      <SectionItemContainer>
        <p>
          <span>{section.name}</span>
        </p>
        <p>
          <Labels>Price: </Labels><span>{section.price}</span>
        </p>
        <ButtonContainer>
          {section.selected && (
            <h4>Current Member</h4>
          )}
          {!section.selected && (
            <Button
              id={`section${section.id}AddToCartButton`}
              onClick={this.addToCart}
              disabled={this.addingToCart}
              isSuccess={isSuccessState || isSectionInTheCart}
            >
              {(isSuccessState || isSectionInTheCart) ? 'Added To Cart' : 'Add To Cart'}
              {(!isSuccessState && !isSectionInTheCart) && <ShoppingCartIcon />}
            </Button>
          )}
        </ButtonContainer>
        {isErrorState && (
          <SectionErrorMessage>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: error || message }} />
          </SectionErrorMessage>
        )}
      </SectionItemContainer>
    );
  }
}

SectionListItem.propTypes = {
  loading: PropTypes.bool,
  section: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    contentGuid: PropTypes.string,
    price: PropTypes.string,
    subscriptionType: PropTypes.string,
    selected: PropTypes.bool,
  }),
  error: PropTypes.string,
  message: PropTypes.string,
  currentGuid: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.object),
};

SectionListItem.defaultProps = {
  loading: true,
  section: {},
  error: '',
  message: '',
  currentGuid: '',
  lineItems: [],
};

SectionListItem.displayName = 'SectionListItem';

const mapStateToProps = ({ cart }) => ({
  loading: cart.loading,
  error: cart.error,
  message: cart.message,
  currentGuid: cart.currentGuid,
  lineItems: cart.lineItems,
});

export default connect(mapStateToProps)(SectionListItem);
