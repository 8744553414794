import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoadingOverlay from '@components/LoadingOverlay';

const ShellLoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: ${props => (props.height ? props.height : 'auto')};
  justify-content: center;
  position: relative;
  width: ${props => (props.width ? props.width : '100%')};

  span:not(:first-child) {
    padding-top: 60px;
  }
`;

ShellLoadingContainer.displayName = 'ShellLoadingContainer';

const ShellLoading = ({
  height = 'auto',
  width = '100%',
  message = '',
}) => (
  <ShellLoadingContainer
    height={height}
    width={width}
  >
    <LoadingOverlay />
    {message && <span>{message}</span>}
  </ShellLoadingContainer>
);

ShellLoading.displayName = 'ShellLoading';

ShellLoading.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  message: PropTypes.string,
};

export default ShellLoading;
