import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';

const SpeakerCompany = styled.span`
  display: block;
  font-size: ${rem('16px', typography.baseFontSize)};
  font-style: italic;
`;

SpeakerCompany.displayName = 'SpeakerCompany';

export default SpeakerCompany;
