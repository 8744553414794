import moment from 'moment';
import ApiGenerator from '@services/ApiGenerator';

const HYDRATE = 'osba/ebarHomePage/EBARHOMEPAGE_INFO_HYDRATE';
const GETDATA_ON_CLICK_PREVIOUS = 'osba/ebarHomePage/EBARHOMEPAGE_INFO_GETDATA_ON_CLICK_PREVIOUS';
const GETDATA_ON_CLICK_PREVIOUS_ERROR = 'osba/ebarHomePage/EBARHOMEPAGE_INFO_GETDATA_ON_CLICK_PREVIOUS_ERROR';
const GETDATA_ON_CLICK_PREVIOUS_RECEIVED = 'osba/ebarHomePage/EBARHOMEPAGE_INFO_GETDATA_ON_CLICK_PREVIOUS_RECEIVED';
const GETDATA_ON_CLICK_NEXT = 'osba/ebarHomePage/EBARHOMEPAGE_INFO_GETDATA_ON_CLICK_NEXT';
const GETDATA_ON_CLICK_NEXT_ERROR = 'osba/ebarHomePage/EBARHOMEPAGE_INFO_GETDATA_ON_CLICK_NEXT_ERROR';
const GETDATA_ON_CLICK_NEXT_RECEIVED = 'osba/ebarHomePage/EBARHOMEPAGE_INFO_GETDATA_ON_CLICK_NEXT_RECEIVED';
const GETDATA_ON_OPTION_CHANGE = 'osba/ebarHomePage/EBARHOMEPAGE_INFO_GETDATA_ON_OPTION_CHANGE';
const GETDATA_ON_OPTION_CHANGE_ERROR = 'osba/ebarHomePage/EBARHOMEPAGE_INFO_GETDATA_ON_OPTION_CHANGE_ERROR';
const GETDATA_ON_OPTION_CHANGE_RECEIVED = 'osba/ebarHomePage/EBARHOMEPAGE_INFO_GETDATA_ON_OPTION_CHANGE_RECEIVED';

const initialState = {
  loading: false,
  error: '',
  message: '',
  casesState: [],
  eBarEnactmentsListsState: [],
  eBarShowSCOAnnouncementState: false,
  eBarTotalSCOAnnouncementState: 0,
  eBarSCOCaseAnnouncementsListsState: [],
  eBarSCOAdministrativeListsState: [],
  eBarSCOOhioRulesListsState: [],
  eBarBoardOfProfessionalsListsState: [],
  eBarAttorneysListsState: [],
  eBarEthicsListsState: [],
  eBarOtherAnnouncementsListsState: [],
  eBarJobsListsState: [],
  selectedOption: 1,
  startDateDaily: moment(),
  startDateWeekly: (moment().day() === 5 || moment().day() === 6 || moment().day() === 7) ? moment().startOf('isoWeek') : moment().subtract(7, 'days').startOf('isoWeek'),
  isNextDisabled: true,
};

const onClickPrevious = (selectedOption, startDateDaily, startDateWeekly) => {
  let start;
  let week;
  if (selectedOption === 1) {
    start = startDateDaily.subtract(1, 'days');
    week = moment().subtract(7, 'days').startOf('isoWeek');
  } else if (selectedOption === 2) {
    start = moment();
    week = startDateWeekly.subtract(7, 'days');
  }
  return {
    loading: true,
    startDateDaily: start,
    startDateWeekly: week,
    isNextDisabled: false,
    selectedOption,
  };
};

const onClickNext = (selectedOption, startDateDaily, startDateWeekly) => {
  let start;
  let week;
  let isNext;
  if (selectedOption === 1) {
    const updatedStartDateDaily = startDateDaily.add(1, 'days');
    isNext = updatedStartDateDaily.isSameOrAfter(moment(), 'day');
    start = updatedStartDateDaily;
    week = moment().subtract(7, 'days').startOf('isoWeek');
  } else if (selectedOption === 2) {
    const updatedStartDateWeekly = startDateWeekly.add(7, 'days');
    isNext = updatedStartDateWeekly.isSameOrAfter(moment().subtract(7, 'days').startOf('isoWeek'), 'day');
    start = moment();
    week = updatedStartDateWeekly;
  }
  return {
    loading: true,
    isNextDisabled: isNext,
    startDateDaily: start,
    startDateWeekly: week,
    selectedOption,
  };
};

const handleOptionChange = event => ({
  loading: true,
  selectedOption: parseInt(event.target.value, 10),
  startDateDaily: moment(),
  startDateWeekly: (moment().day() === 5 || moment().day() === 6 || moment().day() === 0) ? moment().startOf('isoWeek') : moment().subtract(7, 'days').startOf('isoWeek'),
  isNextDisabled: true,
});

const getStartDateValue = (action) => {
  const startDate = action.data.selectedOption === 1 ?
    action.data.startDateDaily.format('MM/DD/YYYY') :
    action.data.startDateWeekly.format('MM/DD/YYYY');

  return startDate;
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.data,
      };
    case GETDATA_ON_CLICK_PREVIOUS:
      return {
        ...state,
        ...action.data,
        loading: true,
        message: '',
        error: '',
      };
    case GETDATA_ON_CLICK_PREVIOUS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GETDATA_ON_CLICK_PREVIOUS_RECEIVED:
      return {
        ...state,
        ...action.data,
        casesState: action.data.practiceAreas,
        eBarEnactmentsListsState: action.data.EBarEnactmentsLinks,
        eBarShowSCOAnnouncementState: action.data.EBarShowSCOAnnouncement,
        eBarTotalSCOAnnouncementState: action.data.TotalEBarSCOAnnounement,
        eBarSCOCaseAnnouncementsListsState: action.data.EBarSCOCaseAnnouncementsLinks,
        eBarSCOAdministrativeListsState: action.data.EBarSCOAdministrativeLinks,
        eBarSCOOhioRulesListsState: action.data.EBarSCOOhioRulesLinks,
        eBarBoardOfProfessionalsListsState: action.data.EBarBoardOfProfessionalsLinks,
        eBarAttorneysListsState: action.data.EBarAttorneysLinks,
        eBarEthicsListsState: action.data.EBarEthicsLinks,
        eBarOtherAnnouncementsListsState: action.data.EBarOtherAnnouncementsLinks,
        eBarJobsListsState: action.data.EBarJobsLinks,
        message: action.data.message,
        loading: false,
      };
    case GETDATA_ON_CLICK_NEXT:
      return {
        ...state,
        ...action.data,
        loading: true,
        message: '',
        error: '',
      };
    case GETDATA_ON_CLICK_NEXT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GETDATA_ON_CLICK_NEXT_RECEIVED:
      return {
        ...state,
        ...action.data,
        casesState: action.data.practiceAreas,
        eBarEnactmentsListsState: action.data.EBarEnactmentsLinks,
        eBarShowSCOAnnouncementState: action.data.EBarShowSCOAnnouncement,
        eBarTotalSCOAnnouncementState: action.data.TotalEBarSCOAnnounement,
        eBarSCOCaseAnnouncementsListsState: action.data.EBarSCOCaseAnnouncementsLinks,
        eBarSCOAdministrativeListsState: action.data.EBarSCOAdministrativeLinks,
        eBarSCOOhioRulesListsState: action.data.EBarSCOOhioRulesLinks,
        eBarBoardOfProfessionalsListsState: action.data.EBarBoardOfProfessionalsLinks,
        eBarAttorneysListsState: action.data.EBarAttorneysLinks,
        eBarEthicsListsState: action.data.EBarEthicsLinks,
        eBarOtherAnnouncementsListsState: action.data.EBarOtherAnnouncementsLinks,
        eBarJobsListsState: action.data.EBarJobsLinks,
        message: action.data.message,
        loading: false,
      };
    case GETDATA_ON_OPTION_CHANGE:
      return {
        ...state,
        ...action.data,
        loading: true,
        message: '',
        error: '',
      };
    case GETDATA_ON_OPTION_CHANGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GETDATA_ON_OPTION_CHANGE_RECEIVED:
      return {
        ...state,
        ...action.data,
        casesState: action.data.practiceAreas,
        eBarEnactmentsListsState: action.data.EBarEnactmentsLinks,
        eBarShowSCOAnnouncementState: action.data.EBarShowSCOAnnouncement,
        eBarTotalSCOAnnouncementState: action.data.TotalEBarSCOAnnounement,
        eBarSCOCaseAnnouncementsListsState: action.data.EBarSCOCaseAnnouncementsLinks,
        eBarSCOAdministrativeListsState: action.data.EBarSCOAdministrativeLinks,
        eBarSCOOhioRulesListsState: action.data.EBarSCOOhioRulesLinks,
        eBarBoardOfProfessionalsListsState: action.data.EBarBoardOfProfessionalsLinks,
        eBarAttorneysListsState: action.data.EBarAttorneysLinks,
        eBarEthicsListsState: action.data.EBarEthicsLinks,
        eBarOtherAnnouncementsListsState: action.data.EBarOtherAnnouncementsLinks,
        eBarJobsListsState: action.data.EBarJobsLinks,
        message: action.data.message,
        loading: false,
      };
    default:
      return state;
  }
};

export const hydrateEBarHomePageInfo = allProps => ({
  type: HYDRATE,
  data: {
    casesState: allProps.casesWithPracticeAreas,
    eBarEnactmentsListsState: allProps.eBarEnactmentsLists,
    eBarShowSCOAnnouncementState: allProps.eBarShowSCOAnnouncementLists,
    eBarTotalSCOAnnouncementState: allProps.totalEBarSCOAnnounement,
    eBarSCOCaseAnnouncementsListsState: allProps.eBarSCOCaseAnnouncementsLists,
    eBarSCOAdministrativeListsState: allProps.eBarSCOAdministrativeLists,
    eBarSCOOhioRulesListsState: allProps.eBarSCOOhioRulesLists,
    eBarBoardOfProfessionalsListsState: allProps.eBarBoardOfProfessionalsLists,
    eBarAttorneysListsState: allProps.eBarAttorneysLists,
    eBarEthicsListsState: allProps.eBarEthicsLists,
    eBarOtherAnnouncementsListsState: allProps.eBarOtherAnnouncementsLists,
    eBarJobsListsState: allProps.eBarJobsLists,
  },
});

export const onClickPreviousEBarHomePageInfo =
(selectedOption, startDateDaily, startDateWeekly) => ({
  type: GETDATA_ON_CLICK_PREVIOUS,
  data: onClickPrevious(selectedOption, startDateDaily, startDateWeekly),
});

export const onClickNextEBarHomePageInfo = (selectedOption, startDateDaily, startDateWeekly) => ({
  type: GETDATA_ON_CLICK_NEXT,
  data: onClickNext(selectedOption, startDateDaily, startDateWeekly),
});

export const onChangeEBarHomePageInfo = e => ({
  type: GETDATA_ON_OPTION_CHANGE,
  data: handleOptionChange(e),
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);
  switch (action.type) {
    case GETDATA_ON_CLICK_PREVIOUS:
      api(
        'get',
        `/EBarHomePage/GetAllBlocks?frequency=${action.data.selectedOption}&startDate=${getStartDateValue(action)}`,
        GETDATA_ON_CLICK_PREVIOUS,
      );
      break;
    case GETDATA_ON_CLICK_NEXT:
      api(
        'get',
        `/EBarHomePage/GetAllBlocks?frequency=${action.data.selectedOption}&startDate=${getStartDateValue(action)}`,
        GETDATA_ON_CLICK_NEXT,
      );
      break;
    case GETDATA_ON_OPTION_CHANGE:
      api(
        'get',
        `/EBarHomePage/GetAllBlocks?frequency=${action.data.selectedOption}&startDate=${getStartDateValue(action)}`,
        GETDATA_ON_OPTION_CHANGE,
      );
      break;
    default:
      break;
  }
};

export default reducer;
