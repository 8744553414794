import React from 'react';
import PropTypes from 'prop-types';

import BlockWrapper from './components/BlockWrapper';
import Link from './components/Link';
import Wrapper from './components/Wrapper';

const CLEAdminHome = ({
  links,
}) => {
  const parsedLinks = JSON.parse(links);

  const transformedLinks = parsedLinks.map((link, index) => ({
    id: index,
    ...link,
  }));

  return (
    <BlockWrapper>
      <Wrapper>
        {transformedLinks.map((link => (
          /* eslint-disable */
          // not sure why this is throwing an error for jsx-a11y/anchor-is-valid. It's not an anchor...
          <Link
            key={link.id}
            label={link.Title}
            url={link.Href}
          />
          /* eslint-enable */
        )))}
      </Wrapper>
    </BlockWrapper>
  );
};

CLEAdminHome.propTypes = {
  /** the links to the news items. */
  links: PropTypes.string.isRequired,
};

CLEAdminHome.displayName = 'CLEAdminHome';

export default CLEAdminHome;
