import styled from 'styled-components';

import { colors } from '@style-guide/config';

import Title from './Title';

const ListItem = styled.a`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: ${colors.black};
  transition: scale 200ms;

  &:hover,
  &:active {
    transform: scale(1.0125);

    ${Title} {
      color: ${colors.darkRed};
    }
  }
`;

ListItem.displayName = 'ListItem';

export default ListItem;
