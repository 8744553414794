import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@style-guide/config';

/**
# SelfStudySVG
An SVG self study events icon
 */
const SelfStudySVG = ({
  height = '82px',
  width = '50px',
  color = colors.white,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 82 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Self study icon</title>
    <desc>An icon for self study CLE courses</desc>
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        fill={color}
        fillRule="nonzero"
      >
        <path
          d="M75.2,20.3 C77.1,22.1 78.5,24.2 79.7,26.7 C80.8,29.2 81.4,31.8 81.4,34.6 C81.4,34.7 81.4,
          34.7 81.4,34.8 C81.4,34.9 81.4,34.9 81.4,35 C81.4,35.1 81.4,35.1 81.4,35.1 L81.4,35.2 C81.4,
          38 80.9,40.6 79.8,43.1 C78.7,45.6 77.2,47.7 75.3,49.5 C74.1,50.7 72.6,51.8 71,52.7 C69.4,53.6 67.6,
          54.3 65.7,54.8 C65.7,54 65.7,52.8 65.7,51.3 C65.7,49.8 65.7,48.7 65.7,47.8 L65.8,47.8 C66.7,
          47.5 67.6,47 68.4,46.5 C69.2,46 70,45.4 70.6,44.7 C71.9,43.5 72.9,42 73.7,40.4 C74.5,38.7 74.8,
          37 74.8,35.1 C74.8,35 74.8,35 74.8,34.9 C74.8,34.8 74.8,34.8 74.8,34.7 C74.8,34.6 74.8,34.6 74.8,
          34.5 C74.8,32.6 74.4,30.9 73.7,29.2 C72.9,27.5 71.9,26.1 70.6,24.9 C69.3,23.6 67.8,22.5 66,
          21.7 C64.2,20.9 62.3,20.5 60.3,20.5 L60.2,20.5 C59.7,20.5 59.3,20.5 58.9,20.6 C58.5,20.7 58.1,
          20.7 57.8,20.8 L54.9,21.2 L54.1,18.3 C53.6,16.7 52.9,15.2 51.9,13.8 C50.9,12.5 49.8,11.3 48.4,
          10.3 C47.1,9.3 45.6,8.5 43.9,7.9 C42.3,7.3 40.6,7 38.8,7 C38.7,7 38.7,7 38.5,7 C38.4,7 38.4,7 38.3,
          7 C38.2,7 38.2,7 38.1,7 C35.9,7 33.8,7.4 31.9,8.3 C29.9,9.1 28.3,10.3 26.8,11.8 C25.3,13.2 24.1,
          14.8 23.3,16.7 C22.5,18.6 22,20.6 22,22.8 L22,23 C22,23.2 22,23.4 22,23.6 C22,23.8 22,24 22,24.1 C22,
          24.3 22,24.5 22,24.7 C22,24.9 22,25.1 22,25.2 L22.7,28.8 L17.3,28.8 L17.2,28.8 L17.1,28.8 C15.8,
          28.8 14.5,29.1 13.3,29.6 C12.1,30.1 11,30.8 10.1,31.7 C9.2,32.5 8.5,33.6 8,34.7 C7.5,35.9 7.2,
          37.1 7.2,38.5 C7.2,38.6 7.2,38.6 7.2,38.7 L7.2,38.8 C7.2,40.1 7.5,41.3 8,42.5 C8.5,43.7 9.3,44.7 10.2,
          45.4 C11,46.2 11.9,46.8 12.9,47.3 C13.9,47.8 15,48.1 16.2,48.2 C16.2,48.6 16.2,49 16.2,49.5 C16.2,
          49.9 16.2,50.4 16.2,50.9 C16.2,52.4 16.2,53.5 16.2,54.4 C16.2,54.5 16.2,54.6 16.2,54.7 C16.2,
          54.8 16.2,54.9 16.1,55 C14,54.9 12.1,54.4 10.2,53.6 C8.4,52.8 6.8,51.7 5.4,50.3 C3.8,48.8 2.6,
          47.1 1.7,45.2 C0.8,43.2 0.4,41.1 0.4,38.9 L0.4,38.8 C0.4,38.7 0.4,38.7 0.4,38.6 C0.4,38.5 0.4,
          38.5 0.4,38.4 C0.4,36.2 0.8,34.1 1.7,32.1 C2.6,30.1 3.8,28.4 5.3,27 C6.5,25.7 8,24.7 9.7,23.8 C11.4,
          23 13.2,22.5 15.1,22.2 C15.2,19.2 15.8,16.3 17,13.7 C18.2,11 19.8,8.7 21.9,6.8 C23.9,4.8 26.3,3.2 29,
          2 C31.7,0.8 34.7,0.2 37.8,0.2 C37.9,0.2 37.9,0.2 38,0.2 C38.1,0.2 38.2,0.2 38.2,0.2 C38.3,0.2 38.3,
          0.2 38.3,0.2 L38.4,0.2 C41,0.2 43.4,0.6 45.8,1.4 C48.1,2.2 50.2,3.4 52.1,4.8 C53.7,5.9 55.1,7.2 56.3,
          8.7 C57.5,10.2 58.5,11.9 59.3,13.7 L60.1,13.7 C60.2,13.7 60.2,13.7 60.3,13.7 C60.4,13.7 60.5,
          13.7 60.6,13.7 C63.4,13.7 66,14.2 68.5,15.3 C71.1,17 73.2,18.4 75.2,20.3 Z M60.8,48.2 C60.7,
          47.4 60.7,46.3 60.5,45 C60.4,44.4 60.4,43.9 60.3,43.4 C60.2,42.9 60.1,42.4 60,41.9 C59.9,41.3 59.7,
          40.8 59.4,40.4 C59.1,40 58.8,39.6 58.5,39.2 C58.1,38.9 57.7,38.6 57.3,38.4 C56.9,38.2 56.6,38 56.3,
          38 C54.1,37.8 51.9,37.7 49.6,37.6 C47.3,37.5 45.1,37.4 42.8,37.4 C42.5,37.4 42.1,37.4 41.8,
          37.4 C41.5,37.4 41.2,37.4 41,37.4 C40.6,37.4 40.2,37.4 39.8,37.4 C39.4,37.4 39,37.4 38.6,37.4 C36.4,
          37.4 34.2,37.4 32.1,37.5 C29.9,37.6 27.9,37.7 25.9,37.9 C25.4,38 24.9,38.2 24.5,38.4 C24,38.6 23.7,
          38.9 23.3,39.2 C22.9,39.5 22.6,39.9 22.3,40.4 C22,40.9 21.9,41.3 21.8,41.7 C21.7,42.2 21.6,42.8 21.5,
          43.3 C21.4,43.8 21.3,44.5 21.3,45 C21.1,46.2 21,47.2 21,48 C21,48.8 21,49.9 21,51.4 C21,52.7 21,
          53.8 21,54.7 C21.1,55.5 21.2,56.6 21.3,57.9 C21.4,58.5 21.4,59 21.5,59.5 C21.6,60 21.7,60.6 21.8,
          61.1 C21.9,61.6 22.1,62.1 22.3,62.5 C22.6,62.9 22.9,63.3 23.3,63.7 C23.6,64 24,64.3 24.4,64.5 C24.8,
          64.7 25.1,64.9 25.4,64.9 C27.6,65.1 29.9,65.3 32.1,65.4 C34.3,65.5 36.6,65.5 38.9,65.5 C39.2,
          65.5 39.6,65.5 39.9,65.5 C40.2,65.5 40.5,65.5 40.7,65.5 C41,65.5 41.4,65.5 41.8,65.5 C42.2,65.5 42.5,
          65.5 42.9,65.5 C45.1,65.5 47.4,65.5 49.6,65.4 C51.8,65.3 53.9,65.2 55.9,65 C56.4,64.9 56.9,64.7 57.3,
          64.5 C57.7,64.3 58.1,64 58.5,63.7 C58.9,63.4 59.2,63 59.5,62.5 C59.8,62.1 59.9,61.6 60,61.2 C60.1,
          60.6 60.2,60.1 60.3,59.5 C60.4,58.9 60.5,58.4 60.5,57.7 C60.7,56.5 60.8,55.5 60.8,54.8 C60.8,54 60.8,
          52.9 60.8,51.4 C60.8,50.1 60.8,49 60.8,48.2 Z M49.4,51.5 C49.4,51.6 49.4,51.6 49.4,51.7 C49.4,
          51.9 49.3,52.1 49.2,52.3 C49.1,52.5 48.9,52.7 48.7,52.8 L37.3,59.9 C37.2,60 37.1,60.1 37,60.1 C36.9,
          60.1 36.7,60.1 36.6,60.1 L36.5,60.1 C36.4,60.1 36.3,60.1 36.2,60.1 C36.1,60.1 36,60 35.9,60 C35.7,
          59.9 35.6,59.7 35.4,59.5 C35.3,59.3 35.2,59.1 35.2,58.8 L35.2,58.7 L35.2,44.5 L35.2,44.4 C35.2,
          44.1 35.3,43.9 35.4,43.7 C35.5,43.5 35.7,43.4 35.9,43.2 C36,43.1 36.1,43.1 36.3,43.1 C36.4,43.1 36.6,
          43.1 36.7,43.1 C36.8,43.1 36.9,43.1 37.1,43.1 C37.2,43.1 37.3,43.2 37.4,43.2 L48.8,50.3 C49,
          50.4 49.2,50.6 49.3,50.8 C49.4,51 49.4,51.2 49.4,51.5 Z"
        />
      </g>
    </g>
  </svg>
);

SelfStudySVG.propTypes = {
  /** height for the SVG. Can be px, rem, percentage, etc. */
  height: PropTypes.string,
  /** width for the SVG. Can be px, rem, percentage, etc. */
  width: PropTypes.string,
  /** a color for the icon */
  color: PropTypes.string,
};

SelfStudySVG.displayName = 'SelfStudySVG';

export default SelfStudySVG;
