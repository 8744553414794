import styled from 'styled-components';

const WrapperInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

WrapperInner.displayName = 'WrapperInner';

export default WrapperInner;
