import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, typography } from '@style-guide/config';

const ItemPrice = styled.span`
  flex-shrink: 0;
  font-weight: 500;
  padding-left: 15px;

  > * {
    margin-left: 15px;
  }

  @media (min-width: ${breakpoints.l}) {
    font-size: ${rem('12px', typography.baseFontSize)};
  }
`;

ItemPrice.displayName = 'ItemPrice';

export default ItemPrice;
