import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@style-guide/config';
import Arrow from '@style-guide/mixins/Arrow';
import SpecializationActions from '@components/SpecializationActions';

const arrowOptions = {
  arrow: 'right',
  position: 'after',
  color: colors.white,
  size: '12px',
};

const StyledSpecializationActions = styled(SpecializationActions)`
  padding-bottom: 10px;

  li {
    margin: 0 -20px;
    padding: 0 20px;

    &.active {
      background-color: ${colors.darkRed};
    }

    span {
      ${Arrow(arrowOptions)}

      &::after {
        margin-left: 10px;
      }
    }
  }

  a {
    align-items: center;
    color: ${colors.white};
    display: flex;
    height: 40px;
    padding-left: 15px;
  }

  svg {
    fill: ${colors.white};
    margin-right: 20px;
    width: 30px;
  }
`;

StyledSpecializationActions.displayName = 'StyledSpecializationActions';

const FlyoutActions = ({ actions }) => (
  <StyledSpecializationActions
    actions={actions}
    location="flyout"
  />
);

FlyoutActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
};

FlyoutActions.defaultProps = {
  actions: [],
};

FlyoutActions.displayName = 'FlyoutActions';

export default FlyoutActions;
