import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

import Divider from '@components/Divider';

import OverviewContentBlock from './components/OverviewContentBlock';
import AffiliationsContentBlock from './components/AffiliationsContentBlock';
import EducationContentBlock from './components/EducationContentBlock';
import ExperienceContentBlock from './components/ExperienceContentBlock';
import HonorsContentBlock from './components/HonorsContentBlock';
import ParticipationContentBlock from './components/ParticipationContentBlock';
import LinksContentBlock from './components/LinksContentBlock';

const contentBlockMap = {
  Overview: OverviewContentBlock,
  Affiliations: AffiliationsContentBlock,
  Education: EducationContentBlock,
  Experience: ExperienceContentBlock,
  Honors: HonorsContentBlock,
  Participation: ParticipationContentBlock,
  Links: LinksContentBlock,
};

const ContentBlockWrapper = styled.div`
  padding: 0px;

  @media (min-width: ${breakpoints.m}) {
    margin: 30px 0px;
    padding: 0 15px;
  }
`;

ContentBlockWrapper.displayName = 'ContentBlockWrapper';

const DirectoryProfileContentBlock = ({
  title,
  contentBlock,
  id,
}) => {
  const Component = contentBlockMap[title];

  return (
    <ContentBlockWrapper id={id}>
      <h3>{title}</h3>
      <Divider />
      <Component contentToRender={contentBlock} />
    </ContentBlockWrapper>
  );
};

DirectoryProfileContentBlock.propTypes = {
  title: PropTypes.string,
  // the shape of this object could change depending on what's passed in
  /* eslint-disable react/forbid-prop-types */
  contentBlock: PropTypes.object,
  id: PropTypes.string,
};

DirectoryProfileContentBlock.defaultProps = {
  title: '',
  contentBlock: {},
  id: '',
};

DirectoryProfileContentBlock.displayName = 'DirectoryProfileContentBlock';

export default DirectoryProfileContentBlock;
