import React from 'react';
import PropTypes from 'prop-types';

import ProgressTrackerContainer from './components/ProgressTrackerContainer';
import ProgressTrackerTitle from './components/ProgressTrackerTitle';
import ProgressTrackerStep from './components/ProgressTrackerStep';

const ProgressTracker = ({
  // currentStep,
  steps,
  convertedChecks,
  width,
}) => {
  const trackerSteps = [];
  for (let i = 0; i < steps; i += 1) {
    if (i !== steps - 1) {
      trackerSteps.push(
        <React.Fragment key={i}>  {/* Add key here */}
          <ProgressTrackerStep
            key={`step-${i}`}
            className={convertedChecks.includes((i + 1).toString()) ? 'active' : ''}
          />
          {width > 720 && width < 1200 && <li key={`line-${i}`} style={{ color: 'black', padding: '5px' }}>  ....................  </li>}
          {width >= 1200 && <li key={`long-line-${i}`} style={{ color: 'black', padding: '5px' }}>  ..............................  </li>}
        </React.Fragment>,
      );
    } else {
      trackerSteps.push(
        <ProgressTrackerStep
          key={`step-${i}`}
          className={convertedChecks.includes((i + 1).toString()) ? 'active' : ''}
        />,
      );
    }
  }

  return (
    <React.Fragment>
      <ProgressTrackerTitle>
        Checkpoints
      </ProgressTrackerTitle>
      <ProgressTrackerContainer>
        {trackerSteps}
      </ProgressTrackerContainer>
    </React.Fragment>
  );
};

ProgressTracker.propTypes = {
  // currentStep: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
  convertedChecks: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

ProgressTracker.displayName = 'ProgressTracker';

export default ProgressTracker;
