import React from 'react';
import styled from 'styled-components';
import is from 'is_js';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { breakpoints } from '@style-guide/config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { courseFormat as courseFormatPropTypes } from '@services/PropTypes';
import CLEContentCard from '@components/CLEContentCard';
import SpecializationCreditSVG from '@components/SpecializationCreditSVG';
import Store from '@redux';

import renderSpecializationCredits from './services/renderSpecializationCredits';
import BrochureDownload from './components/BrochureDownload';
import DetailList from './components/DetailList';
import Divider from './components/Divider';
import Locations from './components/Locations';
import LocationsContainer from './components/Locations/components/LocationsContainer';
import PrimaryLocation from './components/Locations/components/PrimaryLocation';
import PriceList from './components/PriceList';
import Register from './components/Register';

const DetailsContainerCard = styled(CLEContentCard)`
  @media (min-width: ${breakpoints.l}) {
    width: 350px;
  }
`;

DetailsContainerCard.displayName = 'DetailsContainerCard';

const SpecializationCreditBlock = styled.div`
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 10px;

  span:nth-of-type(2) {
    line-height: 28px;
    margin-right: 4px;
  }

  strong {
    font-weight: 700;
  }
`;

SpecializationCreditBlock.displayName = 'SpecializationCreditBlock';

const PricingHeading = styled.h2`
  margin-top: 15px;
`;

PricingHeading.displayName = 'PricingHeading';

const Details = ({
  addOnsActive = [],
  brochureLink = '',
  contentGuid = '',
  zoomMeetingUrl = '',
  date = '',
  endTime = '',
  startTime = '',
  courseFormat = {
    courseFormatType: '',
    name: '',
    sourceId: '',
    type: 1,
  },
  credits = [],
  courseID = '',
  status = '',
  prices = [],
  location = {
    displayName: '',
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    city: '',
    state: '',
    postalCode: '',
  },
  attendanceTypeOptions = {},
  additionalLocations = [],
  practiceAreas = [],
  specializationCredits = [],
  disableAddToCart = false,
  updateShowCart = () => {},
  isMeetingEnded,
}) => {
  const store = Store.get();
  const practiceAreaList = practiceAreas.map((practiceArea, index) => (
    <React.Fragment
      key={practiceArea.sourceId}
    >
      {practiceArea.displayName}{index + 1 < practiceAreas.length && ', '}
    </React.Fragment>
  ));

  const creditsList = credits.map((credit, index) => (
    <React.Fragment
      key={credit.typeName}
    >
      {credit.hoursString} {credit.typeName.trim()}{index + 1 < credits.length && ', '}
    </React.Fragment>
  ));

  const priceList = prices.map(price => (
    <li
      key={price.market}
    >
      <span>{price.market && `${price.market} `}Price: </span>
      <span id={`cleDetailsPrice${price.index}`}>{price.amount}</span>
    </li>
  ));

  return (
    <DetailsContainerCard>
      <h2>Details</h2>
      {brochureLink && (
        <BrochureDownload
          brochureLink={brochureLink}
        />
      )}
      <DetailList>
        {date && (
          <li>
            <span>Date:&nbsp;</span>
            <span>{date}</span>
          </li>
        )}
        {(startTime && endTime) && (
          <li>
            <span>Time:&nbsp;</span>
            <span>{`${startTime} - ${endTime}`}</span>
          </li>
        )}
        <li>
          <span>Format:&nbsp;</span>
          <span>{courseFormat.name}</span>
        </li>
        {credits && !!credits.length && (
          <li>
            <span>{credits.length > 1 ? 'Credits:' : 'Credit:'}&nbsp;</span>
            <span>{creditsList}</span>
          </li>
        )}
        {practiceAreas && !!practiceAreas.length && (
          <li>
            <span>Practice Areas:&nbsp;</span>
            <span>{practiceAreaList}</span>
          </li>
        )}
        {courseID && (
          <li>
            <span>Course ID:&nbsp;</span>
            <span>{courseID}</span>
          </li>
        )}
        <li>
          <span>Status:&nbsp;</span>
          <span>{status}</span>
        </li>
      </DetailList>
      {is.not.empty(specializationCredits) && (
        <SpecializationCreditBlock>
          <span>
            <SpecializationCreditSVG
              height="28px"
              width="28px"
            />
          </span>
          <span><strong>Specialization Credit:</strong></span>
          <span>
            {renderSpecializationCredits(specializationCredits)}
          </span>
        </SpecializationCreditBlock>
      )}
      <PricingHeading>Pricing</PricingHeading>
      <PriceList
        id="cleDetailsPricing"
      >
        {priceList}
      </PriceList>
      <Divider />
      {`${courseFormat.type}` === '0' && (
        <React.Fragment>
          <Locations
            location={location}
            additionalLocations={additionalLocations}
          />
          <Divider />
        </React.Fragment>
      )}
      {`${courseFormat.type}` !== '0' && zoomMeetingUrl && (
        <LocationsContainer>
          <PrimaryLocation>
            <div>
              <FontAwesomeIcon icon={faMapMarkerAlt} aria-hidden="true" />
              Location: {zoomMeetingUrl}
            </div>
          </PrimaryLocation>
        </LocationsContainer>
      )}
      <Provider store={store}>
        <Register
          contentGuid={contentGuid}
          addOnsActive={addOnsActive}
          attendanceTypeOptions={attendanceTypeOptions}
          disableAddToCart={disableAddToCart}
          updateShowCart={updateShowCart}
          isMeetingEnded={isMeetingEnded}
        />
      </Provider>
    </DetailsContainerCard>
  );
};

Details.displayName = 'Details';

Details.propTypes = {
  addOnsActive: PropTypes.arrayOf(PropTypes.string),
  brochureLink: PropTypes.string,
  contentGuid: PropTypes.string,
  zoomMeetingUrl: PropTypes.string,
  date: PropTypes.string,
  endTime: PropTypes.string,
  startTime: PropTypes.string,
  courseFormat: courseFormatPropTypes,
  credits: PropTypes.arrayOf(Object),
  courseID: PropTypes.string,
  status: PropTypes.string,
  prices: PropTypes.arrayOf(Object),
  location: PropTypes.shape({
    displayName: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
    line4: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  additionalLocations: PropTypes.arrayOf(Object),
  practiceAreas: PropTypes.arrayOf(Object),
  specializationCredits: PropTypes.arrayOf(Object),
  // eslint-disable-next-line
  attendanceTypeOptions: PropTypes.object,
  disableAddToCart: PropTypes.bool,
  updateShowCart: PropTypes.func,
  isMeetingEnded: PropTypes.bool.isRequired,
};

export default Details;
