import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  colors,
  typography,
} from '@style-guide/config';

const MembershipTypeContainer = styled.div`
  background: ${colors.white};
  min-width: calc(38% - 5px);
  display: flex;
  flex-direction: column;

  h3 {
    font-size: ${rem('24px', typography.baseFontSize)};
    font-weight: 700;
    line-height: ${rem('28px', typography.baseFontSize)};

    span {
      font-weight: 400;
    }
  }

  h4 {
    font-size: 1rem;
    font-weight: 300;
    line-height: 19px;
    margin-bottom: 8px;
  }
`;

MembershipTypeContainer.displayName = 'MembershipTypeContainer';

export default MembershipTypeContainer;
