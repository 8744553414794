/**
 * We ARE using the prevKeyword state field & the filterValue,
 * we're just using them in a static method.
 */

/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import is from 'is_js';
import rem from 'polished/lib/helpers/rem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { colors, typography } from '@style-guide/config';
import { ButtonReset } from '@style-guide/mixins';
import Input from '@style-guide/components/Input';

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  input {
    padding-right: 40px;
  }
`;

Wrapper.displayName = 'Wrapper';

const SearchIconWrapper = styled.button`
  ${ButtonReset()}

  align-items: center;
  background: ${colors.white};
  border-bottom: 1px solid ${colors.gray};
  border-right: 1px solid ${colors.gray};
  border-top: 1px solid ${colors.gray};
  display: flex;
  font-size: ${rem('16px', typography.baseFontSize)};
  height: 100%;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: ${rem('40px', typography.baseFontSize)};
  }
`;

SearchIconWrapper.displayName = 'SearchIconWrapper';

const SearchIcon = styled(FontAwesomeIcon)`
  color: ${colors.darkGray};
`;

SearchIcon.displayName = 'SearchIcon';

class TextInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyword: '',
      prevKeyword: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const nextFilterValue = props.$filterValue;
    const previousFilterValue = state.prevKeyword;

    if (previousFilterValue !== nextFilterValue) {
      return {
        keyword: nextFilterValue,
        prevKeyword: nextFilterValue,
      };
    }

    return {
      ...state,
    };
  }

  handleInputChange(event) {
    this.setState({ keyword: event.target.value });
  }

  handleKeyPress(e) {
    const {
      $filterField,
      $filterUpdate,
    } = this.props;
    const { keyword } = this.state;

    if (e.charCode === 13) {
      this.setState({
        prevKeyword: keyword,
      });

      if (is.empty(keyword)) {
        $filterUpdate($filterField, '');
      } else {
        $filterUpdate($filterField, keyword);
      }
    }

    return undefined;
  }

  handleClick() {
    const {
      $filterField,
      $filterUpdate,
    } = this.props;
    const { keyword } = this.state;

    this.setState({
      prevKeyword: keyword,
    });

    if (is.empty(keyword)) {
      $filterUpdate($filterField, '');
    } else {
      $filterUpdate($filterField, keyword);
    }
  }

  render() {
    const {
      inputId,
      submitId,
      ...restProps
    } = this.props;
    const { keyword } = this.state;

    return (
      <Wrapper
        {...restProps}
      >
        <Input
          type="text"
          value={keyword}
          onChange={this.handleInputChange}
          onKeyPress={this.handleKeyPress}
          placeholder={this.props.placeholder}
          id={inputId}
        />
        <SearchIconWrapper
          onClick={this.handleClick}
          onKeyPress={this.handleKeyPress}
          aria-label={`search by ${this.props.placeholder}`}
          id={submitId}
        >
          <SearchIcon
            icon={faSearch}
          />
        </SearchIconWrapper>
      </Wrapper>
    );
  }
}

TextInput.propTypes = {
  $filterField: PropTypes.string,
  $filterUpdate: PropTypes.func.isRequired,
  $filterValue: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  submitId: PropTypes.string.isRequired,
};

TextInput.defaultProps = {
  $filterValue: '',
};

TextInput.displayName = 'TextInput';

export default TextInput;
