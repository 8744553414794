import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  breakpoints,
  colors,
  typography,
} from '@style-guide/config';

const ResultsTitle = styled.h2`
  align-items: center;
  color: ${colors.darkRed};
  display: flex;
  font-size: ${rem('22px', typography.baseFontSize)};
  font-weight: 700;
  letter-spacing: 1.2px;
  margin-bottom: 20px;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.m}) {
    margin-bottom: 0px;
  }
`;

ResultsTitle.displayName = 'ResultsTitle';

export default ResultsTitle;
