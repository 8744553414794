import React from 'react';
import PropTypes from 'prop-types';
import { UnmountClosed } from 'react-collapse';
import { presets } from 'react-motion';

import Checkbox from '@style-guide/components/Checkbox';

import Arrow from './shared/Arrow';
import Body from './shared/Body';
import ClearButton from './shared/ClearButton';
import ClearWrapper from './shared/ClearWrapper';
import Header from './shared/Header';
import Wrapper from './shared/Wrapper';
import Title from './shared/Title';

class ResourceTypeFilter extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpened: true,
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.getCorrespondingProp = this.getCorrespondingProp.bind(this);
  }

  getCorrespondingProp(id) {
    switch (id) {
      case 1:
        return 'Case';
      case 2:
        return 'Enactment';
      case 3:
        return 'SCO';
      case 4:
        return 'Conduct';
      case 5:
        return 'Ethics';
      case 6:
        return 'Attorney';
      case 7:
        return 'Other';
      default:
        return this.props.showResults.Case;
    }
  }

  toggleCollapse() {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened,
    }));
  }

  checkStatus(index) {
    switch (index) {
      case 1:
        return this.state.isChecked.Case;
      case 2:
        return this.state.isChecked.Enactment;
      case 3:
        return this.state.isChecked.SCO;
      case 4:
        return this.state.isChecked.Conduct;
      case 5:
        return this.state.isChecked.Ethics;
      case 6:
        return this.state.isChecked.Attorney;
      case 7:
        return this.state.isChecked.Other;
      default:
        return false;
    }
  }

  render() {
    const {
      filterAppendOrRemove,
      filterOptions,
      showResults,
    } = this.props;

    const {
      isOpened,
    } = this.state;

    return (
      <Wrapper>
        <Header
          onClick={this.toggleCollapse}
          $isOpened={isOpened}
          id="resourceTypeFilterCollapseToggle"
        >
          <Title>Content Type</Title>
          <Arrow isOpened={isOpened} />
        </Header>
        <UnmountClosed
          presets={presets.gentle}
          isOpened={isOpened}
        >
          <ClearWrapper>
            &#40;
            <ClearButton
              onClick={() => this.props.filterClearAll()}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  this.props.filterClearAll();
                }
              }}
              tabIndex={0}
              id="resourceTypesClearAll"
            >
              Clear All
            </ClearButton>
            &#41;
          </ClearWrapper>
          <Body
            id="filterResourceTypesWrapper"
          >
            {filterOptions.map((item, itemIndex) => {
              const propName = this.getCorrespondingProp(item.filterId);
              return (
                <Checkbox
                  key={item.filterId.toString() + propName}
                  label={item.filterName}
                  value={`filterResourceTypeOption${itemIndex + 1}`}
                  onChange={() => filterAppendOrRemove(item.filterId)}
                  id={`filterResourceTypeOption${itemIndex + 1}`}
                  isChecked={showResults[propName]}
                  name={`filterResourceTypeOption${itemIndex + 1}`}
                />
              );
            })}
          </Body>
        </UnmountClosed>
      </Wrapper>
    );
  }
}

ResourceTypeFilter.propTypes = {
  filterAppendOrRemove: PropTypes.func.isRequired,
  filterOptions: PropTypes.arrayOf(PropTypes.object),
  showResults: PropTypes.shape({
    Case: PropTypes.bool,
    Enactment: PropTypes.bool,
    SCO: PropTypes.bool,
    Conduct: PropTypes.bool,
    Ethics: PropTypes.bool,
    Attorney: PropTypes.bool,
    Other: PropTypes.bool,
  }),
  filterClearAll: PropTypes.func.isRequired,
};


ResourceTypeFilter.defaultProps = {
  filterOptions: [],
  showResults: {
    Case: false,
    Enactment: false,
    SCO: false,
    Conduct: false,
    Ethics: false,
    Attorney: false,
    Other: false,
  },
};

ResourceTypeFilter.displayName = 'ResourceTypeFilter';

export default ResourceTypeFilter;
