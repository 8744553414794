/* admission items aren't sorted or filtered, and have no id
 * so we can safely use array index as keys */
/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ContentBlock from './shared/ContentBlock';

const TaglineWrapper = styled(ContentBlock)`
  font-weight: bold;
`;

function renderObjectProps(whichArr) {
  const areaArr = whichArr.map(item => item.name);
  const unique = [...new Set(areaArr)];
  return unique.join(', ');
}

TaglineWrapper.displayName = 'TaglineWrapper';

const OverviewContentBlock = ({ contentToRender }) => (
  <React.Fragment>
    {contentToRender.tagline && (
      <TaglineWrapper>
        {contentToRender.tagline}
      </TaglineWrapper>
    )}
    {contentToRender.biography && (
      <ContentBlock>
        <h3>BIO/ABOUT</h3>
        <div style={{ whiteSpace: 'pre-line' }}>{contentToRender.biography}</div>
      </ContentBlock>
    )}
    {(contentToRender.admissions.length > 0 ||
      !!contentToRender.yearsOfPractice ||
      !!contentToRender.courtsofAdmission) && (
      <ContentBlock>
        <h3>DETAILS</h3>
        {contentToRender.admissions.length > 0 &&
        contentToRender.admissions.map((admission, index) => (
          <p key={index}>
            {!!admission.state && (
              <React.Fragment>
                <span>State of Admission: </span> {admission.state},&nbsp;
              </React.Fragment>
            )}
            {!!admission.supremeCourtNumber && (
              <React.Fragment>
                <span>Registration # </span> {admission.supremeCourtNumber},&nbsp;
              </React.Fragment>
            )}
            {!!admission.dateOfAdmission && (
              <React.Fragment>
                <span>Date of Admission: </span> {admission.dateOfAdmission}
              </React.Fragment>
            )}
          </p>
        ))}
        {!!contentToRender.yearsOfPractice && (
          <p><span>Years of Practice: </span> {contentToRender.yearsOfPractice}</p>
        )}
        {!!contentToRender.courtsOfAdmission && (
          <p><span>Courts of Admission: </span> {contentToRender.courtsOfAdmission}</p>
        )}
      </ContentBlock>
    )}

    <ContentBlock>
      <h3>SERVICES</h3>
      {contentToRender.practiceAreas.length > 0 && (
        <p><span>Practice Areas: </span>
          {renderObjectProps(contentToRender.practiceAreas)}
        </p>
      )}
      {contentToRender.isProbono !== null &&
        contentToRender.IsProbono && (
        <p><span>Probono, Free, or Fixed Rate Consultation: </span> Yes</p>
      )}
      {!!contentToRender.priceArrangements && (
        <p>
          <span>Price range, fee arrangements, fixed or reduced fees: </span>
          {contentToRender.priceArrangements}
        </p>
      )}
      {contentToRender.mediation !== null &&
        contentToRender.mediation && (
        <p><span>Mediation / Alternative Dispute Resolution: </span> Yes</p>
      )}
      {contentToRender.urgentCalls !== null &&
        contentToRender.urgentCalls && (
        <p><span>Urgent Calls: </span> Yes</p>
      )}
      {contentToRender.afterHourCalls !== null &&
        contentToRender.afterHourCalls && (
        <p><span>After Hour Calls: </span> Yes</p>
      )}
      {contentToRender.houseCalls !== null &&
        contentToRender.houseCalls && (
        <p><span>House Calls: </span> Yes</p>
      )}
      {contentToRender.countiesServed.length > 0 && (
        <p><span>Counties Served: </span>
          {contentToRender.countiesServed.map((county, index) => `${(index ? ', ' : '') + county.name}`)}
        </p>
      )}
      {contentToRender.acceptingNewClients !== null &&
        contentToRender.acceptingNewClients && (
        <p><span>Accepting New Clients: </span> Yes</p>
      )}
      {contentToRender.limitedScopeRepresentation !== null
        && contentToRender.limitedScopeRepresentation && (
        <p><span>Limited Scope Representation: </span> Yes</p>
      )}
      {contentToRender.languages.length > 0 && (
        <p><span>Languages: </span>
          {contentToRender.languages.map((language, index) => `${(index ? ', ' : '') + language.name}`)}
        </p>
      )}
    </ContentBlock>
  </React.Fragment>
);

OverviewContentBlock.propTypes = {
  // the shape of this object could change depending on what's passed in
  /* eslint-disable react/forbid-prop-types */
  contentToRender: PropTypes.object,
};

OverviewContentBlock.defaultProps = {
  contentToRender: {},
};


OverviewContentBlock.displayName = 'OverviewContentBlock';

export default OverviewContentBlock;
