import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const StyledShoppingCartIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
`;

const ShoppingCartIcon = () => (
  <StyledShoppingCartIcon
    icon={faShoppingCart}
    aria-hidden="true"
  />
);

ShoppingCartIcon.displayName = 'ShoppingCartIcon';

export default ShoppingCartIcon;
