import is from 'is_js';

const renderCredits = (credits) => {
  if (is.not.empty(credits)) {
    return credits.map((credit, index, collection) => {
      if (index === 0) {
        return `${credit.hoursString} ${credit.typeName}`;
      }

      if (index === collection.length - 1) {
        return `, & ${credit.hoursString} ${credit.typeName}`;
      }

      return `, ${credit.hoursString} ${credit.typeName}`;
    });
  }

  return undefined;
};

export default renderCredits;
