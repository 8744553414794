import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const Wrapper = styled.div`
  background-color: ${colors.white};
  padding: 0;

  @media (min-width: ${breakpoints.m}) {
    padding: 15px;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
