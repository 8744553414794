import React from 'react';
import { Provider } from 'react-redux';
import { getHonorsInfo } from '@redux/modules/honorsInfo';
import Store from '@redux';

import HonorsInfoContent from './components/HonorsInfoContent';

class HonorsInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getHonorsInfo());
  }

  render() {
    return (
      <Provider store={this.store}>
        <HonorsInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

HonorsInfo.displayName = 'HonorsInfo';

export default HonorsInfo;
