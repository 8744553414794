import styled from 'styled-components';

import { colors } from '@style-guide/config';

const LoadMoreButton = styled.a`
  color: ${colors.darkRed};
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-top: 15px;
  font-weight: bold;
  cursor: pointer;
  font-size: 0.85rem;
`;

LoadMoreButton.displayName = 'LoadMoreButton';

export default LoadMoreButton;
