import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';
import Link from '@style-guide/mixins/Link';

const ViewCartLink = styled.a`
  ${Link({ type: 'red' })}

  display: none;
  font-weight: 700;

  @media (min-width: ${breakpoints.l}) {
    display: block;
  }
`;

ViewCartLink.displayName = 'ViewCartLink';

export default ViewCartLink;
