import styled from 'styled-components';

const RefreshButtonWrapper = styled.div`
  margin-top: 30px;
  height: 50%;
  padding: 5px;
  float: right;
`;

RefreshButtonWrapper.displayName = 'RefreshButtonWrapper';

export default RefreshButtonWrapper;
