import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, colors, typography } from '@style-guide/config';

const QuickLinksContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  text-align: right;

  svg {
    margin-bottom: 8px;
  }

  > div {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  h3 {
    font-size: ${rem('16px', typography.baseFontSize)};
    font-weight: 600;
    margin: 15px 0;
  }

  ul {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    width: 100%;

    @media(min-width: ${breakpoints.m}) {
      width: auto;
    }

    li {
      width: 33%;

      @media(min-width: 768px) {
        width: auto;
      }
    }
  }

  @media(min-width: ${breakpoints.m}) {
    ul:nth-of-type(2) {
      li:first-child {
        border-left: 1px solid ${colors.gray};
      }
    }
  }

  li:not(:first-child) {
    border-left: 1px solid ${colors.gray};
  }

  a {
    align-items: center;
    color: black;
    display: flex;
    flex-direction: column;
    font-size: ${rem('12px', typography.baseFontSize)};
    padding: 0 15px;
    text-align: center;
    line-height: 1.25em;
  }

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    padding: 0;

    li:first-child {
      border-left: 1px solid ${colors.gray};
    }

    ul {
      margin: 0;
    }

    h3 {
      margin: 0 15px 0 0;
    }

    > div {
      flex-direction: row;
    }
  }

  @media (min-width: ${breakpoints.l}) and (max-width: 1024px) {
    padding-top: 20px;
  }
`;

QuickLinksContainer.displayName = 'QuickLinksContainer';

export default QuickLinksContainer;
