import ApiGenerator from '@services/ApiGenerator';

const HYDRATE = 'osba/account/SECTIONS_HYDRATE';
const GET = 'osba/account/Sections/GET';
const GET_ERROR = 'osba/account/Sections/GET_ERROR';
const GET_RECEIVED = 'osba/account/Sections/GET_RECEIVED';
const SAVE = 'osba/account/Sections/SAVE';
const SAVE_ERROR = 'osba/account/Sections/SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/Sections/SAVE_RECEIVED';

const initialState = {
  Sections: [],
  loading: false,
  message: '',
  error: '',
};

const mapResponse = data => ({
  Sections: data.Sections,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...mapResponse(action.data),
        error: '',
      };
    case GET:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SAVE:
      return {
        ...state,
        Sections: action.data.model,
        loading: true,
        error: '',
      };
    case GET_RECEIVED:
    case SAVE_RECEIVED:
      return {
        ...state,
        ...mapResponse(action.data),
        loading: false,
        error: action.data.IsSuccess ? '' : '',
      };
    case GET_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const hydrateSectionsInfo = data => ({
  type: HYDRATE,
  data: {
    Sections: data,
  },
});

export const getSections = () => ({
  type: GET,
});

export const saveSections = data => ({
  type: SAVE,
  data: {
    model: data.Sections,
  },
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api('get', '/CommitteesAndSectionsPage/Get', GET);
      break;
    case SAVE:
      api('post', '/CommitteesAndSectionsPage/Save', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
