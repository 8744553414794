import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import is from 'is_js';

import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import LinkButton from '@style-guide/components/LinkButton';

import SpecializationCreditSVG from '@components/SpecializationCreditSVG';
import renderGoogleMapLinkQuery from './services/renderGoogleMapLinkQuery';
import renderSpecializationCredits from './services/renderSpecializationCredits';
import renderCredits from './services/renderCredits';

import BrochureDownloadLink from './components/BrochureDownloadLink';
import ButtonWrapper from './components/ButtonWrapper';
import DescriptionInner from './components/DescriptionInner';
import DescriptionWrapper from './components/DescriptionWrapper';
import DetailsItem from './components/DetailsItem';
import DetailsWrapper from './components/DetailsWrapper';
import LocationBlock from './components/LocationBlock';
import SpecializationCreditBlock from './components/SpecializationCreditBlock';
import Title from './components/Title';
import Thumbnail from './components/Thumbnail';
import ThumbnailWrapper from './components/ThumbnailWrapper';
import Wrapper from './components/Wrapper';

const CourseDetails = ({
  eventInstance,
  parentMeeting,
  credits,
  specializationCredits = [],
  inxpoClassroomLink = '',
  podcastUrl = '',
  richCourseDescription = '',
}) => (
  <Wrapper>
    <ThumbnailWrapper>
      <Thumbnail
        src={eventInstance.pictureLink || '/Static/img/osba-default-cle.png'}
        alt={`${eventInstance.displayName} course thumbnail`}
        style={{
          width: '100%',
        }}
      />
    </ThumbnailWrapper>
    <DetailsWrapper>
      <h2>{eventInstance.displayName}</h2>
      {eventInstance.courseId && (
        <DetailsItem>
          <strong>Course ID: </strong><span>{eventInstance.courseId}</span>
        </DetailsItem>
      )}
      <DetailsItem>
        <strong>Date:</strong><span>{eventInstance.meetingDateString}</span>
      </DetailsItem>
      {eventInstance.startTimeValue && eventInstance.endTimeValue && (
        <DetailsItem>
          <strong>Time:</strong>
          <span>{eventInstance.startTimeValue} - {eventInstance.endTimeValue}</span>
        </DetailsItem>
      )}
      {is.not.empty(credits) && (
        <DetailsItem>
          <strong>Credits:</strong><span>{renderCredits(credits)}</span>
        </DetailsItem>
      )}
      {is.not.empty(specializationCredits) && (
        <SpecializationCreditBlock>
          <SpecializationCreditSVG
            height="28px"
            width="28px"
          />
          <span>
            <strong>Specialization Credit:</strong>
          </span>
          <span>
            {renderSpecializationCredits(specializationCredits)}
          </span>
        </SpecializationCreditBlock>
      )}
      {inxpoClassroomLink && (
        <ButtonWrapper>
          <LinkButton
            href={`/${inxpoClassroomLink}`}
            target="_blank"
          >
            Start Course
          </LinkButton>
        </ButtonWrapper>
      )}
      {podcastUrl && (
        <ButtonWrapper>
          <LinkButton
            href={podcastUrl}
            target="_blank"
          >
            Download Podcast
          </LinkButton>
        </ButtonWrapper>
      )}
      {eventInstance.location && (
        <React.Fragment>
          <LocationBlock>
            <div>Location:</div>
            <div>
              <p>{eventInstance.location.name}</p>
              {/* eslint-disable max-len */}
              <p>{eventInstance.location.line1} {eventInstance.location.line2} {eventInstance.location.line3} {eventInstance.location.line4}</p>
              <p>{eventInstance.location.city}, {eventInstance.location.state} {eventInstance.location.postalCode}</p>
              {/* eslint-enable max-len */}
            </div>
          </LocationBlock>
          {eventInstance.location.name && eventInstance.location.name.toLowerCase() !== 'webcast' && (
            <ButtonWrapper>
              <LinkButton
                href={`https://www.google.com/maps/dir/?api=1&destination=${renderGoogleMapLinkQuery(eventInstance.location)}`}
                target="_blank"
              >
                Get Directions
              </LinkButton>
            </ButtonWrapper>
          )}
        </React.Fragment>
      )}
      {!eventInstance.location && eventInstance.zoomMeeting !== '' && eventInstance.zoomMeeting !== null && (
        <LocationBlock>
          <div>Location:</div>
          <div>
            <p>Virtual</p>
          </div>
        </LocationBlock>
      )}
      {eventInstance.zoomMeeting !== '' && eventInstance.zoomMeeting !== null && (
        <ButtonWrapper>
          <LinkButton
            href={eventInstance.zoomMeeting}
            target="_blank"
          >
            Join Meeting
          </LinkButton>
        </ButtonWrapper>
      )}
      {eventInstance.brochure && (
        <ButtonWrapper>
          <BrochureDownloadLink
            href={eventInstance.brochure}
          >
            <FontAwesomeIcon
              icon={faDownload}
            />
            <span>Download Course Brochure</span>
          </BrochureDownloadLink>
        </ButtonWrapper>
      )}
    </DetailsWrapper>
    <DescriptionWrapper>
      <Title>Course Description</Title>
      <YellowLineSVG />
      <DescriptionInner>
        {(richCourseDescription.length) /* eslint-disable-next-line react/no-danger */
          ? <span dangerouslySetInnerHTML={{ __html: richCourseDescription }} />
          : <p>{parentMeeting.description}</p>}
      </DescriptionInner>
    </DescriptionWrapper>
  </Wrapper>
);

CourseDetails.propTypes = {
  eventInstance: PropTypes.shape({
    pictureLink: PropTypes.string,
    displayName: PropTypes.string,
    courseId: PropTypes.string,
    meetingDateString: PropTypes.string,
    startTimeValue: PropTypes.string,
    endTimeValue: PropTypes.string,
    location: PropTypes.objectOf(Object),
    brochure: PropTypes.string,
    zoomMeeting: PropTypes.string,
    isVirtual: PropTypes.bool,
  }).isRequired,
  credits: PropTypes.arrayOf(Object).isRequired,
  // eslint-disable-next-line
  parentMeeting: PropTypes.object.isRequired,
  specializationCredits: PropTypes.arrayOf(Object),
  inxpoClassroomLink: PropTypes.string,
  podcastUrl: PropTypes.string,
  richCourseDescription: PropTypes.string,
};

CourseDetails.displayName = 'CourseDetails';

export default CourseDetails;
