import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 100%;

  /**
   * These max-widths are 95% of the breakpoint width- this is a convention I saw in Bootstrap.
   * We need to talk about creating a standard page "container",
   * but this is just what I've been using the mean time.
   */
  @media(min-width: ${breakpoints.s}) {
    margin-bottom: 100px;
    width: 456px;
  }

  @media(min-width: ${breakpoints.m}) {
    padding: 30px 50px;
    margin-top: -100px;
    width: 684px;
  }

  @media(min-width: ${breakpoints.l}) {
    width: 855px;
  }

  @media(min-width: ${breakpoints.xl}) {
    width: 1140px;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
