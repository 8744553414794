import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 0;
  justify-content: space-between;

  &:empty {
    margin: 0;
  }

  > *:not(:first-child) {
    margin: 15px 0 0 0;
  }

  @media (min-width: ${breakpoints.s}) {
    flex-direction: row;

    > *:not(:first-child) {
      margin: 0 0 0 15px;
    }
  }
`;

FlexContainer.displayName = 'FlexContainer';

export default FlexContainer;
