import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import { colors } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import Wrapper from './components/Wrapper';
import Header from './components/Header';
import Title from './components/Title';
import Body from './components/Body';
import Item from './components/Item';

const FilterCloud = ({
  updateSearchFilter,
  clearSearchFilters,
  searchFilterValues,
}) => {
  const activeFilters = searchFilterValues.filter(filter => (filter.FilterValue === true));
  const hasActiveFilters = is.not.empty(activeFilters);

  const renderCloudFilters = () => {
    const filtersToRender = activeFilters.map((item, index) => {
      const onClickCb = () => {
        if (item.FilterName === 'Keyword') {
          updateSearchFilter('Keyword', false);
        } else if (item.FilterName === 'Date Range') {
          updateSearchFilter('Date Range', false);
        } else if (item.FilterName === 'Content Type') {
          updateSearchFilter('Content Type', false);
        } else if (item.FilterName === 'Areas of Law') {
          updateSearchFilter('Areas of Law', false);
        } else if (item.FilterName === 'Courts') {
          updateSearchFilter('Courts', false);
        }
      };

      return (
        <Item
          onClick={onClickCb}
          key={item.FilterId}
          id={`filterCloudOption${index}`}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{
              color: colors.red,
              marginRight: '5px',
              fontSize: '1.2rem',
            }}
          />
          <span>{item.FilterName}</span>
        </Item>
      );
    });

    if (filtersToRender.length > 0) {
      return filtersToRender;
    }

    return <div>No filters applied</div>;
  };

  const renderClearCloudFilters = () => {
    if (hasActiveFilters) {
      return (
        <Button
          variant="primary"
          onClick={() => clearSearchFilters()}
          id="filterCloudClearAllButton"
        >
          Clear all
        </Button>
      );
    }

    return undefined;
  };

  return (
    <Wrapper>
      <Header>
        <Title>Filtered By</Title>
      </Header>
      <Body>
        <div>
          {renderCloudFilters()}
        </div>
        {renderClearCloudFilters()}
      </Body>
    </Wrapper>
  );
};

FilterCloud.propTypes = {
  searchFilterValues: PropTypes.arrayOf(PropTypes.shape({
    FilterId: PropTypes.string,
    FilterName: PropTypes.string,
    FilterValue: PropTypes.bool,
  })).isRequired,
  updateSearchFilter: PropTypes.func.isRequired,
  clearSearchFilters: PropTypes.func.isRequired,
};

export default FilterCloud;
