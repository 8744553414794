import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { colors, typography } from '@style-guide/config';

import Link from '@style-guide/mixins/Link';

const BrochureDownloadContainer = styled.div`
  color: ${colors.red};
  font-size: ${rem('14px', typography.baseFontSize)};
  margin: 0 auto 15px;
  text-align: center;

  a {
    ${Link({ type: 'red' })}

    font-weight: 600;
    margin-right: 10px;
  }
`;

BrochureDownloadContainer.displayName = 'BrochureDownloadContainer';

const BrochureDownload = ({ brochureLink }) => (
  <BrochureDownloadContainer>
    <a
      download
      href={brochureLink}
      id="cleDetailsBrochureDownload"
    >
      <span>Download Brochure</span>
    </a>
    <FontAwesomeIcon
      aria-hidden="true"
      icon={faDownload}
    />
  </BrochureDownloadContainer>
);

BrochureDownload.displayName = 'BrochureDownload';

BrochureDownload.propTypes = {
  brochureLink: PropTypes.string.isRequired,
};

export default BrochureDownload;
