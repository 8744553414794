import styled from 'styled-components';

const ListParagraph = styled.p`
  p{
    margin-top: 5px;
    margin-bottom: 5px;
  }

  ul{
    list-style-type: disc;
    padding-left: 30px;
  }

  ol{
    list-style-type: decimal;
    padding-left: 30px;
  }
`;

ListParagraph.displayName = 'ListParagraph';

export default ListParagraph;
