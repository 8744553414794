import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import ReduxFormInput from '@components/ReduxFormInput';

const selector = formValueSelector('personalInfoForm');

const StateContainer = props => (
  <ReduxFormInput
    {...props}
  />
);

const SupremeCourtNumberField = connect((state) => {
  const isOptedOut = selector(state, 'OhioSupremeCourtNumberOptOut');

  return {
    disabled: isOptedOut,
  };
})(StateContainer);

SupremeCourtNumberField.displayName = 'SupremeCourtNumberField';

export default SupremeCourtNumberField;
