import styled from 'styled-components';
import BaseTabTitle from '../../TabTitle';

const Tabtitle = styled(BaseTabTitle)`
  margin-bottom: 0;
`;

Tabtitle.displayName = 'Tabtitle';

export default Tabtitle;
