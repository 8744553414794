import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const DesktopLogo = styled.div`
  display: none;

  @media(min-width: ${breakpoints.xl}) {
    display: block;
  }
`;

DesktopLogo.displayName = 'DesktopLogo';

export default DesktopLogo;
