import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import ShoppingCartItems from '@views/Header/components/ShoppingCartItems';

import ShoppingCartContent from './components/ShoppingCartContent';
import ShoppingCartHeading from './components/ShoppingCartHeading';
import ShoppingCartNavItem from './components/ShoppingCartNavItem';
import ShoppingCartToggle from './components/ShoppingCartToggle';

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.cartRef = createRef();
    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.cartRef != null &&
        this.cartRef.current != null &&
        !this.cartRef.current.contains(event.target)) {
      if (this.state.isOpen) {
        document.body.style.overflow = 'auto';

        this.setState({
          isOpen: false,
        });
      }
    }
  }

  toggleOpenState() {
    if (!this.state.isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const {
      checkoutLink,
      shoppingCartLink,
      count,
    } = this.props;

    const shoppingCartClassNames = classNames({
      open: this.state.isOpen,
    });

    return (
      <ShoppingCartNavItem
        id="mobileHeaderCart"
        ref={this.cartRef}
      >
        <ShoppingCartToggle
          className={shoppingCartClassNames}
          onClick={this.toggleOpenState}
          aria-label="Shopping Cart"
          aria-expanded={this.state.isOpen}
          id="mobileHeaderCartToggle"
        >
          <FontAwesomeIcon icon={faShoppingCart} aria-hidden="true" />
          <span>{count}</span>
        </ShoppingCartToggle>
        <ShoppingCartContent
          className={shoppingCartClassNames}
          id="mobileHeaderCartContent"
        >
          <ShoppingCartHeading>Shopping Cart</ShoppingCartHeading>
          <ShoppingCartItems
            shoppingCartLink={shoppingCartLink}
            checkoutLink={checkoutLink}
          />
        </ShoppingCartContent>
      </ShoppingCartNavItem>
    );
  }
}

ShoppingCart.propTypes = {
  checkoutLink: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  shoppingCartLink: PropTypes.string.isRequired,
};

const mapStateToProps = ({ cart }) => ({
  count: cart.lineItems.length,
});

ShoppingCart.displayName = 'ShoppingCart';

export default connect(mapStateToProps)(ShoppingCart);
