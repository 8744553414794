import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearSubmitErrors } from 'redux-form';
import { connect } from 'react-redux';
import {
  faCloudUploadAlt,
  faImage,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import Button from '@style-guide/components/Button';

import { reduxInputPropType } from '@services/PropTypes';

import LeftCol from './components/LeftCol';
import MessageWrapper from './components/MessageWrapper';
import RemoveButton from './components/RemoveButton';
import StyledIcon from './components/StyledIcon';
import UploadLabel from './components/UploadLabel';
import UploadSubtitle from './components/UploadSubtitle';
import Wrapper from './components/Wrapper';

class MediaUploadSection extends React.Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e, value) {
    const file = e.target.files[0];
    const fr = new FileReader();
    const {
      input,
      isValid,
    } = this.props;

    this.props.dispatch(clearSubmitErrors('mediaInfoForm'));

    fr.onload = () => {
      const img = new Image();

      img.onload = () => {
        const {
          height,
          width,
        } = img;

        if (isValid(width, height)) {
          input.onChange({
            url: value.url,
            deleted: false,
            file,
            isValid: true,
          });
        } else {
          input.onChange({
            url: value.url,
            deleted: false,
            file,
            isValid: false,
          });
        }

        return undefined;
      };

      img.src = fr.result;
    };

    fr.readAsDataURL(file);
  }

  render() {
    const { value } = this.props.input;

    return (
      <Wrapper>
        <LeftCol>
          {value.url && !value.deleted && !value.file && (
            <img
              src={value.url}
              alt="profile"
              style={{
                maxWidth: '100%',
                maxHeight: '250px',
              }}
            />
          )}
          {(value.deleted || (is.falsy(value.file) && is.falsy(value.url))) && (
            <MessageWrapper>
              <StyledIcon icon={faImage} />
              <em>No Photo Selected</em>
            </MessageWrapper>
          )}
          {value.file && !value.deleted && (
            <MessageWrapper>
              <StyledIcon icon={faCloudUploadAlt} />
              <em>{value.file.name}</em>
            </MessageWrapper>
          )}
        </LeftCol>
        <div>
          <div>
            <UploadLabel>Upload a new file:</UploadLabel>
            <UploadSubtitle>
              <em>{this.props.subtitle}</em>
            </UploadSubtitle>
            <input
              ref={this.fileInputRef}
              onChange={e => this.handleInputChange(e, value)}
              type="file"
              style={{
                display: 'none',
              }}
            />
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault();
                this.fileInputRef.current.click();
              }}
            >
              CHOOSE FILE
            </Button>
          </div>
          {!value.deleted && (!!value.file || !!value.url) && (
            <div>
              <RemoveButton
                onClick={() => {
                  this.props.input.onChange({
                    url: value.url,
                    deleted: true,
                    file: null,
                    isValid: true,
                  });

                  this.fileInputRef.current.value = null;

                  this.props.dispatch(clearSubmitErrors('mediaInfoForm'));
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
                Remove Photo
              </RemoveButton>
            </div>
          )}
        </div>
      </Wrapper>
    );
  }
}

MediaUploadSection.propTypes = {
  input: reduxInputPropType.isRequired,
  subtitle: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isValid: PropTypes.func,
};

MediaUploadSection.defaultProps = {
  isValid: () => true,
};

export default connect()(MediaUploadSection);
