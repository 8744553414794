import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';

const StyledIcon = styled(FontAwesomeIcon)`
  color: ${props => (props.color ? props.color : '#C3002F')};
  font-size: ${props => (props.fontSize ? props.fontSize : '35px')};
`;

StyledIcon.displayName = 'StyledIcon';

const BookmarkIcon = ({
  color = undefined,
  fontSize = undefined,
}) => (
  <StyledIcon
    icon={faBookmark}
    color={color}
    fontSize={fontSize}
  />
);

BookmarkIcon.propTypes = {
  /** a color for the icon */
  color: PropTypes.string,
  /** height for the SVG. Can be px, rem, percentage, etc. */
  fontSize: PropTypes.string,
};

BookmarkIcon.displayName = 'BookmarkIcon';

export default BookmarkIcon;
