import ApiGenerator from '@services/ApiGenerator';

const GET = 'osba/account/CONTACT_INFO_GET';
const GET_ERROR = 'osba/account/CONTACT_INFO_GET_ERROR';
const GET_RECEIVED = 'osba/account/CONTACT_INFO_GET_RECEIVED';
const SAVE = 'osba/account/CONTACT_INFO_SAVE';
const SAVE_ERROR = 'osba/account/CONTACT_INFO_SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/CONTACT_INFO_SAVE_RECEIVED';

const initialState = {
  AddressLine1: '',
  AddressLine2: '',
  CellPhoneNumber: '',
  City: '',
  CurrentLanguage: '',
  FaxNumber: '',
  HomePhoneNumber: '',
  PreferredPhone: '',
  PersonalEmailAddress: '',
  PreferredEmail: '',
  WorkOrSchoolEmailAddress: '',
  State: '',
  WorkPhoneNumber: '',
  ZipCode: '',
  loading: false,
  error: '',
};

const mapResponse = data => ({
  ...data,
  CellPhoneNumber: `${data.CellPhoneNumberAreaCode}${data.CellPhoneNumber}`,
  HomePhoneNumber: `${data.HomePhoneNumberAreaCode}${data.HomePhoneNumber}`,
  WorkPhoneNumber: `${data.WorkPhoneNumberAreaCode}${data.WorkPhoneNumber}`,
  FaxNumber: `${data.FaxNumberAreaCode}${data.FaxNumber}`,
  PreferredEmail: data.IsWorkOrSchoolEmailPreferred ? 'workOrSchool' : 'personal',
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SAVE:
      return {
        ...state,
        ...mapResponse(action.data),
        loading: true,
        error: '',
      };
    case GET_RECEIVED:
    case SAVE_RECEIVED:
      return {
        ...state,
        ...mapResponse(action.data),
        loading: false,
      };
    case GET_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getContactInfo = () => ({
  type: GET,
});

export const saveContactInfo = data => ({
  type: SAVE,
  data: {
    AddressLine1: data.AddressLine1,
    AddressLine2: data.AddressLine2,
    CellPhoneNumber: data.CellPhoneNumber.substring(3),
    CellPhoneNumberAreaCode: data.CellPhoneNumber.substring(0, 3),
    City: data.City,
    CurrentLanguage: data.CurrentLanguage,
    FaxNumber: data.FaxNumber.substring(3),
    FaxNumberAreaCode: data.FaxNumber.substring(0, 3),
    HomePhoneNumber: data.HomePhoneNumber.substring(3),
    HomePhoneNumberAreaCode: data.HomePhoneNumber.substring(0, 3),
    PreferredPhone: data.PreferredPhone,
    PersonalEmailAddress: data.PersonalEmailAddress,
    IsPersonalEmailPreferred: data.PreferredEmail === 'personal',
    WorkOrSchoolEmailAddress: data.WorkOrSchoolEmailAddress,
    IsWorkOrSchoolEmailPreferred: data.PreferredEmail === 'workOrSchool',
    State: data.State,
    WorkPhoneNumber: data.WorkPhoneNumber.substring(3),
    WorkPhoneNumberAreaCode: data.WorkPhoneNumber.substring(0, 3),
    ZipCode: data.ZipCode,
  },
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api('get', '/ContactInformationForm/GetContactInformation', GET);
      break;
    case SAVE:
      api('post', '/ContactInformationForm/SaveContactInformation', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
