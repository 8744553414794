import React from 'react';
import PropTypes from 'prop-types';

import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import HeaderContainer from './components/HeaderContainer';

const Header = ({ displayName = '' }) => (
  <HeaderContainer>
    <YellowLineSVG />
    <h1>{displayName}</h1>
  </HeaderContainer>
);

Header.displayName = 'Header';

Header.propTypes = {
  displayName: PropTypes.string,
};

export default Header;
