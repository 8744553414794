import React from 'react';
import PropTypes from 'prop-types';

import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';

import CardCTA from '@style-guide/structures/CardCTA';
import OverlayCTA from '@style-guide/structures/OverlayCTA';

const BLOCK_MAP = {
  CallToActionBlock: CardCTA,
};

const DEFAULT_PROP_MAP = {
  CallToActionBlock: {
    hasBackground: false,
    color: 'white',
  },
};

const OverlayCTASection = ({
  imgSrc,
  contentItems,
  overlayAlignment = 'full',
  overlayColor = 'blue',
  type = 'normal',
}) => {
  const overlayCTASectionBlocks = JSON.parse(contentItems).map((blockData) => {
    const Block = BLOCK_MAP[blockData.Type];
    const props = mapKeys(blockData.Block, (value, key) => camelCase(key));

    return (
      <Block
        key={blockData.Key}
        {...DEFAULT_PROP_MAP[blockData.Type]}
        {...props}
      />
    );
  });

  return (
    <OverlayCTA
      imgSrc={imgSrc}
      contentItems={overlayCTASectionBlocks}
      overlayAlignment={overlayAlignment}
      overlayColor={overlayColor}
      type={type}
    />
  );
};

OverlayCTASection.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  contentItems: PropTypes.string.isRequired,
  overlayAlignment: PropTypes.string,
  overlayColor: PropTypes.string,
  type: PropTypes.string,
};

OverlayCTASection.displayName = 'OverlayCTASection';

export default OverlayCTASection;
