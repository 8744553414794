import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveLinksInfo } from '@redux/modules/linksInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import LinksInfoForm from './components/LinksInfoForm';

class LinksInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formValues) {
    this.props.dispatch(saveLinksInfo(formValues));
  }

  render() {
    const {
      loading,
      linksInfo,
      title,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          title={title}
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <LinksInfoForm
                initialValues={linksInfo}
                onSubmit={this.submit}
                loading={loading}
                hasSaveError={!!error}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
        />
      </FormAccordionContainer>
    );
  }
}

LinksInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  linksInfo: PropTypes.shape({
    Links: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
};

LinksInfoContent.defaultProps = {
  error: '',
  loading: false,
  title: 'Links Information',
};

const mapStateToProps = ({ linksInfo }) => ({
  error: linksInfo.error,
  loading: linksInfo.loading,
  linksInfo,
});

LinksInfoContent.displayName = 'LinksInfoContent';

export default connect(mapStateToProps)(LinksInfoContent);
