import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LoadingOverlay from '@components/LoadingOverlay';
import CartActions from './components/CartActions';
import CartItem from './components/CartItem';
import EmptyCartMessage from './components/EmptyCartMessage';
import ShoppingCartItemsContainer from './components/ShoppingCartItemsContainer';

const ShoppingCartItems = ({
  checkoutLink,
  items = [],
  shoppingCartLink,
  total = '$0.00',
  loading = true,
}) => {
  const cartItems = items.map((item, index) => (
    <CartItem
      key={item.ItemId}
      displayName={item.DisplayName}
      price={item.Price}
      contentGuid={item.ItemId}
      itemIndex={index}
      applyAutomatically={item.ApplyAutomatically}
      soldOut={item.SoldOut}
    />
  ));
  const anySoldOut = items.filter(item => item.SoldOut);
  const anySoldOutStyle = {
    color: 'red',
    fontSize: 'x-small',
    paddingBottom: 'initial',
    paddingTop: 'initial',
  };
  return (
    <ShoppingCartItemsContainer>
      {loading && <LoadingOverlay />}
      <ul>
        {cartItems.length ? cartItems : (
          <EmptyCartMessage>You have no items in your shopping cart.</EmptyCartMessage>
        )}
      </ul>
      <ul>
        <CartItem
          variant="summary-item"
          displayName="Total"
          price={total}
          itemIndex="Summary"
        />
      </ul>
      {anySoldOut.length > 0 && (
      <p style={anySoldOutStyle}>
        This event has reached capacity.
        Please contact OSBA Member Services at
        1-800-232-7124 for additional options and information.
      </p>
      )}
      <CartActions
        checkoutDisabled={!items.length || anySoldOut.length > 0}
        checkoutLink={checkoutLink}
        shoppingCartLink={shoppingCartLink}
      />
    </ShoppingCartItemsContainer>
  );
};

ShoppingCartItems.propTypes = {
  checkoutLink: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(Object),
  shoppingCartLink: PropTypes.string.isRequired,
  total: PropTypes.string,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ cart }) => ({
  items: cart.lineItems,
  total: cart.cartTotal,
  loading: cart.loading,
});

ShoppingCartItems.displayName = 'ShoppingCartItems';

export default connect(mapStateToProps)(ShoppingCartItems);
