import styled from 'styled-components';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

FlexWrapper.displayName = 'FlexWrapper';

export default FlexWrapper;
