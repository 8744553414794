// the items in this list don't have ids and are view only
// (never sorted or re-ordered) so we can safely use
// array indexes as keys
/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ContentBlock from './shared/ContentBlock';
import {
  StyledTable,
  ColumnHeaders,
  Column,
  HideOnMobileColumn,
  HideOnMobileColumnHeader,
  ShowOnMobile,
} from './shared/StyledTable';

const ExperienceContentBlock = props => (
  <ContentBlock>
    <StyledTable>
      <thead>
        <ColumnHeaders>
          <th><h3>PLACE OF EMPLOYMENT</h3></th>
          <HideOnMobileColumnHeader><h3>DATES</h3></HideOnMobileColumnHeader>
        </ColumnHeaders>
      </thead>
      <tbody>
        {props.contentToRender.employmentHistory.map((item, index) => (
          <tr key={index}>
            <Column>
              <p>{item.employer}</p>
              <ShowOnMobile>
                <span>Dates: </span>{moment(item.startDate).format('MM/DD/Y')} {item.endDate === null ? ' - Present' : ` - ${moment(item.endDate).format('MM/DD/Y')}`}
              </ShowOnMobile>
            </Column>
            <HideOnMobileColumn>
              <p>
                {moment(item.startDate).format('MM/DD/Y')} {item.endDate === null ? ' - Present' : ` - ${moment(item.endDate).format('MM/DD/Y')}`}
              </p>
            </HideOnMobileColumn>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  </ContentBlock>
);

ExperienceContentBlock.propTypes = {
  contentToRender: PropTypes.shape({
    employmentHistory: PropTypes.arrayOf(PropTypes.shape({
      employer: PropTypes.string,
      startdate: PropTypes.string,
      endDate: PropTypes.string,
    })),
  }),
};

ExperienceContentBlock.defaultProps = {
  contentToRender: {},
};

ExperienceContentBlock.displayName = 'ExperienceContentBlock';

export default ExperienceContentBlock;
