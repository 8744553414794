import ApiGenerator from '@services/ApiGenerator';

const GET = 'osba/account/Committees/GET';
const GET_ERROR = 'osba/account/Committees/GET_ERROR';
const GET_RECEIVED = 'osba/account/Committees/GET_RECEIVED';
const SAVE = 'osba/account/Committees/SAVE';
const SAVE_ERROR = 'osba/account/Committees/SAVE_ERROR';
const SAVE_RECEIVED = 'osba/account/Committees/SAVE_RECEIVED';

const initialState = {
  Committees: [],
  loading: false,
  message: '',
  error: '',
};

const mapResponse = data => ({
  Committees: data.Model,
  message: data.Message,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SAVE:
      return {
        ...state,
        Committees: action.data.model,
        loading: true,
        error: '',
      };
    case GET_RECEIVED:
    case SAVE_RECEIVED:
      return {
        ...state,
        ...mapResponse(action.data),
        loading: false,
        error: action.data.IsSuccess ? '' : '',
      };
    case GET_ERROR:
    case SAVE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export const getCommittees = () => ({
  type: GET,
});

export const saveCommittees = data => ({
  type: SAVE,
  data: {
    model: data.Committees,
  },
});

export const middleware = () => next => (action) => {
  const api = ApiGenerator(next);

  next(action);

  switch (action.type) {
    case GET:
      api('get', '/CommitteesAndSectionsPage/Get', GET);
      break;
    case SAVE:
      api('post', '/CommitteesAndSectionsPage/Save', SAVE, {
        ...action.data,
      });
      break;
    default:
      break;
  }
};

export default reducer;
