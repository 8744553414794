import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import styled from 'styled-components';

import { saveMemberBenefitsInfo } from '@redux/modules/CommunicationPreferences/MemberBenefitsInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import MemberBenefitInfoForm from './components/MemberBenefitInfoForm';

const HeadingDiv = styled.div`
  background: #263746;
  color: #FFF;
  font-family: 'proxima-nova', 'Helvetica Nueue', sans-serif;
  font-weight: 500;
  padding: 12px;
`;

HeadingDiv.displayName = 'HeadingDiv';

class MemberBenefitInfoContent extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;

    this.valueChange = bindActionCreators(change, dispatch);

    this.submit = this.submit.bind(this);
    this.handleChangeCleUpdates = this.handleChangeCleUpdates.bind(this);
  }

  submit(formValues) {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    this.props.dispatch(saveMemberBenefitsInfo(formValues));
  }

  handleChangeCleUpdates() {
    this.valueChange('memberBenefitInfoForm', 'cleFrequency', 1);
    this.valueChange('memberBenefitInfoForm', 'clePromotions', true);
  }

  render() {
    const {
      loading,
      memberBenefitsInfo,
      title,
      isMember,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormContainer>
        {loading && <LoadingOverlay />}
        <HeadingDiv>{title}</HeadingDiv>
        <MemberBenefitInfoForm
          initialValues={memberBenefitsInfo}
          onSubmit={this.submit}
          handleChangeCleUpdates={this.handleChangeCleUpdates}
          loading={loading}
          hasSaveError={!!error}
          isMember={isMember}
        />
        {error && (
          <ErrorMessage>
            <span>{error}</span>
          </ErrorMessage>
        )}
      </FormContainer>
    );
  }
}

MemberBenefitInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  memberBenefitsInfo: PropTypes.shape({
    cleUpdates: PropTypes.bool,
    cleFrequency: PropTypes.number,
    clePromotions: PropTypes.bool,
    memberPracticeMgmtTools: PropTypes.bool,
    memberJobListings: PropTypes.bool,
    memberEvents: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string.isRequired,
  isMember: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

MemberBenefitInfoContent.defaultProps = {
  error: '',
  loading: false,
};

const mapStateToProps = ({ memberBenefitsInfo }) => ({
  error: memberBenefitsInfo.error,
  loading: memberBenefitsInfo.loading,
  memberBenefitsInfo,
});

MemberBenefitInfoContent.displayName = 'MemberBenefitInfoContent';

export default connect(mapStateToProps)(MemberBenefitInfoContent);
