import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { colors, typography } from '@style-guide/config';

const Category = styled.div`
  color: ${colors.darkRed};
  font-size: ${rem('13px', typography.baseFontSize)};
  font-style: italic;
  font-weight: 500;
  margin: 15px 0 10px;
`;

Category.displayName = 'Category';

export default Category;
