import styled from 'styled-components';

import { colors } from '@style-guide/config';

const TabTitle = styled.h3`
  color: ${colors.black};
  margin-bottom: 30px;
`;

TabTitle.displayName = 'TabTitle';

export default TabTitle;
