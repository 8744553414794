import { urlEncode } from '@services/Url';

const serializeFormData = data => ({
  contentGuidString: data.contentGuidString,
  title: data.title,
  summary: data.summary,
  activeDate: data.activeDate,
  createdDate: data.createdDate,
  createdBy: data.createdBy,
});

const eBarResourcesCreate = async (data) => {
  const eBarCreate = await fetch('/EBarResourcesCallToActionBlock/CreateEpiResources', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarCreateJson = eBarCreate.json();

  return eBarCreateJson;
};

const eBarResourcesUpdate = async (data) => {
  const eBarUpdate = await fetch('/EBarResourcesCallToActionBlock/UpdateEpiResources', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarUpdateJson = eBarUpdate.json();

  return eBarUpdateJson;
};

const eBarResourcesDelete = async (data) => {
  const eBarDelete = await fetch('/EBarResourcesCallToActionBlock/DeleteEpiResources', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      stringToDelete: data,
    }),
  });
  const eBarDeleteJson = eBarDelete.json();

  return eBarDeleteJson;
};


export {
  eBarResourcesCreate,
  eBarResourcesUpdate,
  eBarResourcesDelete,
};
