import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const CartActions = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  a {
    width: 100%;

    + a {
      margin-top: 20px;
    }
  }

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;

    a {
      width: auto;

      + a {
        margin-top: 0;
      }
    }
  }
`;

CartActions.displayName = 'CartActions';

export default CartActions;
