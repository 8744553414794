import styled from 'styled-components';

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  span {
    font-size: 14px;
    margin-bottom: 10px;
    font-style: italic;
  }
`;

FlexWrapper.displayName = 'FlexWrapper';

export default FlexWrapper;
