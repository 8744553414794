import React from 'react';
import gtag from 'ga-gtag';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LoadingOverlay from '@components/LoadingOverlay';
import CartActions from './components/CartActions';
import CartItem from './components/CartItem';
import EmptyCartMessage from './components/EmptyCartMessage';
import ShoppingCartItemsContainer from './components/ShoppingCartItemsContainer';

const ShoppingCartItems = ({
  checkoutLink,
  items = [],
  shoppingCartLink,
  continueShoppingLink,
  total = '$0.00',
  loading = true,
  removedItems = [],
  myDashboardLink = '',
}) => {
  const cartItems = items.map((item, index) => (
    <CartItem
      key={item.ItemId}
      displayName={item.DisplayName}
      price={item.Price}
      contentGuid={item.ItemId}
      itemIndex={index}
      applyAutomatically={item.ApplyAutomatically}
      soldOut={item.SoldOut}
    />
  ));
  const removedCartItems = removedItems.map((item, index) => (
    <CartItem
      key={item.ItemId}
      displayName={item.DisplayName}
      price={item.Price}
      contentGuid={item.ItemId}
      itemIndex={index}
      applyAutomatically
      soldOut={item.SoldOut}
    />
  ));
  const anySoldOut = items.filter(item => item.SoldOut);
  const anySoldOutStyle = {
    color: 'red',
    fontSize: 'x-small',
    paddingBottom: 'initial',
    paddingTop: 'initial',
  };

  const removedItemStyle = {
    color: 'red',
    fontSize: 'medium',
    paddingBottom: 'initial',
    paddingTop: 'initial',
  };

  const cartItemsRemoved = removedItems.reduce((acc, curr, index) => [
    {
      Item: index + 1,
      DisplayName: curr.DisplayName,
      LocationDisplayName: curr.LocationDisplayName,
      ProductId: curr.ProductId,
      ItemId: curr.ItemId,
      Price: curr.Price.substring(1, curr.Price.length - 1),
      CourseFormat: curr.CourseFormat,
      ProductType: curr.ProductType,
      StartDate: ''.concat(curr.StartDate, ' ', curr.StartTime),
      EndDate: ''.concat(curr.EndDate, ' ', curr.EndTime),
      SoldOut: curr.SoldOut,
      ItemVariant: ''.concat('Start Date: ', curr.StartDate, ' ', curr.StartTime, ', End Date: ', curr.EndDate, ' ', curr.EndTime, ', Sold Out: ', curr.SoldOut),
    },
  ], 0);

  /* eslint-disable */
    if (cartItemsRemoved.length > 0) {
      gtag('event', 'remove_from_cart', {
        currency: 'USD',
        value: parseFloat(cartItemsRemoved[0].Price),
        items: [
          {
            item_id: cartItemsRemoved[0].ProductId,
            item_name: cartItemsRemoved[0].DisplayName,
            index: cartItemsRemoved[0].Item,
            item_category: cartItemsRemoved[0].CourseFormat,
            price: parseFloat(cartItemsRemoved[0].Price),
          }
        ]
      });
    }
  /* eslint-enable */

  return (
    <ShoppingCartItemsContainer>
      {loading && <LoadingOverlay />}
      <ul>
        {cartItems.length ? cartItems : (
          <EmptyCartMessage>You have no items in your shopping cart.</EmptyCartMessage>
        )}
      </ul>
      {removedCartItems.length > 0 && (
      <ul>
        <p style={removedItemStyle}>
          The following item(s) have been removed from the cart as you
          are already registered. Visit <a href={myDashboardLink}>My Dashboard </a>
          to check your current CLE and /or Event registrations.
        </p>
        {removedCartItems}
      </ul>
      )}
      <ul>
        <CartItem
          variant="summary-item"
          displayName="Total"
          price={total}
          itemIndex="Summary"
        />
      </ul>
      {anySoldOut.length > 0 && (
      <p style={anySoldOutStyle}>
        This event has reached capacity.
        Please contact OSBA Member Services at
        1-800-232-7124 for additional options and information.
      </p>
      )}
      <CartActions
        checkoutDisabled={!items.length || anySoldOut.length > 0}
        checkoutLink={checkoutLink}
        shoppingCartLink={shoppingCartLink}
        continueShoppingLink={continueShoppingLink}
      />
    </ShoppingCartItemsContainer>
  );
};

ShoppingCartItems.propTypes = {
  checkoutLink: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(Object),
  shoppingCartLink: PropTypes.string.isRequired,
  continueShoppingLink: PropTypes.string.isRequired,
  total: PropTypes.string,
  loading: PropTypes.bool,
  removedItems: PropTypes.arrayOf(Object),
  myDashboardLink: PropTypes.string,
};

const mapStateToProps = ({ cart }) => ({
  items: cart.lineItems,
  total: cart.cartTotal,
  loading: cart.loading,
  removedItems: cart.removedItems != null ? cart.removedItems : [],
});

ShoppingCartItems.displayName = 'ShoppingCartItems';

export default connect(mapStateToProps)(ShoppingCartItems);
