import styled from 'styled-components';

import { colors } from '@style-guide/config';

const PercentRequiredNumber = styled.span`
  color: ${colors.red};
  font-size: 24px;
`;

PercentRequiredNumber.displayName = 'PercentRequiredNumber';

export default PercentRequiredNumber;
