import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const Wrapper = styled.div`
  width: 100%;
  justify-content: center;
  flex-direction: row;
  display: none;

  @media(min-width: ${breakpoints.l}) {
    display: ${props => (props.visibleOn === 'l' ? 'flex' : 'none')};
  }

  @media(min-width: ${breakpoints.xl}) {
    display: ${props => (props.visibleOn === 'l' ? 'none' : 'flex')};
  }
`;
const Line = styled.div`
  display: block;
  width: 75%;
  border-bottom: 1px solid ${colors.lightGray};
  margin: 20px 0;
`;

const GridDividerHorizontal = ({
  visibleOn,
}) => (
  <Wrapper
    visibleOn={visibleOn}
  >
    <Line />
  </Wrapper>
);

GridDividerHorizontal.displayName = 'GridDividerHorizontal';

GridDividerHorizontal.propTypes = {
  visibleOn: PropTypes.string.isRequired,
};

export default GridDividerHorizontal;
