import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import Link from '@style-guide/mixins/Link';
import { breakpoints, typography } from '@style-guide/config';

const AccountEditContainer = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0;

  svg {
    font-size: ${rem('14px', typography.baseFontSize)};
    margin-right: 15px;
  }

  a {
    ${Link({ type: 'white' })}

    font-size: ${rem('14px', typography.baseFontSize)};
    font-weight: 400;
  }

  @media (min-width: ${breakpoints.l}) {
    align-items: center;
    padding: 0 20px;
  }
`;

AccountEditContainer.displayName = 'AccountEditContainer';

export default AccountEditContainer;
