import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { colors, typography } from '@style-guide/config';

const LoginLink = styled.a`
  color: ${colors.darkBlue};

  svg {
    margin-right: 5px;
  }
`;

LoginLink.displayName = 'LoginLink';

const LoginNavItem = styled.li`
  background-color: ${colors.yellow};
  font-size: ${rem('14px', typography.baseFontSize)};
  font-weight: 700;

  a {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 1rem;
  }
`;

LoginNavItem.displayName = 'LoginNavItem';

const LogoutMessage = styled.span`
  font-weight: 400;
`;

LogoutMessage.displayName = 'LogoutMessage';

const Login = ({
  isAuthenticated,
  loginLink,
  logoutLink,
  userName,
}) => (
  <LoginNavItem>
    <LoginLink
      href={isAuthenticated ? logoutLink : loginLink}
      id={isAuthenticated ? 'desktopHeaderLogout' : 'desktopHeaderLogin'}
    >
      <FontAwesomeIcon icon={faUser} aria-hidden="true" />
      <span>{isAuthenticated ? `Welcome Back ${userName}` : 'Log In / Create an Account'}</span>
      &nbsp;
      <LogoutMessage>{isAuthenticated ? '(Logout)' : ''}</LogoutMessage>
    </LoginLink>
  </LoginNavItem>
);

Login.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  loginLink: PropTypes.string.isRequired,
  logoutLink: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};

Login.displayName = 'Login';

export default Login;
