import React from 'react';
import PropTypes from 'prop-types';

import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import Input from '@style-guide/components/Input';
import LinkButton from '@style-guide/components/LinkButton';

import findALawyerSearchParamBuilder from '@services/SearchParamBuilder';

import SearchWrapper from './components/SearchWrapper';
import Subtitle from './components/Subtitle';
import Title from './components/Title';
import Wrapper from './components/Wrapper';

class MemberDirectorySearchBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      keyword: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.buildUrl = this.buildUrl.bind(this);
  }

  handleInputChange(event) {
    const { value } = event.target;
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState);

      newState.criteria = value;

      return {
        ...newState,
        error: null,
      };
    });
  }

  handleChange(value, field) {
    this.setState((prevState) => {
      const newState = Object.assign({}, prevState);

      newState[field] = value;

      return {
        ...newState,
      };
    });
  }

  handleKeyPress(e) {
    if (e.charCode === 13) {
      window.location.assign(this.buildUrl());
    }

    return undefined;
  }

  buildUrl() {
    const {
      firstName,
      lastName,
      keyword,
    } = this.state;

    const searchParams = findALawyerSearchParamBuilder({
      firstName,
      lastName,
      keyword,
    });

    return `${this.props.buttonLink}${searchParams}`;
  }

  render() {
    const {
      title,
      subTitle,
      buttonLabel,
    } = this.props;

    return (
      <Wrapper>
        <YellowLineSVG />
        {title && <Title>{title}</Title>}
        {subTitle && <Subtitle>{subTitle}</Subtitle>}
        <SearchWrapper>
          <Input
            type="text"
            placeholder="First name"
            id="memberDirectorySearchFirstName"
            onChange={e => this.handleChange(e.target.value, 'firstName')}
            onKeyPress={this.handleKeyPress}
          />
          <Input
            type="text"
            placeholder="Last name"
            id="memberDirectorySearchLastName"
            onChange={e => this.handleChange(e.target.value, 'lastName')}
            onKeyPress={this.handleKeyPress}
          />
        </SearchWrapper>
        <SearchWrapper>
          <Input
            type="text"
            placeholder="Keyword"
            id="memberDirectorySearchKeyword"
            onChange={e => this.handleChange(e.target.value, 'keyword')}
            onKeyPress={this.handleKeyPress}
          />
        </SearchWrapper>
        <LinkButton
          href={this.buildUrl()}
          style={{
            marginTop: '16px',
          }}
        >
          {buttonLabel}
        </LinkButton>
      </Wrapper>
    );
  }
}

MemberDirectorySearchBlock.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string.isRequired,
};

MemberDirectorySearchBlock.defaultProps = {
  buttonLabel: 'Search',
};

MemberDirectorySearchBlock.displayName = 'MemberDirectorySearchBlock';

export default MemberDirectorySearchBlock;
