import styled from 'styled-components';

const TitleDiv = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
`;

TitleDiv.displayName = 'TitleDiv';

export default TitleDiv;
