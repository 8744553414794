import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  colors,
  typography,
} from '@style-guide/config';

const MembershipAlerts = styled.div`
  align-self: flex-start;
  color: ${colors.red};
  display: flex;
  flex-direction: column;
  font-size: ${rem('14px', typography.baseFontSize)};

  a + span {
    margin-top: 15px;
  }
`;

MembershipAlerts.displayName = 'MembershipAlerts';

export default MembershipAlerts;
