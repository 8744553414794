import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, typography } from '@style-guide/config';

const Title = styled.h1`
  font-size: ${rem('24px', typography.baseFontSize)};
  font-weight: 700;
  line-height: 1.1em;

  @media (min-width: ${breakpoints.m}) {
    font-size: ${rem('38px', typography.baseFontSize)};
  }

  @media (min-width: ${breakpoints.l}) {
    padding-right: 100px;
  }
`;

Title.displayName = 'Title';

export default Title;
