import { urlEncode } from '@services/Url';

const serializeFormData = data => ({
  contentGuidString: data.contentGuidString,
  title: data.title,
  summary: data.summary,
  url: data.url,
  createdDate: data.createdDate,
});

const eBarNewsCreate = async (data) => {
  const eBarCreate = await fetch('/EBarNewsCallToActionBlock/CreateEpiNews', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarCreateJson = eBarCreate.json();

  return eBarCreateJson;
};

const eBarNewsUpdate = async (data) => {
  const eBarUpdate = await fetch('/EBarNewsCallToActionBlock/UpdateEpiNews', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarUpdateJson = eBarUpdate.json();

  return eBarUpdateJson;
};

const eBarNewsDelete = async (data) => {
  const eBarDelete = await fetch('/EBarNewsCallToActionBlock/DeleteEpiNews', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      stringToDelete: data,
    }),
  });
  const eBarDeleteJson = eBarDelete.json();

  return eBarDeleteJson;
};


export {
  eBarNewsCreate,
  eBarNewsUpdate,
  eBarNewsDelete,
};
