import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { typography } from '@style-guide/config';

const Title = styled.h3`
  font-size: ${rem('16px', typography.baseFontSize)};
  font-weight: 700;
  padding-top: 35px;
  margin-bottom: 10px;
`;

Title.displayName = 'Title';

export default Title;
