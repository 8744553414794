import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';
import styled from 'styled-components';

const Subtitle = styled.h4`
  font-size: ${rem('16px', typography.baseFontSize)};
  line-height: ${rem('19px', typography.baseFontSize)};
  margin-top: 10px;
`;

Subtitle.displayName = 'Subtitle';

export default Subtitle;
