import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Divider from '@components/Divider';
import rem from 'polished/lib/helpers/rem';
import { typography, colors } from '@style-guide/config';

import { DeleteEBarBookmark } from '@services/EBarBookmark';
import LoadingOverlay from '@components/LoadingOverlay';
import { sCOAnnouncementsSubHeaderOptions, sCOAnnouncementsDateTypeOptions } from '@config';
import GreenbookBookmarkListItem from './components/GreenbookBookmarkListItem';


const BookMarkListContainer = styled.ul`
  position: relative;
  padding: 30px;
`;

const BookMarkListTitle = styled.p`
  font-size: ${rem('16px', typography.baseFontSize)};
  color: #a41f35;
  font-weight: bold;
  border-bottom: 1px solid ${colors.lightGray};
  padding-bottom: 14px;
  margin-bottom: 25px;
`;

class GreenbookBookmarkList extends React.Component {
  constructor(props) {
    super(props);

    const newEBarCase = props.eBarCase.map(item => ({
      ...item,
      itemState: 'active',
    }));
    const newEBarLegislativeEnactment = props.eBarLegislativeEnactment.map(item => ({
      ...item,
      itemState: 'active',
    }));
    const newEBarSCOAnnouncement = props.eBarSCOAnnouncement.map(item => ({
      ...item,
      itemState: 'active',
    }));
    const newEBarBoardOfProfessionalsConduct = props.eBarBoardOfProfessionalsConduct.map(item => ({
      ...item,
      itemState: 'active',
    }));
    const newEBarEthicsCommissionAdvisoryOpinion =
        props.eBarEthicsCommissionAdvisoryOpinion.map(item => ({
          ...item,
          itemState: 'active',
        }));
    const newEBarAttorneyGeneralOpinion = props.eBarAttorneyGeneralOpinion.map(item => ({
      ...item,
      itemState: 'active',
    }));
    const newEBarOtherAnnouncement = props.eBarOtherAnnouncement.map(item => ({
      ...item,
      itemState: 'active',
    }));

    this.state = {
      eBarCase: newEBarCase,
      eBarLegislativeEnactment: newEBarLegislativeEnactment,
      eBarSCOAnnouncement: newEBarSCOAnnouncement,
      eBarBoardOfProfessionalsConduct: newEBarBoardOfProfessionalsConduct,
      eBarEthicsCommissionAdvisoryOpinion: newEBarEthicsCommissionAdvisoryOpinion,
      eBarAttorneyGeneralOpinion: newEBarAttorneyGeneralOpinion,
      eBarOtherAnnouncement: newEBarOtherAnnouncement,
      loadingState: '',
    };

    this.handleDeleteBookmark = this.handleDeleteBookmark.bind(this);
    this.resetToActiveHandler = this.resetToActiveHandler.bind(this);
    this.removeFromPageHandler = this.removeFromPageHandler.bind(this);
  }

  handleDeleteBookmark(guid, contentType) {
    // throw up loader until the call is made
    this.setState({ loadingState: 'loading' });

    // make the delete call
    DeleteEBarBookmark(guid, contentType)
      .then((res) => {
        if (res === false) {
          // if there wasn error removing this item, set its error state
          // we pass the state to change and id which is how we choose items from state
          if (contentType === 1) {
            this.setState({ eBarCase: this.alterItemState('error', guid, contentType), loadingState: '' });
          }
          if (contentType === 2) {
            this.setState({ eBarLegislativeEnactment: this.alterItemState('error', guid, contentType), loadingState: '' });
          }
          if (contentType === 3) {
            this.setState({ eBarSCOAnnouncement: this.alterItemState('error', guid, contentType), loadingState: '' });
          }
          if (contentType === 4) {
            this.setState({ eBarBoardOfProfessionalsConduct: this.alterItemState('error', guid, contentType), loadingState: '' });
          }
          if (contentType === 5) {
            this.setState({ eBarEthicsCommissionAdvisoryOpinion: this.alterItemState('error', guid, contentType), loadingState: '' });
          }
          if (contentType === 6) {
            this.setState({ eBarAttorneyGeneralOpinion: this.alterItemState('error', guid, contentType), loadingState: '' });
          }
          if (contentType === 7) {
            this.setState({ eBarOtherAnnouncement: this.alterItemState('error', guid, contentType), loadingState: '' });
          }
        } else {
          // no error, set its state to be removed
          if (contentType === 1) {
            this.setState({ eBarCase: this.alterItemState('remove', guid, contentType), loadingState: '' });
          }
          if (contentType === 2) {
            this.setState({ eBarLegislativeEnactment: this.alterItemState('remove', guid, contentType), loadingState: '' });
          }
          if (contentType === 3) {
            this.setState({ eBarSCOAnnouncement: this.alterItemState('remove', guid, contentType), loadingState: '' });
          }
          if (contentType === 4) {
            this.setState({ eBarBoardOfProfessionalsConduct: this.alterItemState('remove', guid, contentType), loadingState: '' });
          }
          if (contentType === 5) {
            this.setState({ eBarEthicsCommissionAdvisoryOpinion: this.alterItemState('remove', guid, contentType), loadingState: '' });
          }
          if (contentType === 6) {
            this.setState({ eBarAttorneyGeneralOpinion: this.alterItemState('remove', guid, contentType), loadingState: '' });
          }
          if (contentType === 7) {
            this.setState({ eBarOtherAnnouncement: this.alterItemState('remove', guid, contentType), loadingState: '' });
          }
        }
      })
      .catch(() => {
        // any other error, just set it to an error state
        if (contentType === 1) {
          this.setState({ eBarCase: this.alterItemState('error', guid, contentType), loadingState: '' });
        }
        if (contentType === 2) {
          this.setState({ eBarLegislativeEnactment: this.alterItemState('error', guid, contentType), loadingState: '' });
        }
        if (contentType === 3) {
          this.setState({ eBarSCOAnnouncement: this.alterItemState('error', guid, contentType), loadingState: '' });
        }
        if (contentType === 4) {
          this.setState({ eBarBoardOfProfessionalsConduct: this.alterItemState('error', guid, contentType), loadingState: '' });
        }
        if (contentType === 5) {
          this.setState({ eBarEthicsCommissionAdvisoryOpinion: this.alterItemState('error', guid, contentType), loadingState: '' });
        }
        if (contentType === 6) {
          this.setState({ eBarAttorneyGeneralOpinion: this.alterItemState('error', guid, contentType), loadingState: '' });
        }
        if (contentType === 7) {
          this.setState({ eBarOtherAnnouncement: this.alterItemState('error', guid, contentType), loadingState: '' });
        }
      });
  }

  resetToActiveHandler(whichId, contentType) {
    // after making any necessary state changes, set the state to change the UI
    if (contentType === 1) {
      this.setState({ eBarCase: this.alterItemState('active', whichId, contentType) });
    }
    if (contentType === 2) {
      this.setState({ eBarLegislativeEnactment: this.alterItemState('active', whichId, contentType) });
    }
    if (contentType === 3) {
      this.setState({ eBarSCOAnnouncement: this.alterItemState('active', whichId, contentType) });
    }
    if (contentType === 4) {
      this.setState({ eBarBoardOfProfessionalsConduct: this.alterItemState('active', whichId, contentType) });
    }
    if (contentType === 5) {
      this.setState({ eBarEthicsCommissionAdvisoryOpinion: this.alterItemState('active', whichId, contentType) });
    }
    if (contentType === 6) {
      this.setState({ eBarAttorneyGeneralOpinion: this.alterItemState('active', whichId, contentType) });
    }
    if (contentType === 7) {
      this.setState({ eBarOtherAnnouncement: this.alterItemState('active', whichId, contentType) });
    }
  }

  removeFromPageHandler(whichId, contentType) {
    // filter out the id that you're removing and re-render the list
    let newState;
    if (contentType === 1) {
      newState = this.state.eBarCase.filter(item => item.contentGuidString !== whichId);
      this.setState({ eBarCase: newState });
    }
    if (contentType === 2) {
      newState = this.state.eBarLegislativeEnactment.filter(item =>
        item.contentGuidString !== whichId);
      this.setState({ eBarLegislativeEnactment: newState });
    }
    if (contentType === 3) {
      newState = this.state.eBarSCOAnnouncement.filter(item => item.contentGuidString !== whichId);
      this.setState({ eBarSCOAnnouncement: newState });
    }
    if (contentType === 4) {
      newState = this.state.eBarBoardOfProfessionalsConduct.filter(item =>
        item.contentGuidString !== whichId);
      this.setState({ eBarBoardOfProfessionalsConduct: newState });
    }
    if (contentType === 5) {
      newState = this.state.eBarEthicsCommissionAdvisoryOpinion.filter(item =>
        item.contentGuidString !== whichId);
      this.setState({ eBarEthicsCommissionAdvisoryOpinion: newState });
    }
    if (contentType === 6) {
      newState = this.state.eBarAttorneyGeneralOpinion.filter(item =>
        item.contentGuidString !== whichId);
      this.setState({ eBarAttorneyGeneralOpinion: newState });
    }
    if (contentType === 7) {
      newState = this.state.eBarOtherAnnouncement.filter(item =>
        item.contentGuidString !== whichId);
      this.setState({ eBarOtherAnnouncement: newState });
    }
  }

  alterItemState(whichState, whichId, contentType) {
    let currentListItems;
    if (contentType === 1) {
      currentListItems = this.state.eBarCase;
    }
    if (contentType === 2) {
      currentListItems = this.state.eBarLegislativeEnactment;
    }
    if (contentType === 3) {
      currentListItems = this.state.eBarSCOAnnouncement;
    }
    if (contentType === 4) {
      currentListItems = this.state.eBarBoardOfProfessionalsConduct;
    }
    if (contentType === 5) {
      currentListItems = this.state.eBarEthicsCommissionAdvisoryOpinion;
    }
    if (contentType === 6) {
      currentListItems = this.state.eBarAttorneyGeneralOpinion;
    }
    if (contentType === 7) {
      currentListItems = this.state.eBarOtherAnnouncement;
    }

    const newState = currentListItems.map((item) => {
      if (item.contentGuidString === whichId) {
        return ({
          ...item,
          itemState: whichState,
        });
      }

      return item;
    });

    return newState;
  }

  /* eslint-disable */
  convertReplaceString(summaryString) {
    if(!summaryString || !summaryString) {
      return summaryString;
    }
    summaryString = summaryString.replaceAll('&lt;', '<');
    summaryString = summaryString.replaceAll('&gt;', '>');
    return summaryString;
  }
  /* eslint-enable */

  render() {
    return (
      <React.Fragment>
        {this.state.loadingState === 'loading' &&
          <LoadingOverlay />
        }
        {this.state.eBarCase.length > 0 && (
          <BookMarkListContainer>
            <BookMarkListTitle>Opinions</BookMarkListTitle>
            {
              this.state.eBarCase.map((item, index) => (
                <div>
                  {!!index && <Divider />}
                  <GreenbookBookmarkListItem
                    removeFromPageHandler={this.removeFromPageHandler}
                    resetToActiveHandler={this.resetToActiveHandler}
                    itemState={item.itemState}
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    caseName={item.caseName}
                    cite={item.webCitation}
                    dateType="Decided"
                    date={item.dateDecidedString}
                    summary={item.summary}
                    ctaLabel="PDF"
                    ctaUrl={item.pDFLink}
                    practiceName={item.practiceName}
                    courtName={item.courtName}
                    contentType={1}
                    deleteBookmarkHandler={this.handleDeleteBookmark}
                  />
                </div>
              ))}
          </BookMarkListContainer>
        )}
        {this.state.eBarLegislativeEnactment.length > 0 && (
          <BookMarkListContainer>
            <BookMarkListTitle>Legislative Enactments</BookMarkListTitle>
            {
              this.state.eBarLegislativeEnactment.map((item, index) => (
                <span>
                  {!!index && <Divider />}
                  <GreenbookBookmarkListItem
                    removeFromPageHandler={this.removeFromPageHandler}
                    resetToActiveHandler={this.resetToActiveHandler}
                    itemState={item.itemState}
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.billNumber}
                    date={item.addedDate}
                    dateType="Effective"
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.ctaLabel}
                    ctaUrl={item.ctaUrl}
                    contentType={2}
                    deleteBookmarkHandler={this.handleDeleteBookmark}
                  />
                </span>
              ))}
          </BookMarkListContainer>
        )}
        {this.state.eBarSCOAnnouncement.length > 0 && (
          <BookMarkListContainer>
            <BookMarkListTitle>Supreme Court of Ohio Announcements</BookMarkListTitle>
            {
              this.state.eBarSCOAnnouncement.map((item, index) => (
                <div>
                  {!!index && <Divider />}
                  <GreenbookBookmarkListItem
                    removeFromPageHandler={this.removeFromPageHandler}
                    resetToActiveHandler={this.resetToActiveHandler}
                    itemState={item.itemState}
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.cite}
                    practiceName={!item.subHeader ? '' : sCOAnnouncementsSubHeaderOptions.filter(c =>
                      c.value === item.subHeader.toString())[0].label}
                    dateType={!item.dateType ? '' : sCOAnnouncementsDateTypeOptions.filter(c =>
                      c.value === item.dateType.toString())[0].label}
                    date={item.effectivePostedDate}
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.ctaLabel}
                    ctaUrl={item.ctaUrl}
                    contentType={3}
                    deleteBookmarkHandler={this.handleDeleteBookmark}
                  />
                </div>
              ))}
          </BookMarkListContainer>
        )}
        {this.state.eBarBoardOfProfessionalsConduct.length > 0 && (
          <BookMarkListContainer>
            <BookMarkListTitle>Board Of Professionals Conduct</BookMarkListTitle>
            {
              this.state.eBarBoardOfProfessionalsConduct.map((item, index) => (
                <div>
                  {!!index && <Divider />}
                  <GreenbookBookmarkListItem
                    removeFromPageHandler={this.removeFromPageHandler}
                    resetToActiveHandler={this.resetToActiveHandler}
                    itemState={item.itemState}
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.cite}
                    date={item.addedDate}
                    dateType="Issued"
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.ctaLabel}
                    ctaUrl={item.ctaUrl}
                    contentType={4}
                    deleteBookmarkHandler={this.handleDeleteBookmark}
                  />
                </div>
              ))}
          </BookMarkListContainer>
        )}
        {this.state.eBarAttorneyGeneralOpinion.length > 0 && (
          <BookMarkListContainer>
            <BookMarkListTitle>Attorney General Opinions</BookMarkListTitle>
            {
              this.state.eBarAttorneyGeneralOpinion.map((item, index) => (
                <div>
                  {!!index && <Divider />}
                  <GreenbookBookmarkListItem
                    removeFromPageHandler={this.removeFromPageHandler}
                    resetToActiveHandler={this.resetToActiveHandler}
                    itemState={item.itemState}
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.cite}
                    date={item.addedDate}
                    dateType="Issued"
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.ctaLabel}
                    ctaUrl={item.ctaUrl}
                    contentType={6}
                    deleteBookmarkHandler={this.handleDeleteBookmark}
                  />
                </div>
              ))}
          </BookMarkListContainer>
        )}
        {this.state.eBarEthicsCommissionAdvisoryOpinion.length > 0 && (
          <BookMarkListContainer>
            <BookMarkListTitle>Ethics Commission Advisory Opinions</BookMarkListTitle>
            {
              this.state.eBarEthicsCommissionAdvisoryOpinion.map((item, index) => (
                <div>
                  {!!index && <Divider />}
                  <GreenbookBookmarkListItem
                    removeFromPageHandler={this.removeFromPageHandler}
                    resetToActiveHandler={this.resetToActiveHandler}
                    itemState={item.itemState}
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.cite}
                    date={item.issueDate}
                    dateType="Issued"
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.ctaLabel}
                    ctaUrl={item.ctaUrl}
                    contentType={5}
                    deleteBookmarkHandler={this.handleDeleteBookmark}
                  />
                </div>
              ))}
          </BookMarkListContainer>
        )}
        {this.state.eBarOtherAnnouncement.length > 0 && (
          <BookMarkListContainer>
            <BookMarkListTitle>Other Announcements</BookMarkListTitle>
            {
              this.state.eBarOtherAnnouncement.map((item, index) => (
                <div>
                  {!!index && <Divider />}
                  <GreenbookBookmarkListItem
                    removeFromPageHandler={this.removeFromPageHandler}
                    resetToActiveHandler={this.resetToActiveHandler}
                    itemState={item.itemState}
                    guid={item.contentGuidString}
                    key={item.contentGuidString}
                    title={item.title}
                    cite={item.partnerEntity}
                    date={item.postedDate}
                    dateType="Posted"
                    summary={this.convertReplaceString(item.summary)}
                    ctaLabel={item.ctaLabel}
                    ctaUrl={item.ctaUrl}
                    contentType={7}
                    deleteBookmarkHandler={this.handleDeleteBookmark}
                  />
                </div>
              ))}
          </BookMarkListContainer>
        )}
      </React.Fragment>
    );
  }
}


GreenbookBookmarkList.propTypes = {
  eBarCase: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    summary: PropTypes.string,
    caseName: PropTypes.string,
    webCitation: PropTypes.string,
    activeDate: PropTypes.string,
    casemakerLink: PropTypes.string,
    pDFLink: PropTypes.string,
    practiceId: PropTypes.number,
    courtId: PropTypes.number,
    practiceName: PropTypes.string,
    courtName: PropTypes.string,
    contentGuidString: PropTypes.string,
    dateDecidedString: PropTypes.string,
    isBookmarked: PropTypes.bool,
  })),
  eBarLegislativeEnactment: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    billNumber: PropTypes.string,
    effectiveDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  eBarSCOAnnouncement: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    subHeader: PropTypes.string,
    title: PropTypes.string,
    cite: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    effectivePostedDateString: PropTypes.string,
    dateTypeString: PropTypes.string,
    summary: PropTypes.string,
    createdDate: PropTypes.string,
    activeDateString: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  eBarBoardOfProfessionalsConduct: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  eBarEthicsCommissionAdvisoryOpinion: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  eBarAttorneyGeneralOpinion: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    summary: PropTypes.string,
    cite: PropTypes.string,
    issueDate: PropTypes.string,
    addedDate: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaUrl: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
  eBarOtherAnnouncement: PropTypes.arrayOf(PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    partnerEntity: PropTypes.string,
    postedDate: PropTypes.string,
    summary: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    activeDateString: PropTypes.string,
    createdDate: PropTypes.string,
    modifiedBy: PropTypes.string,
  })),
};

GreenbookBookmarkList.defaultProps = {
  eBarCase: [],
  eBarLegislativeEnactment: [],
  eBarSCOAnnouncement: [],
  eBarBoardOfProfessionalsConduct: [],
  eBarEthicsCommissionAdvisoryOpinion: [],
  eBarAttorneyGeneralOpinion: [],
  eBarOtherAnnouncement: [],
};

BookMarkListContainer.displayName = 'BookMarkListContainer';
GreenbookBookmarkList.displayName = 'GreenbookBookmarkList';

export default GreenbookBookmarkList;
