import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography, colors } from '@style-guide/config';
import {
  ButtonReset,
  InputReset,
  SearchIcon,
} from '@style-guide/mixins';

const SearchForm = styled.div`
  border-bottom: 2px solid ${colors.lightGray};
  display: flex;
  font-size: ${rem('16px', typography.baseFontSize)};
`;

SearchForm.displayName = 'SearchForm';

const SearchInput = styled.input`
  ${InputReset()}

  background-color: ${colors.white};
  letter-spacing: .03em;
  padding: 20px;
  width: 100%;

  &::placeholder {
    color: ${colors.charcoal};
    font-style: italic;
  }
`;

SearchInput.displayName = 'SearchInput';

const SearchSubmitButton = styled.button`
  ${ButtonReset()}
  ${SearchIcon({
    color: colors.charcoal,
    size: '10px',
    offset: '25px',
  })};

  background-color: ${colors.white};
  padding: 20px 30px 20px 35px;
  position: relative;
`;

SearchSubmitButton.displayName = 'SearchSubmitButton';

class Search extends React.Component {
  constructor() {
    super();

    this.state = {
      keyword: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.search = this.search.bind(this);
  }

  handleInputChange(e) {
    this.setState({
      keyword: e.target.value,
    });
  }

  search() {
    const { keyword } = this.state;

    window.location.href = `/search?search={"keyword": "${keyword}", "section": []}`;
  }

  render() {
    const { isMobile } = this.props;
    const { keyword } = this.state;

    return (
      <SearchForm
        id={isMobile ? 'mobileHeaderSearchForm' : 'desktopHeaderSearchForm'}
      >
        <SearchInput
          type="text"
          placeholder="Search"
          value={keyword}
          onChange={this.handleInputChange}
          id={isMobile ? 'mobileHeaderSearchInput' : 'desktopHeaderSearchInput'}
        />
        <SearchSubmitButton
          type="button"
          id={isMobile ? 'mobileHeaderSearchButton' : 'desktopHeaderSearchButton'}
          onClick={this.search}
        />
      </SearchForm>
    );
  }
}

Search.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

Search.displayName = 'Search';

export default Search;
