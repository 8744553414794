import styled from 'styled-components';

const CaseSpan = styled.span`
  color: #024B3A;
  display: block;
  line-height: 24px;
`;

CaseSpan.displayName = 'CaseSpan';

export default CaseSpan;
