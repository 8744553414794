import styled from 'styled-components';
import { rem } from 'polished';

import { colors, typography } from '@style-guide/config';

const Category = styled.span`
  display: block;
  color: ${colors.darkRed};
  font-size: ${rem('14px', typography.baseFontSize)};
  font-style: italic;
  font-weight: 600;
`;

Category.displayName = 'Category';

export default Category;
