import styled from 'styled-components';
// import { breakpoints } from '@style-guide/config';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1130px;
  padding: 50px 0px;
  width: 100%;
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
