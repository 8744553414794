import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';

import { colors } from '@style-guide/config';

import Button from '@style-guide/components/Button';
import LinkButton from '@style-guide/components/LinkButton';

import FormFieldSection from '@components/FormFieldSection';
import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormCheckbox from '@components/ReduxFormCheckbox';

const LinkItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;

  &:not(:first-child) {
    border-top: 1px solid ${colors.lightGray};
  }

  a {
    margin-left: 5px;
  }
`;

LinkItem.displayName = 'LinkItem';

const CopyText = styled.div`
  margin: 15px 0;
`;

CopyText.displayName = 'CopyText';

const SaveContainer = styled.div`
  button {
    margin: 0;
  }
`;

SaveContainer.displayName = 'SaveContainer';

const MarketingPreferencesInfoForm = ({
  handleSubmit,
  loading,
  pristine,
  hasSaveError,
  links,
  linksSectionTitle,
  copy,
}) => {
  const linkItems = links.map(({
    description,
    link,
    buttonText,
  }) => (
    <LinkItem>
      <span>{description}</span>
      <LinkButton
        href={link}
        target="_blank"
      >
        {buttonText}
      </LinkButton>
    </LinkItem>
  ));

  return (
    <form
      id="marketingPreferencesInfoForm"
      onSubmit={handleSubmit}
    >
      <FormFieldSection
        heading="Email"
      >
        <FormFieldWrapper wide>
          <div>
            <Field
              id="cleMarketing"
              label="CLE Marketing"
              name="cleMarketing"
              props={{
                value: true,
              }}
              type="checkbox"
              component={ReduxFormCheckbox}
            />
          </div>
        </FormFieldWrapper>
        <FormFieldWrapper>
          <div>
            <Field
              id="practiceManagementDiscountPrograms"
              label="Practice Management Discount Programs"
              name="practiceManagementDiscountPrograms"
              props={{
                value: true,
              }}
              type="checkbox"
              component={ReduxFormCheckbox}
            />
          </div>
        </FormFieldWrapper>
        <FormFieldWrapper>
          <div>
            <Field
              id="travelDiscountPrograms"
              label="Travel Discount Programs"
              name="travelDiscountPrograms"
              props={{
                value: true,
              }}
              type="checkbox"
              component={ReduxFormCheckbox}
            />
          </div>
        </FormFieldWrapper>
        {copy && (
          <FormFieldWrapper>
            <CopyText>{copy}</CopyText>
          </FormFieldWrapper>
        )}
        <SaveContainer>
          <Button
            type="submit"
            disabled={(pristine || loading) && !hasSaveError}
          >
            Save
          </Button>
        </SaveContainer>
      </FormFieldSection>
      {!!links.length && (
        <FormFieldSection
          heading={linksSectionTitle}
        >
          <ul>
            {linkItems}
          </ul>
        </FormFieldSection>
      )}
    </form>
  );
};

MarketingPreferencesInfoForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  hasSaveError: PropTypes.bool,
  links: PropTypes.arrayOf(PropTypes.object),
  linksSectionTitle: PropTypes.string,
  copy: PropTypes.string,
};

MarketingPreferencesInfoForm.defaultProps = {
  hasSaveError: false,
  links: [],
  linksSectionTitle: '',
  copy: '',
};

MarketingPreferencesInfoForm.displayName = 'MarketingPreferencesInfoForm';

export default reduxForm({
  form: 'marketingPreferencesInfoForm',
  enableReinitialize: true,
})(MarketingPreferencesInfoForm);
