import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const SpeakersContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

SpeakersContainer.displayName = 'SpeakersContainer';

export default SpeakersContainer;
