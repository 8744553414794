import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { breakpoints, colors } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import removeNonSavedField from '@views/EditDirectory/services/removeNonSavedField';

import School from './components/School';

const SchoolListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  &:not(:first-child) {
    border-top: 1px solid ${colors.gray};
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
    width: 100%;
  }
`;

SchoolListItem.displayName = 'SchoolListItem';

const AddSchoolContainer = styled.li`
  button {
    margin: 0;
  }
`;

AddSchoolContainer.displayName = 'AddSchoolContainer';

const createNewSchool = () => ({
  Deleted: false,
  Id: 0,
  Name: '',
  IsSaved: false,
});

const EducationList = ({ fields = [] }) => {
  const renderSchool = (school, index) => {
    if (!fields.get(index).Deleted) {
      return (
        <SchoolListItem
          key={school}
        >
          <School
            school={school}
            index={index}
            onRemove={() => removeNonSavedField(fields, index)}
          />
        </SchoolListItem>
      );
    }

    return undefined;
  };

  return (
    <ul>
      {fields.map(renderSchool)}
      <AddSchoolContainer>
        <Button
          type="button"
          onClick={() => fields.push(createNewSchool())}
        >
          ADD SCHOOL
        </Button>
      </AddSchoolContainer>
    </ul>
  );
};

EducationList.propTypes = {
  fields: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

EducationList.displayName = 'EducationList';

export default EducationList;
