import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  breakpoints,
  typography,
} from '@style-guide/config';

const SearchHeading = styled.h2`
  font-size: ${rem('18px', typography.baseFontSize)};
  font-weight: 500;
  margin: 20px 0px 15px 0px;
  text-align: left;
  width: 100%;

  @media(min-width: ${breakpoints.m}) {
    font-size: ${rem('32px', typography.baseFontSize)};
    margin: 32px 0px 24px 0px;
    text-align: center;
  }
`;

SearchHeading.displayName = 'SearchHeading';

export default SearchHeading;
