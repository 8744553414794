import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

import { colors } from '@style-guide/config';
import Store from '@redux';

import ShoppingCartContent from './components/ShoppingCartContent';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.lightGray};
  }
`;

class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  render() {
    const { checkoutLink } = this.props;
    return (
      <Provider store={this.store}>
        <GlobalStyle />
        <ShoppingCartContent
          checkoutLink={checkoutLink}
        />
      </Provider>
    );
  }
}

ShoppingCart.propTypes = {
  checkoutLink: PropTypes.string.isRequired,
};

ShoppingCart.displayName = 'ShoppingCart';

export default ShoppingCart;
