import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const FormButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 15px;
  }

  @media (min-width: ${breakpoints.s}) {
    flex-direction: row;

    button:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

FormButtonWrapper.displayName = 'FormButtonWrapper';

export default FormButtonWrapper;
