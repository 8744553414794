import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import FormContainer from '@components/FormContainer';

const HeadingDiv = styled.div`
  background: #263746;
  color: #FFF;
  font-family: 'proxima-nova', 'Helvetica Nueue', sans-serif;
  font-weight: 500;
  padding: 12px;
`;

HeadingDiv.displayName = 'HeadingDiv';

const ContentDiv = styled.div`
  background: #FFF;
  padding: 14px;
`;

ContentDiv.displayName = 'ContentDiv';

const EmailBlock = ({
  email,
  description,
  title,
}) => (
  <FormContainer>
    <HeadingDiv>{!title ? 'Your Preferred Email:' : title} {email}</HeadingDiv>
    <ContentDiv>{!description ? 'Update your preferred email in the Profile Information tab (in the navigation to the left)' : description}
    </ContentDiv>
  </FormContainer>
);

EmailBlock.propTypes = {
  email: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

EmailBlock.displayName = 'EmailBlock';

export default EmailBlock;
