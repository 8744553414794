import styled from 'styled-components';

const CartItemContainer = styled.li`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  line-height: 1.25em;

  + li {
    padding-top: 10px;
  }
`;

CartItemContainer.displayName = 'CartItemContainer';

export default CartItemContainer;
