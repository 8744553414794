const renderGoogleMapLinkQuery = (location) => {
  if (!location) {
    return undefined;
  }
  const lines = [
    location.line1,
    location.line2,
    location.line3,
    location.line4,
  ];

  const extantLines = lines.filter(x => x);

  return encodeURIComponent(extantLines.join());
};

export default renderGoogleMapLinkQuery;
