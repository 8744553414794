const warn = ({
  FirstName,
  LastName,
  OhioSupremeCourtNumber,
  OhioSupremeCourtNumberOptOut,
}, props) => {
  const warnings = {};

  if (
    !props.loading
    &&
    (!FirstName || !LastName || (!OhioSupremeCourtNumber && !OhioSupremeCourtNumberOptOut))
  ) {
    /* this is part of redux-form API */
    /* eslint-disable-next-line no-underscore-dangle */
    warnings._warning = 'Missing required fields';
    props.setWarning(true);
  } else {
    props.setWarning(false);
  }

  return warnings;
};

export default warn;
