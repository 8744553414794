import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRssSquare } from '@fortawesome/free-solid-svg-icons';

import { breakpoints, colors, typography } from '@style-guide/config';
import Link from '@style-guide/mixins/Link';

import Divider from '@components/Divider';

const CareerCenterContainer = styled.div`
  background: ${colors.white};
  padding: 30px 15px;

  h3 {
    font-size: ${rem('24px', typography.baseFontSize)};
    font-weight: 700;
    margin: 15px 0;

    svg {
      color: ${colors.darkRed};
      margin-left: 10px;
    }
  }

  @media (min-width: ${breakpoints.m}) {
    padding: 30px;
  }
`;

CareerCenterContainer.displayName = 'CareerCenterContainer';

const Summary = styled.div`
  background: ${colors.lightGray};
  margin-top: 10px;
  padding: 10px 15px;

  p {
    margin: 0;
    overflow: hidden;
  }
`;

Summary.displayName = 'Summary';

const CareerItem = styled.li`
  margin: 30px 0;

  a {
    ${Link({ type: 'red' })}

    font-size: 1rem;
    line-height: 1.5rem;
  }

  @media (min-width: ${breakpoints.m}) {
    margin: 30px 15px;
  }
`;

CareerItem.displayName = 'CareerItem';

const PublishDate = styled.div`
  font-size: ${rem('14px', typography.baseFontSize)};
  margin: 10px 0 0 15px;

  span {
    font-weight: 600;
  }
`;

PublishDate.displayName = 'PublishDate';

const Description = styled.p`
  font-size: 1rem;
  margin: 15px 0 0;

  @media (min-width: ${breakpoints.m}) {
    margin: 15px 15px 0;
  }
`;

Description.displayName = 'Description';

const CareerCenter = ({
  careers,
  title,
  description,
}) => {
  const careerItems = careers.map(({
    id,
    title: careerTitle,
    summary,
    links,
    publishDate,
  }) => (
    <CareerItem
      key={id}
    >
      <h5>
        <a
          href={links[0].uri}
          target="_blank"
          rel="noopener noreferrer"
        >
          {careerTitle.text}
        </a>
      </h5>
      {publishDate && (
        <PublishDate>
          <span>Published: </span>
          {moment(new Date(publishDate)).format('MMMM Do, YYYY')}
        </PublishDate>
      )}
      {summary && summary.text && (
        <Summary>
          <p>{summary.text}</p>
        </Summary>
      )}
    </CareerItem>
  ));

  return (
    <CareerCenterContainer>
      <h3>
        {title}
        <FontAwesomeIcon icon={faRssSquare} />
      </h3>
      <Divider />
      {description && (
        <Description>{description}</Description>
      )}
      <ul>
        {careerItems}
      </ul>
    </CareerCenterContainer>
  );
};

CareerCenter.propTypes = {
  careers: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

CareerCenter.defaultProps = {
  careers: [],
  description: '',
};

CareerCenter.displayName = 'CareerCenter';

export default CareerCenter;
