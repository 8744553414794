import styled from 'styled-components';

import { colors } from '@style-guide/config';

const Wrapper = styled.div`
  align-items: center;
  background-color: ${colors.lightGray};
  display: flex;
  flex-direction: column;
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
