import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

import InputRow from './InputRow';

const SelectRow = styled(InputRow)`
  @media (min-width: ${breakpoints.xl}) {
    flex-wrap: nowrap;
  }
`;

SelectRow.displayName = 'SelectRow';

export default SelectRow;
