import React from 'react';
import PropTypes from 'prop-types';

import PageContainerRightRail from '@components/PageContainerRightRail';
import ResourceContent from './components/ResourceContent';
import ResourceRightRail from './components/ResourceRightRail';

const ResourceBody = ({
  articleContent,
  articleContentAreaName,
  rightRailContent,
  rightRailContentAreaName,
  contributors,
  tags,
  relatedSpecialFeatures,
  relatedArticles,
  relatedCles,
  isMember,
  pageId,
  isBookmarked,
  isSpecialFeature,
  isRestricted,
  createAccountUrl,
}) => (
  <PageContainerRightRail>
    <ResourceContent
      content={articleContent}
      contributors={contributors}
      tags={tags}
      contentAreaName={articleContentAreaName}
      isMember={isMember}
      pageId={pageId}
      isBookmarked={isBookmarked}
      isSpecialFeature={isSpecialFeature}
      isRestricted={isRestricted}
      createAccountUrl={createAccountUrl}
      relatedArticles={relatedArticles}
      relatedCles={relatedCles}
    />
    <ResourceRightRail
      content={rightRailContent}
      contentAreaName={rightRailContentAreaName}
      relatedSpecialFeatures={relatedSpecialFeatures}
      relatedArticles={relatedArticles}
      isSpecialFeature={isSpecialFeature}
    />
  </PageContainerRightRail>
);

ResourceBody.propTypes = {
  articleContent: PropTypes.string.isRequired,
  articleContentAreaName: PropTypes.string.isRequired,
  rightRailContent: PropTypes.string.isRequired,
  rightRailContentAreaName: PropTypes.string.isRequired,
  contributors: PropTypes.arrayOf(PropTypes.object).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  relatedSpecialFeatures: PropTypes.arrayOf(PropTypes.object),
  relatedArticles: PropTypes.arrayOf(PropTypes.object),
  relatedCles: PropTypes.arrayOf(PropTypes.object),
  isMember: PropTypes.bool.isRequired,
  pageId: PropTypes.number.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  isSpecialFeature: PropTypes.bool.isRequired,
  isRestricted: PropTypes.bool.isRequired,
  createAccountUrl: PropTypes.string.isRequired,
};

ResourceBody.defaultProps = {
  relatedSpecialFeatures: [],
  relatedArticles: [],
  relatedCles: [],
};

ResourceBody.displayName = 'ResourceBody';

export default ResourceBody;
