import trimStart from 'lodash/trimEnd';

const getUrlParameterByName = (name, url) => {
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const urlToParse = url || window.location.href;
  const results = regex.exec(urlToParse);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const urlEncode = data => Object.keys(data).map(keyName => (
  `${encodeURIComponent(keyName)}=${encodeURIComponent(data[keyName])}`
)).join('&');

// takes in a window.location object, gives you the back the url with a trailing slash
// IE https://ohiobar.org/practice-library-results/
const getUrl = (location) => {
  const {
    origin,
    pathname,
  } = location;

  return `${origin}${pathname && trimStart(pathname, '/')}`;
};

export {
  getUrl,
  getUrlParameterByName,
  urlEncode,
};
