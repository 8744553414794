import styled from 'styled-components';
import { breakpoints, colors } from '@style-guide/config';

const ResourceHeadingContainer = styled.div`
  background-color: ${colors.lightGray};
  min-height: 190px;
  padding: 20px;
  width: 100%;

  > svg {
    display: block;
  }

  @media (min-width: ${breakpoints.m}) {
    padding: 20px 35px 140px;
  }
`;

ResourceHeadingContainer.displayName = 'ResourceHeadingContainer';

export default ResourceHeadingContainer;
