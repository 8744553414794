import styled from 'styled-components';

const BlockWrapper = styled.div`
  padding: 0px 0px 30px;
  background-color: #fff;
`;

BlockWrapper.displayName = 'BlockWrapper';

export default BlockWrapper;
