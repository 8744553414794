import { urlEncode } from '@services/Url';

const serializeFormData = data => ({
  contentGuidString: data.contentGuidString,
  title: data.title,
  summary: data.summary,
  cite: data.cite,
  ctaLabel: data.ctaLabel,
  ctaUrl: data.ctaUrl,
  issueDate: data.issueDate,
  addedDate: data.addedDate,
  createdDate: data.createdDate,
  createdBy: data.createdBy,
});

const eBarEthicsCreate = async (data) => {
  const eBarCreate = await fetch('/EBarEthicsCallToActionBlock/CreateEpiEthics', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarCreateJson = eBarCreate.json();

  return eBarCreateJson;
};

const eBarEthicsUpdate = async (data) => {
  const eBarUpdate = await fetch('/EBarEthicsCallToActionBlock/UpdateEpiEthics', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarUpdateJson = eBarUpdate.json();

  return eBarUpdateJson;
};

const eBarEthicsDelete = async (data) => {
  const eBarDelete = await fetch('/EBarEthicsCallToActionBlock/DeleteEpiEthics', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      stringToDelete: data,
    }),
  });
  const eBarDeleteJson = eBarDelete.json();

  return eBarDeleteJson;
};


export {
  eBarEthicsCreate,
  eBarEthicsUpdate,
  eBarEthicsDelete,
};
