import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { hydrateEnewsLettersInfo } from '@redux/modules/CommunicationPreferences/ENewsLettersInfo';

import Store from '@redux';

import ENewsLettersInfoContent from './components/ENewsLettersInfoContent';

class ENewsLettersBlock extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(hydrateEnewsLettersInfo(this.props.eBarNewsLettersData));
  }

  render() {
    return (
      <Provider store={this.store}>
        <ENewsLettersInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

ENewsLettersBlock.propTypes = {
  eBarNewsLettersData: PropTypes.shape({
    eNewsLettersGreenBook: PropTypes.bool,
    frequencyGreenBook: PropTypes.number,
    allPracticeAreasSelected: PropTypes.string,
    allCourtsSelected: PropTypes.string,
    practiceAreasSelected: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    courtsSelected: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
    eNewsLettersBriefs: PropTypes.bool,
  }).isRequired,
  eBarAreasOfLawList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  eBarCourtsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  title: PropTypes.string.isRequired,
  isMember: PropTypes.bool.isRequired,
  labelContent: PropTypes.string,
};

ENewsLettersBlock.defaultProps = {
  labelContent: '',
};

ENewsLettersBlock.displayName = 'ENewsLettersBlock';

export default ENewsLettersBlock;
