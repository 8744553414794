import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import {
  clePostQuizDetails,
  clePostMediaDetails,
} from '@services/CLEOnDemandService';
import { breakpoints } from '@style-guide/config';
import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import OuterPlayer from './components/OuterPlayer';
import screenfull from './components/ScreenFull';
import ProgressTracker from './components/ProgressTracker';
import CourseMaterialsTab from './components/CourseMaterialsTab';
import HeaderDiv from './components/HeaderDiv';
import Title from './components/Title';
import Wrapper from './components/Wrapper';
import WrapperInner from './components/WrapperInner';
import PopUpModal from './components/PopUpModal/PopUpModal';
import MessageModalBody from './components/MessageModalBody';
import ModalBody from './components/ModalBody';
import CardBody from './components/CardBody';
import CardWrapperTop from './components/CardWrapperTop';
import CardWrapper from './components/CardWrapper';

class CLEOnDemand extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTime: 0,
      maxTime: this.props.lastDuration,
      // ended: false,
      playing: false,
      checkPoint1: this.props.check1Crossed,
      checkPoint2: this.props.check2Crossed,
      checkPoint3: this.props.check3Crossed,
      checkPoint4: this.props.check4Crossed,
      showPopUp: this.props.lastDuration !== 0,
      showMessagePopUp: this.props.checkpointNo !== 0,
      checkpointPopUpTitle: this.props.question,
      resumePopUpTitle: `Would you like to resume viewing from where you left off
        at ${this.props.resumeTime} or view the video from the beginning?`,
      checkpointNo: this.props.checkpointNo,
      isFullscreen: false,
      audio: new Audio('/Static/audio/audioAlert.mp3'),
      width: window.innerWidth,
    };

    this.onClickYes = this.onClickYes.bind(this);
    this.onResumeYes = this.onResumeYes.bind(this);
    this.onResumeNo = this.onResumeNo.bind(this);
    this.onReady = this.onReady.bind(this);
    this.calculateHeight = this.calculateHeight.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.calculateDuration = this.calculateDuration.bind(this);
    this.convertChecks = this.convertChecks.bind(this);
    this.noOfCheckpoints = this.noOfCheckpoints.bind(this);
    this.crossedCheckpoints = this.crossedCheckpoints.bind(this);
    this.progress = this.progress.bind(this);
    this.processCheckpoint = this.processCheckpoint.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.seek = this.seek.bind(this);
    this.start = this.start.bind(this);
    this.ended = this.ended.bind(this);
    this.tick = this.tick.bind(this);
    this.postMediaTrack = this.postMediaTrack.bind(this);

    this.player = React.createRef();
  }

  componentDidMount() {
    setInterval(() => this.tick(), 5000);
    window.addEventListener('resize', this.updateDimensions);
  }

  onClickYes() {
    this.state.audio.pause();
    const video = document.getElementById('videoplayer');
    if (screenfull.isEnabled) {
      if (this.state.isFullscreen) {
        screenfull.request(video);
      }
    }
    clePostQuizDetails(this.props.meetingInstance.sourceId, this.state.checkpointNo)
      .then(() => {
      })
      .catch(() => {
      });

    this.setState({
      showMessagePopUp: false,
      playing: true,
    });

    switch (this.state.checkpointNo) {
      case 1: this.setState({ checkPoint1: true });
        break;
      case 2: this.setState({ checkPoint2: true });
        break;
      case 3: this.setState({ checkPoint3: true });
        break;
      case 4: this.setState({ checkPoint4: true });
        break;
      default: break;
    }
  }

  onResumeYes() {
    this.setState({
      maxTime: this.props.lastDuration,
      showPopUp: false,
    });
    this.player.current.seekTo(this.props.lastDuration, 'seconds');
  }

  onResumeNo() {
    this.setState({
      maxTime: 0,
      showMessagePopUp: false,
      showPopUp: false,
    });
    this.player.current.seekTo(0, 'seconds');
  }

  onReady() {
    const timeToStart = this.props.lastDuration;
    this.player.current.seekTo(timeToStart, 'seconds');
    this.setState({ currentTime: this.player.current.getCurrentTime() });
  }

  calculateHeight() {
    let height = 0;
    if (this.state.width > Number(breakpoints.xl.replace(/px$/, ''))) {
      height = 525;
    } else if (this.state.width > Number(breakpoints.l.replace(/px$/, ''))) {
      height = 400;
    } else if (this.state.width > Number(breakpoints.m.replace(/px$/, ''))) {
      height = 330;
    } else if (this.state.width > Number(breakpoints.s.replace(/px$/, ''))) {
      height = 200;
    } else if (this.state.width > 400) {
      height = 170;
    } else if (this.state.width > Number(breakpoints.xs.replace(/px$/, ''))) {
      height = 130;
    } else {
      height = 110;
    }
    return height;
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  /* eslint-disable */
  calculateDuration(time) {
    var a = time.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    return seconds;
  }
  /* eslint-enable */

  convertChecks() {
    let checks = '';
    checks += this.state.checkPoint1 ? '1' : '';
    checks += this.state.checkPoint2 ? '2' : '';
    checks += this.state.checkPoint3 ? '3' : '';
    checks += this.state.checkPoint4 ? '4' : '';

    return checks;
  }

  noOfCheckpoints() {
    let count = 0;

    if (this.props.checkpoint1 !== null) {
      if (this.calculateDuration(this.props.checkpoint1) > 0) {
        count += 1;
      }
    }
    if (this.props.checkpoint2 !== null) {
      if (this.calculateDuration(this.props.checkpoint2) > 0) {
        count += 1;
      }
    }
    if (this.props.checkpoint3 !== null) {
      if (this.calculateDuration(this.props.checkpoint3) > 0) {
        count += 1;
      }
    }
    if (this.props.checkpoint4 !== null) {
      if (this.calculateDuration(this.props.checkpoint4) > 0) {
        count += 1;
      }
    }

    return count;
  }

  crossedCheckpoints() {
    let count = 0;

    if (this.state.checkPoint1) {
      count += 1;
    }
    if (this.state.checkPoint2) {
      count += 1;
    }
    if (this.state.checkPoint3) {
      count += 1;
    }
    if (this.state.checkPoint4) {
      count += 1;
    }

    return count;
  }

  progress() {
    this.setState({ currentTime: this.player.current.getCurrentTime() });

    if (this.state.maxTime < this.player.current.getCurrentTime()) {
      this.setState({ maxTime: this.player.current.getCurrentTime() });
    }

    if (!this.state.showMessagePopUp) {
      if (this.props.checkpoint1 !== null) {
        if (this.player.current.getCurrentTime() > this.calculateDuration(this.props.checkpoint1)
          && this.player.current.getCurrentTime() <
          this.calculateDuration(this.props.checkpoint1) + 5 && !this.state.checkPoint1) {
          this.processCheckpoint(1);
        }
      }

      if (this.props.checkpoint2 !== null) {
        if (this.player.current.getCurrentTime() > this.calculateDuration(this.props.checkpoint2)
          && this.player.current.getCurrentTime() <
          this.calculateDuration(this.props.checkpoint2) + 5 && !this.state.checkPoint2) {
          this.processCheckpoint(2);
        }
      }

      if (this.props.checkpoint3 !== null) {
        if (this.player.current.getCurrentTime() > this.calculateDuration(this.props.checkpoint3)
          && this.player.current.getCurrentTime() <
          this.calculateDuration(this.props.checkpoint3) + 5 && !this.state.checkPoint3) {
          this.processCheckpoint(3);
        }
      }

      if (this.props.checkpoint4 !== null) {
        if (this.player.current.getCurrentTime() > this.calculateDuration(this.props.checkpoint4)
          && this.player.current.getCurrentTime() <
          this.calculateDuration(this.props.checkpoint4) + 5 && !this.state.checkPoint4) {
          this.processCheckpoint(4);
        }
      }
    }
  }

  async processCheckpoint(checkpoint) {
    this.state.audio.play();
    this.setState({
      playing: false,
      checkpointNo: checkpoint,
    });
    const delay = ms => new Promise(res => setTimeout(res, ms));
    await delay(1000);
    /* eslint-disable */
    if (this.state.width < Number(breakpoints.s.replace(/px$/, ''))) {
      if (screenfull.isEnabled) {
        if (screenfull.isFullscreen) {
          this.setState({ isFullscreen: true });
          screenfull.exit();
        }
        this.setState({
          showMessagePopUp: true,
        });
      } else if (confirm(this.props.question)) {
        this.onClickYes();
      } 
    } else {      
      if (screenfull.isEnabled) {
        if (screenfull.isFullscreen) {
          this.setState({ isFullscreen: true });
          screenfull.exit();
        } else {
          this.setState({ isFullscreen: false });
        }
        this.setState({
          showMessagePopUp: true,
        });
      } else if (confirm(this.props.question)) {
        this.onClickYes();
      }
    }
  /* eslint-enable */
  }

  play() {
    this.setState({ playing: true });
  }

  pause() {
    this.setState({
      currentTime: Math.floor(this.player.current.getCurrentTime()),
    });

    clePostMediaDetails(this.props.meetingInstance.sourceId,
      Math.floor(this.state.currentTime), Math.floor(this.player.current.getDuration()),
      'pause')
      .then(() => {
      })
      .catch(() => {
      });
  }

  seek(seconds) {
    this.setState({ playing: false });
    if (this.state.width > Number(breakpoints.m.replace(/px$/, ''))) {
      if (seconds < this.state.maxTime) {
        this.player.current.seekTo(seconds, 'seconds');
      } else {
        this.player.current.seekTo(this.state.maxTime, 'seconds');
      }
    } else if (this.state.width < Number(breakpoints.m.replace(/px$/, ''))) {
      if (seconds > this.state.maxTime) {
        if (screenfull.isEnabled) {
          this.player.current.seekTo(this.state.maxTime, 'seconds');
        } else {
          window.location.reload(true);
        }
      }
    }

    this.postMediaTrack('seek');
  }

  start() {
    this.setState({ playing: true });
  }

  ended() {
    clePostMediaDetails(this.props.meetingInstance.sourceId,
      Math.floor(this.state.currentTime), Math.floor(this.player.current.getDuration()),
      'ended')
      .then(() => {
      })
      .catch(() => {
      });
  }

  tick() {
    if (Math.floor(this.player.current.getCurrentTime()) % 60 < 5 && this.state.playing) {
      clePostMediaDetails(this.props.meetingInstance.sourceId,
        Math.floor(this.state.currentTime / 60) * 60, Math.floor(this.player.current.getDuration()),
        'minuteTrack')
        .then(() => {
        })
        .catch(() => {
        });
    }
  }

  postMediaTrack(message) {
    clePostMediaDetails(this.props.meetingInstance.sourceId,
      Math.floor(this.state.currentTime), Math.floor(this.player.current.getDuration()),
      message)
      .then(() => {
      })
      .catch(() => {
      });
  }

  render() {
    const {
      showPopUp,
      showMessagePopUp,
      checkpointPopUpTitle,
      resumePopUpTitle,
    } = this.state;

    /* eslint-disable */
    return (
      <Wrapper>
        <WrapperInner>
          {showMessagePopUp && checkpointPopUpTitle && (
            <PopUpModal
              dismissable={false}
              head="CLE VIEWER CHECKPOINT"
              body={(
                <MessageModalBody
                  title={checkpointPopUpTitle}
                  okFunction={this.onClickYes}
                />
              )}
              closeAction={this.onClickYes}
            />
          )}
          {showPopUp && (
            <PopUpModal
              dismissable={false}
              head="RESUME WHERE YOU LEFT OFF?"
              body={(
                <ModalBody
                  title={resumePopUpTitle}
                  yesFunction={this.onResumeYes}
                  noFunction={this.onResumeNo}
                />
              )}
              closeAction={this.onDeleteCancel}
            />
          )}
          <CardWrapperTop>
            <CardBody>
              <YellowLineSVG />
              <HeaderDiv>
                <Title>{this.props.videoTitle !== null ? this.props.videoTitle
                  : 'You are not registered for the CLE!!'}
                </Title>
              </HeaderDiv>
              {this.props.videoTitle !== null && (
              <OuterPlayer
                className="id"
              >
                <ReactPlayer
                  id="videoplayer"
                  ref={this.player}
                  url={
                    `https://player.vimeo.com/video/${this.props.videoUrl}?h=75209c19da&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;playsinline=0`
                  }
                  progressInterval={1000}
                  controls
                  playing={this.state.playing}
                  onProgress={this.progress}
                  onPause={() => this.pause()}
                  onPlay={() => this.play()}
                  onReady={this.onReady}
                  onSeek={seconds => this.seek(seconds)}
                  onEnded={this.ended}
                  onStart={this.start}
                  width="100%"
                  height={this.calculateHeight() + "px"}
                  onBuffer={() => this.postMediaTrack('bufferstart:')}
                  onBufferEnd={() => this.postMediaTrack('bufferend:')}
                  onError={(message) => this.postMediaTrack('error: ' + message)}
                  onPlaybackRateChange={(playbackRate) => this.postMediaTrack('playbackratechange: ' + playbackRate)}
                />
              </OuterPlayer>
              )}
            </CardBody>
          </CardWrapperTop>
          <CardWrapper>
            <CardBody>
              {this.props.videoTitle !== null && (
                <ProgressTracker
                  currentStep={this.crossedCheckpoints()}
                  steps={this.noOfCheckpoints()}
                  convertedChecks={this.convertChecks()}
                />
              )}
            </CardBody>
          </CardWrapper>
          <CardWrapper>
            <CardBody>
              {this.props.videoTitle !== null && (
                <CourseMaterialsTab
                  courseMaterials={this.props.courseMaterials}
                  meetingInstance={this.props.meetingInstance}
                />
              )}
            </CardBody>
          </CardWrapper>
        </WrapperInner>
      </Wrapper>
    );
    /* eslint-enable */
  }
}

CLEOnDemand.propTypes = {
  videoTitle: PropTypes.string,
  videoUrl: PropTypes.string,
  checkpoint1: PropTypes.string,
  checkpoint2: PropTypes.string,
  checkpoint3: PropTypes.string,
  checkpoint4: PropTypes.string,
  check1Crossed: PropTypes.bool,
  check2Crossed: PropTypes.bool,
  check3Crossed: PropTypes.bool,
  check4Crossed: PropTypes.bool,
  question: PropTypes.string,
  // duration: PropTypes.number,
  courseMaterials: PropTypes.arrayOf(Object),
  meetingInstance: PropTypes.arrayOf(Object).isRequired,
  lastDuration: PropTypes.number,
  resumeTime: PropTypes.string,
  checkpointNo: PropTypes.number,
};

CLEOnDemand.defaultProps = {
  videoTitle: '',
  videoUrl: '',
  checkpoint1: '',
  checkpoint2: '',
  checkpoint3: '',
  checkpoint4: '',
  check1Crossed: false,
  check2Crossed: false,
  check3Crossed: false,
  check4Crossed: false,
  question: '',
  // duration: 0,
  courseMaterials: [],
  lastDuration: 0,
  resumeTime: '',
  checkpointNo: 0,
};

CLEOnDemand.displayName = 'CLEOnDemand';

export default CLEOnDemand;
