import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const MyPracticeAreasToggle = styled.div`
  display: none;

  @media(min-width: ${breakpoints.m}) {
    display: block;
  }
`;

MyPracticeAreasToggle.displayName = 'MyPracticeAreasToggle';

export default MyPracticeAreasToggle;
