import styled from 'styled-components';

import { colors } from '@style-guide/config';

const InstagramLink = styled.a`
  svg {
    color: ${colors.darkRed};
  }
`;

InstagramLink.displayName = 'InstagramLink';

export default InstagramLink;
