import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography, colors, breakpoints } from '@style-guide/config';
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons/faCalendarPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LinkButton from '@style-guide/components/LinkButton';
import TextArea from '@style-guide/components/TextArea';

import FormHeader from '@components/FormHeader';
import SocialShare from '@components/SocialShare';


const ConfirmationWrapper = styled.div`
  margin: 50px;
  width: 70%;
  margin-inline: auto;
`;

ConfirmationWrapper.displayName = 'ConfirmationWrapper';

const ConfirmationContentWrapper = styled.div`
  margin: 0 15px;
`;

ConfirmationContentWrapper.displayName = 'ConfirmationContentWrapper';

const TitleWrapper = styled.div`
  padding: 10px 0px;
  text-decoration: underline;
  font-weight: bold;
  color: #a41f35;
`;

TitleWrapper.displayName = 'TitleWrapper';

const TotalWrapper = styled.div`
  margin: 10px 0px;
  padding: 15px;
  border-top: 1px solid rgb(183, 183, 183);
  border-bottom: 1px solid ${colors.darkGray};
  font-weight: bold;
`;

TotalWrapper.displayName = 'TotalWrapper';

const EventTitle = styled.div`
  margin: 0px;
  color: red;
`;

EventTitle.displayName = 'EventTitle';

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 25px 0;
  width: 100%;

  @media (max-width: ${breakpoints.m}) {
    display: inline;
  }
`;

Navigation.displayName = 'Navigation';

const BlankWrapper = styled.div`
  width: 50%;

  @media (min-width: ${breakpoints.l}) {
    width: 60%;
  }
`;

BlankWrapper.displayName = 'BlankWrapper';

const DetailList = styled.ul`
  li {
    padding: 3px 10px;

    > span:first-child {
      font-weight: 600;
    }
  }

  span {
    font-size: ${rem('14px', typography.baseFontSize)};
  }
`;

DetailList.displayName = 'DetailList';

const BtnContainer = styled.span`
  a {
    font-size: 14px;
    padding: 8px 24px;
    margin: 10px;
  }
`;

BtnContainer.displayName = 'BtnContainer';

class Confirmation extends React.Component {
  render() {
    const {
      confirmationTitle,
      confirmationButton,
      displayName,
      zoomMeetingUrl,
      date,
      endTime,
      startTime,
      location,
      addOns,
      addOnsActive,
      eventStoreLink,
    } = this.props;

    // eslint-disable-next-line
    const filteredAddOns = addOns.filter(function (item) {
      return addOnsActive.indexOf(item.id) !== -1;
    });

    return (
      <React.Fragment>
        <ConfirmationWrapper>
          <FormHeader>{confirmationTitle}</FormHeader>
          <ConfirmationContentWrapper>
            <TitleWrapper>
              <TextArea
                style={{ fontWeight: 'bold', color: colors.darkRed, textDecoration: 'underline' }}
                htmlString={displayName}
              />
            </TitleWrapper>
            <DetailList>
              <li>
                <span style={{ fontWeight: 'bold' }}>Date:&nbsp;</span>
                <span>{date}</span>
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>Time:&nbsp;</span>
                <span>{startTime} - {endTime}</span>
              </li>
              <li>
                {location != null && (
                <React.Fragment>
                  <span style={{ fontWeight: 'bold' }}>Location:&nbsp;</span>
                  <span>{location.displayName}, {location.line1} </span>
                  <span>{location.line2} {location.line3} {location.line4},&nbsp;</span>
                  <span>{location.city}, {location.state} {location.postalCode}</span>
                </React.Fragment>
                )}
                {location === null && (
                <React.Fragment>
                  <span style={{ fontWeight: 'bold' }}>Location:&nbsp;</span>
                  <span>{zoomMeetingUrl}</span>
                </React.Fragment>
                )}
              </li>
              <li>
                <BtnContainer>
                  <LinkButton
                    href={`/MyEventsPage/AddToCalendar?startDate=${date}&startTime=${startTime}&endDate=${date}&endTime=${endTime}&title=${displayName}&location=${location != null ? location.displayName : zoomMeetingUrl}`}
                    target="_parent"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faCalendarPlus}
                      color={colors.white}
                    /> &nbsp;
                    ADD TO CALENDAR
                  </LinkButton>
                </BtnContainer>
              </li>
            </DetailList>
            <SocialShare />
          </ConfirmationContentWrapper>

          {filteredAddOns.map(addOn => (
            <React.Fragment>
              <br />
              <br />
              <ConfirmationContentWrapper>
                <TitleWrapper>
                  <TextArea
                    style={{ fontWeight: 'bold', color: colors.darkRed, textDecoration: 'underline' }}
                    htmlString={addOn.title}
                  />
                </TitleWrapper>
                <DetailList>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>Date:&nbsp;</span>
                    <span>{addOn.date}</span>
                  </li>
                  <li>
                    <span style={{ fontWeight: 'bold' }}>Time:&nbsp;</span>
                    <span>{addOn.startTime} - {addOn.endTime}</span>
                  </li>
                  <li>
                    {addOn.location != null && (
                    <React.Fragment>
                      <span style={{ fontWeight: 'bold' }}>Location:&nbsp;</span>
                      <span>{addOn.location}</span>
                      <span>{addOn.city}</span>
                    </React.Fragment>
                    )}
                  </li>
                  <li>
                    <BtnContainer>
                      <LinkButton
                        href={`/MyEventsPage/AddToCalendar?startDate=${addOn.date}&startTime=${addOn.startTime}&endDate=${addOn.date}&endTime=${addOn.endTime}&title=${addOn.title}&location=${addOn.location != null ? addOn.location : ''}`}
                        target="_parent"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faCalendarPlus}
                          color={colors.white}
                        /> &nbsp;
                        ADD TO CALENDAR
                      </LinkButton>
                    </BtnContainer>
                  </li>
                </DetailList>
                <SocialShare
                  shareUrl={addOn.link}
                  articleTitle={addOn.title}
                />
              </ConfirmationContentWrapper>
            </React.Fragment>
          ))}
          <br />
          <TotalWrapper>
            <span>Total:&nbsp;</span>
            <span style={{ float: 'right' }}>$0.00</span>
          </TotalWrapper>
          <Navigation>
            <BlankWrapper />
            <LinkButton
              style={{ margin: '5px' }}
              href={eventStoreLink}
              variant="primary"
            >
              CONTINUE BROWSING
            </LinkButton>
            <LinkButton
              style={{ margin: '5px' }}
              href={confirmationButton.href}
              variant="primary"
            >
              {confirmationButton.text}
            </LinkButton>
          </Navigation>
        </ConfirmationWrapper>
      </React.Fragment>
    );
  }
}

Confirmation.propTypes = {
  confirmationTitle: PropTypes.string,
  confirmationButton: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
  }),
  displayName: PropTypes.string,
  zoomMeetingUrl: PropTypes.string,
  date: PropTypes.string,
  endTime: PropTypes.string,
  startTime: PropTypes.string,
  location: PropTypes.shape({
    displayName: PropTypes.string,
    line1: PropTypes.string,
    line2: PropTypes.string,
    line3: PropTypes.string,
    line4: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  addOns: PropTypes.arrayOf(Object),
  addOnsActive: PropTypes.arrayOf(PropTypes.string),
  eventStoreLink: PropTypes.string,
};

Confirmation.defaultProps = {
  confirmationTitle: 'Registration Confirmation',
  confirmationButton: {
    href: '/my-dashboard/',
    text: 'My Dashboard',
  },
  displayName: '',
  zoomMeetingUrl: '',
  date: '',
  endTime: '',
  startTime: '',
  location: {
    displayName: '',
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    city: '',
    state: '',
    postalCode: '',
  },
  addOns: [],
  addOnsActive: [],
  eventStoreLink: '',
};

Confirmation.displayName = 'Confirmation';

export default Confirmation;
