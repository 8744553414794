import styled from 'styled-components';

import {
  breakpoints,
  colors,
} from '@style-guide/config';

const Wrapper = styled.div`
  position: relative;

  @media(min-width: ${breakpoints.xl}) {
    padding: 0px 30px 15px;
    background-color: ${colors.lightGray};
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
