import React from 'react';
import PropTypes from 'prop-types';

import ResourceUtilitiesContainer from './components/ResourceUtilitiesContainer';
import ResourceBookmark from './components/ResourceBookmark';
import SocialShare from './components/SocialShare';
import { ebarContentTypesValues } from '@config';

const ResourceUtilities = ({
  isMember,
  contentGuidString,
  isBookmarked,
}) => (
  <ResourceUtilitiesContainer>
    {isMember && (
      <ResourceBookmark
        contentGuidString={contentGuidString}
        isBookmarked={isBookmarked}
        type={ebarContentTypesValues.eBarCase}
      />
    )}
    <SocialShare />
  </ResourceUtilitiesContainer>
);

ResourceUtilities.displayName = 'ResourceUtilities';

ResourceUtilities.propTypes = {
  contentGuidString: PropTypes.string.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  isMember: PropTypes.bool.isRequired,
};

export default ResourceUtilities;
