import styled from 'styled-components';

const EmptyCartMessage = styled.p`
  margin: 20px;
  text-align: center;
`;

EmptyCartMessage.displayName = 'EmptyCartMessage';

export default EmptyCartMessage;
