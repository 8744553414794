import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import styled from 'styled-components';
import {
  breakpoints,
  colors,
} from '@style-guide/config';

import Button from '@style-guide/components/Button';

import CLEContentCard from './CLEContentCard';

const Course = styled.li`
  margin: 0 0 15px;
  padding: 0 0 10px;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.lightGray};
  }

  h4 {
    margin: 0 0 5px;
  }
`;

Course.displayName = 'Course';

const CourseDescription = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media(min-width: ${breakpoints.s}) {
    flex-direction: row;
  }

  p {
    margin: 0;
  }

  > *:first-child {
    margin-right: 15px;
  }
`;

CourseDescription.displayName = 'CourseDescription';

const StyledButton = styled(Button)`
  margin-top: 15px;

  @media(min-width: ${breakpoints.s}) {
    margin-top: 0;
  }
`;

StyledButton.displayName = 'StyledButton';

// destructure similar courses in the args
const SimilarCourses = ({
  similarCourses = [],
}) => {
  const COURSE_FORMAT_MAP = {
    0: 'Live',
    1: 'Self-Study',
  };

  const SimilarCourseListItems = similarCourses.map((course) => {
    const courseFormat = COURSE_FORMAT_MAP[course.courseFormat];
    const creditsList = course.credits.map((credit, index) => (
      <span
        key={credit.typeName}
      >
        {`${credit.hours.toFixed(2)} ${credit.typeName.trim()}${index + 1 < course.credits.length ? ', ' : ''}`}
      </span>
    ));

    return (
      <Course
        key={course.id}
      >
        <h4>{course.title}</h4>
        <CourseDescription>
          <div>
            {course.date && (
              <p><strong>Date: </strong>{course.date}</p>
            )}
            {courseFormat === 'Live' && (
              <p><strong>Time: </strong>{`${course.startTime} - ${course.endTime}`}</p>
            )}
            {(course.location || course.city) && (
              <p><strong>Location: </strong>{course.location}{(course.location && course.city) && ' - '}{course.city && `${course.city}`}</p>
            )}
            {is.not.empty(course.credits) && (
              <p><strong>Credits: </strong>{creditsList}</p>
            )}
            {courseFormat && (
              <p><strong>Course Format: </strong>{courseFormat}</p>
            )}
          </div>
          <div>
            <StyledButton
              onClick={() => window.location.assign(course.link)}
              id={course.id}
            >
              Learn More
            </StyledButton>
          </div>
        </CourseDescription>
      </Course>
    );
  });

  return (
    <CLEContentCard>
      <h2>Upcoming Courses</h2>
      <ul>
        {SimilarCourseListItems}
      </ul>
    </CLEContentCard>
  );
};

SimilarCourses.propTypes = {
  similarCourses: PropTypes.arrayOf(Object),
};

SimilarCourses.displayName = 'SimilarCourses';

export default SimilarCourses;
