import styled from 'styled-components';

import { colors } from '@style-guide/config';

const LoadMoreWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.gray};
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 15px;
`;

LoadMoreWrapper.displayName = 'LoadMoreWrapper';

export default LoadMoreWrapper;
