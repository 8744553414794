import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';

import { colors, typography, breakpoints } from '@style-guide/config';

const NavigationWrapper = styled.div`
  display: none;
  background-color: ${colors.lightGray};
  margin: 20px 15px 0px 15px;

  @media (min-width: ${breakpoints.m}) {
    display: block;
  }
`;

NavigationWrapper.displayName = 'NavigationWrapper';

const NavigationList = styled.ul`
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  li:last-child {
    border-right: none;
  }
`;

NavigationList.displayName = 'NavigationList';

const NavigationListItem = styled.li`
  padding: 0 8px;
  border-right: 1px solid ${colors.darkGray};
  font-size: ${rem('15px', typography.baseFontSize)};
  text-align: center;
  flex: 1 1 auto;
  position: relative;
  margin: 15px 0px;

  a {
    text-decoration: none;
    color: ${props => (props.selectedIndex ? colors.darkBlue : colors.red)};
  }

  a:hover {
    color: ${colors.darkBlue};
    cursor: pointer;
  }

  svg {
    position: absolute;
    bottom: -23px;
    left: 44%;
    right: 50%;
    color: white;
    font-size: 23px;
  }
`;

NavigationListItem.displayName = 'NavigationListItem';

class DirectoryProfileNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedNav: -1 };
    this.setSelectedNav = this.setSelectedNav.bind(this);
  }

  setSelectedNav(index) {
    this.setState({
      selectedNav: index,
    });
  }

  render() {
    return (
      <NavigationWrapper>
        <NavigationList>
          {this.props.navigationItems.map((item, index) => {
            const navIndex = index + 1;
            return (
              <NavigationListItem selectedIndex={this.state.selectedNav === index} key={navIndex}>
                <a href={`#${item}`} onClick={() => this.setSelectedNav(index)}>{item}</a>
                {this.state.selectedNav === index && (
                  <FontAwesomeIcon icon={faCaretUp} />
                )}
              </NavigationListItem>
            );
          })}
        </NavigationList>
      </NavigationWrapper>
    );
  }
}

DirectoryProfileNavigation.propTypes = {
  navigationItems: PropTypes.arrayOf(PropTypes.string),
};

DirectoryProfileNavigation.defaultProps = {
  navigationItems: [],
};

DirectoryProfileNavigation.displayName = 'DirectoryProfileNavigation';

export default DirectoryProfileNavigation;
