import styled from 'styled-components';

const DescriptionInner = styled.div`
  margin-top: 10px;
  line-height: 1.3rem;
`;


DescriptionInner.displayName = 'DescriptionInner';

export default DescriptionInner;
