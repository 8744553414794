import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 36px;
  margin-bottom: 4px;
`;

StyledIcon.displayName = 'StyledIcon';

export default StyledIcon;
