import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TextArea from '@style-guide/components/TextArea';

import { saveDemographicInfo } from '@redux/modules/demographicInfo';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import DemographicInfoForm from './components/DemographicInfoForm';

class DemographicInfoContent extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formValues) {
    this.props.dispatch(saveDemographicInfo(formValues));
  }

  render() {
    const {
      demographicInfo,
      genders,
      languages,
      loading,
      sexualOrientations,
      races,
      wysiwygContent,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          title="Demographic Information"
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              {wysiwygContent && (
                <TextArea
                  htmlString={wysiwygContent}
                />
              )}
              <DemographicInfoForm
                genders={genders}
                hasSaveError={!!error}
                initialValues={demographicInfo}
                languages={languages}
                loading={loading}
                onSubmit={this.submit}
                races={races}
                sexualOrientations={sexualOrientations}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
        />
      </FormAccordionContainer>
    );
  }
}

DemographicInfoContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  demographicInfo: PropTypes.shape({
    BirthDate: PropTypes.string,
    Gender: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    RaceValue: PropTypes.string,
    IsHispanic: PropTypes.bool,
    Languages: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  races: PropTypes.arrayOf(PropTypes.object),
  /*
    The CMS is handing us an object back. This isn't ideal, but we were having MAJOR
    problems with Episerver trying to change the type of this property
  */
  // eslint-disable-next-line react/forbid-prop-types
  sexualOrientations: PropTypes.object,
  genders: PropTypes.arrayOf(PropTypes.object),
  languages: PropTypes.arrayOf(PropTypes.string),
  dispatch: PropTypes.func.isRequired,
  wysiwygContent: PropTypes.string,
};

DemographicInfoContent.defaultProps = {
  error: '',
  loading: false,
  races: [],
  sexualOrientations: {},
  genders: [],
  languages: [],
  wysiwygContent: '',
};

const mapStateToProps = ({ demographicInfo }) => ({
  error: demographicInfo.error,
  loading: demographicInfo.loading,
  demographicInfo,
});

DemographicInfoContent.displayName = 'DemographicInfoContent';

export default connect(mapStateToProps)(DemographicInfoContent);
