import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import EBarTopicsDiv from './components/EBarTopicsDiv';

const TopicDiv = styled.div`
  padding: 4px;
`;

TopicDiv.displayName = 'TopicDiv';

const TopicAnchor = styled.a`
  color: #000;
  text-decoration: none;
`;

TopicAnchor.displayName = 'TopicAnchor';

const CountSpan = styled.span`
  color: #C3002F;
`;

CountSpan.displayName = 'CountSpan';

const EBarTopics = ({
  totalOpinions,
  totalLegislativeEnactments,
  totalSCOAnnounements,
  totalBoardOfProfessionals,
  totalAttorneyGenerals,
  totalEthics,
  totalOtherAnnouncements,
  totalJobs,
}) => (
  <EBarTopicsDiv>
    <center>
      {totalOpinions > 0 && (
        <TopicDiv>
          <TopicAnchor href="#opinions">Opinions
            <CountSpan> ({totalOpinions})</CountSpan>
          </TopicAnchor>
        </TopicDiv>
      )}
      {totalLegislativeEnactments > 0 && (
        <TopicDiv>
          <TopicAnchor href="#enactments">Legislative Enactments
            <CountSpan> ({totalLegislativeEnactments})</CountSpan>
          </TopicAnchor>
        </TopicDiv>
      )}
      {totalSCOAnnounements > 0 && (
        <TopicDiv>
          <TopicAnchor href="#scoannouncements">Supreme Court of Ohio Announcements
            <CountSpan> ({totalSCOAnnounements})</CountSpan>
          </TopicAnchor>
        </TopicDiv>
      )}
      {totalBoardOfProfessionals > 0 && (
        <TopicDiv>
          <TopicAnchor href="#professconadopi">Board of Professional Conduct Advisory Opinions
            <CountSpan> ({totalBoardOfProfessionals})</CountSpan>
          </TopicAnchor>
        </TopicDiv>
      )}
      {totalAttorneyGenerals > 0 && (
        <TopicDiv>
          <TopicAnchor href="#attgenopi">Attorney General Opinions
            <CountSpan> ({totalAttorneyGenerals})</CountSpan>
          </TopicAnchor>
        </TopicDiv>
      )}
      {totalEthics > 0 && (
        <TopicDiv>
          <TopicAnchor href="#ethicscommopi">Ohio Ethics Commission Advisory Opinions
            <CountSpan> ({totalEthics})</CountSpan>
          </TopicAnchor>
        </TopicDiv>
      )}
      {totalOtherAnnouncements > 0 && (
        <TopicDiv>
          <TopicAnchor href="#otherannounce">Other Announcements
            <CountSpan> ({totalOtherAnnouncements})</CountSpan>
          </TopicAnchor>
        </TopicDiv>
      )}
      {totalJobs > 0 && (
        <TopicDiv>
          <TopicAnchor href="#jobs">Career Opportunities
            <CountSpan> ({totalJobs})</CountSpan>
          </TopicAnchor>
        </TopicDiv>
      )}
    </center>
  </EBarTopicsDiv>
);

EBarTopics.propTypes = {
  totalOpinions: PropTypes.number.isRequired,
  totalLegislativeEnactments: PropTypes.number.isRequired,
  totalSCOAnnounements: PropTypes.number.isRequired,
  totalBoardOfProfessionals: PropTypes.number.isRequired,
  totalAttorneyGenerals: PropTypes.number.isRequired,
  totalEthics: PropTypes.number.isRequired,
  totalOtherAnnouncements: PropTypes.number.isRequired,
  totalJobs: PropTypes.number.isRequired,
};

EBarTopics.defaultProps = {
};

EBarTopics.displayName = 'EBarTopics';

export default EBarTopics;
