import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const ListColumn = styled.div`
  width: 100%;

  @media(min-width: ${breakpoints.m}) {
    width: 33%;
  }
`;

ListColumn.displayName = 'ListColumn';

export default ListColumn;
