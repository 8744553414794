import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import { colors, typography } from '@style-guide/config';
import Caret from '@style-guide/mixins/Caret';
import InputReset from '@style-guide/mixins/InputReset';

const caretSettings = {
  position: 'after',
  color: colors.red,
  size: '4px',
  rotating: false,
  initialRotation: '45deg',
};

const SelectWrapper = styled.div`

  ${/* sc-property */Caret(caretSettings)}

  flex-grow: 2;
  position: relative;

  span {
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &::after {
    pointer-events: none;
    position: absolute;
    right: 25px;
    top: 12px;
  }
`;

SelectWrapper.displayName = 'SelectWrapper';

const StyledSelect = styled.select`
  ${InputReset()}

  background-color: ${colors.white};
  border: 1px solid ${props => (props.$invalid ? colors.red : colors.gray)};
  box-shadow: ${props => (props.$invalid ? `0 0 0 1px ${colors.red}` : 'none')};
  border-radius: 0;
  font-size: ${rem('16px', typography.baseFontSize)};
  height: ${rem('40px', typography.baseFontSize)};
  padding: 10px 40px 10px 15px;
  margin-right: 10px;

  &:disabled {
    background-color: ${colors.gray};
    border: 1px solid ${colors.gray};
    box-shadow: none;
    cursor: default;
  }

  @media (min-width: 377px) {    
    width: 130px;
  }

  @media (max-width: 376px) {    
    width: 100%;
  }

  option:disabled {
    color: ${colors.darkGray};
  }
`;

StyledSelect.displayName = 'StyledSelect';

/**
# Button
A basic button.
 */

const Select = ({
  id = '',
  label = '',
  onChange = () => undefined,
  options = [],
  disabled = false,
  invalid = false,
  pointerBottomPosition = '',
  ...restProps
}) => {
  const selectOptions = options.map(option => (
    <option
      key={option.label}
      disabled={option.disabled}
      selected={option.selected}
      value={option.value}
    >
      {option.label}
    </option>
  ));

  return (
    <SelectWrapper $pointerBottomPosition={pointerBottomPosition}>
      {label && <span>{label}</span>}
      <StyledSelect
        id={id}
        $invalid={invalid}
        onChange={onChange}
        disabled={disabled}
        {...restProps}
      >
        {selectOptions}
      </StyledSelect>
    </SelectWrapper>
  );
};


Select.propTypes = {
  /** id for the select dropdown */
  id: PropTypes.string,
  /** The label for the Input */
  label: PropTypes.string,
  /** A function to be called when the option is changed */
  onChange: PropTypes.func,
  /** An array of options to populate the select dropdown */
  options: PropTypes.arrayOf(PropTypes.object),
  /** Whether the Input is disabled */
  disabled: PropTypes.bool,
  /** Whether the Input is invalid */
  invalid: PropTypes.bool,
  pointerBottomPosition: PropTypes.string,
};

Select.displayName = 'Select';

export default Select;
