import React from 'react';
import { Provider } from 'react-redux';
import { getSelectedPracticeAreas } from '@redux/modules/practiceAreas';
import Store from '@redux';

import PracticeAreasContent from './components/PracticeAreasContent';

class PracticeAreas extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(getSelectedPracticeAreas());
  }

  render() {
    return (
      <Provider store={this.store}>
        <PracticeAreasContent
          {...this.props}
        />
      </Provider>
    );
  }
}

PracticeAreas.displayName = 'PracticeAreas';

export default PracticeAreas;
