import styled from 'styled-components';

const DetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  strong {
    font-weight: bold;
  }

  span {
    margin-left: 4px;
  }
`;

DetailsItem.displayName = 'DetailsItem';

export default DetailsItem;
