import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 25px;

  a {
    margin-right: 15px;
  }
`;

ButtonContainer.displayName = 'ButtonContainer';

export default ButtonContainer;
