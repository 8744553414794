import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '@style-guide/config';
import DecisisSVG from '@style-guide/svg/DecisisSVG';
import PracticeLibrarySVG from '@style-guide/svg/PracticeLibrarySVG';
import JobBoardSVG from '@style-guide/svg/JobBoardSVG';
import DisabledLink from './components/DisabledLink';
import QuickLinksContainer from './components/QuickLinksContainer';
import BookmarkIcon from './components/BookmarkIcon';

const IconContainer = styled.div`
  font-size: 35px;
`;

IconContainer.displayName = 'IconContainer';


const links = {
  caseMaker: {
    label: 'Decisis',
    isMemberLink: true,
    icon: DecisisSVG,
    target: '_blank',
  },
  practiceLibrary: {
    label: 'Practice Library',
    isMemberLink: true,
    icon: PracticeLibrarySVG,
    target: '_blank',
  },
  jobBoard: {
    label: 'Career Center',
    isMemberLink: true,
    icon: JobBoardSVG,
    target: '_blank',
  },
  myBookmarks: {
    label: 'My Bookmarks',
    isMemberLink: true,
    icon: BookmarkIcon,
    target: '_blank',
  },
};

const renderLinkGroup = (linkGroup, isMember, index) => {
  const linksWithIcons = linkGroup.map((link) => {
    const isActive = isMember || (!isMember && !link.isMemberLink);
    const Icon = link.icon;

    return (
      <li
        key={link.href}
      >
        {isActive && (
          <a
            href={link.href}
            id={`quickLink${link.id}`}
            target={link.target}
          >
            <Icon
              height="35px"
              color={colors.darkRed}
            />
            <span>{link.label}</span>
          </a>
        )}
        {!isActive && (
          <DisabledLink
            href={link.href}
            title={`${link.label} - disabled`}
            id={`quickLink${link.id}`}
            disabled
          >
            <Icon
              height="35px"
              color={colors.darkRed}
            />
            <span>{link.label}</span>
          </DisabledLink>
        )}
      </li>
    );
  });

  return (
    <ul
      key={index}
    >
      {linksWithIcons}
    </ul>
  );
};


const QuickLinks = ({
  isMember = false,
  quickLinks = [],
}) => {
  const mappedLinks = quickLinks.map(link => ({ ...link, ...links[link.id] }));
  const linkGroups = [mappedLinks];

  // split links into groupings of max length 3
  // so that we can render 3 links per <ul>
  while (linkGroups[linkGroups.length - 1].length > 2) {
    linkGroups.push(linkGroups[linkGroups.length - 1].splice(2));
  }

  return (
    <QuickLinksContainer>
      <h3>Quick Links:</h3>
      <div>
        {linkGroups.map((linkGroup, index) => renderLinkGroup(linkGroup, isMember, index))}
      </div>
    </QuickLinksContainer>
  );
};

QuickLinks.propTypes = {
  isMember: PropTypes.bool,
  quickLinks: PropTypes.arrayOf(Object),
};

QuickLinks.displayName = 'QuickLinks';

export default QuickLinks;
