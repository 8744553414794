import React from 'react';
import PropTypes from 'prop-types';

import CLEContentCard from '@components/CLEContentCard';
import SpeakerCard from '@components/SpeakerCard';

import SpeakersContainer from '../SpeakersContainer';
import GridDividerHorizontal from './components/GridDividerHorizontal';
import SpeakerGridItem from './components/SpeakerGridItem';

const Speakers = ({
  speakers = [],
}) => {
  const speakerCards = speakers.map((speaker, index, collection) => {
    let listPos = index + 1;
    let divider = [];

    if (listPos !== collection.length) {
      if (listPos % 3 === 0) {
        divider = [...divider, <GridDividerHorizontal key={`divider-xl-${listPos}`} visibleOn="xl" />];
      }

      if (listPos % 2 === 0) {
        divider = [...divider, <GridDividerHorizontal key={`divider-l-${listPos}`} visibleOn="l" />];
      }
    }

    const cardsToRender = () => (
      <React.Fragment key={`fragment-${speaker.sourceId}`}>
        <SpeakerGridItem
          $noBorderOnL={(listPos - 1) % 2 === 0}
          $noBorderOnXl={(listPos - 1) % 3 === 0}
        >
          <SpeakerCard
            key={speaker.sourceId}
            speaker={speaker}
          />
        </SpeakerGridItem>
        {divider && divider}
      </React.Fragment>
    );

    listPos += 1;

    return cardsToRender();
  });

  return (
    <CLEContentCard>
      <h2>Speakers</h2>
      <SpeakersContainer>
        {speakerCards}
      </SpeakersContainer>
    </CLEContentCard>
  );
};

Speakers.displayName = 'Speakers';

Speakers.propTypes = {
  speakers: PropTypes.arrayOf(Object),
};

export default Speakers;
