const clePostQuizDetails = async (sourceId, checkpointNo) => {
  const clePostQuizDetailsCreate = await fetch(`/cleOnDemand/PostQuizDetails?instanceId=${sourceId}&checkpointNo=${checkpointNo}`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  });
  const clePostQuizDetailsJson = clePostQuizDetailsCreate.json();

  return clePostQuizDetailsJson;
};

const clePostMediaDetails = async (sourceId, elapsed, timeDuration, message) => {
  const postMediaDetails =
    await fetch(`/cleOnDemand/PostMediaDetails?productId=${sourceId}&timeElapsed=${elapsed}&duration=${timeDuration}&message=${message}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    });
  const clePostMediaDetailsJson = postMediaDetails.json();

  return clePostMediaDetailsJson;
};

const cleLastMediaDetails = async (data) => {
  const cleLastMediaDetail = await fetch(`/cleOnDemand/GetMediaDetails?sourceId=${data}`, {
    method: 'GET',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const cleLastMediaDetailJson = cleLastMediaDetail.json();

  return cleLastMediaDetailJson;
};

export {
  clePostQuizDetails,
  clePostMediaDetails,
  cleLastMediaDetails,
};
