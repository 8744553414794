import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const StyledTable = styled.table`
  width: 100%;

  span {
    font-weight: bold;
  }

  tr {
    display: flex;
    text-align: left;
    flex: 1 0 45%;
  }
`;

StyledTable.displayName = 'StyledTable';

const ColumnHeaders = styled.tr`
  display: flex;
  text-align: left;

  th {
    display: flex;
    flex: 1 0 45%;
  }
`;

ColumnHeaders.displayName = 'ColumnHeaders';

const Column = styled.td`
  margin: 0 0 10px 0px;

  @media (min-width: ${breakpoints.m}) {
    display: flex;
    text-align: left;
    flex: 1 0 45%;
  }
`;

Column.displayName = 'Column';

const HideOnMobileColumn = styled(Column)`
  visibility: hidden;

  @media (min-width: ${breakpoints.m}) {
    visibility: visible;
  }
`;

HideOnMobileColumn.displayName = 'HideOnMobileColumn';

const HideOnMobileColumnHeader = styled.th`
  visibility: hidden;

  @media (min-width: ${breakpoints.m}) {
    visibility: visible;
  }
`;

HideOnMobileColumnHeader.displayName = 'HideOnMobileColumnHeader';

const MobileExperienceDates = styled.p`
  visibility: visible;

  span {
    font-weight: bold;
  }

  @media (min-width: ${breakpoints.m}) {
    visibility: hidden;
  }
`;

MobileExperienceDates.displayName = 'MobileDateReceived';

const ShowOnMobile = styled.p`
  visibility: visible;

  @media (min-width: ${breakpoints.m}) {
    visibility: hidden;
  }
`;

ShowOnMobile.displayName = 'ShowOnMobile';

export {
  StyledTable,
  ColumnHeaders,
  Column,
  HideOnMobileColumn,
  HideOnMobileColumnHeader,
  ShowOnMobile,
};
