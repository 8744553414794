import styled from 'styled-components';

const ProfileContainer = styled.div`
  min-height: 70px;
  margin: 0 auto;
`;

ProfileContainer.displayName = 'ProfileContainer';

export default ProfileContainer;
