import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  @media (min-width: ${breakpoints.m}) {
    background-image: url(${props => props.$imgSrc});
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 255px;
    padding: 0 100px 50px;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
