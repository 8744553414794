import styled from 'styled-components';

const HeaderDiv = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  button {
    height: 50%;
    margin-top: 15px;
  }
`;

HeaderDiv.displayName = 'HeaderDiv';

export default HeaderDiv;
