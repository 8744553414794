import styled from 'styled-components';

import { colors } from '@style-guide/config';

const CollapseToggle = styled.button`
  align-items: center;
  background-color: ${colors.darkBlue};
  border: 0;
  color: ${colors.white};
  display: flex;
  flex-direction: row;
  font-size: 1.1rem;
  font-weight: 500;
  justify-content: space-between;
  letter-spacing: 1px;
  padding: 15px 30px;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
`;

CollapseToggle.displayName = 'CollapseToggle';

export default CollapseToggle;
