import styled from 'styled-components';

import { colors } from '@style-guide/config';

const Header = styled.div`
  border-bottom: ${colors.gray} solid 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
`;

Header.displayName = 'Header';

export default Header;
