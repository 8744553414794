import React from 'react';
import styled from 'styled-components';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon,
} from 'react-share';

const ShareItem = styled.li`
  cursor: pointer;
`;

ShareItem.displayName = 'ShareItem';

const ShareLabel = styled.span`
  font-weight: 500;
  margin-right: 10px;
`;

ShareLabel.displayName = 'ShareLabel';

const SocialShareContainer = styled.ul`
  align-items: center;
  display: flex;
  justify-content: flex-start;

  > *:not(:last-child) {
    margin-right: 5px;
  }
`;

SocialShareContainer.displayName = 'SocialShareContainer';

const SocialShare = () => {
  const shareUrl = window.location.href || document.location.href;
  const articleTitle = document.title;

  return (
    <SocialShareContainer>
      <li>
        <ShareLabel>Share</ShareLabel>
      </li>
      <ShareItem>
        <EmailShareButton
          subject={articleTitle}
          url={shareUrl}
        >
          <EmailIcon
            size={32}
            round
          />
        </EmailShareButton>
      </ShareItem>
      <ShareItem>
        <FacebookShareButton
          url={shareUrl}
        >
          <FacebookIcon
            size={32}
            round
          />
        </FacebookShareButton>
      </ShareItem>
      <ShareItem>
        <TwitterShareButton
          title={articleTitle}
          url={shareUrl}
        >
          <TwitterIcon
            size={32}
            round
          />
        </TwitterShareButton>
      </ShareItem>
      <ShareItem>
        <LinkedinShareButton
          title={articleTitle}
          url={shareUrl}
        >
          <LinkedinIcon
            size={32}
            round
          />
        </LinkedinShareButton>
      </ShareItem>
    </SocialShareContainer>
  );
};

SocialShare.displayName = 'SocialShare';

export default SocialShare;
