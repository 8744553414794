import styled from 'styled-components';

const InnerDiv = styled.div`
  display: flex;
 
  div {
    margin-right: 10px;
  }

  a {
    margin-right: 10px;
    margin-top: 25px;
  }

  button {
    margin-top: 25px;
  }
`;

InnerDiv.displayName = 'InnerDiv';

export default InnerDiv;
