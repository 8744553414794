import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LinkButton from '@style-guide/components/LinkButton';

import SectionListItem from './components/SectionListItem';

const SectionListContainer = styled.ul`
  margin-top: 15px;
`;

SectionListContainer.displayName = 'SectionListContainer';

const SectionList = ({
  sections = {},
  checkoutLink,
}) => (
  <React.Fragment>
    <SectionListContainer>
      {sections.map((section, index) => (
        <SectionListItem
          key={section.id}
          section={section}
          index={index}
        />
      ))}
    </SectionListContainer>
    <LinkButton href={checkoutLink}>
      Go to Checkout
    </LinkButton>
  </React.Fragment>
);

SectionList.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    contentGuid: PropTypes.string,
    price: PropTypes.string,
    subscriptionType: PropTypes.string,
    selected: PropTypes.bool,
  })),
  checkoutLink: PropTypes.string.isRequired,
};

SectionList.displayName = 'SectionList';

export default SectionList;
