import React from 'react';
import PropTypes from 'prop-types';

import YellowLineSVG from '@style-guide/svg/YellowLineSVG';

import AuthorPicture from './components/AuthorPicture';
import Category from './components/Category';
import InfoLineItem from './components/InfoLineItem';
import PublicationName from './components/PublicationName';
import PublishedDate from './components/PublishedDate';
import ResourceHeadingContainer from './components/ResourceHeadingContainer';
import ResourceHeadingContent from './components/ResourceHeadingContent';
import ResourceInfo from './components/ResourceInfo';
import Title from './components/Title';

const EBarResourceHeading = ({
  authorImageSrc,
  authorImageAltText,
  category,
  title,
  publicationName,
  publishedDate,
  isSpecialFeature,
}) => (
  <ResourceHeadingContainer>
    <ResourceHeadingContent>
      <YellowLineSVG />
      <Category>{category}</Category>
      <Title>{title}</Title>
      {!isSpecialFeature && (
        <ResourceInfo>
          {authorImageSrc && (
            <AuthorPicture src={authorImageSrc} alt={authorImageAltText} />
          )}
          <span>
            <InfoLineItem
              hasTopMargin
            >
              {publicationName && (
                <span>
                  <PublicationName>{publicationName}</PublicationName>,&nbsp;
                </span>
              )}
              <PublishedDate>{publishedDate}</PublishedDate>
            </InfoLineItem>
          </span>
        </ResourceInfo>
      )}
    </ResourceHeadingContent>
  </ResourceHeadingContainer>
);

EBarResourceHeading.propTypes = {
  authorImageSrc: PropTypes.string,
  authorImageAltText: PropTypes.string,
  category: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  publicationName: PropTypes.string,
  publishedDate: PropTypes.string.isRequired,
  isSpecialFeature: PropTypes.bool.isRequired,
};

EBarResourceHeading.defaultProps = {
  authorImageSrc: '',
  authorImageAltText: '',
  publicationName: '',
};

EBarResourceHeading.displayName = 'EBarResourceHeading';

export default EBarResourceHeading;
