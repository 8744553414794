import styled from 'styled-components';

const SearchDiv = styled.div`
  margin-bottom: 15px;
  display: flex;
`;

SearchDiv.displayName = 'SearchDiv';

export default SearchDiv;
