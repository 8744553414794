import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';
import { colors } from '@style-guide/config';
import GetNavAlerts from '@services/NavAlerts';
import LeftNavContainer from './components/LeftNavContainer';
import LeftNavContent from './components/LeftNavContent';
import LeftNavNode from './components/LeftNavNode';
import LeftNavRoot from './components/LeftNavRoot';
import LeftNavToggle from './components/LeftNavToggle';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.lightGray};
  }
`;

class LeftNav extends React.Component {
  constructor(props) {
    super(props);

    this.updateNavAlerts = this.updateNavAlerts.bind(this);
    this.parsedNavData = JSON.parse(props.navData);

    this.state = {
      IsOpen: false,
      NavigationData: this.parsedNavData.Children,
    };
  }

  componentDidMount() {
    if (this.props.hasAlerts) {
      // make the call from the service to get the alerts
      GetNavAlerts('myAccountOverview')
        .then((res) => {
          if (res) {
            this.updateNavAlerts(res);
          }
        });
    }
  }

  updateNavAlerts(navAlerts) {
    const navData = this.state.NavigationData;

    const updatedNavData = navData.map((item) => {
      const updatedItem = item;

      // start off assuming each nav item doesn't need an alert
      updatedItem.ShowAlert = false;
      // if any item has a validation key that's not null AND that value in the nav alerts
      // has alerts, it should show an alert
      if (updatedItem.ValidationKey && navAlerts[updatedItem.ValidationKey].length) {
        updatedItem.ShowAlert = true;
      }

      return updatedItem;
    });

    this.setState({ NavigationData: updatedNavData });
  }

  toggleOpenState() {
    this.setState({
      IsOpen: !this.state.IsOpen,
    });
  }

  render() {
    return (
      <React.Fragment>
        <GlobalStyle />
        <LeftNavContainer>
          <LeftNavToggle
            className={this.state.IsOpen ? 'open' : ''}
            onClick={() => this.toggleOpenState()}
            aria-expanded={this.state.IsOpen}
          >
            <span>Navigation</span>
          </LeftNavToggle>
          <LeftNavContent
            className={this.state.IsOpen ? 'open' : ''}
          >
            <ul>
              <li>
                <LeftNavRoot
                  className={this.parsedNavData.IsActive ? 'active' : ''}
                  href={this.parsedNavData.Link}
                >
                  <span>{this.parsedNavData.Name}</span>
                </LeftNavRoot>
              </li>
              {this.state.NavigationData.map(node => (
                <LeftNavNode
                  key={node.Id}
                  Id={node.Id}
                  Name={node.Name}
                  Link={node.Link}
                  IsActive={node.IsActive}
                  Valid={node.Valid}
                  Children={node.Children}
                  ShowAlert={node.ShowAlert ? node.ShowAlert : false}
                />
              ))}
            </ul>
          </LeftNavContent>
        </LeftNavContainer>
      </React.Fragment>
    );
  }
}

LeftNav.propTypes = {
  navData: PropTypes.string.isRequired,
  hasAlerts: PropTypes.bool,
};

LeftNav.defaultProps = {
  hasAlerts: false,
};

LeftNav.displayName = 'LeftNav';

export default LeftNav;
