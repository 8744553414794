import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import {
  reduxForm,
  Field,
} from 'redux-form';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import Button from '@style-guide/components/Button';

import handleSubmit from './services/handleSubmit';

import CheckMarkIcon from './components/CheckMarkIcon';
import PassedMessage from './components/PassedMessage';
import PassedMessageWrapper from './components/PassedMessageWrapper';
import Question from './components/Question';

class QuizBody extends React.Component {
  render() {
    const { personExamDetails } = this.props;
    const { ExamPassed } = personExamDetails;

    const examQuestions = is.not.empty(personExamDetails.ExamQuestions)
      ?
      personExamDetails.ExamQuestions.map((question, index) => {
        if (question) {
          return (
            <Field
              key={question.Id}
              name={`vk_${question.Id.toString()}`}
              component={field => (
                <Question
                  {...field}
                />
              )}
              questionData={question}
              index={index}
            />
          );
        }

        return undefined;
      })
      :
      undefined;

    if (ExamPassed) {
      return (
        <PassedMessageWrapper>
          <CheckMarkIcon
            icon={faCheckCircle}
          />
          <PassedMessage>You have passed this quiz, congratulations!</PassedMessage>
        </PassedMessageWrapper>
      );
    }

    return (
      <form
        onSubmit={this.props.handleSubmit}
      >
        {examQuestions}
        <Button
          type="submit"
        >
          Submit
        </Button>
      </form>
    );
  }
}

QuizBody.propTypes = {
  personExamDetails: PropTypes.shape({
    ExamPassed: PropTypes.bool,
    ExamQuestions: PropTypes.arrayOf(PropTypes.object),
    ExamTaken: PropTypes.bool,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'cleQuiz',
  onSubmit: handleSubmit,
  onSubmitFail() {
    return undefined;
  },
})(QuizBody);
