import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';

import { breakpoints } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import { required } from '@services/FormValidators';

import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormInput from '@components/ReduxFormInput';

const SchoolContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: ${breakpoints.s}) {
    > div:not(:last-of-type) {
      flex-direction: row;
      margin-right: 15px;
      width: 70%;
    }
  }

  @media (min-width: ${breakpoints.m}) {
    flex-direction: column;
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
  }
`;

SchoolContainer.displayName = 'SchoolContainer';

const RemoveSchoolContainer = styled.div`
  button {
    margin: 15px 0 30px;
  }

  @media (min-width: ${breakpoints.xl}) {
    button {
      margin: 45px 0 30px 15px;
    }
  }
`;

RemoveSchoolContainer.displayName = 'RemoveSchoolContainer';

const School = ({
  school,
  index,
  onRemove,
}) => (
  <SchoolContainer>
    <FormFieldWrapper wide>
      <Field
        id={`schoolName${index}`}
        label="School*"
        name={`${school}.Name`}
        component={ReduxFormInput}
        validate={required}
      />
    </FormFieldWrapper>
    <RemoveSchoolContainer>
      <Button
        type="button"
        variant="ghost"
        onClick={onRemove}
      >
        REMOVE
      </Button>
    </RemoveSchoolContainer>
  </SchoolContainer>
);

School.propTypes = {
  school: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};

School.displayName = 'School';

export default School;
