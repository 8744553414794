import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';
import Flyout from '@structures/Flyout';
import MainNav from './components/MainNav';
import UtilityNav from './components/UtilityNav';

const DesktopNavContainer = styled.section`
  display: none;

  @media (min-width: ${breakpoints.l}) {
    display: block;
  }
`;

DesktopNavContainer.displayName = 'DesktopNavContainer';

const DesktopNav = ({
  checkoutLink,
  headerData,
  headerLogo,
  isAuthenticated = false,
  loginLink,
  logoutLink,
  primaryNavItems = [],
  shoppingCartLink,
  userName = '',
  utilityNavItems = [],
}) => (
  <DesktopNavContainer>
    <UtilityNav
      checkoutLink={checkoutLink}
      navItems={utilityNavItems}
      isAuthenticated={isAuthenticated}
      userName={userName}
      loginLink={loginLink}
      logoutLink={logoutLink}
      shoppingCartLink={shoppingCartLink}
    />
    <MainNav
      navItems={primaryNavItems}
      headerLogo={headerLogo}
    />
    {!isAuthenticated && (
      <Flyout
        headingOpen={headerData.flyout.headingOpen}
        headingClosed={headerData.flyout.headingClosed}
        actions={headerData.flyout.homeTypeLinks}
        description={headerData.flyout.description}
        undoSelection={headerData.flyout.undoSelection}
      />
    )}
  </DesktopNavContainer>
);

DesktopNav.propTypes = {
  checkoutLink: PropTypes.string.isRequired,
  headerData: PropTypes.shape({
    flyout: PropTypes.instanceOf(Object),
    functionalLinks: PropTypes.instanceOf(Array),
    legalLinks: PropTypes.instanceOf(Array),
    primaryNavigationItems: PropTypes.instanceOf(Array),
    utilityNavigationItems: PropTypes.instanceOf(Array),
  }).isRequired,
  headerLogo: PropTypes.string.isRequired,
  utilityNavItems: PropTypes.arrayOf(Object),
  loginLink: PropTypes.string.isRequired,
  logoutLink: PropTypes.string.isRequired,
  primaryNavItems: PropTypes.arrayOf(Object),
  isAuthenticated: PropTypes.bool,
  shoppingCartLink: PropTypes.string.isRequired,
  userName: PropTypes.string,
};

DesktopNav.displayName = 'DesktopNav';

export default DesktopNav;
