import styled from 'styled-components';

import { colors } from '@style-guide/config';

const VCardButton = styled.a`
  border-left: 1px solid ${colors.darkGray};
  color: ${colors.red};
  cursor: pointer;
  flex: 0 1 auto;
  font-weight: normal;
  height: 25px;
  padding: 5px 15px 0 0;

  span {
    text-decoration: underline;
  }

  @media (max-width: 430px) {
    border: none;
    margin: 0px 15px;
  }
`;

VCardButton.displayName = 'VCardButton';

export default VCardButton;
