import React from 'react';
import PropTypes from 'prop-types';

import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';

import SearchCTA from '@style-guide/structures/SearchCTA';
import CardCTA from '@style-guide/structures/CardCTA';

import MemberDirectorySearchBlock from '@structures/MemberDirectorySearchBlock';
import LegislativeReportCTA from '@components/LegislativeReportCTA';
import FindALawyerSearchBlock from '@components/FindALawyerSearchBlock';

import Wrapper from './components/Wrapper';
import ChildWrapper from './components/ChildWrapper';

const CTASection = ({ ctaChildren }) => {
  // the children array is actually passed as a string to the React element by the HTML helper
  const childrenParsed = JSON.parse(ctaChildren);

  const childrenTransformed = childrenParsed.map((child, index) => {
    const childProps = mapKeys(child.Block, (value, key) => camelCase(key));

    /* eslint-disable */
    // react/display-name
    // this is not a "component" in the sense that it needs a display name
    const componentMapper = () => {
      const { Type } = child;

      if (Type === 'SearchBlock') {
        return SearchCTA;
      } else if (Type === 'CallToActionCardBlock') {
        return CardCTA;
      } else if (Type === 'LegislativeReportBlock') {
        return LegislativeReportCTA;
      } else if (Type === 'FindALawyerSearchBlock') {
        return FindALawyerSearchBlock;
      } else if (Type === 'MemberDirectorySearchBlock')  {
        return MemberDirectorySearchBlock;
      }

      return <div />;
    };

    const Component = componentMapper();

    return ({
      id: index,
      Component,
      props: childProps,
    });
  });

  return (
    <Wrapper>
      {childrenTransformed.map((child) => {
        const {
          Component,
          props,
          id,
        } = child;

        return (
          <ChildWrapper
            key={id}
            className={id > 1 ? 'marginTop' : ''}
          >
            <Component {...props} />
          </ChildWrapper>
        );
      })}
    </Wrapper>
  );
};

CTASection.propTypes = {
  ctaChildren: PropTypes.string.isRequired,
};

CTASection.displayName = 'CTASection';

export default CTASection;
