const eBarSendDailyReportMail = async () => {
  const eBarSendDailyReport = await fetch('/EBarManageEmailsCallToActionBlock/SendDailyReportMail', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  });
  const eBarSendDailyReportJson = eBarSendDailyReport.json();

  return eBarSendDailyReportJson;
};

const eBarSendWeeklyReportMail = async () => {
  const eBarSendWeeklyReport = await fetch('/EBarManageEmailsCallToActionBlock/SendWeeklyReportMail', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  });
  const eBarSendWeeklyReportJson = eBarSendWeeklyReport.json();

  return eBarSendWeeklyReportJson;
};

const eBarSendTestEmails = async (dailyWeekly, emailId) => {
  const eBarSendTestEmail = await fetch(`/EBarManageEmailsCallToActionBlock/SendTestEmailsAsync?dailyWeekly=${dailyWeekly}&emailId=${emailId}`, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  });
  const eBarSendTestEmailsJson = eBarSendTestEmail.json();

  return eBarSendTestEmailsJson;
};

export {
  eBarSendDailyReportMail,
  eBarSendWeeklyReportMail,
  eBarSendTestEmails,
};
