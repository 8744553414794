import styled from 'styled-components';

import rem from 'polished/lib/helpers/rem';
import { colors, typography } from '@style-guide/config';

const Title = styled.h2`
  color: ${colors.darkRed};
  font-size: ${rem('22px', typography.baseFontSize)};
  font-weight: 700;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

Title.displayName = 'Title';

export default Title;
