import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography, breakpoints, colors } from '@style-guide/config';
import { Caret } from '@style-guide/mixins';
import SpecializationActions from '@components/SpecializationActions';
import ActionBarBadge from './components/ActionBarBadge';

const caretOptions = {
  position: 'after',
  color: colors.darkGray,
  size: '6px',
  rotating: false,
};

const ActionBarContainer = styled.div`
  background-color: ${colors.darkBlue};
  padding-top: 90px;
  position: relative;

  @media (min-width: ${breakpoints.l}) {
    padding-top: 0;
  }
`;

ActionBarContainer.displayName = 'ActionBarContainer';

const StyledSpecializationActions = styled(SpecializationActions)`
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  font-size: ${rem('16px', typography.baseFontSize)};
  font-weight: 600;
  justify-content: center;
  padding: 90px 25px 50px;

  > li {
    border-top: 1px solid ${colors.gray};
    display: flex;
    position: relative;

    &:last-child {
      border-bottom: 1px solid ${colors.gray};
    }

    > a {
      ${Caret(caretOptions)}

      align-items: center;
      color: ${colors.black};
      display: flex;
      flex-grow: 1;
      height: 80px;

      &:hover {
        color: ${colors.darkRed};

        > svg {
          fill: ${colors.darkBlue};
        }
      }

      > svg {
        fill: ${colors.darkRed};
        margin-right: 20px;
        width: 50px;
      }

      > span {
        flex-grow: 1;
      }
    }
  }

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
    font-size: ${rem('18px', typography.baseFontSize)};
    padding: 90px 25px;

    > li {
      border-top: none;
      width: 33%;

      &:last-child {
        border-bottom: none;
      }

      > a {
        flex-direction: column;
        height: ${rem('120px', typography.baseFontSize)};
        justify-content: center;
        padding: 0 1rem;
        text-align: center;

        &::after {
          display: none;
        }

        > svg {
          margin: 0 0 20px;
          width: 60px;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid ${colors.gray};
      }
    }
  }
`;

StyledSpecializationActions.displayName = 'StyledSpecializationActions';

class SpecializationActionBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      badgeText: props.badgeText,
      actions: props.actions,
    };
  }

  render() {
    return (
      <ActionBarContainer>
        <ActionBarBadge
          badgeText={this.state.badgeText}
        />
        <StyledSpecializationActions
          actions={this.state.actions}
          location="homepageCta"
        />
      </ActionBarContainer>
    );
  }
}

SpecializationActionBar.propTypes = {
  badgeText: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.object),
};

SpecializationActionBar.defaultProps = {
  badgeText: '',
  actions: [],
};

SpecializationActionBar.displayName = 'SpecializationActionBar';

export default SpecializationActionBar;
