import styled from 'styled-components';

const DisabledLink = styled.a`
  cursor: default;
  opacity: 0.5;
  pointer-events: none;
`;

DisabledLink.displayName = 'DisabledLink';

export default DisabledLink;
