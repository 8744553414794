import styled from 'styled-components';
import { breakpoints, stickyHeader } from '@style-guide/config';

const HeaderContainer = styled.div`
  height: ${stickyHeader.stickyHeaderHeight};
  margin: 0 auto;
  max-width: ${breakpoints.xxl};
  position: relative;

  @media (min-width: ${breakpoints.l}) {
    height: 0;
    padding: 0 55px;
  }
`;

HeaderContainer.displayName = 'HeaderContainer';

export default HeaderContainer;
