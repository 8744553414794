import React from 'react';
import PropTypes from 'prop-types';

import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';

import Advertisement from '@style-guide/components/Advertisement';
import GoogleAd from '@style-guide/components/GoogleAd';
import CardCTA from '@style-guide/structures/CardCTA';
import MarketingCTA from '@style-guide/structures/MarketingCTA';

const BLOCK_MAP = {
  CallToActionBlock: CardCTA,
  ImageBlock: Advertisement,
  GoogleAdBlock: GoogleAd,
};

const DEFAULT_PROP_MAP = {
  CallToActionBlock: {
    hasBackground: false,
    color: 'white',
  },
};

const MarketingCTASection = ({
  contentItems = '[]',
  orientation = 'horizontal',
}) => {
  const marketingCTASectionBlocks = JSON.parse(contentItems).map((blockData) => {
    const Block = BLOCK_MAP[blockData.Type];
    const props = mapKeys(blockData.Block, (value, key) => camelCase(key));

    return (
      <Block
        key={blockData.Key}
        {...DEFAULT_PROP_MAP[blockData.Type]}
        {...props}
      />
    );
  });

  return (
    <MarketingCTA
      contentItems={marketingCTASectionBlocks}
      vertical={orientation === 'vertical'}
    />
  );
};

MarketingCTASection.propTypes = {
  contentItems: PropTypes.string,
  orientation: PropTypes.string,
};

MarketingCTASection.displayName = 'MarketingCTASection';

export default MarketingCTASection;
