import styled from 'styled-components';

const CaseWithPracticeArea = styled.div`
  display: block;
  text-align: left;
  padding: 14px 28px 0px;
`;

CaseWithPracticeArea.displayName = 'CaseWithPracticeArea';

export default CaseWithPracticeArea;
