import React from 'react';
import Loadable from 'react-loadable';
import ShellLoading from '@components/ShellLoading';

const PracticeLibraryResultsLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "PracticeLibraryResultsStateContainer" */ './PracticeLibraryResultsStateContainer'),
  loading() {
    return <ShellLoading height="50vh" />;
  },
});

const PracticeLibraryResultsAsync = props => <PracticeLibraryResultsLoadable {...props} />;

export default PracticeLibraryResultsAsync;
