import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  breakpoints,
  typography,
} from '@style-guide/config';

import Button from '@style-guide/components/Button';

const ModalBodyWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

ModalBodyWrapper.displayName = 'ModalBodyWrapper';

const ModalTitle = styled.h1`
  font-size: ${rem('24px', typography.baseFontSize)};
  margin: 30px 0px;
  text-align: center;
`;

ModalTitle.displayName = 'ModalTitle';

const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  button:first-of-type {
    margin: 0px 0px 15px 0px;
  }
  
  @media(min-width: ${breakpoints.s}) {
    flex-direction: row;

    button:first-of-type {
      margin: 0px 15px 0px 0px;
    }
  }
`;

ModalButtonWrapper.displayName = 'ModalButtonWrapper';

const ModalBody = ({
  yesFunction,
  noFunction,
  deleteGuidString,
}) => (
  <ModalBodyWrapper>
    <ModalTitle>
       Do you want to delete this OSBA Member Center information?
    </ModalTitle>
    <ModalButtonWrapper>
      <Button
        onClick={() => yesFunction(deleteGuidString)}
      >
       Yes
      </Button>
      <Button
        onClick={() => noFunction()}
      >
        No
      </Button>
    </ModalButtonWrapper>
  </ModalBodyWrapper>
);

ModalBody.propTypes = {
  yesFunction: PropTypes.func.isRequired,
  noFunction: PropTypes.func.isRequired,
  deleteGuidString: PropTypes.string.isRequired,
};

ModalBody.displayName = 'ModalBody';

export default ModalBody;
