import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const MyAccountOverviewCTAWrapper = styled.div`
  display: block;

  @media (min-width: ${breakpoints.xs}) {
    div {
      padding-left: 0px;
    }
  }

  @media (min-width: ${breakpoints.m}) {
    display: flex;
    flex-wrap: wrap;

    div {
      flex: 1 1 50%;
      max-width: 50%;
    }
  }

  @media (min-width: ${breakpoints.l}) {
    div {
      flex: 1 1 30%;
      max-width: 30%;
      padding-left: 0px;
      padding-right: 40px;
    }
  }
`;

MyAccountOverviewCTAWrapper.displayName = 'MyAccountOverviewCTAWrapper';

export default MyAccountOverviewCTAWrapper;
