import React from 'react';
import Loadable from 'react-loadable';
import ShellLoading from '@components/ShellLoading';

const FindALawyerResultsLoadable = Loadable({
  loader: () => import(/* webpackChunkName: "FindALawyerResultsStateContainer" */ './FindALawyerResultsStateContainer'),
  loading() {
    return <ShellLoading height="50vh" />;
  },
});

const FindALawyerResultsAsync = props => <FindALawyerResultsLoadable {...props} />;

export default FindALawyerResultsAsync;
