import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

import TabButton from './TabButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding-bottom: 20px;

  @media(min-width: ${breakpoints.m}) {
    flex-direction: row;
  }

  @media(min-width: ${breakpoints.l}) {
    left: 0;
    margin-bottom: 0px;
    position: absolute;
    top: -65px;
    padding-bottom: 0;
  }

  ${/* sc-selector */TabButton}:first-of-type {
    margin-right: 25px;
  }
`;

Wrapper.displayName = 'Wrapper';

export default Wrapper;
