import React from 'react';
import PropTypes from 'prop-types';

import ContentBlock from './shared/ContentBlock';

const EducationContentBlock = ({ contentToRender }) => (
  <ContentBlock>
    <h3>SCHOOL NAME</h3>
    {contentToRender.lineItems.length > 0 && contentToRender.lineItems.map(school => (
      <p key={school.name}>{school.name}</p>
    ))}
  </ContentBlock>
);

EducationContentBlock.propTypes = {
  contentToRender: PropTypes.shape({
    lineItems: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      isDeleted: PropTypes.bool,
    })),
  }).isRequired,
};

EducationContentBlock.displayName = 'EducationContentBlock';

export default EducationContentBlock;
