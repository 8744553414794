import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chunk from 'lodash/chunk';

import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';

import { breakpoints, colors } from '@style-guide/config';

import HubCTA from '@components/HubCTA';

const HubCTAContainerWrapper = styled.div`
  align-items: center;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 80px;
  width: 100%;

  @media (min-width: ${breakpoints.m}) {
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;

    > *:first-child {
      margin-top: -135px;
    }
  }

  @media (min-width: ${breakpoints.l}) {
    background: ${colors.lightGray};
  }

  @media (min-width: ${breakpoints.xl}) {
    padding-left: 75px;
    padding-right: 75px;
  }
`;

HubCTAContainerWrapper.displayName = 'HubCTAContainerWrapper';

const HubCTAContainerContent = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;

  @media (min-width: ${breakpoints.l}) {
    flex-wrap: nowrap;
  }
`;

HubCTAContainerContent.displayName = 'HubCTAContainerContent';

const BLOCK_MAP = {
  HubContentBlock: HubCTA,
};

const HubCTAContainer = ({
  content,
}) => {
  const containerBlocks = JSON.parse(content).map((blockData) => {
    const Block = BLOCK_MAP[blockData.Type];
    const props = mapKeys(blockData.Block, (value, key) => camelCase(key));

    return (
      <Block
        key={blockData.Key}
        {...props}
      />
    );
  });

  const contentChunks = chunk(containerBlocks, 3).map(contentChunk => (
    <HubCTAContainerContent
      key={contentChunk[0].key}
    >
      {contentChunk}
    </HubCTAContainerContent>
  ));

  return (
    <HubCTAContainerWrapper>
      {contentChunks}
    </HubCTAContainerWrapper>
  );
};

HubCTAContainer.propTypes = {
  content: PropTypes.string.isRequired,
};

HubCTAContainer.displayName = 'HubCTAContainer';

export default HubCTAContainer;
