import styled from 'styled-components';

import { colors } from '@style-guide/config';

const StyledSelect = styled.select`
  appearance: menulist;
  border: 1px solid ${colors.lightGray};
  padding: 5px 10px;
`;

StyledSelect.displayName = 'StyledSelect';

export default StyledSelect;
