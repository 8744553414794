import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';

import {
  colors,
  typography,
} from '@style-guide/config';

const Header = styled.div`
  background-color: ${colors.darkRed};
  color: ${colors.white};
  padding: 15px 25px;
  line-height: ${rem('24px', typography.baseFontSize)};
`;

Header.displayName = 'Header';

export default Header;
