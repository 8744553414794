import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  colors,
  breakpoints,
} from '@style-guide/config';

import Button from '@style-guide/components/Button';

const SearchWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media(min-width: ${breakpoints.m}) {
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 75%;
  }
`;

SearchWrapper.displayName = 'SearchWrapper';

const SearchInput = styled.input`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  font-size: 14px;
  font-style: italic;
  height: 32px;
  margin-bottom: 15px;
  padding: 10px;
  width: 100%;

  ::placeholder {
    color: ${colors.black};
  }

  @media(min-width: ${breakpoints.m}) {
    margin-bottom: 0px;
    margin-right: 10px;
  }
`;

SearchInput.displayName = 'SearchInput';

class SearchBar extends React.Component {
  constructor() {
    super();

    this.state = {
      searchTerm: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.initializeSearch = this.initializeSearch.bind(this);
  }

  handleInputChange(event) {
    this.setState({ searchTerm: event.target.value });
  }

  handleKeyPress(e) {
    if (e.charCode === 13) {
      this.initializeSearch();
    }

    return undefined;
  }

  initializeSearch() {
    window.location.assign(`${this.props.searchPageUrl}?sort=recentlyPublished&search={"dateRange":{"end":null,"start":null},"keyword":"${this.state.searchTerm}","practiceAreas":[],"otherResources":[],"publications":[],"resourceTypes":[]}`);
  }

  render() {
    return (
      <SearchWrapper>
        <SearchInput
          onChange={this.handleInputChange}
          onKeyPress={this.handleKeyPress}
          placeholder="Keywords"
          value={this.state.searchTerm}
        />
        <Button
          onClick={this.initializeSearch}
        >
          SEARCH
        </Button>
      </SearchWrapper>
    );
  }
}

SearchBar.propTypes = {
  searchPageUrl: PropTypes.string.isRequired,
};

SearchBar.displayName = 'SearchBar';

export default SearchBar;
