import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { typography } from '@style-guide/config';

const Item = styled.button`
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  font-size: ${rem('14px', typography.baseFontSize)};
  font-weight: 500;
  margin: 5px 15px 5px 0;
`;

Item.displayName = 'Item';

export default Item;
