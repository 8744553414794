// the items in this list don't have ids and are view only
// (never sorted or re-ordered) so we can safely use
// array indexes as keys
/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ContentBlock from './shared/ContentBlock';

const CleMeetingWrapper = styled.div`
  margin: 0 0 10px 0px;
`;

CleMeetingWrapper.displayName = 'CleMeetingWrapper';

const ParticipationContentBlock = props => (
  <ContentBlock>
    <h3>MEETINGS SPOKEN AT</h3>
    {props.contentToRender.clesSpokenAt.map((cle, index) => (
      <CleMeetingWrapper key={index}>
        <p><span>{cle.name}</span></p>
        <p>{cle.date}</p>
      </CleMeetingWrapper>
    ))}
  </ContentBlock>
);

ParticipationContentBlock.propTypes = {
  contentToRender: PropTypes.shape({
    clesSpokenAt: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      date: PropTypes.string,
    })),
  }).isRequired,
};

ParticipationContentBlock.displayName = 'ParticipationContentBlock';

export default ParticipationContentBlock;
