import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'redux-form';
import moment from 'moment';

import { breakpoints } from '@style-guide/config';

import Button from '@style-guide/components/Button';

import { required } from '@services/FormValidators';

import FormFieldWrapper from '@components/FormFieldWrapper';
import ReduxFormDatePicker from '@components/ReduxFormDatePicker';
import ReduxFormInput from '@components/ReduxFormInput';

const AwardContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.s}) {
    > div:not(:last-of-type) {
      flex-direction: row;
      margin-right: 15px;
    }
  }

  @media (min-width: ${breakpoints.m}) {
    flex-direction: column;
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;
  }
`;

AwardContainer.displayName = 'AwardContainer';

const RemoveAwardContainer = styled.div`
  button {
    margin: 15px 0 30px;
  }

  @media (min-width: ${breakpoints.xl}) {
    button {
      margin: 45px 0 30px 15px;
    }
  }
`;

RemoveAwardContainer.displayName = 'RemoveAwardContainer';

const Award = ({
  award,
  index,
  onRemove,
}) => (
  <AwardContainer>
    <FormFieldWrapper>
      <Field
        component={ReduxFormInput}
        id={`awardName${index}`}
        label="Name*"
        name={`${award}.Name`}
        validate={required}
      />
      <Field
        component={ReduxFormDatePicker}
        id={`dateReceived${index}`}
        label="Date Received*"
        maxDate={moment().toDate()}
        name={`${award}.DateReceived`}
        scrollableYearDropdown
        showYearDropdown
        validate={required}
        yearDropdownItemNumber={50}
      />
    </FormFieldWrapper>
    <RemoveAwardContainer>
      <Button
        onClick={onRemove}
        type="button"
        variant="ghost"
      >
        REMOVE
      </Button>
    </RemoveAwardContainer>
  </AwardContainer>
);

Award.propTypes = {
  award: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};

Award.displayName = 'Award';

export default Award;
