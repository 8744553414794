import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import transparentize from 'polished/lib/color/transparentize';
import { colors } from '@style-guide/config';
import CloseButton from './CloseButton';

const PopUpOverlay = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
`;

PopUpOverlay.displayName = 'PopUpOverlay';


const SubMenuContainer = styled.section`
  background-color: ${transparentize(0.05, colors.white)};
  padding: 3px;
  right: 0;
  top: 100%;
  z-index: 4;
  max-height: 100vh;
  max-width: 100vw;
  width: 70vw;
`;

SubMenuContainer.displayName = 'SubMenuContainer';

const SubMenuHeading = styled.h2`
  align-items: center;
  background-color: ${colors.darkBlue};
  color: ${colors.white};
  display: flex;
  font-size: 22px;
  height: 50px;
  padding: 0 20px;
`;

SubMenuHeading.displayName = 'SubMenuHeading';

const SubMenu = ({
  heading,
  content,
  id,
  closeAction,
}) => (
  <PopUpOverlay>
    <SubMenuContainer
      id={id}
    >
      <SubMenuHeading>
        <span style={{ width: '90%' }}>
          {heading}
        </span>
        <span style={{ width: '10%' }}>
          <CloseButton
            onClick={closeAction}
          />
        </span>
      </SubMenuHeading>
      {content}
    </SubMenuContainer>
  </PopUpOverlay>
);

SubMenu.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.node,
  id: PropTypes.string,
  closeAction: PropTypes.func,
};

SubMenu.defaultProps = {
  heading: '',
  content: '',
  id: '',
  closeAction: () => {},
};

SubMenu.displayName = 'SubMenu';

export default SubMenu;
