import React from 'react';
import PropTypes from 'prop-types';

import LinkButton from '@style-guide/components/LinkButton';
import AdditionalInstanceContainer from './components/AdditionalInstanceContainer';

const AdditionalInstance = ({
  city,
  date,
  time,
  url,
  id,
}) => (
  <AdditionalInstanceContainer>
    <span>
      {date && (`${date}, `)}
      {time && (`${time}, `)}
      {city}
    </span>
    <LinkButton
      href={url}
      id={id}
    >
      Learn More
    </LinkButton>
  </AdditionalInstanceContainer>
);

AdditionalInstance.displayName = 'AdditionalInstance';

AdditionalInstance.propTypes = {
  city: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.string.isRequired,
};

AdditionalInstance.defaultProps = {
  city: '',
  date: '',
  time: '',
  url: '',
};

export default AdditionalInstance;
