import styled from 'styled-components';
import hideVisually from 'polished/lib/mixins/hideVisually';

const ScreenReaderText = styled.span`
  ${hideVisually()}
`;

ScreenReaderText.displayName = 'ScreenReaderText';

export default ScreenReaderText;
