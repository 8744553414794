import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { Caret } from '@style-guide/mixins';
import { breakpoints, colors, typography } from '@style-guide/config';

const LeftNavToggle = styled.button`
  align-items: center;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.lightGray};
  display: flex;
  font-size: ${rem('20px', typography.baseFontSize)};
  font-weight: 600;
  padding: 20px;
  width: 100%;

  ${Caret({
    position: 'after',
    size: '6px',
  })}

  &::after {
    position: absolute;
    right: 21px;
  }

  &.open::after {
    right: 19px;
    margin-top: -2px;
    margin-left: -2px;
  }

  span {
    flex-grow: 2;
  }

  @media (min-width: ${breakpoints.m}) {
    display: none;
  }
`;

LeftNavToggle.displayName = 'LeftNavToggle';

export default LeftNavToggle;
