import React from 'react';
import PropTypes from 'prop-types';
import { UnmountClosed } from 'react-collapse';
import { presets } from 'react-motion';
import moment from 'moment';

import Button from '@style-guide/components/Button';
import DatePicker from '@style-guide/components/DatePicker';

import Arrow from './shared/Arrow';
import Body from './shared/Body';
import ClearButton from './shared/ClearButton';
import ClearWrapper from './shared/ClearWrapper';
import Header from './shared/Header';
import Title from './shared/Title';
import Wrapper from './shared/Wrapper';

class DateRangeFilter extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpened: true,
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.updateStartDate = this.updateStartDate.bind(this);
    this.updateEndDate = this.updateEndDate.bind(this);
    this.updateClearDate = this.updateClearDate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  updateStartDate(date) {
    this.props.updateStartDate(moment(new Date(date)));
  }

  updateEndDate(date) {
    this.props.updateEndDate(date);
  }

  updateClearDate() {
    this.props.filterUpdate(null, null);
  }

  handleSubmit() {
    if (this.props.start != null && this.props.end != null) {
      this.props.filterUpdate(moment(this.props.start).format('MM/DD/YYYY'), moment(this.props.end).format('MM/DD/YYYY'));
    }
  }

  toggleCollapse() {
    this.setState(prevState => ({
      isOpened: !prevState.isOpened,
    }));
  }

  render() {
    const {
      start,
      end,
      endDateProps,
      maxDate,
      startDateProps,
    } = this.props;

    const { isOpened } = this.state;

    return (
      <Wrapper>
        <Header
          id="dateRangeFilterCollapseToggle"
          $isOpened={isOpened}
          onClick={this.toggleCollapse}
        >
          <Title>Date Range</Title>
          <Arrow
            isOpened={isOpened}
          />
        </Header>
        <UnmountClosed
          isOpened={isOpened}
          presets={presets.gentle}
        >
          <ClearWrapper>
            &#40;
            <ClearButton
              id="dateRangeClearAll"
              onClick={this.updateClearDate}
              onKeyPress={(e) => {
                if (e.charCode === 13) {
                  this.updateClearDate();
                }
              }}
              tabIndex={0}
            >
              Clear All
            </ClearButton>
            &#41;
          </ClearWrapper>
          <Body>
            <DatePicker
              id="filterDateRangeStartInput"
              label="Start Date"
              margin="0 0 25px"
              maxDate={moment(maxDate).toDate()}
              onSelect={this.updateStartDate}
              selected={start ? moment(start).toDate() : undefined}
              // eslint-disable-next-line
              onChangeRaw={(e) => e.preventDefault()}
              variant="secondary"
              {...startDateProps}
            />
            <DatePicker
              id="filterDateRangeEndInput"
              label="End Date"
              maxDate={moment(maxDate).toDate()}
              onSelect={this.updateEndDate}
              selected={end ? moment(end).toDate() : undefined}
              // eslint-disable-next-line
              onChangeRaw={(e) => e.preventDefault()}
              variant="secondary"
              {...endDateProps}
            />
            <Button
              id="filterDateRangeSubmit"
              onClick={this.handleSubmit}
              style={{
                marginTop: '30px',
              }}
              variant="primary"
            >
              Update
            </Button>
          </Body>
        </UnmountClosed>
      </Wrapper>
    );
  }
}

DateRangeFilter.propTypes = {
  filterUpdate: PropTypes.func.isRequired,
  updateStartDate: PropTypes.func.isRequired,
  updateEndDate: PropTypes.func.isRequired,
  start: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  end: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  // these props should just be an arbitrary object of any other props to pass to the start/end
  // datepickers
  /* eslint-disable react/forbid-prop-types */
  startDateProps: PropTypes.object,
  endDateProps: PropTypes.object,
  /* eslint-enable react/forbid-prop-types */
};

DateRangeFilter.defaultProps = {
  end: null,
  endDateProps: undefined,
  maxDate: undefined,
  minDate: undefined,
  start: null,
  startDateProps: undefined,
};

DateRangeFilter.displayName = 'DateRangeFilter';

export default DateRangeFilter;
