import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { hydrateExperienceInfo } from '@redux/modules/marketingPreferencesInfo';
import Store from '@redux';

import MarketingPreferencesInfoContent from './components/MarketingPreferencesInfoContent';

class MarketingPreferencesInfo extends React.Component {
  constructor(props) {
    super(props);

    this.store = Store.get();
  }

  componentDidMount() {
    this.store.dispatch(hydrateExperienceInfo(this.props.marketingPreferences));
  }

  render() {
    return (
      <Provider store={this.store}>
        <MarketingPreferencesInfoContent
          {...this.props}
        />
      </Provider>
    );
  }
}

MarketingPreferencesInfo.propTypes = {
  marketingPreferences: PropTypes.shape({
    CleMarketing: PropTypes.bool,
    PracticeManagementDiscountPrograms: PropTypes.bool,
    TravelDiscountPrograms: PropTypes.bool,
  }).isRequired,
};

MarketingPreferencesInfo.displayName = 'MarketingPreferencesInfo';

export default MarketingPreferencesInfo;
