import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LinkButton from '@style-guide/components/LinkButton';
import AlertMessage from './components/AlertMessage';
import MembershipStatusContainer from './components/MembershipStatusContainer';

const AlertLinkButton = styled(LinkButton)`
  margin-top: 5px;
`;

AlertLinkButton.displayName = 'AlertLinkButton';

const renderContent = (
  isMember,
  isMonthlyMember,
  memberType,
  membershipExpiration,
  becomeAMemberLink,
  upgradeLink,
  autoRenew,
  dateRequired,
) => {
  if (isMonthlyMember && !autoRenew) {
    return (
      <div>
        <div>Membership Type: {memberType}</div>
        <AlertLinkButton
          id="upgradeButton"
          href={upgradeLink}
        >
          Upgrade
        </AlertLinkButton>
      </div>
    );
  }

  if (isMember && autoRenew && !isMonthlyMember) {
    return (
      <div>
        <div>Membership Type: {memberType}</div>
        <div>Automatic Renewal Next Payment: 12/15/{dateRequired.getFullYear()}</div>
      </div>
    );
  }

  if (isMember && autoRenew && isMonthlyMember) {
    return (
      <div>
        <div>Membership Type: {memberType}</div>
        <div>Automatic Renewal Next Payment:&nbsp;
          {new Date(dateRequired.setDate(dateRequired.getDate() + 1)).toLocaleDateString()}
        </div>
      </div>
    );
  }

  if (isMember && !autoRenew) {
    return (
      <div>
        <div>Membership Type: {memberType}</div>
        <div>Exp: {membershipExpiration}</div>
      </div>
    );
  }

  return (
    <div>
      <div>Non-Member</div>
      <AlertLinkButton
        id="joinTodayButton"
        href={becomeAMemberLink}
      >
        Join Today
      </AlertLinkButton>
    </div>
  );
};

const MembershipStatus = ({
  isMember = false,
  isMonthlyMember = false,
  alertMessage = '',
  memberType = '',
  membershipExpiration = '1900/01/01',
  becomeAMemberLink,
  upgradeLink,
  autoRenew,
}) => (
  <MembershipStatusContainer>
    {renderContent(
      isMember,
      isMonthlyMember,
      memberType,
      membershipExpiration,
      becomeAMemberLink,
      upgradeLink,
      autoRenew,
      new Date(membershipExpiration),
    )}
    {alertMessage && (
      <AlertMessage
        dangerouslySetInnerHTML={{
          __html: alertMessage,
        }}
      />
    )}
  </MembershipStatusContainer>
);

MembershipStatus.propTypes = {
  isMember: PropTypes.bool,
  isMonthlyMember: PropTypes.bool,
  alertMessage: PropTypes.string,
  memberType: PropTypes.string,
  membershipExpiration: PropTypes.string,
  becomeAMemberLink: PropTypes.string.isRequired,
  upgradeLink: PropTypes.string.isRequired,
  autoRenew: PropTypes.bool.isRequired,
};

export default MembershipStatus;
