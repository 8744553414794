import React from 'react';
import PropTypes from 'prop-types';
import is from 'is_js';
import { Provider } from 'react-redux';

import { courseFormat as courseFormatPropTypes } from '@services/PropTypes';

import Modal from '@components/Modal';
import store from '@redux';

import QuizModalBody from './components/QuizModalBody';
import ContentArea from './components/ContentArea';
import Wrapper from './components/Wrapper';
import WrapperInner from './components/WrapperInner';
import TabMenu from './components/TabMenu';
import CourseDetailsTab from './components/CourseDetailsTab';
import CourseMaterialsTab from './components/CourseMaterialsTab';
import SystemRequirementsTab from './components/SystemRequirementsTab';
import CourseQuizTab from './components/CourseQuizTab';
import CLEOnDemandTab from './components/CLEOnDemandTab';

class CLEClassroom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: this.props.lastDuration > 0 ? 'cleOnDemand' : 'courseDetails',
      quizData: null,
      modalBodyData: {
        isOpen: false,
        message: null,
        buttonAction: () => undefined,
        buttonLabel: null,
        errors: [],
      },
      submitting: false,
    };

    this.updateSubmittingState = this.updateSubmittingState.bind(this);
    this.updateTabState = this.updateTabState.bind(this);
    this.updateQuizData = this.updateQuizData.bind(this);
    this.updateModalState = this.updateModalState.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.showCLEOnDemand = this.showCLEOnDemand.bind(this);
    this.renderTab = this.renderTab.bind(this);
  }

  async componentDidMount() {
    const {
      sourceId,
      courseFormatString,
    } = this.props.meetingInstance;

    if (courseFormatString === '1') {
      const quizReq = await fetch(`/cleclassroompage/getquizdetails?sourceid=${sourceId}`, {
        credentials: 'same-origin',
      });
      const quizJson = await quizReq.json();

      this.updateQuizData(quizJson);
    }
  }

  updateSubmittingState(state) {
    this.setState({
      submitting: state,
    });
  }

  updateQuizData(data) {
    this.setState({
      quizData: data,
    });
  }

  updateTabState(state) {
    this.setState({
      activeTab: state,
    });
  }

  updateModalState(newModalState) {
    const { modalBodyData } = this.state;

    this.setState({
      modalBodyData: {
        ...modalBodyData,
        ...newModalState,
      },
    });
  }

  closeModal() {
    this.updateModalState({
      isOpen: false,
    });
  }

  showCLEOnDemand() {
    this.setState({ activeTab: 'cleOnDemand' });
  }

  renderTab() {
    const tabToRender = this.state.activeTab;
    const { quizData } = this.state;
    const {
      courseMaterials,
      myClesUrl,
      systemRequirements,
      instructors,
      meetingInstance,
      credits,
      parentMeeting,
      similarCourses,
      practiceAreas,
      personId,
      courseFormat,
      specializationCredits,
      inxpoClassroomLink,
      podcastUrl,
      richCourseDescription,
      videoUrl,
    } = this.props;

    if (tabToRender === 'systemRequirements') {
      if (is.not.empty(systemRequirements)) {
        return (
          <ContentArea>
            <SystemRequirementsTab
              systemRequirementsHtmlString={systemRequirements}
              meetingInstance={meetingInstance}
            />
          </ContentArea>
        );
      }

      return undefined;
    }

    if (tabToRender === 'courseMaterials') {
      if (is.not.empty(courseMaterials) ||
            ((meetingInstance.ctaLabel1 != null && meetingInstance.ctaLabel1 !== '') || (meetingInstance.ctaUrl1 != null && meetingInstance.ctaUrl1 !== ''))
            || ((meetingInstance.ctaLabel2 != null && meetingInstance.ctaLabel2 !== '') || (meetingInstance.ctaUrl2 != null && meetingInstance.ctaUrl2 !== ''))
            || ((meetingInstance.ctaLabel3 != null && meetingInstance.ctaLabel3 !== '') || (meetingInstance.ctaUrl3 != null && meetingInstance.ctaUrl3 !== ''))) {
        return (
          <ContentArea>
            <CourseMaterialsTab
              courseMaterials={courseMaterials}
              meetingInstance={meetingInstance}
            />
          </ContentArea>
        );
      }

      return undefined;
    }

    if (tabToRender === 'courseDetails') {
      return (
        <CourseDetailsTab
          instructors={instructors}
          meetingInstance={meetingInstance}
          parentMeeting={parentMeeting}
          credits={credits}
          similarCourses={similarCourses}
          practiceAreas={practiceAreas}
          courseFormat={courseFormat}
          specializationCredits={specializationCredits}
          inxpoClassroomLink={inxpoClassroomLink}
          podcastUrl={podcastUrl}
          richCourseDescription={richCourseDescription}
          showCLEOnDemand={this.showCLEOnDemand}
          videoUrl={videoUrl}
        />
      );
    }

    if (courseFormat.sourceId === '30' && tabToRender === 'courseQuiz') {
      return (
        <ContentArea>
          <CourseQuizTab
            quizData={quizData}
            meetingInstance={meetingInstance}
            updateQuizData={this.updateQuizData}
            updateModalState={this.updateModalState}
            updateSubmittingState={this.updateSubmittingState}
            isSubmitting={this.state.submitting}
            personId={personId}
            myClesUrl={myClesUrl}
          />
        </ContentArea>
      );
    }

    return undefined;
  }

  render() {
    const { modalBodyData, activeTab } = this.state;
    const {
      systemRequirements,
      courseMaterials,
      courseFormat,
      meetingInstance,
      videoTitle,
      videoUrl,
      checkpoint1,
      checkpoint2,
      checkpoint3,
      checkpoint4,
      check1Crossed,
      check2Crossed,
      check3Crossed,
      check4Crossed,
      question,
      lastDuration,
      resumeTime,
      checkpointNo,
    } = this.props;
    const storeInstance = store.get();
    const systemRequirementsExist = is.not.empty(systemRequirements);
    const courseMaterialsExist = is.not.empty(courseMaterials) ||
          ((meetingInstance.ctaLabel1 != null && meetingInstance.ctaLabel1 !== '') || (meetingInstance.ctaUrl1 != null && meetingInstance.ctaUrl1 !== ''))
          || ((meetingInstance.ctaLabel2 != null && meetingInstance.ctaLabel2 !== '') || (meetingInstance.ctaUrl2 != null && meetingInstance.ctaUrl2 !== ''))
          || ((meetingInstance.ctaLabel3 != null && meetingInstance.ctaLabel3 !== '') || (meetingInstance.ctaUrl3 != null && meetingInstance.ctaUrl3 !== ''));

    return (
      <Provider
        store={storeInstance}
      >
        <React.Fragment>
          <Wrapper>
            <WrapperInner>
              <TabMenu
                systemRequirementsExist={systemRequirementsExist}
                courseMaterialsExist={courseMaterialsExist}
                cleOnDemandExist={this.props.videoUrl !== null && this.props.videoUrl !== ''}
                courseFormat={courseFormat}
                activeTab={this.state.activeTab}
                updateTabState={this.updateTabState}
              />
              {this.renderTab()}
              <ContentArea
                style={{ display: activeTab === 'cleOnDemand' ? 'flex' : 'none' }}
              >
                <CLEOnDemandTab
                  videoTitle={videoTitle}
                  videoUrl={videoUrl}
                  checkpoint1={checkpoint1}
                  checkpoint2={checkpoint2}
                  checkpoint3={checkpoint3}
                  checkpoint4={checkpoint4}
                  check1Crossed={check1Crossed}
                  check2Crossed={check2Crossed}
                  check3Crossed={check3Crossed}
                  check4Crossed={check4Crossed}
                  question={question}
                  meetingInstance={meetingInstance}
                  courseMaterials={courseMaterials}
                  lastDuration={lastDuration}
                  resumeTime={resumeTime}
                  checkpointNo={checkpointNo}
                  showCLEOnDemand={this.showCLEOnDemand}
                  isTabActive={this.state.activeTab === 'cleOnDemand'}
                />
              </ContentArea>
            </WrapperInner>
          </Wrapper>
          {modalBodyData.isOpen && (
            <Modal
              closeAction={this.closeModal}
              body={(
                <QuizModalBody {...modalBodyData} />
              )}
            />
          )}
        </React.Fragment>
      </Provider>
    );
  }
}

CLEClassroom.propTypes = {
  systemRequirements: PropTypes.string,
  courseMaterials: PropTypes.arrayOf(Object),
  instructors: PropTypes.arrayOf(Object),
  similarCourses: PropTypes.arrayOf(Object),
  // eslint-disable-next-line
  meetingInstance: PropTypes.object.isRequired,
  // eslint-disable-next-line
  parentMeeting: PropTypes.object.isRequired,
  credits: PropTypes.arrayOf(Object).isRequired,
  practiceAreas: PropTypes.arrayOf(Object).isRequired,
  personId: PropTypes.string.isRequired,
  courseFormat: courseFormatPropTypes.isRequired,
  specializationCredits: PropTypes.arrayOf(Object),
  myClesUrl: PropTypes.string.isRequired,
  inxpoClassroomLink: PropTypes.string,
  podcastUrl: PropTypes.string,
  richCourseDescription: PropTypes.string,
  videoTitle: PropTypes.string,
  videoUrl: PropTypes.string,
  checkpoint1: PropTypes.string,
  checkpoint2: PropTypes.string,
  checkpoint3: PropTypes.string,
  checkpoint4: PropTypes.string,
  check1Crossed: PropTypes.bool,
  check2Crossed: PropTypes.bool,
  check3Crossed: PropTypes.bool,
  check4Crossed: PropTypes.bool,
  question: PropTypes.string,
  lastDuration: PropTypes.number,
  resumeTime: PropTypes.string,
  checkpointNo: PropTypes.number,
};

CLEClassroom.defaultProps = {
  systemRequirements: '',
  courseMaterials: [],
  instructors: [],
  similarCourses: [],
  specializationCredits: [],
  inxpoClassroomLink: '',
  podcastUrl: '',
  richCourseDescription: '',
  videoTitle: '',
  videoUrl: '',
  checkpoint1: '',
  checkpoint2: '',
  checkpoint3: '',
  checkpoint4: '',
  check1Crossed: false,
  check2Crossed: false,
  check3Crossed: false,
  check4Crossed: false,
  question: '',
  lastDuration: 0,
  resumeTime: '',
  checkpointNo: 0,
};

CLEClassroom.displayName = 'CLEClassroom';

export default CLEClassroom;
