import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/*
 * Attributes
 * 1) bodyContent - if you want to set ur custom text (mandatory)
 * 2) widthSize - set the size of width
 * 3) paddingSize - set the px of padding
 * 4) leftPosition - set the px of left position
*/

const Tooltiptext = styled.span`
  &.TooltipDialog {
    visibility: visible;
    width: ${props => (props.widthSize ? props.widthSize : '80px')};
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: ${props => (props.paddingSize ? props.paddingSize : '5px 0px')};
    position: absolute;
    z-index: 1;
    bottom: 95%;
    left: ${props => (props.leftPosition ? props.leftPosition : '-49%')};
    margin-left: 0px;
    opacity: 0.9;
    transition: opacity 0.3s;
  }

  &.TooltipDialog::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

`;
Tooltiptext.displayName = 'Tooltiptext';


const PopupTooltip = (props) => {
  const {
    bodyContent,
    widthSize,
    paddingSize,
    leftPosition,
  } = props;

  return (
    <Tooltiptext className="TooltipDialog" widthSize={widthSize} paddingSize={paddingSize} leftPosition={leftPosition}>
      {bodyContent}
    </Tooltiptext>
  );
};

PopupTooltip.propTypes = {
  bodyContent: PropTypes.element,
  widthSize: PropTypes.string,
  paddingSize: PropTypes.string,
  leftPosition: PropTypes.string,
};

PopupTooltip.defaultProps = {
  bodyContent: '',
  widthSize: '',
  paddingSize: '',
  leftPosition: '',
};

export default PopupTooltip;
