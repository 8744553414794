import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const HeaderContainer = styled.div`
  padding: 15px;

  svg {
    margin-bottom: 10px;
  }

  @media (min-width: ${breakpoints.m}) {
    padding: 40px 0 30px;
  }

  @media (min-width: ${breakpoints.l}) {
    padding: 60px 0 30px;
  }
`;

HeaderContainer.displayName = 'HeaderContainer';

export default HeaderContainer;
