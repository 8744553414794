import React from 'react';
import Loadable from 'react-loadable';
import ShellLoading from '@components/ShellLoading';

const CheckoutAsync = Loadable({
  loader: () => import(/* webpackChunkName: "Checkout" */ './components/CheckoutAsync'),
  loading() {
    return <ShellLoading height="50vh" />;
  },
});

const Checkout = props => <CheckoutAsync {...props} />;

export default Checkout;
