import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoints, colors } from '@style-guide/config';

const NavigationLinksContainer = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${breakpoints.l}) {
    flex-direction: row;
  }
`;

NavigationLinksContainer.displayName = 'NavigationLinksContainer';

const NavigationLink = styled.a`
  align-items: center;
  color: ${colors.white};
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0 1rem;
  text-align: center;

  &:hover {
    color: ${colors.gray};
  }

  @media (min-width: ${breakpoints.l}) {
    height: 50px;
  }
`;

NavigationLink.displayName = 'NavigationLink';

const NavigationLinks = ({
  links = [],
  isAuthenticated = false,
  loginLink,
  logoutLink,
}) => {
  const navigationItems = links.map(link => (
    <li
      key={link.id}
    >
      <NavigationLink
        href={link.href}
        title={link.title}
        target={link.target}
        id={`footerSecondaryLink${link.id}`}
      >
        {link.text}
      </NavigationLink>
    </li>
  ));

  return (
    <NavigationLinksContainer>
      {navigationItems}
      <li>
        <NavigationLink
          href={isAuthenticated ? logoutLink : loginLink}
          id={isAuthenticated ? 'footerSecondaryLinkLogout' : 'footerSecondaryLinkLogin'}
        >
          {isAuthenticated ? 'Logout' : 'Login'}
        </NavigationLink>
      </li>
    </NavigationLinksContainer>
  );
};

NavigationLinks.propTypes = {
  links: PropTypes.arrayOf(Object),
  isAuthenticated: PropTypes.bool,
  loginLink: PropTypes.string.isRequired,
  logoutLink: PropTypes.string.isRequired,
};

NavigationLinks.displayName = 'NavigationLinks';

export default NavigationLinks;
