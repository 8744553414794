import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { colors, typography } from '@style-guide/config';

const ShoppingCartNavItem = styled.li`
  background-color: ${props => (props.$isOpen ? colors.white : colors.darkRed)};
  font-size: ${rem('16px', typography.baseFontSize)};
  font-weight: 700;
  position: relative;

  > div {
    height: 100%;
    width: 100%;
  }
`;

ShoppingCartNavItem.displayName = 'ShoppingCartNavItem';

export default ShoppingCartNavItem;
