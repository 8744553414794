import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const FormFieldSubGroup = styled.div`
  display: flex;
  flex-direction: ${props => (props.$hasMobileColumns ? 'column' : 'row')};

  > div {
    width: 100%;

    ${props => (props.$hasMobileColumns ? `
      max-width: 300px;

      &:not(:first-child) {
        margin-top: 15px;
      }
    ` : `
      &:not(:first-child) {
        margin-left: 10px;
      }
    `)}
  }

  ${props => (props.$hasMobileColumns ? `
    @media (min-width: ${breakpoints.s}) {
      flex-direction: row;

      > div:not(:first-child) {
        margin-left: 10px;
        margin-top: 0;
      }
    }
  ` : '')}
`;

FormFieldSubGroup.displayName = 'FormFieldSubGroup';

export default FormFieldSubGroup;
