import styled from 'styled-components';

const LeftCol = styled.div`
  width: 350px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

LeftCol.displayName = 'LeftCol';

export default LeftCol;
