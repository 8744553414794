import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import transparentize from 'polished/lib/color/transparentize';

import Close from '@style-guide/mixins/Close';
import { colors, stickyHeader, typography } from '@style-guide/config';

import NavAccordion from './components/NavAccordion';
import SecondaryLinks from './components/SecondaryLinks';
import Search from './components/Search';
import SpecializationSelector from './components/SpecializationSelector';

const MobileNavMenuToggle = styled.button`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  &.open {
    ${Close({ color: colors.yellow })}

    > span {
      display: none;
    }
  }
`;

MobileNavMenuToggle.displayName = 'MobileNavMenuToggle';

const MobileNavMenuContent = styled.section`
  background-color: ${transparentize(0.2, colors.darkBlue)};
  border-top: 2px solid ${colors.white};
  display: none;
  height: calc(100vh - ${stickyHeader.stickyHeaderHeight});
  overflow: scroll;
  width: 100%;

  &.open {
    display: inline-block;
    position: absolute;
    left: 0;
  }
`;

MobileNavMenuContent.displayName = 'MobileNavMenuContent';

const MenuIconBar = styled.span`
  background: ${colors.white};
  border-radius: 1px;
  display: block;
  height: 2px;
  width: 20px;

  + span {
    margin-top: 6px;
  }
`;

MenuIconBar.displayName = 'MenuIconBar';

const NavMenuContainer = styled.li`
  border-left: 1px solid ${transparentize(0.8, colors.white)};
  float: left;

  > a,
  > button {
    color: ${colors.white};
  }

  > button {
    /* magic number from .psd */
    width: ${rem('43px', typography.baseFontSize)};
  }

  > a,
  > button {
    height: ${stickyHeader.stickyHeaderHeight};
    line-height: ${stickyHeader.stickyHeaderHeight};
    text-align: center;
  }
`;

NavMenuContainer.displayName = 'NavMenuContainer';

class NavMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.menuRef = createRef();
    this.toggleOpenState = this.toggleOpenState.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.menuRef != null &&
        this.menuRef.current != null &&
        !this.menuRef.current.contains(event.target)) {
      this.setState({
        isOpen: false,
      });
    }
  }

  toggleOpenState() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const {
      flyoutData,
      primaryNavigationItems,
      functionalLinks,
      legalLinks,
      isAuthenticated,
      userName,
    } = this.props;

    const specializationAccordionContent = (
      <SpecializationSelector
        actions={flyoutData.homeTypeLinks}
        description={flyoutData.description}
        undoSelection={flyoutData.undoSelection}
      />
    );

    const primaryNavItemsWithIDs = primaryNavigationItems.map((navItem, index) => ({
      id: index,
      ...navItem,
    }));

    const primaryNavAccordions = primaryNavItemsWithIDs.map((navItem) => {
      const linksWithIDs = navItem.links.map((link, index) => ({
        id: index,
        ...link,
      }));

      const accordionContent = linksWithIDs.map(link => (
        <li key={link.id}>
          <a
            title={link.title}
            href={link.href}
            target={link.target}
          >
            {link.text}
          </a>
        </li>
      ));

      return (
        <NavAccordion
          key={navItem.id}
          itemIndex={navItem.id}
          title={navItem.title}
          text={navItem.text}
          type="darkBlue"
          content={accordionContent}
          href={navItem.href}
        />
      );
    });

    const secondaryLinks = [...functionalLinks, ...legalLinks];

    const navMenuClassNames = classNames({
      open: this.state.isOpen,
    });

    return (
      <NavMenuContainer ref={this.menuRef}>
        <MobileNavMenuToggle
          className={navMenuClassNames}
          onClick={this.toggleOpenState}
          aria-label="Menu"
          aria-expanded={this.state.isOpen}
          id="mobileHeaderNavToggle"
        >
          <MenuIconBar />
          <MenuIconBar />
          <MenuIconBar />
        </MobileNavMenuToggle>
        <MobileNavMenuContent
          className={navMenuClassNames}
          id="mobileHeaderNavContent"
        >
          {primaryNavAccordions}
          <Search
            isMobile
          />
          {!isAuthenticated && (
            <NavAccordion
              title={flyoutData.headingOpen}
              text="I'm looking for..."
              type="red"
              content={specializationAccordionContent}
              itemIndex="SpecializationActions"
            />
          )}
          <SecondaryLinks
            links={secondaryLinks}
            isAuthenticated={isAuthenticated}
            userName={userName}
          />
        </MobileNavMenuContent>
      </NavMenuContainer>
    );
  }
}

NavMenu.propTypes = {
  primaryNavigationItems: PropTypes.arrayOf(Object).isRequired,
  functionalLinks: PropTypes.arrayOf(Object).isRequired,
  legalLinks: PropTypes.arrayOf(Object).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  userName: PropTypes.string.isRequired,
  flyoutData: PropTypes.shape({
    headingOpen: PropTypes.string,
    headingClosed: PropTypes.string,
    homeTypeLinks: PropTypes.arrayOf(Object),
    description: PropTypes.string,
    undoSelection: PropTypes.string,
  }).isRequired,
};

NavMenu.displayName = 'NavMenu';

export default NavMenu;
