import styled from 'styled-components';
import { colors } from '@style-guide/config';

const ShoppingCartContentContainer = styled.h1`
  border-bottom: 1px solid ${colors.gray};
  padding-bottom: 20px;
  width: 100%;
`;

ShoppingCartContentContainer.displayName = 'ShoppingCartContentContainer';

export default ShoppingCartContentContainer;
