import styled from 'styled-components';

import { breakpoints, colors } from '@style-guide/config';

const ModalBody = styled.div`
  background-color: ${colors.white};
  max-height: 100vh;
  max-width: 100vw;
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 25px;
  width: 100vw;

  -ms-overflow-style: none;  /* IE 10+ */
  overflow: -moz-scrollbars-none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  @media (min-width: ${breakpoints.s}) {
    max-height: 50vh;
    max-width: ${breakpoints.s};
    min-height: 30vh;
    min-width: ${breakpoints.s};
  }

  @media (min-width: ${breakpoints.l}) {
    max-height: 50vh;
    max-width: 50vw;
    min-height: 30vh;
    min-width: 50vw;
  }
`;

ModalBody.displayName = 'ModalBody';

export default ModalBody;
