import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import styled from 'styled-components';

import YellowLineSVG from '@style-guide/svg/YellowLineSVG';
import LinkButton from '@style-guide/components/LinkButton';
import CardCTA from '@style-guide/structures/CardCTA';

import GetNavAlerts from '@services/NavAlerts';

import CLEPassStudentWrapper from './components/CLEPassStudentWrapper';
import CLEPassWrapper from './components/CLEPassWrapper';
import SectionsWrapper from './components/SectionsWrapper';
import FlexWrapper from './components/FlexWrapper';
import MembershipAlerts from './components/MembershipAlerts';
import MembershipTypeContainer from './components/MembershipTypeContainer';
import AccountOverviewCTAWrapper from './components/AccountOverviewCTAWrapper';
import SVGWrapper from './components/SVGWrapper';

const FreeSectionText = styled.p`
  font-family: Arial,Helvetica,Sans-Serif;
  color: #FF0000;
`;

FreeSectionText.displayName = 'FreeSectionText';

const FreeCLEText = styled.p`
  font-family: Arial,Helvetica,Sans-Serif;
  color: #000;
  font-style: italic;
`;

FreeCLEText.displayName = 'FreeCLEText';

class AccountOverviewCTASection extends React.Component {
  constructor(props) {
    super(props);

    this.updateNavAlerts = this.updateNavAlerts.bind(this);

    const blockData = JSON.parse(props.contentItems);

    this.state = {
      membershipAlert: '',
      cardBlockData: blockData,
    };
  }

  componentDidMount() {
    // make the call from the service to get the alerts
    GetNavAlerts('myAccountOverview')
      .then((res) => {
        if (res) {
          this.updateNavAlerts(res);
        }
      });
  }

  updateNavAlerts(blockAlerts) {
    let updatedMembershipAlert = '';
    // grab current state
    const blockData = this.state.cardBlockData;

    const updatedBlockData = blockData.map((block) => {
      const updatedBlock = block;
      // if any item has a validation key that's not null AND that value in the nav alerts
      // has alerts, it should show an alert
      if (updatedBlock.ValidationKey && blockAlerts[updatedBlock.ValidationKey].length) {
        updatedBlock.NeedsAttention = true;
      }

      return updatedBlock;
    });

    // if there is a Membership error, set the alert to the text that comes back
    if (blockAlerts.MembershipErrors) {
      [updatedMembershipAlert] = blockAlerts.MembershipErrors;
    }

    this.setState({
      membershipAlert: updatedMembershipAlert,
      cardBlockData: updatedBlockData,
    });
  }


  render() {
    const {
      clesSearchUrl,
      membershipType,
      monthlyMember,
      memberCanRenew,
      membershipNotInRenewalText,
      packageBuilderLinkText,
      packageBuilderUrl,
      upgradeUrl,
      upgradeUrlText,
      parentUrlText,
      expirationDate,
      freeCourseItemsCount,
      autoRenew,
      freeSectionsCount,
      sectionUrl,
      parentUrl,
      orderType,
      isSubscriptionDiscount,
      prePurchasedCLEUrl,
      automaticRenewalPolicyUrl,
    } = this.props;

    return (
      <React.Fragment>
        <FlexWrapper>
          <MembershipTypeContainer>
            <h3>Membership Type: <span>{membershipType}</span></h3>
            <SVGWrapper>
              <YellowLineSVG />
            </SVGWrapper>
            {autoRenew && !monthlyMember && (
              <h4>Automatic Renewal Next Payment: 12/15/{moment(expirationDate).format('YYYY')}</h4>
            )}
            {autoRenew && monthlyMember && (
              <h4>Automatic Renewal Next Payment: {moment(expirationDate).add(1, 'days').format('MM/DD/YYYY')}</h4>
            )}
            {!autoRenew && membershipType !== 'Non-Member' && (
              <h4>Exp: {moment(expirationDate).format('MM/DD/YYYY')}</h4>
            )}
            {autoRenew && (
            <span>
              <a href={automaticRenewalPolicyUrl} rel="noopener noreferrer" target="_blank">
                <i><u>Automatic Renewal Policy</u></i>
              </a>
              <br />
            </span>
            )}
            {!autoRenew && (
            <MembershipAlerts>
              {membershipType === 'Non-Member' && (
                <LinkButton
                  href={parentUrl}
                  style={{
                    marginTop: '15px',
                    alignSelf: 'flex-start',
                  }}
                >
                  {parentUrlText}
                </LinkButton>
              )}
              {monthlyMember && membershipType !== 'Non-Member' && (
                <LinkButton
                  href={upgradeUrl}
                  style={{
                    marginTop: '15px',
                    alignSelf: 'flex-start',
                  }}
                >
                  {upgradeUrlText}
                </LinkButton>
              )}
              {this.state.membershipAlert !== '' && membershipType !== 'Non-Member' &&
                /* live dangerously \m/ */
                /* eslint-disable-next-line react/no-danger */
                <span dangerouslySetInnerHTML={{ __html: this.state.membershipAlert }} />
              }
              {((memberCanRenew && this.state.membershipAlert !== '' && this.state.membershipAlert !== undefined && membershipType !== 'Non-Member') || (membershipType === 'Ex-Member')) && orderType !== 'Quotation' && !monthlyMember && (
              <LinkButton
                href={parentUrl}
                style={{
                  marginTop: '15px',
                  alignSelf: 'flex-start',
                }}
              >
                {parentUrlText}
              </LinkButton>
              )}
              {((memberCanRenew && this.state.membershipAlert !== '' && membershipType !== 'Non-Member') || (membershipType === 'Ex-Member')) && orderType === 'Quotation' && !monthlyMember && (
              <LinkButton
                href={packageBuilderUrl}
                style={{
                  marginTop: '15px',
                  alignSelf: 'flex-start',
                }}
              >
                {packageBuilderLinkText}
              </LinkButton>
              )}
              {!memberCanRenew && membershipType !== 'Non-Member' && (
                <span>{membershipNotInRenewalText}</span>
              )}
            </MembershipAlerts>
            )}
          </MembershipTypeContainer>
          {!membershipType.includes('Student') && (
            <CLEPassWrapper>
              <h3>Pre-Purchased CLEs</h3>
              <SVGWrapper style={{ paddingTop: '28px' }}>
                <YellowLineSVG />
              </SVGWrapper>
              {isSubscriptionDiscount && (
                <h4>
                  Unlimited*
                  {prePurchasedCLEUrl && (
                    <span>
                      <h4>
                        <span>
                          <FreeCLEText>
                            *Some exclusions apply. Click&nbsp;
                            <a href={prePurchasedCLEUrl} rel="noopener noreferrer" target="_blank" style={{ color: '#c3002f' }}>
                              here
                            </a>
                            &nbsp;for detail.
                          </FreeCLEText>
                        </span>
                      </h4>
                    </span>
                  )}
                </h4>
              )}
              {!isSubscriptionDiscount && (
                <h4>{freeCourseItemsCount}<span>remaining</span></h4>
              )}
              <LinkButton
                href={clesSearchUrl}
                style={{
                  marginTop: '10px',
                  alignSelf: 'flex-start',
                }}
              >
                Find a CLE
              </LinkButton>
            </CLEPassWrapper>
          )}
          {(!membershipType.includes('Student') && !membershipType.includes('Non-Member')) && (
            <SectionsWrapper>
              <h3>Complimentary Sections</h3>
              <SVGWrapper style={{ paddingTop: '4px' }}>
                <YellowLineSVG />
              </SVGWrapper>
              <h4>{freeSectionsCount}<span>remaining</span></h4>
              <FreeSectionText>
                Select your Section(s) and discount will be applied at checkout
              </FreeSectionText>
              <LinkButton
                href={sectionUrl}
                style={{
                  marginTop: '5px',
                  alignSelf: 'flex-start',
                }}
              >
                Select
              </LinkButton>
            </SectionsWrapper>
          )}
          {membershipType.includes('Student') && (
          <CLEPassStudentWrapper>
            <h3>Pre-Purchased CLEs</h3>
            <SVGWrapper>
              <YellowLineSVG />
            </SVGWrapper>
            {isSubscriptionDiscount && (
              <h4>
                Unlimited*
                {prePurchasedCLEUrl && (
                  <span>
                    <h4>
                      <span>
                        <FreeCLEText>
                          *Some exclusions apply. Click&nbsp;
                          <a href={prePurchasedCLEUrl} rel="noopener noreferrer" target="_blank" style={{ color: '#c3002f' }}>
                            here
                          </a>
                          &nbsp;for detail.
                        </FreeCLEText>
                      </span>
                    </h4>
                  </span>
                )}
              </h4>
            )}
            {!isSubscriptionDiscount && (
              <h4>{freeCourseItemsCount}<span>remaining</span></h4>
            )}
            <LinkButton
              href={clesSearchUrl}
              style={{
                marginTop: '10px',
                alignSelf: 'flex-start',
              }}
            >
              Find a CLE
            </LinkButton>
          </CLEPassStudentWrapper>
          )}
        </FlexWrapper>
        <AccountOverviewCTAWrapper>
          {this.state.cardBlockData.map((blockData) => {
            const cardObjects = mapKeys(blockData, (value, key) => camelCase(key));
            return (
              <CardCTA
                key={cardObjects.id}
                buttonLink={
                    cardObjects.externalUrl !== null ? cardObjects.externalUrl : cardObjects.url}
                {...cardObjects}
              />
            );
          })}
        </AccountOverviewCTAWrapper>
      </React.Fragment>
    );
  }
}

AccountOverviewCTASection.propTypes = {
  clesSearchUrl: PropTypes.string.isRequired,
  contentItems: PropTypes.string,
  membershipType: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  membershipNotInRenewalText: PropTypes.string.isRequired,
  monthlyMember: PropTypes.bool,
  upgradeUrl: PropTypes.string,
  upgradeUrlText: PropTypes.string,
  parentUrlText: PropTypes.string,
  memberCanRenew: PropTypes.bool.isRequired,
  packageBuilderLinkText: PropTypes.string.isRequired,
  packageBuilderUrl: PropTypes.string.isRequired,
  freeCourseItemsCount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  autoRenew: PropTypes.bool.isRequired,
  freeSectionsCount: PropTypes.number,
  sectionUrl: PropTypes.string,
  parentUrl: PropTypes.string,
  orderType: PropTypes.string,
  isSubscriptionDiscount: PropTypes.bool.isRequired,
  prePurchasedCLEUrl: PropTypes.string,
  automaticRenewalPolicyUrl: PropTypes.string,
};

AccountOverviewCTASection.defaultProps = {
  contentItems: '[]',
  monthlyMember: false,
  upgradeUrl: '',
  upgradeUrlText: 'upgrade',
  parentUrlText: 'Join Today',
  freeSectionsCount: 0,
  sectionUrl: '',
  parentUrl: '',
  orderType: '',
  prePurchasedCLEUrl: '',
  automaticRenewalPolicyUrl: '',
};

AccountOverviewCTASection.displayName = 'AccountOverviewCTASection';

export default AccountOverviewCTASection;
