import styled from 'styled-components';

import { breakpoints } from '@style-guide/config';

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;

  > * {
    width: 100%;
  }

  > *:not(:first-child) {
    margin: 15px 0 0;
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-wrap: nowrap;

    > *:not(:first-child) {
      margin: 0 0 0 15px;
    }
  }
`;

InputRow.displayName = 'InputRow';

export default InputRow;
