import styled from 'styled-components';
import { breakpoints } from '@style-guide/config';

const DashboardContainer = styled.div`
  max-width: 1600px;

  @media (min-width: ${breakpoints.l}) {
    margin: 102px auto 40px;
    padding: 0 55px;
  }
`;

DashboardContainer.displayName = 'DashboardContainer';

export default DashboardContainer;
