import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import is from 'is_js';
import { ebarContentTypesValues } from '@config';
import ResultDiv from '../shared/components/ResultDiv';
import LinkMainDiv from '../shared/components/LinkMainDiv';
import ClearDiv from '../shared/components/ClearDiv';
import AngleSpan from '../shared/components/AngleSpan';
import LinkAnchor from '../shared/components/LinkAnchor';
import CaseSpan from '../shared/components/CaseSpan';
import TitleDiv from '../shared/components/TitleDiv';
import ResourceBookmark from '../shared/ResourceBookmark/ResourceBookmark';
import ListParagraph from '../shared/components/ListParagraph';

const EBarOtherAnnouncements = ({
  eBarOtherAnnouncementsLists,
}) => (
  <ResultDiv>
    {eBarOtherAnnouncementsLists.title !== undefined &&
      is.not.null(eBarOtherAnnouncementsLists.title) &&
      eBarOtherAnnouncementsLists.title.trim().length > 0 && (
        <TitleDiv>
            {eBarOtherAnnouncementsLists.title}
        </TitleDiv>
    )}
    <CaseSpan>
      {eBarOtherAnnouncementsLists.partnerEntity !== undefined &&
       is.not.null(eBarOtherAnnouncementsLists.partnerEntity) &&
       eBarOtherAnnouncementsLists.partnerEntity.trim().length > 0 && (
       <span>{eBarOtherAnnouncementsLists.partnerEntity}</span>
      )}
      {(eBarOtherAnnouncementsLists.partnerEntity !== undefined &&
       is.not.null(eBarOtherAnnouncementsLists.partnerEntity) &&
       eBarOtherAnnouncementsLists.partnerEntity.trim().length > 0 &&
       eBarOtherAnnouncementsLists.postedDateString !== undefined &&
       is.not.null(eBarOtherAnnouncementsLists.postedDateString) &&
       eBarOtherAnnouncementsLists.postedDateString.trim().length > 0) && (
       <span> | </span>
      )}
      {(eBarOtherAnnouncementsLists.postedDateString !== undefined &&
       is.not.null(eBarOtherAnnouncementsLists.postedDateString) &&
       eBarOtherAnnouncementsLists.postedDateString.trim().length > 0) ?
        `Posted: ${moment(eBarOtherAnnouncementsLists.postedDateString).format('MMM D, YYYY')}` : ''}
    </CaseSpan>
    {/* eslint-disable */}
    {eBarOtherAnnouncementsLists.summary !== undefined &&
     is.not.null(eBarOtherAnnouncementsLists.summary) &&
     eBarOtherAnnouncementsLists.summary.trim().length > 0 && (
     <ListParagraph dangerouslySetInnerHTML={{ __html: eBarOtherAnnouncementsLists.summary }} />
    )}
    {/* eslint-enable */}
    <LinkMainDiv>
      {((eBarOtherAnnouncementsLists.cTAUrl !== undefined &&
       is.not.null(eBarOtherAnnouncementsLists.cTAUrl) &&
       eBarOtherAnnouncementsLists.cTAUrl.trim().length > 0) ||
       (eBarOtherAnnouncementsLists.cTALabel !== undefined &&
       is.not.null(eBarOtherAnnouncementsLists.cTALabel) &&
       eBarOtherAnnouncementsLists.cTALabel.trim().length > 0)) && (
       <div>
         <AngleSpan>
           &gt;
         </AngleSpan>
         <LinkAnchor
           href={(eBarOtherAnnouncementsLists.cTAUrl !== undefined &&
                is.not.null(eBarOtherAnnouncementsLists.cTAUrl) &&
                eBarOtherAnnouncementsLists.cTAUrl.trim().length > 0) ?
             eBarOtherAnnouncementsLists.cTAUrl : eBarOtherAnnouncementsLists.cTALabel}
           target="_blank"
           rel="noopener noreferrer"
         >
           {(eBarOtherAnnouncementsLists.cTALabel !== undefined &&
            is.not.null(eBarOtherAnnouncementsLists.cTALabel) &&
            eBarOtherAnnouncementsLists.cTALabel.trim().length > 0) ?
             eBarOtherAnnouncementsLists.cTALabel : eBarOtherAnnouncementsLists.cTAUrl}
         </LinkAnchor>
       </div>
      )}
      <ResourceBookmark
        contentGuidString={eBarOtherAnnouncementsLists.contentGuidString}
        type={ebarContentTypesValues.eBarOtherAnnouncement}
        isBookmarked={eBarOtherAnnouncementsLists.isBookmarked}
      />
    </LinkMainDiv>
    <ClearDiv />
  </ResultDiv>
);

EBarOtherAnnouncements.propTypes = {
  eBarOtherAnnouncementsLists: PropTypes.shape({
    contentGuidString: PropTypes.string,
    title: PropTypes.string,
    partnerEntity: PropTypes.string,
    postedDateString: PropTypes.string,
    summary: PropTypes.string,
    cTALabel: PropTypes.string,
    cTAUrl: PropTypes.string,
    isBookmarked: PropTypes.bool,
  }).isRequired,
};

EBarOtherAnnouncements.defaultProps = {
};

EBarOtherAnnouncements.displayName = 'EBarOtherAnnouncements';

export default EBarOtherAnnouncements;
