import styled from 'styled-components';

const PublicationName = styled.span`
  font-style: italic;
  font-weight: 600;
`;

PublicationName.displayName = 'PublicationName';

export default PublicationName;
