import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { saveCommittees } from '@redux/modules/committees';

import ErrorMessage from '@components/ErrorMessage';
import FormAccordion from '@components/FormAccordion';
import FormAccordionContainer from '@components/FormAccordionContainer';
import FormContainer from '@components/FormContainer';
import LoadingOverlay from '@components/LoadingOverlay';

import CommitteesForm from './components/CommitteesForm';

class CommitteesContent extends React.Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit(formValues) {
    this.props.dispatch(saveCommittees(formValues));
  }

  render() {
    const {
      loading,
      committees,
      title,
    } = this.props;

    const error = typeof this.props.error === 'object' ?
      'An unknown error has occurred. Please try again later.'
      :
      this.props.error;

    return (
      <FormAccordionContainer>
        <FormAccordion
          title={title}
          content={(
            <FormContainer>
              {loading && <LoadingOverlay />}
              <CommitteesForm
                initialValues={committees}
                onSubmit={this.submit}
                committees={committees}
                loading={loading}
                hasSaveError={!!error}
              />
              {error && (
                <ErrorMessage>
                  <span>{error}</span>
                </ErrorMessage>
              )}
            </FormContainer>
          )}
        />
      </FormAccordionContainer>
    );
  }
}

CommitteesContent.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  loading: PropTypes.bool,
  committees: PropTypes.shape({
    Committees: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.string,
    loading: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string,
};

CommitteesContent.defaultProps = {
  error: '',
  loading: false,
  title: 'Committees',
};

const mapStateToProps = ({ committees }) => ({
  error: committees.error,
  loading: committees.loading,
  committees,
});

CommitteesContent.displayName = 'CommitteesContent';

export default connect(mapStateToProps)(CommitteesContent);
