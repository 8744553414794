import styled from 'styled-components';
import rem from 'polished/lib/helpers/rem';
import { breakpoints, typography } from '@style-guide/config';

const DateStamp = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;

  span {
    font-size: ${rem('12px', typography.baseFontSize)};

    &:first-child {
      font-weight: 600;
    }

    &:last-child {
      font-style: italic;
    }
  }

  @media (min-width: ${breakpoints.l}) {
    display: none;
  }
`;

DateStamp.displayName = 'DateStamp';

export default DateStamp;
