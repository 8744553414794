import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import transparentize from 'polished/lib/color/transparentize';

import { colors } from '@style-guide/config';

const Message = styled.span`
  padding-top: 15px;
  font-weight: bold;
`;

Message.displayName = 'Message';

const OverlayContainer = styled.div`
  align-items: center;
  background-color: ${transparentize(0.2, colors.white)};
  display: flex;
  height: ${props => (props.height ? props.height : '100%')};
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: ${props => (props.width ? props.width : '100%')};
  z-index: 2;
  flex-direction: column;

  svg {
    color: ${colors.red};
    font-size: 3rem;
  }
`;

OverlayContainer.displayName = 'OverlayContainer';

const LoadingOverlay = ({
  height = '100%',
  width = '100%',
  message = null,
}) => (
  <OverlayContainer
    aria-hidden="true"
    height={height}
    width={width}
  >
    <FontAwesomeIcon
      icon={faSpinner}
      spin
    />
    {message && (
      <Message>{message}</Message>
    )}
  </OverlayContainer>
);

LoadingOverlay.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  message: PropTypes.string,
};

LoadingOverlay.displayName = 'LoadingOverlay';

export default LoadingOverlay;
