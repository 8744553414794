import styled from 'styled-components';
import { colors } from '@style-guide/config';

const Body = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  input[type=checkbox] + label,
  input[type=radio] + label {
    color: ${colors.white};
    margin-bottom: 10px;
  }
`;

Body.displayName = 'Body';

export default Body;
