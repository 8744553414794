import React from 'react';
import PropTypes from 'prop-types';

import PageContainerRightRail from '@components/PageContainerRightRail';
import ResourceContent from './components/ResourceContent';
import ResourceRightRail from './components/ResourceRightRail';

const EBarResourceBody = ({
  practiceArea,
  caseDescription,
  caseName,
  webCitation,
  court,
  pdfLink,
  articleContent,
  articleContentAreaName,
  rightRailContent,
  rightRailContentAreaName,
  contributors,
  tags,
  relatedSpecialFeatures,
  relatedArticles,
  relatedCles,
  isMember,
  contentGuidString,
  isBookmarked,
  isSpecialFeature,
  isRestricted,
  createAccountUrl,
}) => (
  <PageContainerRightRail>
    <ResourceContent
      practiceArea={practiceArea}
      caseDescription={caseDescription}
      caseName={caseName}
      webCitation={webCitation}
      court={court}
      pdfLink={pdfLink}
      content={articleContent}
      contributors={contributors}
      tags={tags}
      contentAreaName={articleContentAreaName}
      isMember={isMember}
      contentGuidString={contentGuidString}
      isBookmarked={isBookmarked}
      isSpecialFeature={isSpecialFeature}
      isRestricted={isRestricted}
      createAccountUrl={createAccountUrl}
      relatedArticles={relatedArticles}
      relatedCles={relatedCles}
    />
    <ResourceRightRail
      content={rightRailContent}
      contentAreaName={rightRailContentAreaName}
      relatedSpecialFeatures={relatedSpecialFeatures}
      relatedArticles={relatedArticles}
      isSpecialFeature={isSpecialFeature}
    />
  </PageContainerRightRail>
);

EBarResourceBody.propTypes = {
  practiceArea: PropTypes.string.isRequired,
  caseDescription: PropTypes.string.isRequired,
  caseName: PropTypes.string.isRequired,
  webCitation: PropTypes.string.isRequired,
  court: PropTypes.string.isRequired,
  pdfLink: PropTypes.string.isRequired,
  articleContent: PropTypes.string.isRequired,
  articleContentAreaName: PropTypes.string.isRequired,
  rightRailContent: PropTypes.string.isRequired,
  rightRailContentAreaName: PropTypes.string.isRequired,
  contributors: PropTypes.arrayOf(PropTypes.object).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  relatedSpecialFeatures: PropTypes.arrayOf(PropTypes.object),
  relatedArticles: PropTypes.arrayOf(PropTypes.object),
  relatedCles: PropTypes.arrayOf(PropTypes.object),
  isMember: PropTypes.bool.isRequired,
  contentGuidString: PropTypes.string.isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  isSpecialFeature: PropTypes.bool.isRequired,
  isRestricted: PropTypes.bool.isRequired,
  createAccountUrl: PropTypes.string.isRequired,
};

EBarResourceBody.defaultProps = {
  relatedSpecialFeatures: [],
  relatedArticles: [],
  relatedCles: [],
};

EBarResourceBody.displayName = 'EBarResourceBody';

export default EBarResourceBody;
