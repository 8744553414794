import { urlEncode } from '@services/Url';

const serializeFormData = data => ({
  contentGuidString: data.contentGuidString,
  title: data.title,
  partnerEntity: data.partnerEntity,
  postedDateString: data.postedDateString,
  activeDateString: data.activeDateString,
  summary: data.summary,
  cTALabel: data.cTALabel,
  cTAUrl: data.cTAUrl,
});

const eBarOtherAnnouncementsCreate = async (data) => {
  const eBarCreate = await fetch('/EBarOtherAnnouncementsCTABlock/CreateEBarOtherAnnouncement', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarCreateJson = eBarCreate.json();

  return eBarCreateJson;
};

const eBarOtherAnnouncementsUpdate = async (data) => {
  const eBarUpdate = await fetch('/EBarOtherAnnouncementsCTABlock/UpdateEBarOtherAnnouncement', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      input: JSON.stringify(serializeFormData(data)),
    }),
  });
  const eBarUpdateJson = eBarUpdate.json();

  return eBarUpdateJson;
};

const eBarOtherAnnouncementsDelete = async (data) => {
  const eBarDelete = await fetch('/EBarOtherAnnouncementsCTABlock/DeleteEBarOtherAnnouncement', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: urlEncode({
      stringToDelete: data,
    }),
  });
  const eBarDeleteJson = eBarDelete.json();

  return eBarDeleteJson;
};


export {
  eBarOtherAnnouncementsCreate,
  eBarOtherAnnouncementsUpdate,
  eBarOtherAnnouncementsDelete,
};
